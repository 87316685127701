/* TypeScript file generated from FeatureFlags__AppSpecificConfig.res by genType. */
/* eslint-disable import/first */


// @ts-ignore: Implicit any on import
import * as Curry__Es6Import from 'rescript/lib/es6/curry.js';
const Curry: any = Curry__Es6Import;

// @ts-ignore: Implicit any on import
import * as FeatureFlags__AppSpecificConfigBS__Es6Import from './FeatureFlags__AppSpecificConfig.bs';
const FeatureFlags__AppSpecificConfigBS: any = FeatureFlags__AppSpecificConfigBS__Es6Import;

import type {Map_t as Belt_Map_t} from '../../../../rescript-shims/Belt.shim';

import type {t as FeatureFlags__GeneratedTypes_t} from './FeatureFlags__GeneratedTypes.gen';

// tslint:disable-next-line:interface-over-type-literal
export type status = "on" | "off";
export type Status = status;

// tslint:disable-next-line:max-classes-per-file 
export abstract class FeatureFlagsComparable_identity { protected opaque!: any }; /* simulate opaque types */

// tslint:disable-next-line:interface-over-type-literal
export type mapValue = { readonly status: status; readonly disabled: boolean };

// tslint:disable-next-line:interface-over-type-literal
export type FeatureFlagsMap_t = Belt_Map_t<FeatureFlags__GeneratedTypes_t,mapValue,FeatureFlagsComparable_identity>;

export const errorReportingPrefix: string = FeatureFlags__AppSpecificConfigBS.errorReportingPrefix;

export const storagePrefix: string = FeatureFlags__AppSpecificConfigBS.storagePrefix;

export const FeatureFlagsMap_toArray: <T1,T2,T3>(_1:Belt_Map_t<T1,T2,T3>) => Array<[T1, T2]> = FeatureFlags__AppSpecificConfigBS.FeatureFlagsMap.toArray;

export const FeatureFlagsMap_mergeWithNetwork: (map1:FeatureFlagsMap_t, map2:FeatureFlagsMap_t) => FeatureFlagsMap_t = function (Arg1: any, Arg2: any) {
  const result = Curry._2(FeatureFlags__AppSpecificConfigBS.FeatureFlagsMap.mergeWithNetwork, Arg1, Arg2);
  return result
};
