// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AlgoliaApi__Infra from "./AlgoliaApi__Infra.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as AlgoliaApi__ClientTs from "./AlgoliaApi__Client.ts";

var PriceFilter = {};

var PrelovedItemSearchFilters = {};

function searchProductVariantIndex(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9) {
  return AlgoliaApi__ClientTs.searchProductVariantIndex(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9);
}

function searchPrelovedItemIndex(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7) {
  return AlgoliaApi__ClientTs.searchPrelovedItemIndex(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7);
}

function searchIndex(apiConfig, indexName, searchTerm, take, givenOffset, filters, prelovedItemFilters, settings, sortInfo, prelovedItemSortInfo, analyticsTags, includeRefurbishedInfo, includeOverstockedInfo, newPrelovedCards) {
  var offset = givenOffset;
  if (indexName === "PrelovedItems") {
    if (settings !== undefined) {
      if (settings.TAG === /* ProductVariantSearchSettings */0) {
        RescriptUtils__Logger.warn2(AlgoliaApi__Infra.logger, "AlgoliaApi__Client searchIndex - search settings of unknown type sent. Ignoring.", settings);
        return {
                TAG: /* PrelovedItem */1,
                _0: searchPrelovedItemIndex(searchTerm, take, offset, apiConfig, prelovedItemSortInfo, prelovedItemFilters, undefined, newPrelovedCards)
              };
      }
      var includeTestItems = settings.includeTestItems;
      if (includeTestItems !== undefined) {
        return {
                TAG: /* PrelovedItem */1,
                _0: searchPrelovedItemIndex(searchTerm, take, offset, apiConfig, prelovedItemSortInfo, prelovedItemFilters, {
                      includeTestItems: includeTestItems
                    }, newPrelovedCards)
              };
      }
      
    }
    return {
            TAG: /* PrelovedItem */1,
            _0: searchPrelovedItemIndex(searchTerm, take, offset, apiConfig, prelovedItemSortInfo, prelovedItemFilters, undefined, newPrelovedCards)
          };
  }
  if (settings !== undefined) {
    if (settings.TAG === /* ProductVariantSearchSettings */0) {
      var defaultVariantsOnly = settings.defaultVariantsOnly;
      if (defaultVariantsOnly !== undefined) {
        var includeTestProducts = settings.includeTestProducts;
        if (includeTestProducts !== undefined) {
          return {
                  TAG: /* ProductVariant */0,
                  _0: searchProductVariantIndex(searchTerm, take, offset, analyticsTags, apiConfig, filters, {
                        defaultVariantsOnly: defaultVariantsOnly,
                        includeTestProducts: includeTestProducts
                      }, sortInfo, includeRefurbishedInfo, includeOverstockedInfo)
                };
        } else {
          return {
                  TAG: /* ProductVariant */0,
                  _0: searchProductVariantIndex(searchTerm, take, offset, analyticsTags, apiConfig, filters, {
                        defaultVariantsOnly: defaultVariantsOnly,
                        includeTestProducts: false
                      }, sortInfo, includeRefurbishedInfo, includeOverstockedInfo)
                };
        }
      }
      var includeTestProducts$1 = settings.includeTestProducts;
      if (includeTestProducts$1 !== undefined) {
        return {
                TAG: /* ProductVariant */0,
                _0: searchProductVariantIndex(searchTerm, take, offset, analyticsTags, apiConfig, filters, {
                      defaultVariantsOnly: undefined,
                      includeTestProducts: includeTestProducts$1
                    }, sortInfo, includeRefurbishedInfo, includeOverstockedInfo)
              };
      }
      
    } else {
      RescriptUtils__Logger.warn2(AlgoliaApi__Infra.logger, "AlgoliaApi__Client searchIndex - search settings of unknown type sent. Ignoring.", settings);
      return {
              TAG: /* ProductVariant */0,
              _0: searchProductVariantIndex(searchTerm, take, offset, analyticsTags, apiConfig, filters, undefined, sortInfo, includeRefurbishedInfo, includeOverstockedInfo)
            };
    }
  }
  return {
          TAG: /* ProductVariant */0,
          _0: searchProductVariantIndex(searchTerm, take, offset, analyticsTags, apiConfig, filters, undefined, sortInfo, includeRefurbishedInfo, includeOverstockedInfo)
        };
}

export {
  PriceFilter ,
  PrelovedItemSearchFilters ,
  searchProductVariantIndex ,
  searchPrelovedItemIndex ,
  searchIndex ,
}
/* AlgoliaApi__Infra Not a pure module */
