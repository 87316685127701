// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as ShopifyApi__Id from "./ShopifyApi__Id.bs.js";
import * as ShopifyApi__Infra from "./ShopifyApi__Infra.bs.js";
import * as ShopifyApi__Client from "./ShopifyApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as ShopifyApi__CommonFragments from "./ShopifyApi__CommonFragments.bs.js";

var Raw = {};

var query = "fragment ProductVariantFields on ProductVariant   {\nid  \npriceV2  {\n...ShopifyMoneyFields   \n}\n\ncompareAtPriceV2  {\n...ShopifyMoneyFields   \n}\n\navailableForSale  \ncurrentlyNotInStock  \nimage  {\n...ImageFields   \n}\n\nsku  \nselectedOptions  {\nname  \nvalue  \n}\n\nproduct  {\nid  \noptions  {\nid  \nname  \nvalues  \n}\n\n}\n\nquantityAvailable  \n}\n" + ShopifyApi__CommonFragments.ImageFields.query + ShopifyApi__CommonFragments.ShopifyMoneyFields.query;

function parse(value) {
  var value$1 = value.priceV2;
  var value$2 = value.compareAtPriceV2;
  var value$3 = value.image;
  var value$4 = value.sku;
  var value$5 = value.selectedOptions;
  var value$6 = value.product;
  var value$7 = value$6.options;
  var value$8 = value.quantityAvailable;
  return {
          id: value.id,
          priceV2: ShopifyApi__CommonFragments.ShopifyMoneyFields.verifyArgsAndParse("ShopifyMoneyFields", value$1),
          compareAtPriceV2: !(value$2 == null) ? ShopifyApi__CommonFragments.ShopifyMoneyFields.verifyArgsAndParse("ShopifyMoneyFields", value$2) : undefined,
          availableForSale: value.availableForSale,
          currentlyNotInStock: value.currentlyNotInStock,
          image: !(value$3 == null) ? ShopifyApi__CommonFragments.ImageFields.verifyArgsAndParse("ImageFields", value$3) : undefined,
          sku: !(value$4 == null) ? value$4 : undefined,
          selectedOptions: Js_array.map((function (value) {
                  return {
                          name: value.name,
                          value: value.value
                        };
                }), value$5),
          product: {
            id: value$6.id,
            options: Js_array.map((function (value) {
                    var value$1 = value.values;
                    return {
                            id: value.id,
                            name: value.name,
                            values: Js_array.map((function (value) {
                                    return value;
                                  }), value$1)
                          };
                  }), value$7)
          },
          quantityAvailable: !(value$8 == null) ? value$8 : undefined
        };
}

function serialize(value) {
  var value$1 = value.quantityAvailable;
  var quantityAvailable = value$1 !== undefined ? value$1 : null;
  var value$2 = value.product;
  var value$3 = value$2.options;
  var options = Js_array.map((function (value) {
          var value$1 = value.values;
          var values = Js_array.map((function (value) {
                  return value;
                }), value$1);
          var value$2 = value.name;
          var value$3 = value.id;
          return {
                  id: value$3,
                  name: value$2,
                  values: values
                };
        }), value$3);
  var value$4 = value$2.id;
  var product = {
    id: value$4,
    options: options
  };
  var value$5 = value.selectedOptions;
  var selectedOptions = Js_array.map((function (value) {
          var value$1 = value.value;
          var value$2 = value.name;
          return {
                  name: value$2,
                  value: value$1
                };
        }), value$5);
  var value$6 = value.sku;
  var sku = value$6 !== undefined ? value$6 : null;
  var value$7 = value.image;
  var image = value$7 !== undefined ? ShopifyApi__CommonFragments.ImageFields.serialize(value$7) : null;
  var value$8 = value.currentlyNotInStock;
  var value$9 = value.availableForSale;
  var value$10 = value.compareAtPriceV2;
  var compareAtPriceV2 = value$10 !== undefined ? ShopifyApi__CommonFragments.ShopifyMoneyFields.serialize(value$10) : null;
  var value$11 = value.priceV2;
  var priceV2 = ShopifyApi__CommonFragments.ShopifyMoneyFields.serialize(value$11);
  var value$12 = value.id;
  return {
          id: value$12,
          priceV2: priceV2,
          compareAtPriceV2: compareAtPriceV2,
          availableForSale: value$9,
          currentlyNotInStock: value$8,
          image: image,
          sku: sku,
          selectedOptions: selectedOptions,
          product: product,
          quantityAvailable: quantityAvailable
        };
}

function verifyArgsAndParse(_ProductVariantFields, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var ProductVariantFields = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = "query DefaultVariant($productId: ID!)  {\nproduct(id: $productId)  {\nvariants(first: 1)  {\nedges  {\nnode  {\n...ProductVariantFields   \n}\n\n}\n\n}\n\n}\n\n}\n" + query;

function parse$1(value) {
  var value$1 = value.product;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.variants;
    var value$3 = value$2.edges;
    tmp = {
      variants: {
        edges: Js_array.map((function (value) {
                return {
                        node: parse(value.node)
                      };
              }), value$3)
      }
    };
  }
  return {
          product: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.product;
  var product;
  if (value$1 !== undefined) {
    var value$2 = value$1.variants;
    var value$3 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var node = serialize(value$1);
            return {
                    node: node
                  };
          }), value$3);
    var variants = {
      edges: edges
    };
    product = {
      variants: variants
    };
  } else {
    product = null;
  }
  return {
          product: product
        };
}

function serializeVariables(inp) {
  return {
          productId: inp.productId
        };
}

function makeVariables(productId, param) {
  return {
          productId: productId
        };
}

var DefaultVariantQuery = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var Raw$2 = {};

var query$2 = "query DefaultVariantByProductIds($productIds: [ID!]!)  {\nnodes(ids: $productIds)  {\n__typename\n...on Product   {\nvariants(first: 1)  {\nnodes  {\n...ProductVariantFields   \n}\n\n}\n\n}\n\n}\n\n}\n" + query;

function parse$2(value) {
  var value$1 = value.nodes;
  return {
          nodes: Js_array.map((function (value) {
                  if (value == null) {
                    return ;
                  }
                  var typename = value["__typename"];
                  var tmp;
                  if (typename === "Product") {
                    var value$1 = value.variants;
                    var value$2 = value$1.nodes;
                    tmp = {
                      NAME: "Product",
                      VAL: {
                        variants: {
                          nodes: Js_array.map(parse, value$2)
                        }
                      }
                    };
                  } else {
                    tmp = {
                      NAME: "UnspecifiedFragment",
                      VAL: typename
                    };
                  }
                  return tmp;
                }), value$1)
        };
}

function serialize$2(value) {
  var value$1 = value.nodes;
  var nodes = Js_array.map((function (value) {
          if (value === undefined) {
            return null;
          }
          if (value.NAME !== "Product") {
            return {};
          }
          var value$1 = value.VAL.variants;
          var value$2 = value$1.nodes;
          var nodes = Js_array.map(serialize, value$2);
          var variants = {
            nodes: nodes
          };
          return {
                  __typename: "Product",
                  variants: variants
                };
        }), value$1);
  return {
          nodes: nodes
        };
}

function serializeVariables$1(inp) {
  var a = inp.productIds;
  return {
          productIds: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function makeVariables$1(productIds, param) {
  return {
          productIds: productIds
        };
}

var DefaultVariantByProductIdsQuery = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var Raw$3 = {};

var query$3 = "query ProductVariant($id: ID!)  {\nnode(id: $id)  {\n__typename\n...on ProductVariant   {\n...ProductVariantFields   \n}\n\n}\n\n}\n" + query;

function parse$3(value) {
  var value$1 = value.node;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1 = typename === "ProductVariant" ? ({
          NAME: "ProductVariant",
          VAL: {
            productVariantFields: parse(value$1)
          }
        }) : ({
          NAME: "UnspecifiedFragment",
          VAL: typename
        });
    tmp = tmp$1;
  }
  return {
          node: tmp
        };
}

function serialize$3(value) {
  var value$1 = value.node;
  var node = value$1 !== undefined ? (
      value$1.NAME === "UnspecifiedFragment" ? ({}) : Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                return {};
              }), [serialize(value$1.VAL.productVariantFields)])
    ) : null;
  return {
          node: node
        };
}

function serializeVariables$2(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$2(id, param) {
  return {
          id: id
        };
}

var ProductVariantQuery = {
  Raw: Raw$3,
  query: query$3,
  parse: parse$3,
  serialize: serialize$3,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2
};

var Raw$4 = {};

var query$4 = "query ProductVariantsByIds($ids: [ID!]!)  {\nnodes(ids: $ids)  {\n__typename\n...on ProductVariant   {\n...ProductVariantFields   \n}\n\n}\n\n}\n" + query;

function parse$4(value) {
  var value$1 = value.nodes;
  return {
          nodes: Js_array.map((function (value) {
                  if (value == null) {
                    return ;
                  }
                  var typename = value["__typename"];
                  var tmp = typename === "ProductVariant" ? ({
                        NAME: "ProductVariant",
                        VAL: {
                          productVariantFields: parse(value)
                        }
                      }) : ({
                        NAME: "UnspecifiedFragment",
                        VAL: typename
                      });
                  return tmp;
                }), value$1)
        };
}

function serialize$4(value) {
  var value$1 = value.nodes;
  var nodes = Js_array.map((function (value) {
          if (value !== undefined) {
            if (value.NAME === "UnspecifiedFragment") {
              return {};
            } else {
              return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                            return {};
                          }), [serialize(value.VAL.productVariantFields)]);
            }
          } else {
            return null;
          }
        }), value$1);
  return {
          nodes: nodes
        };
}

function serializeVariables$3(inp) {
  var a = inp.ids;
  return {
          ids: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function makeVariables$3(ids, param) {
  return {
          ids: ids
        };
}

var ProductVariantsByIdsQuery = {
  Raw: Raw$4,
  query: query$4,
  parse: parse$4,
  serialize: serialize$4,
  serializeVariables: serializeVariables$3,
  makeVariables: makeVariables$3
};

var Raw$5 = {};

var query$5 = "query GetProductVariantBySelectedOptions($id: ID!, $selectedOptions: [SelectedOptionInput!]!)  {\nproduct(id: $id)  {\nvariantBySelectedOptions(selectedOptions: $selectedOptions)  {\n...ProductVariantFields   \n}\n\n}\n\n}\n" + query;

function parse$5(value) {
  var value$1 = value.product;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.variantBySelectedOptions;
    tmp = {
      variantBySelectedOptions: !(value$2 == null) ? parse(value$2) : undefined
    };
  }
  return {
          product: tmp
        };
}

function serialize$5(value) {
  var value$1 = value.product;
  var product;
  if (value$1 !== undefined) {
    var value$2 = value$1.variantBySelectedOptions;
    var variantBySelectedOptions = value$2 !== undefined ? serialize(value$2) : null;
    product = {
      variantBySelectedOptions: variantBySelectedOptions
    };
  } else {
    product = null;
  }
  return {
          product: product
        };
}

function serializeInputObjectSelectedOptionInput(inp) {
  return {
          name: inp.name,
          value: inp.value
        };
}

function serializeVariables$4(inp) {
  var a = inp.selectedOptions;
  return {
          id: inp.id,
          selectedOptions: Js_array.map(serializeInputObjectSelectedOptionInput, a)
        };
}

function makeVariables$4(id, selectedOptions, param) {
  return {
          id: id,
          selectedOptions: selectedOptions
        };
}

function makeInputObjectSelectedOptionInput(name, value, param) {
  return {
          name: name,
          value: value
        };
}

var ProductVariantBySelectedOptionsQuery = {
  Raw: Raw$5,
  query: query$5,
  parse: parse$5,
  serialize: serialize$5,
  serializeVariables: serializeVariables$4,
  serializeInputObjectSelectedOptionInput: serializeInputObjectSelectedOptionInput,
  makeVariables: makeVariables$4,
  makeInputObjectSelectedOptionInput: makeInputObjectSelectedOptionInput
};

function make(optionId, value) {
  return "" + ShopifyApi__Id.toString(optionId) + "_" + value + "";
}

var AttributeValueId = {
  make: make
};

function makeProductVariant(param) {
  var product = param.product;
  return {
          id: ShopifyApi__Id.parseExn(param.id),
          compareAtPrice: Belt_Option.map(param.compareAtPriceV2, ShopifyApi__CommonFragments.makeMoney),
          price: ShopifyApi__CommonFragments.makeMoney(param.priceV2),
          attributeValues: Belt_Array.map(param.selectedOptions, (function (selectedOption) {
                  var match = Belt_Array.getBy(product.options, (function (param) {
                          return param.name === selectedOption.name;
                        }));
                  var optionId = match !== undefined ? ShopifyApi__Id.parseExn(match.id) : Js_exn.raiseError("Unknown selectedOption.name received: " + selectedOption.name);
                  return {
                          id: make(optionId, selectedOption.value),
                          image: undefined,
                          value: selectedOption.value,
                          attributeId: optionId
                        };
                })),
          availableForSale: param.availableForSale,
          inStock: !param.currentlyNotInStock,
          mainImage: Belt_Option.map(param.image, ShopifyApi__CommonFragments.makeImage),
          media: [],
          productId: ShopifyApi__Id.parseExn(product.id),
          sku: param.sku,
          quantityAvailable: param.quantityAvailable
        };
}

function fetchDefaultVariant(apiConfig, productId) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$1,
                    Raw: Raw$1,
                    parse: parse$1,
                    serialize: serialize$1,
                    serializeVariables: serializeVariables
                  }), {
                productId: ShopifyApi__Id.toString(productId)
              }, undefined).then(function (param) {
              return Belt_Option.flatMap(RescriptUtils__OptionPlus.tapNone(param.product, (function (param) {
                                RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "fetchDefaultVariant - no product found. Id:", ShopifyApi__Id.toString(productId));
                              })), (function (param) {
                            return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(Belt_Array.get(param.variants.edges, 0), (function (param) {
                                              RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "fetchDefaultVariant - no default variant found. Product Id:", ShopifyApi__Id.toString(productId));
                                            })), (function (param) {
                                          return makeProductVariant(param.node);
                                        }));
                          }));
            });
}

function fetchDefaultVariantByProductIds(apiConfig, productIds) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                      query: query$2,
                      Raw: Raw$2,
                      parse: parse$2,
                      serialize: serialize$2,
                      serializeVariables: serializeVariables$1
                    }), {
                  productIds: Belt_Array.map(productIds, ShopifyApi__Id.toString)
                }, undefined).then(function (param) {
                return param.nodes;
              }).then(function (__x) {
              return Belt_Array.mapWithIndex(__x, (function (i, node) {
                            if (node !== undefined) {
                              if (node.NAME === "Product") {
                                return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(Belt_Array.get(node.VAL.variants.nodes, 0), (function (param) {
                                                  RescriptUtils__Logger.warn3(ShopifyApi__Infra.logger, "fetchDefaultVariantByProductIds - no variant found. Index/Node productIds:", i, Belt_Array.map(productIds, ShopifyApi__Id.toString));
                                                })), makeProductVariant);
                              } else {
                                RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "fetchDefaultVariantByProductIds - unexpected typename found. Node:", node);
                                return ;
                              }
                            }
                            
                          }));
            });
}

function fetchVariant(apiConfig, id) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$3,
                    Raw: Raw$3,
                    parse: parse$3,
                    serialize: serialize$3,
                    serializeVariables: serializeVariables$2
                  }), {
                id: ShopifyApi__Id.toString(id)
              }, undefined).then(function (param) {
              var node = param.node;
              if (node !== undefined) {
                if (node.NAME === "UnspecifiedFragment") {
                  RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "fetchVariant - unexpected typename found. Id:", ShopifyApi__Id.toString(id));
                  return ;
                } else {
                  return makeProductVariant(node.VAL.productVariantFields);
                }
              } else {
                RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "fetchVariant - no variant found. Id:", ShopifyApi__Id.toString(id));
                return ;
              }
            });
}

function fetchVariantsByIds(apiConfig, ids) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                      query: query$4,
                      Raw: Raw$4,
                      parse: parse$4,
                      serialize: serialize$4,
                      serializeVariables: serializeVariables$3
                    }), {
                  ids: Belt_Array.map(ids, ShopifyApi__Id.toString)
                }, undefined).then(function (param) {
                return param.nodes;
              }).then(function (__x) {
              return Belt_Array.mapWithIndex(__x, (function (i, node) {
                            if (node !== undefined) {
                              if (node.NAME === "UnspecifiedFragment") {
                                RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "fetchVariantsByIds - unexpected typename found. Node:", node);
                                return ;
                              } else {
                                return makeProductVariant(node.VAL.productVariantFields);
                              }
                            } else {
                              RescriptUtils__Logger.warn3(ShopifyApi__Infra.logger, "fetchVariantsByIds - no variant found. Index/Node ids:", i, Belt_Array.map(ids, ShopifyApi__Id.toString));
                              return ;
                            }
                          }));
            });
}

function fetchProductVariantBySelectedOptions(apiConfig, id, selectedOptions) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$5,
                    Raw: Raw$5,
                    parse: parse$5,
                    serialize: serialize$5,
                    serializeVariables: serializeVariables$4
                  }), {
                id: ShopifyApi__Id.toString(id),
                selectedOptions: Belt_Array.map(selectedOptions, (function (selectedOption) {
                        return {
                                name: selectedOption.name,
                                value: selectedOption.value
                              };
                      }))
              }, undefined).then(function (param) {
              return Belt_Option.flatMap(RescriptUtils__OptionPlus.tapNone(param.product, (function (param) {
                                RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "fetchProductVariantBySelectedOptions - no product found. Id:", ShopifyApi__Id.toString(id));
                              })), (function (param) {
                            return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(param.variantBySelectedOptions, (function (param) {
                                              RescriptUtils__Logger.warn3(ShopifyApi__Infra.logger, "fetchProductVariantBySelectedOptions - no variantBySelectedOptions. Product id, selected options:", ShopifyApi__Id.toString(id), selectedOptions);
                                            })), makeProductVariant);
                          }));
            });
}

var logger = ShopifyApi__Infra.logger;

var Logger;

export {
  logger ,
  Logger ,
  ProductVariantFields ,
  DefaultVariantQuery ,
  DefaultVariantByProductIdsQuery ,
  ProductVariantQuery ,
  ProductVariantsByIdsQuery ,
  ProductVariantBySelectedOptionsQuery ,
  AttributeValueId ,
  makeProductVariant ,
  fetchDefaultVariant ,
  fetchDefaultVariantByProductIds ,
  fetchVariant ,
  fetchVariantsByIds ,
  fetchProductVariantBySelectedOptions ,
}
/* ShopifyApi__Id Not a pure module */
