// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";
import * as Ui__Group from "../../../../../ui/src/group/components/Ui__Group.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__TextField from "../../../../../ui/src/textField/components/Ui__TextField.bs.js";
import * as Ui__MarkotText from "../../../../../ui/src/text/components/Ui__MarkotText.bs.js";
import LodashDebounce from "lodash.debounce";
import * as Ui__Toast__Error from "../../../../../ui/src/toast/components/error/Ui__Toast__Error.bs.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Ui__LoadingIndicator from "../../../../../ui/src/loading/components/Ui__LoadingIndicator.bs.js";
import * as Ignored$DecidrIoUtils from "@decidr-io/utils/src/functions/Ignored.bs.js";
import * as App__AddressPrediction from "../addressForm/App__AddressPrediction.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as App__GooglePlacesAttribution from "../googlePlacesAttribution/App__GooglePlacesAttribution.bs.js";

var initialPredictions = [];

var match = Ui__Styling.describe({
      predictionsContainer: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.background,
                    borderBottomEndRadius: theme.roundness._4,
                    borderBottomStartRadius: theme.roundness._4,
                    paddingHorizontal: theme.spacing._8,
                    paddingVertical: theme.spacing._4
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

var make = React.forwardRef(function (Props, ref) {
      var testIDOpt = Props.testID;
      var label = Props.label;
      var value = Props.value;
      var placeholder = Props.placeholder;
      var status = Props.status;
      var onChangeText = Props.onChangeText;
      var onBlur = Props.onBlur;
      var blurOnSubmit = Props.blurOnSubmit;
      var returnKeyType = Props.returnKeyType;
      var autoComplete = Props.autoComplete;
      var textContentType = Props.textContentType;
      var onSubmitEditing = Props.onSubmitEditing;
      var onSelectPredicition = Props.onSelectPredicition;
      var testID = testIDOpt !== undefined ? testIDOpt : "address-lookup-field";
      var match = Curry._1(Ui__Styling.useTheme, undefined);
      var theme = match.theme;
      var styled = Curry._1(stylesheet, theme);
      var match$1 = React.useState(function () {
            return false;
          });
      var setLoading = match$1[1];
      var match$2 = React.useState(function () {
            
          });
      var setServiceErrorMessage = match$2[1];
      var serviceErrorMessage = match$2[0];
      var match$3 = React.useState(function () {
            
          });
      var setError = match$3[1];
      var match$4 = React.useState(function () {
            return initialPredictions;
          });
      var setPredictions = match$4[1];
      var predictions = match$4[0];
      var partial_arg = LodashDebounce((function (searchTerm) {
              Curry._1(setServiceErrorMessage, (function (param) {
                      
                    }));
              var trimmedSearchTerm = searchTerm.trim();
              if (trimmedSearchTerm !== "") {
                Curry._1(setLoading, (function (param) {
                        return true;
                      }));
                return Ignored$DecidrIoUtils.promise($$Promise.$$catch(App__AddressPrediction.getPredictions({
                                      input: searchTerm,
                                      apiKey: undefined,
                                      types: ["address"],
                                      sessionToken: undefined
                                    }).then(function (predictions) {
                                    Curry._1(setPredictions, (function (param) {
                                            return predictions;
                                          }));
                                    Curry._1(setLoading, (function (param) {
                                            return false;
                                          }));
                                    return Promise.resolve(undefined);
                                  }), (function (error) {
                                  var msg;
                                  var exit = 0;
                                  var _error;
                                  try {
                                    _error = error;
                                    exit = 1;
                                  }
                                  catch (raw_error){
                                    var error$1 = Caml_js_exceptions.internalToOCamlException(raw_error);
                                    if (error$1.RE_EXN_ID === $$Promise.JsError) {
                                      msg = Belt_Option.getWithDefault(error$1._1.message, "Unknown error occurred!");
                                    } else {
                                      throw error$1;
                                    }
                                  }
                                  if (exit === 1) {
                                    msg = Belt_Option.getWithDefault((_error && _error._1 && _error._1.message), "Unknown error occurred!");
                                  }
                                  Curry._1(setServiceErrorMessage, (function (param) {
                                          return msg;
                                        }));
                                  Curry._1(setLoading, (function (param) {
                                          return false;
                                        }));
                                  return Promise.resolve(undefined);
                                })));
              }
              
            }), 300);
      var search = React.useCallback(Curry.__1(partial_arg), []);
      var handleAddressSelection = function (param) {
        var subpremise = Belt_Option.mapWithDefault(param.subpremise, "", (function (subpremise) {
                return "" + subpremise + "/";
              }));
        var streetNumber = Belt_Option.mapWithDefault(param.streetNumber, "", (function (streetNumber) {
                return "" + streetNumber + " ";
              }));
        Belt_Option.forEach(Belt_Option.map(param.street, (function (street) {
                    return subpremise + streetNumber + street;
                  })), onChangeText);
        Curry._4(onSelectPredicition, param.city, param.state, param.country, param.postalCode);
        Curry._1(setPredictions, (function (param) {
                return [];
              }));
      };
      var tmp = {
        onChangeText: (function (value) {
            Curry._1(search, value);
            Curry._1(onChangeText, value);
          }),
        onSubmitEditing: (function (value) {
            Belt_Option.forEach(onSubmitEditing, (function (onSubmitEditing) {
                    Curry._1(onSubmitEditing, value);
                  }));
            Curry._1(setPredictions, (function (param) {
                    return [];
                  }));
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
          }),
        testID: "address-lookup-field-input",
        ref: ref
      };
      if (label !== undefined) {
        tmp.label = Caml_option.valFromOption(label);
      }
      if (value !== undefined) {
        tmp.value = Caml_option.valFromOption(value);
      }
      if (placeholder !== undefined) {
        tmp.placeholder = Caml_option.valFromOption(placeholder);
      }
      if (status !== undefined) {
        tmp.status = Caml_option.valFromOption(status);
      }
      if (onBlur !== undefined) {
        tmp.onBlur = Caml_option.valFromOption(onBlur);
      }
      if (autoComplete !== undefined) {
        tmp.autoComplete = Caml_option.valFromOption(autoComplete);
      }
      if (blurOnSubmit !== undefined) {
        tmp.blurOnSubmit = Caml_option.valFromOption(blurOnSubmit);
      }
      if (returnKeyType !== undefined) {
        tmp.returnKeyType = Caml_option.valFromOption(returnKeyType);
      }
      if (textContentType !== undefined) {
        tmp.textContentType = Caml_option.valFromOption(textContentType);
      }
      var tmp$1;
      var exit = 0;
      if (predictions.length !== 0 || serviceErrorMessage !== undefined) {
        exit = 1;
      } else {
        tmp$1 = null;
      }
      if (exit === 1) {
        tmp$1 = serviceErrorMessage !== undefined ? React.createElement(Ui__Group.make, {
                spacing: theme.spacing._6,
                children: null,
                containerStyle: Curry._1(styled, styles.predictionsContainer)
              }, React.createElement(Ui__MarkotText.make, {
                    children: serviceErrorMessage
                  }), React.createElement(Ui__MarkotText.make, {
                    children: "You can still save this address"
                  }), React.createElement(App__GooglePlacesAttribution.make, {})) : React.createElement(Ui__Group.make, {
                spacing: theme.spacing._8,
                children: null,
                containerStyle: Curry._1(styled, styles.predictionsContainer),
                testID: "predictions-container"
              }, Belt_Array.map(predictions, (function (param) {
                      var placeId = param.placeId;
                      var text = param.text;
                      return React.createElement(ReactNative.TouchableOpacity, {
                                  onPress: (function (param) {
                                      Ignored$DecidrIoUtils.promise(App__AddressPrediction.getAddressDetails(placeId).then(function (result) {
                                                if (result.TAG === /* Ok */0) {
                                                  var match = result._0;
                                                  if (match.NAME === "Data") {
                                                    return handleAddressSelection(match.VAL);
                                                  }
                                                  var match$1 = match.VAL;
                                                  var error = match$1[1];
                                                  handleAddressSelection(match$1[0]);
                                                  return Curry._1(setError, (function (param) {
                                                                return error;
                                                              }));
                                                }
                                                var message = result._0;
                                                Curry._1(setServiceErrorMessage, (function (param) {
                                                        return message;
                                                      }));
                                              }));
                                    }),
                                  testID: "address-prediction",
                                  children: React.createElement(Ui__MarkotText.make, {
                                        children: text
                                      }),
                                  key: text
                                });
                    })), React.createElement(App__GooglePlacesAttribution.make, {}));
      }
      return React.createElement(Ui__Group.make, {
                  children: null,
                  testID: testID
                }, React.createElement(Ui__TextField.make, tmp), SimpleReact$DecidrIoUtils.whenTrue(match$1[0], React.createElement(Ui__LoadingIndicator.make, {})), tmp$1, SimpleReact$DecidrIoUtils.renderIfSome(match$3[0], (function (error) {
                        return React.createElement(Ui__Toast__Error.make, {
                                    title: "An error has occurred!",
                                    description: error,
                                    onDismiss: (function (param) {
                                        Curry._1(setError, (function (param) {
                                                
                                              }));
                                      })
                                  });
                      })));
    });

var debounceDelay = 300;

export {
  initialPredictions ,
  styles ,
  stylesheet ,
  debounceDelay ,
  make ,
}
/* match Not a pure module */
