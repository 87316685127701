// Generated by ReScript, PLEASE EDIT WITH CARE


function money(param) {
  return {
          amount: param.amount,
          currency: param.currency
        };
}

function imageResource(param) {
  var size = param.size;
  return {
          __typename: "ImageResource",
          alt: param.alt,
          url: param.url,
          size: {
            width: size.width,
            height: size.height
          }
        };
}

export {
  money ,
  imageResource ,
}
/* No side effect */
