/* TypeScript file generated from Ui__MarkotText.res by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as Ui__MarkotTextBS__Es6Import from './Ui__MarkotText.bs';
const Ui__MarkotTextBS: any = Ui__MarkotTextBS__Es6Import;

import type {Accessibility_role as ReactNative_Accessibility_role} from '../../../../../rescript-shims/ReactNative.shim';

import type {Color_t as ReactNative_Color_t} from '../../../../../rescript-shims/ReactNative.shim';

import type {Event_layoutEvent as ReactNative_Event_layoutEvent} from '../../../../../rescript-shims/ReactNative.shim';

import type {Event_pressEvent as ReactNative_Event_pressEvent} from '../../../../../rescript-shims/ReactNative.shim';

import type {Style_t as ReactNative_Style_t} from '../../../../../rescript-shims/ReactNative.shim';

import type {Typography_variant as Ui__Theme_Typography_variant} from '../../../../../packages/ui/src/theme/components/Ui__Theme.gen';

import type {WebText_accessibilityLevel as Ui__BaseText_WebText_accessibilityLevel} from './Ui__BaseText.gen';

import type {WebText_ellipsizeMode as Ui__BaseText_WebText_ellipsizeMode} from './Ui__BaseText.gen';

import type {Web_hrefAttrs as ReactNative_Web_hrefAttrs} from '../../../../../rescript-shims/ReactNative.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly accessibilityLabel?: string; 
  readonly accessibilityLevel?: Ui__BaseText_WebText_accessibilityLevel; 
  readonly accessibilityRole?: ReactNative_Accessibility_role; 
  readonly children: React.ReactNode; 
  readonly color?: ReactNative_Color_t; 
  readonly ellipsizeMode?: Ui__BaseText_WebText_ellipsizeMode; 
  readonly fontFamily?: string; 
  readonly href?: string; 
  readonly hrefAttrs?: ReactNative_Web_hrefAttrs; 
  readonly numberOfLines?: number; 
  readonly onClick?: (_1:MouseEvent) => void; 
  readonly onLayout?: (_1:ReactNative_Event_layoutEvent) => void; 
  readonly onPress?: (_1:ReactNative_Event_pressEvent) => void; 
  readonly selectable?: boolean; 
  readonly size?: Ui__Theme_Typography_variant; 
  readonly style?: ReactNative_Style_t; 
  readonly testID?: string
};

export const make: React.ComponentType<{
  readonly accessibilityLabel?: string; 
  readonly accessibilityLevel?: Ui__BaseText_WebText_accessibilityLevel; 
  readonly accessibilityRole?: ReactNative_Accessibility_role; 
  readonly children: React.ReactNode; 
  readonly color?: ReactNative_Color_t; 
  readonly ellipsizeMode?: Ui__BaseText_WebText_ellipsizeMode; 
  readonly fontFamily?: string; 
  readonly href?: string; 
  readonly hrefAttrs?: ReactNative_Web_hrefAttrs; 
  readonly numberOfLines?: number; 
  readonly onClick?: (_1:MouseEvent) => void; 
  readonly onLayout?: (_1:ReactNative_Event_layoutEvent) => void; 
  readonly onPress?: (_1:ReactNative_Event_pressEvent) => void; 
  readonly selectable?: boolean; 
  readonly size?: Ui__Theme_Typography_variant; 
  readonly style?: ReactNative_Style_t; 
  readonly testID?: string
}> = Ui__MarkotTextBS.make;
