// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "../../../api-adapter-markot/src/MarkotApi__Id.bs.js";
import * as GraphqlCore__Types from "../../../graphql-core/src/GraphqlCore__Types.bs.js";
import * as MarkotApi__Address from "../../../api-adapter-markot/src/MarkotApi__Address.bs.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";

function makeAddressId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "Address", param);
}

function makeAddress(param) {
  return {
          __typename: "Address",
          id: GqlMiddleware__Core__NodeId.make(1, "Address", MarkotApi__Id.unpackExn(param.id).internalId),
          state2: param.state2,
          country: param.country,
          postalCode: param.postalCode,
          city: param.city,
          recipientName: param.recipientName,
          streetLine1: param.streetLine1,
          streetLine2: param.streetLine2,
          extraInfo: param.extraInfo,
          description: param.description
        };
}

function fetchAddress(apiConfig, id) {
  return MarkotApi__Address.fetchBasicData(apiConfig, MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(id))).then(function (__x) {
              return Belt_Option.map(__x, makeAddress);
            });
}

function fetchUserAddresses(apiConfig, first, after) {
  return MarkotApi__Address.fetchUserAddresses(apiConfig, first, after).then(function (__x) {
                return Belt_Option.map(__x, (function (__x) {
                              return GraphqlCore__Types.Connection.map(__x, makeAddress);
                            }));
              }).then(function (result) {
              if (result !== undefined) {
                return result;
              } else {
                RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "[Address] fetchUserAddresses - viewer not returned from Markot API");
                return {
                        pageInfo: {
                          startCursor: undefined,
                          endCursor: undefined,
                          hasNextPage: false,
                          hasPreviousPage: false
                        },
                        edges: []
                      };
              }
            });
}

function createUserAddress(apiConfig, param) {
  return MarkotApi__Address.createUserAddress(apiConfig, {
                city: param.city,
                country: param.country,
                description: param.description,
                extraInfo: param.extraInfo,
                postalCode: param.postalCode,
                recipientName: param.recipientName,
                state: param.state,
                streetLine1: param.streetLine1,
                streetLine2: param.streetLine2,
                addressType: Belt_Option.map(param.addressType, (function (addressType) {
                        if (addressType === "Shipping") {
                          return "Shipping";
                        } else {
                          return "Billing";
                        }
                      }))
              }).then(function (result) {
              return {
                      __typename: "CreateUserAddressPayload",
                      address: makeAddress(result.address),
                      addressId: GqlMiddleware__Core__NodeId.make(1, "Address", MarkotApi__Id.unpackExn(result.addressId).internalId)
                    };
            });
}

function updateUserAddress(apiConfig, param) {
  return MarkotApi__Address.updateUserAddress(apiConfig, {
                addressId: MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(param.addressId)),
                city: param.city,
                country: param.country,
                description: param.description,
                extraInfo: param.extraInfo,
                postalCode: param.postalCode,
                recipientName: param.recipientName,
                state: param.state,
                streetLine1: param.streetLine1,
                streetLine2: param.streetLine2
              }).then(function (result) {
              return {
                      __typename: "UpdateUserAddressPayload",
                      address: makeAddress(result.address),
                      addressId: GqlMiddleware__Core__NodeId.make(1, "Address", MarkotApi__Id.unpackExn(result.addressId).internalId)
                    };
            });
}

function deleteUserAddress(apiConfig, id) {
  return MarkotApi__Address.deleteUserAddress(apiConfig, id).then(function (param) {
              return {
                      __typename: "DeleteUserAddressPayload",
                      deletedAddressId: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.deletedAddressId))
                    };
            });
}

export {
  makeAddressId ,
  makeAddress ,
  fetchAddress ,
  fetchUserAddresses ,
  createUserAddress ,
  updateUserAddress ,
  deleteUserAddress ,
}
/* MarkotApi__Id Not a pure module */
