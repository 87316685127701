// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../group/components/Ui__Group.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../styling/Ui__Styling.bs.js";
import * as Ui__Responsive from "../responsive/Ui__Responsive.bs.js";
import * as Ui__RadioButton from "../radioButton/Ui__RadioButton.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as Box$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/box/components/Box.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center"
        },
        _1: (function (props) {
            var tmp = {
              borderColor: props.selected ? props.theme.colors.surface : props.theme.colors.primaryShade2,
              borderRadius: props.theme.roundness._2,
              borderWidth: props.theme.lineThickness.xs,
              paddingHorizontal: props.padding
            };
            var tmp$1 = Bool$DecidrIoUtils.thenSome(props.selected, props.theme.colors.surface);
            if (tmp$1 !== undefined) {
              tmp.backgroundColor = Caml_option.valFromOption(tmp$1);
            }
            return tmp;
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__SelectableWrapper(Props) {
  var selectedOpt = Props.selected;
  var onPress = Props.onPress;
  var children = Props.children;
  var containerStyle = Props.containerStyle;
  var testID = Props.testID;
  var selected = selectedOpt !== undefined ? selectedOpt : false;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var spacing = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        undefined,
        theme.spacing._8,
        undefined,
        theme.spacing._10,
        undefined,
        theme.spacing._6,
        undefined
      ]);
  var styled = Curry._1(stylesheet, {
        theme: theme,
        padding: spacing,
        selected: selected
      });
  var tmp = {
    kind: {
      NAME: "Pressable",
      VAL: Box$DecidrIoUniversalComponents.makePressableProps(undefined, undefined, undefined, (function (param) {
              Curry._1(onPress, undefined);
            }), undefined, undefined, undefined)
    },
    spacing: 0,
    children: null,
    containerStyle: [
      Caml_option.some(Curry._1(styled, styles.container)),
      containerStyle
    ],
    orientation: "horizontal"
  };
  if (testID !== undefined) {
    tmp.testID = testID;
  }
  return React.createElement(Ui__Group.make, tmp, React.createElement(Ui__RadioButton.make, {
                  selected: selected
                }), children);
}

var make = Ui__SelectableWrapper;

export {
  make ,
}
/* match Not a pure module */
