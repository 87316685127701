// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../../../ui/src/group/components/Ui__Group.bs.js";
import * as App__Money from "../../../money/components/App__Money.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as App__Report from "../../../utils/App__Report.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Counter from "../../../../../ui/src/counter/components/Ui__Counter.bs.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as ReactNative from "react-native";
import * as Ui__MarkotIcon from "../../../../../ui/src/icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__MarkotText from "../../../../../ui/src/text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../../../../ui/src/responsive/Ui__Responsive.bs.js";
import * as Ui__MarkotImage from "../../../../../ui/src/image/components/Ui__MarkotImage.bs.js";
import * as Utils__Analytics from "../../../../../utils/src/analytics/Utils__Analytics.bs.js";
import * as Ui__ImageFallback from "../../../../../ui/src/image/components/imageFallback/Ui__ImageFallback.bs.js";
import * as Ui__OverstockedTag from "../../../../../ui/src/overstockedTag/Ui__OverstockedTag.bs.js";
import * as Ui__RefurbishedTag from "../../../../../ui/src/refurbishedTag/Ui__RefurbishedTag.bs.js";
import * as Ui__BrandNameFallback from "../../../../../ui/src/brandNameFallback/Ui__BrandNameFallback.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as AppBagItemCard_bagItem_graphql from "../../../../../relay-codegen/generated/AppBagItemCard_bagItem_graphql.bs.js";
import * as FeatureFlags__LocalFeatureFlags from "../../../../../feature-flags/src/local/FeatureFlags__LocalFeatureFlags.bs.js";
import * as Dialog$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/dialog/Dialog.bs.js";
import * as RnUtils$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/utils/RnUtils.bs.js";
import * as AppBagItemCard_removeFromBagMutation_graphql from "../../../../../relay-codegen/generated/AppBagItemCard_removeFromBagMutation_graphql.bs.js";
import * as AppBagItemCard_updateBagItemQuantityMutation_graphql from "../../../../../relay-codegen/generated/AppBagItemCard_updateBagItemQuantityMutation_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppBagItemCard_bagItem_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppBagItemCard_bagItem_graphql.Internal.convertFragment, data);
}

function use$1(param) {
  var match = ReactRelay.useMutation(AppBagItemCard_removeFromBagMutation_graphql.node);
  var mutate = match[0];
  return [
          React.useMemo((function () {
                  return function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
                    return Curry._1(mutate, {
                                onError: param,
                                onCompleted: param$1 !== undefined ? (function (r, errors) {
                                      Curry._2(param$1, AppBagItemCard_removeFromBagMutation_graphql.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                                    }) : undefined,
                                onUnsubscribe: param$2,
                                optimisticResponse: param$3 !== undefined ? AppBagItemCard_removeFromBagMutation_graphql.Internal.convertWrapRawResponse(param$3) : undefined,
                                optimisticUpdater: param$4,
                                updater: param$5 !== undefined ? (function (store, r) {
                                      Curry._2(param$5, store, AppBagItemCard_removeFromBagMutation_graphql.Internal.convertResponse(r));
                                    }) : undefined,
                                variables: AppBagItemCard_removeFromBagMutation_graphql.Internal.convertVariables(param$6),
                                uploadables: param$7
                              });
                  };
                }), [mutate]),
          match[1]
        ];
}

function use$2(param) {
  var match = ReactRelay.useMutation(AppBagItemCard_updateBagItemQuantityMutation_graphql.node);
  var mutate = match[0];
  return [
          React.useMemo((function () {
                  return function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
                    return Curry._1(mutate, {
                                onError: param,
                                onCompleted: param$1 !== undefined ? (function (r, errors) {
                                      Curry._2(param$1, AppBagItemCard_updateBagItemQuantityMutation_graphql.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                                    }) : undefined,
                                onUnsubscribe: param$2,
                                optimisticResponse: param$3 !== undefined ? AppBagItemCard_updateBagItemQuantityMutation_graphql.Internal.convertWrapRawResponse(param$3) : undefined,
                                optimisticUpdater: param$4,
                                updater: param$5 !== undefined ? (function (store, r) {
                                      Curry._2(param$5, store, AppBagItemCard_updateBagItemQuantityMutation_graphql.Internal.convertResponse(r));
                                    }) : undefined,
                                variables: AppBagItemCard_updateBagItemQuantityMutation_graphql.Internal.convertVariables(param$6),
                                uploadables: param$7
                              });
                  };
                }), [mutate]),
          match[1]
        ];
}

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          overflow: "hidden"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.surface,
                    borderRadius: theme.roundness._4,
                    marginVertical: theme.spacing._2,
                    paddingRight: theme.spacing._4,
                    paddingVertical: theme.spacing._4
                  };
          })
      },
      apart: {
        TAG: /* Static */0,
        _0: {
          justifyContent: "space-between"
        }
      },
      alignEnd: {
        TAG: /* Static */0,
        _0: {
          alignItems: "flex-end"
        }
      },
      section: {
        TAG: /* Static */0,
        _0: {
          flex: 1,
          justifyContent: "space-between"
        }
      },
      price: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "bold"
        }
      },
      discountedPrice: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "500",
          textDecorationLine: "line-through"
        }
      },
      brand: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "500"
        }
      },
      flex1: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      removeFromBagButton: {
        TAG: /* Static */0,
        _0: {
          alignSelf: "flex-start"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function App__BagItem__Card(Props) {
  var bagId = Props.bagId;
  var bagItem = Props.bagItem;
  var customImageSize = Props.customImageSize;
  var style = Props.style;
  var onError = Props.onError;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = use(bagItem);
  var quantity = match$1.quantity;
  var pricePerQuantity = match$1.pricePerQuantity;
  var merchandise = match$1.merchandise;
  var id = match$1.id;
  var compareAtPricePerQuantity = match$1.compareAtPricePerQuantity;
  var match$2 = use$1(undefined);
  var removeFromBag = match$2[0];
  var match$3 = use$2(undefined);
  var updateBagItemQuantity = match$3[0];
  var implementRefurbishedProducts = FeatureFlags__LocalFeatureFlags.use("implementRefurbishedProducts");
  var implementOverstockedProducts = FeatureFlags__LocalFeatureFlags.use("implementOverstockedProducts");
  var match$4 = Utils__Analytics.useSegmentAnalytics(undefined);
  var track = match$4.track;
  var imageSize = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        88,
        undefined,
        108,
        80,
        undefined,
        undefined,
        88,
        undefined
      ]);
  var compareAtPrice = Belt_Option.flatMap(compareAtPricePerQuantity, (function (compareAtPricePerQuantity) {
          return Belt_Float.fromString(compareAtPricePerQuantity.amount);
        }));
  var price = Belt_Float.fromString(pricePerQuantity.amount);
  var tmp;
  if (merchandise.NAME === "UnselectedUnionMember") {
    App__Report.unknownUnionMember(merchandise.VAL);
    tmp = React.createElement(Ui__ImageFallback.make, {
          width: imageSize,
          height: imageSize
        });
  } else {
    var match$5 = merchandise.VAL;
    var mainImage = match$5.mainImage;
    tmp = mainImage !== undefined ? React.createElement(Ui__MarkotImage.make, {
            image: {
              src: mainImage.url,
              width: mainImage.size.width,
              height: mainImage.size.height
            },
            alt: Belt_Option.getWithDefault(mainImage.alt, match$5.product.name),
            size: {
              NAME: "fixedWidth",
              VAL: {
                width: Belt_Option.getWithDefault(customImageSize, imageSize),
                aspectRatio: 1
              }
            },
            resizeMode: "contain",
            testID: "bag-new-item-image"
          }) : React.createElement(Ui__ImageFallback.make, {
            width: Belt_Option.getWithDefault(customImageSize, imageSize),
            height: Belt_Option.getWithDefault(customImageSize, imageSize)
          });
  }
  var tmp$1;
  if (merchandise.NAME === "UnselectedUnionMember") {
    App__Report.unknownUnionMember(merchandise.VAL);
    tmp$1 = null;
  } else {
    var match$6 = merchandise.VAL.product;
    var match$7 = match$6.brand;
    if (match$7 !== undefined) {
      tmp$1 = React.createElement(Ui__MarkotText.make, {
            size: "subtitle",
            children: match$7.name,
            style: Curry._1(styled, styles.brand)
          });
    } else {
      App__Report.unexpected("Missing brand for " + match$6.name + "", "App__BagItem__Card");
      tmp$1 = React.createElement(Ui__BrandNameFallback.make, {
            size: "subtitle",
            style: Curry._1(styled, styles.brand)
          });
    }
  }
  var tmp$2;
  if (merchandise.NAME === "UnselectedUnionMember") {
    App__Report.unknownUnionMember(merchandise.VAL);
    tmp$2 = null;
  } else {
    var match$8 = merchandise.VAL;
    var product = match$8.product;
    var attributeValues = match$8.attributeValues;
    var counter = React.createElement(Ui__Counter.make, {
          value: quantity,
          size: "xxs",
          lowestValue: 1,
          highestValue: 99,
          onChange: (function (newValue) {
              Curry.app(updateBagItemQuantity, [
                    (function (param) {
                        App__Report.unexpected("An error has occurred: " + param.message + "", "App__BagItem__Card updateBagItemQuantity onError");
                        Curry._2(onError, "Something went wrong", "Could not update quantity! Please try again.");
                      }),
                    (function (result, errors) {
                        if (errors !== undefined) {
                          Curry._2(onError, "Something went wrong", "Could not update quantity! Please try again.");
                          return App__Report.unexpected(Belt_Array.joinWith(errors, ", ", (function (error) {
                                            return error.message;
                                          })), "App__BagItem__Card updateBagItemQuantity onCompleted");
                        }
                        var updatedBagItemNewQuantity = Belt_Option.map(Belt_Array.getBy(Belt_Array.map(result.updateBagItemQuantity.bag.items.edges, (function (param) {
                                        return param.node;
                                      })), (function (param) {
                                    return param.id === id;
                                  })), (function (param) {
                                return param.quantity;
                              }));
                        if (Belt_Option.getWithDefault(Belt_Option.map(updatedBagItemNewQuantity, (function (quantity) {
                                      return quantity === 0;
                                    })), false)) {
                          Curry._2(onError, "Error updating quantity", "The item has been sold out in the meantime and it has been removed from your cart.");
                        } else if (Belt_Option.getWithDefault(Belt_Option.map(updatedBagItemNewQuantity, (function (quantity) {
                                      return quantity !== newValue;
                                    })), false)) {
                          Curry._2(onError, "Error updating quantity", "You can't update quantity as desired because inventory has changed in the meantime.");
                        }
                        var variantInfo_cartId = bagId;
                        var variantInfo_name = product.name;
                        var variantInfo_brand = Belt_Option.mapWithDefault(product.brand, "-", (function (param) {
                                return param.name;
                              }));
                        var variantInfo_variantId = id;
                        var variantInfo_variantName = Belt_Array.joinWith(attributeValues, " / ", (function (attributeValue) {
                                if (attributeValue.value === "Default Title") {
                                  return "";
                                } else {
                                  return attributeValue.value;
                                }
                              }));
                        var variantInfo_currency = pricePerQuantity.currency;
                        var variantInfo_quantity = 1;
                        var variantInfo = {
                          cartId: variantInfo_cartId,
                          name: variantInfo_name,
                          brand: variantInfo_brand,
                          variantId: variantInfo_variantId,
                          variantName: variantInfo_variantName,
                          price: price,
                          compareAtPrice: compareAtPrice,
                          currency: variantInfo_currency,
                          quantity: variantInfo_quantity,
                          totalPrice: price
                        };
                        if (newValue > quantity) {
                          return Curry._2(track, {
                                      TAG: /* AddToCart */8,
                                      _0: variantInfo
                                    }, "App__BagItem__Card updateBagItemQuantity add");
                        } else {
                          return Curry._2(track, {
                                      TAG: /* RemoveFromCart */9,
                                      _0: variantInfo
                                    }, "App__BagItem__Card updateBagItemQuantity remove");
                        }
                      }),
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    {
                      bagId: bagId,
                      bagItemId: id,
                      quantity: newValue
                    },
                    undefined,
                    undefined
                  ]);
            }),
          testID: "bag-quantity-counter",
          disabled: match$3[1]
        });
    var tmp$3;
    var exit = 0;
    if (implementRefurbishedProducts === "off" && implementOverstockedProducts === "off") {
      tmp$3 = counter;
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp$3 = Belt_Option.isSome(product.refurbished) ? React.createElement(Ui__RefurbishedTag.make, {
              style: {
                alignSelf: "flex-end"
              }
            }) : (
          Belt_Option.isSome(product.overstocked) ? React.createElement(Ui__OverstockedTag.make, {
                  style: {
                    alignSelf: "flex-end"
                  }
                }) : counter
        );
    }
    tmp$2 = React.createElement(Ui__Group.make, {
          children: null,
          containerStyle: [
            Curry._1(styled, styles.apart),
            Curry._1(styled, styles.alignEnd)
          ],
          orientation: "horizontal"
        }, React.createElement(Ui__Group.make, {
              spacing: theme.spacing._2,
              children: null,
              includeStartSpacing: true
            }, SimpleReact$DecidrIoUtils.renderIfSome(compareAtPricePerQuantity, (function (param) {
                    return React.createElement(Ui__MarkotText.make, {
                                size: "price3",
                                color: theme.colors.info,
                                children: React.createElement(App__Money.make, {
                                      money: param.fragmentRefs
                                    }),
                                style: Curry._1(styled, styles.discountedPrice)
                              });
                  })), React.createElement(Ui__MarkotText.make, {
                  size: "price2",
                  children: React.createElement(App__Money.make, {
                        money: pricePerQuantity.fragmentRefs
                      }),
                  style: Curry._1(styled, styles.price),
                  testID: "price-container"
                })), tmp$3);
  }
  return React.createElement(Ui__Group.make, {
              spacing: theme.spacing._4,
              children: null,
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles.container)),
                style
              ],
              orientation: "horizontal",
              testID: "bag-item-card-container"
            }, tmp, React.createElement(ReactNative.View, {
                  style: Curry._1(styled, styles.section),
                  children: null
                }, React.createElement(Ui__Group.make, {
                      children: null,
                      containerStyle: Curry._1(styled, styles.apart),
                      orientation: "horizontal"
                    }, React.createElement(Ui__Group.make, {
                          spacing: theme.spacing._2,
                          children: null,
                          containerStyle: Curry._1(styled, styles.flex1)
                        }, tmp$1, merchandise.NAME === "UnselectedUnionMember" ? (App__Report.unknownUnionMember(merchandise.VAL), null) : React.createElement(Ui__MarkotText.make, {
                                size: "subtitle",
                                children: merchandise.VAL.product.name,
                                numberOfLines: 2
                              }), merchandise.NAME === "UnselectedUnionMember" ? (App__Report.unknownUnionMember(merchandise.VAL), null) : React.createElement(Ui__MarkotText.make, {
                                size: "subtitle",
                                color: theme.colors.primaryShade1,
                                children: Belt_Array.joinWith(merchandise.VAL.attributeValues, " / ", (function (attributeValue) {
                                        if (attributeValue.value === "Default Title") {
                                          return "";
                                        } else {
                                          return attributeValue.value;
                                        }
                                      })),
                                numberOfLines: 2
                              })), React.createElement(ReactNative.TouchableOpacity, {
                          style: Curry._1(styled, styles.removeFromBagButton),
                          hitSlop: RnUtils$DecidrIoUniversalComponents.makeSymmetricHitSlop(theme.spacing._5),
                          onPress: (function (param) {
                              Dialog$DecidrIoUniversalComponents.ask("Are you sure you want to remove this item from your bag?", undefined, [
                                    {
                                      text: "Cancel",
                                      style: "cancel"
                                    },
                                    {
                                      text: "Confirm",
                                      onPress: (function (param) {
                                          Curry.app(removeFromBag, [
                                                (function (param) {
                                                    App__Report.unexpected("An error has occurred: " + param.message + "", "App__BagItem__Card removeFromBag onError");
                                                    Curry._2(onError, "Something went wrong", "Could not remove selected item! Please try again.");
                                                  }),
                                                (function (_result, errors) {
                                                    if (errors !== undefined) {
                                                      Curry._2(onError, "Something went wrong", "Could not remove selected item! Please try again.");
                                                      return App__Report.unexpected(Belt_Array.joinWith(errors, ", ", (function (error) {
                                                                        return error.message;
                                                                      })), "App__BagItem__Card removeFromBag onCompleted");
                                                    }
                                                    if (merchandise.NAME === "UnselectedUnionMember") {
                                                      return ;
                                                    }
                                                    var match = merchandise.VAL;
                                                    var product = match.product;
                                                    Curry._2(track, {
                                                          TAG: /* RemoveFromCart */9,
                                                          _0: {
                                                            cartId: bagId,
                                                            name: product.name,
                                                            brand: Belt_Option.mapWithDefault(product.brand, "-", (function (param) {
                                                                    return param.name;
                                                                  })),
                                                            variantId: match.id,
                                                            variantName: Belt_Array.joinWith(match.attributeValues, " / ", (function (attributeValue) {
                                                                    if (attributeValue.value === "Default Title") {
                                                                      return "";
                                                                    } else {
                                                                      return attributeValue.value;
                                                                    }
                                                                  })),
                                                            price: price,
                                                            compareAtPrice: compareAtPrice,
                                                            currency: pricePerQuantity.currency,
                                                            quantity: quantity,
                                                            totalPrice: Belt_Option.map(price, (function (price) {
                                                                    return price * quantity;
                                                                  }))
                                                          }
                                                        }, "App__BagItem__Card removeFromBag");
                                                  }),
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                {
                                                  bagId: bagId,
                                                  bagItemId: id
                                                },
                                                undefined,
                                                undefined
                                              ]);
                                        }),
                                      style: "destructive"
                                    }
                                  ], undefined, undefined);
                            }),
                          testID: "remove-bag-item-button",
                          children: React.createElement(Ui__MarkotIcon.make, {
                                name: "trash-bin",
                                size: 20
                              })
                        })), tmp$2));
}

var make = App__BagItem__Card;

export {
  make ,
}
/* match Not a pure module */
