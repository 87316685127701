// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as MarkotApi__Infra from "./MarkotApi__Infra.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";

var Raw = {};

function parse(value) {
  var value$1 = value.alt;
  var value$2 = value.size;
  return {
          url: value.url,
          alt: !(value$1 == null) ? value$1 : undefined,
          size: {
            width: value$2.width,
            height: value$2.height
          }
        };
}

function serialize(value) {
  var value$1 = value.size;
  var value$2 = value$1.height;
  var value$3 = value$1.width;
  var size = {
    width: value$3,
    height: value$2
  };
  var value$4 = value.alt;
  var alt = value$4 !== undefined ? value$4 : null;
  var value$5 = value.url;
  return {
          url: value$5,
          alt: alt,
          size: size
        };
}

function verifyArgsAndParse(_ImageResourceFields, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var ImageResourceFields = {
  Raw: Raw,
  query: "fragment ImageResourceFields on ImageResource   {\nurl  \nalt  \nsize  {\nwidth  \nheight  \n}\n\n}\n",
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

function parse$1(value) {
  var value$1 = value.currency;
  var tmp = value$1 === "AUD" ? "AUD" : ({
        NAME: "FutureAddedValue",
        VAL: value$1
      });
  return {
          amount: value.amount,
          currency: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.currency;
  var currency = typeof value$1 === "object" ? value$1.VAL : "AUD";
  var value$2 = value.amount;
  return {
          amount: value$2,
          currency: currency
        };
}

function verifyArgsAndParse$1(_MoneyFields, value) {
  return parse$1(value);
}

function verifyName$1(param) {
  
}

var MoneyFields = {
  Raw: Raw$1,
  query: "fragment MoneyFields on Money   {\namount  \ncurrency  \n}\n",
  parse: parse$1,
  serialize: serialize$1,
  verifyArgsAndParse: verifyArgsAndParse$1,
  verifyName: verifyName$1
};

var Raw$2 = {};

function parse$2(value) {
  var value$1 = value.streetLine2;
  var value$2 = value.extraInfo;
  var value$3 = value.description;
  return {
          id: value.id,
          state2: value.state2,
          country: value.country,
          postalCode: value.postalCode,
          city: value.city,
          recipientName: value.recipientName,
          streetLine1: value.streetLine1,
          streetLine2: !(value$1 == null) ? value$1 : undefined,
          extraInfo: !(value$2 == null) ? value$2 : undefined,
          description: !(value$3 == null) ? value$3 : undefined
        };
}

function serialize$2(value) {
  var value$1 = value.description;
  var description = value$1 !== undefined ? value$1 : null;
  var value$2 = value.extraInfo;
  var extraInfo = value$2 !== undefined ? value$2 : null;
  var value$3 = value.streetLine2;
  var streetLine2 = value$3 !== undefined ? value$3 : null;
  var value$4 = value.streetLine1;
  var value$5 = value.recipientName;
  var value$6 = value.city;
  var value$7 = value.postalCode;
  var value$8 = value.country;
  var value$9 = value.state2;
  var value$10 = value.id;
  return {
          id: value$10,
          state2: value$9,
          country: value$8,
          postalCode: value$7,
          city: value$6,
          recipientName: value$5,
          streetLine1: value$4,
          streetLine2: streetLine2,
          extraInfo: extraInfo,
          description: description
        };
}

function verifyArgsAndParse$2(_AddressFields, value) {
  return parse$2(value);
}

function verifyName$2(param) {
  
}

var AddressFields = {
  Raw: Raw$2,
  query: "fragment AddressFields on Address   {\nid  \nstate2  \ncountry  \npostalCode  \ncity  \nrecipientName  \nstreetLine1  \nstreetLine2  \nextraInfo  \ndescription  \n}\n",
  parse: parse$2,
  serialize: serialize$2,
  verifyArgsAndParse: verifyArgsAndParse$2,
  verifyName: verifyName$2
};

function makeCurrency(currency) {
  if (typeof currency !== "object") {
    return "AUD";
  }
  var newCurrency = currency.VAL;
  RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "makeCurrency - unsupported currency encountered", newCurrency);
  return Js_exn.raiseError("Unsupported currency encountered: " + newCurrency);
}

function makeMoney(param) {
  return {
          amount: param.amount,
          currency: makeCurrency(param.currency)
        };
}

function makeImageResource(param) {
  var size = param.size;
  return {
          url: param.url,
          alt: param.alt,
          size: {
            width: size.width,
            height: size.height
          }
        };
}

function makeAddress(param) {
  return {
          id: MarkotApi__Id.parseExn(param.id),
          state2: param.state2,
          country: param.country,
          postalCode: param.postalCode,
          city: param.city,
          recipientName: param.recipientName,
          streetLine1: param.streetLine1,
          streetLine2: param.streetLine2,
          extraInfo: param.extraInfo,
          description: param.description
        };
}

var Logger;

export {
  Logger ,
  ImageResourceFields ,
  MoneyFields ,
  AddressFields ,
  makeCurrency ,
  makeMoney ,
  makeImageResource ,
  makeAddress ,
}
/* MarkotApi__Id Not a pure module */
