// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Ui__NavigationRe from "./Ui__NavigationRe";

function useNavigation(prim) {
  return Ui__NavigationRe.useNavigation();
}

function useIsFocused(prim) {
  return Ui__NavigationRe.useIsFocused();
}

export {
  useNavigation ,
  useIsFocused ,
}
/* ./Ui__NavigationRe Not a pure module */
