// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Theme from "../../theme/components/Ui__Theme.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__TextUtils from "../utils/Ui__TextUtils.bs.js";
import * as NativeElement$ReactNative from "rescript-react-native/src/elements/NativeElement.bs.js";

var WebText = {};

var match = Ui__Styling.describe({
      text: {
        TAG: /* Dynamic */1,
        _0: (function (props) {
            return {
                    color: Belt_Option.getWithDefault(props.color, props.theme.colors.onSurface),
                    fontSize: Curry._1(Ui__Theme.Typography.Font.getSize, props.size),
                    letterSpacing: Curry._1(Ui__Theme.Typography.Font.getSize, props.size) * Curry._1(Ui__Theme.Typography.Font.getLetterSpacingPct, props.size),
                    lineHeight: Curry._1(Ui__Theme.Typography.Font.getLineHeight, props.size)
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function typographyVariantToFont(variant, theme) {
  if (typeof variant !== "object") {
    if (variant === "button2") {
      return theme.typography.button2;
    } else if (variant === "subtitle") {
      return theme.typography.subtitle;
    } else if (variant === "b1") {
      return theme.typography.b1;
    } else if (variant === "b2") {
      return theme.typography.b2;
    } else if (variant === "title1") {
      return theme.typography.title1;
    } else if (variant === "title2") {
      return theme.typography.title2;
    } else if (variant === "title3") {
      return theme.typography.title3;
    } else if (variant === "price1") {
      return theme.typography.price1;
    } else if (variant === "price2") {
      return theme.typography.price2;
    } else if (variant === "price3") {
      return theme.typography.price3;
    } else if (variant === "caption1") {
      return theme.typography.caption1;
    } else if (variant === "caption2") {
      return theme.typography.caption2;
    } else {
      return theme.typography.button1;
    }
  }
  var font = variant.VAL;
  return Curry._3(Ui__Theme.Typography.Font.unsafeMake, font.size, font.lineHeight, font.letterSpacingPct);
}

var make = React.forwardRef(function (Props, ref) {
      var givenFontFamily = Props.fontFamily;
      var size = Props.size;
      var color = Props.color;
      var accessible = Props.accessible;
      var accessibilityHint = Props.accessibilityHint;
      var accessibilityLabel = Props.accessibilityLabel;
      var accessibilityLevel = Props.accessibilityLevel;
      var accessibilityRole = Props.accessibilityRole;
      var accessibilityState = Props.accessibilityState;
      var allowFontScaling = Props.allowFontScaling;
      var ellipsizeMode = Props.ellipsizeMode;
      var numberOfLines = Props.numberOfLines;
      var onLayout = Props.onLayout;
      var onLongPress = Props.onLongPress;
      var onPress = Props.onPress;
      var pressRetentionOffset = Props.pressRetentionOffset;
      var selectable = Props.selectable;
      var style = Props.style;
      var testID = Props.testID;
      var selectionColor = Props.selectionColor;
      var textBreakStrategy = Props.textBreakStrategy;
      var adjustsFontSizeToFit = Props.adjustsFontSizeToFit;
      var minimumFontScale = Props.minimumFontScale;
      var suppressHighlighting = Props.suppressHighlighting;
      var value = Props.value;
      var children = Props.children;
      var onMouseEnter = Props.onMouseEnter;
      var onMouseLeave = Props.onMouseLeave;
      var href = Props.href;
      var hrefAttrs = Props.hrefAttrs;
      var onClick = Props.onClick;
      var match = Curry._1(Ui__Styling.useTheme, undefined);
      var theme = match.theme;
      var styled = Curry._1(stylesheet, {
            theme: theme,
            size: Belt_Option.getWithDefault(size, theme.typography.b2),
            color: color
          });
      var defaultFontFamily = Belt_Option.getWithDefault(givenFontFamily, theme.fontFamily.dm);
      var fontFamily = Ui__TextUtils.determineFontName([
            {
              fontFamily: defaultFontFamily
            },
            style
          ]);
      var finalStyle = [
        Caml_option.some(Curry._1(styled, styles.text)),
        style,
        {
          fontFamily: fontFamily
        }
      ];
      if (ReactNative.Platform.OS === "web") {
        var tmp = {
          children: Belt_Option.getWithDefault(children, null),
          style: finalStyle
        };
        var tmp$1 = Belt_Option.map((ref == null) ? undefined : Caml_option.some(ref), (function (prim) {
                return prim;
              }));
        if (tmp$1 !== undefined) {
          tmp.ref = Caml_option.valFromOption(tmp$1);
        }
        if (accessibilityHint !== undefined) {
          tmp.accessibilityHint = accessibilityHint;
        }
        if (accessibilityLabel !== undefined) {
          tmp.accessibilityLabel = accessibilityLabel;
        }
        if (accessibilityRole !== undefined) {
          tmp.accessibilityRole = Caml_option.valFromOption(accessibilityRole);
        }
        if (accessibilityState !== undefined) {
          tmp.accessibilityState = Caml_option.valFromOption(accessibilityState);
        }
        if (accessibilityLevel !== undefined) {
          tmp.accessibilityLevel = Caml_option.valFromOption(accessibilityLevel);
        }
        if (accessible !== undefined) {
          tmp.accessible = accessible;
        }
        if (adjustsFontSizeToFit !== undefined) {
          tmp.adjustsFontSizeToFit = adjustsFontSizeToFit;
        }
        if (allowFontScaling !== undefined) {
          tmp.allowFontScaling = allowFontScaling;
        }
        if (ellipsizeMode !== undefined) {
          tmp.ellipsizeMode = Caml_option.valFromOption(ellipsizeMode);
        }
        if (minimumFontScale !== undefined) {
          tmp.minimumFontScale = minimumFontScale;
        }
        if (numberOfLines !== undefined) {
          tmp.numberOfLines = numberOfLines;
        }
        if (onLayout !== undefined) {
          tmp.onLayout = Caml_option.valFromOption(onLayout);
        }
        if (onLongPress !== undefined) {
          tmp.onLongPress = Caml_option.valFromOption(onLongPress);
        }
        if (onPress !== undefined) {
          tmp.onPress = Caml_option.valFromOption(onPress);
        }
        if (pressRetentionOffset !== undefined) {
          tmp.pressRetentionOffset = Caml_option.valFromOption(pressRetentionOffset);
        }
        if (selectable !== undefined) {
          tmp.selectable = selectable;
        }
        if (selectionColor !== undefined) {
          tmp.selectionColor = selectionColor;
        }
        if (suppressHighlighting !== undefined) {
          tmp.suppressHighlighting = suppressHighlighting;
        }
        if (testID !== undefined) {
          tmp.testID = testID;
        }
        if (textBreakStrategy !== undefined) {
          tmp.textBreakStrategy = Caml_option.valFromOption(textBreakStrategy);
        }
        if (value !== undefined) {
          tmp.value = value;
        }
        if (href !== undefined) {
          tmp.href = href;
        }
        if (hrefAttrs !== undefined) {
          tmp.hrefAttrs = Caml_option.valFromOption(hrefAttrs);
        }
        if (onMouseEnter !== undefined) {
          tmp.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
        }
        if (onMouseLeave !== undefined) {
          tmp.onMouseLeave = Caml_option.valFromOption(onMouseLeave);
        }
        if (onClick !== undefined) {
          tmp.onClick = Caml_option.valFromOption(onClick);
        }
        return React.createElement(ReactNative.Text, tmp);
      }
      var tmp$2 = {
        children: Belt_Option.getWithDefault(children, null),
        style: finalStyle
      };
      var tmp$3 = Belt_Option.map((ref == null) ? undefined : Caml_option.some(ref), (function (prim) {
              return prim;
            }));
      if (tmp$3 !== undefined) {
        tmp$2.ref = Caml_option.valFromOption(tmp$3);
      }
      if (accessibilityHint !== undefined) {
        tmp$2.accessibilityHint = accessibilityHint;
      }
      if (accessibilityLabel !== undefined) {
        tmp$2.accessibilityLabel = accessibilityLabel;
      }
      if (accessibilityRole !== undefined) {
        tmp$2.accessibilityRole = Caml_option.valFromOption(accessibilityRole);
      }
      if (accessibilityState !== undefined) {
        tmp$2.accessibilityState = Caml_option.valFromOption(accessibilityState);
      }
      if (accessible !== undefined) {
        tmp$2.accessible = accessible;
      }
      if (adjustsFontSizeToFit !== undefined) {
        tmp$2.adjustsFontSizeToFit = adjustsFontSizeToFit;
      }
      if (allowFontScaling !== undefined) {
        tmp$2.allowFontScaling = allowFontScaling;
      }
      if (ellipsizeMode !== undefined) {
        tmp$2.ellipsizeMode = Caml_option.valFromOption(ellipsizeMode);
      }
      if (minimumFontScale !== undefined) {
        tmp$2.minimumFontScale = minimumFontScale;
      }
      if (numberOfLines !== undefined) {
        tmp$2.numberOfLines = numberOfLines;
      }
      if (onLayout !== undefined) {
        tmp$2.onLayout = Caml_option.valFromOption(onLayout);
      }
      if (onLongPress !== undefined) {
        tmp$2.onLongPress = Caml_option.valFromOption(onLongPress);
      }
      if (onPress !== undefined) {
        tmp$2.onPress = Caml_option.valFromOption(onPress);
      }
      if (pressRetentionOffset !== undefined) {
        tmp$2.pressRetentionOffset = Caml_option.valFromOption(pressRetentionOffset);
      }
      if (selectable !== undefined) {
        tmp$2.selectable = selectable;
      }
      if (selectionColor !== undefined) {
        tmp$2.selectionColor = selectionColor;
      }
      if (suppressHighlighting !== undefined) {
        tmp$2.suppressHighlighting = suppressHighlighting;
      }
      if (testID !== undefined) {
        tmp$2.testID = testID;
      }
      if (textBreakStrategy !== undefined) {
        tmp$2.textBreakStrategy = Caml_option.valFromOption(textBreakStrategy);
      }
      if (value !== undefined) {
        tmp$2.value = value;
      }
      return React.createElement(ReactNative.Text, tmp$2);
    });

export {
  WebText ,
  styles ,
  stylesheet ,
  typographyVariantToFont ,
  make ,
}
/* match Not a pure module */
