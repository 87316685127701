import { atob, btoa } from "@decidr-io/utils/src/base64";

export const gql = String.raw;

export const getShopifyId = (nodeId: string) => {
  const decodedId = atob(nodeId);

  return decodedId.slice(decodedId.indexOf("gid://shopify/"));
};

// TODO Shorten URL
export const makeProductVariantNodeId = (shopifyId: string) => {
  return btoa(`1;ProductVariant;${shopifyId}`);
};

export const getShopifyInternalId = (nodeId: string) => {
  const decodedId = atob(nodeId);

  return decodedId.slice(decodedId.lastIndexOf("/") + 1);
};
