// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as GroupFactory$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/group/components/GroupFactory.bs.js";

var match = Ui__Styling.describe({
      center: {
        TAG: /* Static */0,
        _0: {
          textAlign: "center"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__MultilineText(Props) {
  var testIDOpt = Props.testID;
  var textLines = Props.textLines;
  var containerStyle = Props.containerStyle;
  var color = Props.color;
  var textStyle = Props.textStyle;
  var spacing = Props.spacing;
  var sizeOpt = Props.size;
  var fontFamily = Props.fontFamily;
  var testID = testIDOpt !== undefined ? testIDOpt : "multiline-text";
  var size = sizeOpt !== undefined ? sizeOpt : "b1";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var spacing$1 = spacing !== undefined ? spacing : Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
          undefined,
          theme.spacing._6,
          undefined,
          theme.spacing._8,
          undefined,
          theme.spacing._10,
          undefined,
          theme.spacing._4,
          undefined
        ]);
  var tmp = {
    testID: testID,
    children: Belt_Array.mapWithIndex(textLines, (function (index, text) {
            return React.createElement(React.Fragment, {
                        children: null,
                        key: "multiline " + String(index) + ""
                      }, React.createElement(Ui__MarkotText.make, {
                            fontFamily: Belt_Option.getWithDefault(fontFamily, theme.fontFamily.dm),
                            size: size,
                            color: Belt_Option.getWithDefault(color, theme.colors.onPrimaryShade4),
                            children: text,
                            style: [
                              Caml_option.some(Curry._1(styled, styles.center)),
                              textStyle
                            ],
                            testID: "text-line"
                          }), SimpleReact$DecidrIoUtils.whenTrue(index !== (textLines.length - 1 | 0) && spacing$1 !== 0, React.createElement(GroupFactory$DecidrIoUniversalComponents.Spacer.make, {
                                size: spacing$1
                              })));
          }))
  };
  if (containerStyle !== undefined) {
    tmp.style = Caml_option.valFromOption(containerStyle);
  }
  return React.createElement(ReactNative.View, tmp);
}

var make = Ui__MultilineText;

export {
  make ,
}
/* match Not a pure module */
