// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as ShopifyApi__Id from "../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as MarkotApi__Infra from "./MarkotApi__Infra.bs.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";
import * as MarkotApi__Listing from "./MarkotApi__Listing.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as MarkotApi__CommonFragments from "./MarkotApi__CommonFragments.bs.js";
import * as MarkotApi__PrelovedItemMedia from "./MarkotApi__PrelovedItemMedia.bs.js";
import * as MarkotApi__Scalars__DateTime from "./MarkotApi__Scalars__DateTime.bs.js";

var query = "fragment DraftListingFields on DraftListing   {\nid  \nupdatedAt  \ncondition  \ndescription  \nproductId: productId3  \nmainImage  {\n...ImageResourceFields   \n}\n\nmedia @include(if: $loadMedia) {\n...PrelovedItemMediaFields   \n}\n\npricing  {\n__typename\n...on SellUsingFixedPriceSetting   {\nprice  {\n...MoneyFields   \n}\n\n}\n\n...on SellUsingAuctionSetting   {\npricing  {\n__typename\n...on WithBuyout   {\nbuyNowPrice  {\n...MoneyFields   \n}\n\nminBidLimit  {\n__typename\n...on MinAmount   {\namount  {\n...MoneyFields   \n}\n\n}\n\n...on MinPercentageOfBuyNowPrice   {\namount  {\n...MoneyFields   \n}\n\npercent  \n}\n\n}\n\n}\n\n...on WithoutBuyout   {\nminBidAmount  \n}\n\n}\n\n}\n\n}\n\npickupAddressId  \nbillingAddressId  \nusedForTesting  \nproduct2  {\n__typename\n...on ProductInfo   {\nproductId  \n}\n\n...on SuggestedProductInfo   {\nproductName  \nbrandName  \n}\n\n}\n\n}\n" + MarkotApi__CommonFragments.ImageResourceFields.query + MarkotApi__CommonFragments.MoneyFields.query + MarkotApi__PrelovedItemMedia.PrelovedItemMediaFields.query;

function parse(value) {
  var value$1 = value.condition;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var tmp$1;
    switch (value$1) {
      case "AsGoodAsNew" :
          tmp$1 = "AsGoodAsNew";
          break;
      case "Good" :
          tmp$1 = "Good";
          break;
      case "NeverUsedWithPackaging" :
          tmp$1 = "NeverUsedWithPackaging";
          break;
      case "NeverUsedWithoutPackaging" :
          tmp$1 = "NeverUsedWithoutPackaging";
          break;
      case "WellLoved" :
          tmp$1 = "WellLoved";
          break;
      default:
        tmp$1 = {
          NAME: "FutureAddedValue",
          VAL: value$1
        };
    }
    tmp = tmp$1;
  }
  var value$2 = value.description;
  var value$3 = value.productId;
  var value$4 = value.mainImage;
  var value$5 = value.media;
  var value$6 = value.pricing;
  var tmp$2;
  if (value$6 == null) {
    tmp$2 = undefined;
  } else {
    var typename = value$6["__typename"];
    var tmp$3;
    switch (typename) {
      case "SellUsingAuctionSetting" :
          var value$7 = value$6.pricing;
          var typename$1 = value$7["__typename"];
          var tmp$4;
          switch (typename$1) {
            case "WithBuyout" :
                var value$8 = value$7.buyNowPrice;
                var value$9 = value$7.minBidLimit;
                var typename$2 = value$9["__typename"];
                var tmp$5;
                switch (typename$2) {
                  case "MinAmount" :
                      var value$10 = value$9.amount;
                      tmp$5 = {
                        NAME: "MinAmount",
                        VAL: {
                          amount: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$10)
                        }
                      };
                      break;
                  case "MinPercentageOfBuyNowPrice" :
                      var value$11 = value$9.amount;
                      tmp$5 = {
                        NAME: "MinPercentageOfBuyNowPrice",
                        VAL: {
                          amount: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$11),
                          percent: value$9.percent
                        }
                      };
                      break;
                  default:
                    tmp$5 = {
                      NAME: "FutureAddedValue",
                      VAL: value$9
                    };
                }
                tmp$4 = {
                  NAME: "WithBuyout",
                  VAL: {
                    buyNowPrice: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$8),
                    minBidLimit: tmp$5
                  }
                };
                break;
            case "WithoutBuyout" :
                tmp$4 = {
                  NAME: "WithoutBuyout",
                  VAL: {
                    minBidAmount: value$7.minBidAmount
                  }
                };
                break;
            default:
              tmp$4 = {
                NAME: "FutureAddedValue",
                VAL: value$7
              };
          }
          tmp$3 = {
            NAME: "SellUsingAuctionSetting",
            VAL: {
              pricing: tmp$4
            }
          };
          break;
      case "SellUsingFixedPriceSetting" :
          var value$12 = value$6.price;
          tmp$3 = {
            NAME: "SellUsingFixedPriceSetting",
            VAL: {
              price: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$12)
            }
          };
          break;
      default:
        tmp$3 = {
          NAME: "FutureAddedValue",
          VAL: value$6
        };
    }
    tmp$2 = tmp$3;
  }
  var value$13 = value.pickupAddressId;
  var value$14 = value.billingAddressId;
  var value$15 = value.product2;
  var tmp$6;
  if (value$15 == null) {
    tmp$6 = undefined;
  } else {
    var typename$3 = value$15["__typename"];
    var tmp$7;
    switch (typename$3) {
      case "ProductInfo" :
          tmp$7 = {
            NAME: "ProductInfo",
            VAL: {
              productId: value$15.productId
            }
          };
          break;
      case "SuggestedProductInfo" :
          tmp$7 = {
            NAME: "SuggestedProductInfo",
            VAL: {
              productName: value$15.productName,
              brandName: value$15.brandName
            }
          };
          break;
      default:
        tmp$7 = {
          NAME: "FutureAddedValue",
          VAL: value$15
        };
    }
    tmp$6 = tmp$7;
  }
  return {
          id: value.id,
          updatedAt: MarkotApi__Scalars__DateTime.parse(value.updatedAt),
          condition: tmp,
          description: !(value$2 == null) ? value$2 : undefined,
          productId: !(value$3 == null) ? value$3 : undefined,
          mainImage: !(value$4 == null) ? MarkotApi__CommonFragments.ImageResourceFields.verifyArgsAndParse("ImageResourceFields", value$4) : undefined,
          media: !(value$5 == null) ? Js_array.map((function (value) {
                    return MarkotApi__PrelovedItemMedia.PrelovedItemMediaFields.verifyArgsAndParse("PrelovedItemMediaFields", value);
                  }), value$5) : undefined,
          pricing: tmp$2,
          pickupAddressId: !(value$13 == null) ? value$13 : undefined,
          billingAddressId: !(value$14 == null) ? value$14 : undefined,
          usedForTesting: value.usedForTesting,
          product2: tmp$6
        };
}

function serialize(value) {
  var value$1 = value.product2;
  var product2;
  if (value$1 !== undefined) {
    var variant = value$1.NAME;
    if (variant === "SuggestedProductInfo") {
      var value$2 = value$1.VAL;
      var value$3 = value$2.brandName;
      var value$4 = value$2.productName;
      product2 = {
        __typename: "SuggestedProductInfo",
        productName: value$4,
        brandName: value$3
      };
    } else if (variant === "FutureAddedValue") {
      product2 = value$1.VAL;
    } else {
      var value$5 = value$1.VAL.productId;
      product2 = {
        __typename: "ProductInfo",
        productId: value$5
      };
    }
  } else {
    product2 = null;
  }
  var value$6 = value.usedForTesting;
  var value$7 = value.billingAddressId;
  var billingAddressId = value$7 !== undefined ? value$7 : null;
  var value$8 = value.pickupAddressId;
  var pickupAddressId = value$8 !== undefined ? value$8 : null;
  var value$9 = value.pricing;
  var pricing;
  if (value$9 !== undefined) {
    var variant$1 = value$9.NAME;
    if (variant$1 === "SellUsingFixedPriceSetting") {
      var value$10 = value$9.VAL.price;
      var price = MarkotApi__CommonFragments.MoneyFields.serialize(value$10);
      pricing = {
        __typename: "SellUsingFixedPriceSetting",
        price: price
      };
    } else if (variant$1 === "FutureAddedValue") {
      pricing = value$9.VAL;
    } else {
      var value$11 = value$9.VAL.pricing;
      var variant$2 = value$11.NAME;
      var pricing$1;
      if (variant$2 === "WithoutBuyout") {
        var value$12 = value$11.VAL.minBidAmount;
        pricing$1 = {
          __typename: "WithoutBuyout",
          minBidAmount: value$12
        };
      } else if (variant$2 === "WithBuyout") {
        var value$13 = value$11.VAL;
        var value$14 = value$13.minBidLimit;
        var variant$3 = value$14.NAME;
        var minBidLimit;
        if (variant$3 === "MinPercentageOfBuyNowPrice") {
          var value$15 = value$14.VAL;
          var value$16 = value$15.percent;
          var value$17 = value$15.amount;
          var amount = MarkotApi__CommonFragments.MoneyFields.serialize(value$17);
          minBidLimit = {
            __typename: "MinPercentageOfBuyNowPrice",
            amount: amount,
            percent: value$16
          };
        } else if (variant$3 === "FutureAddedValue") {
          minBidLimit = value$14.VAL;
        } else {
          var value$18 = value$14.VAL.amount;
          var amount$1 = MarkotApi__CommonFragments.MoneyFields.serialize(value$18);
          minBidLimit = {
            __typename: "MinAmount",
            amount: amount$1
          };
        }
        var value$19 = value$13.buyNowPrice;
        var buyNowPrice = MarkotApi__CommonFragments.MoneyFields.serialize(value$19);
        pricing$1 = {
          __typename: "WithBuyout",
          buyNowPrice: buyNowPrice,
          minBidLimit: minBidLimit
        };
      } else {
        pricing$1 = value$11.VAL;
      }
      pricing = {
        __typename: "SellUsingAuctionSetting",
        pricing: pricing$1
      };
    }
  } else {
    pricing = null;
  }
  var value$20 = value.media;
  var media = value$20 !== undefined ? Js_array.map((function (value) {
            return MarkotApi__PrelovedItemMedia.PrelovedItemMediaFields.serialize(value);
          }), value$20) : null;
  var value$21 = value.mainImage;
  var mainImage = value$21 !== undefined ? MarkotApi__CommonFragments.ImageResourceFields.serialize(value$21) : null;
  var value$22 = value.productId;
  var productId = value$22 !== undefined ? value$22 : null;
  var value$23 = value.description;
  var description = value$23 !== undefined ? value$23 : null;
  var value$24 = value.condition;
  var condition = value$24 !== undefined ? (
      typeof value$24 === "object" ? value$24.VAL : (
          value$24 === "NeverUsedWithoutPackaging" ? "NeverUsedWithoutPackaging" : (
              value$24 === "WellLoved" ? "WellLoved" : (
                  value$24 === "AsGoodAsNew" ? "AsGoodAsNew" : (
                      value$24 === "Good" ? "Good" : "NeverUsedWithPackaging"
                    )
                )
            )
        )
    ) : null;
  var value$25 = value.updatedAt;
  var value$26 = MarkotApi__Scalars__DateTime.serialize(value$25);
  var value$27 = value.id;
  return {
          id: value$27,
          updatedAt: value$26,
          condition: condition,
          description: description,
          productId: productId,
          mainImage: mainImage,
          media: media,
          pricing: pricing,
          pickupAddressId: pickupAddressId,
          billingAddressId: billingAddressId,
          usedForTesting: value$6,
          product2: product2
        };
}

var Raw = {};

var query$1 = "query DraftListingBasicData($id: ID!, $loadMedia: Boolean!)  {\nnode(id: $id)  {\n__typename\n...on DraftListing   {\n...DraftListingFields   \n}\n\n}\n\n}\n" + query;

function parse$1(value) {
  var value$1 = value.node;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1 = typename === "DraftListing" ? ({
          NAME: "DraftListing",
          VAL: {
            draftListingFields: parse(value$1)
          }
        }) : ({
          NAME: "UnspecifiedFragment",
          VAL: typename
        });
    tmp = tmp$1;
  }
  return {
          node: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.node;
  var node = value$1 !== undefined ? (
      value$1.NAME === "UnspecifiedFragment" ? ({}) : Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                return {};
              }), [serialize(value$1.VAL.draftListingFields)])
    ) : null;
  return {
          node: node
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id,
          loadMedia: inp.loadMedia
        };
}

var Raw$1 = {};

var query$2 = "query DraftListings($first: Int, $after: String, $loadMedia: Boolean!)  {\nviewer  {\ndraftListings(first: $first, after: $after)  {\ntotalItems  \npageInfo  {\nstartCursor  \nendCursor  \nhasPreviousPage  \nhasNextPage  \n}\n\nedges  {\ncursor  \nnode  {\n...DraftListingFields   \n}\n\n}\n\n}\n\n}\n\n}\n" + query;

function parse$2(value) {
  var value$1 = value.viewer;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.draftListings;
    var value$3 = value$2.pageInfo;
    var value$4 = value$3.startCursor;
    var value$5 = value$3.endCursor;
    var value$6 = value$2.edges;
    tmp = {
      draftListings: {
        totalItems: value$2.totalItems,
        pageInfo: {
          startCursor: !(value$4 == null) ? value$4 : undefined,
          endCursor: !(value$5 == null) ? value$5 : undefined,
          hasPreviousPage: value$3.hasPreviousPage,
          hasNextPage: value$3.hasNextPage
        },
        edges: Js_array.map((function (value) {
                return {
                        cursor: value.cursor,
                        node: parse(value.node)
                      };
              }), value$6)
      }
    };
  }
  return {
          viewer: tmp
        };
}

function serialize$2(value) {
  var value$1 = value.viewer;
  var viewer;
  if (value$1 !== undefined) {
    var value$2 = value$1.draftListings;
    var value$3 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var node = serialize(value$1);
            var value$2 = value.cursor;
            return {
                    cursor: value$2,
                    node: node
                  };
          }), value$3);
    var value$4 = value$2.pageInfo;
    var value$5 = value$4.hasNextPage;
    var value$6 = value$4.hasPreviousPage;
    var value$7 = value$4.endCursor;
    var endCursor = value$7 !== undefined ? value$7 : null;
    var value$8 = value$4.startCursor;
    var startCursor = value$8 !== undefined ? value$8 : null;
    var pageInfo = {
      startCursor: startCursor,
      endCursor: endCursor,
      hasPreviousPage: value$6,
      hasNextPage: value$5
    };
    var value$9 = value$2.totalItems;
    var draftListings = {
      totalItems: value$9,
      pageInfo: pageInfo,
      edges: edges
    };
    viewer = {
      draftListings: draftListings
    };
  } else {
    viewer = null;
  }
  return {
          viewer: viewer
        };
}

function serializeVariables$1(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  return {
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined,
          loadMedia: inp.loadMedia
        };
}

var Raw$2 = {};

var query$3 = "mutation CreateDraftListing($input: CreateDraftListingInput!, $loadMedia: Boolean!)  {\ncreateDraftListing(input: $input)  {\ndraftListing  {\n...DraftListingFields   \n}\n\n}\n\n}\n" + query;

function parse$3(value) {
  var value$1 = value.createDraftListing;
  return {
          createDraftListing: {
            draftListing: parse(value$1.draftListing)
          }
        };
}

function serialize$3(value) {
  var value$1 = value.createDraftListing;
  var value$2 = value$1.draftListing;
  var draftListing = serialize(value$2);
  var createDraftListing = {
    draftListing: draftListing
  };
  return {
          createDraftListing: createDraftListing
        };
}

function serializeInputObjectCreateDraftListingInput(inp) {
  return {
          productId: inp.productId,
          productSlug: inp.productSlug
        };
}

function serializeVariables$2(inp) {
  return {
          input: serializeInputObjectCreateDraftListingInput(inp.input),
          loadMedia: inp.loadMedia
        };
}

var Raw$3 = {};

var query$4 = "mutation CreateDraftListing2($input: CreateDraftListing2Input!, $loadMedia: Boolean!)  {\ncreateDraftListing2(input: $input)  {\ndraftListing  {\n...DraftListingFields   \n}\n\n}\n\n}\n" + query;

function parse$4(value) {
  var value$1 = value.createDraftListing2;
  return {
          createDraftListing2: {
            draftListing: parse(value$1.draftListing)
          }
        };
}

function serialize$4(value) {
  var value$1 = value.createDraftListing2;
  var value$2 = value$1.draftListing;
  var draftListing = serialize(value$2);
  var createDraftListing2 = {
    draftListing: draftListing
  };
  return {
          createDraftListing2: createDraftListing2
        };
}

function serializeInputObjectCreateDraftListingSuggestedProductInput(inp) {
  return {
          productName: inp.productName,
          brandName: inp.brandName
        };
}

function serializeInputObjectCreateDraftListingProductInput(inp) {
  return {
          id: inp.id,
          slug: inp.slug
        };
}

function serializeInputObjectCreateDraftListing2Input(inp) {
  var a = inp.product;
  var a$1 = inp.suggestedProduct;
  return {
          product: a !== undefined ? serializeInputObjectCreateDraftListingProductInput(a) : undefined,
          suggestedProduct: a$1 !== undefined ? serializeInputObjectCreateDraftListingSuggestedProductInput(a$1) : undefined
        };
}

function serializeVariables$3(inp) {
  return {
          input: serializeInputObjectCreateDraftListing2Input(inp.input),
          loadMedia: inp.loadMedia
        };
}

var Raw$4 = {};

var query$5 = "mutation UpdateDraftListing($input: UpdateDraftListingInput!, $loadMedia: Boolean!)  {\nupdateDraftListing(input: $input)  {\ndraftListing  {\n...DraftListingFields   \n}\n\n}\n\n}\n" + query;

function parse$5(value) {
  var value$1 = value.updateDraftListing;
  return {
          updateDraftListing: {
            draftListing: parse(value$1.draftListing)
          }
        };
}

function serialize$5(value) {
  var value$1 = value.updateDraftListing;
  var value$2 = value$1.draftListing;
  var draftListing = serialize(value$2);
  var updateDraftListing = {
    draftListing: draftListing
  };
  return {
          updateDraftListing: updateDraftListing
        };
}

function serializeInputObjectUpdateDraftListingInput(inp) {
  var a = inp.buyNowPrice;
  var a$1 = inp.condition;
  var a$2 = inp.listingAndHandlingFeeId;
  var a$3 = inp.description;
  var a$4 = inp.pickupAddressId;
  var a$5 = inp.billingAddressId;
  return {
          id: inp.id,
          buyNowPrice: a !== undefined ? a : undefined,
          condition: a$1 !== undefined ? (
              a$1 === "NeverUsedWithoutPackaging" ? "NeverUsedWithoutPackaging" : (
                  a$1 === "WellLoved" ? "WellLoved" : (
                      a$1 === "AsGoodAsNew" ? "AsGoodAsNew" : (
                          a$1 === "Good" ? "Good" : "NeverUsedWithPackaging"
                        )
                    )
                )
            ) : undefined,
          listingAndHandlingFeeId: a$2 !== undefined ? a$2 : undefined,
          description: a$3 !== undefined ? a$3 : undefined,
          pickupAddressId: a$4 !== undefined ? a$4 : undefined,
          billingAddressId: a$5 !== undefined ? a$5 : undefined
        };
}

function serializeVariables$4(inp) {
  return {
          input: serializeInputObjectUpdateDraftListingInput(inp.input),
          loadMedia: inp.loadMedia
        };
}

var Raw$5 = {};

function parse$6(value) {
  var value$1 = value.deleteDraftListing;
  return {
          deleteDraftListing: {
            deletedDraftListingId: value$1.deletedDraftListingId
          }
        };
}

function serialize$6(value) {
  var value$1 = value.deleteDraftListing;
  var value$2 = value$1.deletedDraftListingId;
  var deleteDraftListing = {
    deletedDraftListingId: value$2
  };
  return {
          deleteDraftListing: deleteDraftListing
        };
}

function serializeVariables$5(inp) {
  return {
          id: inp.id
        };
}

var Raw$6 = {};

var query$6 = "mutation UploadDraftListingMedia($draftListingId: ID!, $mediaFileInBase64: String!, $mediaTag: String, $loadMedia: Boolean!)  {\nuploadDraftListingMedia(draftListingId: $draftListingId, mediaFileInBase64: $mediaFileInBase64, mediaTag: $mediaTag)  {\nmedia  {\n...PrelovedItemMediaFields   \n}\n\ndraftListing  {\n...DraftListingFields   \n}\n\n}\n\n}\n" + query + MarkotApi__PrelovedItemMedia.PrelovedItemMediaFields.query;

function parse$7(value) {
  var value$1 = value.uploadDraftListingMedia;
  var value$2 = value$1.media;
  return {
          uploadDraftListingMedia: {
            media: MarkotApi__PrelovedItemMedia.PrelovedItemMediaFields.verifyArgsAndParse("PrelovedItemMediaFields", value$2),
            draftListing: parse(value$1.draftListing)
          }
        };
}

function serialize$7(value) {
  var value$1 = value.uploadDraftListingMedia;
  var value$2 = value$1.draftListing;
  var draftListing = serialize(value$2);
  var value$3 = value$1.media;
  var media = MarkotApi__PrelovedItemMedia.PrelovedItemMediaFields.serialize(value$3);
  var uploadDraftListingMedia = {
    media: media,
    draftListing: draftListing
  };
  return {
          uploadDraftListingMedia: uploadDraftListingMedia
        };
}

function serializeVariables$6(inp) {
  var a = inp.mediaTag;
  return {
          draftListingId: inp.draftListingId,
          mediaFileInBase64: inp.mediaFileInBase64,
          mediaTag: a !== undefined ? a : undefined,
          loadMedia: inp.loadMedia
        };
}

function makeVariables(draftListingId, mediaFileInBase64, mediaTag, loadMedia, param) {
  return {
          draftListingId: draftListingId,
          mediaFileInBase64: mediaFileInBase64,
          mediaTag: mediaTag,
          loadMedia: loadMedia
        };
}

var Raw$7 = {};

function parse$8(value) {
  var value$1 = value.deleteDraftListingMedia;
  return {
          deleteDraftListingMedia: {
            mediaId: value$1.mediaId
          }
        };
}

function serialize$8(value) {
  var value$1 = value.deleteDraftListingMedia;
  var value$2 = value$1.mediaId;
  var deleteDraftListingMedia = {
    mediaId: value$2
  };
  return {
          deleteDraftListingMedia: deleteDraftListingMedia
        };
}

function serializeVariables$7(inp) {
  return {
          id: inp.id
        };
}

var Raw$8 = {};

var query$7 = "mutation SubmitDraftListingForApproval($id: ID!)  {\nsubmitDraftListingForApproval(draftListingId: $id)  {\n...ListingFields   \n}\n\n}\n" + MarkotApi__Listing.ListingFields.query;

function parse$9(value) {
  var value$1 = value.submitDraftListingForApproval;
  return {
          submitDraftListingForApproval: MarkotApi__Listing.ListingFields.verifyArgsAndParse("ListingFields", value$1)
        };
}

function serialize$9(value) {
  var value$1 = value.submitDraftListingForApproval;
  var submitDraftListingForApproval = MarkotApi__Listing.ListingFields.serialize(value$1);
  return {
          submitDraftListingForApproval: submitDraftListingForApproval
        };
}

function serializeVariables$8(inp) {
  return {
          id: inp.id
        };
}

var Raw$9 = {};

var query$8 = "query DraftListingMedia($draftListingId: ID!)  {\nnode(id: $draftListingId)  {\n__typename\n...on DraftListing   {\nmedia  {\n...PrelovedItemMediaFields   \n}\n\n}\n\n}\n\n}\n" + MarkotApi__PrelovedItemMedia.PrelovedItemMediaFields.query;

function parse$10(value) {
  var value$1 = value.node;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1;
    if (typename === "DraftListing") {
      var value$2 = value$1.media;
      tmp$1 = {
        NAME: "DraftListing",
        VAL: {
          media: Js_array.map((function (value) {
                  return MarkotApi__PrelovedItemMedia.PrelovedItemMediaFields.verifyArgsAndParse("PrelovedItemMediaFields", value);
                }), value$2)
        }
      };
    } else {
      tmp$1 = {
        NAME: "UnspecifiedFragment",
        VAL: typename
      };
    }
    tmp = tmp$1;
  }
  return {
          node: tmp
        };
}

function serialize$10(value) {
  var value$1 = value.node;
  var node;
  if (value$1 !== undefined) {
    if (value$1.NAME === "UnspecifiedFragment") {
      node = {};
    } else {
      var value$2 = value$1.VAL.media;
      var media = Js_array.map((function (value) {
              return MarkotApi__PrelovedItemMedia.PrelovedItemMediaFields.serialize(value);
            }), value$2);
      node = {
        __typename: "DraftListing",
        media: media
      };
    }
  } else {
    node = null;
  }
  return {
          node: node
        };
}

function serializeVariables$9(inp) {
  return {
          draftListingId: inp.draftListingId
        };
}

function makeDraftListing(param) {
  var product2 = param.product2;
  return {
          id: MarkotApi__Id.parseExn(param.id),
          updatedAt: param.updatedAt,
          productId: Belt_Option.map(param.productId, ShopifyApi__Id.parseExn),
          description: param.description,
          condition: Belt_Option.map(param.condition, (function (condition) {
                  if (typeof condition === "object") {
                    return Js_exn.raiseError("PrelovedItem.condition unknown: " + condition.VAL);
                  } else if (condition === "NeverUsedWithoutPackaging") {
                    return "NeverUsedWithoutPackaging";
                  } else if (condition === "WellLoved") {
                    return "WellLoved";
                  } else if (condition === "AsGoodAsNew") {
                    return "AsGoodAsNew";
                  } else if (condition === "Good") {
                    return "Good";
                  } else {
                    return "NeverUsedWithPackaging";
                  }
                })),
          mainImage: Belt_Option.map(param.mainImage, MarkotApi__CommonFragments.makeImageResource),
          media: Belt_Option.map(param.media, (function (__x) {
                  return Belt_Array.map(__x, MarkotApi__PrelovedItemMedia.makePrelovedItemMedia);
                })),
          pricing: Belt_Option.map(param.pricing, (function (pricing) {
                  var variant = pricing.NAME;
                  if (variant === "SellUsingFixedPriceSetting") {
                    return {
                            TAG: /* SellUsingFixedPriceSetting */0,
                            price: MarkotApi__CommonFragments.makeMoney(pricing.VAL.price)
                          };
                  }
                  if (variant === "FutureAddedValue") {
                    RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "DraftListing makeDraftListing - DraftListing.pricing unknown typename", pricing);
                    return Js_exn.raiseError("DraftListing.pricing unknown typename.");
                  }
                  var match = pricing.VAL.pricing;
                  var variant$1 = match.NAME;
                  if (variant$1 === "WithoutBuyout") {
                    return {
                            TAG: /* SellUsingAuctionSetting */1,
                            pricing: {
                              TAG: /* WithoutBuyout */1,
                              minBidAmount: match.VAL.minBidAmount
                            }
                          };
                  }
                  if (variant$1 === "WithBuyout") {
                    var match$1 = match.VAL;
                    var match$2 = match$1.minBidLimit;
                    var variant$2 = match$2.NAME;
                    var buyNowPrice = match$1.buyNowPrice;
                    if (variant$2 !== "MinPercentageOfBuyNowPrice") {
                      if (variant$2 === "FutureAddedValue") {
                        RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "DraftListing makeDraftListing - DraftListing.pricing.withBuyout.minBidLimit unknown typename", pricing);
                        return Js_exn.raiseError("DraftListing.pricing.withBuyout.minBidLimit unknown typename.");
                      } else {
                        return {
                                TAG: /* SellUsingAuctionSetting */1,
                                pricing: {
                                  TAG: /* WithBuyout */0,
                                  buyNowPrice: MarkotApi__CommonFragments.makeMoney(buyNowPrice),
                                  minBidLimit: {
                                    TAG: /* MinAmount */0,
                                    amount: MarkotApi__CommonFragments.makeMoney(match$2.VAL.amount)
                                  }
                                }
                              };
                      }
                    }
                    var match$3 = match$2.VAL;
                    return {
                            TAG: /* SellUsingAuctionSetting */1,
                            pricing: {
                              TAG: /* WithBuyout */0,
                              buyNowPrice: MarkotApi__CommonFragments.makeMoney(buyNowPrice),
                              minBidLimit: {
                                TAG: /* MinPercentageOfBuyNowPrice */1,
                                percent: match$3.percent,
                                amount: MarkotApi__CommonFragments.makeMoney(match$3.amount)
                              }
                            }
                          };
                  }
                  RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "DraftListing makeDraftListing - DraftListing.SellUsingAuctionSetting.pricing unknown typename", pricing);
                  return Js_exn.raiseError("DraftListing.SellUsingAuctionSetting.pricing unknown typename.");
                })),
          billingAddressId: Belt_Option.map(param.billingAddressId, MarkotApi__Id.parseExn),
          pickupAddressId: Belt_Option.map(param.pickupAddressId, MarkotApi__Id.parseExn),
          usedForTesting: param.usedForTesting,
          product2: Belt_Option.flatMap(product2, (function (product) {
                  var variant = product.NAME;
                  if (variant !== "SuggestedProductInfo") {
                    if (variant === "FutureAddedValue") {
                      RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "DraftListing makeDraftListing - DraftListing.product2 unknown typename", product2);
                      return ;
                    } else {
                      return {
                              NAME: "ProductInfo",
                              VAL: {
                                productId: MarkotApi__Id.parseExn(product.VAL.productId)
                              }
                            };
                    }
                  }
                  var match = product.VAL;
                  return {
                          NAME: "SuggestedProductInfo",
                          VAL: {
                            productName: match.productName,
                            brandName: match.brandName
                          }
                        };
                }))
        };
}

function fetchBasicData(apiConfig, id, loadMedia) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$1,
                    Raw: Raw,
                    parse: parse$1,
                    serialize: serialize$1,
                    serializeVariables: serializeVariables
                  }), {
                id: id,
                loadMedia: loadMedia
              }, undefined).then(function (param) {
              var node = param.node;
              if (node !== undefined) {
                if (node.NAME === "UnspecifiedFragment") {
                  RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "DraftListing fetchBasicData - node of unknown type found. Ignoring.", node);
                  return ;
                } else {
                  return makeDraftListing(node.VAL.draftListingFields);
                }
              } else {
                RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "DraftListing fetchBasicData - not found. Id", id);
                return ;
              }
            });
}

function fetchDraftListings(apiConfig, first, after, loadMedia) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                          query: query$2,
                          Raw: Raw$1,
                          parse: parse$2,
                          serialize: serialize$2,
                          serializeVariables: serializeVariables$1
                        }), {
                      first: first,
                      after: after,
                      loadMedia: loadMedia
                    }, undefined).then(function (param) {
                    return param.viewer;
                  }).then(function (viewer) {
                  return RescriptUtils__OptionPlus.tapNone(viewer, (function (param) {
                                RescriptUtils__Logger.warn(MarkotApi__Infra.logger, "Viewer not found when getting draftListings");
                              }));
                }).then(function (__x) {
                return Belt_Option.map(__x, (function (param) {
                              var match = param.draftListings;
                              var pageInfo = match.pageInfo;
                              return {
                                      totalItems: match.totalItems,
                                      pageInfo: {
                                        startCursor: pageInfo.startCursor,
                                        endCursor: pageInfo.endCursor,
                                        hasNextPage: pageInfo.hasNextPage,
                                        hasPreviousPage: pageInfo.hasPreviousPage
                                      },
                                      edges: Belt_Array.map(match.edges, (function (param) {
                                              return {
                                                      cursor: param.cursor,
                                                      node: makeDraftListing(param.node)
                                                    };
                                            }))
                                    };
                            }));
              }).then(function (__x) {
              return Belt_Option.getWithDefault(__x, {
                          totalItems: 0,
                          pageInfo: {
                            startCursor: undefined,
                            endCursor: undefined,
                            hasNextPage: false,
                            hasPreviousPage: false
                          },
                          edges: []
                        });
            });
}

function executeCreateDraftListing(apiConfig, param) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$3,
                    Raw: Raw$2,
                    parse: parse$3,
                    serialize: serialize$3,
                    serializeVariables: serializeVariables$2
                  }), {
                input: {
                  productId: MarkotApi__Id.toString(param.productId),
                  productSlug: param.productSlug
                },
                loadMedia: true
              }, undefined).then(function (param) {
              return {
                      draftListing: makeDraftListing(param.createDraftListing.draftListing)
                    };
            });
}

function executeCreateDraftListing2(apiConfig, param) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$4,
                    Raw: Raw$3,
                    parse: parse$4,
                    serialize: serialize$4,
                    serializeVariables: serializeVariables$3
                  }), {
                input: {
                  product: Belt_Option.map(param.product, (function (param) {
                          return {
                                  id: MarkotApi__Id.toString(param.productId),
                                  slug: param.productSlug
                                };
                        })),
                  suggestedProduct: Belt_Option.map(param.suggestedProduct, (function (param) {
                          return {
                                  productName: param.productName,
                                  brandName: param.brandName
                                };
                        }))
                },
                loadMedia: true
              }, undefined).then(function (param) {
              return {
                      draftListing: makeDraftListing(param.createDraftListing2.draftListing)
                    };
            });
}

function executeUpdateDraftListing(apiConfig, param) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$5,
                    Raw: Raw$4,
                    parse: parse$5,
                    serialize: serialize$5,
                    serializeVariables: serializeVariables$4
                  }), {
                input: {
                  id: MarkotApi__Id.toString(param.id),
                  buyNowPrice: param.buyNowPrice,
                  condition: param.condition,
                  listingAndHandlingFeeId: undefined,
                  description: param.description,
                  pickupAddressId: Belt_Option.map(param.pickupAddressId, MarkotApi__Id.toString),
                  billingAddressId: Belt_Option.map(param.billingAddressId, MarkotApi__Id.toString)
                },
                loadMedia: false
              }, undefined).then(function (param) {
              return {
                      draftListing: makeDraftListing(param.updateDraftListing.draftListing)
                    };
            });
}

function executeDeleteDraftListing(apiConfig, id) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: "mutation DeleteDraftListing($id: ID!)  {\ndeleteDraftListing(id: $id)  {\ndeletedDraftListingId  \n}\n\n}\n",
                    Raw: Raw$5,
                    parse: parse$6,
                    serialize: serialize$6,
                    serializeVariables: serializeVariables$5
                  }), {
                id: MarkotApi__Id.toString(id)
              }, undefined).then(function (param) {
              return {
                      deletedDraftListingId: MarkotApi__Id.parseExn(param.deleteDraftListing.deletedDraftListingId)
                    };
            });
}

function executeUploadDraftListingMedia(apiConfig, draftListingId, mediaFileInBase64, mediaTag) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$6,
                    Raw: Raw$6,
                    parse: parse$7,
                    serialize: serialize$7,
                    serializeVariables: serializeVariables$6
                  }), makeVariables(MarkotApi__Id.toString(draftListingId), mediaFileInBase64, mediaTag, true, undefined), undefined).then(function (param) {
              var match = param.uploadDraftListingMedia;
              return {
                      media: MarkotApi__PrelovedItemMedia.makePrelovedItemMedia(match.media),
                      draftListing: makeDraftListing(match.draftListing)
                    };
            });
}

function executeDeleteDraftListingMedia(apiConfig, id) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: "mutation DeleteDraftListingMedia($id: ID!)  {\ndeleteDraftListingMedia(mediaId: $id)  {\nmediaId  \n}\n\n}\n",
                    Raw: Raw$7,
                    parse: parse$8,
                    serialize: serialize$8,
                    serializeVariables: serializeVariables$7
                  }), {
                id: MarkotApi__Id.toString(id)
              }, undefined).then(function (param) {
              return {
                      mediaId: MarkotApi__Id.parseExn(param.deleteDraftListingMedia.mediaId)
                    };
            });
}

function executeSubmitDraftListingForApproval(apiConfig, id) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$7,
                    Raw: Raw$8,
                    parse: parse$9,
                    serialize: serialize$9,
                    serializeVariables: serializeVariables$8
                  }), {
                id: MarkotApi__Id.toString(id)
              }, undefined).then(function (param) {
              return MarkotApi__Listing.makeListing(param.submitDraftListingForApproval);
            });
}

function fetchPrelovedItemMediaByDraftListingId(apiConfig, draftListingId) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$8,
                    Raw: Raw$9,
                    parse: parse$10,
                    serialize: serialize$10,
                    serializeVariables: serializeVariables$9
                  }), {
                draftListingId: MarkotApi__Id.toString(draftListingId)
              }, undefined).then(function (param) {
              var node = param.node;
              if (node !== undefined) {
                if (node.NAME === "UnspecifiedFragment") {
                  RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "PrelovedItemMedia fetchPrelovedItemMediaByDraftListingId - node of unknown type found. Ignoring.", node);
                  return ;
                } else {
                  return Belt_Array.map(node.VAL.media, MarkotApi__PrelovedItemMedia.makePrelovedItemMedia);
                }
              } else {
                RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "PrelovedItemMedia fetchPrelovedItemMediaByDraftListingId - not found. Draft listing Id", draftListingId);
                return ;
              }
            });
}

export {
  fetchBasicData ,
  fetchDraftListings ,
  executeCreateDraftListing ,
  executeCreateDraftListing2 ,
  executeUpdateDraftListing ,
  executeDeleteDraftListing ,
  executeUploadDraftListingMedia ,
  executeDeleteDraftListingMedia ,
  executeSubmitDraftListingForApproval ,
  fetchPrelovedItemMediaByDraftListingId ,
}
/* MarkotApi__Id Not a pure module */
