// TODO Remove this before going to production

// const realCategoryId = {
//   "Category:nursery":
//     "MTtDYXRlZ29yeTs4MDI0NjllZC1jZmQyLTQ4Y2ItYjUyNC0yOWQ4MzE0YTI2Nzg=",
//   "Category:out-and-about":
//     "MTtDYXRlZ29yeTtiYTQzYzIzNS0wNTJhLTQ4MTItODljOC1iN2JlNDJlN2U3MTk=",
//   "Category:play":
//     "MTtDYXRlZ29yeTs2MmM0MTY3MC04ODBkLTRiMzgtOGFhMi0wMGMxMDQzN2NiMmI=",
//   "Category:sleep":
//     "MTtDYXRlZ29yeTtmNjkzOTlmYS1jNmU5LTRhOWItYjRmNS0yMGZjM2I1NGNlNzQ=",
//   "Category:dress":
//     "MTtDYXRlZ29yeTs5ODY2Y2JjMi1hOGEzLTRhNzYtOGY4YS01ZjRjOTg0NDNiZDY=",
// };

export default {};

// export default {
//   entities: {
//     Product: {
//       "Product:snoo-smart-baby-sleeper": {
//         __typename: "Product",
//         id: "Product:snoo-smart-baby-sleeper",
//         "ref:brandId": "Brand:happiest-baby",
//         name: "SNOO Smart Baby Sleeper",
//         priceRange: { min: "1395", max: "1395" },
//         inWishlist: true,
//         tag: "preloved-available",
//         mainImage: {
//           id: "2",
//           url: "https://cdn.filestackcontent.com/96YVveP9QUm1ysvY5gBq",
//           size: { width: 900, height: 900 },
//           imageMimetype: "image/jpeg",
//           blurhash: "LURypYt7%Mj[M{j[t7ay~qayM{jt",
//         },
//       },
//     },
//   },
//   categories: [
//     [realCategoryId["Category:nursery"]],
//     [realCategoryId["Category:out-and-about"]],
//     [realCategoryId["Category:play"]],
//     [realCategoryId["Category:sleep"]],
//     [realCategoryId["Category:dress"]],
//   ],
//   favorites: [
//     "Product:snoo-smart-baby-sleeper",
//     "Product:cameleon-pram",
//     "Product:gelato-wooden-crib",
//     "Product:travel-cot-light",
//   ],
//   favoritesByCategory: {
//     [realCategoryId["Category:nursery"]]: [
//       "Product:multi-motion-baby-swing",
//       "Product:gelato-wooden-crib",
//     ],
//     [realCategoryId["Category:out-and-about"]]: [
//       "Product:cameleon-pram",
//       "Product:travel-cot-light",
//       "Product:gelato-wooden-crib",
//     ],
//     [realCategoryId["Category:play"]]: [
//       "Product:snoo-smart-baby-sleeper",
//       "Product:travel-cot-light",
//     ],
//     [realCategoryId["Category:sleep"]]: [
//       "Product:snoo-smart-baby-sleeper",
//       "Product:gelato-wooden-crib",
//     ],
//     [realCategoryId["Category:dress"]]: [
//       "Product:snoo-smart-baby-sleeper",
//       "Product:multi-motion-baby-swing",
//     ],
//   },
// };
