// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as ShopifyApi__Id from "./ShopifyApi__Id.bs.js";
import * as ShopifyApi__Infra from "./ShopifyApi__Infra.bs.js";
import * as ShopifyApi__Client from "./ShopifyApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as ShopifyApi__Scalars__Url from "./ShopifyApi__Scalars__Url.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as ShopifyApi__CommonFragments from "./ShopifyApi__CommonFragments.bs.js";
import * as ShopifyApi__ProductVariants from "./ShopifyApi__ProductVariants.bs.js";

var query = "fragment CartLineFields on CartLine   {\nquantity  \nid  \ncost  {\ncompareAtAmountPerQuantity  {\n...ShopifyMoneyFields   \n}\n\namountPerQuantity  {\n...ShopifyMoneyFields   \n}\n\n}\n\nmerchandise  {\n__typename\n...on ProductVariant   {\n...ProductVariantFields   \n}\n\n}\n\n}\n" + ShopifyApi__ProductVariants.ProductVariantFields.query + ShopifyApi__CommonFragments.ShopifyMoneyFields.query;

function parse(value) {
  var value$1 = value.cost;
  var value$2 = value$1.compareAtAmountPerQuantity;
  var value$3 = value$1.amountPerQuantity;
  var value$4 = value.merchandise;
  var typename = value$4["__typename"];
  var tmp = typename === "ProductVariant" ? ({
        NAME: "ProductVariant",
        VAL: ShopifyApi__ProductVariants.ProductVariantFields.verifyArgsAndParse("ProductVariantFields", value$4)
      }) : ({
        NAME: "FutureAddedValue",
        VAL: value$4
      });
  return {
          quantity: value.quantity,
          id: value.id,
          cost: {
            compareAtAmountPerQuantity: !(value$2 == null) ? ShopifyApi__CommonFragments.ShopifyMoneyFields.verifyArgsAndParse("ShopifyMoneyFields", value$2) : undefined,
            amountPerQuantity: ShopifyApi__CommonFragments.ShopifyMoneyFields.verifyArgsAndParse("ShopifyMoneyFields", value$3)
          },
          merchandise: tmp
        };
}

function serialize(value) {
  var value$1 = value.merchandise;
  var merchandise = value$1.NAME === "FutureAddedValue" ? value$1.VAL : ShopifyApi__ProductVariants.ProductVariantFields.serialize(value$1.VAL);
  var value$2 = value.cost;
  var value$3 = value$2.amountPerQuantity;
  var amountPerQuantity = ShopifyApi__CommonFragments.ShopifyMoneyFields.serialize(value$3);
  var value$4 = value$2.compareAtAmountPerQuantity;
  var compareAtAmountPerQuantity = value$4 !== undefined ? ShopifyApi__CommonFragments.ShopifyMoneyFields.serialize(value$4) : null;
  var cost = {
    compareAtAmountPerQuantity: compareAtAmountPerQuantity,
    amountPerQuantity: amountPerQuantity
  };
  var value$5 = value.id;
  var value$6 = value.quantity;
  return {
          quantity: value$6,
          id: value$5,
          cost: cost,
          merchandise: merchandise
        };
}

var query$1 = "fragment CartFields on Cart   {\nid  \ncheckoutUrl  \ntotalQuantity  \ncost  {\ntotalAmount  {\n...ShopifyMoneyFields   \n}\n\n}\n\nlines(first: 250)  {\npageInfo  {\nstartCursor  \nendCursor  \nhasNextPage  \nhasPreviousPage  \n}\n\nedges  {\ncursor  \nnode  {\n...CartLineFields   \n}\n\n}\n\n}\n\n}\n" + query + ShopifyApi__CommonFragments.ShopifyMoneyFields.query;

function parse$1(value) {
  var value$1 = value.cost;
  var value$2 = value$1.totalAmount;
  var value$3 = value.lines;
  var value$4 = value$3.pageInfo;
  var value$5 = value$4.startCursor;
  var value$6 = value$4.endCursor;
  var value$7 = value$3.edges;
  return {
          id: value.id,
          checkoutUrl: ShopifyApi__Scalars__Url.parse(value.checkoutUrl),
          totalQuantity: value.totalQuantity,
          cost: {
            totalAmount: ShopifyApi__CommonFragments.ShopifyMoneyFields.verifyArgsAndParse("ShopifyMoneyFields", value$2)
          },
          lines: {
            pageInfo: {
              startCursor: !(value$5 == null) ? value$5 : undefined,
              endCursor: !(value$6 == null) ? value$6 : undefined,
              hasNextPage: value$4.hasNextPage,
              hasPreviousPage: value$4.hasPreviousPage
            },
            edges: Js_array.map((function (value) {
                    return {
                            cursor: value.cursor,
                            node: parse(value.node)
                          };
                  }), value$7)
          }
        };
}

function serialize$1(value) {
  var value$1 = value.lines;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var node = serialize(value$1);
          var value$2 = value.cursor;
          return {
                  cursor: value$2,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasPreviousPage;
  var value$5 = value$3.hasNextPage;
  var value$6 = value$3.endCursor;
  var endCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$3.startCursor;
  var startCursor = value$7 !== undefined ? value$7 : null;
  var pageInfo = {
    startCursor: startCursor,
    endCursor: endCursor,
    hasNextPage: value$5,
    hasPreviousPage: value$4
  };
  var lines = {
    pageInfo: pageInfo,
    edges: edges
  };
  var value$8 = value.cost;
  var value$9 = value$8.totalAmount;
  var totalAmount = ShopifyApi__CommonFragments.ShopifyMoneyFields.serialize(value$9);
  var cost = {
    totalAmount: totalAmount
  };
  var value$10 = value.totalQuantity;
  var value$11 = value.checkoutUrl;
  var value$12 = ShopifyApi__Scalars__Url.serialize(value$11);
  var value$13 = value.id;
  return {
          id: value$13,
          checkoutUrl: value$12,
          totalQuantity: value$10,
          cost: cost,
          lines: lines
        };
}

var Raw = {};

var query$2 = "query BagById($id: ID!)  {\ncart(id: $id)  {\n...CartFields   \n}\n\n}\n" + query$1;

function parse$2(value) {
  var value$1 = value.cart;
  return {
          cart: !(value$1 == null) ? parse$1(value$1) : undefined
        };
}

function serialize$2(value) {
  var value$1 = value.cart;
  var cart = value$1 !== undefined ? serialize$1(value$1) : null;
  return {
          cart: cart
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

var Raw$1 = {};

var query$3 = "query BagItemById($id: ID!)  {\nnode(id: $id)  {\n__typename\n...on CartLine   {\n...CartLineFields   \n}\n\n}\n\n}\n" + query;

function parse$3(value) {
  var value$1 = value.node;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1 = typename === "CartLine" ? ({
          NAME: "CartLine",
          VAL: {
            cartLineFields: parse(value$1)
          }
        }) : ({
          NAME: "UnspecifiedFragment",
          VAL: typename
        });
    tmp = tmp$1;
  }
  return {
          node: tmp
        };
}

function serialize$3(value) {
  var value$1 = value.node;
  var node = value$1 !== undefined ? (
      value$1.NAME === "UnspecifiedFragment" ? ({}) : Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                return {};
              }), [serialize(value$1.VAL.cartLineFields)])
    ) : null;
  return {
          node: node
        };
}

function serializeVariables$1(inp) {
  return {
          id: inp.id
        };
}

var Raw$2 = {};

var query$4 = "mutation CreateBag($merchandiseId: ID!, $quantity: Int)  {\ncartCreate(input: {lines: [{merchandiseId: $merchandiseId, quantity: $quantity}]})  {\ncart  {\n...CartFields   \n}\n\n}\n\n}\n" + query$1;

function parse$4(value) {
  var value$1 = value.cartCreate;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.cart;
    tmp = {
      cart: !(value$2 == null) ? parse$1(value$2) : undefined
    };
  }
  return {
          cartCreate: tmp
        };
}

function serialize$4(value) {
  var value$1 = value.cartCreate;
  var cartCreate;
  if (value$1 !== undefined) {
    var value$2 = value$1.cart;
    var cart = value$2 !== undefined ? serialize$1(value$2) : null;
    cartCreate = {
      cart: cart
    };
  } else {
    cartCreate = null;
  }
  return {
          cartCreate: cartCreate
        };
}

function serializeVariables$2(inp) {
  var a = inp.quantity;
  return {
          merchandiseId: inp.merchandiseId,
          quantity: a !== undefined ? a : undefined
        };
}

var Raw$3 = {};

var query$5 = "mutation AddBagItem($bagId: ID!, $merchandiseId: ID!, $quantity: Int)  {\ncartLinesAdd(cartId: $bagId, lines: [{merchandiseId: $merchandiseId, quantity: $quantity}])  {\ncart  {\n...CartFields   \n}\n\n}\n\n}\n" + query$1;

function parse$5(value) {
  var value$1 = value.cartLinesAdd;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.cart;
    tmp = {
      cart: !(value$2 == null) ? parse$1(value$2) : undefined
    };
  }
  return {
          cartLinesAdd: tmp
        };
}

function serialize$5(value) {
  var value$1 = value.cartLinesAdd;
  var cartLinesAdd;
  if (value$1 !== undefined) {
    var value$2 = value$1.cart;
    var cart = value$2 !== undefined ? serialize$1(value$2) : null;
    cartLinesAdd = {
      cart: cart
    };
  } else {
    cartLinesAdd = null;
  }
  return {
          cartLinesAdd: cartLinesAdd
        };
}

function serializeVariables$3(inp) {
  var a = inp.quantity;
  return {
          bagId: inp.bagId,
          merchandiseId: inp.merchandiseId,
          quantity: a !== undefined ? a : undefined
        };
}

var Raw$4 = {};

var query$6 = "mutation RemoveBagItem($bagId: ID!, $bagItemId: ID!)  {\ncartLinesRemove(cartId: $bagId, lineIds: [$bagItemId])  {\ncart  {\n...CartFields   \n}\n\n}\n\n}\n" + query$1;

function parse$6(value) {
  var value$1 = value.cartLinesRemove;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.cart;
    tmp = {
      cart: !(value$2 == null) ? parse$1(value$2) : undefined
    };
  }
  return {
          cartLinesRemove: tmp
        };
}

function serialize$6(value) {
  var value$1 = value.cartLinesRemove;
  var cartLinesRemove;
  if (value$1 !== undefined) {
    var value$2 = value$1.cart;
    var cart = value$2 !== undefined ? serialize$1(value$2) : null;
    cartLinesRemove = {
      cart: cart
    };
  } else {
    cartLinesRemove = null;
  }
  return {
          cartLinesRemove: cartLinesRemove
        };
}

function serializeVariables$4(inp) {
  return {
          bagId: inp.bagId,
          bagItemId: inp.bagItemId
        };
}

var Raw$5 = {};

var query$7 = "mutation UpdateBagItemQuantity($bagId: ID!, $bagItemId: ID!, $quantity: Int)  {\ncartLinesUpdate(cartId: $bagId, lines: [{id: $bagItemId, quantity: $quantity}])  {\ncart  {\n...CartFields   \n}\n\n}\n\n}\n" + query$1;

function parse$7(value) {
  var value$1 = value.cartLinesUpdate;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.cart;
    tmp = {
      cart: !(value$2 == null) ? parse$1(value$2) : undefined
    };
  }
  return {
          cartLinesUpdate: tmp
        };
}

function serialize$7(value) {
  var value$1 = value.cartLinesUpdate;
  var cartLinesUpdate;
  if (value$1 !== undefined) {
    var value$2 = value$1.cart;
    var cart = value$2 !== undefined ? serialize$1(value$2) : null;
    cartLinesUpdate = {
      cart: cart
    };
  } else {
    cartLinesUpdate = null;
  }
  return {
          cartLinesUpdate: cartLinesUpdate
        };
}

function serializeVariables$5(inp) {
  var a = inp.quantity;
  return {
          bagId: inp.bagId,
          bagItemId: inp.bagItemId,
          quantity: a !== undefined ? a : undefined
        };
}

function makeBagItem(param) {
  var merchandise = param.merchandise;
  var cost = param.cost;
  return {
          id: ShopifyApi__Id.parseExn(param.id),
          quantity: param.quantity,
          merchandise: merchandise.NAME === "FutureAddedValue" ? (RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "Bag makeBagItem - BagItem.merchandise unknown typename", merchandise), Js_exn.raiseError("BagItem.merchandise unknown typename.")) : /* ProductVariant */({
                _0: ShopifyApi__ProductVariants.makeProductVariant(merchandise.VAL)
              }),
          cost: {
            compareAtPricePerQuantity: Belt_Option.map(cost.compareAtAmountPerQuantity, ShopifyApi__CommonFragments.makeMoney),
            pricePerQuantity: ShopifyApi__CommonFragments.makeMoney(cost.amountPerQuantity)
          }
        };
}

function makeBag(param) {
  var match = param.lines;
  var pageInfo = match.pageInfo;
  return {
          id: ShopifyApi__Id.parseExn(param.id),
          items: {
            pageInfo: {
              startCursor: pageInfo.startCursor,
              endCursor: pageInfo.endCursor,
              hasNextPage: pageInfo.hasNextPage,
              hasPreviousPage: pageInfo.hasPreviousPage
            },
            edges: Belt_Array.map(match.edges, (function (param) {
                    return {
                            cursor: param.cursor,
                            node: makeBagItem(param.node)
                          };
                  }))
          },
          totalQuantity: param.totalQuantity,
          totalPrice: ShopifyApi__CommonFragments.makeMoney(param.cost.totalAmount),
          checkoutUrl: param.checkoutUrl
        };
}

function fetchBagById(apiConfig, id) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$2,
                    Raw: Raw,
                    parse: parse$2,
                    serialize: serialize$2,
                    serializeVariables: serializeVariables
                  }), {
                id: ShopifyApi__Id.toString(id)
              }, undefined).then(function (param) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(param.cart, (function (param) {
                                RescriptUtils__Logger.error2(ShopifyApi__Infra.logger, (new Error("Bag not found")), {
                                      id: id
                                    });
                              })), makeBag);
            });
}

function fetchBagItemById(apiConfig, id) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$3,
                    Raw: Raw$1,
                    parse: parse$3,
                    serialize: serialize$3,
                    serializeVariables: serializeVariables$1
                  }), {
                id: ShopifyApi__Id.toString(id)
              }, undefined).then(function (param) {
              var node = param.node;
              if (node !== undefined) {
                if (node.NAME === "UnspecifiedFragment") {
                  RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "fetchBagItemById - node of unexpected type found:", node);
                  return ;
                } else {
                  return makeBagItem(node.VAL.cartLineFields);
                }
              } else {
                RescriptUtils__Logger.error2(ShopifyApi__Infra.logger, (new Error("Bag item not found")), {
                      id: id
                    });
                return ;
              }
            });
}

function executeCreateBag(apiConfig, merchandiseId, quantity) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$4,
                    Raw: Raw$2,
                    parse: parse$4,
                    serialize: serialize$4,
                    serializeVariables: serializeVariables$2
                  }), {
                merchandiseId: ShopifyApi__Id.toString(merchandiseId),
                quantity: quantity
              }, undefined).then(function (param) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(Belt_Option.flatMap(RescriptUtils__OptionPlus.tapNone(param.cartCreate, (function (param) {
                                        RescriptUtils__Logger.warn(ShopifyApi__Infra.logger, "executeCreateBag - cartCreate not found. Merchandise id: " + ShopifyApi__Id.toString(merchandiseId) + " Quantity: " + String(quantity));
                                      })), (function (param) {
                                    return param.cart;
                                  })), (function (param) {
                                RescriptUtils__Logger.warn(ShopifyApi__Infra.logger, "executeCreateBag - cart not found. Mercandise id: " + ShopifyApi__Id.toString(merchandiseId) + " Quantity: " + String(quantity));
                              })), (function (cart) {
                            return {
                                    bag: makeBag(cart)
                                  };
                          }));
            });
}

function executeAddBagItem(apiConfig, bagId, merchandiseId, quantity) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$5,
                    Raw: Raw$3,
                    parse: parse$5,
                    serialize: serialize$5,
                    serializeVariables: serializeVariables$3
                  }), {
                bagId: ShopifyApi__Id.toString(bagId),
                merchandiseId: ShopifyApi__Id.toString(merchandiseId),
                quantity: quantity
              }, undefined).then(function (param) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(Belt_Option.flatMap(RescriptUtils__OptionPlus.tapNone(param.cartLinesAdd, (function (param) {
                                        RescriptUtils__Logger.warn(ShopifyApi__Infra.logger, "executeAddBagItem - cartLinesAdd not found. Bag id :" + ShopifyApi__Id.toString(bagId) + " Mercandise id: " + ShopifyApi__Id.toString(merchandiseId) + " Quantity: " + String(quantity));
                                      })), (function (param) {
                                    return param.cart;
                                  })), (function (param) {
                                RescriptUtils__Logger.warn(ShopifyApi__Infra.logger, "executeAddBagItem - cart not found. Bag id :" + ShopifyApi__Id.toString(bagId) + " Mercandise id: " + ShopifyApi__Id.toString(merchandiseId) + " Quantity: " + String(quantity));
                              })), (function (cart) {
                            return {
                                    bag: makeBag(cart)
                                  };
                          }));
            });
}

function executeRemoveBagItem(apiConfig, bagId, bagItemId) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$6,
                    Raw: Raw$4,
                    parse: parse$6,
                    serialize: serialize$6,
                    serializeVariables: serializeVariables$4
                  }), {
                bagId: ShopifyApi__Id.toString(bagId),
                bagItemId: ShopifyApi__Id.toString(bagItemId)
              }, undefined).then(function (param) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(Belt_Option.flatMap(RescriptUtils__OptionPlus.tapNone(param.cartLinesRemove, (function (param) {
                                        RescriptUtils__Logger.warn(ShopifyApi__Infra.logger, "executeRemoveBagItem - cartLinesRemove not found. Bag id :" + ShopifyApi__Id.toString(bagId) + " Bag item id: " + ShopifyApi__Id.toString(bagItemId));
                                      })), (function (param) {
                                    return param.cart;
                                  })), (function (param) {
                                RescriptUtils__Logger.warn(ShopifyApi__Infra.logger, "executeRemoveBagItem - cart not found. Bag id :" + ShopifyApi__Id.toString(bagId) + " Bag item id: " + ShopifyApi__Id.toString(bagItemId));
                              })), (function (cart) {
                            return {
                                    bag: makeBag(cart)
                                  };
                          }));
            });
}

function executeUpdateBagItemQuantity(apiConfig, bagId, bagItemId, quantity) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$7,
                    Raw: Raw$5,
                    parse: parse$7,
                    serialize: serialize$7,
                    serializeVariables: serializeVariables$5
                  }), {
                bagId: ShopifyApi__Id.toString(bagId),
                bagItemId: ShopifyApi__Id.toString(bagItemId),
                quantity: quantity
              }, undefined).then(function (param) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(Belt_Option.flatMap(RescriptUtils__OptionPlus.tapNone(param.cartLinesUpdate, (function (param) {
                                        RescriptUtils__Logger.warn(ShopifyApi__Infra.logger, "executeUpdateBagItemQuantity - cartLinesUpdate not found. Bag id :" + ShopifyApi__Id.toString(bagId) + " Bag item id: " + ShopifyApi__Id.toString(bagItemId) + " Quantity: " + String(quantity));
                                      })), (function (param) {
                                    return param.cart;
                                  })), (function (param) {
                                RescriptUtils__Logger.warn(ShopifyApi__Infra.logger, "executeUpdateBagItemQuantity - cart not found. Bag id :" + ShopifyApi__Id.toString(bagId) + " Bag item id: " + ShopifyApi__Id.toString(bagItemId) + " Quantity: " + String(quantity));
                              })), (function (cart) {
                            return {
                                    bag: makeBag(cart)
                                  };
                          }));
            });
}

export {
  fetchBagById ,
  fetchBagItemById ,
  executeCreateBag ,
  executeAddBagItem ,
  executeRemoveBagItem ,
  executeUpdateBagItemQuantity ,
}
/* ShopifyApi__Id Not a pure module */
