// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlMiddleware__Entity__AuctionPricing from "./GqlMiddleware__Entity__AuctionPricing.bs.js";

function makePricingOption(pricing) {
  if (pricing.TAG !== /* SellUsingFixedPriceSetting */0) {
    return {
            TAG: /* SellUsingAuctionSetting */1,
            __typename: "SellUsingAuctionSetting",
            pricing: GqlMiddleware__Entity__AuctionPricing.makeAuctionPricing(pricing.pricing)
          };
  }
  var price = pricing.price;
  return {
          TAG: /* SellUsingFixedPriceSetting */0,
          __typename: "SellUsingFixedPriceSetting",
          price: {
            amount: price.amount,
            currency: price.currency
          }
        };
}

export {
  makePricingOption ,
}
/* No side effect */
