/* TypeScript file generated from App__BagPage.resi by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as App__BagPageBS__Es6Import from './App__BagPage.bs';
const App__BagPageBS: any = App__BagPageBS__Es6Import;

// tslint:disable-next-line:interface-over-type-literal
export type Props = { readonly showGoToBagButton?: boolean; readonly runQuery?: boolean };

export const make: React.ComponentType<{ readonly showGoToBagButton?: boolean; readonly runQuery?: boolean }> = App__BagPageBS.make;
