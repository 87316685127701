// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as ReactNative from "react-native";
import * as Ui__MarkotText from "../../../../ui/src/text/components/Ui__MarkotText.bs.js";
import * as Utils__PriceUtils from "../../../../utils/src/price/Utils__PriceUtils.bs.js";
import * as AppMoney_money_graphql from "../../../../relay-codegen/generated/AppMoney_money_graphql.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppMoney_money_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppMoney_money_graphql.Internal.convertFragment, data);
}

function App__Money(Props) {
  var money = Props.money;
  var testID = Props.testID;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var money$1 = use(money);
  var match$1 = money$1.currency;
  var currency = match$1 === "AUD" ? "AUD" : undefined;
  var match$2 = Belt_Float.fromString(money$1.amount);
  if (currency !== undefined && match$2 !== undefined) {
    var tmp = {
      children: Utils__PriceUtils.toLocaleString(match$2, currency)
    };
    if (testID !== undefined) {
      tmp.testID = testID;
    }
    return React.createElement(ReactNative.Text, tmp);
  }
  var tmp$1 = {
    color: match.theme.colors.error,
    children: "Error!"
  };
  if (testID !== undefined) {
    tmp$1.testID = Caml_option.valFromOption(testID);
  }
  return React.createElement(Ui__MarkotText.make, tmp$1);
}

var make = App__Money;

export {
  make ,
}
/* react Not a pure module */
