// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as ShopifyApi__Id from "./ShopifyApi__Id.bs.js";
import * as ShopifyApi__Infra from "./ShopifyApi__Infra.bs.js";
import * as Belt_HashSetString from "rescript/lib/es6/belt_HashSetString.js";
import * as ShopifyApi__Client from "./ShopifyApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as ShopifyApi__CommonFragments from "./ShopifyApi__CommonFragments.bs.js";
import * as ShopifyApi__TitleWithMetadata from "./ShopifyApi__TitleWithMetadata.bs.js";

var query = "fragment CategoryCollectionFields on Collection   {\nid  \ntitle  \nhandle  \ndescription  \nimage  {\n...ImageFields   \n}\n\ncoverImage: metafield(namespace: \"real-concepts\", key: \"cat-cover-image\")  {\n...MetafieldImageFields   \n}\n\nicon: metafield(namespace: \"real-concepts\", key: \"cat-icon\")  {\n...MetafieldImageFields   \n}\n\nprelovedItemsAccepted: metafield(namespace: \"real-concepts\", key: \"cat-preloved-items-accepted\")  {\nvalue  \n}\n\nseo  {\ntitle  \ndescription  \n}\n\n}\n" + ShopifyApi__CommonFragments.ImageFields.query + ShopifyApi__CommonFragments.MetafieldImageFields.query;

function parse(value) {
  var value$1 = value.image;
  var value$2 = value.coverImage;
  var value$3 = value.icon;
  var value$4 = value.prelovedItemsAccepted;
  var value$5 = value.seo;
  var value$6 = value$5.title;
  var value$7 = value$5.description;
  return {
          id: value.id,
          title: ShopifyApi__TitleWithMetadata.parse(value.title),
          handle: value.handle,
          description: value.description,
          image: !(value$1 == null) ? ShopifyApi__CommonFragments.ImageFields.verifyArgsAndParse("ImageFields", value$1) : undefined,
          coverImage: !(value$2 == null) ? ShopifyApi__CommonFragments.MetafieldImageFields.verifyArgsAndParse("MetafieldImageFields", value$2) : undefined,
          icon: !(value$3 == null) ? ShopifyApi__CommonFragments.MetafieldImageFields.verifyArgsAndParse("MetafieldImageFields", value$3) : undefined,
          prelovedItemsAccepted: !(value$4 == null) ? ({
                value: value$4.value
              }) : undefined,
          seo: {
            title: !(value$6 == null) ? value$6 : undefined,
            description: !(value$7 == null) ? value$7 : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.seo;
  var value$2 = value$1.description;
  var description = value$2 !== undefined ? value$2 : null;
  var value$3 = value$1.title;
  var title = value$3 !== undefined ? value$3 : null;
  var seo = {
    title: title,
    description: description
  };
  var value$4 = value.prelovedItemsAccepted;
  var prelovedItemsAccepted;
  if (value$4 !== undefined) {
    var value$5 = value$4.value;
    prelovedItemsAccepted = {
      value: value$5
    };
  } else {
    prelovedItemsAccepted = null;
  }
  var value$6 = value.icon;
  var icon = value$6 !== undefined ? ShopifyApi__CommonFragments.MetafieldImageFields.serialize(value$6) : null;
  var value$7 = value.coverImage;
  var coverImage = value$7 !== undefined ? ShopifyApi__CommonFragments.MetafieldImageFields.serialize(value$7) : null;
  var value$8 = value.image;
  var image = value$8 !== undefined ? ShopifyApi__CommonFragments.ImageFields.serialize(value$8) : null;
  var value$9 = value.description;
  var value$10 = value.handle;
  var value$11 = value.title;
  var value$12 = ShopifyApi__TitleWithMetadata.serialize(value$11);
  var value$13 = value.id;
  return {
          id: value$13,
          title: value$12,
          handle: value$10,
          description: value$9,
          image: image,
          coverImage: coverImage,
          icon: icon,
          prelovedItemsAccepted: prelovedItemsAccepted,
          seo: seo
        };
}

var Raw = {};

function parse$1(value) {
  var value$1 = value.menu;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.items;
    tmp = {
      items: Js_array.map((function (value) {
              var value$1 = value.resourceId;
              var value$2 = value.items;
              return {
                      resourceId: !(value$1 == null) ? value$1 : undefined,
                      title: value.title,
                      items: Js_array.map((function (value) {
                              var value$1 = value.resourceId;
                              return {
                                      resourceId: !(value$1 == null) ? value$1 : undefined,
                                      title: value.title
                                    };
                            }), value$2)
                    };
            }), value$2)
    };
  }
  return {
          menu: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.menu;
  var menu;
  if (value$1 !== undefined) {
    var value$2 = value$1.items;
    var items = Js_array.map((function (value) {
            var value$1 = value.items;
            var items = Js_array.map((function (value) {
                    var value$1 = value.title;
                    var value$2 = value.resourceId;
                    var resourceId = value$2 !== undefined ? value$2 : null;
                    return {
                            resourceId: resourceId,
                            title: value$1
                          };
                  }), value$1);
            var value$2 = value.title;
            var value$3 = value.resourceId;
            var resourceId = value$3 !== undefined ? value$3 : null;
            return {
                    resourceId: resourceId,
                    title: value$2,
                    items: items
                  };
          }), value$2);
    menu = {
      items: items
    };
  } else {
    menu = null;
  }
  return {
          menu: menu
        };
}

function serializeVariables(param) {
  
}

var Raw$1 = {};

var query$1 = "query SpecificCollections($ids: [ID!]!)  {\nnodes(ids: $ids)  {\n__typename\n__typename  \n...on Collection   {\nid  \n...CategoryCollectionFields   \n}\n\n}\n\n}\n" + query;

function parse$2(value) {
  var value$1 = value.nodes;
  return {
          nodes: Js_array.map((function (value) {
                  if (value == null) {
                    return ;
                  }
                  var typename = value["__typename"];
                  var tmp = typename === "Collection" ? ({
                        NAME: "Collection",
                        VAL: {
                          id: value["id"],
                          categoryCollectionFields: parse(value)
                        }
                      }) : ({
                        NAME: "UnspecifiedFragment",
                        VAL: typename
                      });
                  return {
                          fragment: tmp,
                          __typename: value["__typename"]
                        };
                }), value$1)
        };
}

function serialize$2(value) {
  var value$1 = value.nodes;
  var nodes = Js_array.map((function (value) {
          if (value === undefined) {
            return null;
          }
          var value$1 = value.fragment;
          var tmp;
          if (value$1.NAME === "Collection") {
            var value$2 = value$1.VAL;
            var value$3 = value$2.id;
            tmp = Js_array.reduce(GraphQL_PPX.deepMerge, {
                  __typename: "Collection",
                  id: value$3
                }, [serialize(value$2.categoryCollectionFields)]);
          } else {
            tmp = {};
          }
          return Js_array.reduce(GraphQL_PPX.deepMerge, {
                      __typename: value.__typename
                    }, [tmp]);
        }), value$1);
  return {
          nodes: nodes
        };
}

function serializeVariables$1(inp) {
  var a = inp.ids;
  return {
          ids: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function collectId(ids, resourceId, title) {
  if (resourceId !== undefined) {
    return Belt_HashSetString.add(ids, resourceId);
  } else {
    return RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "Menu item resourceID not found in ", title);
  }
}

function collectUniqueIds(items, wantedCategoryId, param) {
  var ids = Belt_HashSetString.make(100);
  Belt_Array.forEach(items, (function (categoryItem) {
          var wanted = wantedCategoryId !== undefined ? Caml_obj.equal(Caml_option.valFromOption(wantedCategoryId), categoryItem.resourceId) : true;
          if (wanted) {
            collectId(ids, categoryItem.resourceId, categoryItem.title);
            return Belt_Array.forEach(categoryItem.items, (function (subcategoryItem) {
                          collectId(ids, subcategoryItem.resourceId, subcategoryItem.title);
                        }));
          }
          
        }));
  return Belt_HashSetString.toArray(ids);
}

function makeSubcategory(source) {
  var prelovedItemsAccepted = Belt_Option.getWithDefault(Belt_Option.map(source.prelovedItemsAccepted, (function (prelovedItemsAccepted) {
              return prelovedItemsAccepted.value === "true";
            })), false);
  return {
          id: ShopifyApi__Id.parseExn(source.id),
          name: ShopifyApi__TitleWithMetadata.toTitle(source.title),
          slug: source.handle,
          prelovedItemsAccepted: prelovedItemsAccepted,
          containsNewProductsOnly: !prelovedItemsAccepted,
          seo: {
            description: source.seo.description,
            title: source.seo.title
          }
        };
}

function $$fetch(apiConfig, categoryId, param) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: "query CategoryMenu  {\nmenu(handle: \"categories\")  {\nitems  {\nresourceId  \ntitle  \nitems  {\nresourceId  \ntitle  \n}\n\n}\n\n}\n\n}\n",
                    Raw: Raw,
                    parse: parse$1,
                    serialize: serialize$1,
                    serializeVariables: serializeVariables
                  }), undefined, undefined).then(function (param) {
              var menu = param.menu;
              if (menu !== undefined) {
                return Curry._2(ShopifyApi__Client.query(apiConfig)({
                                    query: query$1,
                                    Raw: Raw$1,
                                    parse: parse$2,
                                    serialize: serialize$2,
                                    serializeVariables: serializeVariables$1
                                  }), {
                                ids: collectUniqueIds(menu.items, undefined, undefined)
                              }, undefined).then(function (param) {
                              var categoriesById = Belt_Array.reduce(Belt_Array.keepMap(param.nodes, (function (n) {
                                          return n;
                                        })), undefined, (function (acc, x) {
                                      var match = x.fragment;
                                      if (typeof match === "object") {
                                        if (match.NAME === "Collection") {
                                          var x$1 = match.VAL;
                                          return Belt_MapString.set(acc, x$1.id, x$1.categoryCollectionFields);
                                        }
                                        RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "Unexpected typename found among collections", x.__typename);
                                        return acc;
                                      }
                                      RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "Unexpected typename found among collections", x.__typename);
                                      return acc;
                                    }));
                              return Promise.resolve(Belt_Array.keepMap(Belt_Array.keepMap(menu.items, (function (param) {
                                                    var items = param.items;
                                                    return Belt_Option.flatMap(param.resourceId, (function (resourceId) {
                                                                  if (categoryId !== undefined && resourceId !== categoryId) {
                                                                    return ;
                                                                  } else {
                                                                    return [
                                                                            resourceId,
                                                                            items
                                                                          ];
                                                                  }
                                                                }));
                                                  })), (function (param) {
                                                var subcategories_pageInfo = {
                                                  startCursor: undefined,
                                                  endCursor: undefined,
                                                  hasNextPage: false,
                                                  hasPreviousPage: false
                                                };
                                                var subcategories_edges = Belt_Array.map(Belt_Array.keepMap(Belt_Array.keepMap(param[1], (function (param) {
                                                                return param.resourceId;
                                                              })), (function (id) {
                                                            return Belt_Option.map(Belt_MapString.get(categoriesById, id), makeSubcategory);
                                                          })), (function (subcategory) {
                                                        return {
                                                                cursor: "no-pagination",
                                                                node: subcategory
                                                              };
                                                      }));
                                                var subcategories = {
                                                  pageInfo: subcategories_pageInfo,
                                                  edges: subcategories_edges
                                                };
                                                return Belt_Option.map(Belt_MapString.get(categoriesById, param[0]), (function (param) {
                                                              var prelovedItemsAccepted = Belt_Option.getWithDefault(Belt_Option.map(param.prelovedItemsAccepted, (function (prelovedItemsAccepted) {
                                                                          return prelovedItemsAccepted.value === "true";
                                                                        })), false);
                                                              return {
                                                                      id: ShopifyApi__Id.parseExn(param.id),
                                                                      name: ShopifyApi__TitleWithMetadata.toTitle(param.title),
                                                                      slug: param.handle,
                                                                      description: param.description,
                                                                      image: Belt_Option.map(param.image, ShopifyApi__CommonFragments.makeImage),
                                                                      coverImage: Belt_Option.flatMap(param.coverImage, ShopifyApi__CommonFragments.tryMakeImageFromMetafield),
                                                                      icon: Belt_Option.flatMap(param.icon, ShopifyApi__CommonFragments.tryMakeImageFromMetafield),
                                                                      prelovedItemsAccepted: prelovedItemsAccepted,
                                                                      containsNewProductsOnly: !prelovedItemsAccepted,
                                                                      subcategories: subcategories,
                                                                      seo: {
                                                                        description: param.seo.description,
                                                                        title: param.seo.title
                                                                      }
                                                                    };
                                                            }));
                                              })));
                            }).then(function (recievedCategories) {
                            return {
                                    pageInfo: {
                                      startCursor: undefined,
                                      endCursor: undefined,
                                      hasNextPage: false,
                                      hasPreviousPage: false
                                    },
                                    edges: Belt_Array.map(recievedCategories, (function (category) {
                                            return {
                                                    cursor: "no-pagination",
                                                    node: category
                                                  };
                                          }))
                                  };
                          });
              } else {
                RescriptUtils__Logger.warn(ShopifyApi__Infra.logger, "Category menu not found");
                return Promise.resolve({
                            pageInfo: {
                              startCursor: undefined,
                              endCursor: undefined,
                              hasNextPage: false,
                              hasPreviousPage: false
                            },
                            edges: []
                          });
              }
            });
}

var Raw$2 = {};

var query$2 = "query SpecificCollection($id: ID!)  {\ncollection(id: $id)  {\n...CategoryCollectionFields   \n}\n\n}\n" + query;

function parse$3(value) {
  var value$1 = value.collection;
  return {
          collection: !(value$1 == null) ? parse(value$1) : undefined
        };
}

function serialize$3(value) {
  var value$1 = value.collection;
  var collection = value$1 !== undefined ? serialize(value$1) : null;
  return {
          collection: collection
        };
}

function serializeVariables$2(inp) {
  return {
          id: inp.id
        };
}

function fetchSubcategory(apiConfig, id, param) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$2,
                    Raw: Raw$2,
                    parse: parse$3,
                    serialize: serialize$3,
                    serializeVariables: serializeVariables$2
                  }), {
                id: ShopifyApi__Id.toString(ShopifyApi__Id.make("Collection", id))
              }, undefined).then(function (param) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(param.collection, (function (param) {
                                RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "Subcategory collection not found. Id:", id);
                              })), makeSubcategory);
            });
}

var Categories = {
  $$fetch: $$fetch,
  fetchSubcategory: fetchSubcategory
};

export {
  Categories ,
}
/* ShopifyApi__Id Not a pure module */
