// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";

function makeSellerRatingFeeId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "SellerRatingFee", param);
}

export {
  makeSellerRatingFeeId ,
}
/* GqlMiddleware__Core__NodeId Not a pure module */
