// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "../../../api-adapter-markot/src/MarkotApi__Id.bs.js";
import * as MarkotApi__Bag from "../../../api-adapter-markot/src/MarkotApi__Bag.bs.js";
import * as ShopifyApi__Id from "../../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as ShopifyApi__Bag from "../../../api-adapter-shopify/src/ShopifyApi__Bag.bs.js";
import * as GraphqlCore__Types from "../../../graphql-core/src/GraphqlCore__Types.bs.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Core__FromShopify from "../core/GqlMiddleware__Core__FromShopify.bs.js";
import * as GqlMiddleware__Entity__ProductVariant from "./GqlMiddleware__Entity__ProductVariant.bs.js";

var AddToBagPayload = {};

var RemoveFromBagPayload = {};

var UpdateBagItemQuantityPayload = {};

function makeBagId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "Bag", param);
}

function makeBagItemId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "BagItem", param);
}

function makeBagItem(param) {
  var cost = param.cost;
  return {
          __typename: "BagItem",
          id: GqlMiddleware__Core__NodeId.make(1, "BagItem", ShopifyApi__Id.toString(param.id)),
          quantity: param.quantity,
          merchandise: /* ProductVariant */{
            _0: GqlMiddleware__Entity__ProductVariant.makeVariant(param.merchandise._0)
          },
          compareAtPricePerQuantity: Belt_Option.map(cost.compareAtPricePerQuantity, GqlMiddleware__Core__FromShopify.money),
          pricePerQuantity: GqlMiddleware__Core__FromShopify.money(cost.pricePerQuantity)
        };
}

function makeBag(param) {
  return {
          __typename: "Bag",
          id: GqlMiddleware__Core__NodeId.make(1, "Bag", ShopifyApi__Id.toString(param.id)),
          items: GraphqlCore__Types.Connection.map(param.items, makeBagItem),
          totalQuantity: param.totalQuantity,
          totalPrice: GqlMiddleware__Core__FromShopify.money(param.totalPrice),
          checkoutUrl: param.checkoutUrl
        };
}

function fetchBagById(apiConfig, id) {
  return ShopifyApi__Bag.fetchBagById(apiConfig, ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(id).internalId)).then(function (bag) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(bag, (function (param) {
                                RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "fetchBagById - no bag found. Id:", GqlMiddleware__Core__NodeId.toString(id));
                              })), makeBag);
            });
}

function fetchBagItemById(apiConfig, id) {
  return ShopifyApi__Bag.fetchBagItemById(apiConfig, ShopifyApi__Id.make("CartLine", GqlMiddleware__Core__NodeId.unpackExn(id).internalId)).then(function (bagItem) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(bagItem, (function (param) {
                                RescriptUtils__Logger.warn2(GqlMiddleware__Core__Infra.logger, "fetchBagItemById - no bag item found. Id:", GqlMiddleware__Core__NodeId.toString(id));
                              })), makeBagItem);
            });
}

function executeCreateBag(apiConfig, merchandiseId, quantity) {
  return ShopifyApi__Bag.executeCreateBag(apiConfig, ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(merchandiseId).internalId), quantity).then(function (createdBag) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(createdBag, (function (param) {
                                RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "executeCreateBag - no bag created. Merchandise Id:", GqlMiddleware__Core__NodeId.toString(merchandiseId));
                              })), (function (createdBag) {
                            return {
                                    __typename: "AddToBagPayload",
                                    bag: makeBag(createdBag.bag)
                                  };
                          }));
            });
}

function executeAddBagItem(apiConfig, bagId, merchandiseId, quantity) {
  return ShopifyApi__Bag.executeAddBagItem(apiConfig, ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(bagId).internalId), ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(merchandiseId).internalId), quantity).then(function (bagAfterAddingBagItem) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(bagAfterAddingBagItem, (function (param) {
                                RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "executeAddBagItem - no bag returned. Bag Id:" + GqlMiddleware__Core__NodeId.toString(bagId) + " Merchandise Id:" + GqlMiddleware__Core__NodeId.toString(merchandiseId));
                              })), (function (bagAfterAddingBagItem) {
                            return {
                                    __typename: "AddToBagPayload",
                                    bag: makeBag(bagAfterAddingBagItem.bag)
                                  };
                          }));
            });
}

function executeRemoveBagItem(apiConfig, bagId, bagItemId) {
  return ShopifyApi__Bag.executeRemoveBagItem(apiConfig, ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(bagId).internalId), ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(bagItemId).internalId)).then(function (bagAfterRemovingBagItem) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(bagAfterRemovingBagItem, (function (param) {
                                RescriptUtils__Logger.info(GqlMiddleware__Core__Infra.logger, "executeRemoveBagItem - no bag returned. Bag Id:" + GqlMiddleware__Core__NodeId.toString(bagId) + " BagItem Id:" + GqlMiddleware__Core__NodeId.toString(bagItemId));
                              })), (function (bagAfterRemovingBagItem) {
                            return {
                                    __typename: "RemoveFromBagPayload",
                                    bag: makeBag(bagAfterRemovingBagItem.bag)
                                  };
                          }));
            });
}

function executeUpdateBagItemQuantity(apiConfig, bagId, bagItemId, quantity) {
  return ShopifyApi__Bag.executeUpdateBagItemQuantity(apiConfig, ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(bagId).internalId), ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(bagItemId).internalId), quantity).then(function (updatedBag) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(updatedBag, (function (param) {
                                RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "executeUpdateBagItemQuantity - no bag returned. Bag Id:" + GqlMiddleware__Core__NodeId.toString(bagId) + " BagItem Id:" + GqlMiddleware__Core__NodeId.toString(bagItemId));
                              })), (function (updatedBag) {
                            return {
                                    __typename: "UpdateBagItemQuantityPayload",
                                    bag: makeBag(updatedBag.bag)
                                  };
                          }));
            });
}

function setViewerBag(apiConfig, bagRefInput) {
  return MarkotApi__Bag.setViewerBag(apiConfig, {
                externalId: ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(bagRefInput.externalId).internalId)
              }).then(function (param) {
              return {
                      __typename: "SetViewerBagPayload",
                      bagId: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.bagId))
                    };
            });
}

function setViewerBagWithNodeId(apiConfig, bagId) {
  return MarkotApi__Bag.setViewerBagWithNodeId(apiConfig, bagId).then(function (param) {
              return {
                      __typename: "SetViewerBagPayload",
                      bagId: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.bagId))
                    };
            });
}

export {
  AddToBagPayload ,
  RemoveFromBagPayload ,
  UpdateBagItemQuantityPayload ,
  makeBagItemId ,
  makeBagItem ,
  makeBagId ,
  makeBag ,
  fetchBagById ,
  fetchBagItemById ,
  executeCreateBag ,
  executeAddBagItem ,
  executeRemoveBagItem ,
  executeUpdateBagItemQuantity ,
  setViewerBag ,
  setViewerBagWithNodeId ,
}
/* MarkotApi__Id Not a pure module */
