// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Ignored$DecidrIoUtils from "@decidr-io/utils/src/functions/Ignored.bs.js";
import * as FeatureFlags__AppSpecificConfig from "./FeatureFlags__AppSpecificConfig.bs.js";
import * as AsyncStorage from "@react-native-async-storage/async-storage";

var context = React.createContext({
      featureFlags: FeatureFlags__AppSpecificConfig.FeatureFlagsMap.empty,
      getFeatureFlagStatus: (function (param) {
          return "off";
        }),
      setFeatureFlag: (function (_feature, _status) {
          
        })
    });

function use(featureFlag) {
  return Curry._1(React.useContext(context).getFeatureFlagStatus, featureFlag);
}

function useFeatureFlagsEnvironment(param) {
  var contextData = React.useContext(context);
  return [
          contextData.featureFlags,
          contextData.setFeatureFlag
        ];
}

var make = context.Provider;

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

function getFeatureFlagStatus(featureFlags, featureFlag) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Map.get(featureFlags, featureFlag), (function (param) {
                    return param.status;
                  })), "off");
}

function FeatureFlags__LocalFeatureFlags$FeatureFlagsProvider(Props) {
  var children = Props.children;
  var featureFlags = Props.featureFlags;
  var updateFeatureFlags = Props.updateFeatureFlags;
  var getFeatureFlagStatus$1 = React.useCallback((function (featureFlag) {
          return getFeatureFlagStatus(featureFlags, featureFlag);
        }), [featureFlags]);
  var setFeatureFlag = React.useCallback((function (featureFlag) {
          return function (status) {
            return Ignored$DecidrIoUtils.promise(AsyncStorage.default.setItem(FeatureFlags__AppSpecificConfig.storagePrefix + featureFlag, status).then(function (param) {
                            Curry._1(updateFeatureFlags, (function (prevFeatureFlags) {
                                    return Belt_Map.set(prevFeatureFlags, featureFlag, {
                                                status: status,
                                                disabled: false
                                              });
                                  }));
                          }));
          };
        }), [featureFlags]);
  return React.createElement(make, makeProps({
                  featureFlags: featureFlags,
                  getFeatureFlagStatus: getFeatureFlagStatus$1,
                  setFeatureFlag: Curry.__2(setFeatureFlag)
                }, children, undefined));
}

var FeatureFlagsProvider = {
  make: FeatureFlags__LocalFeatureFlags$FeatureFlagsProvider
};

export {
  getFeatureFlagStatus ,
  use ,
  useFeatureFlagsEnvironment ,
  FeatureFlagsProvider ,
}
/* context Not a pure module */
