// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ShopifyApi__Id from "../../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as GraphqlCore__Types from "../../../graphql-core/src/GraphqlCore__Types.bs.js";
import * as ShopifyApi__NewOrder from "../../../api-adapter-shopify/src/ShopifyApi__NewOrder.bs.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Core__FromShopify from "../core/GqlMiddleware__Core__FromShopify.bs.js";
import * as GqlMiddleware__Entity__ProductVariant from "./GqlMiddleware__Entity__ProductVariant.bs.js";

function makeNewOrderLineItem(param, orderId) {
  var variant = param.variant;
  return {
          __typename: "NewOrderLineItem",
          title: param.title,
          quantity: param.quantity,
          productVariant: variant !== undefined ? GqlMiddleware__Entity__ProductVariant.makeVariant(variant) : Js_exn.raiseError("makeNewOrderLineItem: variant not found. order id=" + GqlMiddleware__Core__NodeId.toString(orderId))
        };
}

function makeExternalAddress(param) {
  return {
          __typename: "ExternalAddress",
          streetLine1: param.address1,
          streetLine2: param.address2,
          city: param.city,
          country: param.country,
          recipientName: param.name,
          phone: param.phone,
          state: param.province,
          stateCode: param.provinceCode,
          postalCode: param.zip
        };
}

function makeNewOrder(param) {
  return {
          __typename: "NewOrder",
          id: GqlMiddleware__Core__NodeId.make(1, "NewOrder", ShopifyApi__Id.toString(param.id)),
          name: param.name,
          phone: param.phone,
          orderNumber: param.orderNumber,
          shippingAddress: Belt_Option.map(param.shippingAddress, makeExternalAddress),
          processedAt: param.processedAt,
          subtotalPrice: Belt_Option.map(param.subtotalPrice, GqlMiddleware__Core__FromShopify.money),
          totalShippingPrice: GqlMiddleware__Core__FromShopify.money(param.totalShippingPrice),
          totalTaxPrice: Belt_Option.map(param.totalTaxPrice, GqlMiddleware__Core__FromShopify.money),
          totalPrice: GqlMiddleware__Core__FromShopify.money(param.totalPrice)
        };
}

function fetchNewOrder(apiConfig, id) {
  return ShopifyApi__NewOrder.fetchNewOrderById(apiConfig, ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(id).internalId)).then(function (newOrder) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(newOrder, (function (param) {
                                RescriptUtils__Logger.warn2(GqlMiddleware__Core__Infra.logger, "fetchNewOrderById - no order found. Id:", GqlMiddleware__Core__NodeId.toString(id));
                              })), makeNewOrder);
            });
}

function fetchUserNewOrders(apiConfig, customerAccessToken, first, after) {
  return ShopifyApi__NewOrder.fetchOrders(apiConfig, customerAccessToken, first, after).then(function (__x) {
              return GraphqlCore__Types.Connection.map(__x, makeNewOrder);
            });
}

function fetchNewOrderLineItems(apiConfig, id, first, after) {
  return ShopifyApi__NewOrder.fetchOrderLineItems(apiConfig, GqlMiddleware__Core__NodeId.unpackExn(id).internalId, first, after).then(function (__x) {
              return GraphqlCore__Types.Connection.map(__x, (function (param) {
                            return makeNewOrderLineItem(param, id);
                          }));
            });
}

export {
  makeNewOrderLineItem ,
  fetchNewOrder ,
  fetchUserNewOrders ,
  fetchNewOrderLineItems ,
}
/* ShopifyApi__Id Not a pure module */
