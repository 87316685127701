// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";

var context = React.createContext({
      visibleDrawer: undefined,
      updateVisibleDrawer: (function (_drawer) {
          console.warn("Context not set up correctly - updateVisibleDrawer function not provided. Trying to store: ", _drawer);
        })
    });

function useWebDrawers(param) {
  return React.useContext(context);
}

var make = context.Provider;

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var ContextProvider = {
  make: make,
  makeProps: makeProps
};

function Ui__WebDrawersContext$WebDrawersProvider(Props) {
  var children = Props.children;
  var contextData = Props.contextData;
  return React.createElement(make, makeProps(contextData, children, undefined));
}

var WebDrawersProvider = {
  make: Ui__WebDrawersContext$WebDrawersProvider
};

function Ui__WebDrawersContext$WebDrawersConsumer(Props) {
  var children = Props.children;
  return Curry._1(children, React.useContext(context));
}

var WebDrawersConsumer = {
  make: Ui__WebDrawersContext$WebDrawersConsumer
};

export {
  context ,
  useWebDrawers ,
  ContextProvider ,
  WebDrawersProvider ,
  WebDrawersConsumer ,
}
/* context Not a pure module */
