// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Style$ReactNative from "rescript-react-native/src/apis/Style.bs.js";
import * as Media$DecidrIoResponsive from "@decidr-io/responsive/src/media/Media.bs.js";

var defaultBreakpoints = {
  xs: 375,
  s: 576,
  m: 768,
  l: 1024,
  xl: 1280,
  xxl: 1536
};

function useMedia(breakpointsOpt) {
  var breakpoints = breakpointsOpt !== undefined ? breakpointsOpt : defaultBreakpoints;
  var partial_arg = breakpoints;
  return function (param) {
    return Media$DecidrIoResponsive.useMedia(partial_arg, param);
  };
}

function determineMediaTag(width, breakpointsOpt, param) {
  var breakpoints = breakpointsOpt !== undefined ? breakpointsOpt : defaultBreakpoints;
  return Media$DecidrIoResponsive.determineMediaTag(width, breakpoints, undefined);
}

function widthLimiterStyle(isFormOpt, forceMaxWidth, param) {
  var isForm = isFormOpt !== undefined ? isFormOpt : false;
  return {
          alignSelf: "center",
          maxWidth: isForm ? 768 : Belt_Option.getWithDefault(forceMaxWidth, 1536),
          width: Style$ReactNative.pct(100)
        };
}

export {
  defaultBreakpoints ,
  useMedia ,
  determineMediaTag ,
  widthLimiterStyle ,
}
/* Media-DecidrIoResponsive Not a pure module */
