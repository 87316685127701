// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNativeSvg from "react-native-svg";

function Ui__OverstockedBadge(Props) {
  var sizeOpt = Props.size;
  var circleColor = Props.circleColor;
  var symbolColor = Props.symbolColor;
  var style = Props.style;
  var size = sizeOpt !== undefined ? sizeOpt : 24;
  var tmp = {
    viewBox: "0 0 24 24",
    width: size,
    height: size,
    children: React.createElement(ReactNativeSvg.G, {
          children: null,
          id: "Group 182"
        }, React.createElement(ReactNativeSvg.Circle, {
              cx: 12,
              cy: 12,
              r: 12,
              id: "Ellipse 278",
              fill: Belt_Option.getWithDefault(circleColor, "#E0D4BA")
            }), React.createElement(ReactNativeSvg.G, {
              children: React.createElement(ReactNativeSvg.Path, {
                    d: "M17.0495 5.60568L10.6342 5.92898L4.29784 14.4558L11.9228 20.122L18.2592 11.5952L17.0495 5.60568ZM13.7573 10.035C13.4803 9.82749 13.4199 9.43486 13.6223 9.15721C13.8247 8.87957 14.2128 8.82285 14.4898 9.03041C14.7669 9.23797 14.8273 9.63061 14.6248 9.90825C14.4224 10.1859 14.0344 10.2426 13.7573 10.035Z",
                    id: "Subtract",
                    fill: Belt_Option.getWithDefault(symbolColor, "#95775B"),
                    fillRule: "evenodd",
                    clipRule: "evenodd"
                  }),
              id: "Group 172"
            })),
    fill: "none"
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  return React.createElement(ReactNativeSvg.Svg, tmp);
}

var make = Ui__OverstockedBadge;

export {
  make ,
}
/* react Not a pure module */
