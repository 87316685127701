// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";

function useStyle(paddingHorizontal) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  return {
          backgroundColor: match.theme.colors.background,
          paddingHorizontal: paddingHorizontal
        };
}

export {
  useStyle ,
}
/* Ui__Styling Not a pure module */
