// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";

function waitFor(milliseconds) {
  var res = "nothing";
  return new Promise((function (resolve, _reject) {
                setTimeout((function (param) {
                        resolve(res);
                      }), milliseconds);
              }));
}

function retry(executeAsyncJob, onRetry, onError, maxRetries, timeoutOpt, param) {
  var timeout = timeoutOpt !== undefined ? timeoutOpt : 1000;
  var retryWithBackoff = function (retries) {
    var tmp;
    if (retries > 0) {
      var timeToWait = Math.imul(Math.pow(2, retries) | 0, timeout);
      tmp = waitFor(timeToWait).then(function (param) {
            return Curry._1(executeAsyncJob, undefined);
          });
    } else {
      tmp = Curry._1(executeAsyncJob, undefined);
    }
    return $$Promise.$$catch(tmp, (function (e) {
                  if (retries < maxRetries) {
                    Curry._1(onRetry, undefined);
                    return retryWithBackoff(retries + 1 | 0);
                  } else {
                    console.warn("Max retries reached. Bubbling the error up");
                    return Promise.resolve(Curry._1(onError, e));
                  }
                }));
  };
  return retryWithBackoff(0);
}

export {
  retry ,
}
/* No side effect */
