// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../styling/Ui__Styling.bs.js";
import * as Divider$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/divider/components/Divider.bs.js";

var match = Ui__Styling.describe({
      separator: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.primaryVariantShade1
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__Divider(Props) {
  var style = Props.style;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, match.theme);
  return React.createElement(Divider$DecidrIoUniversalComponents.make, {
              style: [
                Caml_option.some(Curry._1(styled, styles.separator)),
                style
              ]
            });
}

var make = Ui__Divider;

export {
  make ,
}
/* match Not a pure module */
