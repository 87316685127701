import Freshdesk from "freshdesk-api";

type FreshdeskApiConfig = {
  apiKey: string;
  baseUrl: string;
};

type Result = { id: number | undefined };

export const createTicket = async (
  config: FreshdeskApiConfig,
  name: string,
  email: string,
  subject: string,
  description: string
): Promise<Result> => {
  try {
    const freshdesk = new Freshdesk(config.baseUrl, config.apiKey);
    return new Promise((res, rej) =>
      freshdesk.createTicket(
        {
          name,
          email,
          subject,
          description,
          status: 2,
          priority: 1,
        },
        (error, data, _extra) => {
          if (error) {
            rej(error);
            return;
          }
          /* eslint-disable @typescript-eslint/no-unsafe-member-access */
          res({ id: Number.isFinite(data.id) ? data.id : undefined });
          /* eslint-enable */
        }
      )
    );
  } catch (error) {
    console.error(
      `<Unexpected> MiscApi__Freshdesk.createTicket - Error while creating ticket.`,
      error
    );
    throw error;
  }
};
