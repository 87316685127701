/* TypeScript file generated from App__ProfileInfo.resi by genType. */
/* eslint-disable import/first */


// @ts-ignore: Implicit any on import
import * as App__ProfileInfoBS__Es6Import from './App__ProfileInfo.bs';
const App__ProfileInfoBS: any = App__ProfileInfoBS__Es6Import;

import type {Environment_t as RescriptRelay_Environment_t} from '../../../../rescript-shims/RescriptRelay.shim';

import type {t as Promise_t} from '../../../../rescript-shims/Promise.shim';

export const getViewerAid: (_1:{ readonly environment: RescriptRelay_Environment_t }) => Promise_t<(null | undefined | string)> = function (Arg1: any) {
  const result = App__ProfileInfoBS.getViewerAid(Arg1.environment);
  return result
};
