// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function tap(opt, fn) {
  Curry._1(fn, opt);
  return opt;
}

function tapNone(opt, fn) {
  if (Belt_Option.isNone(opt)) {
    Curry._1(fn, undefined);
  }
  return opt;
}

function tapSome(opt, fn) {
  Belt_Option.forEach(opt, fn);
  return opt;
}

export {
  tap ,
  tapNone ,
  tapSome ,
}
/* No side effect */
