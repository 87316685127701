// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AsyncStorage from "@react-native-async-storage/async-storage";

function use(prim) {
  return AsyncStorage.useAsyncStorage(prim);
}

function ReactNativeAsyncStorage_getItem(prim) {
  return AsyncStorage.default.getItem(prim);
}

function ReactNativeAsyncStorage_setItem(prim0, prim1) {
  return AsyncStorage.default.setItem(prim0, prim1);
}

function ReactNativeAsyncStorage_removeItem(prim) {
  return AsyncStorage.default.removeItem(prim);
}

function ReactNativeAsyncStorage_useAsyncStorage(prim) {
  return AsyncStorage.useAsyncStorage(prim);
}

var ReactNativeAsyncStorage = {
  getItem: ReactNativeAsyncStorage_getItem,
  setItem: ReactNativeAsyncStorage_setItem,
  removeItem: ReactNativeAsyncStorage_removeItem,
  useAsyncStorage: ReactNativeAsyncStorage_useAsyncStorage
};

export {
  ReactNativeAsyncStorage ,
  use ,
}
/* @react-native-async-storage/async-storage Not a pure module */
