// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotText from "../../../text/components/Ui__MarkotText.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.primaryShade4
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__ImageFallback(Props) {
  var width = Props.width;
  var height = Props.height;
  var style = Props.style;
  var titleOpt = Props.title;
  var title = titleOpt !== undefined ? titleOpt : "No image";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var tmp = {};
  var tmp$1 = Belt_Option.map(height, (function (prim) {
          return prim;
        }));
  if (tmp$1 !== undefined) {
    tmp.height = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Belt_Option.map(width, (function (prim) {
          return prim;
        }));
  if (tmp$2 !== undefined) {
    tmp.width = Caml_option.valFromOption(tmp$2);
  }
  return React.createElement(ReactNative.View, {
              style: [
                Caml_option.some(Curry._1(styled, styles.container)),
                Caml_option.some(tmp),
                style
              ],
              children: React.createElement(Ui__MarkotText.make, {
                    size: "caption2",
                    color: theme.colors.onPrimaryShade4,
                    children: title
                  })
            });
}

var make = Ui__ImageFallback;

export {
  make ,
}
/* match Not a pure module */
