// This is a lite version of the client used to search only
import algoliasearch from "algoliasearch/lite";
import { SearchResponse } from "@algolia/client-search";
import runtimeConfig from "../runtime-config.json";

export type ApiConfig = {
  // This is your unique application identifier. It's used to identify you when using Algolia's API.
  appId: string;

  // This is the public API key to use in your frontend code.
  // This key is only usable for search queries and sending data to the Insights API.
  searchOnlyApiKey: string;
};

const searchRequestTimeoutSettings = {
  connect: 4,
  read: 10,
  write: 30, // not important since this client wont be used for write operations.
};

type Image = {
  url: string;
  size: { width: number; height: number };
};

type Money = { amount: string; currency: string };

type ProductVariant = {
  objectID: string;
  sku?: string | null;
  productId: string;
  compareAtPrice?: number | null;
  price: number;
  productPriceRange: string; // "from:to"
  mainImage?: Image | null;
  inventoryQuantity: number;
  brandName?: string | null;
  handle: string;
  productImage?: Image | null;
  position: number;
  productTitle: string;
  indexMinPrice: number;
  indexMaxPrice: number;
  usedForTesting?: boolean | null;
  sellingNewItems?: boolean | null;
  sellingPrelovedItems?: boolean | null;
  isRefurbishedProduct?: boolean | null;
  refurbishedProductModelYear?: string | null;
  refurbishedProductOriginalPrice?: Money | null;
  isOverstockedProduct?: boolean | null;
  overstockedProductModelYear?: string | null;
  overstockedProductOriginalPrice?: Money | null;
  openboxProductModelYear?: string | null;
  openboxProductOriginalPrice?: Money | null;
};

type PrelovedItem = {
  objectID: string;
  buyNowPrice: number;
  highestBid?: number | null;
  numberOfBids: number;
  condition: string;
  productSlug: string;
  productName: string;
  mainImage?: Image | null;
  status: string;
  purchaseAmount?: number | null;
  minimumBid?: number | null;
  usedForTesting?: boolean | null;
};

type ProductVariantSearchFilters = {
  brandId?: string | null;
  categoryIds?: string[] | null;
  price?:
    | {
        gt?: number | null;
        lt?: number | null;
      }[]
    | null;
  sellingNewItems?: boolean | null;
  sellingPrelovedItems?: boolean | null;
  facetFilters?: string[][];
};

type PriceFilterRange = {
  gt?: string | null;
  lt?: string | null;
};

type PrelovedItemSearchFilters = {
  productId?: string | null;
  status?: string[] | null;
  buyNowPrice?: PriceFilterRange[] | null;
  highestBid?: PriceFilterRange[] | null;
  condition?: string[];
};

type ProductVariantSearchSettings = {
  defaultVariantsOnly?: boolean | null;
  includeTestProducts?: boolean | null;
};

type PrelovedItemSearchSettings = {
  includeTestItems?: boolean | null;
};

// TODO: Remove when we implement separate gql query for getting facets
type AllFacets = {
  allBrandNames: { value: string; count: number }[];
  allCategoryNames: { value: string; count: number }[];
};

type ProductVariantSortInfo =
  | "PriceAsc"
  | "PriceDesc"
  | "DateAsc"
  | "DateDesc"
  | "NameAsc"
  | "NameDesc";

type PrelovedItemSortInfo =
  | "BuyNowPriceAsc"
  | "BuyNowPriceDesc"
  | "DateAsc"
  | "DateDesc"
  | "HighestBidAsc"
  | "HighestBidDesc"
  | "NumberOfBidsAsc"
  | "NumberOfBidsDesc";

// Used to limit attributes we retrieve from Algolia in order to
// lower response size and speed up the search.
// NOTE: should be kept in sync with relevant types
const attributesToRetrieve = {
  productVariant: [
    "objectID",
    "sku",
    "productId",
    "compareAtPrice",
    "price",
    "productPriceRange",
    "mainImage",
    "inventoryQuantity",
    "brandName",
    "handle",
    "productImage",
    "position",
    "productTitle",
    "indexMinPrice",
    "indexMaxPrice",
    "usedForTesting",
    "sellingNewItems",
    "sellingPrelovedItems",
    "openboxProductModelYear",
    "openboxProductOriginalPrice",
  ],
  prelovedItem: [
    "objectID",
    "buyNowPrice",
    "highestBid",
    "numberOfBids",
    "productSlug",
    "purchaseAmount",
    "status",
    "mainImage",
    "productName",
    "condition",
    "minimumBid",
    "usedForTesting",
  ],
};

// NOTE: In order to use some attribute for filtering it has to be defined as facet in index configuration.
const AttributesForFaceting = {
  // facets for product index.
  productIndexAttributesForFaceting: {
    brandName: "brandName",
    brandId: "brandId",
    price: "price",
    sellingNewItems: "sellingNewItems",
    sellingPrelovedItems: "sellingPrelovedItems",
    categoryNames: "categoryNames",
    categoryIds: "categoryIds",
    position: "position",
  },
  prelovedItemIndexAttributesForFaceting: {
    productId: "productId",
    status: "status",
    buyNowPrice: "buyNowPrice",
    highestBid: "highestBid",
    condition: "condition",
  },
} as const;

const AlgoliaAttributeName = {
  productVariant: {
    brandName: "brandName",
    price: "price",
    sellingNewItems: "sellingNewItems",
    sellingPrelovedItems: "sellingPrelovedItems",
    brandId: "brandId",
    categoryNames: "categoryNames",
    categoryIds: "categoryIds",
    position: "position",
    usedForTesting: "usedForTesting",
    hidden: "hidden",
    hiddenOutOfStock: "hiddenOutOfStock",
  },
  prelovedItem: {
    productId: "productId",
    listingStatus: "listingStatus",
    buyNowPrice: "buyNowPrice",
    highestBid: "highestBid",
    numberOfBids: "numberOfBids",
    productSlug: "productSlug",
    purchaseAmount: "purchaseAmount",
    status: "status",
    mainImage: "mainImage",
    productName: "productName",
    brandName: "brandName",
    condition: "condition",
    minimumBid: "minimumBid",
    usedForTesting: "usedForTesting",
    removedByMarkot: "removedByMarkot",
  },
} as const;

const getAppropriateReplicaForProductVariantIndex = (
  sortInfo?: ProductVariantSortInfo
) => {
  if (!sortInfo) return "markot-shopify-products";
  switch (sortInfo) {
    case "PriceAsc":
      return "markot-shopify-products_price_asc";
    case "PriceDesc":
      return "markot-shopify-products_price_desc";
    case "NameAsc":
      return "markot-shopify-products_title_asc";
    case "NameDesc":
      return "markot-shopify-products_title_desc";
    case "DateAsc":
      return "markot-shopify-products_date_asc";
    case "DateDesc":
      return "markot-shopify-products_date_desc";
    default:
      return "markot-shopify-products";
  }
};

// make appropriate for preloved items
const getAppropriateReplicaForPrelovedItemIndex = (
  sortInfo?: PrelovedItemSortInfo
) => {
  if (!sortInfo) return "markot-preloved-items";
  switch (sortInfo) {
    case "BuyNowPriceAsc":
      return "markot-preloved-items_buy_now_price_asc";
    case "BuyNowPriceDesc":
      return "markot-preloved-items_buy_now_price_desc";
    case "DateAsc":
      return "markot-preloved-items_date_asc";
    case "DateDesc":
      return "markot-preloved-items_date_desc";
    case "HighestBidAsc":
      return "markot-preloved-items_highest_bid_asc";
    case "HighestBidDesc":
      return "markot-preloved-items_highest_bid_desc";
    case "NumberOfBidsAsc":
      return "markot-preloved-items_number_of_bids_asc";
    case "NumberOfBidsDesc":
      return "markot-preloved-items_number_of_bids_desc";
    default:
      return "markot-preloved-items";
  }
};

// Making filter string in Algolia syntax
// https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/in-depth/combining-boolean-operators/
// Final filter string should be in conjunctive normal form (CNF). For example:
// brandName:"Bugaboo" AND (price:10 TO 50 OR price > 10 OR price:100 TO 200) AND categoryName:"prams" ...
const makeProductVariantFilterString = ({
  filters,
  settings,
}: {
  filters?: ProductVariantSearchFilters;
  settings?: ProductVariantSearchSettings;
}): string => {
  // We will create string representing CNF by putting individual
  // filter strings into an array and than join that array using AND
  const filterArr = filters
    ? Object.keys(filters).reduce((acc, filter) => {
        // In order not to modify function argument. We should create only pure functions.
        const filtersArr: string[] = [...acc];

        switch (filter) {
          case AttributesForFaceting.productIndexAttributesForFaceting.price:
            if (
              filters.price === undefined ||
              filters.price === null ||
              filters.price.length === 0
            ) {
              return filtersArr;
            }

            /* 
          Following reduce will transform arrays like [{gt:1, lt:5}, {gt:10, lt: undefined}] into a single string:
          price 1 TO 5 OR price > 10'
          this disjunction will be enclosed in parentheses and added to filtersArr in order to become part of CNF:
          brandName:"Bugaboo" AND (price 1 TO 5 OR price > 10) AND categoryName:"prams" ...
        */
            const disjunctionOfPriceFilters = filters.price
              .reduce((acc, priceFilter) => {
                const priceFiltersArr: string[] = [...acc];

                if (
                  !(priceFilter.gt === undefined || priceFilter.gt === null) &&
                  !(priceFilter.lt === undefined || priceFilter.lt === null)
                ) {
                  priceFiltersArr.push(
                    `${AlgoliaAttributeName.productVariant.price}:${priceFilter.gt} TO ${priceFilter.lt}`
                  );
                } else if (
                  !(priceFilter.gt === undefined || priceFilter.gt === null)
                ) {
                  priceFiltersArr.push(
                    `${AlgoliaAttributeName.productVariant.price} >= ${priceFilter.gt}`
                  );
                } else if (
                  !(priceFilter.lt === undefined || priceFilter.lt === null)
                ) {
                  priceFiltersArr.push(
                    `${AlgoliaAttributeName.productVariant.price} <= ${priceFilter.lt}`
                  );
                }

                return priceFiltersArr;
              }, new Array<string>())
              .join(" OR ");

            filtersArr.push(`(${disjunctionOfPriceFilters})`);

            return filtersArr;

          case AttributesForFaceting.productIndexAttributesForFaceting
            .sellingNewItems:
            if (
              filters.sellingNewItems === undefined ||
              filters.sellingNewItems === null
            ) {
              return filtersArr;
            }

            filtersArr.push(
              `${AlgoliaAttributeName.productVariant.sellingNewItems}:${String(
                filters.sellingNewItems
              )}`
            );
            return filtersArr;

          case AttributesForFaceting.productIndexAttributesForFaceting
            .sellingPrelovedItems:
            if (
              filters.sellingPrelovedItems === undefined ||
              filters.sellingPrelovedItems === null
            ) {
              return filtersArr;
            }

            filtersArr.push(
              `${
                AlgoliaAttributeName.productVariant.sellingPrelovedItems
              }:${String(filters.sellingPrelovedItems)}`
            );
            return filtersArr;

          case AttributesForFaceting.productIndexAttributesForFaceting.brandId:
            if (
              filters.brandId === "" ||
              filters.brandId === undefined ||
              filters.brandId === null
            ) {
              return filtersArr;
            }

            filtersArr.push(
              `${AlgoliaAttributeName.productVariant.brandId}:"${filters.brandId}"`
            );
            return filtersArr;

          case AttributesForFaceting.productIndexAttributesForFaceting
            .categoryIds:
            if (
              filters.categoryIds === undefined ||
              filters.categoryIds === null ||
              filters.categoryIds.length === 0
            ) {
              return filtersArr;
            }

            filters.categoryIds.forEach(catId =>
              filtersArr.push(
                `${AlgoliaAttributeName.productVariant.categoryIds}:"${catId}"`
              )
            );

            return filtersArr;

          // TODO: refactor to remove this
          case "facetFilters":
            return filtersArr;
          default:
            console.warn(
              `<Unexpected> [AlgoliaApi__Client.makeProductVariantFilterString] Unknown filter provided: ${filter}. Ignoring filter.`
            );
            return filtersArr;
        }
      }, new Array<string>())
    : [];

  // Filters for ProductVariant search settings
  if (settings?.defaultVariantsOnly) {
    // If only default variants are asked for filter only variants with position=1
    filterArr.push(`${AlgoliaAttributeName.productVariant.position}:1`);
  }

  // Exclude/include test products.
  if (!settings?.includeTestProducts) {
    filterArr.push(
      `${AlgoliaAttributeName.productVariant.usedForTesting}:false`
    );
  }

  // Always filter out hidden products.
  filterArr.push(`${AlgoliaAttributeName.productVariant.hidden}:false`);
  // Always filter out sold-out products.
  filterArr.push(
    `${AlgoliaAttributeName.productVariant.hiddenOutOfStock}:false`
  );
  return filterArr.join(" AND ");
};

const makePrelovedItemFilterString = ({
  filters,
  settings,
}: {
  filters?: PrelovedItemSearchFilters;
  settings?: PrelovedItemSearchSettings;
}): string => {
  const filterArr = filters
    ? Object.entries(filters).reduce((acc, [filterKey, filterValue]) => {
        const filtersArr: string[] = [...acc];

        switch (filterKey) {
          case AttributesForFaceting.prelovedItemIndexAttributesForFaceting
            .buyNowPrice:
          case AttributesForFaceting.prelovedItemIndexAttributesForFaceting
            .highestBid:
            if (
              filterValue === null ||
              filterValue === undefined ||
              filterValue.length === 0 ||
              !Array.isArray(filterValue)
            ) {
              return filtersArr;
            }

            const disjunctionOfPriceFilters = (
              filterValue as Array<PriceFilterRange>
            )
              .reduce((acc, priceFilter) => {
                const priceFiltersArr: string[] = [...acc];

                if (
                  !(
                    priceFilter.gt === undefined ||
                    priceFilter.gt === null ||
                    priceFilter.lt === undefined ||
                    priceFilter.lt === null
                  )
                ) {
                  priceFiltersArr.push(
                    `${filterKey}:${priceFilter.gt} TO ${priceFilter.lt}`
                  );
                } else if (
                  !(priceFilter.gt === undefined || priceFilter.gt === null)
                ) {
                  priceFiltersArr.push(`${filterKey} >= ${priceFilter.gt}`);
                } else if (
                  !(priceFilter.lt === undefined || priceFilter.lt === null)
                ) {
                  priceFiltersArr.push(`${filterKey} <= ${priceFilter.lt}`);
                }

                return priceFiltersArr;
              }, new Array<string>())
              .join(" OR ");

            filtersArr.push(`(${disjunctionOfPriceFilters})`);

            return filtersArr;

          case AttributesForFaceting.prelovedItemIndexAttributesForFaceting
            .productId:
          case AttributesForFaceting.prelovedItemIndexAttributesForFaceting
            .status:
            if (filterValue === null || filterValue === undefined) {
              return filtersArr;
            }

            filtersArr.push(`${filterKey}:"${filterValue as string}"`);
            return filtersArr;

          case AttributesForFaceting.prelovedItemIndexAttributesForFaceting
            .condition:
            if (
              filterValue === null ||
              filterValue === undefined ||
              !Array.isArray(filterValue) ||
              filterValue.length === 0
            ) {
              return filtersArr;
            }

            const conditionQueries = (filterValue as Array<string>).map(
              value => `${filterKey}:"${String(value)}"`
            );

            filtersArr.push(`(${conditionQueries.join(" OR ")})`);
            return filtersArr;

          default:
            console.warn(
              `<Unexpected> [AlgoliaApi__Client.makePrelovedItemFilterString] Unknown filter provided: ${filterKey}. Ignoring filter.`
            );
            return filtersArr;
        }
      }, new Array<string>())
    : [];

  // Exclude/include test products.
  if (!settings?.includeTestItems) {
    filterArr.push(`${AlgoliaAttributeName.prelovedItem.usedForTesting}:false`);
  }

  // Filter out records marked as RemovedByMarkot.
  filterArr.push(`${AlgoliaAttributeName.prelovedItem.removedByMarkot}:false`);

  return filterArr.join(" AND ");
};

// TODO: If too slow because of pagination we can try to switch to page based pagination
// https://www.algolia.com/doc/guides/building-search-ui/ui-and-ux-patterns/pagination/js/
export const searchProductVariantIndex = async (
  searchString: string,
  take: number,
  offset = 0,
  analyticsTags?: string[],
  config?: ApiConfig,
  filters?: ProductVariantSearchFilters,
  settings?: ProductVariantSearchSettings,
  sortInfo?: ProductVariantSortInfo,
  includeRefurbishedInfo?: boolean,
  includeOverstockedInfo?: boolean
): Promise<{
  searchResp: SearchResponse<ProductVariant>;
  allFacets: AllFacets;
}> => {
  let client;
  try {
    // Use passed config values or fallback to env variables
    client = algoliasearch(
      config?.appId ?? runtimeConfig.algoliaAppId,
      config?.searchOnlyApiKey ?? runtimeConfig.algoliaSearchOnlyApiKey,
      {
        timeouts: searchRequestTimeoutSettings,
      }
    );
  } catch (ex) {
    console.error(
      `<Unexpected> AlgoliaApi__Client.searchProductIndex - Unable to initiate Algolia client`,
      ex
    );
    throw ex;
  }

  try {
    const index = client.initIndex(
      getAppropriateReplicaForProductVariantIndex(sortInfo)
    );

    // TODO: Instead of this we should implement a separate gql query for getting facets
    const allBrandFacets = (
      await index.searchForFacetValues(
        AttributesForFaceting.productIndexAttributesForFaceting.brandName,
        searchString,
        // Default timeout is 2sec, but we will increase it as requests often timeout.
        { maxFacetHits: 100, timeout: 10 } // TODO: remove when we implement pagination for facets
      )
    ).facetHits.map(({ value, count }) => ({ value, count }));

    const allCategoryFacets = (
      await index.searchForFacetValues(
        AttributesForFaceting.productIndexAttributesForFaceting.categoryNames,
        searchString,
        // Default timeout is 2sec, but we will increase it as requests often timeout.
        { maxFacetHits: 100, timeout: 10 } // TODO: remove when we implement pagination for facets
      )
    ).facetHits.map(({ value, count }) => ({ value, count }));

    const finalAttributesToRetrieve = attributesToRetrieve.productVariant;
    // TODO includeRefurbishedInfo inline these attributes above when
    // removing feature flag
    if (includeRefurbishedInfo) {
      finalAttributesToRetrieve.push(
        "isRefurbishedProduct",
        "refurbishedProductModelYear",
        "refurbishedProductOriginalPrice"
      );
    }
    if (includeOverstockedInfo) {
      finalAttributesToRetrieve.push(
        "isOverstockedProduct",
        "overstockedProductModelYear",
        "overstockedProductOriginalPrice"
      );
    }
    finalAttributesToRetrieve.push("isOpenBoxProduct");
    const resp: SearchResponse<ProductVariant> = await index.search(
      searchString,
      {
        // Default timeout is 2sec, but we will increase it as requests often timeout.
        timeout: 10,
        attributesToRetrieve: finalAttributesToRetrieve,
        length: take,
        offset,
        filters: makeProductVariantFilterString({
          filters,
          settings,
        }),
        // Attributes for which we want possible facets returned so that they can show them on FE.
        facets: [
          AttributesForFaceting.productIndexAttributesForFaceting.brandName,
          AttributesForFaceting.productIndexAttributesForFaceting.categoryNames,

          // NOTE: We don't really want all possible values for price facet.
          // What we want is price to appear in facets_stats object in the response
          // so that we can get min/max price. But facets_stats doesn't appear in response
          // if that facet is not added to facets request param.
          AttributesForFaceting.productIndexAttributesForFaceting.price,
        ],
        // [[brand1, brand2] , [cat1, cat2]] = [[brand1 OR brand2] AND [cat1 OR cat2]]
        facetFilters: filters?.facetFilters ?? [],
        /*
           Non exclusive filters used for promoting.
           Records matching these filters get ranked higher. Each filter matched increases rank by 1 point:
           https://www.algolia.com/doc/guides/managing-results/relevance-overview/in-depth/ranking-criteria/#filters

           position:1 -> we rank default variants higher.

           _tags:has_image -> we rank variants with images higher.

           NOTE: We use _tags attribute to filter on null/nonexistent attributes
           https://www.algolia.com/doc/guides/managing-results/refine-results/filtering/how-to/filter-by-null-or-missing-attributes/
        */
        optionalFilters: ["position:1", "_tags:has_image"],
        // Don't use analytics if search term is empty string
        analytics: searchString.trim() === "" ? false : true,
        // TODO: remove second ternary when FE is implemented to send analyticsTags
        analyticsTags:
          analyticsTags != undefined
            ? analyticsTags
            : filters?.sellingPrelovedItems === true
            ? ["products_selling_preloved"]
            : [],
      }
    );

    const replaceNullWithUndefined = (
      variant: ProductVariant
    ): ProductVariant => ({
      ...variant,
      mainImage: variant.mainImage ?? undefined,
      productImage: variant.productImage ?? undefined,
      compareAtPrice: variant.compareAtPrice ?? undefined,
      refurbishedProductOriginalPrice:
        variant.refurbishedProductOriginalPrice ?? undefined,
      refurbishedProductModelYear:
        variant.refurbishedProductModelYear ?? undefined,
      overstockedProductOriginalPrice:
        variant.overstockedProductOriginalPrice ?? undefined,
      overstockedProductModelYear:
        variant.overstockedProductModelYear ?? undefined,
      openboxProductModelYear: variant.openboxProductModelYear ?? undefined,
      openboxProductOriginalPrice:
        variant.openboxProductOriginalPrice ?? undefined,
    });

    return {
      searchResp: {
        ...resp,
        hits: resp.hits.map(hit => replaceNullWithUndefined(hit)),
      },
      allFacets: {
        allBrandNames: allBrandFacets,
        allCategoryNames: allCategoryFacets,
      },
    };
  } catch (ex) {
    console.error(
      `<Unexpected> AlgoliaApi__Client.searchProductIndex - Error while searching index.`,
      ex
    );
    throw ex;
  }
};

export const searchPrelovedItemIndex = async (
  searchString: string,
  take: number,
  offset = 0,
  config?: ApiConfig,
  prelovedItemSortInfo?: PrelovedItemSortInfo,
  filters?: PrelovedItemSearchFilters,
  settings?: PrelovedItemSearchSettings,
  newPrelovedCards?: boolean
): Promise<SearchResponse<PrelovedItem>> => {
  let client;

  try {
    // Use passed config values or fallback to env variables
    client = algoliasearch(
      config?.appId ?? runtimeConfig.algoliaAppId,
      config?.searchOnlyApiKey ?? runtimeConfig.algoliaSearchOnlyApiKey,
      {
        timeouts: searchRequestTimeoutSettings,
      }
    );
  } catch (ex) {
    console.error(
      `<Unexpected> AlgoliaApi__Client.searchPrelovedItemIndex - Unable to initiate Algolia client`,
      ex
    );
    throw ex;
  }

  try {
    const index = client.initIndex(
      getAppropriateReplicaForPrelovedItemIndex(prelovedItemSortInfo)
    );

    const prelovedItemsResult: SearchResponse<PrelovedItem> =
      await index.search(searchString, {
        // Default timeout is 2sec, but we will increase it as requests often timeout.
        timeout: 10,
        attributesToRetrieve:
          // TODO newPrelovedCards inline this attribute above when
          // removing feature flag
          newPrelovedCards
            ? attributesToRetrieve.prelovedItem.concat(["brandName"])
            : attributesToRetrieve.prelovedItem,
        length: take,
        offset,
        facets: [
          AttributesForFaceting.prelovedItemIndexAttributesForFaceting
            .condition,
        ],
        filters: makePrelovedItemFilterString({
          filters,
          settings,
        }),
        // Don't use analytics if search term is empty string
        analytics: searchString.trim() === "" ? false : true, // analytics is always on.
        analyticsTags: ["preloved"],
      });

    const replaceNullWithUndefined = (
      prelovedItem: PrelovedItem
    ): PrelovedItem => ({
      ...prelovedItem,
      purchaseAmount: prelovedItem.purchaseAmount ?? undefined,
      highestBid:
        prelovedItem.highestBid !== null &&
        prelovedItem.highestBid !== undefined &&
        prelovedItem.highestBid > 0
          ? prelovedItem.highestBid
          : undefined,
      mainImage: prelovedItem.mainImage ?? undefined,
    });

    return {
      ...prelovedItemsResult,
      hits: prelovedItemsResult.hits.map(hit => replaceNullWithUndefined(hit)),
    };
  } catch (ex) {
    console.error(
      `<Unexpected> AlgoliaApi__Client.searchPrelovedItemIndex - Error while searching index.`,
      ex
    );
    throw ex;
  }
};
