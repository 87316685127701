// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MarkotApi__Id from "../../../api-adapter-markot/src/MarkotApi__Id.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Core__FromMarkot from "../core/GqlMiddleware__Core__FromMarkot.bs.js";

function makeBid(param) {
  return {
          __typename: "Bid",
          id: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.id)),
          timestamp: param.timestamp,
          amount: GqlMiddleware__Core__FromMarkot.money(param.amount),
          belongsToViewer: param.belongsToViewer,
          expiresAt: param.expiresAt,
          prelovedItemInfoId: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.prelovedItemInfoId))
        };
}

export {
  makeBid ,
}
/* MarkotApi__Id Not a pure module */
