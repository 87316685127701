// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Infra from "./MarkotApi__Infra.bs.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";

var Raw = {};

function parse(value) {
  var value$1 = value.viewer;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.wishlist;
    var value$3 = value$2.pageInfo;
    var value$4 = value$3.startCursor;
    var value$5 = value$3.endCursor;
    var value$6 = value$2.edges;
    tmp = {
      wishlist: {
        totalItems: value$2.totalItems,
        pageInfo: {
          startCursor: !(value$4 == null) ? value$4 : undefined,
          endCursor: !(value$5 == null) ? value$5 : undefined,
          hasPreviousPage: value$3.hasPreviousPage,
          hasNextPage: value$3.hasNextPage
        },
        edges: Js_array.map((function (value) {
                var value$1 = value.node;
                return {
                        cursor: value.cursor,
                        node: {
                          id: value$1.id
                        }
                      };
              }), value$6)
      }
    };
  }
  return {
          viewer: tmp
        };
}

function serialize(value) {
  var value$1 = value.viewer;
  var viewer;
  if (value$1 !== undefined) {
    var value$2 = value$1.wishlist;
    var value$3 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var value$2 = value$1.id;
            var node = {
              id: value$2
            };
            var value$3 = value.cursor;
            return {
                    cursor: value$3,
                    node: node
                  };
          }), value$3);
    var value$4 = value$2.pageInfo;
    var value$5 = value$4.hasNextPage;
    var value$6 = value$4.hasPreviousPage;
    var value$7 = value$4.endCursor;
    var endCursor = value$7 !== undefined ? value$7 : null;
    var value$8 = value$4.startCursor;
    var startCursor = value$8 !== undefined ? value$8 : null;
    var pageInfo = {
      startCursor: startCursor,
      endCursor: endCursor,
      hasPreviousPage: value$6,
      hasNextPage: value$5
    };
    var value$9 = value$2.totalItems;
    var wishlist = {
      totalItems: value$9,
      pageInfo: pageInfo,
      edges: edges
    };
    viewer = {
      wishlist: wishlist
    };
  } else {
    viewer = null;
  }
  return {
          viewer: viewer
        };
}

function serializeVariables(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  return {
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined
        };
}

var Raw$1 = {};

function parse$1(value) {
  var value$1 = value.addToWishlist;
  var value$2 = value$1.wishlistItem;
  return {
          addToWishlist: {
            wishlistItem: {
              id: value$2.id
            }
          }
        };
}

function serialize$1(value) {
  var value$1 = value.addToWishlist;
  var value$2 = value$1.wishlistItem;
  var value$3 = value$2.id;
  var wishlistItem = {
    id: value$3
  };
  var addToWishlist = {
    wishlistItem: wishlistItem
  };
  return {
          addToWishlist: addToWishlist
        };
}

function serializeVariables$1(inp) {
  return {
          itemId: inp.itemId
        };
}

var Raw$2 = {};

function parse$2(value) {
  var value$1 = value.removeFromWishlist;
  return {
          removeFromWishlist: {
            wishlistItemId: value$1.wishlistItemId
          }
        };
}

function serialize$2(value) {
  var value$1 = value.removeFromWishlist;
  var value$2 = value$1.wishlistItemId;
  var removeFromWishlist = {
    wishlistItemId: value$2
  };
  return {
          removeFromWishlist: removeFromWishlist
        };
}

function serializeVariables$2(inp) {
  return {
          itemId: inp.itemId
        };
}

function $$fetch(apiConfig, first, after) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: "query Wishlist($first: Int, $after: String)  {\nviewer  {\nwishlist: wishlist2(first: $first, after: $after)  {\ntotalItems  \npageInfo  {\nstartCursor  \nendCursor  \nhasPreviousPage  \nhasNextPage  \n}\n\nedges  {\ncursor  \nnode  {\nid  \n}\n\n}\n\n}\n\n}\n\n}\n",
                    Raw: Raw,
                    parse: parse,
                    serialize: serialize,
                    serializeVariables: serializeVariables
                  }), {
                first: first,
                after: after
              }, undefined).then(function (param) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(param.viewer, (function (param) {
                                RescriptUtils__Logger.warn(MarkotApi__Infra.logger, "Viewer null while fetching wishlist");
                              })), (function (param) {
                            var match = param.wishlist;
                            var pageInfo = match.pageInfo;
                            return {
                                    totalItems: match.totalItems,
                                    pageInfo: {
                                      startCursor: pageInfo.startCursor,
                                      endCursor: pageInfo.endCursor,
                                      hasNextPage: pageInfo.hasNextPage,
                                      hasPreviousPage: pageInfo.hasPreviousPage
                                    },
                                    edges: Belt_Array.map(match.edges, (function (param) {
                                            return {
                                                    cursor: param.cursor,
                                                    node: {
                                                      id: param.node.id
                                                    }
                                                  };
                                          }))
                                  };
                          }));
            });
}

function addToWishlist(apiConfig, itemId) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: "mutation AddToWishlist($itemId: ID!)  {\naddToWishlist(id: $itemId)  {\nwishlistItem  {\n__typename\nid  \n}\n\n}\n\n}\n",
                    Raw: Raw$1,
                    parse: parse$1,
                    serialize: serialize$1,
                    serializeVariables: serializeVariables$1
                  }), {
                itemId: itemId
              }, undefined).then(function (param) {
              
            });
}

function removeFromWishlist(apiConfig, itemId) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: "mutation RemoveFromWishlist($itemId: ID!)  {\nremoveFromWishlist(id: $itemId)  {\nwishlistItemId  \n}\n\n}\n",
                    Raw: Raw$2,
                    parse: parse$2,
                    serialize: serialize$2,
                    serializeVariables: serializeVariables$2
                  }), {
                itemId: itemId
              }, undefined).then(function (param) {
              
            });
}

export {
  $$fetch ,
  addToWishlist ,
  removeFromWishlist ,
}
/* MarkotApi__Infra Not a pure module */
