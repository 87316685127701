// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as ShopifyApi__Id from "./ShopifyApi__Id.bs.js";
import * as ShopifyApi__Infra from "./ShopifyApi__Infra.bs.js";
import * as ShopifyApi__Client from "./ShopifyApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as ShopifyApi__CommonFragments from "./ShopifyApi__CommonFragments.bs.js";
import * as ShopifyApi__TitleWithMetadata from "./ShopifyApi__TitleWithMetadata.bs.js";

var query = "fragment BrandCollectionFields on Collection   {\nid  \nhandle  \ndescription  \ntitle  \nimage  {\n...ImageFields   \n}\n\nseo  {\ntitle  \ndescription  \n}\n\n}\n" + ShopifyApi__CommonFragments.ImageFields.query;

function parse(value) {
  var value$1 = value.image;
  var value$2 = value.seo;
  var value$3 = value$2.title;
  var value$4 = value$2.description;
  return {
          id: value.id,
          handle: value.handle,
          description: value.description,
          title: ShopifyApi__TitleWithMetadata.parse(value.title),
          image: !(value$1 == null) ? ShopifyApi__CommonFragments.ImageFields.verifyArgsAndParse("ImageFields", value$1) : undefined,
          seo: {
            title: !(value$3 == null) ? value$3 : undefined,
            description: !(value$4 == null) ? value$4 : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.seo;
  var value$2 = value$1.description;
  var description = value$2 !== undefined ? value$2 : null;
  var value$3 = value$1.title;
  var title = value$3 !== undefined ? value$3 : null;
  var seo = {
    title: title,
    description: description
  };
  var value$4 = value.image;
  var image = value$4 !== undefined ? ShopifyApi__CommonFragments.ImageFields.serialize(value$4) : null;
  var value$5 = value.title;
  var value$6 = ShopifyApi__TitleWithMetadata.serialize(value$5);
  var value$7 = value.description;
  var value$8 = value.handle;
  var value$9 = value.id;
  return {
          id: value$9,
          handle: value$8,
          description: value$7,
          title: value$6,
          image: image,
          seo: seo
        };
}

var Raw = {};

var query$1 = "query BrandCollections($first: Int, $after: String)  {\ncollections(first: $first, after: $after, query: \"[brand] AND title:[brand]\")  {\npageInfo  {\nhasNextPage  \nendCursor  \nhasPreviousPage  \nstartCursor  \n}\n\nedges  {\ncursor  \nnode  {\n...BrandCollectionFields   \n}\n\n}\n\n}\n\n}\n" + query;

function parse$1(value) {
  var value$1 = value.collections;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.endCursor;
  var value$4 = value$2.startCursor;
  var value$5 = value$1.edges;
  return {
          collections: {
            pageInfo: {
              hasNextPage: value$2.hasNextPage,
              endCursor: !(value$3 == null) ? value$3 : undefined,
              hasPreviousPage: value$2.hasPreviousPage,
              startCursor: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    return {
                            cursor: value.cursor,
                            node: parse(value.node)
                          };
                  }), value$5)
          }
        };
}

function serialize$1(value) {
  var value$1 = value.collections;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var node = serialize(value$1);
          var value$2 = value.cursor;
          return {
                  cursor: value$2,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.startCursor;
  var startCursor = value$4 !== undefined ? value$4 : null;
  var value$5 = value$3.hasPreviousPage;
  var value$6 = value$3.endCursor;
  var endCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$3.hasNextPage;
  var pageInfo = {
    hasNextPage: value$7,
    endCursor: endCursor,
    hasPreviousPage: value$5,
    startCursor: startCursor
  };
  var collections = {
    pageInfo: pageInfo,
    edges: edges
  };
  return {
          collections: collections
        };
}

function serializeVariables(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  return {
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined
        };
}

var Raw$1 = {};

var query$2 = "query BrandCollectionById($id: ID!)  {\ncollection(id: $id)  {\n...BrandCollectionFields   \n}\n\n}\n" + query;

function parse$2(value) {
  var value$1 = value.collection;
  return {
          collection: !(value$1 == null) ? parse(value$1) : undefined
        };
}

function serialize$2(value) {
  var value$1 = value.collection;
  var collection = value$1 !== undefined ? serialize(value$1) : null;
  return {
          collection: collection
        };
}

function serializeVariables$1(inp) {
  return {
          id: inp.id
        };
}

var Raw$2 = {};

var query$3 = "query BrandCollectionsByIds($ids: [ID!]!)  {\nnodes(ids: $ids)  {\n__typename\n...on Collection   {\n...BrandCollectionFields   \n}\n\n}\n\n}\n" + query;

function parse$3(value) {
  var value$1 = value.nodes;
  return {
          nodes: Js_array.map((function (value) {
                  if (value == null) {
                    return ;
                  }
                  var typename = value["__typename"];
                  var tmp = typename === "Collection" ? ({
                        NAME: "Collection",
                        VAL: {
                          brandCollectionFields: parse(value)
                        }
                      }) : ({
                        NAME: "UnspecifiedFragment",
                        VAL: typename
                      });
                  return tmp;
                }), value$1)
        };
}

function serialize$3(value) {
  var value$1 = value.nodes;
  var nodes = Js_array.map((function (value) {
          if (value !== undefined) {
            if (value.NAME === "Collection") {
              return Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                            return {};
                          }), [serialize(value.VAL.brandCollectionFields)]);
            } else {
              return {};
            }
          } else {
            return null;
          }
        }), value$1);
  return {
          nodes: nodes
        };
}

function serializeVariables$2(inp) {
  var a = inp.ids;
  return {
          ids: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

var Raw$3 = {};

function parse$4(value) {
  var value$1 = value.collection;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.products;
    var value$3 = value$2.filters;
    tmp = {
      products: {
        filters: Js_array.map((function (value) {
                var value$1 = value.values;
                return {
                        id: value.id,
                        values: Js_array.map((function (value) {
                                return {
                                        label: value.label
                                      };
                              }), value$1)
                      };
              }), value$3)
      }
    };
  }
  return {
          collection: tmp
        };
}

function serialize$4(value) {
  var value$1 = value.collection;
  var collection;
  if (value$1 !== undefined) {
    var value$2 = value$1.products;
    var value$3 = value$2.filters;
    var filters = Js_array.map((function (value) {
            var value$1 = value.values;
            var values = Js_array.map((function (value) {
                    var value$1 = value.label;
                    return {
                            label: value$1
                          };
                  }), value$1);
            var value$2 = value.id;
            return {
                    id: value$2,
                    values: values
                  };
          }), value$3);
    var products = {
      filters: filters
    };
    collection = {
      products: products
    };
  } else {
    collection = null;
  }
  return {
          collection: collection
        };
}

function serializeVariables$3(inp) {
  return {
          categoryId: inp.categoryId
        };
}

function makeBrand(param) {
  var seo = param.seo;
  return {
          id: ShopifyApi__Id.parseExn(param.id),
          name: ShopifyApi__TitleWithMetadata.toTitle(param.title),
          description: param.description,
          handle: param.handle,
          image: Belt_Option.flatMap(param.image, (function (image) {
                  var id = image.id;
                  if (id !== undefined) {
                    var width = image.width;
                    if (width !== undefined) {
                      var height = image.height;
                      if (height !== undefined) {
                        return {
                                id: id,
                                url: image.url,
                                alt: image.altText,
                                size: {
                                  width: width,
                                  height: height
                                }
                              };
                      }
                      
                    }
                    
                  }
                  RescriptUtils__Logger.error2(ShopifyApi__Infra.logger, (new Error("Brand image doesn't have some of: id/width/height")), image);
                })),
          seo: {
            description: seo.description,
            title: seo.title
          }
        };
}

function fetchBrand(apiConfig, id) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$2,
                    Raw: Raw$1,
                    parse: parse$2,
                    serialize: serialize$2,
                    serializeVariables: serializeVariables$1
                  }), {
                id: ShopifyApi__Id.toString(id)
              }, undefined).then(function (param) {
              return RescriptUtils__OptionPlus.tapNone(Belt_Option.map(param.collection, makeBrand), (function (param) {
                            RescriptUtils__Logger.error2(ShopifyApi__Infra.logger, (new Error("Brand collection not found")), {
                                  id: id
                                });
                          }));
            });
}

function fetchBrandsByIds(apiConfig, ids) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                      query: query$3,
                      Raw: Raw$2,
                      parse: parse$3,
                      serialize: serialize$3,
                      serializeVariables: serializeVariables$2
                    }), {
                  ids: Belt_Array.map(ids, ShopifyApi__Id.toString)
                }, undefined).then(function (param) {
                return param.nodes;
              }).then(function (__x) {
              return Belt_Array.mapWithIndex(__x, (function (i, node) {
                            if (node !== undefined) {
                              if (node.NAME === "Collection") {
                                return makeBrand(node.VAL.brandCollectionFields);
                              } else {
                                RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "fetchBrandsByIds - unexpected typename found. Node:", node);
                                return ;
                              }
                            } else {
                              RescriptUtils__Logger.error2(ShopifyApi__Infra.logger, (new Error("A brand in a list not found")), {
                                    index: i,
                                    ids: Belt_Array.map(ids, ShopifyApi__Id.toString)
                                  });
                              return ;
                            }
                          }));
            });
}

function fetchBrands(apiConfig, first, after, param, param$1) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$1,
                    Raw: Raw,
                    parse: parse$1,
                    serialize: serialize$1,
                    serializeVariables: serializeVariables
                  }), {
                first: first,
                after: after
              }, undefined).then(function (param) {
              var match = param.collections;
              var pageInfo = match.pageInfo;
              return {
                      pageInfo: {
                        startCursor: pageInfo.startCursor,
                        endCursor: pageInfo.endCursor,
                        hasNextPage: pageInfo.hasNextPage,
                        hasPreviousPage: pageInfo.hasPreviousPage
                      },
                      edges: Belt_Array.map(match.edges, (function (edge) {
                              return {
                                      cursor: edge.cursor,
                                      node: makeBrand(edge.node)
                                    };
                            }))
                    };
            });
}

function fetchCategoryBrands(apiConfig, categoryId, first, param) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: "query CategoryBrands($categoryId: ID!)  {\ncollection(id: $categoryId)  {\nproducts(first: 1)  {\nfilters  {\nid  \nvalues  {\nlabel  \n}\n\n}\n\n}\n\n}\n\n}\n",
                    Raw: Raw$3,
                    parse: parse$4,
                    serialize: serialize$4,
                    serializeVariables: serializeVariables$3
                  }), {
                categoryId: ShopifyApi__Id.toString(categoryId)
              }, undefined).then(function (param) {
              return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.map(Belt_Option.map(Belt_Option.map(Belt_Option.map(RescriptUtils__OptionPlus.tapNone(Belt_Option.flatMap(Belt_Option.map(RescriptUtils__OptionPlus.tapNone(param.collection, (function (param) {
                                                                RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "fetchCategoryBrands - Brand collection not found. Id:", categoryId);
                                                              })), (function (param) {
                                                            return param.products.filters;
                                                          })), (function (__x) {
                                                        return Belt_Array.getBy(__x, (function (param) {
                                                                      return param.id === "filter.p.m.real-concepts.prod-brand-collection-id";
                                                                    }));
                                                      })), (function (param) {
                                                    RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "fetchCategoryBrands - brand filter not found. Id:", categoryId);
                                                  })), (function (param) {
                                                return param.values;
                                              })), (function (param) {
                                            return Belt_Array.slice(param, 0, first);
                                          })), (function (__x) {
                                        return Belt_Array.map(__x, (function (param) {
                                                      return param.label;
                                                    }));
                                      })), (function (__x) {
                                    return Belt_Array.map(__x, ShopifyApi__Id.parseExn);
                                  })), (function (brandIds) {
                                return fetchBrandsByIds(apiConfig, brandIds).then(function (__x) {
                                              return Belt_Array.keepMap(__x, (function (x) {
                                                            return x;
                                                          }));
                                            }).then(function (brands) {
                                            return {
                                                    pageInfo: {
                                                      startCursor: undefined,
                                                      endCursor: undefined,
                                                      hasNextPage: false,
                                                      hasPreviousPage: false
                                                    },
                                                    edges: Belt_Array.map(brands, (function (brand) {
                                                            return {
                                                                    cursor: "no-pagination",
                                                                    node: brand
                                                                  };
                                                          }))
                                                  };
                                          });
                              })), Promise.resolve({
                              pageInfo: {
                                startCursor: undefined,
                                endCursor: undefined,
                                hasNextPage: false,
                                hasPreviousPage: false
                              },
                              edges: []
                            }));
            });
}

export {
  fetchBrand ,
  fetchBrandsByIds ,
  fetchBrands ,
  fetchCategoryBrands ,
}
/* ShopifyApi__Id Not a pure module */
