// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNative from "react-native";
import * as Ui__ImageFactory from "./Ui__ImageFactory.bs.js";
import * as Ui__Image__Adapters from "./Ui__Image__Adapters.bs.js";

var WordPressImage = Ui__ImageFactory.Make(Ui__Image__Adapters.WordPress);

var FilestackImage = Ui__ImageFactory.Make(Ui__Image__Adapters.Filestack);

var ShopifyImage = Ui__ImageFactory.Make(Ui__Image__Adapters.Shopify);

function Ui__MarkotImage(Props) {
  var image = Props.image;
  var alt = Props.alt;
  var size = Props.size;
  var resizeModeOpt = Props.resizeMode;
  var priorityOpt = Props.priority;
  var style = Props.style;
  var testID = Props.testID;
  var tintColor = Props.tintColor;
  var resizeMode = resizeModeOpt !== undefined ? resizeModeOpt : "cover";
  var priority = priorityOpt !== undefined ? priorityOpt : "normal";
  if (image.src.includes(Ui__Image__Adapters.Filestack.domain)) {
    var tmp = {
      image: image,
      alt: alt,
      size: size,
      resizeMode: resizeMode,
      priority: priority
    };
    if (style !== undefined) {
      tmp.style = Caml_option.valFromOption(style);
    }
    if (testID !== undefined) {
      tmp.testID = testID;
    }
    if (tintColor !== undefined) {
      tmp.tintColor = Caml_option.valFromOption(tintColor);
    }
    return React.createElement(FilestackImage.make, tmp);
  }
  if (image.src.includes(Ui__Image__Adapters.Shopify.domain)) {
    var tmp$1 = {
      image: image,
      alt: alt,
      size: size,
      resizeMode: resizeMode,
      priority: priority
    };
    if (style !== undefined) {
      tmp$1.style = Caml_option.valFromOption(style);
    }
    if (testID !== undefined) {
      tmp$1.testID = testID;
    }
    if (tintColor !== undefined) {
      tmp$1.tintColor = Caml_option.valFromOption(tintColor);
    }
    return React.createElement(ShopifyImage.make, tmp$1);
  }
  if (image.src.includes(Ui__Image__Adapters.WordPress.domain)) {
    var tmp$2 = {
      image: image,
      alt: alt,
      size: size,
      resizeMode: resizeMode,
      priority: priority
    };
    if (style !== undefined) {
      tmp$2.style = Caml_option.valFromOption(style);
    }
    if (testID !== undefined) {
      tmp$2.testID = testID;
    }
    if (tintColor !== undefined) {
      tmp$2.tintColor = Caml_option.valFromOption(tintColor);
    }
    return React.createElement(WordPressImage.make, tmp$2);
  }
  console.warn("Unexpected thing happened in ", "Ui__MarkotImage", "Received an unsupported image" + image.src);
  var match = Ui__ImageFactory.getWidthAndHeight(size);
  var imageStyle = Ui__ImageFactory.getImageStyle(size);
  var tmp$3 = {};
  var tmp$4 = Belt_Option.map(match[1], (function (prim) {
          return prim;
        }));
  if (tmp$4 !== undefined) {
    tmp$3.height = Caml_option.valFromOption(tmp$4);
  }
  var tmp$5 = Belt_Option.map(match[0], (function (prim) {
          return prim;
        }));
  if (tmp$5 !== undefined) {
    tmp$3.width = Caml_option.valFromOption(tmp$5);
  }
  var tmp$6 = {
    accessibilityLabel: alt,
    resizeMode: Ui__ImageFactory.ResizeMode.toRNResizeMode(resizeMode),
    source: {
      uri: image.src
    },
    style: [
      Caml_option.some(tmp$3),
      imageStyle,
      style
    ]
  };
  if (testID !== undefined) {
    tmp$6.testID = testID;
  }
  return React.createElement(ReactNative.Image, tmp$6);
}

var dpToSizes = Ui__ImageFactory.dpToSizes;

var deviceSizes = Ui__Image__Adapters.deviceSizes;

var imageSizes = Ui__Image__Adapters.imageSizes;

var Size;

var ResizeMode;

var make = Ui__MarkotImage;

export {
  dpToSizes ,
  deviceSizes ,
  imageSizes ,
  Size ,
  ResizeMode ,
  WordPressImage ,
  make ,
}
/* WordPressImage Not a pure module */
