// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as ShopifyApi__Id from "../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";

var Raw = {};

function parse(value) {
  var value$1 = value.setViewerBag;
  return {
          setViewerBag: {
            bagId: value$1.bagId
          }
        };
}

function serialize(value) {
  var value$1 = value.setViewerBag;
  var value$2 = value$1.bagId;
  var setViewerBag = {
    bagId: value$2
  };
  return {
          setViewerBag: setViewerBag
        };
}

function serializeInputObjectExternalRefInput(inp) {
  return {
          externalId: inp.externalId
        };
}

function serializeVariables(inp) {
  return {
          bagRef: serializeInputObjectExternalRefInput(inp.bagRef)
        };
}

function setViewerBag(apiConfig, bagRefInput) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: "mutation SetViewerBag($bagRef: ExternalRefInput!)  {\nsetViewerBag(bagId: $bagRef)  {\nbagId  \n}\n\n}\n",
                    Raw: Raw,
                    parse: parse,
                    serialize: serialize,
                    serializeVariables: serializeVariables
                  }), {
                bagRef: {
                  externalId: ShopifyApi__Id.toString(bagRefInput.externalId)
                }
              }, undefined).then(function (param) {
              return {
                      bagId: MarkotApi__Id.parseExn(param.setViewerBag.bagId)
                    };
            });
}

function setViewerBagWithNodeId(apiConfig, bagId) {
  return setViewerBag(apiConfig, {
              externalId: ShopifyApi__Id.parseExn(MarkotApi__Id.unpackExn(bagId).internalId)
            });
}

export {
  setViewerBag ,
  setViewerBagWithNodeId ,
}
/* MarkotApi__Id Not a pure module */
