/* TypeScript file generated from App__RelayEnv.resi by genType. */
/* eslint-disable import/first */


// @ts-ignore: Implicit any on import
import * as App__RelayEnvBS__Es6Import from './App__RelayEnv.bs';
const App__RelayEnvBS: any = App__RelayEnvBS__Es6Import;

import type {Relay_RawRelay_Environment_t as Utils_Relay_RawRelay_Environment_t} from '../../../../packages/utils/src/Utils.gen';

export const makeEnvironment: () => Utils_Relay_RawRelay_Environment_t = App__RelayEnvBS.makeEnvironment;
