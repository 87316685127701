// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "../../../api-adapter-markot/src/MarkotApi__Id.bs.js";
import * as GraphqlCore__Types from "../../../graphql-core/src/GraphqlCore__Types.bs.js";
import * as MarkotApi__BlogPost from "../../../api-adapter-markot/src/MarkotApi__BlogPost.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Core__FromMarkot from "../core/GqlMiddleware__Core__FromMarkot.bs.js";

function makeBlogPostId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "BlogPost", param);
}

function makeBlogPost(param) {
  return {
          __typename: "BlogPost",
          id: GqlMiddleware__Core__NodeId.make(1, "BlogPost", MarkotApi__Id.unpackExn(param.id).internalId),
          title: param.title,
          url: param.url,
          blurb: param.blurb,
          coverImage: Belt_Option.map(param.coverImage, GqlMiddleware__Core__FromMarkot.imageResource),
          slug: param.slug
        };
}

function fetchBlogPost(apiConfig, id) {
  return MarkotApi__BlogPost.fetchBasicData(apiConfig, MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(id))).then(function (__x) {
              return Belt_Option.map(__x, makeBlogPost);
            });
}

function fetchBlogPosts(apiConfig, first, after, tag) {
  return MarkotApi__BlogPost.fetchBlogPosts(apiConfig, first, after, tag).then(function (__x) {
              return Belt_Option.map(__x, (function (__x) {
                            return GraphqlCore__Types.Connection.map(__x, makeBlogPost);
                          }));
            });
}

export {
  makeBlogPostId ,
  makeBlogPost ,
  fetchBlogPost ,
  fetchBlogPosts ,
}
/* MarkotApi__Id Not a pure module */
