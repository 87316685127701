// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Ui__Theme from "../../theme/components/Ui__Theme.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "../../routing/Ui__AppLink.bs.js";
import * as Ui__Divider from "../../divider/Ui__Divider.bs.js";
import * as Ui__Heading from "../../text/components/Ui__Heading.bs.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Utils__A11y from "../../../../utils/src/Utils__A11y.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotIcon from "../../icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__PageSection$Heading(Props) {
  var left = Props.left;
  var right = Props.right;
  var testIDOpt = Props.testID;
  var containerStyle = Props.containerStyle;
  var testID = testIDOpt !== undefined ? testIDOpt : "page-section-heading";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, undefined);
  return React.createElement(Ui__Group.make, {
              spacing: match.theme.spacing._6,
              children: null,
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles.container)),
                containerStyle
              ],
              orientation: "horizontal",
              testID: testID
            }, left, right);
}

var match$1 = Ui__Styling.describe({
      divider: {
        TAG: /* Dynamic */1,
        _0: (function (props) {
            return {
                    backgroundColor: props.theme.colors.primaryShade3,
                    height: props.dividerThickness
                  };
          })
      },
      count: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "bold"
        }
      }
    });

var stylesheet$1 = match$1[1];

var styles$1 = match$1[0];

function Ui__PageSection$HeadingWithCount(Props) {
  var title = Props.title;
  var count = Props.count;
  var levelOpt = Props.level;
  var style = Props.style;
  var level = levelOpt !== undefined ? levelOpt : 2;
  var match = Curry._1(Ui__Theme.useTheme, undefined);
  var theme = match.theme;
  var match$1 = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        [
          theme.lineThickness.xxs,
          theme.spacing._4
        ],
        undefined,
        [
          theme.lineThickness.xs,
          theme.spacing._5
        ],
        undefined,
        [
          theme.lineThickness.xs,
          theme.spacing._6
        ],
        [
          theme.lineThickness.xs,
          theme.spacing._7
        ],
        [
          theme.lineThickness.xxs,
          theme.spacing._4
        ],
        undefined
      ]);
  var styled = Curry._1(stylesheet$1, {
        theme: theme,
        dividerThickness: match$1[0]
      });
  var tmp = {
    spacing: match$1[1],
    children: null,
    testID: "heading-with-count"
  };
  if (style !== undefined) {
    tmp.containerStyle = Caml_option.valFromOption(style);
  }
  return React.createElement(Ui__Group.make, tmp, React.createElement(Ui__Heading.make, {
                  fontFamily: theme.fontFamily.dm,
                  size: "b2",
                  color: theme.colors.primary,
                  level: level,
                  children: null
                }, SimpleReact$DecidrIoUtils.renderIfSome(count, (function (count) {
                        return React.createElement(Ui__MarkotText.make, {
                                    size: "b2",
                                    children: "" + String(count) + " ",
                                    style: Curry._1(styled, styles$1.count),
                                    testID: "heading-with-count-value"
                                  });
                      })), title), React.createElement(Ui__Divider.make, {
                  style: Curry._1(styled, styles$1.divider)
                }));
}

var match$2 = Ui__Styling.describe({
      divider: {
        TAG: /* Dynamic */1,
        _0: (function (props) {
            return {
                    backgroundColor: props.theme.colors.primaryShade3,
                    height: props.dividerThickness
                  };
          })
      }
    });

var stylesheet$2 = match$2[1];

var styles$2 = match$2[0];

function Ui__PageSection$HeadingWithDivider(Props) {
  var title = Props.title;
  var levelOpt = Props.level;
  var style = Props.style;
  var level = levelOpt !== undefined ? levelOpt : 2;
  var match = Curry._1(Ui__Theme.useTheme, undefined);
  var theme = match.theme;
  var match$1 = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        [
          theme.lineThickness.xxs,
          theme.spacing._4
        ],
        undefined,
        [
          theme.lineThickness.xs,
          theme.spacing._5
        ],
        undefined,
        [
          theme.lineThickness.xs,
          theme.spacing._6
        ],
        [
          theme.lineThickness.xs,
          theme.spacing._7
        ],
        [
          theme.lineThickness.xxs,
          theme.spacing._4
        ],
        undefined
      ]);
  var styled = Curry._1(stylesheet$2, {
        theme: theme,
        dividerThickness: match$1[0]
      });
  var tmp = {
    spacing: match$1[1],
    children: null
  };
  if (style !== undefined) {
    tmp.containerStyle = Caml_option.valFromOption(style);
  }
  return React.createElement(Ui__Group.make, tmp, React.createElement(Ui__Heading.make, {
                  fontFamily: theme.fontFamily.dm,
                  size: "b2",
                  color: theme.colors.primary,
                  level: level,
                  children: title
                }), React.createElement(Ui__Divider.make, {
                  style: Curry._1(styled, styles$2.divider)
                }));
}

var match$3 = Ui__Styling.describe({
      heading: {
        TAG: /* Dynamic */1,
        _0: (function (props) {
            return {
                    paddingHorizontal: props.paddingHorizontal,
                    paddingTop: props.paddingTop
                  };
          })
      },
      left: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      seeMoreIcon: {
        TAG: /* Static */0,
        _0: {
          alignSelf: "center"
        }
      }
    });

var stylesheet$3 = match$3[1];

var styles$3 = match$3[0];

function Ui__PageSection$CommonHeading(Props) {
  var title = Props.title;
  var iconTestIdOpt = Props.iconTestId;
  var containerStyle = Props.containerStyle;
  var route = Props.route;
  var a11yLabelOpt = Props.a11yLabel;
  var iconTestId = iconTestIdOpt !== undefined ? iconTestIdOpt : "open-list-button";
  var a11yLabel = a11yLabelOpt !== undefined ? a11yLabelOpt : "You will go to undefined route";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        undefined,
        {
          seeMoreIconSize: 24,
          paddingTop: theme.spacing._12,
          paddingHorizontal: theme.spacing._28
        },
        undefined,
        {
          seeMoreIconSize: 18,
          paddingTop: theme.spacing._14,
          paddingHorizontal: theme.spacing._38
        },
        undefined,
        {
          seeMoreIconSize: 18,
          paddingTop: theme.spacing._12,
          paddingHorizontal: theme.spacing._12
        },
        undefined
      ]);
  var styled = Curry._1(stylesheet$3, {
        paddingTop: match$1.paddingTop,
        paddingHorizontal: match$1.paddingHorizontal
      });
  var match$2 = Curry._1(Ui__AppLink.useRouting, undefined);
  var nativeNavigationHandler = match$2.nativeNavigationHandler;
  var routeConfiguration = Curry._3(Ui__AppLink.makeConfiguration, route, undefined, undefined);
  var tmp = {
    style: Curry._1(styled, styles$3.left),
    children: React.createElement(Ui__AppLink.make, {
          configuration: routeConfiguration,
          children: React.createElement(Ui__Heading.make, {
                fontFamily: theme.fontFamily.libre,
                size: "title3",
                level: 2,
                children: title,
                ellipsizeMode: "tail",
                numberOfLines: 1
              }),
          a11yLabel: a11yLabel,
          wrapContent: true
        })
  };
  var tmp$1 = Belt_Option.map(nativeNavigationHandler, (function (navHandler, param) {
          Curry._1(navHandler, routeConfiguration);
        }));
  if (tmp$1 !== undefined) {
    tmp.onPress = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    testID: iconTestId,
    children: React.createElement(Ui__AppLink.make, {
          configuration: routeConfiguration,
          children: React.createElement(Ui__Group.make, {
                spacing: theme.spacing._4,
                children: null,
                orientation: "horizontal"
              }, React.createElement(Ui__MarkotText.make, {
                    size: "b1",
                    children: "See more"
                  }), React.createElement(Ui__MarkotIcon.make, {
                    name: "arrow-right",
                    size: match$1.seeMoreIconSize,
                    style: Curry._1(styled, styles$3.seeMoreIcon)
                  })),
          a11yLabel: a11yLabel,
          wrapContent: true
        })
  };
  var tmp$3 = Belt_Option.map(nativeNavigationHandler, (function (navHandler, param) {
          Curry._1(navHandler, routeConfiguration);
        }));
  if (tmp$3 !== undefined) {
    tmp$2.onPress = Caml_option.valFromOption(tmp$3);
  }
  return React.createElement(Ui__PageSection$Heading, {
              left: React.createElement(ReactNative.TouchableOpacity, tmp),
              right: React.createElement(ReactNative.TouchableOpacity, tmp$2),
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles$3.heading)),
                containerStyle
              ]
            });
}

var match$4 = Ui__Styling.describe({
      accentColorStyle: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.backgroundVariant
                  };
          })
      }
    });

var stylesheet$4 = match$4[1];

var styles$4 = match$4[0];

function Ui__PageSection(Props) {
  var children = Props.children;
  var testIDOpt = Props.testID;
  var containerStyle = Props.containerStyle;
  var accentOpt = Props.accent;
  var testID = testIDOpt !== undefined ? testIDOpt : "page-section";
  var accent = accentOpt !== undefined ? accentOpt : false;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet$4, match.theme);
  var tmp = {
    style: [
      accent ? Caml_option.some(Curry._1(styled, styles$4.accentColorStyle)) : undefined,
      containerStyle
    ],
    testID: testID,
    children: children
  };
  var tmp$1 = Utils__A11y.toSafeA11yRole("region");
  if (tmp$1 !== undefined) {
    tmp.accessibilityRole = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(ReactNative.View, tmp);
}

var Heading = {
  make: Ui__PageSection$Heading
};

var HeadingWithCount = {
  make: Ui__PageSection$HeadingWithCount
};

var HeadingWithDivider = {
  make: Ui__PageSection$HeadingWithDivider
};

var CommonHeading = {
  make: Ui__PageSection$CommonHeading
};

var make = Ui__PageSection;

export {
  Heading ,
  HeadingWithCount ,
  HeadingWithDivider ,
  CommonHeading ,
  make ,
}
/* match Not a pure module */
