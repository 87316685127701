// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ShopifyApi__Id from "./ShopifyApi__Id.bs.js";
import * as ShopifyApi__Infra from "./ShopifyApi__Infra.bs.js";
import * as ShopifyApi__Client from "./ShopifyApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as ShopifyApi__TitleWithMetadata from "./ShopifyApi__TitleWithMetadata.bs.js";

var Raw = {};

var query = "fragment InfluencerFields on Page   {\nid  \ntitle  \nseo  {\ndescription  \ntitle  \n}\n\nnickname: metafield(namespace: \"real-concepts\", key: \"influ-nickname\")  {\nvalue  \n}\n\nblogPostTag: metafield(namespace: \"real-concepts\", key: \"influ-blog-post-tag\")  {\nvalue  \n}\n\n}\n";

function parse(value) {
  var value$1 = value.seo;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.description;
    var value$3 = value$1.title;
    tmp = {
      description: !(value$2 == null) ? value$2 : undefined,
      title: !(value$3 == null) ? value$3 : undefined
    };
  }
  var value$4 = value.nickname;
  var value$5 = value.blogPostTag;
  return {
          id: value.id,
          title: ShopifyApi__TitleWithMetadata.parse(value.title),
          seo: tmp,
          nickname: !(value$4 == null) ? ({
                value: value$4.value
              }) : undefined,
          blogPostTag: !(value$5 == null) ? ({
                value: value$5.value
              }) : undefined
        };
}

function serialize(value) {
  var value$1 = value.blogPostTag;
  var blogPostTag;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    blogPostTag = {
      value: value$2
    };
  } else {
    blogPostTag = null;
  }
  var value$3 = value.nickname;
  var nickname;
  if (value$3 !== undefined) {
    var value$4 = value$3.value;
    nickname = {
      value: value$4
    };
  } else {
    nickname = null;
  }
  var value$5 = value.seo;
  var seo;
  if (value$5 !== undefined) {
    var value$6 = value$5.title;
    var title = value$6 !== undefined ? value$6 : null;
    var value$7 = value$5.description;
    var description = value$7 !== undefined ? value$7 : null;
    seo = {
      description: description,
      title: title
    };
  } else {
    seo = null;
  }
  var value$8 = value.title;
  var value$9 = ShopifyApi__TitleWithMetadata.serialize(value$8);
  var value$10 = value.id;
  return {
          id: value$10,
          title: value$9,
          seo: seo,
          nickname: nickname,
          blogPostTag: blogPostTag
        };
}

function verifyArgsAndParse(_InfluencerFields, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var InfluencerFields = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = "query Influencer($id: ID!)  {\npage(id: $id)  {\n...InfluencerFields   \n}\n\n}\nfragment InfluencerFields on Page   {\nid  \ntitle  \nseo  {\ndescription  \ntitle  \n}\n\nnickname: metafield(namespace: \"real-concepts\", key: \"influ-nickname\")  {\nvalue  \n}\n\nblogPostTag: metafield(namespace: \"real-concepts\", key: \"influ-blog-post-tag\")  {\nvalue  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.page;
  return {
          page: !(value$1 == null) ? parse(value$1) : undefined
        };
}

function serialize$1(value) {
  var value$1 = value.page;
  var page = value$1 !== undefined ? serialize(value$1) : null;
  return {
          page: page
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

function makeVariables(id, param) {
  return {
          id: id
        };
}

var InfluencerQuery = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

function makeInfluencer(param) {
  var seo = param.seo;
  return {
          id: ShopifyApi__Id.parseExn(param.id),
          fullName: ShopifyApi__TitleWithMetadata.toTitle(param.title),
          nickname: Belt_Option.map(param.nickname, (function (param) {
                  return param.value;
                })),
          blogPostTag: Belt_Option.map(param.blogPostTag, (function (param) {
                  return param.value;
                })),
          intro: Belt_Option.flatMap(seo, (function (param) {
                  return param.description;
                })),
          seo: Belt_Option.flatMap(seo, (function (param) {
                  return {
                          description: param.description,
                          title: param.title
                        };
                }))
        };
}

function $$fetch(apiConfig, id) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$1,
                    Raw: Raw$1,
                    parse: parse$1,
                    serialize: serialize$1,
                    serializeVariables: serializeVariables
                  }), {
                id: id
              }, undefined).then(function (param) {
              return RescriptUtils__OptionPlus.tapNone(Belt_Option.map(param.page, makeInfluencer), (function (param) {
                            RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "Influencer page not found. id:", id);
                          }));
            });
}

var logger = ShopifyApi__Infra.logger;

var Logger;

export {
  logger ,
  Logger ,
  InfluencerFields ,
  InfluencerQuery ,
  makeInfluencer ,
  $$fetch ,
}
/* ShopifyApi__Id Not a pure module */
