// Generated by ReScript, PLEASE EDIT WITH CARE

import Dataloader from "dataloader";
import * as GqlMiddleware__Entity__Brand from "../entities/GqlMiddleware__Entity__Brand.bs.js";
import * as GqlMiddleware__Entity__Product from "../entities/GqlMiddleware__Entity__Product.bs.js";
import * as GqlMiddleware__Entity__ProductVariant from "../entities/GqlMiddleware__Entity__ProductVariant.bs.js";

function make(shopifyApiConfig, includeTestProducts, includeMarkdownDescription, includeRefurbishedInfo, includeOverstockedInfo) {
  return {
          productById: new Dataloader((function (ids) {
                  return GqlMiddleware__Entity__Product.fetchProductsByIds(shopifyApiConfig, ids, includeTestProducts, includeMarkdownDescription, includeRefurbishedInfo, includeOverstockedInfo);
                }), undefined),
          productVariantById: new Dataloader((function (ids) {
                  return GqlMiddleware__Entity__ProductVariant.fetchProductVariantsByIds(shopifyApiConfig, ids);
                }), undefined),
          defaultVariantByProductId: new Dataloader((function (ids) {
                  return GqlMiddleware__Entity__ProductVariant.fetchDefaultVariantByProductIds(shopifyApiConfig, ids);
                }), undefined),
          brandById: new Dataloader((function (ids) {
                  return GqlMiddleware__Entity__Brand.fetchBrandsByIds(shopifyApiConfig, ids);
                }), undefined)
        };
}

export {
  make ,
}
/* dataloader Not a pure module */
