// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../styling/Ui__Styling.bs.js";
import * as ReactNativeSvg from "react-native-svg";

function Ui__VerificationMark(Props) {
  var sizeOpt = Props.size;
  var backgroundColor = Props.backgroundColor;
  var checkmarkColor = Props.checkmarkColor;
  var style = Props.style;
  var size = sizeOpt !== undefined ? sizeOpt : 24;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var tmp = {
    viewBox: "0 0 24 24",
    width: size,
    height: size,
    children: null,
    fill: "none"
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  return React.createElement(ReactNativeSvg.Svg, tmp, React.createElement(ReactNativeSvg.Path, {
                  d: "M8.64228 22.321C9.03558 22.269 9.43288 22.3757 9.74619 22.617L11.1901 23.725C11.6674 24.0917 12.3313 24.0917 12.8073 23.725L14.3058 22.5744C14.5858 22.3597 14.9391 22.265 15.2884 22.3117L17.1629 22.5584C17.7589 22.637 18.3335 22.305 18.5641 21.7491L19.2854 20.0051C19.42 19.6785 19.6787 19.4198 20.0053 19.2851L21.7492 18.5638C22.3051 18.3345 22.6371 17.7585 22.5585 17.1625L22.3211 15.3559C22.2691 14.9626 22.3758 14.5653 22.6171 14.2519L23.725 12.808C24.0917 12.3307 24.0917 11.6667 23.725 11.1907L22.5745 9.69206C22.3598 9.41207 22.2651 9.05875 22.3118 8.70942L22.5585 6.83481C22.6371 6.23883 22.3051 5.66418 21.7492 5.43352L20.0053 4.7122C19.6787 4.57754 19.42 4.31888 19.2854 3.99222L18.5641 2.24827C18.3348 1.69229 17.7589 1.3603 17.1629 1.43896L15.2884 1.68562C14.9391 1.73362 14.5858 1.63895 14.3071 1.42563L12.8086 0.274992C12.3313 -0.0916641 11.6674 -0.0916641 11.1914 0.274992L9.69286 1.42563C9.41288 1.63895 9.05958 1.73362 8.71027 1.68829L6.83576 1.44163C6.23981 1.36296 5.66519 1.69495 5.43455 2.25094L4.71461 3.99489C4.57862 4.32021 4.31997 4.57887 3.99467 4.71487L2.25081 5.43485C1.69486 5.66551 1.36289 6.24016 1.44155 6.83614L1.68819 8.71076C1.73352 9.06008 1.63886 9.4134 1.42555 9.69206L0.274977 11.1907C-0.091659 11.668 -0.091659 12.332 0.274977 12.808L1.42555 14.3066C1.6402 14.5866 1.73486 14.9399 1.68819 15.2892L1.44155 17.1639C1.36289 17.7598 1.69486 18.3345 2.25081 18.5652L3.99467 19.2865C4.32131 19.4211 4.57995 19.6798 4.71461 20.0064L5.43588 21.7504C5.66519 22.3064 6.24115 22.6384 6.8371 22.5597L8.64228 22.321Z",
                  fill: Belt_Option.getWithDefault(backgroundColor, theme.colors.primary)
                }), React.createElement(ReactNativeSvg.Path, {
                  d: "M16.6241 8.57588C16.8584 8.8102 16.8584 9.1901 16.6241 9.42441L11.2241 14.8244C10.9898 15.0587 10.6099 15.0587 10.3755 14.8244L7.97554 12.4244C7.74123 12.1901 7.74123 11.8102 7.97554 11.5759C8.20986 11.3416 8.58975 11.3416 8.82407 11.5759L10.7998 13.5516L15.7755 8.57588C16.0099 8.34157 16.3898 8.34157 16.6241 8.57588Z",
                  fill: Belt_Option.getWithDefault(checkmarkColor, theme.colors.secondaryVariant),
                  fillRule: "evenodd",
                  clipRule: "evenodd"
                }));
}

var make = Ui__VerificationMark;

export {
  make ,
}
/* react Not a pure module */
