/* TypeScript file generated from FeatureFlags__Utils.resi by genType. */
/* eslint-disable import/first */


// @ts-ignore: Implicit any on import
import * as FeatureFlags__UtilsBS__Es6Import from './FeatureFlags__Utils.bs';
const FeatureFlags__UtilsBS: any = FeatureFlags__UtilsBS__Es6Import;

import type {FeatureFlagsMap_t as FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t} from '../../../../packages/feature-flags/src/local/FeatureFlags__AppSpecificConfig.gen';

import type {Json_t as Js_Json_t} from '../../../../rescript-shims/Js.shim';

import type {t as Promise_t} from '../../../../rescript-shims/Promise.shim';

// tslint:disable-next-line:interface-over-type-literal
export type useFeatureFlagsFromLocalStorageResult = { readonly featureFlags: FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t; readonly updateFeatureFlags: (_1:((_1:FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t) => FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t)) => void };

// tslint:disable-next-line:interface-over-type-literal
export type useCombinedFeatureFlagsResult = { readonly featureFlags: FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t; readonly updateFeatureFlags: (_1:((_1:FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t) => FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t)) => void };

export const useFeatureFlagsFromLocalStorage: () => useFeatureFlagsFromLocalStorageResult = FeatureFlags__UtilsBS.useFeatureFlagsFromLocalStorage;

export const useCombinedFeatureFlags: (_1:{ readonly getFeatureFlagsFromNetwork: (() => Promise_t<Js_Json_t>) }) => useCombinedFeatureFlagsResult = function (Arg1: any) {
  const result = FeatureFlags__UtilsBS.useCombinedFeatureFlags(Arg1.getFeatureFlagsFromNetwork);
  return result
};
