// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Ui__MarkotIcon from "../../icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        }
      },
      textSection: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center"
        }
      },
      bagText: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "500"
        }
      },
      description: {
        TAG: /* Static */0,
        _0: {
          textAlign: "center"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__BagEmptyState(Props) {
  var containerStyle = Props.containerStyle;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var iconSize = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        140,
        undefined,
        160,
        undefined,
        185,
        undefined,
        105,
        undefined
      ]);
  var styled = Curry._1(stylesheet, undefined);
  return React.createElement(Ui__Group.make, {
              spacing: theme.spacing._12,
              children: null,
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles.container)),
                containerStyle
              ],
              testID: "bag-empty-state"
            }, React.createElement(Ui__MarkotIcon.make, {
                  name: "bag-empty",
                  size: iconSize,
                  color: theme.colors.backgroundVariant2,
                  testID: "bag-empty-icon"
                }), React.createElement(Ui__Group.make, {
                  spacing: theme.spacing._5,
                  children: null,
                  containerStyle: Curry._1(styled, styles.textSection)
                }, React.createElement(Ui__MarkotText.make, {
                      size: "b1",
                      children: "Your bag is empty",
                      style: Curry._1(styled, styles.bagText)
                    }), React.createElement(Ui__MarkotText.make, {
                      size: "b2",
                      color: theme.colors.onPrimaryShade4,
                      children: "Start bidding, get shopping, elbow people out of the way if you have to!",
                      style: Curry._1(styled, styles.description)
                    })));
}

var make = Ui__BagEmptyState;

export {
  make ,
}
/* match Not a pure module */
