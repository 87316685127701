import { btoa, atob } from "@decidr-io/utils/src/base64";

type SearchInfo = {
  nbHits: number;
  query: string;
  params: string;
  processingTimeMS: number;
  facets: Record<string, Record<string, number>>;
};

type WithPagination<Model> = {
  pageInfo: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    endCursor?: string | null | undefined;
    startCursor?: string | null | undefined;
  };
  edges: {
    cursor: string;
    node: Model;
  }[];
  searchInfo: SearchInfo;
};

type AlgoliaOffsetPaginatorResult<DataModel> = {
  data: DataModel[];
  searchInfo: SearchInfo;
};

type CursorPaginatorFromOffsetPaginatorSettings = {
  leftSearchLimit?: number;
  rightSearchLimit?: number;
  leftSearchBatchSize?: number;
  rightSearchBatchSize?: number;
};

export const cursorPaginatorFromOffsetPaginator = <DataModel>(
  offsetPaginator: (
    take: number,
    offset: number,
    search?: string | null
  ) => Promise<AlgoliaOffsetPaginatorResult<DataModel>>,
  idColumn: keyof DataModel,
  settings?: CursorPaginatorFromOffsetPaginatorSettings
): ((
  first: number,
  after?: string | null,
  search?: string | null
) => Promise<WithPagination<DataModel>>) => {
  const makeConnection = ({
    data,
    hasNextPage,
    offset,
    searchInfo,
  }: {
    data: DataModel[];
    hasNextPage: boolean;
    offset: number;
    searchInfo: SearchInfo;
  }): WithPagination<DataModel> => {
    const edges = data.map((item, itemIndex) => ({
      // +1 because indices are zero based and offsets are not
      cursor: btoa(
        `{"offset":${offset + itemIndex + 1}, "itemId":"${String(
          item[idColumn]
        )}"}`
      ),
      node: { ...item },
    }));

    return {
      pageInfo: {
        startCursor: null, // TODO could be calculated at cost of 1 call to offsetPaginator
        endCursor: edges.length > 0 ? edges[edges.length - 1].cursor : null,
        hasNextPage,
        hasPreviousPage: false, // should be offset > 1 commented out due to performance
      },
      edges: edges,
      searchInfo,
    };
  };

  const cursorPaginator = async (
    take: number,
    after?: string | null,
    search?: string | null
  ): Promise<WithPagination<DataModel>> => {
    // all cases explicitly covered because of linter
    if (after === "" || after === undefined || after === null) {
      try {
        // We take one item more than asked for so that we know is there more items after this data set.
        // We need this in order to fill pageInfo.hasNextPage
        const { data, searchInfo } = await offsetPaginator(take + 1, 0, search);

        return makeConnection({
          data: data.length > take ? data.slice(0, data.length - 1) : data,
          hasNextPage: data.length > take,
          offset: 0,
          searchInfo,
        });
      } catch (ex) {
        console.error(
          `Provided offsetPaginator: ${
            offsetPaginator.name
          } has thrown an error. Arguments: take=${
            take + 1
          }, offset=${0}. Error:`,
          ex
        );

        throw ex;
      }
    }

    let afterCursorObj: { offset: number; itemId: string };

    try {
      afterCursorObj = JSON.parse(atob(after));
    } catch (ex) {
      console.error(
        `<Unexpected> [cursorPaginator] Error thrown while parsing cursor as JSON. After cursor is in invalid format cursor: ${after}, expected format c={ "offset": number; "itemId": string }. Error:`,
        ex
      );
      throw ex;
    }

    // We fetch 2 more items than needed. One before required data set, because we hope to find afterCursorObj.itemId there.
    // One after required data set so that we can check if pageInfo.hasNextPage === true
    let dataWithItemFromCursorAndOneMoreItem: DataModel[];
    let searchInfo: SearchInfo;

    try {
      const resp = await offsetPaginator(
        take + 2,
        afterCursorObj.offset - 1,
        search
      );
      dataWithItemFromCursorAndOneMoreItem = resp.data;
      searchInfo = resp.searchInfo;
    } catch (ex) {
      console.error(
        `<Unexpected> [cursorPaginator] Provided offsetPaginator: ${
          offsetPaginator.name
        } has thrown an error. Arguments: take=${take + 2}, offset=${
          afterCursorObj.offset - 1
        }. Error:`,
        ex
      );

      throw ex;
    }

    if (dataWithItemFromCursorAndOneMoreItem.length === 0) {
      console.warn(
        `<Unexpected>[cursorPaginator] provided offsetPaginator: ${
          offsetPaginator.name
        } didn't return any data for take=${take + 2} and offset=${
          afterCursorObj.offset - 1
        }`
      );

      return {
        pageInfo: {
          endCursor: null,
          startCursor: null,
          hasNextPage: false,
          hasPreviousPage: afterCursorObj.offset > 0,
        },
        edges: [],
        searchInfo: {
          nbHits: 0,
          facets: {},
          params: "",
          processingTimeMS: 0,
          query: search ?? "",
        },
      };
    }

    // Data with one extra item at the end so that we can fill pageInfo.hasNextPage
    let dataWithOneExtraItem = dataWithItemFromCursorAndOneMoreItem.slice(1);

    // Actual data
    let data =
      dataWithOneExtraItem.length > take
        ? dataWithOneExtraItem.slice(0, -1)
        : dataWithOneExtraItem;

    // We found afterCursorObj.itemId at its old position
    // dataWithItemFromCursor[0][idColumn] may not be string, this depends on offsetPaginator and we cant control it
    if (
      String(dataWithItemFromCursorAndOneMoreItem[0][idColumn]) ===
      String(afterCursorObj.itemId)
    ) {
      return makeConnection({
        data,
        hasNextPage: dataWithOneExtraItem.length > take,
        offset: afterCursorObj.offset,
        searchInfo,
      });
    }

    // We didn't find afterCursorObj.itemId at its old position. We will try to find it's new position.

    // Check if afterCursorObj.itemId is in fetched data

    const newItemIndexInFetchedData =
      dataWithOneExtraItem.findIndex(
        item => String(item[idColumn]) === String(afterCursorObj.itemId)
      ) + 1; // +1 because indices are zero based and offsets are not

    let newItemOffset: number | undefined =
      newItemIndexInFetchedData > 0
        ? afterCursorObj.offset + newItemIndexInFetchedData
        : undefined;

    // all cases explicitly covered because of linter
    if (
      newItemOffset !== 0 &&
      !Number.isNaN(newItemOffset) &&
      newItemOffset !== undefined
    ) {
      try {
        const resp = await offsetPaginator(take + 1, newItemOffset, search);

        dataWithOneExtraItem = resp.data;

        data =
          dataWithOneExtraItem.length > take
            ? dataWithOneExtraItem.slice(0, -1)
            : dataWithOneExtraItem;

        return makeConnection({
          data,
          hasNextPage: dataWithOneExtraItem.length > take,
          offset: newItemOffset,
          searchInfo: resp.searchInfo,
        });
      } catch (ex) {
        console.error(
          `<Unexpected> [cursorPaginator] Provided offsetPaginator: ${
            offsetPaginator.name
          } has thrown an error. Arguments: take=${
            take + 1
          }, offset=${newItemOffset}. Error:`,
          ex
        );

        throw ex;
      }
    }

    // I made two search functions so that they can have different constraints if we want.
    // For example we might have insight that underlaying list of data is growing more/only in one direction.
    // Configurable through settings object.

    // if settings.leftSearchBatchSize is not specified use take
    // const leftSearchBatchSize = settings?.leftSearchBatchSize || take;
    const leftSearchBatchSize = take;

    // if settings.rightSearchBatchSize is not specified use take
    // const rightSearchBatchSize = settings?.rightSearchBatchSize || take;
    const rightSearchBatchSize = take;

    const lookForItemIdLeft = async (
      offset: number,
      iteration: number,
      offsetFromLastIteration?: number // used to stop before leftSearchLimit if we hit beginning of the list
    ): Promise<number | undefined> => {
      if (
        iteration > (settings?.leftSearchLimit ?? 1) ||
        offsetFromLastIteration === offset
      ) {
        return;
      }

      try {
        const itemIndex = (
          await offsetPaginator(leftSearchBatchSize, offset, search)
        ).data.findIndex(
          item => String(item[idColumn]) === String(afterCursorObj.itemId)
        );

        // If we have found our item return its new offset
        if (itemIndex >= 0) {
          return offset + itemIndex + 1; // +1 because indices are zero based and offsets are not
        }
      } catch (ex) {
        console.error(
          `<Unexpected> [cursorPaginator] Provided offsetPaginator: ${offsetPaginator.name} has thrown an error. Arguments: take=${leftSearchBatchSize}, offset=${offset}. Error:`,
          ex
        );

        throw ex;
      }

      // If we didn't find our item continue searching
      return lookForItemIdLeft(
        offset - leftSearchBatchSize > 0 ? offset - leftSearchBatchSize : 0,
        iteration + 1,
        offset
      );
    };

    const lookForItemIdRight = async (
      offset: number,
      iteration: number,
      offsetFromLastIteration?: number // used to stop before rightSearchLimit if we hit end of the list
    ): Promise<number | undefined> => {
      if (
        iteration > (settings?.rightSearchLimit ?? 1) ||
        offsetFromLastIteration === offset
      ) {
        return;
      }

      const dataBatch = (
        await offsetPaginator(rightSearchBatchSize, offset, search)
      ).data;

      try {
        const itemIndex = dataBatch.findIndex(
          item => String(item[idColumn]) === String(afterCursorObj.itemId)
        );

        // If we have found our item return its new offset
        if (itemIndex > 0) {
          return offset + itemIndex + 1; // +1 because indices are zero based and offsets are not
        }
      } catch (ex) {
        console.error(
          `<Unexpected> [cursorPaginator] Provided offsetPaginator: ${offsetPaginator.name} has thrown an error. Arguments: take=${rightSearchBatchSize}, offset=${offset}. Error:`,
          ex
        );

        throw ex;
      }

      // If we didn't find our item continue searching.
      // If we received empty data set we hit end of the list and there is no point in searching more to the right.
      return dataBatch.length === rightSearchBatchSize
        ? lookForItemIdRight(offset + rightSearchBatchSize, iteration + 1)
        : undefined;
    };

    // We don't want to run left search if we are at the beginning of the data array
    if (afterCursorObj.offset > 1) {
      newItemOffset = await lookForItemIdLeft(
        afterCursorObj.offset - leftSearchBatchSize > 0
          ? afterCursorObj.offset - leftSearchBatchSize
          : 0,
        1
      );
    }

    // all cases explicitly covered because of linter
    if (
      newItemOffset !== 0 &&
      !Number.isNaN(newItemOffset) &&
      newItemOffset !== undefined
    ) {
      newItemOffset = await lookForItemIdRight(afterCursorObj.offset + take, 1);
    }

    // If we didn't find position of item from cursor return possibly duplicate data.
    // TODO Maybe return items from the beginning?
    if (
      newItemOffset === 0 ||
      Number.isNaN(newItemOffset) ||
      newItemOffset === undefined
    ) {
      console.error(
        `<Unexpected> [cursorPaginator] item from after cursor not found. Cursor=${after}. Possibly duplicate data is returned.`
      );
      return makeConnection({
        data: data,
        hasNextPage: dataWithOneExtraItem.length > take,
        offset: afterCursorObj.offset,
        searchInfo,
      });
    }

    try {
      const resp = await offsetPaginator(take + 1, newItemOffset, search);

      dataWithOneExtraItem = resp.data;

      data =
        dataWithOneExtraItem.length > take
          ? dataWithOneExtraItem.slice(0, -1)
          : dataWithOneExtraItem;

      return makeConnection({
        data,
        hasNextPage: dataWithOneExtraItem.length > take,
        offset: newItemOffset,
        searchInfo: resp.searchInfo,
      });
    } catch (ex) {
      console.error(
        `<Unexpected> [cursorPaginator] Provided offsetPaginator: ${
          offsetPaginator.name
        } has thrown an error. Arguments: take=${
          take + 1
        }, offset=${newItemOffset}. Error:`,
        ex
      );

      throw ex;
    }
  };

  return cursorPaginator;
};
