// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";

var Raw = {};

function parse(value) {
  var value$1 = value.productSpecs;
  return {
          productSpecs: Js_array.map((function (value) {
                  return {
                          title: value.title,
                          value: value.value
                        };
                }), value$1)
        };
}

function serialize(value) {
  var value$1 = value.productSpecs;
  var productSpecs = Js_array.map((function (value) {
          var value$1 = value.value;
          var value$2 = value.title;
          return {
                  title: value$2,
                  value: value$1
                };
        }), value$1);
  return {
          productSpecs: productSpecs
        };
}

function serializeInputObjectExternalRefInput(inp) {
  return {
          externalId: inp.externalId
        };
}

function serializeVariables(inp) {
  return {
          productRef: serializeInputObjectExternalRefInput(inp.productRef)
        };
}

function makeProductSpecification(param) {
  return {
          title: param.title,
          value: param.value
        };
}

function fetchProductSpecifications(apiConfig, externalId) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: "query GetProductSpecifications($productRef: ExternalRefInput!)  {\nproductSpecs(productRef: $productRef)  {\ntitle  \nvalue  \n}\n\n}\n",
                    Raw: Raw,
                    parse: parse,
                    serialize: serialize,
                    serializeVariables: serializeVariables
                  }), {
                productRef: {
                  externalId: externalId
                }
              }, undefined).then(function (param) {
              return Belt_Array.map(param.productSpecs, makeProductSpecification);
            });
}

export {
  fetchProductSpecifications ,
}
/* MarkotApi__Client Not a pure module */
