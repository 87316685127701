// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as MarkotApi__CommonFragments from "./MarkotApi__CommonFragments.bs.js";
import * as MarkotApi__Scalars__DateTime from "./MarkotApi__Scalars__DateTime.bs.js";

var Raw = {};

var query = "fragment BidFields on Bid   {\nid  \ntimestamp  \namount  {\n...MoneyFields   \n}\n\nbelongsToViewer  \nexpiresAt  \nprelovedItemInfo  {\nid  \n}\n\n}\n" + MarkotApi__CommonFragments.MoneyFields.query;

function parse(value) {
  var value$1 = value.amount;
  var value$2 = value.expiresAt;
  var value$3 = value.prelovedItemInfo;
  return {
          id: value.id,
          timestamp: MarkotApi__Scalars__DateTime.parse(value.timestamp),
          amount: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$1),
          belongsToViewer: value.belongsToViewer,
          expiresAt: !(value$2 == null) ? MarkotApi__Scalars__DateTime.parse(value$2) : undefined,
          prelovedItemInfo: {
            id: value$3.id
          }
        };
}

function serialize(value) {
  var value$1 = value.prelovedItemInfo;
  var value$2 = value$1.id;
  var prelovedItemInfo = {
    id: value$2
  };
  var value$3 = value.expiresAt;
  var expiresAt = value$3 !== undefined ? MarkotApi__Scalars__DateTime.serialize(value$3) : null;
  var value$4 = value.belongsToViewer;
  var value$5 = value.amount;
  var amount = MarkotApi__CommonFragments.MoneyFields.serialize(value$5);
  var value$6 = value.timestamp;
  var value$7 = MarkotApi__Scalars__DateTime.serialize(value$6);
  var value$8 = value.id;
  return {
          id: value$8,
          timestamp: value$7,
          amount: amount,
          belongsToViewer: value$4,
          expiresAt: expiresAt,
          prelovedItemInfo: prelovedItemInfo
        };
}

function verifyArgsAndParse(_BidFields, value) {
  return parse(value);
}

function verifyName(param) {
  
}

function makeBid(param) {
  return {
          id: MarkotApi__Id.parseExn(param.id),
          timestamp: param.timestamp,
          amount: MarkotApi__CommonFragments.makeMoney(param.amount),
          belongsToViewer: param.belongsToViewer,
          expiresAt: param.expiresAt,
          prelovedItemInfoId: MarkotApi__Id.parseExn(param.prelovedItemInfo.id)
        };
}

function BidFields_unsafe_fromJson(prim) {
  return prim;
}

function BidFields_toJson(prim) {
  return prim;
}

var BidFields = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName,
  unsafe_fromJson: BidFields_unsafe_fromJson,
  toJson: BidFields_toJson
};

export {
  BidFields ,
  makeBid ,
}
/* MarkotApi__Id Not a pure module */
