// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ShopifyApi__Id from "../../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as GraphqlCore__Types from "../../../graphql-core/src/GraphqlCore__Types.bs.js";
import * as ShopifyApi__Bundles from "../../../api-adapter-shopify/src/ShopifyApi__Bundles.bs.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Entity__Product from "./GqlMiddleware__Entity__Product.bs.js";
import * as GqlMiddleware__Core__FromShopify from "../core/GqlMiddleware__Core__FromShopify.bs.js";
import * as GqlMiddleware__Entity__Influencer from "./GqlMiddleware__Entity__Influencer.bs.js";

function makeBundle(bundle) {
  if (bundle.TAG === /* InfluencerBundle */0) {
    return {
            TAG: /* InfluencerBundle */0,
            _0: {
              __typename: "InfluencerBundle",
              id: GqlMiddleware__Core__NodeId.make(1, "InfluencerBundle", ShopifyApi__Id.toString(bundle.id)),
              name: bundle.name,
              slug: bundle.slug,
              image: Belt_Option.flatMap(bundle.image, GqlMiddleware__Core__FromShopify.tryMakeImageResource),
              influencer: GqlMiddleware__Entity__Influencer.makeInfluencer(bundle.influencer),
              inWishlist: undefined,
              seo: Belt_Option.map(bundle.seo, (function (param) {
                      return {
                              description: param.description,
                              title: param.title
                            };
                    }))
            }
          };
  } else {
    return {
            TAG: /* MarkotBundle */1,
            _0: {
              __typename: "MarkotBundle",
              id: GqlMiddleware__Core__NodeId.make(1, "MarkotBundle", ShopifyApi__Id.toString(bundle.id)),
              name: bundle.name,
              slug: bundle.slug,
              image: Belt_Option.flatMap(bundle.image, GqlMiddleware__Core__FromShopify.tryMakeImageResource),
              inWishlist: undefined,
              seo: Belt_Option.map(bundle.seo, (function (param) {
                      return {
                              description: param.description,
                              title: param.title
                            };
                    }))
            }
          };
  }
}

function fetchBundle(apiConfig, id) {
  return ShopifyApi__Bundles.fetchBundle(apiConfig, ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(id).internalId)).then(function (bundle) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(bundle, (function (param) {
                                RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "Bundle not found. Id:", id);
                              })), makeBundle);
            });
}

function fetchBundles(apiConfig, first, after, param) {
  return ShopifyApi__Bundles.fetchBundles(apiConfig, first, after, undefined).then(function (__x) {
              return GraphqlCore__Types.Connection.map(__x, makeBundle);
            });
}

function fetchItemCount(apiConfig, id) {
  return ShopifyApi__Bundles.fetchItemCount(apiConfig, ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(id).internalId));
}

function fetchItems(apiConfig, id, firstOpt, after, includeMarkdownDescription, includeRefurbishedInfo, includeOverstockedInfo, param) {
  var first = firstOpt !== undefined ? firstOpt : 25;
  var collectionId = ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(id).internalId);
  return ShopifyApi__Bundles.fetchItems(apiConfig, collectionId, first, after, undefined, includeMarkdownDescription, includeRefurbishedInfo, includeOverstockedInfo, undefined).then(function (result) {
              if (result !== undefined) {
                return GraphqlCore__Types.Connection.map(result, GqlMiddleware__Entity__Product.makeProduct);
              } else {
                return Js_exn.raiseError("BundleCollectionItems: collection not found. ID=" + ShopifyApi__Id.toString(collectionId));
              }
            });
}

export {
  makeBundle ,
  fetchBundle ,
  fetchBundles ,
  fetchItemCount ,
  fetchItems ,
}
/* ShopifyApi__Id Not a pure module */
