// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Index from "./index";
import * as App__Report from "../../../utils/App__Report.bs.js";

function getPredictions(prim) {
  return Index.getAutocompletePredictions(prim);
}

function getAddressDetails(placeId) {
  return Index.getPlaceDetails(placeId).then(function (param) {
              var error = param.error;
              var address = param.address;
              if (address !== undefined) {
                if (error !== undefined) {
                  return {
                          TAG: /* Ok */0,
                          _0: {
                            NAME: "DataWithError",
                            VAL: [
                              address,
                              error
                            ]
                          }
                        };
                } else {
                  return {
                          TAG: /* Ok */0,
                          _0: {
                            NAME: "Data",
                            VAL: address
                          }
                        };
                }
              } else if (error !== undefined) {
                return {
                        TAG: /* Error */1,
                        _0: error
                      };
              } else {
                App__Report.unexpected("No data and no error received in the response", "App__AddressPrediction getAddressDetails");
                return {
                        TAG: /* Error */1,
                        _0: "Unexpected thing happend. Please try again!"
                      };
              }
            });
}

var googlePlacesAutocompleteServiceInfo = {
  name: "",
  attributionUrl: undefined,
  attributionText: undefined,
  getPredictions: getPredictions
};

export {
  getPredictions ,
  getAddressDetails ,
  googlePlacesAutocompleteServiceInfo ,
}
/* ./index Not a pure module */
