// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ignored$DecidrIoUtils from "@decidr-io/utils/src/functions/Ignored.bs.js";
import * as FeatureFlags__AppSpecificConfig from "../local/FeatureFlags__AppSpecificConfig.bs.js";
import * as AsyncStorage from "@react-native-async-storage/async-storage";

function statusFromString(value) {
  switch (value) {
    case "off" :
        return "off";
    case "on" :
        return "on";
    default:
      return "off";
  }
}

function isFeatureFlagKey(key) {
  return key.startsWith(FeatureFlags__AppSpecificConfig.storagePrefix);
}

function removeOldFeatureFlagsFromLocalStorage(activeFeatureFlags) {
  Ignored$DecidrIoUtils.promise(AsyncStorage.default.getAllKeys().then(function (keys) {
            var currentKeys = Belt_Array.map(activeFeatureFlags, (function (param) {
                    return FeatureFlags__AppSpecificConfig.storagePrefix + param.key;
                  }));
            var allFeatureFlagKeys = Belt_Array.keep(keys, isFeatureFlagKey);
            return AsyncStorage.default.multiRemove(Belt_Array.keep(allFeatureFlagKeys, (function (key) {
                              return !currentKeys.includes(key);
                            })));
          }));
}

function getAllFeatureFlagsFromLocalStorage(param) {
  return AsyncStorage.default.multiGet(Belt_Array.map(FeatureFlags__AppSpecificConfig.displayFeatureFlags, (function (param) {
                    return FeatureFlags__AppSpecificConfig.storagePrefix + param.key;
                  })));
}

function useFeatureFlagsFromLocalStorage(param) {
  var match = React.useState(function () {
        return FeatureFlags__AppSpecificConfig.FeatureFlagsMap.empty;
      });
  var setFeatureFlags = match[1];
  React.useEffect((function () {
          Ignored$DecidrIoUtils.promise(getAllFeatureFlagsFromLocalStorage(undefined).then(function (result) {
                    var featureFlags = FeatureFlags__AppSpecificConfig.FeatureFlagsMap.fromArray(Belt_Array.map(result, (function (param) {
                                return [
                                        FeatureFlags__AppSpecificConfig.unsafe_make(Belt_Array.getExn(param[0].split(FeatureFlags__AppSpecificConfig.storageDelimiter), 1)),
                                        Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.map(Caml_option.null_to_opt(param[1]), statusFromString), (function (status) {
                                                    return {
                                                            status: status,
                                                            disabled: false
                                                          };
                                                  })), {
                                              status: "off",
                                              disabled: false
                                            })
                                      ];
                              })));
                    Curry._1(setFeatureFlags, (function (param) {
                            return featureFlags;
                          }));
                  }));
        }), []);
  return {
          featureFlags: match[0],
          updateFeatureFlags: setFeatureFlags
        };
}

function extractFeatureFlagsFromAppConfig(config) {
  return FeatureFlags__AppSpecificConfig.FeatureFlagsMap.fromArray(Belt_Array.keep(Belt_Array.keepMap(Belt_Array.map(Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(Js_json.decodeObject(config), (function (config) {
                                            return Js_dict.get(config, "featureFlags");
                                          })), Js_json.decodeObject), Js_dict.entries), []), (function (param) {
                            return [
                                    FeatureFlags__AppSpecificConfig.unsafe_make(param[0]),
                                    Js_json.decodeString(param[1])
                                  ];
                          })), (function (param) {
                        var key = param[0];
                        return Belt_Option.map(param[1], (function (value) {
                                      return [
                                              key,
                                              {
                                                status: statusFromString(value),
                                                disabled: true
                                              }
                                            ];
                                    }));
                      })), (function (param) {
                    var key = param[0];
                    return Belt_Array.some(FeatureFlags__AppSpecificConfig.displayFeatureFlags, (function (param) {
                                  return key === param.key;
                                }));
                  })));
}

function useCombinedFeatureFlags(getFeatureFlagsFromNetwork) {
  var match = React.useState(function () {
        return FeatureFlags__AppSpecificConfig.FeatureFlagsMap.empty;
      });
  var setFeatureFlags = match[1];
  React.useEffect((function () {
          removeOldFeatureFlagsFromLocalStorage(FeatureFlags__AppSpecificConfig.displayFeatureFlags);
        }), []);
  React.useEffect((function () {
          Ignored$DecidrIoUtils.promise(Promise.all([
                      getAllFeatureFlagsFromLocalStorage(undefined),
                      Curry._1(getFeatureFlagsFromNetwork, undefined)
                    ]).then(function (param) {
                    var localFeatureFlags = FeatureFlags__AppSpecificConfig.FeatureFlagsMap.fromArray(Belt_Array.map(param[0], (function (param) {
                                return [
                                        FeatureFlags__AppSpecificConfig.unsafe_make(Belt_Array.getExn(param[0].split(FeatureFlags__AppSpecificConfig.storageDelimiter), 1)),
                                        Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.map(Caml_option.null_to_opt(param[1]), statusFromString), (function (status) {
                                                    return {
                                                            status: status,
                                                            disabled: false
                                                          };
                                                  })), {
                                              status: "off",
                                              disabled: false
                                            })
                                      ];
                              })));
                    var networkFeatureFlags = extractFeatureFlagsFromAppConfig(param[1]);
                    Belt_Array.forEach(Curry._1(FeatureFlags__AppSpecificConfig.FeatureFlagsMap.toArray, networkFeatureFlags), (function (param) {
                            Ignored$DecidrIoUtils.promise(AsyncStorage.default.setItem(FeatureFlags__AppSpecificConfig.storagePrefix + param[0], param[1].status));
                          }));
                    Curry._1(setFeatureFlags, (function (param) {
                            return FeatureFlags__AppSpecificConfig.FeatureFlagsMap.mergeWithNetwork(localFeatureFlags, networkFeatureFlags);
                          }));
                  }));
        }), [getFeatureFlagsFromNetwork]);
  return {
          featureFlags: match[0],
          updateFeatureFlags: setFeatureFlags
        };
}

export {
  statusFromString ,
  getAllFeatureFlagsFromLocalStorage ,
  useFeatureFlagsFromLocalStorage ,
  useCombinedFeatureFlags ,
}
/* react Not a pure module */
