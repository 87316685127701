// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as GraphqlClient from "../../graphql-client/src/GraphqlClient.bs.js";

var headerKey = {
  authorization: "authorization",
  apiKey: "x-api-key",
  accessToken: "x-access-token",
  decidrAuth: "x-decidr-auth"
};

function makeAuthHeaders(accessToken, apiKey) {
  if (accessToken === undefined) {
    return [[
              headerKey.apiKey,
              apiKey
            ]];
  }
  var accessToken$1 = Caml_option.valFromOption(accessToken);
  return [
          [
            headerKey.authorization,
            accessToken$1
          ],
          [
            headerKey.accessToken,
            accessToken$1
          ]
        ];
}

function query(config) {
  var decidrAuth = JSON.stringify({
        appId: config.decidrAppId
      });
  var partial_arg = Belt_Array.concat(makeAuthHeaders(config.accessToken, config.apiKey), decidrAuth !== undefined ? [[
            headerKey.decidrAuth,
            decidrAuth
          ]] : []);
  var partial_arg$1 = config.url;
  var func = function (param, param$1, param$2, param$3) {
    return GraphqlClient.query(partial_arg$1, partial_arg, param, param$1, param$2, param$3);
  };
  return function (param) {
    var func$1 = Curry._1(func, param);
    return function (param) {
      return Curry._2(func$1, param, "Markot");
    };
  };
}

function rawQuery(config) {
  var decidrAuth = JSON.stringify({
        appId: config.decidrAppId
      });
  var partial_arg = Belt_Array.concat(makeAuthHeaders(config.accessToken, config.apiKey), decidrAuth !== undefined ? [[
            headerKey.decidrAuth,
            decidrAuth
          ]] : []);
  var partial_arg$1 = config.url;
  var func = function (param, param$1, param$2, param$3) {
    return GraphqlClient.rawQuery(partial_arg$1, partial_arg, param, param$1, param$2, param$3);
  };
  return function (param) {
    var func$1 = Curry._1(func, param);
    return function (param) {
      return Curry._2(func$1, param, "Markot");
    };
  };
}

export {
  query ,
  rawQuery ,
}
/* GraphqlClient Not a pure module */
