/* TypeScript file generated from App__RelayProvider.resi by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as App__RelayProviderBS__Es6Import from './App__RelayProvider.bs';
const App__RelayProviderBS: any = App__RelayProviderBS__Es6Import;

import type {Environment_t as RescriptRelay_Environment_t} from '../../../../rescript-shims/RescriptRelay.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = { readonly environment: RescriptRelay_Environment_t; readonly children: React.ReactNode };

export const make: React.ComponentType<{ readonly environment: RescriptRelay_Environment_t; readonly children: React.ReactNode }> = App__RelayProviderBS.make;
