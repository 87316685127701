// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../group/components/Ui__Group.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Heading from "../text/components/Ui__Heading.bs.js";
import * as Ui__Styling from "../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotText from "../text/components/Ui__MarkotText.bs.js";
import * as Ui__MarkotImage from "../image/components/Ui__MarkotImage.bs.js";
import * as Box$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/box/components/Box.bs.js";

function Ui__Card$Section(Props) {
  var children = Props.children;
  var spacing = Props.spacing;
  var orientationOpt = Props.orientation;
  var paddingHorizontal = Props.paddingHorizontal;
  var paddingVertical = Props.paddingVertical;
  var style = Props.style;
  var testID = Props.testID;
  var orientation = orientationOpt !== undefined ? orientationOpt : "vertical";
  var tmp = {};
  var tmp$1 = Belt_Option.map(paddingHorizontal, (function (prim) {
          return prim;
        }));
  if (tmp$1 !== undefined) {
    tmp.paddingHorizontal = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = Belt_Option.map(paddingVertical, (function (prim) {
          return prim;
        }));
  if (tmp$2 !== undefined) {
    tmp.paddingVertical = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = {
    children: children,
    containerStyle: [
      Caml_option.some(tmp),
      style
    ],
    orientation: orientation
  };
  if (spacing !== undefined) {
    tmp$3.spacing = spacing;
  }
  if (testID !== undefined) {
    tmp$3.testID = testID;
  }
  return React.createElement(Ui__Group.make, tmp$3);
}

var Section = {
  make: Ui__Card$Section
};

var match = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center",
          justifyContent: "space-between"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__Card$Apart(Props) {
  var children = Props.children;
  var spacing = Props.spacing;
  var containerStyle = Props.containerStyle;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  return React.createElement(Ui__Group.make, {
              spacing: Belt_Option.getWithDefault(spacing, theme.spacing._6),
              children: children,
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles.container)),
                containerStyle
              ],
              orientation: "horizontal"
            });
}

var match$1 = Ui__Styling.describe({
      wrapper: {
        TAG: /* Static */0,
        _0: {
          bottom: 0,
          left: 0,
          position: "absolute"
        }
      },
      container: {
        TAG: /* Dynamic */1,
        _0: (function (width) {
            return {
                    position: "relative",
                    width: width
                  };
          })
      }
    });

var stylesheet$1 = match$1[1];

var styles$1 = match$1[0];

function Ui__Card$BoundlessContainer(Props) {
  var width = Props.width;
  var children = Props.children;
  var containerStyle = Props.containerStyle;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "boundless-container";
  var styled = Curry._1(stylesheet$1, width);
  return React.createElement(ReactNative.View, {
              style: [
                Caml_option.some(Curry._1(styled, styles$1.container)),
                containerStyle
              ],
              testID: testID,
              children: React.createElement(ReactNative.View, {
                    style: Curry._1(styled, styles$1.wrapper),
                    children: Curry._1(children, width)
                  })
            });
}

function Ui__Card$Heading(Props) {
  var text = Props.text;
  var level = Props.level;
  var size = Props.size;
  var fontFamily = Props.fontFamily;
  var fontWeightOpt = Props.fontWeight;
  var textAlignOpt = Props.textAlign;
  var color = Props.color;
  var numberOfLinesOpt = Props.numberOfLines;
  var testIDOpt = Props.testID;
  var style = Props.style;
  var accessibilityLabel = Props.accessibilityLabel;
  var fontWeight = fontWeightOpt !== undefined ? fontWeightOpt : "normal";
  var textAlign = textAlignOpt !== undefined ? textAlignOpt : "left";
  var numberOfLines = numberOfLinesOpt !== undefined ? numberOfLinesOpt : 1;
  var testID = testIDOpt !== undefined ? testIDOpt : "card-heading";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var defaultFontFamily = Belt_Option.getWithDefault(fontFamily, match.theme.fontFamily.libre);
  var tmp = {
    fontFamily: defaultFontFamily,
    size: size,
    level: level,
    children: text,
    style: [
      {
        fontWeight: (function () {
              switch (fontWeight) {
                case "normal" :
                    return "normal";
                case "bold" :
                    return "bold";
                case "_100" :
                    return "100";
                case "_200" :
                    return "200";
                case "_300" :
                    return "300";
                case "_400" :
                    return "400";
                case "_500" :
                    return "500";
                case "_600" :
                    return "600";
                case "_700" :
                    return "700";
                case "_800" :
                    return "800";
                case "_900" :
                    return "900";
                
              }
            })(),
        textAlign: textAlign
      },
      style
    ],
    ellipsizeMode: "tail",
    numberOfLines: numberOfLines,
    testID: testID
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (accessibilityLabel !== undefined) {
    tmp.accessibilityLabel = Caml_option.valFromOption(accessibilityLabel);
  }
  return React.createElement(Ui__Heading.make, tmp);
}

var Heading = {
  make: Ui__Card$Heading
};

function Ui__Card$Description(Props) {
  var text = Props.text;
  var size = Props.size;
  var fontFamily = Props.fontFamily;
  var fontWeightOpt = Props.fontWeight;
  var textAlignOpt = Props.textAlign;
  var numberOfLinesOpt = Props.numberOfLines;
  var style = Props.style;
  var color = Props.color;
  var accessibilityRole = Props.accessibilityRole;
  var accessibilityLevel = Props.accessibilityLevel;
  var accessibilityLabel = Props.accessibilityLabel;
  var testIDOpt = Props.testID;
  var fontWeight = fontWeightOpt !== undefined ? fontWeightOpt : "normal";
  var textAlign = textAlignOpt !== undefined ? textAlignOpt : "left";
  var numberOfLines = numberOfLinesOpt !== undefined ? numberOfLinesOpt : 2;
  var testID = testIDOpt !== undefined ? testIDOpt : "card-description";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var tmp = {
    fontFamily: Belt_Option.getWithDefault(fontFamily, match.theme.fontFamily.dm),
    size: size,
    children: text,
    style: [
      {
        fontWeight: (function () {
              switch (fontWeight) {
                case "normal" :
                    return "normal";
                case "bold" :
                    return "bold";
                case "_100" :
                    return "100";
                case "_200" :
                    return "200";
                case "_300" :
                    return "300";
                case "_400" :
                    return "400";
                case "_500" :
                    return "500";
                case "_600" :
                    return "600";
                case "_700" :
                    return "700";
                case "_800" :
                    return "800";
                case "_900" :
                    return "900";
                
              }
            })(),
        textAlign: textAlign
      },
      style
    ],
    ellipsizeMode: "tail",
    numberOfLines: numberOfLines,
    testID: testID
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (accessibilityLabel !== undefined) {
    tmp.accessibilityLabel = Caml_option.valFromOption(accessibilityLabel);
  }
  if (accessibilityRole !== undefined) {
    tmp.accessibilityRole = Caml_option.valFromOption(accessibilityRole);
  }
  if (accessibilityLevel !== undefined) {
    tmp.accessibilityLevel = Caml_option.valFromOption(accessibilityLevel);
  }
  return React.createElement(Ui__MarkotText.make, tmp);
}

var Description = {
  make: Ui__Card$Description
};

function Ui__Card$Image(Props) {
  var image = Props.image;
  var size = Props.size;
  var resizeModeOpt = Props.resizeMode;
  var alt = Props.alt;
  var kindOpt = Props.kind;
  var priority = Props.priority;
  var style = Props.style;
  var testIDOpt = Props.testID;
  var tintColor = Props.tintColor;
  var resizeMode = resizeModeOpt !== undefined ? resizeModeOpt : "cover";
  var kind = kindOpt !== undefined ? kindOpt : "sharp";
  var testID = testIDOpt !== undefined ? testIDOpt : "card-image";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styleByKind = kind === "rounded" ? ({
        borderRadius: match.theme.roundness._4
      }) : undefined;
  var tmp = {
    image: image,
    alt: alt,
    size: size,
    resizeMode: resizeMode,
    style: [
      styleByKind,
      style
    ],
    testID: testID
  };
  if (priority !== undefined) {
    tmp.priority = Caml_option.valFromOption(priority);
  }
  if (tintColor !== undefined) {
    tmp.tintColor = Caml_option.valFromOption(tintColor);
  }
  return React.createElement(Ui__MarkotImage.make, tmp);
}

var $$Image = {
  make: Ui__Card$Image
};

function Ui__Card$BiddingStatus(Props) {
  var kind = Props.kind;
  var value = Props.value;
  var valueFontSize = Props.valueFontSize;
  var valueFontWeightOpt = Props.valueFontWeight;
  var valueFontWeight = valueFontWeightOpt !== undefined ? valueFontWeightOpt : "bold";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  return React.createElement(ReactNative.View, {
              style: {
                flexDirection: "row"
              },
              children: null
            }, React.createElement(Ui__Heading.make, {
                  size: "b1",
                  color: match.theme.colors.onPrimaryShade4,
                  level: 2,
                  children: kind === "purchased" ? "Purchased:" : (
                      kind === "yourBid" ? "Your bid:" : "Highest bid:"
                    ),
                  style: {
                    marginRight: 4
                  }
                }), React.createElement(Ui__Heading.make, {
                  size: Belt_Option.getWithDefault(valueFontSize, "b1"),
                  level: 3,
                  children: value,
                  style: {
                    fontWeight: (function () {
                          switch (valueFontWeight) {
                            case "normal" :
                                return "normal";
                            case "bold" :
                                return "bold";
                            case "_100" :
                                return "100";
                            case "_200" :
                                return "200";
                            case "_300" :
                                return "300";
                            case "_400" :
                                return "400";
                            case "_500" :
                                return "500";
                            case "_600" :
                                return "600";
                            case "_700" :
                                return "700";
                            case "_800" :
                                return "800";
                            case "_900" :
                                return "900";
                            
                          }
                        })()
                  }
                }));
}

var BiddingStatus = {
  make: Ui__Card$BiddingStatus
};

function Ui__Card$ExpiryDate(Props) {
  var value = Props.value;
  var valueFontSize = Props.valueFontSize;
  var valueFontWeightOpt = Props.valueFontWeight;
  var valueFontWeight = valueFontWeightOpt !== undefined ? valueFontWeightOpt : "normal";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  return React.createElement(ReactNative.View, {
              style: {
                flexDirection: "row"
              },
              children: null
            }, React.createElement(Ui__Heading.make, {
                  size: "b2",
                  color: match.theme.colors.onPrimaryShade4,
                  level: 2,
                  children: "Expires in:",
                  style: {
                    marginRight: 4
                  }
                }), React.createElement(Ui__Heading.make, {
                  size: Belt_Option.getWithDefault(valueFontSize, "b2"),
                  level: 3,
                  children: value,
                  style: {
                    fontWeight: (function () {
                          switch (valueFontWeight) {
                            case "normal" :
                                return "normal";
                            case "bold" :
                                return "bold";
                            case "_100" :
                                return "100";
                            case "_200" :
                                return "200";
                            case "_300" :
                                return "300";
                            case "_400" :
                                return "400";
                            case "_500" :
                                return "500";
                            case "_600" :
                                return "600";
                            case "_700" :
                                return "700";
                            case "_800" :
                                return "800";
                            case "_900" :
                                return "900";
                            
                          }
                        })()
                  }
                }));
}

var ExpiryDate = {
  make: Ui__Card$ExpiryDate
};

var match$2 = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          overflow: "hidden"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.secondary,
                    borderRadius: theme.roundness._4
                  };
          })
      }
    });

var stylesheet$2 = match$2[1];

var styles$2 = match$2[0];

function Ui__Card(Props) {
  var children = Props.children;
  var onPress = Props.onPress;
  var kindOpt = Props.kind;
  var orientationOpt = Props.orientation;
  var spacing = Props.spacing;
  var paddingHorizontal = Props.paddingHorizontal;
  var paddingVertical = Props.paddingVertical;
  var backgroundColor = Props.backgroundColor;
  var imageBackground = Props.imageBackground;
  var imageBackgroundStyle = Props.imageBackgroundStyle;
  var accessibilityLabel = Props.accessibilityLabel;
  var accessibilityRole = Props.accessibilityRole;
  var reversed = Props.reversed;
  var sizes = Props.sizes;
  var includeEndSpacingOpt = Props.includeEndSpacing;
  var includeStartSpacingOpt = Props.includeStartSpacing;
  var disabled = Props.disabled;
  var style = Props.style;
  var testIDOpt = Props.testID;
  var testMode = Props.testMode;
  var priority = Props.priority;
  var kind = kindOpt !== undefined ? kindOpt : "rounded";
  var orientation = orientationOpt !== undefined ? orientationOpt : "vertical";
  var includeEndSpacing = includeEndSpacingOpt !== undefined ? includeEndSpacingOpt : false;
  var includeStartSpacing = includeStartSpacingOpt !== undefined ? includeStartSpacingOpt : false;
  var testID = testIDOpt !== undefined ? testIDOpt : "card";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$2, theme);
  var tmp = {};
  if (backgroundColor !== undefined) {
    tmp.backgroundColor = Caml_option.valFromOption(backgroundColor);
  }
  var tmp$1 = typeof kind === "object" ? kind.VAL : (
      kind === "rounded" ? undefined : 0
    );
  if (tmp$1 !== undefined) {
    tmp.borderRadius = tmp$1;
  }
  var tmp$2 = Belt_Option.map(paddingHorizontal, (function (prim) {
          return prim;
        }));
  if (tmp$2 !== undefined) {
    tmp.paddingHorizontal = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = Belt_Option.map(paddingVertical, (function (prim) {
          return prim;
        }));
  if (tmp$3 !== undefined) {
    tmp.paddingVertical = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = {
    spacing: Belt_Option.getWithDefault(spacing, theme.spacing._6),
    children: children,
    containerStyle: [
      Caml_option.some(Curry._1(styled, styles$2.container)),
      Caml_option.some(tmp),
      style
    ],
    orientation: orientation,
    testID: testID,
    includeEndSpacing: includeEndSpacing,
    includeStartSpacing: includeStartSpacing
  };
  var tmp$5 = Belt_Option.map(onPress, (function (onPress) {
          return {
                  NAME: "Pressable",
                  VAL: Box$DecidrIoUniversalComponents.makePressableProps(disabled, undefined, undefined, (function (param) {
                          Curry._1(onPress, undefined);
                        }), undefined, undefined, undefined)
                };
        }));
  if (tmp$5 !== undefined) {
    tmp$4.kind = Caml_option.valFromOption(tmp$5);
  }
  if (reversed !== undefined) {
    tmp$4.reversed = reversed;
  }
  if (accessibilityLabel !== undefined) {
    tmp$4.accessibilityLabel = accessibilityLabel;
  }
  if (accessibilityRole !== undefined) {
    tmp$4.accessibilityRole = Caml_option.valFromOption(accessibilityRole);
  }
  if (imageBackground !== undefined) {
    tmp$4.imageBackground = Caml_option.valFromOption(imageBackground);
  }
  if (imageBackgroundStyle !== undefined) {
    tmp$4.imageBackgroundStyle = Caml_option.valFromOption(imageBackgroundStyle);
  }
  if (sizes !== undefined) {
    tmp$4.sizes = sizes;
  }
  if (priority !== undefined) {
    tmp$4.priority = Caml_option.valFromOption(priority);
  }
  if (testMode !== undefined) {
    tmp$4.testMode = testMode;
  }
  return React.createElement(Ui__Group.make, tmp$4);
}

var Apart = {
  make: Ui__Card$Apart
};

var BoundlessContainer = {
  make: Ui__Card$BoundlessContainer
};

var PressableText;

var Button;

var make = Ui__Card;

export {
  Section ,
  Apart ,
  BoundlessContainer ,
  Heading ,
  Description ,
  PressableText ,
  Button ,
  $$Image ,
  BiddingStatus ,
  ExpiryDate ,
  make ,
}
/* match Not a pure module */
