// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AlgoliaApi__Client from "./AlgoliaApi__Client.bs.js";
import * as AlgoliaApi__OffsetToCursorPagination from "./AlgoliaApi__OffsetToCursorPagination.bs.js";

function createSortInfo(sortOrder, sortDirection) {
  if (sortOrder !== undefined) {
    if (sortOrder === "Date") {
      if (sortDirection === "Asc") {
        return "DateAsc";
      } else {
        return "DateDesc";
      }
    } else if (sortOrder === "Name") {
      if (sortDirection === "Asc") {
        return "NameAsc";
      } else {
        return "NameDesc";
      }
    } else if (sortDirection === "Asc") {
      return "PriceAsc";
    } else {
      return "PriceDesc";
    }
  }
  
}

function makeProductVariant(param) {
  var mainImage = param.mainImage;
  var productImage = param.productImage;
  var tmp;
  if (mainImage !== undefined) {
    var match = mainImage.size;
    tmp = {
      url: mainImage.url,
      size: {
        width: match.width,
        height: match.height
      }
    };
  } else {
    tmp = undefined;
  }
  var tmp$1;
  if (productImage !== undefined) {
    var match$1 = productImage.size;
    tmp$1 = {
      url: productImage.url,
      size: {
        width: match$1.width,
        height: match$1.height
      }
    };
  } else {
    tmp$1 = undefined;
  }
  return {
          id: param.objectID,
          sku: param.sku,
          productId: param.productId,
          productTitle: param.productTitle,
          compareAtPrice: param.compareAtPrice,
          price: param.price,
          productPriceRange: param.productPriceRange,
          mainImage: tmp,
          productImage: tmp$1,
          inventoryQuantity: param.inventoryQuantity,
          brandName: param.brandName,
          handle: param.handle,
          usedForTesting: Belt_Option.getWithDefault(param.usedForTesting, false),
          sellingNewItems: Belt_Option.getWithDefault(param.sellingNewItems, false),
          sellingPrelovedItems: Belt_Option.getWithDefault(param.sellingPrelovedItems, false),
          isRefurbishedProduct: param.isRefurbishedProduct,
          refurbishedProductModelYear: param.refurbishedProductModelYear,
          refurbishedProductOriginalPrice: param.refurbishedProductOriginalPrice,
          isOverstockedProduct: param.isOverstockedProduct,
          overstockedProductModelYear: param.overstockedProductModelYear,
          overstockedProductOriginalPrice: param.overstockedProductOriginalPrice,
          isOpenBoxProduct: param.isOpenBoxProduct,
          openboxProductModelYear: param.openboxProductModelYear,
          openboxProductOriginalPrice: param.openboxProductOriginalPrice
        };
}

function getFacetFilters(filters) {
  if (filters === undefined) {
    return ;
  }
  var facetArr = [];
  var brandName = filters.brandName;
  var brandFilters = brandName !== undefined ? brandName : [];
  var categoryName = filters.categoryName;
  var categoryFilters = categoryName !== undefined ? categoryName : [];
  var isRefurbishedProduct = filters.isRefurbishedProduct;
  var isRefurbishedProduct$1 = isRefurbishedProduct !== undefined ? isRefurbishedProduct : false;
  var isOverstockedProduct = filters.isOverstockedProduct;
  var isOverstockedProduct$1 = isOverstockedProduct !== undefined ? isOverstockedProduct : false;
  var isPrelovedProduct = filters.isPrelovedProduct;
  var isPrelovedProduct$1 = isPrelovedProduct !== undefined ? isPrelovedProduct : false;
  var isOpenBoxProduct = filters.isOpenBoxProduct;
  var isOpenBoxProduct$1 = isOpenBoxProduct !== undefined ? isOpenBoxProduct : false;
  facetArr.push(brandFilters.map(function (value) {
            return "brandName:" + value + "";
          }));
  facetArr.push(categoryFilters.map(function (value) {
            return "categoryNames:" + value + "";
          }));
  var productTypesArr = [];
  if (isRefurbishedProduct$1) {
    productTypesArr.push("isRefurbishedProduct:" + Pervasives.string_of_bool(isRefurbishedProduct$1));
  }
  if (isOverstockedProduct$1) {
    productTypesArr.push("isOverstockedProduct:" + Pervasives.string_of_bool(isOverstockedProduct$1));
  }
  if (isPrelovedProduct$1) {
    productTypesArr.push("sellingPrelovedItems:" + Pervasives.string_of_bool(isPrelovedProduct$1));
  }
  if (isOpenBoxProduct$1) {
    productTypesArr.push("isOpenBoxProduct:" + Pervasives.string_of_bool(isOpenBoxProduct$1));
  }
  facetArr.push(productTypesArr);
  return facetArr;
}

function searchProductVariants(apiConfig, searchTerm, first, after, filters, settings, sortKey, sortDirection, analyticsTags, includeRefurbishedInfo, includeOverstockedInfo) {
  var sortInfo = createSortInfo(sortKey, sortDirection);
  var productVariantOffsetPaginator = function (param, param$1, param$2) {
    var searchIndexResult = AlgoliaApi__Client.searchIndex(apiConfig, "ProductVariants", param$2, param, param$1, Belt_Option.map(filters, (function (param) {
                return {
                        brandId: param.brandId,
                        categoryIds: param.categoryIds,
                        price: Belt_Option.map(param.price, (function (__x) {
                                return Belt_Array.map(__x, (function (param) {
                                              return {
                                                      gt: param.gt,
                                                      lt: param.lt
                                                    };
                                            }));
                              })),
                        sellingNewItems: param.sellingNewItems,
                        sellingPrelovedItems: param.sellingPrelovedItems,
                        facetFilters: getFacetFilters(param.facetFilters)
                      };
              })), undefined, settings, sortInfo, undefined, analyticsTags, includeRefurbishedInfo, includeOverstockedInfo, false);
    if (searchIndexResult.TAG === /* ProductVariant */0) {
      return searchIndexResult._0.then(function (param) {
                  var match = param.allFacets;
                  var match$1 = param.searchResp;
                  var facets_stats = match$1.facets_stats;
                  var priceFacetStats = facets_stats !== undefined ? Belt_Option.map(Js_dict.get(Caml_option.valFromOption(facets_stats), "price"), (function (stats) {
                            return stats;
                          })) : undefined;
                  return {
                          data: Belt_Array.map(match$1.hits, makeProductVariant),
                          searchInfo: {
                            nbHits: match$1.nbHits,
                            query: match$1.query,
                            params: match$1.params,
                            processingTimeMS: match$1.processingTimeMS,
                            facets: match$1.facets,
                            minPrice: priceFacetStats !== undefined ? priceFacetStats.min : 0.0,
                            maxPrice: priceFacetStats !== undefined ? priceFacetStats.max : 0.0,
                            allBrandNames: match.allBrandNames,
                            allCategoryNames: match.allCategoryNames
                          }
                        };
                });
    }
    var emptyDic = {};
    return new Promise((function (resolve, param) {
                  resolve({
                        data: [{
                            id: "",
                            sku: undefined,
                            productId: "",
                            productTitle: "",
                            compareAtPrice: undefined,
                            price: 0,
                            productPriceRange: "",
                            mainImage: undefined,
                            productImage: undefined,
                            inventoryQuantity: 0,
                            brandName: undefined,
                            handle: "",
                            usedForTesting: false,
                            sellingNewItems: false,
                            sellingPrelovedItems: false,
                            isRefurbishedProduct: undefined,
                            refurbishedProductModelYear: undefined,
                            refurbishedProductOriginalPrice: undefined,
                            isOverstockedProduct: undefined,
                            overstockedProductModelYear: undefined,
                            overstockedProductOriginalPrice: undefined,
                            isOpenBoxProduct: undefined,
                            openboxProductModelYear: undefined,
                            openboxProductOriginalPrice: undefined
                          }],
                        searchInfo: {
                          nbHits: 0,
                          query: "",
                          params: "",
                          processingTimeMS: 0,
                          facets: emptyDic,
                          minPrice: 0,
                          maxPrice: 0,
                          allBrandNames: [],
                          allCategoryNames: []
                        }
                      });
                }));
  };
  var productVariantCursorPaginator = AlgoliaApi__OffsetToCursorPagination.cursorPaginatorFromOffsetPaginator(productVariantOffsetPaginator, "id", undefined);
  return Curry._3(productVariantCursorPaginator, first, after, searchTerm);
}

export {
  createSortInfo ,
  searchProductVariants ,
}
/* AlgoliaApi__Client Not a pure module */
