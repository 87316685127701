import Link from "next/link";
import { ReactNode } from "react";
import { StyleProp, ViewStyle } from "react-native";

// FIXME ReactNative style can be recursive array, so handle that case
const transformRNStylesToReactStyles = (
  style: StyleProp<ViewStyle> | undefined | null
) => {
  if (style == null) {
    return {};
  }
  // React doesn't accept an array of styles so in case of an array,
  // we are flattening it into single object
  else if (Array.isArray(style)) {
    return style.reduce((_acc, _style) => {
      // FIXME `Object` should be removed
      const acc = Object(_acc);
      const style = Object(_style);
      return { ...acc, ...style };
    }, {});
  } else if (typeof style === "string") {
    console.warn(
      "[Ui__NextLink] - React doesn't support string styles. Returning an empty object instead."
    );
    return {};
  } else if (typeof style === "object") {
    return style;
  } else {
    console.warn(
      "[Ui__NextLink] - Received unknown style structure. Returning an empty object instead.",
      style
    );
    return {};
  }
};
export const NextLink = ({
  a11yLabel,
  style,
  wrapContent = false,
  ...otherProps
}: {
  href: string;
  children: ReactNode;
  as?: string;
  replace?: boolean;
  scroll?: boolean;
  shallow?: boolean;
  passHref?: boolean;
  prefetch?: boolean;
  locale?: string;
  a11yLabel?: string;
  style?: StyleProp<ViewStyle>;
  // Custom prop!
  wrapContent?: boolean;
}) => {
  return (
    <Link
      aria-label={a11yLabel}
      style={{
        // These styles are added in order to make `anchor` behave like the
        // RNW `View` which we "know" how to style usually :)
        // Styles are taken from here:
        // https://github.com/necolas/react-native-web/blob/e8098fd029102d7801c32c1ede792bce01808c00/packages/react-native-web/src/exports/View/index.js#L150
        alignItems: "stretch",
        backgroundColor: "transparent",
        border: "0 solid black",
        boxSizing: "border-box",
        display: "flex",
        flexBasis: "auto",
        flexDirection: "column",
        flexShrink: 0,
        listStyle: "none",
        margin: 0,
        minHeight: 0,
        minWidth: 0,
        padding: 0,
        position: "relative",
        textDecoration: "none",
        zIndex: 0,
        alignSelf: wrapContent ? "flex-start" : undefined,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(transformRNStylesToReactStyles(style) as any),
      }}
      {...otherProps}
    />
  );
};
