// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ShopifyApi__Id from "./ShopifyApi__Id.bs.js";
import * as ShopifyApi__Infra from "./ShopifyApi__Infra.bs.js";
import * as ShopifyApi__Client from "./ShopifyApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";

function parse(value) {
  var value$1 = value.defaultMinPercentageOfBuyNowPrice;
  var value$2 = value.mediaTags;
  var value$3 = value.minMediaItems;
  return {
          productId: value.productId,
          defaultMinPercentageOfBuyNowPrice: !(value$1 == null) ? ({
                value: value$1.value
              }) : undefined,
          mediaTags: !(value$2 == null) ? ({
                value: value$2.value
              }) : undefined,
          minMediaItems: !(value$3 == null) ? ({
                value: value$3.value
              }) : undefined
        };
}

function serialize(value) {
  var value$1 = value.minMediaItems;
  var minMediaItems;
  if (value$1 !== undefined) {
    var value$2 = value$1.value;
    minMediaItems = {
      value: value$2
    };
  } else {
    minMediaItems = null;
  }
  var value$3 = value.mediaTags;
  var mediaTags;
  if (value$3 !== undefined) {
    var value$4 = value$3.value;
    mediaTags = {
      value: value$4
    };
  } else {
    mediaTags = null;
  }
  var value$5 = value.defaultMinPercentageOfBuyNowPrice;
  var defaultMinPercentageOfBuyNowPrice;
  if (value$5 !== undefined) {
    var value$6 = value$5.value;
    defaultMinPercentageOfBuyNowPrice = {
      value: value$6
    };
  } else {
    defaultMinPercentageOfBuyNowPrice = null;
  }
  var value$7 = value.productId;
  return {
          productId: value$7,
          defaultMinPercentageOfBuyNowPrice: defaultMinPercentageOfBuyNowPrice,
          mediaTags: mediaTags,
          minMediaItems: minMediaItems
        };
}

var Raw = {};

var query = "query GetSettingsForPrelovedItemsByProductId($productId: ID!)  {\nproduct(id: $productId)  {\n...SettingsForPrelovedItemsFields   \n}\n\n}\nfragment SettingsForPrelovedItemsFields on Product   {\nproductId: id  \ndefaultMinPercentageOfBuyNowPrice: metafield(namespace: \"real-concepts\", key: \"preloved-item-min-p-of-buy-now\")  {\nvalue  \n}\n\nmediaTags: metafield(namespace: \"real-concepts\", key: \"preloved-item-media-tags\")  {\nvalue  \n}\n\nminMediaItems: metafield(namespace: \"real-concepts\", key: \"preloved-item-min-media-items\")  {\nvalue  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.product;
  return {
          product: !(value$1 == null) ? parse(value$1) : undefined
        };
}

function serialize$1(value) {
  var value$1 = value.product;
  var product = value$1 !== undefined ? serialize(value$1) : null;
  return {
          product: product
        };
}

function serializeVariables(inp) {
  return {
          productId: inp.productId
        };
}

function parseMediaTags(mediaTagsArr) {
  return Js_string.split(",", Js_string.replaceByRe(/\[*\"\]*/g, "", mediaTagsArr));
}

function makeSettingsForPrelovedItems(param) {
  var productId = param.productId;
  return {
          availableTagsForMedia: Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.map(RescriptUtils__OptionPlus.tapNone(param.mediaTags, (function (param) {
                              RescriptUtils__Logger.info2(ShopifyApi__Infra.logger, "makeSettingsForPrelovedItems: Product doesn't have any preloved item media tags:", productId);
                            })), (function (param) {
                          return param.value;
                        })), parseMediaTags), []),
          defaultMinPercentageOfBuyNowPrice: Belt_Option.getWithDefault(Belt_Option.map(RescriptUtils__OptionPlus.tapNone(param.defaultMinPercentageOfBuyNowPrice, (function (param) {
                          RescriptUtils__Logger.info2(ShopifyApi__Infra.logger, "makeSettingsForPrelovedItems: Product doesn't have default min percentage of buy now price:", productId);
                        })), (function (param) {
                      return param.value;
                    })), String(70)),
          minMediaItems: Belt_Option.flatMap(Belt_Option.map(RescriptUtils__OptionPlus.tapNone(param.minMediaItems, (function (param) {
                          RescriptUtils__Logger.info2(ShopifyApi__Infra.logger, "makeSettingsForPrelovedItems: Product doesn't have min media items:", productId);
                        })), (function (param) {
                      return param.value;
                    })), Belt_Int.fromString)
        };
}

function fetchSettingsForPrelovedItemsByProductId(apiConfig, productId) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query,
                    Raw: Raw,
                    parse: parse$1,
                    serialize: serialize$1,
                    serializeVariables: serializeVariables
                  }), {
                productId: ShopifyApi__Id.toString(productId)
              }, undefined).then(function (param) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(param.product, (function (param) {
                                RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "fetchSettingsForPrelovedItemsByProductId - no product found. Id:", ShopifyApi__Id.toString(productId));
                              })), makeSettingsForPrelovedItems);
            });
}

export {
  fetchSettingsForPrelovedItemsByProductId ,
}
/* ShopifyApi__Id Not a pure module */
