/* TypeScript file generated from Ui__PressableText.resi by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as Ui__PressableTextBS__Es6Import from './Ui__PressableText.bs';
const Ui__PressableTextBS: any = Ui__PressableTextBS__Es6Import;

import type {Color_t as ReactNative_Color_t} from '../../../../../rescript-shims/ReactNative.shim';

import type {Style_t as ReactNative_Style_t} from '../../../../../rescript-shims/ReactNative.shim';

import type {Typography_variant as Ui__Theme_Typography_variant} from '../../../../../packages/ui/src/theme/components/Ui__Theme.gen';

import type {View_edgeInsets as ReactNative_View_edgeInsets} from '../../../../../rescript-shims/ReactNative.shim';

import type {linkProps as Ui__AppLink_linkProps} from '../../../../../packages/ui/src/routing/Ui__AppLink.gen';

// tslint:disable-next-line:interface-over-type-literal
export type externalLink = { readonly link: Ui__AppLink_linkProps; readonly baseUrl: string };

// tslint:disable-next-line:interface-over-type-literal
export type behavior = 
    { NAME: "Button"; VAL: () => void }
  | { NAME: "ExternalLink"; VAL: externalLink }
  | { NAME: "Link"; VAL: Ui__AppLink_linkProps };

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly behavior: behavior; 
  readonly underlined?: boolean; 
  readonly titleStyle?: ReactNative_Style_t; 
  readonly disabled?: boolean; 
  readonly fontWeight?: 
    "_100"
  | "_800"
  | "_900"
  | "_600"
  | "_500"
  | "normal"
  | "_200"
  | "_700"
  | "_300"
  | "_400"
  | "bold"; 
  readonly containerStyle?: ReactNative_Style_t; 
  readonly uppercased?: boolean; 
  readonly testID?: string; 
  readonly accessibilityLabel?: string; 
  readonly color?: ReactNative_Color_t; 
  readonly title: string; 
  readonly hitSlop?: ReactNative_View_edgeInsets; 
  readonly textSize?: Ui__Theme_Typography_variant
};

export const make: React.ComponentType<{
  readonly behavior: behavior; 
  readonly underlined?: boolean; 
  readonly titleStyle?: ReactNative_Style_t; 
  readonly disabled?: boolean; 
  readonly fontWeight?: 
    "_100"
  | "_800"
  | "_900"
  | "_600"
  | "_500"
  | "normal"
  | "_200"
  | "_700"
  | "_300"
  | "_400"
  | "bold"; 
  readonly containerStyle?: ReactNative_Style_t; 
  readonly uppercased?: boolean; 
  readonly testID?: string; 
  readonly accessibilityLabel?: string; 
  readonly color?: ReactNative_Color_t; 
  readonly title: string; 
  readonly hitSlop?: ReactNative_View_edgeInsets; 
  readonly textSize?: Ui__Theme_Typography_variant
}> = Ui__PressableTextBS.make;
