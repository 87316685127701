// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "../../../api-adapter-markot/src/MarkotApi__Id.bs.js";
import * as GraphqlCore__Types from "../../../graphql-core/src/GraphqlCore__Types.bs.js";
import * as MarkotApi__PrelovedOrder from "../../../api-adapter-markot/src/MarkotApi__PrelovedOrder.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Core__FromMarkot from "../core/GqlMiddleware__Core__FromMarkot.bs.js";
import * as GqlMiddleware__Entity__PaymentMethod from "./GqlMiddleware__Entity__PaymentMethod.bs.js";
import * as GqlMiddleware__Entity__PrelovedItemInfo from "./GqlMiddleware__Entity__PrelovedItemInfo.bs.js";

function makePrelovedOrder(param) {
  var status = param.status;
  var tmp;
  if (typeof status === "number") {
    tmp = {
      TAG: /* OrderStatusMarkotProcessing */3,
      __typename: "OrderStatusMarkotProcessing"
    };
  } else {
    switch (status.TAG | 0) {
      case /* OrderStatusCancelled */0 :
          tmp = {
            TAG: /* OrderStatusCancelled */0,
            __typename: "OrderStatusCancelled",
            reason: status.reason
          };
          break;
      case /* OrderStatusDeliveryInProgress */1 :
          tmp = {
            TAG: /* OrderStatusDeliveryInProgress */1,
            __typename: "OrderStatusDeliveryInProgress",
            dateSent: status.dateSent,
            estimatedDeliveryDate: status.estimatedDeliveryDate
          };
          break;
      case /* OrderStatusItemDelivered */2 :
          tmp = {
            TAG: /* OrderStatusItemDelivered */2,
            __typename: "OrderStatusItemDelivered",
            date: status.date
          };
          break;
      
    }
  }
  return {
          __typename: "PrelovedOrder",
          id: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.id)),
          billingAddressId: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.billingAddressId)),
          date: param.date,
          deliveryAddressId: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.deliveryAddressId)),
          itemCount: param.itemCount,
          paymentMethodId: param.paymentMethodId,
          purchaseMethod: param.purchaseMethod,
          status: tmp,
          totalPrice: GqlMiddleware__Core__FromMarkot.money(param.totalPrice)
        };
}

function makePrelovedOrderId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "PrelovedOrder", param);
}

function fetchPrelovedOrder(apiConfig, id) {
  return MarkotApi__PrelovedOrder.fetchBasicData(apiConfig, GqlMiddleware__Core__NodeId.toString(id)).then(function (__x) {
              return Belt_Option.map(__x, makePrelovedOrder);
            });
}

function fetchPrelovedOrders(apiConfig, first, after) {
  return MarkotApi__PrelovedOrder.fetchPrelovedOrders(apiConfig, first, after).then(function (__x) {
              return GraphqlCore__Types.Connection.map(__x, makePrelovedOrder);
            });
}

function makePrelovedOrderLineItem(prelovedOrderLineItem) {
  if (prelovedOrderLineItem.TAG === /* PrelovedOrderLineItemCustom */0) {
    return {
            TAG: /* PrelovedOrderLineItemCustom */0,
            __typename: "PrelovedOrderLineItemCustom",
            description: prelovedOrderLineItem.description,
            discountedTotalPrice: GqlMiddleware__Core__FromMarkot.money(prelovedOrderLineItem.discountedTotalPrice),
            id: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(prelovedOrderLineItem.id)),
            isRefundAvailable: prelovedOrderLineItem.isRefundAvailable,
            isReturnAvailable: prelovedOrderLineItem.isReturnAvailable,
            originalTotalPrice: GqlMiddleware__Core__FromMarkot.money(prelovedOrderLineItem.originalTotalPrice),
            quantity: prelovedOrderLineItem.quantity
          };
  } else {
    return {
            TAG: /* PrelovedOrderLineItemPrelovedItem */1,
            __typename: "PrelovedOrderLineItemPrelovedItem",
            discountedTotalPrice: GqlMiddleware__Core__FromMarkot.money(prelovedOrderLineItem.discountedTotalPrice),
            id: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(prelovedOrderLineItem.id)),
            isRefundAvailable: prelovedOrderLineItem.isRefundAvailable,
            isReturnAvailable: prelovedOrderLineItem.isReturnAvailable,
            originalTotalPrice: GqlMiddleware__Core__FromMarkot.money(prelovedOrderLineItem.originalTotalPrice),
            prelovedItem: GqlMiddleware__Entity__PrelovedItemInfo.makePrelovedItemInfo(prelovedOrderLineItem.prelovedItem),
            quantity: prelovedOrderLineItem.quantity
          };
  }
}

function fetchPrelovedOrderLineItems(apiConfig, id, first, after) {
  return MarkotApi__PrelovedOrder.fetchPrelovedOrderLineItems(apiConfig, GqlMiddleware__Core__NodeId.toString(id), first, after).then(function (__x) {
              return GraphqlCore__Types.Connection.map(__x, makePrelovedOrderLineItem);
            });
}

function fetchPrelovedOrderPaymentMethod(apiConfig, id) {
  return MarkotApi__PrelovedOrder.fetchPaymentMethodData(apiConfig, GqlMiddleware__Core__NodeId.toString(id)).then(function (__x) {
              return Belt_Option.map(__x, GqlMiddleware__Entity__PaymentMethod.makePaymentMethod);
            });
}

export {
  makePrelovedOrderId ,
  makePrelovedOrder ,
  fetchPrelovedOrder ,
  fetchPrelovedOrders ,
  makePrelovedOrderLineItem ,
  fetchPrelovedOrderLineItems ,
  fetchPrelovedOrderPaymentMethod ,
}
/* MarkotApi__Id Not a pure module */
