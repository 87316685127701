// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Portal from "@gorhom/portal";

var make = Portal.Portal;

var Provider = {
  make: Portal.PortalProvider
};

var Host = {
  make: Portal.PortalHost
};

export {
  make ,
  Provider ,
  Host ,
}
/* make Not a pure module */
