// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as MarkotApi__Infra from "./MarkotApi__Infra.bs.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";
import * as MarkotApi__BidInfo from "./MarkotApi__BidInfo.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as MarkotApi__AuctionCommons from "./MarkotApi__AuctionCommons.bs.js";
import * as MarkotApi__CommonFragments from "./MarkotApi__CommonFragments.bs.js";
import * as MarkotApi__PrelovedItemInfo from "./MarkotApi__PrelovedItemInfo.bs.js";
import * as MarkotApi__Scalars__DateTime from "./MarkotApi__Scalars__DateTime.bs.js";

var query = "fragment PriceChangedEventFields on PriceChangedEvent   {\nid  \ntimestamp  \noldPrice  {\n...MoneyFields   \n}\n\nnewPrice  {\n...MoneyFields   \n}\n\n}\n" + MarkotApi__CommonFragments.MoneyFields.query;

function parse(value) {
  var value$1 = value.oldPrice;
  var value$2 = value.newPrice;
  return {
          id: value.id,
          timestamp: MarkotApi__Scalars__DateTime.parse(value.timestamp),
          oldPrice: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$1),
          newPrice: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$2)
        };
}

function serialize(value) {
  var value$1 = value.newPrice;
  var newPrice = MarkotApi__CommonFragments.MoneyFields.serialize(value$1);
  var value$2 = value.oldPrice;
  var oldPrice = MarkotApi__CommonFragments.MoneyFields.serialize(value$2);
  var value$3 = value.timestamp;
  var value$4 = MarkotApi__Scalars__DateTime.serialize(value$3);
  var value$5 = value.id;
  return {
          id: value$5,
          timestamp: value$4,
          oldPrice: oldPrice,
          newPrice: newPrice
        };
}

var Raw = {};

var query$1 = "query AuctionBasicData($id: ID!)  {\nnode(id: $id)  {\n__typename\n...on Auction   {\n...AuctionBasicDataFields   \n}\n\n}\n\n}\n" + MarkotApi__AuctionCommons.AuctionBasicDataFields.query;

function parse$1(value) {
  var value$1 = value.node;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1 = typename === "Auction" ? ({
          NAME: "Auction",
          VAL: {
            auctionBasicDataFields: MarkotApi__AuctionCommons.AuctionBasicDataFields.verifyArgsAndParse("AuctionBasicDataFields", value$1)
          }
        }) : ({
          NAME: "UnspecifiedFragment",
          VAL: typename
        });
    tmp = tmp$1;
  }
  return {
          node: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.node;
  var node = value$1 !== undefined ? (
      value$1.NAME === "Auction" ? Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                return {};
              }), [MarkotApi__AuctionCommons.AuctionBasicDataFields.serialize(value$1.VAL.auctionBasicDataFields)]) : ({})
    ) : null;
  return {
          node: node
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

var Raw$1 = {};

var query$2 = "query AuctionHistory($id: ID!, $first: Int, $after: String)  {\nnode(id: $id)  {\n__typename\n...on Auction   {\nhistory(first: $first, after: $after)  {\ntotalEvents  \npageInfo  {\nstartCursor  \nendCursor  \nhasPreviousPage  \nhasNextPage  \n}\n\nedges  {\ncursor  \nnode  {\n__typename\n...on BidInfo   {\n...BidInfoFields   \n}\n\n...on PriceChangedEvent   {\n...PriceChangedEventFields   \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n" + MarkotApi__BidInfo.BidInfoFields.query + query;

function parse$2(value) {
  var value$1 = value.node;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1;
    if (typename === "Auction") {
      var value$2 = value$1.history;
      var value$3 = value$2.pageInfo;
      var value$4 = value$3.startCursor;
      var value$5 = value$3.endCursor;
      var value$6 = value$2.edges;
      tmp$1 = {
        NAME: "Auction",
        VAL: {
          history: {
            totalEvents: value$2.totalEvents,
            pageInfo: {
              startCursor: !(value$4 == null) ? value$4 : undefined,
              endCursor: !(value$5 == null) ? value$5 : undefined,
              hasPreviousPage: value$3.hasPreviousPage,
              hasNextPage: value$3.hasNextPage
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var typename = value$1["__typename"];
                    var tmp;
                    switch (typename) {
                      case "BidInfo" :
                          tmp = {
                            NAME: "BidInfo",
                            VAL: MarkotApi__BidInfo.BidInfoFields.verifyArgsAndParse("BidInfoFields", value$1)
                          };
                          break;
                      case "PriceChangedEvent" :
                          tmp = {
                            NAME: "PriceChangedEvent",
                            VAL: parse(value$1)
                          };
                          break;
                      default:
                        tmp = {
                          NAME: "FutureAddedValue",
                          VAL: value$1
                        };
                    }
                    return {
                            cursor: value.cursor,
                            node: tmp
                          };
                  }), value$6)
          }
        }
      };
    } else {
      tmp$1 = {
        NAME: "UnspecifiedFragment",
        VAL: typename
      };
    }
    tmp = tmp$1;
  }
  return {
          node: tmp
        };
}

function serialize$2(value) {
  var value$1 = value.node;
  var node;
  if (value$1 !== undefined) {
    if (value$1.NAME === "Auction") {
      var value$2 = value$1.VAL.history;
      var value$3 = value$2.edges;
      var edges = Js_array.map((function (value) {
              var value$1 = value.node;
              var variant = value$1.NAME;
              var node = variant === "FutureAddedValue" ? value$1.VAL : (
                  variant === "PriceChangedEvent" ? serialize(value$1.VAL) : MarkotApi__BidInfo.BidInfoFields.serialize(value$1.VAL)
                );
              var value$2 = value.cursor;
              return {
                      cursor: value$2,
                      node: node
                    };
            }), value$3);
      var value$4 = value$2.pageInfo;
      var value$5 = value$4.hasNextPage;
      var value$6 = value$4.hasPreviousPage;
      var value$7 = value$4.endCursor;
      var endCursor = value$7 !== undefined ? value$7 : null;
      var value$8 = value$4.startCursor;
      var startCursor = value$8 !== undefined ? value$8 : null;
      var pageInfo = {
        startCursor: startCursor,
        endCursor: endCursor,
        hasPreviousPage: value$6,
        hasNextPage: value$5
      };
      var value$9 = value$2.totalEvents;
      var history = {
        totalEvents: value$9,
        pageInfo: pageInfo,
        edges: edges
      };
      node = {
        __typename: "Auction",
        history: history
      };
    } else {
      node = {};
    }
  } else {
    node = null;
  }
  return {
          node: node
        };
}

function serializeVariables$1(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  return {
          id: inp.id,
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined
        };
}

var Raw$2 = {};

var query$3 = "mutation ChangeBuyNowPrice($input: ChangeBuyNowPriceInput!)  {\nchangeBuyNowPrice(input: $input)  {\nprelovedItemInfoId  \nprelovedItemInfo  {\n...PrelovedItemInfoFields   \n}\n\npriceChangedEvent  {\n...PriceChangedEventFields   \n}\n\n}\n\n}\n" + MarkotApi__PrelovedItemInfo.PrelovedItemInfoFields.query + query;

function parse$3(value) {
  var value$1 = value.changeBuyNowPrice;
  var value$2 = value$1.prelovedItemInfo;
  return {
          changeBuyNowPrice: {
            prelovedItemInfoId: value$1.prelovedItemInfoId,
            prelovedItemInfo: MarkotApi__PrelovedItemInfo.PrelovedItemInfoFields.verifyArgsAndParse("PrelovedItemInfoFields", value$2),
            priceChangedEvent: parse(value$1.priceChangedEvent)
          }
        };
}

function serialize$3(value) {
  var value$1 = value.changeBuyNowPrice;
  var value$2 = value$1.priceChangedEvent;
  var priceChangedEvent = serialize(value$2);
  var value$3 = value$1.prelovedItemInfo;
  var prelovedItemInfo = MarkotApi__PrelovedItemInfo.PrelovedItemInfoFields.serialize(value$3);
  var value$4 = value$1.prelovedItemInfoId;
  var changeBuyNowPrice = {
    prelovedItemInfoId: value$4,
    prelovedItemInfo: prelovedItemInfo,
    priceChangedEvent: priceChangedEvent
  };
  return {
          changeBuyNowPrice: changeBuyNowPrice
        };
}

function serializeInputObjectChangeBuyNowPriceInput(inp) {
  return {
          newPrice: inp.newPrice,
          prelovedItemInfoId: inp.prelovedItemInfoId
        };
}

function serializeVariables$2(inp) {
  return {
          input: serializeInputObjectChangeBuyNowPriceInput(inp.input)
        };
}

function makePriceChangedEvent(param) {
  return {
          id: MarkotApi__Id.parseExn(param.id),
          timestamp: param.timestamp,
          oldPrice: MarkotApi__CommonFragments.makeMoney(param.oldPrice),
          newPrice: MarkotApi__CommonFragments.makeMoney(param.newPrice)
        };
}

function fetchBasicData(apiConfig, id) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$1,
                    Raw: Raw,
                    parse: parse$1,
                    serialize: serialize$1,
                    serializeVariables: serializeVariables
                  }), {
                id: id
              }, undefined).then(function (param) {
              var node = param.node;
              if (node !== undefined) {
                if (node.NAME === "Auction") {
                  return MarkotApi__AuctionCommons.makeAuction(node.VAL.auctionBasicDataFields);
                } else {
                  RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "Auction fetchBasicData - node of unknown type found. Ignoring.", node);
                  return ;
                }
              } else {
                RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "Auction fetchBasicData - not found. Id", id);
                return ;
              }
            });
}

function fetchAuctionHistory(apiConfig, id, first, after) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                      query: query$2,
                      Raw: Raw$1,
                      parse: parse$2,
                      serialize: serialize$2,
                      serializeVariables: serializeVariables$1
                    }), {
                  id: id,
                  first: first,
                  after: after
                }, undefined).then(function (param) {
                var node = param.node;
                if (node !== undefined) {
                  if (node.NAME === "Auction") {
                    return node.VAL.history;
                  } else {
                    RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "Auction fetchAuctionHistory - node of unknown type found. Ignoring.", node);
                    return ;
                  }
                } else {
                  RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "Auction fetchAuctionHistory - not found. Id", id);
                  return ;
                }
              }).then(function (__x) {
              return Belt_Option.map(__x, (function (param) {
                            var pageInfo = param.pageInfo;
                            return {
                                    totalEvents: param.totalEvents,
                                    pageInfo: {
                                      startCursor: pageInfo.startCursor,
                                      endCursor: pageInfo.endCursor,
                                      hasNextPage: pageInfo.hasNextPage,
                                      hasPreviousPage: pageInfo.hasPreviousPage
                                    },
                                    edges: Belt_Array.map(param.edges, (function (param) {
                                            var node = param.node;
                                            var variant = node.NAME;
                                            return {
                                                    cursor: param.cursor,
                                                    node: variant === "FutureAddedValue" ? (RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "Auction fetchAuctionHistory - unknown typename", node), Js_exn.raiseError("Auction fetchAuctionHistory unknown typename.")) : (
                                                        variant === "PriceChangedEvent" ? ({
                                                              TAG: /* PriceChangedEvent */1,
                                                              _0: makePriceChangedEvent(node.VAL)
                                                            }) : ({
                                                              TAG: /* BidInfo */0,
                                                              _0: MarkotApi__BidInfo.makeBidInfo(node.VAL)
                                                            })
                                                      )
                                                  };
                                          }))
                                  };
                          }));
            });
}

function executeChangeBuyNowPrice(apiConfig, newPrice, prelovedItemInfoId) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$3,
                    Raw: Raw$2,
                    parse: parse$3,
                    serialize: serialize$3,
                    serializeVariables: serializeVariables$2
                  }), {
                input: {
                  newPrice: newPrice,
                  prelovedItemInfoId: prelovedItemInfoId
                }
              }, undefined).then(function (param) {
              var match = param.changeBuyNowPrice;
              return {
                      prelovedItemInfoId: MarkotApi__Id.parseExn(match.prelovedItemInfoId),
                      prelovedItemInfo: MarkotApi__PrelovedItemInfo.makePrelovedItemInfo(match.prelovedItemInfo),
                      priceChangedEvent: makePriceChangedEvent(match.priceChangedEvent)
                    };
            });
}

export {
  fetchBasicData ,
  fetchAuctionHistory ,
  executeChangeBuyNowPrice ,
}
/* MarkotApi__Id Not a pure module */
