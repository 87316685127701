// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";
import * as MarkotApi__CommonFragments from "./MarkotApi__CommonFragments.bs.js";
import * as MarkotApi__Scalars__DateTime from "./MarkotApi__Scalars__DateTime.bs.js";

var query = "fragment MarkotUserFields on MarkotUser   {\nid  \naid  \nfirstName  \nlastName  \nemail  \nbagId  \nphone  {\nid  \ncountryCallingCode  \nphoneNumber  \n}\n\nsellerRating  {\nid  \ntitle  \npercent  \ndescription  \n}\n\naddresses(first: 25) @include(if: $loadAddresses) {\npageInfo  {\nstartCursor  \nendCursor  \nhasNextPage  \nhasPreviousPage  \n}\n\nedges  {\ncursor  \nnode  {\n...AddressFields   \n}\n\n}\n\n}\n\ndefaultBillingAddressId  \ndefaultShippingAddressId  \ndefaultPaymentMethodId2  \nexpoNotificationIds  \n}\n" + MarkotApi__CommonFragments.AddressFields.query;

function parse(value) {
  var value$1 = value.firstName;
  var value$2 = value.lastName;
  var value$3 = value.email;
  var value$4 = value.bagId;
  var value$5 = value.phone;
  var value$6 = value.sellerRating;
  var value$7 = value.addresses;
  var tmp;
  if (value$7 == null) {
    tmp = undefined;
  } else {
    var value$8 = value$7.pageInfo;
    var value$9 = value$8.startCursor;
    var value$10 = value$8.endCursor;
    var value$11 = value$7.edges;
    tmp = {
      pageInfo: {
        startCursor: !(value$9 == null) ? value$9 : undefined,
        endCursor: !(value$10 == null) ? value$10 : undefined,
        hasNextPage: value$8.hasNextPage,
        hasPreviousPage: value$8.hasPreviousPage
      },
      edges: Js_array.map((function (value) {
              var value$1 = value.node;
              return {
                      cursor: value.cursor,
                      node: MarkotApi__CommonFragments.AddressFields.verifyArgsAndParse("AddressFields", value$1)
                    };
            }), value$11)
    };
  }
  var value$12 = value.defaultBillingAddressId;
  var value$13 = value.defaultShippingAddressId;
  var value$14 = value.defaultPaymentMethodId2;
  var value$15 = value.expoNotificationIds;
  return {
          id: value.id,
          aid: value.aid,
          firstName: !(value$1 == null) ? value$1 : undefined,
          lastName: !(value$2 == null) ? value$2 : undefined,
          email: !(value$3 == null) ? MarkotApi__Scalars__DateTime.parse(value$3) : undefined,
          bagId: !(value$4 == null) ? value$4 : undefined,
          phone: !(value$5 == null) ? ({
                id: value$5.id,
                countryCallingCode: value$5.countryCallingCode,
                phoneNumber: value$5.phoneNumber
              }) : undefined,
          sellerRating: {
            id: value$6.id,
            title: value$6.title,
            percent: value$6.percent,
            description: value$6.description
          },
          addresses: tmp,
          defaultBillingAddressId: !(value$12 == null) ? value$12 : undefined,
          defaultShippingAddressId: !(value$13 == null) ? value$13 : undefined,
          defaultPaymentMethodId2: !(value$14 == null) ? value$14 : undefined,
          expoNotificationIds: Js_array.map((function (value) {
                  return value;
                }), value$15)
        };
}

function serialize(value) {
  var value$1 = value.expoNotificationIds;
  var expoNotificationIds = Js_array.map((function (value) {
          return value;
        }), value$1);
  var value$2 = value.defaultPaymentMethodId2;
  var defaultPaymentMethodId2 = value$2 !== undefined ? value$2 : null;
  var value$3 = value.defaultShippingAddressId;
  var defaultShippingAddressId = value$3 !== undefined ? value$3 : null;
  var value$4 = value.defaultBillingAddressId;
  var defaultBillingAddressId = value$4 !== undefined ? value$4 : null;
  var value$5 = value.addresses;
  var addresses;
  if (value$5 !== undefined) {
    var value$6 = value$5.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var node = MarkotApi__CommonFragments.AddressFields.serialize(value$1);
            var value$2 = value.cursor;
            return {
                    cursor: value$2,
                    node: node
                  };
          }), value$6);
    var value$7 = value$5.pageInfo;
    var value$8 = value$7.hasPreviousPage;
    var value$9 = value$7.hasNextPage;
    var value$10 = value$7.endCursor;
    var endCursor = value$10 !== undefined ? value$10 : null;
    var value$11 = value$7.startCursor;
    var startCursor = value$11 !== undefined ? value$11 : null;
    var pageInfo = {
      startCursor: startCursor,
      endCursor: endCursor,
      hasNextPage: value$9,
      hasPreviousPage: value$8
    };
    addresses = {
      pageInfo: pageInfo,
      edges: edges
    };
  } else {
    addresses = null;
  }
  var value$12 = value.sellerRating;
  var value$13 = value$12.description;
  var value$14 = value$12.percent;
  var value$15 = value$12.title;
  var value$16 = value$12.id;
  var sellerRating = {
    id: value$16,
    title: value$15,
    percent: value$14,
    description: value$13
  };
  var value$17 = value.phone;
  var phone;
  if (value$17 !== undefined) {
    var value$18 = value$17.phoneNumber;
    var value$19 = value$17.countryCallingCode;
    var value$20 = value$17.id;
    phone = {
      id: value$20,
      countryCallingCode: value$19,
      phoneNumber: value$18
    };
  } else {
    phone = null;
  }
  var value$21 = value.bagId;
  var bagId = value$21 !== undefined ? value$21 : null;
  var value$22 = value.email;
  var email = value$22 !== undefined ? MarkotApi__Scalars__DateTime.serialize(value$22) : null;
  var value$23 = value.lastName;
  var lastName = value$23 !== undefined ? value$23 : null;
  var value$24 = value.firstName;
  var firstName = value$24 !== undefined ? value$24 : null;
  var value$25 = value.aid;
  var value$26 = value.id;
  return {
          id: value$26,
          aid: value$25,
          firstName: firstName,
          lastName: lastName,
          email: email,
          bagId: bagId,
          phone: phone,
          sellerRating: sellerRating,
          addresses: addresses,
          defaultBillingAddressId: defaultBillingAddressId,
          defaultShippingAddressId: defaultShippingAddressId,
          defaultPaymentMethodId2: defaultPaymentMethodId2,
          expoNotificationIds: expoNotificationIds
        };
}

var Raw = {};

var query$1 = "query ViewerBasicData($loadAddresses: Boolean!)  {\nviewer  {\n...MarkotUserFields   \n}\n\n}\n" + query;

function parse$1(value) {
  var value$1 = value.viewer;
  return {
          viewer: !(value$1 == null) ? parse(value$1) : undefined
        };
}

function serialize$1(value) {
  var value$1 = value.viewer;
  var viewer = value$1 !== undefined ? serialize(value$1) : null;
  return {
          viewer: viewer
        };
}

function serializeVariables(inp) {
  return {
          loadAddresses: inp.loadAddresses
        };
}

var Raw$1 = {};

var query$2 = "mutation UpdateMarkotUser($input: UpdateMarkotUserInput!, $loadAddresses: Boolean!)  {\nupdateMarkotUser(input: $input)  {\nmarkotUserId  \nmarkotUser  {\n...MarkotUserFields   \n}\n\n}\n\n}\n" + query;

function parse$2(value) {
  var value$1 = value.updateMarkotUser;
  return {
          updateMarkotUser: {
            markotUserId: value$1.markotUserId,
            markotUser: parse(value$1.markotUser)
          }
        };
}

function serialize$2(value) {
  var value$1 = value.updateMarkotUser;
  var value$2 = value$1.markotUser;
  var markotUser = serialize(value$2);
  var value$3 = value$1.markotUserId;
  var updateMarkotUser = {
    markotUserId: value$3,
    markotUser: markotUser
  };
  return {
          updateMarkotUser: updateMarkotUser
        };
}

function serializeInputObjectUpdateMarkotUserInput(inp) {
  var a = inp.email;
  var a$1 = inp.phone;
  var a$2 = inp.firstName;
  var a$3 = inp.lastName;
  var a$4 = inp.defaultBillingAddressId;
  var a$5 = inp.defaultShippingAddressId;
  var a$6 = inp.stripePaymentMethodId;
  return {
          email: a !== undefined ? a : undefined,
          phone: a$1 !== undefined ? a$1 : undefined,
          firstName: a$2 !== undefined ? a$2 : undefined,
          lastName: a$3 !== undefined ? a$3 : undefined,
          defaultBillingAddressId: a$4 !== undefined ? a$4 : undefined,
          defaultShippingAddressId: a$5 !== undefined ? a$5 : undefined,
          stripePaymentMethodId: a$6 !== undefined ? a$6 : undefined
        };
}

function serializeVariables$1(inp) {
  return {
          input: serializeInputObjectUpdateMarkotUserInput(inp.input),
          loadAddresses: inp.loadAddresses
        };
}

var Raw$2 = {};

function parse$3(value) {
  var value$1 = value.addExpoToken2;
  var value$2 = value$1.expoTokens;
  return {
          addExpoToken2: {
            expoTokens: Js_array.map((function (value) {
                    return value;
                  }), value$2)
          }
        };
}

function serialize$3(value) {
  var value$1 = value.addExpoToken2;
  var value$2 = value$1.expoTokens;
  var expoTokens = Js_array.map((function (value) {
          return value;
        }), value$2);
  var addExpoToken2 = {
    expoTokens: expoTokens
  };
  return {
          addExpoToken2: addExpoToken2
        };
}

function serializeInputObjectAddExpoTokenInput(inp) {
  return {
          expoNotificationToken: inp.expoNotificationToken
        };
}

function serializeVariables$2(inp) {
  return {
          input: serializeInputObjectAddExpoTokenInput(inp.input)
        };
}

var Raw$3 = {};

function parse$4(value) {
  var value$1 = value.removeExpoToken2;
  var value$2 = value$1.expoTokens;
  return {
          removeExpoToken2: {
            expoTokens: Js_array.map((function (value) {
                    return value;
                  }), value$2)
          }
        };
}

function serialize$4(value) {
  var value$1 = value.removeExpoToken2;
  var value$2 = value$1.expoTokens;
  var expoTokens = Js_array.map((function (value) {
          return value;
        }), value$2);
  var removeExpoToken2 = {
    expoTokens: expoTokens
  };
  return {
          removeExpoToken2: removeExpoToken2
        };
}

function serializeInputObjectRemoveExpoTokenInput(inp) {
  return {
          expoNotificationToken: inp.expoNotificationToken
        };
}

function serializeVariables$3(inp) {
  return {
          input: serializeInputObjectRemoveExpoTokenInput(inp.input)
        };
}

function makeMarkotUser(param) {
  var sellerRating = param.sellerRating;
  return {
          id: MarkotApi__Id.parseExn(param.id),
          aid: param.aid,
          firstName: param.firstName,
          lastName: param.lastName,
          email: param.email,
          bagId: Belt_Option.map(param.bagId, MarkotApi__Id.parseExn),
          phone: Belt_Option.map(param.phone, (function (param) {
                  return {
                          id: MarkotApi__Id.parseExn(param.id),
                          countryCallingCode: param.countryCallingCode,
                          phoneNumber: param.phoneNumber
                        };
                })),
          sellerRating: {
            id: MarkotApi__Id.parseExn(sellerRating.id),
            title: sellerRating.title,
            percent: sellerRating.percent,
            description: sellerRating.description
          },
          defaultPaymentMethodId2: param.defaultPaymentMethodId2,
          defaultBillingAddressId: Belt_Option.map(param.defaultBillingAddressId, MarkotApi__Id.parseExn),
          defaultShippingAddressId: Belt_Option.map(param.defaultShippingAddressId, MarkotApi__Id.parseExn),
          addresses: Belt_Option.map(param.addresses, (function (param) {
                  var pageInfo = param.pageInfo;
                  return {
                          pageInfo: {
                            startCursor: pageInfo.startCursor,
                            endCursor: pageInfo.endCursor,
                            hasNextPage: pageInfo.hasNextPage,
                            hasPreviousPage: pageInfo.hasPreviousPage
                          },
                          edges: Belt_Array.map(param.edges, (function (param) {
                                  return {
                                          cursor: param.cursor,
                                          node: MarkotApi__CommonFragments.makeAddress(param.node)
                                        };
                                }))
                        };
                })),
          expoNotificationIds: param.expoNotificationIds
        };
}

function fetchBasicData(apiConfig) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$1,
                    Raw: Raw,
                    parse: parse$1,
                    serialize: serialize$1,
                    serializeVariables: serializeVariables
                  }), {
                loadAddresses: false
              }, undefined).then(function (param) {
              return Belt_Option.map(param.viewer, makeMarkotUser);
            });
}

var UpdateMarkotUserPayload = {};

function updateMarkotUser(apiConfig, param) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$2,
                    Raw: Raw$1,
                    parse: parse$2,
                    serialize: serialize$2,
                    serializeVariables: serializeVariables$1
                  }), {
                input: {
                  email: param.email,
                  phone: param.phone,
                  firstName: param.firstName,
                  lastName: param.lastName,
                  defaultBillingAddressId: param.defaultBillingAddressId,
                  defaultShippingAddressId: param.defaultShippingAddressId,
                  stripePaymentMethodId: param.stripePaymentMethodId
                },
                loadAddresses: true
              }, undefined).then(function (param) {
              var markotUser = param.updateMarkotUser.markotUser;
              return {
                      markotUserId: MarkotApi__Id.parseExn(markotUser.id),
                      markotUser: makeMarkotUser(markotUser)
                    };
            });
}

var AddExpoTokenPayload = {};

function addExpoToken(apiConfig, param) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: "mutation AddExpoToken($input: AddExpoTokenInput!)  {\naddExpoToken2(input: $input)  {\nexpoTokens  \n}\n\n}\n",
                    Raw: Raw$2,
                    parse: parse$3,
                    serialize: serialize$3,
                    serializeVariables: serializeVariables$2
                  }), {
                input: {
                  expoNotificationToken: param.expoNotificationToken
                }
              }, undefined).then(function (param) {
              return {
                      expoTokens: param.addExpoToken2.expoTokens
                    };
            });
}

var RemoveExpoTokenPayload = {};

function removeExpoToken(apiConfig, param) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: "mutation RemoveExpoToken($input: RemoveExpoTokenInput!)  {\nremoveExpoToken2(input: $input)  {\nexpoTokens  \n}\n\n}\n",
                    Raw: Raw$3,
                    parse: parse$4,
                    serialize: serialize$4,
                    serializeVariables: serializeVariables$3
                  }), {
                input: {
                  expoNotificationToken: param.expoNotificationToken
                }
              }, undefined).then(function (param) {
              return {
                      expoTokens: param.removeExpoToken2.expoTokens
                    };
            });
}

export {
  fetchBasicData ,
  UpdateMarkotUserPayload ,
  updateMarkotUser ,
  AddExpoTokenPayload ,
  addExpoToken ,
  RemoveExpoTokenPayload ,
  removeExpoToken ,
}
/* MarkotApi__Id Not a pure module */
