// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";
import * as Graphql from "graphql";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schema from "@graphql-tools/schema";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as GqlMiddleware__Loaders from "../loaders/GqlMiddleware__Loaders.bs.js";
import * as GraphqlCore__ParserUtils from "../../../graphql-core/src/GraphqlCore__ParserUtils.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";
import * as GqlMiddleware__Core__Utils from "../core/GqlMiddleware__Core__Utils.bs.js";
import * as GqlMiddleware__Server__Schema from "./GqlMiddleware__Server__Schema.bs.js";
import * as FeatureFlags__AppSpecificConfig from "../../../feature-flags/src/local/FeatureFlags__AppSpecificConfig.bs.js";
import * as GqlMiddleware__Server__Resolvers from "./GqlMiddleware__Server__Resolvers.bs.js";

var schema = Schema.makeExecutableSchema({
      typeDefs: GqlMiddleware__Server__Schema.schemaSdl,
      resolvers: GqlMiddleware__Server__Resolvers.resolvers,
      inheritResolversFromInterfaces: true
    });

function execute(request, variables, context) {
  var shopifyApiConfig_url = context.shopifyApiConfig.url;
  var shopifyApiConfig_storefrontAccessToken = context.shopifyApiConfig.storefrontAccessToken;
  var shopifyApiConfig = {
    url: shopifyApiConfig_url,
    storefrontAccessToken: shopifyApiConfig_storefrontAccessToken
  };
  var markotApiConfig_url = context.markotApiConfig.url;
  var markotApiConfig_apiKey = context.markotApiConfig.apiKey;
  var markotApiConfig_accessToken = context.markotApiConfig.accessToken;
  var markotApiConfig_decidrAppId = context.markotApiConfig.decidrAppId;
  var markotApiConfig = {
    url: markotApiConfig_url,
    apiKey: markotApiConfig_apiKey,
    accessToken: markotApiConfig_accessToken,
    decidrAppId: markotApiConfig_decidrAppId
  };
  var algoliaApiConfig_appId = context.algoliaApiConfig.appId;
  var algoliaApiConfig_searchOnlyApiKey = context.algoliaApiConfig.searchOnlyApiKey;
  var algoliaApiConfig = {
    appId: algoliaApiConfig_appId,
    searchOnlyApiKey: algoliaApiConfig_searchOnlyApiKey
  };
  var freshdeskApiConfig_apiKey = context.freshdeskApiConfig.apiKey;
  var freshdeskApiConfig_baseUrl = context.freshdeskApiConfig.baseUrl;
  var freshdeskApiConfig = {
    apiKey: freshdeskApiConfig_apiKey,
    baseUrl: freshdeskApiConfig_baseUrl
  };
  var loaders = GqlMiddleware__Loaders.make(shopifyApiConfig, Belt_Option.getWithDefault(GqlMiddleware__Core__Utils.isTestUser(context.cognitoGroups), false), Belt_Option.getWithDefault(Belt_Option.map(Curry._2(FeatureFlags__AppSpecificConfig.FeatureFlagsMap.get, context.featureFlags, "useMarkdownDescription"), (function (param) {
                  return param.status === "on";
                })), false), true, true);
  var contextValue_cognitoGroups = context.cognitoGroups;
  var contextValue_featureFlags = context.featureFlags;
  var contextValue = {
    markotApiConfig: markotApiConfig,
    shopifyApiConfig: shopifyApiConfig,
    algoliaApiConfig: algoliaApiConfig,
    freshdeskApiConfig: freshdeskApiConfig,
    loaders: loaders,
    cognitoGroups: contextValue_cognitoGroups,
    featureFlags: contextValue_featureFlags
  };
  return $$Promise.$$catch(Graphql.graphql({
                    schema: schema,
                    rootValue: undefined,
                    source: request,
                    variableValues: variables,
                    contextValue: contextValue
                  }).then(function (result) {
                  var errors = result.errors;
                  if (errors !== undefined) {
                    var partialData = result.data;
                    if (partialData !== undefined) {
                      RescriptUtils__Logger.error3(GqlMiddleware__Core__Infra.logger, (new Error("Middleware received errors along with data")), errors, Caml_option.valFromOption(partialData));
                    } else {
                      RescriptUtils__Logger.error2(GqlMiddleware__Core__Infra.logger, (new Error("Middleware received errors only")), errors);
                    }
                  } else if (result.data !== undefined) {
                    
                  } else {
                    RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "GqlMiddleware.execute received no data and no errors");
                  }
                  GraphqlCore__ParserUtils.forceObjectPrototype(result);
                  return result;
                }), (function (reason) {
                RescriptUtils__Logger.error2(GqlMiddleware__Core__Infra.logger, reason, "Graphql.execute error in catch");
                return Promise.reject(reason);
              }));
}

export {
  execute ,
}
/* schema Not a pure module */
