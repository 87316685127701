// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Infra from "./MarkotApi__Infra.bs.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";

var Raw = {};

var query = "fragment PaymentMethodFields on PaymentMethodDetails   {\nstripePaymentMethodId  \ncardBrand  \ncardLast4  \n}\n";

function parse(value) {
  return {
          stripePaymentMethodId: value.stripePaymentMethodId,
          cardBrand: value.cardBrand,
          cardLast4: value.cardLast4
        };
}

function serialize(value) {
  var value$1 = value.cardLast4;
  var value$2 = value.cardBrand;
  var value$3 = value.stripePaymentMethodId;
  return {
          stripePaymentMethodId: value$3,
          cardBrand: value$2,
          cardLast4: value$1
        };
}

function verifyArgsAndParse(_PaymentMethodFields, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var Raw$1 = {};

var query$1 = "query PaymentMethods2($fetchLiveData: Boolean)  {\nviewer  {\npaymentMethods2(fetchLiveData: $fetchLiveData)  {\n...PaymentMethodFields   \n}\n\n}\n\n}\nfragment PaymentMethodFields on PaymentMethodDetails   {\nstripePaymentMethodId  \ncardBrand  \ncardLast4  \n}\n";

function parse$1(value) {
  var value$1 = value.viewer;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.paymentMethods2;
    tmp = {
      paymentMethods2: Js_array.map(parse, value$2)
    };
  }
  return {
          viewer: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.viewer;
  var viewer;
  if (value$1 !== undefined) {
    var value$2 = value$1.paymentMethods2;
    var paymentMethods2 = Js_array.map(serialize, value$2);
    viewer = {
      paymentMethods2: paymentMethods2
    };
  } else {
    viewer = null;
  }
  return {
          viewer: viewer
        };
}

function serializeVariables(inp) {
  var a = inp.fetchLiveData;
  return {
          fetchLiveData: a !== undefined ? a : undefined
        };
}

var Raw$2 = {};

var query$2 = "query DefaultPaymentMethod2($fetchLiveData: Boolean)  {\nviewer  {\ndefaultPaymentMethod2(fetchLiveData: $fetchLiveData)  {\n...PaymentMethodFields   \n}\n\n}\n\n}\nfragment PaymentMethodFields on PaymentMethodDetails   {\nstripePaymentMethodId  \ncardBrand  \ncardLast4  \n}\n";

function parse$2(value) {
  var value$1 = value.viewer;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.defaultPaymentMethod2;
    tmp = {
      defaultPaymentMethod2: !(value$2 == null) ? parse(value$2) : undefined
    };
  }
  return {
          viewer: tmp
        };
}

function serialize$2(value) {
  var value$1 = value.viewer;
  var viewer;
  if (value$1 !== undefined) {
    var value$2 = value$1.defaultPaymentMethod2;
    var defaultPaymentMethod2 = value$2 !== undefined ? serialize(value$2) : null;
    viewer = {
      defaultPaymentMethod2: defaultPaymentMethod2
    };
  } else {
    viewer = null;
  }
  return {
          viewer: viewer
        };
}

function serializeVariables$1(inp) {
  var a = inp.fetchLiveData;
  return {
          fetchLiveData: a !== undefined ? a : undefined
        };
}

var Raw$3 = {};

function parse$3(value) {
  var value$1 = value.deletePaymentMethod;
  return {
          deletePaymentMethod: {
            deletedStripePaymentMethodId: value$1.deletedStripePaymentMethodId
          }
        };
}

function serialize$3(value) {
  var value$1 = value.deletePaymentMethod;
  var value$2 = value$1.deletedStripePaymentMethodId;
  var deletePaymentMethod = {
    deletedStripePaymentMethodId: value$2
  };
  return {
          deletePaymentMethod: deletePaymentMethod
        };
}

function serializeInputObjectDeletePaymentMethodInput(inp) {
  return {
          stripePaymentMethodId: inp.stripePaymentMethodId
        };
}

function serializeVariables$2(inp) {
  return {
          input: serializeInputObjectDeletePaymentMethodInput(inp.input)
        };
}

function makePaymentMethod(param) {
  return {
          stripePaymentMethodId: param.stripePaymentMethodId,
          cardBrand: param.cardBrand,
          cardLast4: param.cardLast4
        };
}

function fetchPaymentMethods2(apiConfig, fetchLiveData) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$1,
                    Raw: Raw$1,
                    parse: parse$1,
                    serialize: serialize$1,
                    serializeVariables: serializeVariables
                  }), {
                fetchLiveData: fetchLiveData
              }, undefined).then(function (param) {
              return Belt_Option.map(Belt_Option.map(RescriptUtils__OptionPlus.tapNone(param.viewer, (function (param) {
                                    RescriptUtils__Logger.warn(MarkotApi__Infra.logger, "PaymentMethod - fetchPaymentMethods2: `viewer` not returned");
                                  })), (function (param) {
                                return param.paymentMethods2;
                              })), (function (__x) {
                            return Belt_Array.map(__x, makePaymentMethod);
                          }));
            });
}

function fetchDefaultPaymentMethod2(apiConfig, fetchLiveData) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$2,
                    Raw: Raw$2,
                    parse: parse$2,
                    serialize: serialize$2,
                    serializeVariables: serializeVariables$1
                  }), {
                fetchLiveData: fetchLiveData
              }, undefined).then(function (param) {
              return Belt_Option.flatMap(RescriptUtils__OptionPlus.tapNone(param.viewer, (function (param) {
                                RescriptUtils__Logger.warn(MarkotApi__Infra.logger, "DefaultPaymentMethod - fetchDefaultPaymentMethod2: `viewer` not returned");
                              })), (function (param) {
                            return Belt_Option.map(param.defaultPaymentMethod2, makePaymentMethod);
                          }));
            });
}

function deletePaymentMethod(apiConfig, deletePaymentMethodInput) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: "mutation DeletePaymentMethod($input: DeletePaymentMethodInput!)  {\ndeletePaymentMethod(input: $input)  {\ndeletedStripePaymentMethodId  \n}\n\n}\n",
                    Raw: Raw$3,
                    parse: parse$3,
                    serialize: serialize$3,
                    serializeVariables: serializeVariables$2
                  }), {
                input: {
                  stripePaymentMethodId: deletePaymentMethodInput.stripePaymentMethodId
                }
              }, undefined).then(function (param) {
              return {
                      deletedStripePaymentMethodId: param.deletePaymentMethod.deletedStripePaymentMethodId
                    };
            });
}

function PaymentMethodFields_unsafe_fromJson(prim) {
  return prim;
}

function PaymentMethodFields_toJson(prim) {
  return prim;
}

var PaymentMethodFields = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName,
  unsafe_fromJson: PaymentMethodFields_unsafe_fromJson,
  toJson: PaymentMethodFields_toJson
};

export {
  PaymentMethodFields ,
  makePaymentMethod ,
  fetchPaymentMethods2 ,
  fetchDefaultPaymentMethod2 ,
  deletePaymentMethod ,
}
/* MarkotApi__Infra Not a pure module */
