// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";

function useNavigation(param) {
  return Js_null_undefined.fromOption(undefined);
}

function useIsFocused(param) {
  return false;
}

export {
  useNavigation ,
  useIsFocused ,
}
/* No side effect */
