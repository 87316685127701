// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "../../routing/Ui__AppLink.bs.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Ui__ExternalLink from "../../routing/Ui__ExternalLink.bs.js";

function Ui__PressableText(Props) {
  var title = Props.title;
  var behavior = Props.behavior;
  var color = Props.color;
  var containerStyle = Props.containerStyle;
  var titleStyle = Props.titleStyle;
  var testIDOpt = Props.testID;
  var disabledOpt = Props.disabled;
  var underlinedOpt = Props.underlined;
  var uppercasedOpt = Props.uppercased;
  var fontWeight = Props.fontWeight;
  var textSize = Props.textSize;
  var hitSlop = Props.hitSlop;
  var accessibilityLabel = Props.accessibilityLabel;
  var testID = testIDOpt !== undefined ? testIDOpt : "pressable-text";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var underlined = underlinedOpt !== undefined ? underlinedOpt : false;
  var uppercased = uppercasedOpt !== undefined ? uppercasedOpt : false;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = Curry._1(Ui__AppLink.useRouting, undefined);
  var nativeNavigationHandler = match$1.nativeNavigationHandler;
  var variant = behavior.NAME;
  var internalVariant;
  if (variant === "Button") {
    internalVariant = {
      NAME: "RegularButton",
      VAL: behavior.VAL
    };
  } else if (variant === "Link") {
    var match$2 = behavior.VAL;
    var route = match$2.route;
    internalVariant = nativeNavigationHandler !== undefined ? ({
          NAME: "NativeLink",
          VAL: (function (param) {
              Curry._1(nativeNavigationHandler, Curry._3(Ui__AppLink.makeConfiguration, route, undefined, undefined));
            })
        }) : ({
          NAME: "WebLink",
          VAL: [
            Curry._3(Ui__AppLink.makeConfiguration, route, undefined, undefined),
            match$2.a11yLabel
          ]
        });
  } else {
    var match$3 = behavior.VAL;
    var match$4 = match$3.link;
    var match$5 = Ui__ExternalLink.handle(match$4.route, match$3.baseUrl);
    internalVariant = match$5.NAME === "Web" ? ({
          NAME: "WebLink",
          VAL: [
            Curry._3(Ui__AppLink.makeConfiguration, match$5.VAL, undefined, undefined),
            match$4.a11yLabel
          ]
        }) : ({
          NAME: "RegularButton",
          VAL: match$5.VAL
        });
  }
  var color$1 = disabled ? theme.colors.onSecondary : (
      color !== undefined ? color : theme.colors.primary
    );
  var tmp = {
    size: Belt_Option.getWithDefault(textSize, "b2"),
    color: color$1,
    children: title,
    style: [
      {
        fontWeight: (function () {
              switch (Belt_Option.getWithDefault(fontWeight, "_400")) {
                case "normal" :
                    return "normal";
                case "bold" :
                    return "bold";
                case "_100" :
                    return "100";
                case "_200" :
                    return "200";
                case "_300" :
                    return "300";
                case "_400" :
                    return "400";
                case "_500" :
                    return "500";
                case "_600" :
                    return "600";
                case "_700" :
                    return "700";
                case "_800" :
                    return "800";
                case "_900" :
                    return "900";
                
              }
            })(),
        textDecorationLine: (function () {
              switch (underlined ? "underline" : "none") {
                case "none" :
                    return "none";
                case "underline" :
                    return "underline";
                case "lineThrough" :
                    return "line-through";
                case "underlineLineThrough" :
                    return "underline line-through";
                
              }
            })(),
        textTransform: uppercased ? "uppercase" : "none"
      },
      titleStyle
    ]
  };
  if (accessibilityLabel !== undefined) {
    tmp.accessibilityLabel = Caml_option.valFromOption(accessibilityLabel);
  }
  var textElement = React.createElement(Ui__MarkotText.make, tmp);
  var variant$1 = internalVariant.NAME;
  if (variant$1 === "NativeLink") {
    var onPress = internalVariant.VAL;
    var tmp$1 = {
      disabled: disabled,
      onPress: (function (param) {
          Curry._1(onPress, undefined);
        }),
      testID: testID,
      children: textElement
    };
    if (containerStyle !== undefined) {
      tmp$1.style = Caml_option.valFromOption(containerStyle);
    }
    if (hitSlop !== undefined) {
      tmp$1.hitSlop = Caml_option.valFromOption(hitSlop);
    }
    return React.createElement(ReactNative.TouchableOpacity, tmp$1);
  }
  if (variant$1 === "WebLink") {
    if (disabled) {
      var tmp$2 = {
        disabled: disabled,
        testID: testID,
        children: textElement
      };
      if (containerStyle !== undefined) {
        tmp$2.style = Caml_option.valFromOption(containerStyle);
      }
      if (hitSlop !== undefined) {
        tmp$2.hitSlop = Caml_option.valFromOption(hitSlop);
      }
      return React.createElement(ReactNative.TouchableOpacity, tmp$2);
    }
    var match$6 = internalVariant.VAL;
    var tmp$3 = {
      disabled: disabled,
      testID: testID,
      children: textElement
    };
    if (containerStyle !== undefined) {
      tmp$3.style = Caml_option.valFromOption(containerStyle);
    }
    if (hitSlop !== undefined) {
      tmp$3.hitSlop = Caml_option.valFromOption(hitSlop);
    }
    return React.createElement(Ui__AppLink.make, {
                configuration: match$6[0],
                children: React.createElement(ReactNative.TouchableOpacity, tmp$3),
                a11yLabel: match$6[1]
              });
  }
  var onPress$1 = internalVariant.VAL;
  var tmp$4 = {
    disabled: disabled,
    onPress: (function (param) {
        Curry._1(onPress$1, undefined);
      }),
    testID: testID,
    children: textElement
  };
  if (containerStyle !== undefined) {
    tmp$4.style = Caml_option.valFromOption(containerStyle);
  }
  if (hitSlop !== undefined) {
    tmp$4.hitSlop = Caml_option.valFromOption(hitSlop);
  }
  return React.createElement(ReactNative.TouchableOpacity, tmp$4);
}

var make = Ui__PressableText;

export {
  make ,
}
/* react Not a pure module */
