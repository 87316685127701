// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_promise from "rescript/lib/es6/js_promise.js";
import * as ReactRelay from "react-relay";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as AppProfileInfoViewerQuery_graphql from "../../../relay-codegen/generated/AppProfileInfoViewerQuery_graphql.bs.js";

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  var __x = ReactRelay.fetchQuery(environment, AppProfileInfoViewerQuery_graphql.node, AppProfileInfoViewerQuery_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).toPromise();
  return Js_promise.then_((function (res) {
                return Promise.resolve(AppProfileInfoViewerQuery_graphql.Internal.convertResponse(res));
              }), __x);
}

function getViewerAid(environment) {
  return fetchPromised(environment, {}, undefined, undefined, undefined).then(function (param) {
              var viewer = param.viewer;
              if (viewer !== undefined) {
                return Promise.resolve(viewer.aid);
              } else {
                console.warn("[App__ProfileInfo] user is authenticated but viewer is null");
                return Promise.resolve(undefined);
              }
            });
}

export {
  getViewerAid ,
}
/* react-relay Not a pure module */
