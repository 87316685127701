// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as AlgoliaApi__Client from "./AlgoliaApi__Client.bs.js";
import * as AlgoliaApi__OffsetToCursorPagination from "./AlgoliaApi__OffsetToCursorPagination.bs.js";

function makePrelovedItemSearchResult(param) {
  return {
          id: param.objectID,
          productName: param.productName,
          brandName: param.brandName,
          condition: param.condition,
          buyNowPrice: param.buyNowPrice,
          highestBid: param.highestBid,
          numberOfBids: param.numberOfBids,
          productSlug: param.productSlug,
          purchaseAmount: param.purchaseAmount,
          mainImage: Belt_Option.map(param.mainImage, (function (param) {
                  var match = param.size;
                  return {
                          url: param.url,
                          size: {
                            width: match.width,
                            height: match.height
                          }
                        };
                })),
          status: param.status,
          minimumBid: param.minimumBid,
          usedForTesting: Belt_Option.getWithDefault(param.usedForTesting, false)
        };
}

function createPrelovedItemsSortInfo(prelovedItemsSortOrder, sortDirection) {
  if (prelovedItemsSortOrder !== undefined) {
    if (prelovedItemsSortOrder === "NumberOfBids") {
      if (sortDirection === "Asc") {
        return "NumberOfBidsAsc";
      } else {
        return "NumberOfBidsDesc";
      }
    } else if (prelovedItemsSortOrder === "Date") {
      if (sortDirection === "Asc") {
        return "DateAsc";
      } else {
        return "DateDesc";
      }
    } else if (prelovedItemsSortOrder === "BuyNowPrice") {
      if (sortDirection === "Asc") {
        return "BuyNowPriceAsc";
      } else {
        return "BuyNowPriceDesc";
      }
    } else if (sortDirection === "Asc") {
      return "HighestBidAsc";
    } else {
      return "HighestBidDesc";
    }
  }
  
}

function searchPrelovedItems(apiConfig, searchTerm, first, after, prelovedItemsSortOrder, sortDirection, filters, settings, newPrelovedCards) {
  var prelovedItemSortInfo = createPrelovedItemsSortInfo(prelovedItemsSortOrder, sortDirection);
  var prelovedItemsOffsetPaginator = function (param, param$1, param$2) {
    var searchIndexResult = AlgoliaApi__Client.searchIndex(apiConfig, "PrelovedItems", param$2, param, param$1, undefined, filters, settings, undefined, prelovedItemSortInfo, undefined, false, false, newPrelovedCards);
    if (searchIndexResult.TAG !== /* ProductVariant */0) {
      return searchIndexResult._0.then(function (param) {
                  return {
                          data: Belt_Array.map(param.hits, makePrelovedItemSearchResult),
                          searchInfo: {
                            nbHits: param.nbHits,
                            query: param.query,
                            params: param.params,
                            processingTimeMS: param.processingTimeMS,
                            facets: param.facets,
                            minPrice: 0,
                            maxPrice: 0,
                            allBrandNames: [],
                            allCategoryNames: []
                          }
                        };
                });
    }
    var emptyDic = {};
    return new Promise((function (resolve, param) {
                  resolve({
                        data: [{
                            id: "",
                            productName: "",
                            brandName: undefined,
                            condition: "",
                            buyNowPrice: 0,
                            highestBid: undefined,
                            numberOfBids: 0,
                            productSlug: "",
                            purchaseAmount: undefined,
                            mainImage: undefined,
                            status: "NotPublished",
                            minimumBid: undefined,
                            usedForTesting: false
                          }],
                        searchInfo: {
                          nbHits: 0,
                          query: "",
                          params: "",
                          processingTimeMS: 0,
                          facets: emptyDic,
                          minPrice: 0,
                          maxPrice: 0,
                          allBrandNames: [],
                          allCategoryNames: []
                        }
                      });
                }));
  };
  var prelovedItemCursorPaginator = AlgoliaApi__OffsetToCursorPagination.cursorPaginatorFromOffsetPaginator(prelovedItemsOffsetPaginator, "id", undefined);
  return Curry._3(prelovedItemCursorPaginator, first, after, searchTerm);
}

export {
  createPrelovedItemsSortInfo ,
  searchPrelovedItems ,
}
/* AlgoliaApi__Client Not a pure module */
