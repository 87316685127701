// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotImage from "../../../../../ui/src/image/components/Ui__MarkotImage.bs.js";

var match = Ui__Styling.describe({
      image: {
        TAG: /* Static */0,
        _0: {
          height: 18,
          width: 59
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function App__GooglePlacesAttribution(Props) {
  var containerStyle = Props.containerStyle;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, match.theme);
  var tmp = {
    testID: "attribution-container",
    children: ReactNative.Platform.OS === "web" ? React.createElement(Ui__MarkotImage.make, {
            image: {
              src: "https://cdn.filestackcontent.com/3ZyKN5NuT7Or5c2WdIQI",
              width: 59,
              height: 18
            },
            alt: "google-attribution",
            size: {
              NAME: "fixedWidth",
              VAL: {
                width: 59,
                aspectRatio: 18 / 59
              }
            },
            resizeMode: "contain"
          }) : React.createElement(ReactNative.Image, {
            source: require("./assets/google_on_white.png"),
            style: Curry._1(styled, styles.image),
            testID: "attribution"
          })
  };
  if (containerStyle !== undefined) {
    tmp.style = Caml_option.valFromOption(containerStyle);
  }
  return React.createElement(ReactNative.View, tmp);
}

var make = App__GooglePlacesAttribution;

export {
  make ,
}
/* match Not a pure module */
