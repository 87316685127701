// Generated by ReScript, PLEASE EDIT WITH CARE


function make(prefix) {
  return {
          prefix: "[" + prefix + "]"
        };
}

function info(t, a) {
  console.info(t.prefix, a);
}

function info2(t, a, b) {
  console.info(t.prefix, a, b);
}

function info3(t, a, b, c) {
  console.info(t.prefix, a, b, c);
}

function warn(t, a) {
  console.warn(t.prefix, a);
}

function warn2(t, a, b) {
  console.warn(t.prefix, a, b);
}

function warn3(t, a, b, c) {
  console.warn(t.prefix, a, b, c);
}

function error(t, a) {
  console.error(a, t.prefix);
}

function error2(t, a, b) {
  console.error(a, b, t.prefix);
}

function error3(t, a, b, c) {
  console.error(a, b, c, t.prefix);
}

export {
  make ,
  info ,
  info2 ,
  info3 ,
  warn ,
  warn2 ,
  warn3 ,
  error ,
  error2 ,
  error3 ,
}
/* No side effect */
