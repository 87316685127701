// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "../../../api-adapter-markot/src/MarkotApi__Id.bs.js";
import * as MarkotApi__Auction from "../../../api-adapter-markot/src/MarkotApi__Auction.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Entity__BidInfo from "./GqlMiddleware__Entity__BidInfo.bs.js";
import * as GqlMiddleware__Core__FromMarkot from "../core/GqlMiddleware__Core__FromMarkot.bs.js";
import * as GqlMiddleware__Entity__AuctionPricing from "./GqlMiddleware__Entity__AuctionPricing.bs.js";
import * as GqlMiddleware__Entity__PrelovedItemInfo from "./GqlMiddleware__Entity__PrelovedItemInfo.bs.js";

function makeAuctionId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "Auction", param);
}

function fetchAuction(apiConfig, id) {
  return MarkotApi__Auction.fetchBasicData(apiConfig, GqlMiddleware__Core__NodeId.toString(id)).then(function (__x) {
              return Belt_Option.map(__x, (function (param) {
                            return {
                                    __typename: "Auction",
                                    id: GqlMiddleware__Core__NodeId.make(1, "Auction", MarkotApi__Id.unpackExn(param.id).internalId),
                                    pricing: GqlMiddleware__Entity__AuctionPricing.makeAuctionPricing(param.pricing),
                                    settings: {
                                      __typename: "AuctionSettings",
                                      endsAt: param.settings.endsAt
                                    },
                                    totalBids: param.totalBids,
                                    highestBidInfo: Belt_Option.map(param.highestBidInfo, GqlMiddleware__Entity__BidInfo.makeBidInfo),
                                    latestBidAt: param.latestBidAt,
                                    viewerLastActiveBidInfo: Belt_Option.map(param.viewerLastActiveBidInfo, GqlMiddleware__Entity__BidInfo.makeBidInfo)
                                  };
                          }));
            });
}

function makePriceChangedEvent(param) {
  return {
          __typename: "PriceChangedEvent",
          id: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.id)),
          timestamp: param.timestamp,
          oldPrice: GqlMiddleware__Core__FromMarkot.money(param.oldPrice),
          newPrice: GqlMiddleware__Core__FromMarkot.money(param.newPrice)
        };
}

function fetchAuctionHistory(apiConfig, id, first, after) {
  return MarkotApi__Auction.fetchAuctionHistory(apiConfig, id, first, after).then(function (__x) {
              return Belt_Option.map(__x, (function (param) {
                            var pageInfo = param.pageInfo;
                            return {
                                    totalEvents: param.totalEvents,
                                    pageInfo: {
                                      startCursor: pageInfo.startCursor,
                                      endCursor: pageInfo.endCursor,
                                      hasNextPage: pageInfo.hasNextPage,
                                      hasPreviousPage: pageInfo.hasPreviousPage
                                    },
                                    edges: Belt_Array.map(param.edges, (function (param) {
                                            var node = param.node;
                                            var tmp;
                                            tmp = node.TAG === /* BidInfo */0 ? ({
                                                  TAG: /* BidInfo */0,
                                                  _0: GqlMiddleware__Entity__BidInfo.makeBidInfo(node._0)
                                                }) : ({
                                                  TAG: /* PriceChangedEvent */1,
                                                  _0: makePriceChangedEvent(node._0)
                                                });
                                            return {
                                                    cursor: param.cursor,
                                                    node: tmp
                                                  };
                                          }))
                                  };
                          }));
            });
}

function executeChangeBuyNowPrice(apiConfig, newPrice, prelovedItemInfoId) {
  return MarkotApi__Auction.executeChangeBuyNowPrice(apiConfig, newPrice, prelovedItemInfoId).then(function (param) {
              return {
                      __typename: "ChangeBuyNowPricePayload",
                      prelovedItemInfoId: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.prelovedItemInfoId)),
                      prelovedItemInfo: GqlMiddleware__Entity__PrelovedItemInfo.makePrelovedItemInfo(param.prelovedItemInfo),
                      priceChangedEvent: makePriceChangedEvent(param.priceChangedEvent)
                    };
            });
}

export {
  makeAuctionId ,
  fetchAuction ,
  fetchAuctionHistory ,
  executeChangeBuyNowPrice ,
}
/* MarkotApi__Id Not a pure module */
