/* TypeScript file generated from App__Config.res by genType. */
/* eslint-disable import/first */


// @ts-ignore: Implicit any on import
import * as App__ConfigBS__Es6Import from './App__Config.bs';
const App__ConfigBS: any = App__ConfigBS__Es6Import;

import type {Json_t as Js_Json_t} from '../../../../rescript-shims/Js.shim';

import type {t as Promise_t} from '../../../../rescript-shims/Promise.shim';

export const configError: string = App__ConfigBS.configError;

export const getConfigFromNetwork: (_1:{ readonly onError: (() => Js_Json_t) }) => Promise_t<Js_Json_t> = function (Arg1: any) {
  const result = App__ConfigBS.getConfigFromNetwork(Arg1.onError);
  return result
};
