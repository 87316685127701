// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactRelay from "react-relay";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as AppMarkotUserAddressPickerFragment_markotUser_graphql from "../../../../relay-codegen/generated/AppMarkotUserAddressPickerFragment_markotUser_graphql.bs.js";
import * as AppMarkotUserAddressPickerFragmentPaginationQuery_graphql from "../../../../relay-codegen/generated/AppMarkotUserAddressPickerFragmentPaginationQuery_graphql.bs.js";

function internal_makeRefetchableFnOpts(fetchPolicy, onComplete, param) {
  var tmp = {};
  var tmp$1 = RescriptRelay.mapFetchPolicy(fetchPolicy);
  if (tmp$1 !== undefined) {
    tmp.fetchPolicy = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = RescriptRelay_Internal.internal_nullableToOptionalExnHandler(onComplete);
  if (tmp$2 !== undefined) {
    tmp.onComplete = Caml_option.valFromOption(tmp$2);
  }
  return tmp;
}

function usePagination(fr) {
  var p = ReactRelay.usePaginationFragment(AppMarkotUserAddressPickerFragment_markotUser_graphql.node, fr);
  var data = RescriptRelay_Internal.internal_useConvertedValue(AppMarkotUserAddressPickerFragment_markotUser_graphql.Internal.convertFragment, p.data);
  return {
          data: data,
          loadNext: React.useMemo((function () {
                  return function (param, param$1, param$2) {
                    return p.loadNext(param, {
                                onComplete: RescriptRelay_Internal.internal_nullableToOptionalExnHandler(param$1)
                              });
                  };
                }), [p.loadNext]),
          loadPrevious: React.useMemo((function () {
                  return function (param, param$1, param$2) {
                    return p.loadPrevious(param, {
                                onComplete: RescriptRelay_Internal.internal_nullableToOptionalExnHandler(param$1)
                              });
                  };
                }), [p.loadPrevious]),
          hasNext: p.hasNext,
          hasPrevious: p.hasPrevious,
          isLoadingNext: p.isLoadingNext,
          isLoadingPrevious: p.isLoadingPrevious,
          refetch: React.useMemo((function () {
                  return function (param, param$1, param$2, param$3) {
                    return p.refetch(RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(AppMarkotUserAddressPickerFragmentPaginationQuery_graphql.Internal.convertVariables(param)), internal_makeRefetchableFnOpts(param$1, param$2, undefined));
                  };
                }), [p.refetch])
        };
}

var Operation = {
  connectionKey: "AppMarkotUserAddressPickerFragment_markotUser_addresses"
};

var getConnectionNodes = AppMarkotUserAddressPickerFragment_markotUser_graphql.Utils.getConnectionNodes;

var Types;

export {
  Operation ,
  getConnectionNodes ,
  Types ,
  usePagination ,
}
/* react Not a pure module */
