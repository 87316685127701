// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../group/components/Ui__Group.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../styling/Ui__Styling.bs.js";
import * as Ui__MarkotText from "../text/components/Ui__MarkotText.bs.js";
import * as Ui__VerificationMark from "../verificationMark/Ui__VerificationMark.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          alignSelf: "flex-start"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.secondary,
                    borderRadius: theme.roundness._2
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__RefurbishedTag(Props) {
  var textOpt = Props.text;
  var textStyle = Props.textStyle;
  var sizeOpt = Props.size;
  var style = Props.style;
  var textSize = Props.textSize;
  var checkmarkColor = Props.checkmarkColor;
  var checkmarkBackgroundColor = Props.checkmarkBackgroundColor;
  var testIDOpt = Props.testID;
  var text = textOpt !== undefined ? textOpt : "Refurbished";
  var size = sizeOpt !== undefined ? sizeOpt : "small";
  var testID = testIDOpt !== undefined ? testIDOpt : "refurbished-tag";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = size === "small" ? ({
        verificationMarkSize: 16,
        paddingHorizontal: theme.spacing._2,
        paddingVertical: theme.spacing._1,
        spacing: theme.spacing._2
      }) : ({
        verificationMarkSize: 20,
        paddingHorizontal: theme.spacing._4,
        paddingVertical: theme.spacing._2,
        spacing: theme.spacing._4
      });
  var tmp = {
    size: Belt_Option.getWithDefault(textSize, "caption1"),
    children: text
  };
  if (textStyle !== undefined) {
    tmp.style = Caml_option.valFromOption(textStyle);
  }
  return React.createElement(Ui__Group.make, {
              spacing: match$1.spacing,
              children: null,
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles.container)),
                {
                  paddingHorizontal: match$1.paddingHorizontal,
                  paddingVertical: match$1.paddingVertical
                },
                style
              ],
              orientation: "horizontal",
              testID: testID
            }, React.createElement(Ui__VerificationMark.make, {
                  size: match$1.verificationMarkSize,
                  backgroundColor: Belt_Option.getWithDefault(checkmarkBackgroundColor, theme.colors.secondaryVariant),
                  checkmarkColor: Belt_Option.getWithDefault(checkmarkColor, theme.colors.primary)
                }), React.createElement(Ui__MarkotText.make, tmp));
}

var make = Ui__RefurbishedTag;

export {
  make ,
}
/* match Not a pure module */
