/* TypeScript file generated from Ui__WebDrawersContext.resi by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as Ui__WebDrawersContextBS__Es6Import from './Ui__WebDrawersContext.bs';
const Ui__WebDrawersContextBS: any = Ui__WebDrawersContextBS__Es6Import;

// tslint:disable-next-line:interface-over-type-literal
export type drawer = "account" | "bag";

// tslint:disable-next-line:interface-over-type-literal
export type contextData = { readonly visibleDrawer?: drawer; readonly updateVisibleDrawer: (_1:(null | undefined | drawer)) => void };

export const useWebDrawers: () => contextData = function () {
  const result = Ui__WebDrawersContextBS.useWebDrawers();
  return {visibleDrawer:result.visibleDrawer, updateVisibleDrawer:function (Arg1: any) {
      const result1 = result.updateVisibleDrawer((Arg1 == null ? undefined : Arg1));
      return result1
    }}
};

// tslint:disable-next-line:interface-over-type-literal
export type Props = { readonly contextData: contextData; readonly children: React.ReactNode };

export const WebDrawersProvider_make: React.ComponentType<{ readonly contextData: contextData; readonly children: React.ReactNode }> = Ui__WebDrawersContextBS.WebDrawersProvider.make;

export const WebDrawersProvider: { make: React.ComponentType<{ readonly contextData: contextData; readonly children: React.ReactNode }> } = Ui__WebDrawersContextBS.WebDrawersProvider
