import { ReactNode } from "react";
import { ViewProps, StyleProp, View } from "react-native";
// import Animated, { SlideInRight, SlideOutRight } from "react-native-reanimated";

interface SlideInRightViewProps {
  children: ReactNode;
  style?: StyleProp<ViewProps>;
}

// FIXME Temporary disabled layout animations since they break
// the app.
// If it doesn't get fixed some time soon, implement custom animation
// which would be something similar to the drawer, sliding from out of the
// screen to the wanted position.
export const SlideInRightView = ({
  children,
  style,
}: SlideInRightViewProps) => {
  return (
    <View
      // entering={SlideInRight}
      // exiting={SlideOutRight}
      style={style}
    >
      {children}
    </View>
  );
};
