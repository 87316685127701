// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactRelay from "react-relay";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as AppBagListPaginationFragment_graphql from "../../../../../relay-codegen/generated/AppBagListPaginationFragment_graphql.bs.js";
import * as AppBagListPaginationFragment_bag_graphql from "../../../../../relay-codegen/generated/AppBagListPaginationFragment_bag_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppBagListPaginationFragment_bag_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppBagListPaginationFragment_bag_graphql.Internal.convertFragment, data);
}

var makeRefetchVariables = AppBagListPaginationFragment_graphql.Types.makeRefetchVariables;

var getConnectionNodes = AppBagListPaginationFragment_bag_graphql.Utils.getConnectionNodes;

var Types;

export {
  getConnectionNodes ,
  Types ,
  use ,
  makeRefetchVariables ,
}
/* react-relay Not a pure module */
