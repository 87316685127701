// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MiscApi__FreshdeskTs from "./MiscApi__Freshdesk.ts";

function submitSupportTicket(prim0, prim1, prim2, prim3, prim4) {
  return MiscApi__FreshdeskTs.createTicket(prim0, prim1, prim2, prim3, prim4);
}

export {
  submitSupportTicket ,
}
/* ./MiscApi__Freshdesk.ts Not a pure module */
