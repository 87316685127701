// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils__Analytics from "../Utils__Analytics.bs.js";

function makeDeviceContextEvent(properties) {
  var deviceContextSetEvent = {
    TAG: /* DeviceContextSet */0,
    _0: properties
  };
  if (deviceContextSetEvent.TAG === /* DeviceContextSet */0) {
    return {
            name: Utils__Analytics.deviceContextEventNameForSegment,
            properties: Utils__Analytics.adaptDeviceContextForSegment(properties)
          };
  } else {
    return {
            name: Utils__Analytics.deviceContextEventNameForSegment,
            properties: (function (prim) {
                return {};
              })
          };
  }
}

export {
  makeDeviceContextEvent ,
}
/* Utils__Analytics Not a pure module */
