// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AlgoliaApi__OffsetToCursorPaginationTs from "./AlgoliaApi__OffsetToCursorPagination.ts";

function cursorPaginatorFromOffsetPaginator(prim0, prim1, prim2) {
  return AlgoliaApi__OffsetToCursorPaginationTs.cursorPaginatorFromOffsetPaginator(prim0, prim1, prim2);
}

export {
  cursorPaginatorFromOffsetPaginator ,
}
/* ./AlgoliaApi__OffsetToCursorPagination.ts Not a pure module */
