// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../../../ui/src/group/components/Ui__Group.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as Ui__MarkotText from "../../../../../ui/src/text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../../../../ui/src/responsive/Ui__Responsive.bs.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as AppAddressSnippet_address_graphql from "../../../../../relay-codegen/generated/AppAddressSnippet_address_graphql.bs.js";

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = ReactRelay.useFragment(AppAddressSnippet_address_graphql.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return RescriptRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return AppAddressSnippet_address_graphql.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dynamic */1,
        _0: (function (props) {
            var tmp = {
              borderRadius: props.theme.roundness._2,
              padding: props.padding
            };
            if (props.backgroundColor !== undefined) {
              tmp.backgroundColor = Caml_option.valFromOption(props.backgroundColor);
            }
            return tmp;
          })
      },
      section: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      streetName: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "500"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function App__Address__Snippet(Props) {
  var address = Props.address;
  var emptyStateTextOpt = Props.emptyStateText;
  var backgroundColor = Props.backgroundColor;
  var style = Props.style;
  var emptyStateText = emptyStateTextOpt !== undefined ? emptyStateTextOpt : "Please select address";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var address$1 = useOpt(address);
  var spacing = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        undefined,
        theme.spacing._8,
        undefined,
        theme.spacing._10,
        undefined,
        theme.spacing._6,
        undefined
      ]);
  var styled = Curry._1(stylesheet, {
        theme: theme,
        padding: spacing,
        backgroundColor: backgroundColor
      });
  if (address$1 === undefined) {
    return React.createElement(Ui__MarkotText.make, {
                children: emptyStateText
              });
  }
  var state2 = address$1.state2;
  return React.createElement(Ui__Group.make, {
              spacing: theme.spacing._4,
              children: null,
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles.container)),
                style
              ]
            }, React.createElement(Ui__MarkotText.make, {
                  size: "b1",
                  color: theme.colors.onPrimaryShade4,
                  children: address$1.recipientName
                }), React.createElement(Ui__Group.make, {
                  spacing: 0,
                  children: null,
                  containerStyle: Curry._1(styled, styles.section)
                }, React.createElement(Ui__MarkotText.make, {
                      size: "b1",
                      children: address$1.streetLine1,
                      style: Curry._1(styled, styles.streetName)
                    }), React.createElement(Ui__MarkotText.make, {
                      size: "b1",
                      color: theme.colors.primaryShade1,
                      children: "" + address$1.city + ", " + (
                        state2 !== "" ? state2 + ", " : ""
                      ) + "" + address$1.country + ", " + address$1.postalCode + "",
                      numberOfLines: 2
                    })));
}

var make = App__Address__Snippet;

export {
  make ,
}
/* match Not a pure module */
