// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ShopifyApi__Id from "../../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as GraphqlCore__Types from "../../../graphql-core/src/GraphqlCore__Types.bs.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";
import * as GqlMiddleware__Core__Utils from "../core/GqlMiddleware__Core__Utils.bs.js";
import * as GqlMiddleware__Entity__Bag from "../entities/GqlMiddleware__Entity__Bag.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Entity__Brand from "../entities/GqlMiddleware__Entity__Brand.bs.js";
import * as GqlMiddleware__Entity__Bundle from "../entities/GqlMiddleware__Entity__Bundle.bs.js";
import * as GqlMiddleware__Entity__Wallet from "../entities/GqlMiddleware__Entity__Wallet.bs.js";
import * as GqlMiddleware__Entity__Address from "../entities/GqlMiddleware__Entity__Address.bs.js";
import * as GqlMiddleware__Entity__Auction from "../entities/GqlMiddleware__Entity__Auction.bs.js";
import * as GqlMiddleware__Entity__BidInfo from "../entities/GqlMiddleware__Entity__BidInfo.bs.js";
import * as GqlMiddleware__Entity__Listing from "../entities/GqlMiddleware__Entity__Listing.bs.js";
import * as GqlMiddleware__Entity__Product from "../entities/GqlMiddleware__Entity__Product.bs.js";
import * as FeatureFlags__AppSpecificConfig from "../../../feature-flags/src/local/FeatureFlags__AppSpecificConfig.bs.js";
import * as GqlMiddleware__Entity__BlogPost from "../entities/GqlMiddleware__Entity__BlogPost.bs.js";
import * as GqlMiddleware__Entity__Category from "../entities/GqlMiddleware__Entity__Category.bs.js";
import * as GqlMiddleware__Entity__Checkout from "../entities/GqlMiddleware__Entity__Checkout.bs.js";
import * as GqlMiddleware__Entity__NewOrder from "../entities/GqlMiddleware__Entity__NewOrder.bs.js";
import * as GqlMiddleware__Entity__Wishlist from "../entities/GqlMiddleware__Entity__Wishlist.bs.js";
import * as GqlMiddleware__Entity__Attribute from "../entities/GqlMiddleware__Entity__Attribute.bs.js";
import * as GqlMiddleware__Entity__MarkotUser from "../entities/GqlMiddleware__Entity__MarkotUser.bs.js";
import * as GqlMiddleware__Core__ProxyResolvers from "../core/GqlMiddleware__Core__ProxyResolvers.bs.js";
import * as GqlMiddleware__Entity__DraftListing from "../entities/GqlMiddleware__Entity__DraftListing.bs.js";
import * as GqlMiddleware__Entity__PrelovedItem from "../entities/GqlMiddleware__Entity__PrelovedItem.bs.js";
import * as GqlMiddleware__Entity__WishlistItem from "../entities/GqlMiddleware__Entity__WishlistItem.bs.js";
import * as GqlMiddleware__Entity__PaymentMethod from "../entities/GqlMiddleware__Entity__PaymentMethod.bs.js";
import * as GqlMiddleware__Entity__PrelovedOrder from "../entities/GqlMiddleware__Entity__PrelovedOrder.bs.js";
import * as GqlMiddleware__Entity__SupportTicket from "../entities/GqlMiddleware__Entity__SupportTicket.bs.js";
import * as GqlMiddleware__Entity__ProductVariant from "../entities/GqlMiddleware__Entity__ProductVariant.bs.js";
import * as GqlMiddleware__Entity__ShopifyCustomer from "../entities/GqlMiddleware__Entity__ShopifyCustomer.bs.js";
import * as GqlMiddleware__Entity__PrelovedItemInfo from "../entities/GqlMiddleware__Entity__PrelovedItemInfo.bs.js";
import * as GqlMiddleware__Entity__ProductCollection from "../entities/GqlMiddleware__Entity__ProductCollection.bs.js";
import * as GqlMiddleware__Entity__ProductSpecifications from "../entities/GqlMiddleware__Entity__ProductSpecifications.bs.js";
import * as GqlMiddleware__Entity__PrelovedItemSearchResult from "../entities/GqlMiddleware__Entity__PrelovedItemSearchResult.bs.js";
import * as GqlMiddleware__Entity__SettingsForPrelovedItems from "../entities/GqlMiddleware__Entity__SettingsForPrelovedItems.bs.js";
import * as GqlMiddleware__Entity__ProductVariantSearchResult from "../entities/GqlMiddleware__Entity__ProductVariantSearchResult.bs.js";

var node = (function unwrapNode(variant) { return variant._0 });

var bundle = (function unwrapBundle(variant) { return variant._0 });

var auctionEvent = (function unwrapAuctionEvent(variant) { return variant._0 });

var merchandise = (function unwrapMerchandise(variant) { return variant._0 });

var fullWishlistItem = (function unwrapFullWishlistItem(variant) { return variant._0 });

var listingProduct = (function unwrapListingProduct(variant) { return variant._0 });

var draftListingProduct = (function unwrapDraftListingProduct(variant) { return variant._0 });

function loadIfNotPreloaded(value, loader) {
  if (value !== undefined) {
    return Promise.resolve(Caml_option.some(Caml_option.valFromOption(value)));
  } else {
    return Curry._1(loader, undefined);
  }
}

function makeWishlistItemResolverAndUnwrapper(context, inWishlistFlagToApply, node, includeMarkdownDescription, includeRefurbishedInfo, includeOverstockedInfo) {
  var tmp;
  var exit = 0;
  switch (node.TAG | 0) {
    case /* Product */0 :
        tmp = context.loaders.productById.load(node.id).then(function (__x) {
              return Belt_Option.map(__x, (function (data) {
                            var newrecord = Caml_obj.obj_dup(data);
                            return {
                                    TAG: /* Product */0,
                                    _0: (newrecord.inWishlist = inWishlistFlagToApply, newrecord)
                                  };
                          }));
            });
        break;
    case /* ProductVariant */1 :
        tmp = context.loaders.productVariantById.load(node.id).then(function (__x) {
              return Belt_Option.map(__x, (function (data) {
                            return {
                                    TAG: /* ProductVariant */1,
                                    _0: {
                                      __typename: data.__typename,
                                      id: data.id,
                                      compareAtPrice: data.compareAtPrice,
                                      price: data.price,
                                      attributeValues: data.attributeValues,
                                      availableForSale: data.availableForSale,
                                      inStock: data.inStock,
                                      mainImage: data.mainImage,
                                      media: data.media,
                                      productId: data.productId,
                                      sku: data.sku,
                                      inWishlist: inWishlistFlagToApply,
                                      quantityAvailable: data.quantityAvailable
                                    }
                                  };
                          }));
            });
        break;
    case /* PrelovedItemInfo */2 :
        tmp = GqlMiddleware__Entity__PrelovedItemInfo.fetchPrelovedItemInfo(context.markotApiConfig, node.id, Belt_Option.getWithDefault(GqlMiddleware__Core__Utils.isTestUser(context.cognitoGroups), false)).then(function (__x) {
              return Belt_Option.map(__x, (function (data) {
                            return {
                                    TAG: /* PrelovedItemInfo */2,
                                    _0: {
                                      __typename: data.__typename,
                                      id: data.id,
                                      prelovedItemId: data.prelovedItemId,
                                      productSlug: data.productSlug,
                                      pricing: data.pricing,
                                      status: data.status,
                                      inWishlist: inWishlistFlagToApply,
                                      usedForTesting: data.usedForTesting
                                    }
                                  };
                          }));
            });
        break;
    case /* MarkotBundle */3 :
    case /* InfluencerBundle */4 :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    tmp = GqlMiddleware__Entity__Bundle.fetchBundle(context.shopifyApiConfig, node.id).then(function (__x) {
          return Belt_Option.map(__x, (function (bundle) {
                        if (bundle.TAG === /* InfluencerBundle */0) {
                          var data = bundle._0;
                          return {
                                  TAG: /* InfluencerBundle */3,
                                  _0: {
                                    __typename: data.__typename,
                                    id: data.id,
                                    name: data.name,
                                    slug: data.slug,
                                    image: data.image,
                                    influencer: data.influencer,
                                    inWishlist: inWishlistFlagToApply,
                                    seo: data.seo
                                  }
                                };
                        }
                        var data$1 = bundle._0;
                        return {
                                TAG: /* MarkotBundle */4,
                                _0: {
                                  __typename: data$1.__typename,
                                  id: data$1.id,
                                  name: data$1.name,
                                  slug: data$1.slug,
                                  image: data$1.image,
                                  inWishlist: inWishlistFlagToApply,
                                  seo: data$1.seo
                                }
                              };
                      }));
        });
  }
  return tmp.then(function (__x) {
              return Belt_Option.map(__x, fullWishlistItem);
            });
}

var resolvers = {
  Query: {
    productRandomCollection: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Product.fetchProductsRandomCollection(context.shopifyApiConfig, args.queryString, args.first, undefined);
      }),
    productCollectionForPlaceholder: (function (_obj, args, context, _info) {
        RescriptUtils__Logger.info(GqlMiddleware__Core__Infra.logger, "[resolver] Query.productCollectionForPlaceholder");
        var entitySlug = Belt_Option.flatMap(args.entitySlug, (function (prim) {
                if (prim == null) {
                  return ;
                } else {
                  return Caml_option.some(prim);
                }
              }));
        if (entitySlug !== undefined) {
          return GqlMiddleware__Entity__ProductCollection.fetchProductCollectionByHandle(context.shopifyApiConfig, "" + args.placeholderCode + "-" + entitySlug + "", false).then(function (data) {
                      if (data !== undefined) {
                        return Promise.resolve(data);
                      } else {
                        return GqlMiddleware__Entity__ProductCollection.fetchProductCollectionByHandle(context.shopifyApiConfig, args.placeholderCode, true);
                      }
                    });
        } else {
          return GqlMiddleware__Entity__ProductCollection.fetchProductCollectionByHandle(context.shopifyApiConfig, args.placeholderCode, true);
        }
      }),
    brands: (function (_obj, args, context, _info) {
        RescriptUtils__Logger.info(GqlMiddleware__Core__Infra.logger, "[resolver] Query.brands");
        var categoryId = args.categoryId;
        if (categoryId == null) {
          return GqlMiddleware__Entity__Brand.fetchBrands(context.shopifyApiConfig, args.first, args.after, undefined).then(function (result) {
                      return result;
                    });
        } else {
          return GqlMiddleware__Entity__Brand.fetchCategoryBrands(context.shopifyApiConfig, categoryId, args.first, undefined);
        }
      }),
    viewer: (function (_obj, _args, context, _info) {
        RescriptUtils__Logger.info(GqlMiddleware__Core__Infra.logger, "[resolver] Query.viewer");
        return GqlMiddleware__Entity__MarkotUser.fetchBasicData(context.markotApiConfig);
      }),
    categories: (function (_obj, _args, context, _info) {
        RescriptUtils__Logger.info(GqlMiddleware__Core__Infra.logger, "[resolver] Query.categories");
        return GqlMiddleware__Entity__Category.fetchCategories(context.shopifyApiConfig, undefined, undefined);
      }),
    node: (function (obj, args, context, info) {
        var id = args.id;
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] Query.node id=", id);
        var error = GqlMiddleware__Core__NodeId.unpack(id);
        if (error.TAG === /* Ok */0) {
          var match = error._0;
          var internalId = match.internalId;
          var typename = match.typename;
          RescriptUtils__Logger.info3(GqlMiddleware__Core__Infra.logger, "Resolving node for typename, internalId", typename, internalId);
          if (typename === "ProductVariant") {
            return context.loaders.productVariantById.load(id).then(function (__x) {
                        return Belt_Option.map(__x, (function (variant) {
                                      return node({
                                                  TAG: /* ProductVariant */4,
                                                  _0: variant
                                                });
                                    }));
                      });
          } else if (typename === "Brand") {
            return context.loaders.brandById.load(id).then(function (__x) {
                        return Belt_Option.map(__x, (function (brand) {
                                      return node({
                                                  TAG: /* Brand */0,
                                                  _0: brand
                                                });
                                    }));
                      });
          } else if (typename === "ProductSnippet") {
            return Js_exn.raiseError("ProductSnippet shouldn't be resolved via `node` query ever!");
          } else if (typename === "Listing") {
            return GqlMiddleware__Entity__Listing.fetchListing(context.markotApiConfig, id).then(function (__x) {
                        return Belt_Option.map(__x, (function (listing) {
                                      return node({
                                                  TAG: /* Listing */10,
                                                  _0: listing
                                                });
                                    }));
                      });
          } else if (typename === "Address") {
            return GqlMiddleware__Entity__Address.fetchAddress(context.markotApiConfig, id).then(function (__x) {
                        return Belt_Option.map(__x, (function (address) {
                                      return node({
                                                  TAG: /* Address */11,
                                                  _0: address
                                                });
                                    }));
                      });
          } else if (typename === "PrelovedItemInfo") {
            return GqlMiddleware__Entity__PrelovedItemInfo.fetchPrelovedItemInfo(context.markotApiConfig, id, Belt_Option.getWithDefault(GqlMiddleware__Core__Utils.isTestUser(context.cognitoGroups), false)).then(function (__x) {
                        return Belt_Option.map(__x, (function (prelovedItemInfo) {
                                      return node({
                                                  TAG: /* PrelovedItemInfo */5,
                                                  _0: prelovedItemInfo
                                                });
                                    }));
                      });
          } else if (typename === "BagItem") {
            return GqlMiddleware__Entity__Bag.fetchBagItemById(context.shopifyApiConfig, id).then(function (__x) {
                        return Belt_Option.map(__x, (function (bagItem) {
                                      return node({
                                                  TAG: /* BagItem */14,
                                                  _0: bagItem
                                                });
                                    }));
                      });
          } else if (typename === "Bag") {
            return GqlMiddleware__Entity__Bag.fetchBagById(context.shopifyApiConfig, id).then(function (__x) {
                        return Belt_Option.map(__x, (function (bag) {
                                      return node({
                                                  TAG: /* Bag */13,
                                                  _0: bag
                                                });
                                    }));
                      });
          } else if (typename === "MarkotUser") {
            return GqlMiddleware__Entity__MarkotUser.fetchBasicData(context.markotApiConfig).then(function (__x) {
                        return Belt_Option.map(__x, (function (markotUser) {
                                      return node({
                                                  TAG: /* MarkotUser */17,
                                                  _0: markotUser
                                                });
                                    }));
                      });
          } else if (typename === "DraftListing") {
            return GqlMiddleware__Entity__DraftListing.fetchDraftListing(context.markotApiConfig, id).then(function (__x) {
                        return Belt_Option.map(__x, (function (draftListing) {
                                      return node({
                                                  TAG: /* DraftListing */9,
                                                  _0: draftListing
                                                });
                                    }));
                      });
          } else if (typename === "MarkotBundle" || typename === "InfluencerBundle") {
            return GqlMiddleware__Entity__Bundle.fetchBundle(context.shopifyApiConfig, id).then(function (__x) {
                        return Belt_Option.map(__x, (function (bundle) {
                                      var tmp;
                                      tmp = bundle.TAG === /* InfluencerBundle */0 ? ({
                                            TAG: /* InfluencerBundle */7,
                                            _0: bundle._0
                                          }) : ({
                                            TAG: /* MarkotBundle */8,
                                            _0: bundle._0
                                          });
                                      return node(tmp);
                                    }));
                      });
          } else if (typename === "Category") {
            return GqlMiddleware__Entity__Category.fetchCategories(context.shopifyApiConfig, internalId, undefined).then(function (result) {
                        return Belt_Option.map(Belt_Array.get(result.edges, 0), (function (param) {
                                      return node({
                                                  TAG: /* Category */1,
                                                  _0: param.node
                                                });
                                    }));
                      });
          } else if (typename === "PrelovedOrder") {
            return GqlMiddleware__Entity__PrelovedOrder.fetchPrelovedOrder(context.markotApiConfig, id).then(function (__x) {
                        return Belt_Option.map(__x, (function (prelovedOrder) {
                                      return node({
                                                  TAG: /* PrelovedOrder */15,
                                                  _0: prelovedOrder
                                                });
                                    }));
                      });
          } else if (typename === "Subcategory") {
            return GqlMiddleware__Entity__Category.fetchSubcategory(context.shopifyApiConfig, internalId, undefined).then(function (subcategory) {
                        return Belt_Option.map(subcategory, (function (subcategory) {
                                      return node({
                                                  TAG: /* Subcategory */2,
                                                  _0: subcategory
                                                });
                                    }));
                      });
          } else if (typename === "Auction") {
            return GqlMiddleware__Entity__Auction.fetchAuction(context.markotApiConfig, id).then(function (__x) {
                        return Belt_Option.map(__x, (function (auction) {
                                      return node({
                                                  TAG: /* Auction */6,
                                                  _0: auction
                                                });
                                    }));
                      });
          } else if (typename === "BlogPost") {
            return GqlMiddleware__Entity__BlogPost.fetchBlogPost(context.markotApiConfig, id).then(function (__x) {
                        return Belt_Option.map(__x, (function (blogPost) {
                                      return node({
                                                  TAG: /* BlogPost */12,
                                                  _0: blogPost
                                                });
                                    }));
                      });
          } else if (typename === "NewOrder") {
            return GqlMiddleware__Entity__NewOrder.fetchNewOrder(context.shopifyApiConfig, id).then(function (__x) {
                        return Belt_Option.map(__x, (function (newOrder) {
                                      return node({
                                                  TAG: /* NewOrder */16,
                                                  _0: newOrder
                                                });
                                    }));
                      });
          } else if (typename === "Product") {
            return context.loaders.productById.load(id).then(function (__x) {
                        return Belt_Option.map(__x, (function (product) {
                                      return node({
                                                  TAG: /* Product */3,
                                                  _0: product
                                                });
                                    }));
                      });
          } else {
            RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "Resolving node via markotApiResolverProxy", {
                  id: id,
                  typename: typename
                });
            return GqlMiddleware__Core__ProxyResolvers.markotApiResolverProxy(obj, args, context, info);
          }
        }
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "Resolving node via markotApiResolverProxy, as typename not recognized", {
              id: id,
              error: error._0
            });
        return GqlMiddleware__Core__ProxyResolvers.markotApiResolverProxy(obj, args, context, info);
      }),
    bundles: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Bundle.fetchBundles(context.shopifyApiConfig, args.first, args.after, undefined).then(function (__x) {
                    return GraphqlCore__Types.Connection.map(__x, bundle);
                  });
      }),
    bag: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Bag.fetchBagById(context.shopifyApiConfig, args.id).then(function (__x) {
                    return Belt_Option.map(__x, (function (bag) {
                                  return {
                                          __typename: bag.__typename,
                                          id: bag.id,
                                          items: GraphqlCore__Types.Connection.map(bag.items, (function (node) {
                                                  return {
                                                          __typename: node.__typename,
                                                          id: node.id,
                                                          quantity: node.quantity,
                                                          merchandise: merchandise(node.merchandise),
                                                          compareAtPricePerQuantity: node.compareAtPricePerQuantity,
                                                          pricePerQuantity: node.pricePerQuantity
                                                        };
                                                })),
                                          totalQuantity: bag.totalQuantity,
                                          totalPrice: bag.totalPrice,
                                          checkoutUrl: bag.checkoutUrl
                                        };
                                }));
                  });
      }),
    bag2: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Bag.fetchBagById(context.shopifyApiConfig, args.id).then(function (__x) {
                    return Belt_Option.map(__x, (function (bag) {
                                  return {
                                          __typename: bag.__typename,
                                          id: bag.id,
                                          items: GraphqlCore__Types.Connection.map(bag.items, (function (node) {
                                                  return {
                                                          __typename: node.__typename,
                                                          id: node.id,
                                                          quantity: node.quantity,
                                                          merchandise: merchandise(node.merchandise),
                                                          compareAtPricePerQuantity: node.compareAtPricePerQuantity,
                                                          pricePerQuantity: node.pricePerQuantity
                                                        };
                                                })),
                                          totalQuantity: bag.totalQuantity,
                                          totalPrice: bag.totalPrice,
                                          checkoutUrl: bag.checkoutUrl
                                        };
                                }));
                  });
      }),
    blogPosts: (function (_obj, _args, _context, _info) {
        RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "[resolver] Query.blogPosts - Use Query.blogPosts2 instead");
        return Js_exn.raiseError("Use Query.blogPosts2 instead");
      }),
    blogPosts2: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__BlogPost.fetchBlogPosts(context.markotApiConfig, args.first, args.after, args.tag);
      }),
    products: (function (_obj, _args, _context, _info) {
        return Js_exn.raiseError("`Query.products` shouldn't be used anymore! Use `Query.search` instead!");
      }),
    search: (function (_obj, args, context, _info) {
        var limitToArr = Belt_Option.map(Caml_option.nullable_to_opt(args.settings), (function (settings) {
                return settings.limitTo;
              }));
        if (limitToArr !== undefined && limitToArr.length !== 0 && !Belt_Array.some(limitToArr, (function (limit) {
                  return limit === "ProductVariant";
                }))) {
          if (Belt_Array.some(limitToArr, (function (limit) {
                    return limit === "PrelovedItem";
                  }))) {
            RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "[resolver] Query.search use Query.searchPreloved for PrelovedItem search");
            return Js_exn.raiseError("Use Query.searchPreloved for PrelovedItem search");
          } else {
            RescriptUtils__Logger.warn2(GqlMiddleware__Core__Infra.logger, "[resolver] Query.search Unknown limitTo value", limitToArr);
            return Js_exn.raiseError("Unhandled limitTo value passed in Query.search");
          }
        } else {
          return GqlMiddleware__Entity__ProductVariantSearchResult.searchProductVariants(context.algoliaApiConfig, args.searchTerm, args.first, args.after, Belt_Option.map(Caml_option.nullable_to_opt(args.filters), (function (filters) {
                            return {
                                    brandId: Caml_option.nullable_to_opt(filters.brandId),
                                    categoryIds: Belt_Option.map(Caml_option.nullable_to_opt(filters.categoryIds), (function (__x) {
                                            return Belt_Array.map(__x, (function (categoryId) {
                                                          var match = GqlMiddleware__Core__NodeId.unpackExn(categoryId);
                                                          return GqlMiddleware__Core__NodeId.make(match.version, match.typename, ShopifyApi__Id.toString(ShopifyApi__Id.make("Collection", match.internalId)));
                                                        }));
                                          })),
                                    price: Caml_option.nullable_to_opt(filters.price),
                                    sellingNewItems: Caml_option.nullable_to_opt(filters.sellingNewItems),
                                    sellingPrelovedItems: Caml_option.nullable_to_opt(filters.sellingPrelovedItems),
                                    facetFilters: Belt_Option.map(Caml_option.nullable_to_opt(filters.facetFilters), (function (facetFilters) {
                                            return {
                                                    brandName: Caml_option.nullable_to_opt(facetFilters.brandName),
                                                    categoryName: Caml_option.nullable_to_opt(facetFilters.categoryName),
                                                    isRefurbishedProduct: Caml_option.nullable_to_opt(facetFilters.isRefurbishedProduct),
                                                    isOverstockedProduct: Caml_option.nullable_to_opt(facetFilters.isOverstockedProduct),
                                                    isPrelovedProduct: Caml_option.nullable_to_opt(facetFilters.isPrelovedProduct),
                                                    isOpenBoxProduct: Caml_option.nullable_to_opt(facetFilters.isOpenBoxProduct)
                                                  };
                                          }))
                                  };
                          })), {
                      defaultVariantsOnly: Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.nullable_to_opt(args.settings), (function (settings) {
                                  return settings.variantSearchSettings;
                                })), (function (variantSearchSettings) {
                              return variantSearchSettings.defaultVariantsOnly;
                            })),
                      includeTestProducts: GqlMiddleware__Core__Utils.isTestUser(context.cognitoGroups)
                    }, Caml_option.nullable_to_opt(args.sortKey), Caml_option.nullable_to_opt(args.sortDirection), args.analyticsTags, true, true);
        }
      }),
    searchPreloved: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__PrelovedItemSearchResult.searchPrelovedItems(context.algoliaApiConfig, args.searchTerm, args.first, args.after, args.sortKey, args.sortDirection, Belt_Option.map(Caml_option.nullable_to_opt(args.filters), (function (filters) {
                          return {
                                  productId: Caml_option.nullable_to_opt(filters.productId),
                                  status: Caml_option.nullable_to_opt(filters.status),
                                  buyNowPrice: Caml_option.nullable_to_opt(filters.buyNowPrice),
                                  highestBid: Caml_option.nullable_to_opt(filters.highestBid),
                                  condition: Caml_option.nullable_to_opt(filters.condition)
                                };
                        })), {
                    includeTestItems: GqlMiddleware__Core__Utils.isTestUser(context.cognitoGroups)
                  }, GqlMiddleware__Core__Utils.isFeatureFlagOn(context, "newPrelovedCards"));
      }),
    sellerRatingFees: GqlMiddleware__Core__ProxyResolvers.markotApiResolverProxy
  },
  Mutation: {
    acceptBidInfo: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__BidInfo.executeAcceptBid(context.markotApiConfig, args.input);
      }),
    addToBag: (function (_obj, args, context, _info) {
        var bagId = args.input.bagId;
        return (
                  (bagId == null) ? GqlMiddleware__Entity__Bag.executeCreateBag(context.shopifyApiConfig, args.input.merchandiseId, args.input.quantity).then(function (__x) {
                          return Belt_Option.map(__x, (function (result) {
                                        if (Belt_Option.isSome(context.markotApiConfig.accessToken)) {
                                          GqlMiddleware__Entity__Bag.setViewerBag(context.markotApiConfig, {
                                                externalId: result.bag.id
                                              });
                                        }
                                        return result;
                                      }));
                        }) : GqlMiddleware__Entity__Bag.executeAddBagItem(context.shopifyApiConfig, bagId, args.input.merchandiseId, args.input.quantity)
                ).then(function (__x) {
                    return Belt_Option.map(__x, (function (param) {
                                  var bag = param.bag;
                                  return {
                                          __typename: "AddToBagPayload",
                                          bag: {
                                            __typename: bag.__typename,
                                            id: bag.id,
                                            items: GraphqlCore__Types.Connection.map(bag.items, (function (node) {
                                                    return {
                                                            __typename: node.__typename,
                                                            id: node.id,
                                                            quantity: node.quantity,
                                                            merchandise: merchandise(node.merchandise),
                                                            compareAtPricePerQuantity: node.compareAtPricePerQuantity,
                                                            pricePerQuantity: node.pricePerQuantity
                                                          };
                                                  })),
                                            totalQuantity: bag.totalQuantity,
                                            totalPrice: bag.totalPrice,
                                            checkoutUrl: bag.checkoutUrl
                                          }
                                        };
                                }));
                  });
      }),
    setViewerBagWithNodeId: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Bag.setViewerBagWithNodeId(context.markotApiConfig, args.bagId);
      }),
    addExpoToken: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__MarkotUser.addExpoToken(context.markotApiConfig, args.input);
      }),
    clearViewerBag: GqlMiddleware__Core__ProxyResolvers.markotApiResolverProxy,
    deleteUser: GqlMiddleware__Core__ProxyResolvers.markotApiResolverProxy,
    buyPrelovedItemInstantly: GqlMiddleware__Core__ProxyResolvers.markotApiResolverProxy,
    buyerTempConfirmArrival: GqlMiddleware__Core__ProxyResolvers.markotApiResolverProxy,
    schedulePrelovedItemPickup: GqlMiddleware__Core__ProxyResolvers.markotApiResolverProxy,
    requestPayout: GqlMiddleware__Core__ProxyResolvers.markotApiResolverProxy,
    removeExpoToken: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__MarkotUser.removeExpoToken(context.markotApiConfig, args.input);
      }),
    addToWishlist: (function (_obj, args, context, _info) {
        var partial_arg = true;
        var arg = GqlMiddleware__Core__Utils.isFeatureFlagOn(context, "useMarkdownDescription");
        var resolveAndUnwrapWishlistItem = function (param) {
          var param$1 = true;
          var param$2 = true;
          return makeWishlistItemResolverAndUnwrapper(context, partial_arg, param, arg, param$1, param$2);
        };
        return GqlMiddleware__Entity__Wishlist.addToWishlist(context.markotApiConfig, args.id).then(function (param) {
                    return {
                            wishlistItemId: args.id,
                            wishlistItem: resolveAndUnwrapWishlistItem(param.wishlistItem)
                          };
                  });
      }),
    cancelBidInfo: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__BidInfo.executeCancelBid(context.markotApiConfig, args.input);
      }),
    changeBuyNowPrice: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Auction.executeChangeBuyNowPrice(context.markotApiConfig, args.input.newPrice, args.input.prelovedItemInfoId);
      }),
    createDraftListing: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__DraftListing.executeCreateDraftListing(context.markotApiConfig, args.input);
      }),
    createDraftListing2: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__DraftListing.executeCreateDraftListing2(context.markotApiConfig, args.input);
      }),
    createUserAddress: (function (_obj, _args, _context, _info) {
        return Js_exn.raiseError("createUserAddress is not supported. Use `createUserAddress2` instead.");
      }),
    createUserAddress2: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Address.createUserAddress(context.markotApiConfig, args.input);
      }),
    declineBidInfo: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__BidInfo.executeDeclineBid(context.markotApiConfig, args.input);
      }),
    deleteDraftListing: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__DraftListing.executeDeleteDraftListing(context.markotApiConfig, args.id);
      }),
    deleteDraftListingMedia: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__DraftListing.executeDeleteDraftListingMedia(context.markotApiConfig, args.mediaId);
      }),
    deleteListing: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Listing.executeDeleteListing(context.markotApiConfig, args.id);
      }),
    deleteListingMedia: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Listing.executeDeleteListingMedia(context.markotApiConfig, args.mediaId);
      }),
    deleteUserAddress: (function (_obj, _args, _context, _info) {
        return Js_exn.raiseError("deleteUserAddress is not supported. Use `deleteUserAddress2` instead.");
      }),
    deleteUserAddress2: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Address.deleteUserAddress(context.markotApiConfig, args.id);
      }),
    initiateStripePaymentMethodSetup: GqlMiddleware__Core__ProxyResolvers.markotApiResolverProxy,
    makeCheckout: GqlMiddleware__Core__ProxyResolvers.markotApiResolverProxy,
    makeCheckoutWithoutCustomerAccessToken: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__ShopifyCustomer.createAccessToken(context.markotApiConfig).then(function (customerAccessToken) {
                    return GqlMiddleware__Entity__Checkout.executeMakeCheckout(context.markotApiConfig, args.input.bagId, customerAccessToken);
                  });
      }),
    postBid: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__BidInfo.executePostBid(context.markotApiConfig, args.input);
      }),
    removeFromBag: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Bag.executeRemoveBagItem(context.shopifyApiConfig, args.input.bagId, args.input.bagItemId).then(function (__x) {
                    return Belt_Option.map(__x, (function (param) {
                                  var bag = param.bag;
                                  return {
                                          __typename: "RemoveFromBagPayload",
                                          bag: {
                                            __typename: "Bag",
                                            id: bag.id,
                                            items: GraphqlCore__Types.Connection.map(bag.items, (function (node) {
                                                    return {
                                                            __typename: node.__typename,
                                                            id: node.id,
                                                            quantity: node.quantity,
                                                            merchandise: merchandise(node.merchandise),
                                                            compareAtPricePerQuantity: node.compareAtPricePerQuantity,
                                                            pricePerQuantity: node.pricePerQuantity
                                                          };
                                                  })),
                                            totalQuantity: bag.totalQuantity,
                                            totalPrice: bag.totalPrice,
                                            checkoutUrl: bag.checkoutUrl
                                          }
                                        };
                                }));
                  });
      }),
    removeFromWishlist: (function (_obj, args, context, _info) {
        var partial_arg = false;
        var arg = GqlMiddleware__Core__Utils.isFeatureFlagOn(context, "useMarkdownDescription");
        var resolveAndUnwrapWishlistItem = function (param) {
          var param$1 = true;
          var param$2 = true;
          return makeWishlistItemResolverAndUnwrapper(context, partial_arg, param, arg, param$1, param$2);
        };
        return GqlMiddleware__Entity__Wishlist.removeFromWishlist(context.markotApiConfig, args.id).then(function (param) {
                    return {
                            wishlistItemId: args.id,
                            wishlistItem: resolveAndUnwrapWishlistItem(param.wishlistItem)
                          };
                  });
      }),
    submitDraftListingForApproval: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__DraftListing.executeSubmitDraftListingForApproval(context.markotApiConfig, args.draftListingId);
      }),
    submitListingForApproval: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Listing.executeSubmitListingForApproval(context.markotApiConfig, args.listingId);
      }),
    updateBagItemQuantity: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Bag.executeUpdateBagItemQuantity(context.shopifyApiConfig, args.input.bagId, args.input.bagItemId, args.input.quantity).then(function (__x) {
                    return Belt_Option.map(__x, (function (param) {
                                  var bag = param.bag;
                                  return {
                                          __typename: "UpdateBagItemQuantityPayload",
                                          bag: {
                                            __typename: bag.__typename,
                                            id: bag.id,
                                            items: GraphqlCore__Types.Connection.map(bag.items, (function (node) {
                                                    return {
                                                            __typename: node.__typename,
                                                            id: node.id,
                                                            quantity: node.quantity,
                                                            merchandise: merchandise(node.merchandise),
                                                            compareAtPricePerQuantity: node.compareAtPricePerQuantity,
                                                            pricePerQuantity: node.pricePerQuantity
                                                          };
                                                  })),
                                            totalQuantity: bag.totalQuantity,
                                            totalPrice: bag.totalPrice,
                                            checkoutUrl: bag.checkoutUrl
                                          }
                                        };
                                }));
                  });
      }),
    updateListing2: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Listing.executeUpdateListing(context.markotApiConfig, args.input);
      }),
    updateDraftListing2: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__DraftListing.executeUpdateDraftListing(context.markotApiConfig, args.input);
      }),
    updateMarkotUser: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__MarkotUser.updateMarkotUser(context.markotApiConfig, args.input);
      }),
    updateUserAddress: (function (_obj, _args, _context, _info) {
        return Js_exn.raiseError("updateUserAddress is not supported. Use `updateUserAddress2` instead.");
      }),
    updateUserAddress2: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Address.updateUserAddress(context.markotApiConfig, args.input);
      }),
    uploadDraftListingMedia: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__DraftListing.executeUploadDraftListingMedia(context.markotApiConfig, args.draftListingId, args.mediaFileInBase64, args.mediaTag);
      }),
    uploadListingMedia: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Listing.executeUploadListingMedia(context.markotApiConfig, args.listingId, args.mediaFileInBase64, args.mediaTag);
      }),
    deletePaymentMethod: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__PaymentMethod.executeDeletePaymentMethod(context.markotApiConfig, args.input);
      }),
    submitSupportTicket: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__SupportTicket.submitSupportTicket(context.freshdeskApiConfig, args.input.fullName, args.input.email, args.input.subject, args.input.description);
      }),
    updateNotificationSetting: (function (_obj, _args, context, _info) {
        var match = Curry._2(FeatureFlags__AppSpecificConfig.FeatureFlagsMap.get, context.featureFlags, "notificationScreen");
        if (match !== undefined && match.status === "on") {
          console.log("updateNotificationSetting mutation is on");
          return ;
        } else {
          RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "updateNotificationSetting is not supported");
          return Js_exn.raiseError("updateNotificationSetting is not supported");
        }
      })
  },
  Bid: {
    prelovedItemInfo: (function (obj, _args, context, _info) {
        return GqlMiddleware__Entity__PrelovedItemInfo.fetchPrelovedItemInfo(context.markotApiConfig, obj.prelovedItemInfoId, Belt_Option.getWithDefault(GqlMiddleware__Core__Utils.isTestUser(context.cognitoGroups), false));
      })
  },
  MarkotUser: {
    isTestUser: (function (_obj, _args, context, _info) {
        return Belt_Option.getWithDefault(GqlMiddleware__Core__Utils.isTestUser(context.cognitoGroups), false);
      }),
    payoutRequests: GqlMiddleware__Core__ProxyResolvers.markotApiResolverProxyForViewer,
    listings: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Listing.fetchListings(context.markotApiConfig, args.first, args.after, args.status);
      }),
    bids: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__BidInfo.fetchBids(context.markotApiConfig, args.first, args.after, args.bidGroup);
      }),
    addresses: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__Address.fetchUserAddresses(context.markotApiConfig, args.first, args.after);
      }),
    draftListings: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__DraftListing.fetchDraftListings(context.markotApiConfig, args.first, args.after);
      }),
    paymentMethods2: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__PaymentMethod.fetchPaymentMethods2(context.markotApiConfig, Belt_Option.getWithDefault(Caml_option.nullable_to_opt(args.fetchLiveData), false));
      }),
    wallet: (function (_obj, _args, context, _info) {
        return GqlMiddleware__Entity__Wallet.fetchWallet(context.markotApiConfig);
      }),
    wishlist: (function (_obj, args, context, _info) {
        var partial_arg = true;
        var arg = GqlMiddleware__Core__Utils.isFeatureFlagOn(context, "useMarkdownDescription");
        var resolveAndUnwrapWishlistItem = function (param) {
          var param$1 = true;
          var param$2 = true;
          return makeWishlistItemResolverAndUnwrapper(context, partial_arg, param, arg, param$1, param$2);
        };
        return GqlMiddleware__Entity__Wishlist.fetchWishlist(context.markotApiConfig, args.first, args.after).then(function (result) {
                    if (result === undefined) {
                      return Promise.resolve(undefined);
                    }
                    var pageInfo = result.pageInfo;
                    var totalItems = result.totalItems;
                    return Promise.all(Belt_Array.map(result.edges, (function (param) {
                                        var node = param.node;
                                        var cursor = param.cursor;
                                        return resolveAndUnwrapWishlistItem(node).then(function (resolvedNode) {
                                                    return [
                                                            node.id,
                                                            {
                                                              cursor: cursor,
                                                              node: resolvedNode
                                                            }
                                                          ];
                                                  });
                                      }))).then(function (edgeResults) {
                                var match = Belt_Array.partition(edgeResults, (function (param) {
                                        return Belt_Option.isSome(param[1].node);
                                      }));
                                var unknownEdges = match[1];
                                if (unknownEdges.length !== 0) {
                                  var invalidIds = Belt_Array.joinWith(Belt_Array.map(unknownEdges, (function (param) {
                                              return param[0];
                                            })), ", ", GqlMiddleware__Core__NodeId.toString);
                                  RescriptUtils__Logger.error2(GqlMiddleware__Core__Infra.logger, (new Error("Wishlist items couldn't be found")), {
                                        invalidIds: invalidIds
                                      });
                                }
                                return {
                                        totalItems: totalItems,
                                        pageInfo: pageInfo,
                                        edges: Belt_Array.map(match[0], (function (param) {
                                                return param[1];
                                              }))
                                      };
                              });
                  });
      }),
    defaultPaymentMethod2: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__PaymentMethod.fetchDefaultPaymentMethod2(context.markotApiConfig, Belt_Option.getWithDefault(Caml_option.nullable_to_opt(args.fetchLiveData), false));
      }),
    defaultShippingAddress: (function (obj, _args, context, _info) {
        return Belt_Option.map(obj.defaultShippingAddressId, (function (defaultShippingAddressId) {
                      return GqlMiddleware__Entity__Address.fetchAddress(context.markotApiConfig, defaultShippingAddressId);
                    }));
      }),
    defaultBillingAddress: (function (obj, _args, context, _info) {
        return Belt_Option.map(obj.defaultBillingAddressId, (function (defaultBillingAddressId) {
                      return GqlMiddleware__Entity__Address.fetchAddress(context.markotApiConfig, defaultBillingAddressId);
                    }));
      }),
    expoNotificationIds: (function (obj, _args, _context, _info) {
        return obj.expoNotificationIds;
      }),
    prelovedOrders: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__PrelovedOrder.fetchPrelovedOrders(context.markotApiConfig, args.first, args.after);
      }),
    newOrders: (function (_obj, args, context, _info) {
        return GqlMiddleware__Entity__ShopifyCustomer.createAccessToken(context.markotApiConfig).then(function (customerAccessToken) {
                    return GqlMiddleware__Entity__NewOrder.fetchUserNewOrders(context.shopifyApiConfig, customerAccessToken, args.first, args.after);
                  });
      }),
    notificationPreferences: (function (_obj, _args, context, _info) {
        var match = Curry._2(FeatureFlags__AppSpecificConfig.FeatureFlagsMap.get, context.featureFlags, "notificationScreen");
        if (match !== undefined && match.status === "on") {
          return [
                  {
                    title: "Auctions",
                    settings: [
                      {
                        notificationId: "MTtOb3RpZmljYXRpb25TZXR0aW5nO25vdGlmaWNhdGlvbi1pZDE=",
                        title: "New bid",
                        channels: [
                          {
                            code: "email",
                            value: true
                          },
                          {
                            code: "push",
                            value: false
                          }
                        ]
                      },
                      {
                        notificationId: "notification id2",
                        title: "Won auction",
                        channels: [
                          {
                            code: "email",
                            value: false
                          },
                          {
                            code: "push",
                            value: true
                          }
                        ]
                      },
                      {
                        notificationId: "notification id3",
                        title: "Higher bid recieved",
                        channels: [
                          {
                            code: "email",
                            value: false
                          },
                          {
                            code: "push",
                            value: false
                          }
                        ]
                      },
                      {
                        notificationId: "notification id4",
                        title: "Declined bid",
                        channels: [
                          {
                            code: "email",
                            value: true
                          },
                          {
                            code: "push",
                            value: true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    title: "Notify me",
                    settings: [
                      {
                        notificationId: "notification id5",
                        title: "New product",
                        channels: [
                          {
                            code: "email",
                            value: true
                          },
                          {
                            code: "push",
                            value: false
                          }
                        ]
                      },
                      {
                        notificationId: "notification id6",
                        title: "Pre-loved product",
                        channels: [
                          {
                            code: "email",
                            value: false
                          },
                          {
                            code: "push",
                            value: true
                          }
                        ]
                      }
                    ]
                  }
                ];
        } else {
          RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "notificationPreferences is not supported");
          return Js_exn.raiseError("notificationPreferences is not supported");
        }
      })
  },
  Bundle: {
    inWishlist: (function (obj, _args, context, _info) {
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] Bundle.inWishlist id", obj.id);
        return loadIfNotPreloaded(obj.inWishlist, (function (param) {
                      return GqlMiddleware__Entity__WishlistItem.isInWishlist(context.markotApiConfig, obj.id);
                    }));
      }),
    itemCount: (function (obj, _args, context, _info) {
        return GqlMiddleware__Entity__Bundle.fetchItemCount(context.shopifyApiConfig, obj.id);
      }),
    items: (function (obj, args, context, _info) {
        return GqlMiddleware__Entity__Bundle.fetchItems(context.shopifyApiConfig, obj.id, args.first, args.after, GqlMiddleware__Core__Utils.isFeatureFlagOn(context, "useMarkdownDescription"), true, true, undefined);
      })
  },
  Influencer: {
    posts: (function (_obj, _args, _context, _info) {
        RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "[resolver] Influencer.posts - Use Influencer.posts2 instead");
        return Js_exn.raiseError("Use Influencer.posts2 instead");
      }),
    posts2: (function (obj, args, context, _info) {
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] Influencer.posts influencer ID: ", obj.id);
        return Belt_Option.map(obj.blogPostTag, (function (blogPostTag) {
                      return GqlMiddleware__Entity__BlogPost.fetchBlogPosts(context.markotApiConfig, args.first, args.after, blogPostTag);
                    }));
      })
  },
  Category: {
    brands: (function (obj, args, context, _info) {
        RescriptUtils__Logger.info3(GqlMiddleware__Core__Infra.logger, "[resolver] Category.brands categoryId", obj.id, obj.slug);
        return GqlMiddleware__Entity__Brand.fetchCategoryBrands(context.shopifyApiConfig, obj.id, args.first, undefined);
      })
  },
  Product: {
    media: (function (obj, _args, context, _info) {
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] Product.media productId", obj.id);
        return loadIfNotPreloaded(obj.media, (function (param) {
                      return GqlMiddleware__Entity__Product.fetchProductMedia(context.shopifyApiConfig, obj.id);
                    }));
      }),
    prelovedItems: (function (obj, args, context, _info) {
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] Product.prelovedItems productId", obj.id);
        return GqlMiddleware__Entity__PrelovedItemInfo.fetchPrelovedItems(context.markotApiConfig, obj.id, args.status, args.first, args.after, args.sort);
      }),
    attributes: (function (obj, _args, context, _info) {
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] Product.attributes productId", obj.id);
        return GqlMiddleware__Entity__Attribute.fetchProductAttributes(context.shopifyApiConfig, obj.id);
      }),
    defaultVariant: (function (obj, _args, context, _info) {
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] Product.defaultVariant productId", obj.id);
        var batchFetchDefaultVariants = GqlMiddleware__Core__Utils.isFeatureFlagOn(context, "batchFetchDefaultVariants");
        if (batchFetchDefaultVariants) {
          return context.loaders.defaultVariantByProductId.load(obj.id);
        } else {
          return GqlMiddleware__Entity__ProductVariant.fetchProductDefaultVariant(context.shopifyApiConfig, obj.id);
        }
      }),
    brand: (function (obj, _args, context, _info) {
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] Product.brand productId", obj.id);
        var id = obj.brandId;
        if (id !== undefined) {
          return context.loaders.brandById.load(Caml_option.valFromOption(id));
        } else {
          RescriptUtils__Logger.warn2(GqlMiddleware__Core__Infra.logger, "No 'brandId' found in Product.id", GqlMiddleware__Core__NodeId.toString(obj.id));
          return Promise.resolve(undefined);
        }
      }),
    inWishlist: (function (obj, _args, context, _info) {
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] Product.inWishlist productId", obj.id);
        return loadIfNotPreloaded(obj.inWishlist, (function (param) {
                      return GqlMiddleware__Entity__WishlistItem.isInWishlist(context.markotApiConfig, obj.id);
                    }));
      }),
    settingsForPrelovedItems: (function (obj, _args, context, _info) {
        return GqlMiddleware__Entity__SettingsForPrelovedItems.fetchSettingsForPrelovedItemsByProductId(context.shopifyApiConfig, obj.id);
      }),
    variantByAttributeValues: (function (obj, args, context, _info) {
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] Product.variantByAttributeValues productId", obj.id);
        return GqlMiddleware__Entity__ProductVariant.fetchProductVariantByProductIdAndAttributeValueIds(context.shopifyApiConfig, obj.id, args.attributeValues);
      }),
    specs: (function (obj, _args, context, _info) {
        return GqlMiddleware__Entity__ProductSpecifications.fetchProductSpecifications(context.markotApiConfig, obj.id);
      })
  },
  ProductVariant: {
    product: (function (_obj, _args, context, _info) {
        var productId = (_obj.productId || (_obj.product && _obj.product.id));
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] ProductVariant.product productId", productId);
        return context.loaders.productById.load(productId);
      }),
    inWishlist: (function (obj, _args, context, _info) {
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] ProductVariant.inWishlist id", obj.id);
        return loadIfNotPreloaded(obj.inWishlist, (function (param) {
                      return GqlMiddleware__Entity__WishlistItem.isInWishlist(context.markotApiConfig, obj.id);
                    }));
      })
  },
  ProductCollection: {
    products: (function (obj, args, context, _info) {
        return GqlMiddleware__Entity__Product.fetchProductsInProductCollection(context.shopifyApiConfig, GqlMiddleware__Core__NodeId.unpackExn(obj.id).internalId, args.first, GqlMiddleware__Core__Utils.isTestUser(context.cognitoGroups), args.after, GqlMiddleware__Core__Utils.isFeatureFlagOn(context, "useMarkdownDescription"), true, true, undefined);
      })
  },
  Auction: {
    history: (function (obj, args, context, _info) {
        return GqlMiddleware__Entity__Auction.fetchAuctionHistory(context.markotApiConfig, obj.id, args.first, args.after).then(function (__x) {
                    return Belt_Option.map(__x, (function (param) {
                                  return {
                                          totalEvents: param.totalEvents,
                                          pageInfo: param.pageInfo,
                                          edges: Belt_Array.map(param.edges, (function (param) {
                                                  return {
                                                          cursor: param.cursor,
                                                          node: auctionEvent(param.node)
                                                        };
                                                }))
                                        };
                                }));
                  });
      })
  },
  PrelovedItemInfo: {
    prelovedItem: (function (_obj, _args, context, _info) {
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] PrelovedItemInfo.prelovedItem prelovedItemId", (_obj.prelovedItemId || (_obj.prelovedItem && _obj.prelovedItem.id)));
        return GqlMiddleware__Entity__PrelovedItem.fetchPrelovedItem(context.markotApiConfig, (_obj.prelovedItemId || (_obj.prelovedItem && _obj.prelovedItem.id)));
      }),
    inWishlist: (function (obj, _args, context, _info) {
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] PrelovedItemInfo.inWishlist prelovedItemId", obj.id);
        return loadIfNotPreloaded(obj.inWishlist, (function (param) {
                      return GqlMiddleware__Entity__WishlistItem.isInWishlist(context.markotApiConfig, obj.id);
                    }));
      })
  },
  PrelovedItem: {
    product: (function (_obj, _args, _context, _info) {
        RescriptUtils__Logger.error(GqlMiddleware__Core__Infra.logger, "[resolver] PrelovedItem.product - Use product2 instead");
      }),
    product2: (function (obj, _args, context, _info) {
        var productId = obj.productId;
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] PrelovedItem.product2 productId", productId);
        return Belt_Option.map(productId, (function (productId) {
                      return context.loaders.productById.load(productId);
                    }));
      })
  },
  ProductInfo: {
    product: (function (obj, _args, context, _info) {
        var productId = obj.productId;
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] ProductInfo.product productId", productId);
        return context.loaders.productById.load(productId);
      })
  },
  DraftListing: {
    product: (function (obj, _args, context, _info) {
        var productId = obj.productId;
        RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "[resolver] DraftListing.product productId", productId);
        return Belt_Option.map(productId, (function (productId) {
                      return context.loaders.productById.load(productId);
                    }));
      }),
    product2: (function (obj, _args, _context, _info) {
        return Belt_Option.map(obj.product2, draftListingProduct);
      }),
    billingAddress: (function (obj, _args, context, _info) {
        return Belt_Option.map(obj.billingAddressId, (function (billingAddressId) {
                      return GqlMiddleware__Entity__Address.fetchAddress(context.markotApiConfig, billingAddressId);
                    }));
      }),
    pickupAddress: (function (obj, _args, context, _info) {
        return Belt_Option.map(obj.pickupAddressId, (function (pickupAddressId) {
                      return GqlMiddleware__Entity__Address.fetchAddress(context.markotApiConfig, pickupAddressId);
                    }));
      }),
    media: (function (obj, _args, context, _info) {
        return GqlMiddleware__Entity__DraftListing.fetchPrelovedItemMediaByDraftListingId(context.markotApiConfig, obj.id);
      })
  },
  Listing: {
    product: (function (obj, _args, _context, _info) {
        return Belt_Option.map(obj.product, listingProduct);
      }),
    prelovedItemInfo: (function (obj, _args, context, _info) {
        return GqlMiddleware__Entity__PrelovedItemInfo.fetchPrelovedItemInfo(context.markotApiConfig, obj.prelovedItemInfoId, Belt_Option.getWithDefault(GqlMiddleware__Core__Utils.isTestUser(context.cognitoGroups), false));
      }),
    billingAddress: (function (obj, _args, context, _info) {
        var __x = obj.billingAddressId;
        return GqlMiddleware__Entity__Address.fetchAddress(context.markotApiConfig, __x);
      }),
    pickupAddress: (function (obj, _args, context, _info) {
        var __x = obj.pickupAddressId;
        return GqlMiddleware__Entity__Address.fetchAddress(context.markotApiConfig, __x);
      })
  },
  PrelovedOrder: {
    lineItems: (function (obj, args, context, _info) {
        var __x = obj.id;
        return GqlMiddleware__Entity__PrelovedOrder.fetchPrelovedOrderLineItems(context.markotApiConfig, __x, args.first, args.after);
      }),
    billingAddress: (function (obj, _args, context, _info) {
        var __x = obj.billingAddressId;
        return GqlMiddleware__Entity__Address.fetchAddress(context.markotApiConfig, __x);
      }),
    deliveryAddress: (function (obj, _args, context, _info) {
        var __x = obj.deliveryAddressId;
        return GqlMiddleware__Entity__Address.fetchAddress(context.markotApiConfig, __x);
      }),
    paymentMethod: (function (obj, _args, context, _info) {
        var __x = obj.id;
        return GqlMiddleware__Entity__PrelovedOrder.fetchPrelovedOrderPaymentMethod(context.markotApiConfig, __x);
      })
  },
  NewOrder: {
    lineItems: (function (obj, args, context, _info) {
        var __x = obj.id;
        return GqlMiddleware__Entity__NewOrder.fetchNewOrderLineItems(context.shopifyApiConfig, __x, args.first, args.after);
      })
  }
};

export {
  resolvers ,
}
/* ShopifyApi__Id Not a pure module */
