// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactNative from "react-native";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";
import * as ThemeContextFactory$DecidrIoStyles from "@decidr-io/styles/src/theme/ThemeContextFactory.bs.js";

function getSize(font) {
  return font.size;
}

function getLineHeight(font) {
  return font.lineHeight;
}

function getLetterSpacingPct(font) {
  return font.letterSpacingPct;
}

function unsafeMake(size, lineHeight, letterSpacingPct) {
  return {
          size: size,
          lineHeight: lineHeight,
          letterSpacingPct: letterSpacingPct
        };
}

var Font = {
  getSize: getSize,
  getLineHeight: getLineHeight,
  getLetterSpacingPct: getLetterSpacingPct,
  unsafeMake: unsafeMake
};

var xs = {
  title1: {
    size: 20,
    lineHeight: 26,
    letterSpacingPct: 0.0025
  },
  title2: {
    size: 18,
    lineHeight: 24,
    letterSpacingPct: 0.0025
  },
  title3: {
    size: 16,
    lineHeight: 22,
    letterSpacingPct: 0.0025
  },
  price1: {
    size: 16,
    lineHeight: 20,
    letterSpacingPct: 0.008
  },
  price2: {
    size: 14,
    lineHeight: 18,
    letterSpacingPct: 0.004
  },
  price3: {
    size: 11,
    lineHeight: 14,
    letterSpacingPct: 0.002
  },
  subtitle: {
    size: 11,
    lineHeight: 14,
    letterSpacingPct: 0.004
  },
  b1: {
    size: 13,
    lineHeight: 16,
    letterSpacingPct: 0.006
  },
  b2: {
    size: 11,
    lineHeight: 14,
    letterSpacingPct: 0.008
  },
  button1: {
    size: 13,
    lineHeight: 16,
    letterSpacingPct: 0.2
  },
  button2: {
    size: 11,
    lineHeight: 14,
    letterSpacingPct: 0.2
  },
  caption1: {
    size: 11,
    lineHeight: 14,
    letterSpacingPct: 0.007
  },
  caption2: {
    size: 9,
    lineHeight: 12,
    letterSpacingPct: 0.005
  }
};

var s = {
  title1: {
    size: 20,
    lineHeight: 26,
    letterSpacingPct: 0.0025
  },
  title2: {
    size: 18,
    lineHeight: 24,
    letterSpacingPct: 0.0025
  },
  title3: {
    size: 16,
    lineHeight: 22,
    letterSpacingPct: 0.0025
  },
  price1: {
    size: 18,
    lineHeight: 22,
    letterSpacingPct: 0.008
  },
  price2: {
    size: 16,
    lineHeight: 20,
    letterSpacingPct: 0.006
  },
  price3: {
    size: 14,
    lineHeight: 18,
    letterSpacingPct: 0.004
  },
  subtitle: {
    size: 13,
    lineHeight: 16,
    letterSpacingPct: 0.004
  },
  b1: {
    size: 15,
    lineHeight: 20,
    letterSpacingPct: 0.004
  },
  b2: {
    size: 13,
    lineHeight: 16,
    letterSpacingPct: 0.006
  },
  button1: {
    size: 13,
    lineHeight: 16,
    letterSpacingPct: 0.2
  },
  button2: {
    size: 11,
    lineHeight: 14,
    letterSpacingPct: 0.2
  },
  caption1: {
    size: 13,
    lineHeight: 16,
    letterSpacingPct: 0.007
  },
  caption2: {
    size: 11,
    lineHeight: 14,
    letterSpacingPct: 0.007
  }
};

var m = {
  title1: {
    size: 24,
    lineHeight: 30,
    letterSpacingPct: 0
  },
  title2: {
    size: 22,
    lineHeight: 28,
    letterSpacingPct: 0.001
  },
  title3: {
    size: 20,
    lineHeight: 26,
    letterSpacingPct: 0.0015
  },
  price1: {
    size: 20,
    lineHeight: 24,
    letterSpacingPct: 0.01
  },
  price2: {
    size: 18,
    lineHeight: 22,
    letterSpacingPct: 0.008
  },
  price3: {
    size: 16,
    lineHeight: 20,
    letterSpacingPct: 0.006
  },
  subtitle: {
    size: 15,
    lineHeight: 18,
    letterSpacingPct: 0.004
  },
  b1: {
    size: 17,
    lineHeight: 24,
    letterSpacingPct: 0.002
  },
  b2: {
    size: 15,
    lineHeight: 20,
    letterSpacingPct: 0.004
  },
  button1: {
    size: 13,
    lineHeight: 16,
    letterSpacingPct: 0.2
  },
  button2: {
    size: 11,
    lineHeight: 14,
    letterSpacingPct: 0.2
  },
  caption1: {
    size: 14,
    lineHeight: 18,
    letterSpacingPct: 0.007
  },
  caption2: {
    size: 12,
    lineHeight: 16,
    letterSpacingPct: 0.007
  }
};

var l = {
  title1: {
    size: 28,
    lineHeight: 36,
    letterSpacingPct: -0.001
  },
  title2: {
    size: 26,
    lineHeight: 32,
    letterSpacingPct: -0.001
  },
  title3: {
    size: 24,
    lineHeight: 30,
    letterSpacingPct: 0
  },
  price1: {
    size: 22,
    lineHeight: 28,
    letterSpacingPct: 0.012
  },
  price2: {
    size: 20,
    lineHeight: 24,
    letterSpacingPct: 0.01
  },
  price3: {
    size: 18,
    lineHeight: 22,
    letterSpacingPct: 0.008
  },
  subtitle: {
    size: 15,
    lineHeight: 18,
    letterSpacingPct: 0.004
  },
  b1: {
    size: 20,
    lineHeight: 28,
    letterSpacingPct: 0
  },
  b2: {
    size: 17,
    lineHeight: 24,
    letterSpacingPct: 0.002
  },
  button1: {
    size: 15,
    lineHeight: 18,
    letterSpacingPct: 0.2
  },
  button2: {
    size: 13,
    lineHeight: 16,
    letterSpacingPct: 0.2
  },
  caption1: {
    size: 15,
    lineHeight: 18,
    letterSpacingPct: 0.007
  },
  caption2: {
    size: 13,
    lineHeight: 16,
    letterSpacingPct: 0.007
  }
};

var adaptiveTypography = {
  xxs: xs,
  xs: s,
  s: s,
  m: m,
  l: m,
  xl: l,
  xxl: l
};

var Typography = {
  Font: Font,
  adaptiveTypography: adaptiveTypography,
  xs: xs,
  s: s,
  m: m,
  l: l
};

var xs$1 = {
  _05: 20,
  _1: 20,
  _2: 32,
  _3: 56
};

var s$1 = {
  _05: 20,
  _1: 32,
  _2: 40,
  _3: 60
};

var m$1 = {
  _05: 32,
  _1: 40,
  _2: 56,
  _3: 80
};

var l$1 = {
  _05: 40,
  _1: 60,
  _2: 80,
  _3: 100
};

var adaptiveSectionSpacing = {
  xxs: xs$1,
  xs: s$1,
  s: s$1,
  m: m$1,
  l: m$1,
  xl: l$1,
  xxl: l$1
};

var SectionSpacing = {
  xs: xs$1,
  s: s$1,
  m: m$1,
  l: l$1,
  adaptiveSectionSpacing: adaptiveSectionSpacing
};

var spacing = {
  _1: 2,
  _2: 4,
  _3: 6,
  _4: 8,
  _5: 10,
  _6: 12,
  _7: 14,
  _8: 16,
  _10: 20,
  _12: 24,
  _14: 28,
  _16: 32,
  _18: 36,
  _20: 40,
  _22: 44,
  _24: 48,
  _26: 52,
  _28: 56,
  _30: 60,
  _32: 64,
  _34: 68,
  _36: 72,
  _38: 76,
  _40: 80,
  _43: 86,
  _46: 92,
  _50: 100
};

var adaptiveSpacing = {
  xxs: spacing,
  xs: spacing,
  s: spacing,
  m: spacing,
  l: spacing,
  xl: spacing,
  xxl: spacing
};

var Spacing = {
  spacing: spacing,
  adaptiveSpacing: adaptiveSpacing
};

var colors = {
  primary: "#282828",
  onPrimary: "#FEFEFC",
  primaryShade1: "#4A4A4A",
  onPrimaryShade1: "#FEFEFC",
  primaryShade2: "#A7A7A7",
  onPrimaryShade2: "#FEFEFC",
  primaryShade3: "#C6C6C6",
  onPrimaryShade3: "#282828",
  primaryShade4: "#F2F2F2",
  onPrimaryShade4: "#7E7E7E",
  primaryVariant: "#D2C7B3",
  onPrimaryVariant: "#282828",
  primaryVariantShade1: "#E3DBCA",
  onPrimaryVariantShade1: "#282828",
  secondary: "#E5F9D4",
  onSecondary: "#282828",
  secondaryShade1: "#F3FDE6",
  onSecondaryShade1: "#D1D0C9",
  secondaryVariant: "#B8DEB5",
  onSecondaryVariant: "#282828",
  secondaryVariantShade1: "#CFEBCA",
  onSecondaryVariantShade1: "#282828",
  secondaryVariant2: "#E7E5D9",
  onSecondaryVariant2: "#282828",
  secondaryVariant3: "#F5F2E9",
  onSecondaryVariant3: "#282828",
  secondaryVariant4: "#D3E8FD",
  onSecondaryVariant4: "#282828",
  background: "#F7F7F2",
  onBackground: "#282828",
  backgroundVariant: "#FBFBF8",
  onBackgroundVariant: "#282828",
  backgroundVariant2: "#E8E7E1",
  onBackgroundVariant2: "#282828",
  backgroundVariant2Shade1: "#EEEDE9",
  onBackgroundVariant2Shade1: "#282828",
  onBackgroundShade: "#A7A7A7",
  surface: "#FFFFFF",
  onSurface: "#282828",
  info: "#AB8F74",
  onInfo: "#FEFEFC",
  infoShade1: "#CCB59B",
  onInfoShade1: "#FEFEFC",
  infoShade2: "#E5D3B9",
  onInfoShade2: "#FEFEFC",
  infoShade3: "#F6EBD6",
  onInfoShade3: "#AB8F74",
  infoShade4: "#FAF5EA",
  onInfoShade4: "#AB8F74",
  success: "#6ABE8A",
  onSuccess: "#FEFEFC",
  successShade1: "#90D8A4",
  onSuccessShade1: "#FEFEFC",
  successShade2: "#ADEBB8",
  onSuccessShade2: "#FEFEFC",
  successShade3: "#CCF8D0",
  onSuccessShade3: "#6ABE8A",
  successShade4: "#E5FBE5",
  onSuccessShade4: "#6ABE8A",
  error: "#C56768",
  onError: "#FEFEFC",
  errorShade1: "#DC938D",
  onErrorShade1: "#FEFEFC",
  errorShade2: "#EDB4A9",
  onErrorShade2: "#FEFEFC",
  errorShade3: "#F9D5C9",
  onErrorShade3: "#C56768",
  errorShade4: "#FCECE4",
  onErrorShade4: "#C56768"
};

var Colors = {
  colors: colors
};

var roundness = {
  _1: 2,
  _2: 4,
  _3: 6,
  _4: 8,
  _5: 10,
  _6: 12,
  _7: 14,
  _8: 16,
  _9: 18,
  _10: 20,
  _11: 22,
  _12: 24,
  _13: 26,
  _14: 28,
  circle: 1000
};

var Roundness = {
  roundness: roundness
};

var lineThickness = {
  xxs: 0.5,
  xs: 1,
  s: 2,
  m: 3,
  l: 4,
  xl: 5
};

var LineThickness = {
  lineThickness: lineThickness
};

var fontFamily = {
  libre: "libre",
  dm: "dm-sans"
};

var FontFamily = {
  fontFamily: fontFamily
};

var lightThemeConfig = {
  isDark: false,
  colors: colors,
  adaptiveSpacing: adaptiveSpacing,
  adaptiveRoundness: roundness,
  adaptiveLineThickness: lineThickness,
  fontFamily: fontFamily,
  adaptiveTypography: adaptiveTypography,
  adaptiveSectionSpacing: adaptiveSectionSpacing
};

var darkThemeConfig = {
  isDark: true,
  colors: colors,
  adaptiveSpacing: adaptiveSpacing,
  adaptiveRoundness: roundness,
  adaptiveLineThickness: lineThickness,
  fontFamily: fontFamily,
  adaptiveTypography: adaptiveTypography,
  adaptiveSectionSpacing: adaptiveSectionSpacing
};

var Defaults = {
  lightThemeConfig: lightThemeConfig,
  darkThemeConfig: darkThemeConfig,
  fallbackThemeConfig: lightThemeConfig
};

function makeTheme(param, mediaTag) {
  var adaptiveSectionSpacing = param.adaptiveSectionSpacing;
  var adaptiveTypography = param.adaptiveTypography;
  var fontFamily = param.fontFamily;
  var adaptiveLineThickness = param.adaptiveLineThickness;
  var adaptiveRoundness = param.adaptiveRoundness;
  var adaptiveSpacing = param.adaptiveSpacing;
  var colors = param.colors;
  var isDark = param.isDark;
  if (mediaTag === "m") {
    return {
            isDark: isDark,
            colors: colors,
            spacing: adaptiveSpacing.m,
            roundness: adaptiveRoundness,
            lineThickness: adaptiveLineThickness,
            fontFamily: fontFamily,
            typography: adaptiveTypography.m,
            sectionSpacing: adaptiveSectionSpacing.m
          };
  } else if (mediaTag === "s") {
    return {
            isDark: isDark,
            colors: colors,
            spacing: adaptiveSpacing.s,
            roundness: adaptiveRoundness,
            lineThickness: adaptiveLineThickness,
            fontFamily: fontFamily,
            typography: adaptiveTypography.s,
            sectionSpacing: adaptiveSectionSpacing.s
          };
  } else if (mediaTag === "xl") {
    return {
            isDark: isDark,
            colors: colors,
            spacing: adaptiveSpacing.xl,
            roundness: adaptiveRoundness,
            lineThickness: adaptiveLineThickness,
            fontFamily: fontFamily,
            typography: adaptiveTypography.xl,
            sectionSpacing: adaptiveSectionSpacing.xl
          };
  } else if (mediaTag === "xs") {
    return {
            isDark: isDark,
            colors: colors,
            spacing: adaptiveSpacing.xs,
            roundness: adaptiveRoundness,
            lineThickness: adaptiveLineThickness,
            fontFamily: fontFamily,
            typography: adaptiveTypography.xs,
            sectionSpacing: adaptiveSectionSpacing.xs
          };
  } else if (mediaTag === "xxl") {
    return {
            isDark: isDark,
            colors: colors,
            spacing: adaptiveSpacing.xxl,
            roundness: adaptiveRoundness,
            lineThickness: adaptiveLineThickness,
            fontFamily: fontFamily,
            typography: adaptiveTypography.xxl,
            sectionSpacing: adaptiveSectionSpacing.xxl
          };
  } else if (mediaTag === "xxs") {
    return {
            isDark: isDark,
            colors: colors,
            spacing: adaptiveSpacing.xxs,
            roundness: adaptiveRoundness,
            lineThickness: adaptiveLineThickness,
            fontFamily: fontFamily,
            typography: adaptiveTypography.xxs,
            sectionSpacing: adaptiveSectionSpacing.xxs
          };
  } else {
    return {
            isDark: isDark,
            colors: colors,
            spacing: adaptiveSpacing.l,
            roundness: adaptiveRoundness,
            lineThickness: adaptiveLineThickness,
            fontFamily: fontFamily,
            typography: adaptiveTypography.l,
            sectionSpacing: adaptiveSectionSpacing.l
          };
  }
}

var fallbackTheme = makeTheme(lightThemeConfig, Ui__Responsive.determineMediaTag(ReactNative.Dimensions.get("window").width, undefined, undefined));

var themeConfigSet = {
  light: lightThemeConfig,
  dark: darkThemeConfig
};

var Theme = {
  makeTheme: makeTheme,
  fallbackTheme: fallbackTheme,
  themeConfigSet: themeConfigSet
};

var Context = ThemeContextFactory$DecidrIoStyles.Make(Theme);

var themeProvider = Context.ThemeProvider.make;

var themeConsumer = Context.ThemeConsumer.make;

var useTheme = Context.useTheme;

export {
  Typography ,
  SectionSpacing ,
  Spacing ,
  Colors ,
  Roundness ,
  LineThickness ,
  FontFamily ,
  Defaults ,
  Theme ,
  Context ,
  useTheme ,
  themeProvider ,
  themeConsumer ,
  themeConfigSet ,
  fallbackTheme ,
}
/* fallbackTheme Not a pure module */
