// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as MarkotApi__Infra from "./MarkotApi__Infra.bs.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as MarkotApi__CommonFragments from "./MarkotApi__CommonFragments.bs.js";

var Raw = {};

var query = "query AddressBasicData($id: ID!)  {\nnode(id: $id)  {\n__typename\n...on Address   {\n...AddressFields   \n}\n\n}\n\n}\n" + MarkotApi__CommonFragments.AddressFields.query;

function parse(value) {
  var value$1 = value.node;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1 = typename === "Address" ? ({
          NAME: "Address",
          VAL: {
            addressFields: MarkotApi__CommonFragments.AddressFields.verifyArgsAndParse("AddressFields", value$1)
          }
        }) : ({
          NAME: "UnspecifiedFragment",
          VAL: typename
        });
    tmp = tmp$1;
  }
  return {
          node: tmp
        };
}

function serialize(value) {
  var value$1 = value.node;
  var node = value$1 !== undefined ? (
      value$1.NAME === "UnspecifiedFragment" ? ({}) : Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                return {};
              }), [MarkotApi__CommonFragments.AddressFields.serialize(value$1.VAL.addressFields)])
    ) : null;
  return {
          node: node
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

var Raw$1 = {};

var query$1 = "query Addresses($first: Int, $after: String)  {\nviewer  {\naddresses(first: $first, after: $after)  {\npageInfo  {\nstartCursor  \nendCursor  \nhasNextPage  \nhasPreviousPage  \n}\n\nedges  {\ncursor  \nnode  {\n...AddressFields   \n}\n\n}\n\n}\n\n}\n\n}\n" + MarkotApi__CommonFragments.AddressFields.query;

function parse$1(value) {
  var value$1 = value.viewer;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.addresses;
    var value$3 = value$2.pageInfo;
    var value$4 = value$3.startCursor;
    var value$5 = value$3.endCursor;
    var value$6 = value$2.edges;
    tmp = {
      addresses: {
        pageInfo: {
          startCursor: !(value$4 == null) ? value$4 : undefined,
          endCursor: !(value$5 == null) ? value$5 : undefined,
          hasNextPage: value$3.hasNextPage,
          hasPreviousPage: value$3.hasPreviousPage
        },
        edges: Js_array.map((function (value) {
                var value$1 = value.node;
                return {
                        cursor: value.cursor,
                        node: MarkotApi__CommonFragments.AddressFields.verifyArgsAndParse("AddressFields", value$1)
                      };
              }), value$6)
      }
    };
  }
  return {
          viewer: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.viewer;
  var viewer;
  if (value$1 !== undefined) {
    var value$2 = value$1.addresses;
    var value$3 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var node = MarkotApi__CommonFragments.AddressFields.serialize(value$1);
            var value$2 = value.cursor;
            return {
                    cursor: value$2,
                    node: node
                  };
          }), value$3);
    var value$4 = value$2.pageInfo;
    var value$5 = value$4.hasPreviousPage;
    var value$6 = value$4.hasNextPage;
    var value$7 = value$4.endCursor;
    var endCursor = value$7 !== undefined ? value$7 : null;
    var value$8 = value$4.startCursor;
    var startCursor = value$8 !== undefined ? value$8 : null;
    var pageInfo = {
      startCursor: startCursor,
      endCursor: endCursor,
      hasNextPage: value$6,
      hasPreviousPage: value$5
    };
    var addresses = {
      pageInfo: pageInfo,
      edges: edges
    };
    viewer = {
      addresses: addresses
    };
  } else {
    viewer = null;
  }
  return {
          viewer: viewer
        };
}

function serializeVariables$1(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  return {
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined
        };
}

var Raw$2 = {};

var query$2 = "mutation CreateUserAddress($input: CreateUserAddressInput2!)  {\ncreateUserAddress2(input: $input)  {\naddress  {\n...AddressFields   \n}\n\n}\n\n}\n" + MarkotApi__CommonFragments.AddressFields.query;

function parse$2(value) {
  var value$1 = value.createUserAddress2;
  var value$2 = value$1.address;
  return {
          createUserAddress2: {
            address: MarkotApi__CommonFragments.AddressFields.verifyArgsAndParse("AddressFields", value$2)
          }
        };
}

function serialize$2(value) {
  var value$1 = value.createUserAddress2;
  var value$2 = value$1.address;
  var address = MarkotApi__CommonFragments.AddressFields.serialize(value$2);
  var createUserAddress2 = {
    address: address
  };
  return {
          createUserAddress2: createUserAddress2
        };
}

function serializeInputObjectCreateUserAddressInput2(inp) {
  var a = inp.streetLine2;
  var a$1 = inp.description;
  var a$2 = inp.extraInfo;
  var a$3 = inp.addressType;
  return {
          recipientName: inp.recipientName,
          streetLine1: inp.streetLine1,
          streetLine2: a !== undefined ? a : undefined,
          country: inp.country,
          city: inp.city,
          postalCode: inp.postalCode,
          state: inp.state,
          description: a$1 !== undefined ? a$1 : undefined,
          extraInfo: a$2 !== undefined ? a$2 : undefined,
          addressType: a$3 !== undefined ? (
              a$3 === "Shipping" ? "Shipping" : "Billing"
            ) : undefined
        };
}

function serializeVariables$2(inp) {
  return {
          input: serializeInputObjectCreateUserAddressInput2(inp.input)
        };
}

var Raw$3 = {};

var query$3 = "mutation UpdateUserAddress($input: UpdateUserAddressInput!)  {\nupdateUserAddress2(input: $input)  {\naddress  {\n...AddressFields   \n}\n\n}\n\n}\n" + MarkotApi__CommonFragments.AddressFields.query;

function parse$3(value) {
  var value$1 = value.updateUserAddress2;
  var value$2 = value$1.address;
  return {
          updateUserAddress2: {
            address: MarkotApi__CommonFragments.AddressFields.verifyArgsAndParse("AddressFields", value$2)
          }
        };
}

function serialize$3(value) {
  var value$1 = value.updateUserAddress2;
  var value$2 = value$1.address;
  var address = MarkotApi__CommonFragments.AddressFields.serialize(value$2);
  var updateUserAddress2 = {
    address: address
  };
  return {
          updateUserAddress2: updateUserAddress2
        };
}

function serializeInputObjectUpdateUserAddressInput(inp) {
  var a = inp.recipientName;
  var a$1 = inp.streetLine1;
  var a$2 = inp.streetLine2;
  var a$3 = inp.country;
  var a$4 = inp.city;
  var a$5 = inp.postalCode;
  var a$6 = inp.state;
  var a$7 = inp.description;
  var a$8 = inp.extraInfo;
  return {
          addressId: inp.addressId,
          recipientName: a !== undefined ? a : undefined,
          streetLine1: a$1 !== undefined ? a$1 : undefined,
          streetLine2: a$2 !== undefined ? a$2 : undefined,
          country: a$3 !== undefined ? a$3 : undefined,
          city: a$4 !== undefined ? a$4 : undefined,
          postalCode: a$5 !== undefined ? a$5 : undefined,
          state: a$6 !== undefined ? a$6 : undefined,
          description: a$7 !== undefined ? a$7 : undefined,
          extraInfo: a$8 !== undefined ? a$8 : undefined
        };
}

function serializeVariables$3(inp) {
  return {
          input: serializeInputObjectUpdateUserAddressInput(inp.input)
        };
}

var Raw$4 = {};

function parse$4(value) {
  var value$1 = value.deleteUserAddress2;
  return {
          deleteUserAddress2: {
            deletedAddressId: value$1.deletedAddressId
          }
        };
}

function serialize$4(value) {
  var value$1 = value.deleteUserAddress2;
  var value$2 = value$1.deletedAddressId;
  var deleteUserAddress2 = {
    deletedAddressId: value$2
  };
  return {
          deleteUserAddress2: deleteUserAddress2
        };
}

function serializeVariables$4(inp) {
  return {
          id: inp.id
        };
}

var CreateUserAddressPayload = {};

var UpdateUserAddressPayload = {};

function fetchBasicData(apiConfig, id) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query,
                    Raw: Raw,
                    parse: parse,
                    serialize: serialize,
                    serializeVariables: serializeVariables
                  }), {
                id: MarkotApi__Id.toString(id)
              }, undefined).then(function (param) {
              var node = param.node;
              if (node !== undefined) {
                if (node.NAME === "UnspecifiedFragment") {
                  RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "Address fetchBasicData - node of unknown type found. Ignoring.", node);
                  return ;
                } else {
                  return MarkotApi__CommonFragments.makeAddress(node.VAL.addressFields);
                }
              } else {
                RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "Address fetchBasicData - not found. Id", id);
                return ;
              }
            });
}

function fetchUserAddresses(apiConfig, first, after) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                      query: query$1,
                      Raw: Raw$1,
                      parse: parse$1,
                      serialize: serialize$1,
                      serializeVariables: serializeVariables$1
                    }), {
                  first: first,
                  after: after
                }, undefined).then(function (param) {
                return Belt_Option.map(param.viewer, (function (param) {
                              return param.addresses;
                            }));
              }).then(function (addresses) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(addresses, (function (param) {
                                RescriptUtils__Logger.warn(MarkotApi__Infra.logger, "Address fetchUserAddresses - viewer not found.");
                              })), (function (param) {
                            var pageInfo = param.pageInfo;
                            return {
                                    pageInfo: {
                                      startCursor: pageInfo.startCursor,
                                      endCursor: pageInfo.endCursor,
                                      hasNextPage: pageInfo.hasNextPage,
                                      hasPreviousPage: pageInfo.hasPreviousPage
                                    },
                                    edges: Belt_Array.map(param.edges, (function (param) {
                                            return {
                                                    cursor: param.cursor,
                                                    node: MarkotApi__CommonFragments.makeAddress(param.node)
                                                  };
                                          }))
                                  };
                          }));
            });
}

function createUserAddress(apiConfig, param) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$2,
                    Raw: Raw$2,
                    parse: parse$2,
                    serialize: serialize$2,
                    serializeVariables: serializeVariables$2
                  }), {
                input: {
                  recipientName: param.recipientName,
                  streetLine1: param.streetLine1,
                  streetLine2: param.streetLine2,
                  country: param.country,
                  city: param.city,
                  postalCode: param.postalCode,
                  state: param.state,
                  description: param.description,
                  extraInfo: param.extraInfo,
                  addressType: Belt_Option.map(param.addressType, (function (addressType) {
                          if (addressType === "Shipping") {
                            return "Shipping";
                          } else {
                            return "Billing";
                          }
                        }))
                }
              }, undefined).then(function (param) {
              var address = param.createUserAddress2.address;
              return {
                      address: MarkotApi__CommonFragments.makeAddress(address),
                      addressId: MarkotApi__Id.parseExn(address.id)
                    };
            });
}

function updateUserAddress(apiConfig, param) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$3,
                    Raw: Raw$3,
                    parse: parse$3,
                    serialize: serialize$3,
                    serializeVariables: serializeVariables$3
                  }), {
                input: {
                  addressId: MarkotApi__Id.toString(param.addressId),
                  recipientName: param.recipientName,
                  streetLine1: param.streetLine1,
                  streetLine2: param.streetLine2,
                  country: param.country,
                  city: param.city,
                  postalCode: param.postalCode,
                  state: param.state,
                  description: param.description,
                  extraInfo: param.extraInfo
                }
              }, undefined).then(function (param) {
              var address = param.updateUserAddress2.address;
              return {
                      address: MarkotApi__CommonFragments.makeAddress(address),
                      addressId: MarkotApi__Id.parseExn(address.id)
                    };
            });
}

function deleteUserAddress(apiConfig, id) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: "mutation DeleteUserAddress($id: ID!)  {\ndeleteUserAddress2(id: $id)  {\ndeletedAddressId  \n}\n\n}\n",
                    Raw: Raw$4,
                    parse: parse$4,
                    serialize: serialize$4,
                    serializeVariables: serializeVariables$4
                  }), {
                id: MarkotApi__Id.toString(id)
              }, undefined).then(function (param) {
              return {
                      deletedAddressId: MarkotApi__Id.parseExn(param.deleteUserAddress2.deletedAddressId)
                    };
            });
}

export {
  CreateUserAddressPayload ,
  UpdateUserAddressPayload ,
  fetchBasicData ,
  fetchUserAddresses ,
  createUserAddress ,
  updateUserAddress ,
  deleteUserAddress ,
}
/* MarkotApi__Id Not a pure module */
