/* TypeScript file generated from Ui__Heading.res by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as Ui__HeadingBS__Es6Import from './Ui__Heading.bs';
const Ui__HeadingBS: any = Ui__HeadingBS__Es6Import;

import type {Color_t as ReactNative_Color_t} from '../../../../../rescript-shims/ReactNative.shim';

import type {Style_t as ReactNative_Style_t} from '../../../../../rescript-shims/ReactNative.shim';

import type {Typography_variant as Ui__Theme_Typography_variant} from '../../../../../packages/ui/src/theme/components/Ui__Theme.gen';

import type {WebText_accessibilityLevel as Ui__BaseText_WebText_accessibilityLevel} from './Ui__BaseText.gen';

import type {WebText_ellipsizeMode as Ui__BaseText_WebText_ellipsizeMode} from './Ui__BaseText.gen';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly accessibilityLabel?: string; 
  readonly children: React.ReactNode; 
  readonly color?: ReactNative_Color_t; 
  readonly ellipsizeMode?: Ui__BaseText_WebText_ellipsizeMode; 
  readonly fontFamily?: string; 
  readonly href?: string; 
  readonly level: Ui__BaseText_WebText_accessibilityLevel; 
  readonly numberOfLines?: number; 
  readonly onClick?: (_1:MouseEvent) => void; 
  readonly onMouseEnter?: (_1:MouseEvent) => void; 
  readonly onMouseLeave?: (_1:MouseEvent) => void; 
  readonly size?: Ui__Theme_Typography_variant; 
  readonly style?: ReactNative_Style_t; 
  readonly testID?: string
};

export const make: React.ComponentType<{
  readonly accessibilityLabel?: string; 
  readonly children: React.ReactNode; 
  readonly color?: ReactNative_Color_t; 
  readonly ellipsizeMode?: Ui__BaseText_WebText_ellipsizeMode; 
  readonly fontFamily?: string; 
  readonly href?: string; 
  readonly level: Ui__BaseText_WebText_accessibilityLevel; 
  readonly numberOfLines?: number; 
  readonly onClick?: (_1:MouseEvent) => void; 
  readonly onMouseEnter?: (_1:MouseEvent) => void; 
  readonly onMouseLeave?: (_1:MouseEvent) => void; 
  readonly size?: Ui__Theme_Typography_variant; 
  readonly style?: ReactNative_Style_t; 
  readonly testID?: string
}> = Ui__HeadingBS.make;
