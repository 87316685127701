/* TypeScript file generated from AuthContext.res by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as AuthContextBS__Es6Import from './AuthContext.bs';
const AuthContextBS: any = AuthContextBS__Es6Import;

// tslint:disable-next-line:interface-over-type-literal
export type authStatus = 
    "pending"
  | "authenticated"
  | "notAuthenticated";

// tslint:disable-next-line:interface-over-type-literal
export type t = { readonly status: authStatus };

// tslint:disable-next-line:interface-over-type-literal
export type Context_contextData = {
  readonly authInfo?: t; 
  readonly updateAuthInfo: (_1:t) => void; 
  readonly cleanAfterLogout: () => void
};

export const useAuth: () => Context_contextData = AuthContextBS.useAuth;

// tslint:disable-next-line:interface-over-type-literal
export type AuthProvider_Props = { readonly children: React.ReactNode; readonly contextData: Context_contextData };

export const AuthProvider: React.ComponentType<{ readonly children: React.ReactNode; readonly contextData: Context_contextData }> = AuthContextBS.AuthProvider;

export const AuthConsumer: <T1>(_1:{ readonly children: ((_1:Context_contextData) => T1) }) => T1 = AuthContextBS.AuthConsumer;
