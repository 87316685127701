// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "../../../api-adapter-markot/src/MarkotApi__Id.bs.js";
import * as ShopifyApi__Id from "../../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as MarkotApi__PrelovedItem from "../../../api-adapter-markot/src/MarkotApi__PrelovedItem.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Core__FromMarkot from "../core/GqlMiddleware__Core__FromMarkot.bs.js";
import * as GqlMiddleware__Entity__PrelovedItemMedia from "./GqlMiddleware__Entity__PrelovedItemMedia.bs.js";
import * as GqlMiddleware__Entity__PrelovedItemCondition from "./GqlMiddleware__Entity__PrelovedItemCondition.bs.js";

function makePrelovedItemId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "PrelovedItem", param);
}

function makePrelovedItem(param) {
  return {
          __typename: "PrelovedItem",
          id: GqlMiddleware__Core__NodeId.make(1, "PrelovedItem", MarkotApi__Id.unpackExn(param.id).internalId),
          productId: Belt_Option.map(param.productId, (function (productId) {
                  return GqlMiddleware__Core__NodeId.make(1, "Product", ShopifyApi__Id.toString(productId));
                })),
          listingId: GqlMiddleware__Core__NodeId.make(1, "Listing", MarkotApi__Id.unpackExn(param.listingId).internalId),
          description: param.description,
          condition2: GqlMiddleware__Entity__PrelovedItemCondition.fromMarkotApi(param.condition),
          belongsToViewer: param.belongsToViewer,
          mainImage: Belt_Option.map(param.mainImage, GqlMiddleware__Core__FromMarkot.imageResource),
          media: Belt_Option.map(param.media, (function (__x) {
                  return Belt_Array.map(__x, GqlMiddleware__Entity__PrelovedItemMedia.makePrelovedItemMedia);
                }))
        };
}

function fetchPrelovedItem(apiConfig, id) {
  return MarkotApi__PrelovedItem.fetchBasicData(apiConfig, id, true).then(function (__x) {
              return Belt_Option.map(__x, makePrelovedItem);
            });
}

export {
  makePrelovedItemId ,
  makePrelovedItem ,
  fetchPrelovedItem ,
}
/* MarkotApi__Id Not a pure module */
