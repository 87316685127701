/* TypeScript file generated from Ui__Toast__Error.resi by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as Ui__Toast__ErrorBS__Es6Import from './Ui__Toast__Error.bs';
const Ui__Toast__ErrorBS: any = Ui__Toast__ErrorBS__Es6Import;

import type {Style_t as ReactNative_Style_t} from '../../../../../../rescript-shims/ReactNative.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly wrapperStyle?: ReactNative_Style_t; 
  readonly description: string; 
  readonly autoDismiss?: boolean; 
  readonly title: string; 
  readonly onDismiss: () => void
};

export const make: React.ComponentType<{
  readonly wrapperStyle?: ReactNative_Style_t; 
  readonly description: string; 
  readonly autoDismiss?: boolean; 
  readonly title: string; 
  readonly onDismiss: () => void
}> = Ui__Toast__ErrorBS.make;
