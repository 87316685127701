import {
  BottomSheetModal as OriginalBottomSheetModal,
  BottomSheetScrollView as OriginalBottomSheetScrollView,
  BottomSheetFlatList as OriginalBottomSheetFlatList,
  BottomSheetModalProvider as OriginalBottomSheetModalProvider,
  useBottomSheetDynamicSnapPoints as useBottomSheetDynamicSnapPointsOriginal,
  BottomSheetView as OriginalBottomSheetView,
} from "@gorhom/bottom-sheet";

export const BottomSheetModal = OriginalBottomSheetModal;
export const BottomSheetScrollView = OriginalBottomSheetScrollView;
export const BottomSheetFlatList = OriginalBottomSheetFlatList;
export const BottomSheetModalProvider = OriginalBottomSheetModalProvider;
export const useBottomSheetDynamicSnapPoints =
  useBottomSheetDynamicSnapPointsOriginal;
export const BottomSheetView = OriginalBottomSheetView;
