/* TypeScript file generated from App__FakeData.resi by genType. */
/* eslint-disable import/first */


// @ts-ignore: Implicit any on import
import * as App__FakeDataBS__Es6Import from './App__FakeData.bs';
const App__FakeDataBS: any = App__FakeDataBS__Es6Import;

export const seed: <a>(_1:{ readonly environment: a }) => void = function <a>(Arg1: any) {
  const result = App__FakeDataBS.seed(Arg1.environment);
  return result
};
