// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils__NetworkUtils from "../../../utils/src/network/Utils__NetworkUtils.bs.js";
import RuntimeConfigJson from "./runtime-config.json";
import * as Ignored$DecidrIoUtils from "@decidr-io/utils/src/functions/Ignored.bs.js";

var values = RuntimeConfigJson;

function fetchConfig(onError) {
  var defaultHeaders = [
    [
      "content-type",
      "application/json"
    ],
    [
      "accept",
      "application/json"
    ]
  ];
  var extraHeaders = [[
      "x-api-key",
      values.remoteConfigApiKey
    ]];
  return fetch(values.remoteConfigUrl, Fetch.RequestInit.make(/* Get */0, Caml_option.some(Js_dict.fromArray(Belt_Option.getWithDefault(Belt_Option.map(extraHeaders, (function (extraHeaders) {
                                        return Belt_Array.concat(defaultHeaders, extraHeaders);
                                      })), defaultHeaders))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (resp) {
              if (resp.ok) {
                return Fetch.$$Response.json(resp).then(function (json_) {
                            return Promise.resolve(json_);
                          });
              } else {
                Ignored$DecidrIoUtils.promise(Fetch.$$Response.json(resp).then(function (__x) {
                          console.log("[NetworkConfig] Error loading config ", __x);
                        }));
                return Curry._1(onError, undefined);
              }
            });
}

function getConfigFromNetwork(onError) {
  return Utils__NetworkUtils.retry((function (param) {
                return fetchConfig(function (param) {
                            return Promise.resolve(Curry._1(onError, undefined));
                          });
              }), (function (param) {
                console.log("Trying again...");
              }), (function (_e) {
                return Curry._1(onError, undefined);
              }), 2, undefined, undefined);
}

var configError = "Failed to get app config from the network after multiple retries.";

export {
  values ,
  configError ,
  fetchConfig ,
  getConfigFromNetwork ,
}
/* values Not a pure module */
