// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ShopifyApi__Id from "../../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as GraphqlCore__Types from "../../../graphql-core/src/GraphqlCore__Types.bs.js";
import * as ShopifyApi__Brands from "../../../api-adapter-shopify/src/ShopifyApi__Brands.bs.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Core__FromShopify from "../core/GqlMiddleware__Core__FromShopify.bs.js";

function makeBrandId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "Brand", param);
}

function makeBrand(param) {
  return {
          __typename: "Brand",
          id: GqlMiddleware__Core__NodeId.make(1, "Brand", ShopifyApi__Id.toString(param.id)),
          name: param.name,
          description: param.description,
          slug: param.handle,
          image: Belt_Option.flatMap(param.image, GqlMiddleware__Core__FromShopify.tryMakeImageResource),
          seo: Belt_Option.map(param.seo, (function (param) {
                  return {
                          description: param.description,
                          title: param.title
                        };
                }))
        };
}

function fetchBrand(apiConfig, id) {
  return ShopifyApi__Brands.fetchBrand(apiConfig, ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(id).internalId)).then(function (brand) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(brand, (function (param) {
                                RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "Brand not found. Id:", id);
                              })), makeBrand);
            });
}

function fetchBrands(apiConfig, first, after, param) {
  return ShopifyApi__Brands.fetchBrands(apiConfig, first, after, undefined, undefined).then(function (__x) {
              return GraphqlCore__Types.Connection.map(__x, makeBrand);
            });
}

function fetchBrandsByIds(apiConfig, ids) {
  return ShopifyApi__Brands.fetchBrandsByIds(apiConfig, Belt_Array.map(ids, (function (id) {
                      return ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(id).internalId);
                    }))).then(function (__x) {
              return Belt_Array.map(__x, (function (__x) {
                            return Belt_Option.map(__x, makeBrand);
                          }));
            });
}

function fetchCategoryBrands(apiConfig, categoryId, first, param) {
  return ShopifyApi__Brands.fetchCategoryBrands(apiConfig, ShopifyApi__Id.make("Collection", GqlMiddleware__Core__NodeId.unpackExn(categoryId).internalId), first, undefined).then(function (__x) {
              return Belt_Option.map(__x, (function (__x) {
                            return GraphqlCore__Types.Connection.map(__x, makeBrand);
                          }));
            });
}

export {
  makeBrandId ,
  makeBrand ,
  fetchBrand ,
  fetchBrands ,
  fetchBrandsByIds ,
  fetchCategoryBrands ,
}
/* ShopifyApi__Id Not a pure module */
