// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Base64 from "@decidr-io/utils/src/base64";

function parseTypename(str) {
  switch (str) {
    case "" :
        return {
                TAG: /* Error */1,
                _0: "Empty typename provided"
              };
    case "Address" :
        return {
                TAG: /* Ok */0,
                _0: "Address"
              };
    case "Attribute" :
        return {
                TAG: /* Ok */0,
                _0: "Attribute"
              };
    case "Auction" :
        return {
                TAG: /* Ok */0,
                _0: "Auction"
              };
    case "Bag" :
        return {
                TAG: /* Ok */0,
                _0: "Bag"
              };
    case "BagItem" :
        return {
                TAG: /* Ok */0,
                _0: "BagItem"
              };
    case "Bid" :
        return {
                TAG: /* Ok */0,
                _0: "Bid"
              };
    case "BidInfo" :
        return {
                TAG: /* Ok */0,
                _0: "BidInfo"
              };
    case "BlogPost" :
        return {
                TAG: /* Ok */0,
                _0: "BlogPost"
              };
    case "Brand" :
        return {
                TAG: /* Ok */0,
                _0: "Brand"
              };
    case "Category" :
        return {
                TAG: /* Ok */0,
                _0: "Category"
              };
    case "DraftListing" :
        return {
                TAG: /* Ok */0,
                _0: "DraftListing"
              };
    case "Influencer" :
        return {
                TAG: /* Ok */0,
                _0: "Influencer"
              };
    case "InfluencerBundle" :
        return {
                TAG: /* Ok */0,
                _0: "InfluencerBundle"
              };
    case "ItemLive" :
        return {
                TAG: /* Ok */0,
                _0: "ItemLive"
              };
    case "ItemNotPublished" :
        return {
                TAG: /* Ok */0,
                _0: "ItemNotPublished"
              };
    case "ItemSold" :
        return {
                TAG: /* Ok */0,
                _0: "ItemSold"
              };
    case "Listing" :
        return {
                TAG: /* Ok */0,
                _0: "Listing"
              };
    case "ListingAndHandlingFee" :
        return {
                TAG: /* Ok */0,
                _0: "ListingAndHandlingFee"
              };
    case "MarkotBundle" :
        return {
                TAG: /* Ok */0,
                _0: "MarkotBundle"
              };
    case "MarkotUser" :
        return {
                TAG: /* Ok */0,
                _0: "MarkotUser"
              };
    case "NewOrder" :
        return {
                TAG: /* Ok */0,
                _0: "NewOrder"
              };
    case "PaymentMethodDetails" :
        return {
                TAG: /* Ok */0,
                _0: "PaymentMethodDetails"
              };
    case "PhoneNumber" :
        return {
                TAG: /* Ok */0,
                _0: "PhoneNumber"
              };
    case "PrelovedItem" :
        return {
                TAG: /* Ok */0,
                _0: "PrelovedItem"
              };
    case "PrelovedItemInfo" :
        return {
                TAG: /* Ok */0,
                _0: "PrelovedItemInfo"
              };
    case "PrelovedItemMedia" :
        return {
                TAG: /* Ok */0,
                _0: "PrelovedItemMedia"
              };
    case "PrelovedOrder" :
        return {
                TAG: /* Ok */0,
                _0: "PrelovedOrder"
              };
    case "PrelovedOrderLineItemCustom" :
        return {
                TAG: /* Ok */0,
                _0: "PrelovedOrderLineItemCustom"
              };
    case "PrelovedOrderLineItemPrelovedItem" :
        return {
                TAG: /* Ok */0,
                _0: "PrelovedOrderLineItemPrelovedItem"
              };
    case "PriceChangedEvent" :
        return {
                TAG: /* Ok */0,
                _0: "PriceChangedEvent"
              };
    case "Product" :
        return {
                TAG: /* Ok */0,
                _0: "Product"
              };
    case "ProductCollection" :
        return {
                TAG: /* Ok */0,
                _0: "ProductCollection"
              };
    case "ProductSnippet" :
        return {
                TAG: /* Ok */0,
                _0: "ProductSnippet"
              };
    case "ProductVariant" :
        return {
                TAG: /* Ok */0,
                _0: "ProductVariant"
              };
    case "ProductVariantSearchResult" :
        return {
                TAG: /* Ok */0,
                _0: "ProductVariantSearchResult"
              };
    case "Review" :
        return {
                TAG: /* Ok */0,
                _0: "Review"
              };
    case "SellUsingAuction" :
        return {
                TAG: /* Ok */0,
                _0: "SellUsingAuction"
              };
    case "SellUsingAuctionSetting" :
        return {
                TAG: /* Ok */0,
                _0: "SellUsingAuctionSetting"
              };
    case "SellUsingFixedPrice" :
        return {
                TAG: /* Ok */0,
                _0: "SellUsingFixedPrice"
              };
    case "SellUsingFixedPriceSetting" :
        return {
                TAG: /* Ok */0,
                _0: "SellUsingFixedPriceSetting"
              };
    case "SellerRatingFee" :
        return {
                TAG: /* Ok */0,
                _0: "SellerRatingFee"
              };
    case "Subcategory" :
        return {
                TAG: /* Ok */0,
                _0: "Subcategory"
              };
    case "Wallet" :
        return {
                TAG: /* Ok */0,
                _0: "Wallet"
              };
    default:
      return {
              TAG: /* Error */1,
              _0: "Unknown typename provided: \"" + str + "\""
            };
  }
}

function makeCompositeString(version, typename, internalId) {
  return "" + version.toString() + ";" + typename + ";" + internalId + "";
}

function make(version, typename, internalId) {
  return Base64.btoa(makeCompositeString(version, typename, internalId));
}

function unpack(nodeId) {
  var match = Base64.atob(nodeId).split(";");
  var len = match.length;
  if (len >= 4) {
    return {
            TAG: /* Error */1,
            _0: "Could not unpack nodeId (too many components found): " + nodeId
          };
  }
  switch (len) {
    case 0 :
    case 1 :
    case 2 :
        return {
                TAG: /* Error */1,
                _0: "Could not unpack nodeId (too few components found): " + nodeId
              };
    case 3 :
        var match$1 = match[0];
        if (match$1 !== "1") {
          return {
                  TAG: /* Error */1,
                  _0: "Could not unpack nodeId (too many components found): " + nodeId
                };
        }
        var typenameStr = match[1];
        var internalId = match[2];
        return Belt_Result.map(parseTypename(typenameStr), (function (typename) {
                      return {
                              version: 1,
                              typename: typename,
                              internalId: internalId
                            };
                    }));
    
  }
}

function unpackExn(id) {
  var id$1 = unpack(id);
  if (id$1.TAG === /* Ok */0) {
    return id$1._0;
  } else {
    return Js_exn.raiseError(id$1._0);
  }
}

function parse(str) {
  return Belt_Result.map(unpack(str), (function (param) {
                return str;
              }));
}

function parseExn(str) {
  unpackExn(str);
  return str;
}

function toString(prim) {
  return prim;
}

export {
  make ,
  toString ,
  parse ,
  parseExn ,
  unpack ,
  unpackExn ,
}
/* @decidr-io/utils/src/base64 Not a pure module */
