// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ShopifyApi__Infra from "./ShopifyApi__Infra.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";

var regex = /^\s*([^\[]+)\s*\[\s*([^\]]+)\s*]\s*$/;

function peek(value) {
  var match = Belt_Option.map(Belt_Option.map(Caml_option.null_to_opt(regex.exec(value)), (function (prim) {
              return prim;
            })), (function (__x) {
          return Belt_Array.map(__x, (function (prim) {
                        if (prim == null) {
                          return ;
                        } else {
                          return Caml_option.some(prim);
                        }
                      }));
        }));
  if (match !== undefined) {
    if (match.length === 3) {
      var title = match[1];
      if (title !== undefined) {
        var tag = match[2];
        if (tag !== undefined) {
          return {
                  title: title,
                  tag: tag
                };
        } else {
          RescriptUtils__Logger.info(ShopifyApi__Infra.logger, "TitleWithMetadata - no tag found in title: \"" + value + "\"");
          return {
                  title: title,
                  tag: undefined
                };
        }
      }
      
    }
    
  } else {
    RescriptUtils__Logger.info(ShopifyApi__Infra.logger, "TitleWithMetadata - wrong title format. Returning title as-is: \"" + value + "\"");
    return {
            title: value.trim(),
            tag: undefined
          };
  }
  RescriptUtils__Logger.info(ShopifyApi__Infra.logger, "TitleWithMetadata - unexpected format. Returning title as-is: \"" + value + "\"");
  return {
          title: value.trim(),
          tag: undefined
        };
}

function toTitle(value) {
  return peek(value).title.trim();
}

function rawText(prim) {
  return prim;
}

function serialize(prim) {
  return prim;
}

function parse(prim) {
  return prim;
}

export {
  rawText ,
  toTitle ,
  peek ,
  serialize ,
  parse ,
}
/* ShopifyApi__Infra Not a pure module */
