// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../../ui/src/group/components/Ui__Group.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as App__Report from "../../utils/App__Report.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotIcon from "../../../../ui/src/icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__MarkotText from "../../../../ui/src/text/components/Ui__MarkotText.bs.js";
import * as Ui__ErrorDisplay from "../../../../ui/src/error/errorDisplay/components/Ui__ErrorDisplay.bs.js";
import * as Ui__Toast__Error from "../../../../ui/src/toast/components/error/Ui__Toast__Error.bs.js";
import * as App__AddressEntity from "../../address/App__AddressEntity.bs.js";
import * as Ui__Toast__Loading from "../../../../ui/src/toast/components/loading/Ui__Toast__Loading.bs.js";
import * as App__Address__Snippet from "../../address/components/snippet/App__Address__Snippet.bs.js";
import * as Ui__SelectableWrapper from "../../../../ui/src/selectableWrapper/Ui__SelectableWrapper.bs.js";
import * as App__DeleteAddressHook from "../../address/components/deleteAddressHook/App__DeleteAddressHook.bs.js";
import * as App__UpdateAddressModal from "../../address/components/addressFormModal/App__UpdateAddressModal.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as Dialog$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/dialog/Dialog.bs.js";
import * as Reloader$DecidrIoReactNativeHelpers from "@decidr-io/react-native-helpers/src/reloader/Reloader.bs.js";
import * as RnUtils$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/utils/RnUtils.bs.js";
import * as App__MarkotUser__AddressPickerFragment from "./App__MarkotUser__AddressPickerFragment.bs.js";

function App__MarkotUser__AddressPicker$EmptyState(Props) {
  var title = Props.title;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  return React.createElement(Ui__MarkotText.make, {
              children: title,
              style: {
                textAlign: "center",
                backgroundColor: theme.colors.primaryShade3,
                borderRadius: theme.roundness._2,
                padding: theme.spacing._6
              }
            });
}

var EmptyState = {
  make: App__MarkotUser__AddressPicker$EmptyState
};

var match = Ui__Styling.describe({
      controls: {
        TAG: /* Dual */2,
        _0: {
          alignSelf: "flex-start",
          position: "absolute"
        },
        _1: (function (theme) {
            return {
                    right: -theme.spacing._3,
                    top: -theme.spacing._3
                  };
          })
      },
      button: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.primary,
                    borderRadius: theme.roundness._1,
                    padding: theme.spacing._3
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function App__MarkotUser__AddressPicker(Props) {
  var markotUser = Props.markotUser;
  var selectedAddressId = Props.selectedAddressId;
  var onSelect = Props.onSelect;
  var spacing = Props.spacing;
  var containerStyle = Props.containerStyle;
  var selectableContainerStyle = Props.selectableContainerStyle;
  var emptyStateElement = Props.emptyStateElement;
  var footerElementOpt = Props.footerElement;
  var shouldSelectFirstOpt = Props.shouldSelectFirst;
  var selectedStyle = Props.selectedStyle;
  var canEditAddressOpt = Props.canEditAddress;
  var canDeleteAddressOpt = Props.canDeleteAddress;
  var determineIfAddressCanBeRemoved = Props.determineIfAddressCanBeRemoved;
  var testIDOpt = Props.testID;
  var footerElement = footerElementOpt !== undefined ? Caml_option.valFromOption(footerElementOpt) : null;
  var shouldSelectFirst = shouldSelectFirstOpt !== undefined ? shouldSelectFirstOpt : true;
  var canEditAddress = canEditAddressOpt !== undefined ? canEditAddressOpt : false;
  var canDeleteAddress = canDeleteAddressOpt !== undefined ? canDeleteAddressOpt : false;
  var testID = testIDOpt !== undefined ? testIDOpt : "markot-user-address-picker";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = App__MarkotUser__AddressPickerFragment.usePagination(markotUser);
  var result = match$1.data;
  var match$2 = React.useState(function () {
        
      });
  var setEditingAddressId = match$2[1];
  var editingAddressId = match$2[0];
  var match$3 = App__DeleteAddressHook.use(undefined);
  var deleteUserAddress = match$3[0];
  var match$4 = React.useState(function () {
        
      });
  var setError = match$4[1];
  var maybeData = result.addresses;
  var data = Belt_Option.map(maybeData, App__MarkotUser__AddressPickerFragment.getConnectionNodes);
  React.useEffect((function () {
          if (data !== undefined && shouldSelectFirst && data.length === 1) {
            var address = data[0];
            Curry._1(onSelect, address.id);
          }
          
        }), [Belt_Option.getWithDefault(data, []).length]);
  if (data !== undefined) {
    if (data.length === 0) {
      return Belt_Option.getWithDefault(emptyStateElement, React.createElement(App__MarkotUser__AddressPicker$EmptyState, {
                      title: "Address list is empty!"
                    }));
    }
    var tmp = {
      children: null,
      testID: testID
    };
    if (spacing !== undefined) {
      tmp.spacing = spacing;
    }
    if (containerStyle !== undefined) {
      tmp.containerStyle = Caml_option.valFromOption(containerStyle);
    }
    return React.createElement(Ui__Group.make, tmp, Belt_Array.map(data, (function (param) {
                      var id = param.id;
                      var selected = Belt_Option.mapWithDefault(selectedAddressId, false, (function (selectedAddressId) {
                              return selectedAddressId === id;
                            }));
                      return React.createElement(ReactNative.View, {
                                  testID: "address-container",
                                  children: null,
                                  key: id
                                }, React.createElement(Ui__SelectableWrapper.make, {
                                      selected: selected,
                                      onPress: (function (param) {
                                          Curry._1(onSelect, id);
                                        }),
                                      children: React.createElement(App__Address__Snippet.make, {
                                            address: Caml_option.some(param.fragmentRefs),
                                            style: {
                                              flex: 1
                                            }
                                          }),
                                      containerStyle: [
                                        selectableContainerStyle,
                                        selected ? selectedStyle : undefined
                                      ],
                                      testID: "address-selectable-wrapper"
                                    }), React.createElement(Ui__Group.make, {
                                      spacing: theme.spacing._4,
                                      children: null,
                                      containerStyle: Curry._1(styled, styles.controls),
                                      orientation: "horizontal"
                                    }, SimpleReact$DecidrIoUtils.whenTrue(canEditAddress, React.createElement(ReactNative.TouchableOpacity, {
                                              style: Curry._1(styled, styles.button),
                                              hitSlop: RnUtils$DecidrIoUniversalComponents.makeSymmetricHitSlop(theme.spacing._8),
                                              onPress: (function (param) {
                                                  Curry._1(setEditingAddressId, (function (param) {
                                                          return id;
                                                        }));
                                                }),
                                              testID: "edit-address-button",
                                              children: React.createElement(Ui__MarkotIcon.make, {
                                                    name: "edit",
                                                    size: 16,
                                                    color: theme.colors.onPrimary
                                                  })
                                            })), SimpleReact$DecidrIoUtils.whenTrue(canDeleteAddress, React.createElement(ReactNative.TouchableOpacity, {
                                              style: Curry._1(styled, styles.button),
                                              hitSlop: RnUtils$DecidrIoUniversalComponents.makeSymmetricHitSlop(theme.spacing._8),
                                              onPress: (function (param) {
                                                  if (Belt_Option.getWithDefault(Belt_Option.map(determineIfAddressCanBeRemoved, (function (fn) {
                                                                return Curry._1(fn, id);
                                                              })), false)) {
                                                    return Curry._7(deleteUserAddress, id, App__AddressEntity.collectAllRelayConnections(result.__id), undefined, undefined, undefined, (function (error) {
                                                                  Curry._1(setError, (function (param) {
                                                                          return error;
                                                                        }));
                                                                }), undefined);
                                                  } else {
                                                    return Dialog$DecidrIoUniversalComponents.tell("Address can't be removed", "This address is selected as a default address which is used for all sorts of things. To remove it, first choose a new default address.", undefined);
                                                  }
                                                }),
                                              testID: "delete-address-button",
                                              children: React.createElement(Ui__MarkotIcon.make, {
                                                    name: "x-mark",
                                                    size: 16,
                                                    color: theme.colors.onPrimary
                                                  })
                                            }))));
                    })), footerElement, SimpleReact$DecidrIoUtils.whenTrue(canEditAddress, React.createElement(App__UpdateAddressModal.make, {
                        viewerDataId: result.__id,
                        address: Belt_Option.map(Belt_Array.getBy(data, (function (param) {
                                    var id = param.id;
                                    return Belt_Option.mapWithDefault(editingAddressId, false, (function (editingAddressId) {
                                                  return editingAddressId === id;
                                                }));
                                  })), (function (param) {
                                return param.fragmentRefs;
                              })),
                        onClose: (function (param) {
                            Curry._1(setEditingAddressId, (function (param) {
                                    
                                  }));
                          }),
                        visible: Belt_Option.isSome(editingAddressId)
                      })), SimpleReact$DecidrIoUtils.whenTrue(canDeleteAddress && match$3[1], React.createElement(Ui__Toast__Loading.make, {})), SimpleReact$DecidrIoUtils.renderIfSome(match$4[0], (function (errorMessage) {
                      return React.createElement(Ui__Toast__Error.make, {
                                  title: "An error has occurred!",
                                  description: errorMessage,
                                  onDismiss: (function (param) {
                                      Curry._1(setError, (function (param) {
                                              
                                            }));
                                    })
                                });
                    })));
  }
  App__Report.unexpected("Didn't receive address data", "App__MarkotUser__AddressPicker");
  return React.createElement(Ui__ErrorDisplay.make, {
              title: "Error loading addresses",
              errorMessage: "Didn't receive address data",
              onReload: Reloader$DecidrIoReactNativeHelpers.reloadApp
            });
}

var make = App__MarkotUser__AddressPicker;

export {
  EmptyState ,
  make ,
}
/* match Not a pure module */
