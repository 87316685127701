// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactRelay from "react-relay";
import * as RelayRuntime from "relay-runtime";
import * as AppBagMakeCheckout_makeCheckoutWithoutCustomerAccessTokenMutation_graphql from "../../../../../relay-codegen/generated/AppBagMakeCheckout_makeCheckoutWithoutCustomerAccessTokenMutation_graphql.bs.js";

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: AppBagMakeCheckout_makeCheckoutWithoutCustomerAccessTokenMutation_graphql.node,
              variables: AppBagMakeCheckout_makeCheckoutWithoutCustomerAccessTokenMutation_graphql.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, AppBagMakeCheckout_makeCheckoutWithoutCustomerAccessTokenMutation_graphql.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? AppBagMakeCheckout_makeCheckoutWithoutCustomerAccessTokenMutation_graphql.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    Curry._2(updater, store, AppBagMakeCheckout_makeCheckoutWithoutCustomerAccessTokenMutation_graphql.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

function use(param) {
  var match = ReactRelay.useMutation(AppBagMakeCheckout_makeCheckoutWithoutCustomerAccessTokenMutation_graphql.node);
  var mutate = match[0];
  return [
          React.useMemo((function () {
                  return function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
                    return Curry._1(mutate, {
                                onError: param,
                                onCompleted: param$1 !== undefined ? (function (r, errors) {
                                      Curry._2(param$1, AppBagMakeCheckout_makeCheckoutWithoutCustomerAccessTokenMutation_graphql.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                                    }) : undefined,
                                onUnsubscribe: param$2,
                                optimisticResponse: param$3 !== undefined ? AppBagMakeCheckout_makeCheckoutWithoutCustomerAccessTokenMutation_graphql.Internal.convertWrapRawResponse(param$3) : undefined,
                                optimisticUpdater: param$4,
                                updater: param$5 !== undefined ? (function (store, r) {
                                      Curry._2(param$5, store, AppBagMakeCheckout_makeCheckoutWithoutCustomerAccessTokenMutation_graphql.Internal.convertResponse(r));
                                    }) : undefined,
                                variables: AppBagMakeCheckout_makeCheckoutWithoutCustomerAccessTokenMutation_graphql.Internal.convertVariables(param$6),
                                uploadables: param$7
                              });
                  };
                }), [mutate]),
          match[1]
        ];
}

var Operation;

var Types;

export {
  Operation ,
  Types ,
  commitMutation ,
  use ,
}
/* react Not a pure module */
