// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ShopifyApi__Id from "../../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as ShopifyApi__Categories from "../../../api-adapter-shopify/src/ShopifyApi__Categories.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Core__FromShopify from "../core/GqlMiddleware__Core__FromShopify.bs.js";

function makeCategoryId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "Category", param);
}

function makeSubcategoryId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "Subcategory", param);
}

function fetchCategories(apiConfig, id, param) {
  return ShopifyApi__Categories.Categories.$$fetch(apiConfig, id, undefined).then(function (result) {
              return {
                      pageInfo: result.pageInfo,
                      edges: Belt_Array.map(result.edges, (function (param) {
                              var node = param.node;
                              return {
                                      cursor: param.cursor,
                                      node: {
                                        __typename: "Category",
                                        id: GqlMiddleware__Core__NodeId.make(1, "Category", ShopifyApi__Id.unpackExn(node.id).internalId),
                                        name: node.name,
                                        slug: node.slug,
                                        description: node.description,
                                        image: Belt_Option.flatMap(node.image, GqlMiddleware__Core__FromShopify.tryMakeImageResource),
                                        coverImage: Belt_Option.flatMap(node.coverImage, GqlMiddleware__Core__FromShopify.tryMakeImageResource),
                                        icon: Belt_Option.flatMap(node.icon, GqlMiddleware__Core__FromShopify.tryMakeImageResource),
                                        prelovedItemsAccepted: node.prelovedItemsAccepted,
                                        containsNewProductsOnly: node.containsNewProductsOnly,
                                        subcategories: {
                                          pageInfo: node.subcategories.pageInfo,
                                          edges: Belt_Array.map(node.subcategories.edges, (function (param) {
                                                  var node = param.node;
                                                  return {
                                                          cursor: param.cursor,
                                                          node: {
                                                            __typename: "Subcategory",
                                                            id: GqlMiddleware__Core__NodeId.make(1, "Subcategory", ShopifyApi__Id.unpackExn(node.id).internalId),
                                                            name: node.name,
                                                            slug: node.slug,
                                                            prelovedItemsAccepted: node.prelovedItemsAccepted,
                                                            containsNewProductsOnly: node.containsNewProductsOnly,
                                                            seo: Belt_Option.map(node.seo, (function (param) {
                                                                    return {
                                                                            description: param.description,
                                                                            title: param.title
                                                                          };
                                                                  }))
                                                          }
                                                        };
                                                }))
                                        },
                                        seo: Belt_Option.map(node.seo, (function (param) {
                                                return {
                                                        description: param.description,
                                                        title: param.title
                                                      };
                                              }))
                                      }
                                    };
                            }))
                    };
            });
}

function fetchSubcategory(apiConfig, id, param) {
  return ShopifyApi__Categories.Categories.fetchSubcategory(apiConfig, id, undefined).then(function (result) {
              return Belt_Option.map(result, (function (result) {
                            return {
                                    __typename: "Subcategory",
                                    id: GqlMiddleware__Core__NodeId.make(1, "Subcategory", ShopifyApi__Id.unpackExn(result.id).internalId),
                                    name: result.name,
                                    slug: result.slug,
                                    prelovedItemsAccepted: result.prelovedItemsAccepted,
                                    containsNewProductsOnly: result.containsNewProductsOnly,
                                    seo: Belt_Option.map(result.seo, (function (param) {
                                            return {
                                                    description: param.description,
                                                    title: param.title
                                                  };
                                          }))
                                  };
                          }));
            });
}

export {
  makeCategoryId ,
  makeSubcategoryId ,
  fetchCategories ,
  fetchSubcategory ,
}
/* ShopifyApi__Id Not a pure module */
