import { Typography_variant } from "./components/Ui__Theme.gen";

export {
  themeProvider as ThemeProvider,
  useTheme,
  themeConfigSet,
} from "./components/Ui__Theme.gen";

export type {
  ThemeConfigSet,
  Config as ThemeConfig,
  Theme_t as Theme,
} from "./components/Ui__Theme.gen";

export const testOnly_makeSize: (
  size: number
) => Typography_variant = size => ({
  NAME: "unsafe",
  VAL: { size: size, lineHeight: size * 0.2, letterSpacingPct: 0 },
});
