// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ShopifyApi__Id from "../../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as ShopifyApi__ProductCollections from "../../../api-adapter-shopify/src/ShopifyApi__ProductCollections.bs.js";

function makeProductCollectionId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "ProductCollection", param);
}

function fetchProductCollectionByHandle(apiConfig, handle, logWarning) {
  return ShopifyApi__ProductCollections.fetchProductCollectionByHandle(apiConfig, handle, logWarning).then(function (productCollection) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(productCollection, (function (param) {
                                if (logWarning) {
                                  return RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "ProductCollection not found. Handle:", handle);
                                }
                                
                              })), (function (param) {
                            return {
                                    __typename: "ProductCollection",
                                    id: GqlMiddleware__Core__NodeId.make(1, "ProductCollection", ShopifyApi__Id.toString(param.id)),
                                    name: param.name,
                                    description: param.description,
                                    slug: param.slug
                                  };
                          }));
            });
}

export {
  makeProductCollectionId ,
  fetchProductCollectionByHandle ,
}
/* ShopifyApi__Id Not a pure module */
