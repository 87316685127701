// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";

var Raw = {};

function parse(value) {
  var value$1 = value.itemInWishlist;
  return {
          itemInWishlist: !(value$1 == null) ? value$1 : undefined
        };
}

function serialize(value) {
  var value$1 = value.itemInWishlist;
  var itemInWishlist = value$1 !== undefined ? value$1 : null;
  return {
          itemInWishlist: itemInWishlist
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

function inWishlist(apiConfig, id) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: "query InWishlist($id: ID!)  {\nitemInWishlist(itemId: $id)  \n}\n",
                    Raw: Raw,
                    parse: parse,
                    serialize: serialize,
                    serializeVariables: serializeVariables
                  }), {
                id: id
              }, undefined).then(function (param) {
              return param.itemInWishlist;
            });
}

export {
  inWishlist ,
}
/* MarkotApi__Client Not a pure module */
