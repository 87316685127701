// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ShopifyApi__Id from "../../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as ShopifyApi__ProductOptions from "../../../api-adapter-shopify/src/ShopifyApi__ProductOptions.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";

var AttributeValueId = {};

function makeAttributeValueId(optionId, value) {
  return "" + optionId + "_" + value + "";
}

function makeAttributeId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "Attribute", param);
}

function fetchProductAttributes(apiConfig, productId) {
  return ShopifyApi__ProductOptions.$$fetch(apiConfig, ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(productId).internalId)).then(function (productOptions) {
              return Belt_Option.map(productOptions, (function (productOptions) {
                            return Belt_Array.map(productOptions, (function (param) {
                                          var name = param.name;
                                          var id = param.id;
                                          var attributeId = GqlMiddleware__Core__NodeId.make(1, "Attribute", ShopifyApi__Id.toString(id));
                                          return {
                                                  __typename: "Attribute",
                                                  id: attributeId,
                                                  code: name,
                                                  name: name,
                                                  description: undefined,
                                                  possibleValues: Belt_Array.map(param.values, (function (value) {
                                                          return {
                                                                  id: makeAttributeValueId(ShopifyApi__Id.toString(id), value),
                                                                  image: undefined,
                                                                  value: value,
                                                                  attributeId: attributeId
                                                                };
                                                        })),
                                                  layoutCode: undefined
                                                };
                                        }));
                          }));
            });
}

export {
  AttributeValueId ,
  makeAttributeValueId ,
  makeAttributeId ,
  fetchProductAttributes ,
}
/* ShopifyApi__Id Not a pure module */
