// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Index from "./index";

function useAnalytics(prim) {
  return Index.useAnalytics();
}

export {
  useAnalytics ,
}
/* ./index Not a pure module */
