// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as ShopifyApi__Infra from "./ShopifyApi__Infra.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";

function parse(json) {
  var str = Js_json.decodeString(json);
  if (str !== undefined) {
    return str;
  } else {
    RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "Could not parse `DateTime` scalar value:", json);
    return Js_exn.raiseError("Could not parse `DateTime` scalar value");
  }
}

function serialize(datetime) {
  return datetime;
}

export {
  parse ,
  serialize ,
}
/* ShopifyApi__Infra Not a pure module */
