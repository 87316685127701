// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../group/components/Ui__Group.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../styling/Ui__Styling.bs.js";
import * as Ui__MarkotText from "../text/components/Ui__MarkotText.bs.js";
import * as Ui__OverstockedBadge from "../overstockedBadge/Ui__OverstockedBadge.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          alignSelf: "flex-start"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.secondaryVariant3,
                    borderRadius: theme.roundness._2
                  };
          })
      },
      text: {
        TAG: /* Static */0,
        _0: {
          color: "#805832"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__OverstockedTag(Props) {
  var textOpt = Props.text;
  var textStyle = Props.textStyle;
  var sizeOpt = Props.size;
  var style = Props.style;
  var textSize = Props.textSize;
  var testIDOpt = Props.testID;
  var symbolColorOpt = Props.symbolColor;
  var text = textOpt !== undefined ? textOpt : "Overstocked";
  var size = sizeOpt !== undefined ? sizeOpt : "small";
  var testID = testIDOpt !== undefined ? testIDOpt : "overstocked-tag";
  var symbolColor = symbolColorOpt !== undefined ? symbolColorOpt : "#95775B";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = size === "small" ? ({
        overstockedBadgeSize: 16,
        paddingHorizontal: theme.spacing._2,
        paddingVertical: theme.spacing._1,
        spacing: theme.spacing._2
      }) : ({
        overstockedBadgeSize: 20,
        paddingHorizontal: theme.spacing._4,
        paddingVertical: theme.spacing._2,
        spacing: theme.spacing._4
      });
  return React.createElement(Ui__Group.make, {
              spacing: match$1.spacing,
              children: null,
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles.container)),
                {
                  paddingHorizontal: match$1.paddingHorizontal,
                  paddingVertical: match$1.paddingVertical
                },
                style
              ],
              orientation: "horizontal",
              testID: testID
            }, React.createElement(Ui__OverstockedBadge.make, {
                  size: match$1.overstockedBadgeSize,
                  symbolColor: symbolColor
                }), React.createElement(Ui__MarkotText.make, {
                  size: Belt_Option.getWithDefault(textSize, "caption1"),
                  children: text,
                  style: [
                    Caml_option.some(Curry._1(styled, styles.text)),
                    textStyle
                  ]
                }));
}

var make = Ui__OverstockedTag;

export {
  make ,
}
/* match Not a pure module */
