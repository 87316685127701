// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as Ui__PressableText from "../../../../../ui/src/pressableText/components/Ui__PressableText.bs.js";
import * as App__AddAddressModal from "../addressFormModal/App__AddAddressModal.bs.js";

var match = Ui__Styling.describe({
      addAddressTitleStyle: {
        TAG: /* Dual */2,
        _0: {
          textDecorationLine: "underline"
        },
        _1: (function (theme) {
            return {
                    color: theme.colors.onBackground
                  };
          })
      },
      addAddressContainer: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center"
        },
        _1: (function (theme) {
            return {
                    paddingTop: theme.spacing._6
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function App__AddAddressButton(Props) {
  var markotUser = Props.markotUser;
  var titleOpt = Props.title;
  var onAddCompleted = Props.onAddCompleted;
  var buttonContainerStyle = Props.buttonContainerStyle;
  var buttonTextSizeOpt = Props.buttonTextSize;
  var addressType = Props.addressType;
  var title = titleOpt !== undefined ? titleOpt : "+ ADD ADDRESS";
  var buttonTextSize = buttonTextSizeOpt !== undefined ? buttonTextSizeOpt : "button1";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, match.theme);
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowFormModal = match$1[1];
  var onClose = function (param) {
    Curry._1(setShowFormModal, (function (param) {
            return false;
          }));
  };
  var tmp = {
    markotUser: markotUser,
    visible: match$1[0],
    onClose: onClose
  };
  if (onAddCompleted !== undefined) {
    tmp.onAddCompleted = Caml_option.valFromOption(onAddCompleted);
  }
  if (addressType !== undefined) {
    tmp.addressType = Caml_option.valFromOption(addressType);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Ui__PressableText.make, {
                  title: title,
                  behavior: {
                    NAME: "Button",
                    VAL: (function (param) {
                        Curry._1(setShowFormModal, (function (param) {
                                return true;
                              }));
                      })
                  },
                  containerStyle: [
                    Caml_option.some(Curry._1(styled, styles.addAddressContainer)),
                    buttonContainerStyle
                  ],
                  titleStyle: Curry._1(styled, styles.addAddressTitleStyle),
                  textSize: buttonTextSize
                }), React.createElement(React.Suspense, {
                  children: React.createElement(App__AddAddressModal.make, tmp),
                  fallback: null
                }));
}

var make = App__AddAddressButton;

export {
  styles ,
  stylesheet ,
  make ,
}
/* match Not a pure module */
