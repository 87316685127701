// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MarkotApi__Id from "../../../api-adapter-markot/src/MarkotApi__Id.bs.js";
import * as MarkotApi__Checkout from "../../../api-adapter-markot/src/MarkotApi__Checkout.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";

function executeMakeCheckout(apiConfig, bagId, customerAccessToken) {
  return MarkotApi__Checkout.executeMakeCheckout(apiConfig, MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(bagId)), customerAccessToken).then(function (param) {
              return {
                      checkoutUrl: param.checkoutUrl
                    };
            });
}

export {
  executeMakeCheckout ,
}
/* MarkotApi__Id Not a pure module */
