// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Ui__NextLink from "./Ui__NextLink";

var make = Ui__NextLink.NextLink;

export {
  make ,
}
/* make Not a pure module */
