// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as MarkotApi__Bid from "./MarkotApi__Bid.bs.js";
import * as MarkotApi__Infra from "./MarkotApi__Infra.bs.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as MarkotApi__Scalars__DateTime from "./MarkotApi__Scalars__DateTime.bs.js";

var Raw = {};

var query = "fragment BidInfoFields on BidInfo   {\nid  \nbid  {\n...BidFields   \n}\n\nstatus  \nbidderHasActiveBidsOnSimilarItems  \nstatusLastUpdatedAt  \n}\n" + MarkotApi__Bid.BidFields.query;

function parse(value) {
  var value$1 = value.bid;
  var value$2 = value.status;
  var tmp;
  switch (value$2) {
    case "Accepted" :
        tmp = "Accepted";
        break;
    case "Active" :
        tmp = "Active";
        break;
    case "ActiveHighest" :
        tmp = "ActiveHighest";
        break;
    case "Cancelled" :
        tmp = "Cancelled";
        break;
    case "Deactivated" :
        tmp = "Deactivated";
        break;
    case "Declined" :
        tmp = "Declined";
        break;
    case "Expired" :
        tmp = "Expired";
        break;
    case "ListingDeleted" :
        tmp = "ListingDeleted";
        break;
    case "Lost" :
        tmp = "Lost";
        break;
    case "PaymentFailed" :
        tmp = "PaymentFailed";
        break;
    case "Removed" :
        tmp = "Removed";
        break;
    case "Superseded" :
        tmp = "Superseded";
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$2
      };
  }
  var value$3 = value.bidderHasActiveBidsOnSimilarItems;
  var value$4 = value.statusLastUpdatedAt;
  return {
          id: value.id,
          bid: MarkotApi__Bid.BidFields.verifyArgsAndParse("BidFields", value$1),
          status: tmp,
          bidderHasActiveBidsOnSimilarItems: !(value$3 == null) ? value$3 : undefined,
          statusLastUpdatedAt: !(value$4 == null) ? MarkotApi__Scalars__DateTime.parse(value$4) : undefined
        };
}

function serialize(value) {
  var value$1 = value.statusLastUpdatedAt;
  var statusLastUpdatedAt = value$1 !== undefined ? MarkotApi__Scalars__DateTime.serialize(value$1) : null;
  var value$2 = value.bidderHasActiveBidsOnSimilarItems;
  var bidderHasActiveBidsOnSimilarItems = value$2 !== undefined ? value$2 : null;
  var value$3 = value.status;
  var status = typeof value$3 === "object" ? value$3.VAL : (
      value$3 === "Cancelled" ? "Cancelled" : (
          value$3 === "Active" ? "Active" : (
              value$3 === "Expired" ? "Expired" : (
                  value$3 === "ActiveHighest" ? "ActiveHighest" : (
                      value$3 === "Accepted" ? "Accepted" : (
                          value$3 === "Declined" ? "Declined" : (
                              value$3 === "Deactivated" ? "Deactivated" : (
                                  value$3 === "Lost" ? "Lost" : (
                                      value$3 === "ListingDeleted" ? "ListingDeleted" : (
                                          value$3 === "Superseded" ? "Superseded" : (
                                              value$3 === "PaymentFailed" ? "PaymentFailed" : "Removed"
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    );
  var value$4 = value.bid;
  var bid = MarkotApi__Bid.BidFields.serialize(value$4);
  var value$5 = value.id;
  return {
          id: value$5,
          bid: bid,
          status: status,
          bidderHasActiveBidsOnSimilarItems: bidderHasActiveBidsOnSimilarItems,
          statusLastUpdatedAt: statusLastUpdatedAt
        };
}

function verifyArgsAndParse(_BidInfoFields, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var Raw$1 = {};

var query$1 = "query ViewerBids($bidGroup: BidGroup!, $first: Int, $after: String)  {\nviewer  {\nbids(bidGroup: $bidGroup, first: $first, after: $after)  {\ntotalItems  \npageInfo  {\nstartCursor  \nendCursor  \nhasPreviousPage  \nhasNextPage  \n}\n\nedges  {\ncursor  \nnode  {\n...BidInfoFields   \n}\n\n}\n\n}\n\n}\n\n}\n" + query;

function parse$1(value) {
  var value$1 = value.viewer;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.bids;
    var value$3 = value$2.pageInfo;
    var value$4 = value$3.startCursor;
    var value$5 = value$3.endCursor;
    var value$6 = value$2.edges;
    tmp = {
      bids: {
        totalItems: value$2.totalItems,
        pageInfo: {
          startCursor: !(value$4 == null) ? value$4 : undefined,
          endCursor: !(value$5 == null) ? value$5 : undefined,
          hasPreviousPage: value$3.hasPreviousPage,
          hasNextPage: value$3.hasNextPage
        },
        edges: Js_array.map((function (value) {
                return {
                        cursor: value.cursor,
                        node: parse(value.node)
                      };
              }), value$6)
      }
    };
  }
  return {
          viewer: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.viewer;
  var viewer;
  if (value$1 !== undefined) {
    var value$2 = value$1.bids;
    var value$3 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var node = serialize(value$1);
            var value$2 = value.cursor;
            return {
                    cursor: value$2,
                    node: node
                  };
          }), value$3);
    var value$4 = value$2.pageInfo;
    var value$5 = value$4.hasNextPage;
    var value$6 = value$4.hasPreviousPage;
    var value$7 = value$4.endCursor;
    var endCursor = value$7 !== undefined ? value$7 : null;
    var value$8 = value$4.startCursor;
    var startCursor = value$8 !== undefined ? value$8 : null;
    var pageInfo = {
      startCursor: startCursor,
      endCursor: endCursor,
      hasPreviousPage: value$6,
      hasNextPage: value$5
    };
    var value$9 = value$2.totalItems;
    var bids = {
      totalItems: value$9,
      pageInfo: pageInfo,
      edges: edges
    };
    viewer = {
      bids: bids
    };
  } else {
    viewer = null;
  }
  return {
          viewer: viewer
        };
}

function serializeVariables(inp) {
  var a = inp.bidGroup;
  var a$1 = inp.first;
  var a$2 = inp.after;
  return {
          bidGroup: a === "Active" ? "Active" : (
              a === "RecentlyWon" ? "RecentlyWon" : "Other"
            ),
          first: a$1 !== undefined ? a$1 : undefined,
          after: a$2 !== undefined ? a$2 : undefined
        };
}

var Raw$2 = {};

var query$2 = "mutation AcceptBid($input: AcceptBidInfoInput!)  {\nacceptBidInfo(input: $input)  {\nbidInfo  {\n...BidInfoFields   \n}\n\n}\n\n}\n" + query;

function parse$2(value) {
  var value$1 = value.acceptBidInfo;
  return {
          acceptBidInfo: {
            bidInfo: parse(value$1.bidInfo)
          }
        };
}

function serialize$2(value) {
  var value$1 = value.acceptBidInfo;
  var value$2 = value$1.bidInfo;
  var bidInfo = serialize(value$2);
  var acceptBidInfo = {
    bidInfo: bidInfo
  };
  return {
          acceptBidInfo: acceptBidInfo
        };
}

function serializeInputObjectAcceptBidInfoInput(inp) {
  return {
          id: inp.id
        };
}

function serializeVariables$1(inp) {
  return {
          input: serializeInputObjectAcceptBidInfoInput(inp.input)
        };
}

var Raw$3 = {};

var query$3 = "mutation DeclineBid($input: DeclineBidInfoInput!)  {\ndeclineBidInfo(input: $input)  {\nbidInfo  {\n...BidInfoFields   \n}\n\n}\n\n}\n" + query;

function parse$3(value) {
  var value$1 = value.declineBidInfo;
  return {
          declineBidInfo: {
            bidInfo: parse(value$1.bidInfo)
          }
        };
}

function serialize$3(value) {
  var value$1 = value.declineBidInfo;
  var value$2 = value$1.bidInfo;
  var bidInfo = serialize(value$2);
  var declineBidInfo = {
    bidInfo: bidInfo
  };
  return {
          declineBidInfo: declineBidInfo
        };
}

function serializeInputObjectDeclineBidInfoInput(inp) {
  return {
          id: inp.id
        };
}

function serializeVariables$2(inp) {
  return {
          input: serializeInputObjectDeclineBidInfoInput(inp.input)
        };
}

var Raw$4 = {};

var query$4 = "mutation CancelBid($input: CancelBidInfoInput!)  {\ncancelBidInfo(input: $input)  {\nbidInfo  {\n...BidInfoFields   \n}\n\n}\n\n}\n" + query;

function parse$4(value) {
  var value$1 = value.cancelBidInfo;
  return {
          cancelBidInfo: {
            bidInfo: parse(value$1.bidInfo)
          }
        };
}

function serialize$4(value) {
  var value$1 = value.cancelBidInfo;
  var value$2 = value$1.bidInfo;
  var bidInfo = serialize(value$2);
  var cancelBidInfo = {
    bidInfo: bidInfo
  };
  return {
          cancelBidInfo: cancelBidInfo
        };
}

function serializeInputObjectCancelBidInfoInput(inp) {
  return {
          id: inp.id
        };
}

function serializeVariables$3(inp) {
  return {
          input: serializeInputObjectCancelBidInfoInput(inp.input)
        };
}

var Raw$5 = {};

var query$5 = "mutation PostBid($input: PostBidInput!)  {\npostBid(input: $input)  {\nprelovedItemInfoId  \nbidInfo  {\n...BidInfoFields   \n}\n\n}\n\n}\n" + query;

function parse$5(value) {
  var value$1 = value.postBid;
  return {
          postBid: {
            prelovedItemInfoId: value$1.prelovedItemInfoId,
            bidInfo: parse(value$1.bidInfo)
          }
        };
}

function serialize$5(value) {
  var value$1 = value.postBid;
  var value$2 = value$1.bidInfo;
  var bidInfo = serialize(value$2);
  var value$3 = value$1.prelovedItemInfoId;
  var postBid = {
    prelovedItemInfoId: value$3,
    bidInfo: bidInfo
  };
  return {
          postBid: postBid
        };
}

function serializeInputObjectPostBidInput(inp) {
  return {
          prelovedItemInfoId: inp.prelovedItemInfoId,
          amount: inp.amount
        };
}

function serializeVariables$4(inp) {
  return {
          input: serializeInputObjectPostBidInput(inp.input)
        };
}

function makeBidInfo(param) {
  var status = param.status;
  return {
          id: MarkotApi__Id.parseExn(param.id),
          bid: MarkotApi__Bid.makeBid(param.bid),
          status: typeof status === "object" ? (RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "Auction makeBidInfo - status unknown typename", status), Js_exn.raiseError("status unknown typename.")) : (
              status === "Cancelled" ? "Cancelled" : (
                  status === "Active" ? "Active" : (
                      status === "Expired" ? "Expired" : (
                          status === "ActiveHighest" ? "ActiveHighest" : (
                              status === "Accepted" ? "Accepted" : (
                                  status === "Declined" ? "Declined" : (
                                      status === "Deactivated" ? "Deactivated" : (
                                          status === "Lost" ? "Lost" : (
                                              status === "ListingDeleted" ? "ListingDeleted" : (
                                                  status === "Superseded" ? "Superseded" : (
                                                      status === "PaymentFailed" ? "PaymentFailed" : "Removed"
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
          bidderHasActiveBidsOnSimilarItems: param.bidderHasActiveBidsOnSimilarItems,
          statusLastUpdatedAt: param.statusLastUpdatedAt
        };
}

function fetchBids(apiConfig, first, after, bidGroup) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                        query: query$1,
                        Raw: Raw$1,
                        parse: parse$1,
                        serialize: serialize$1,
                        serializeVariables: serializeVariables
                      }), {
                    bidGroup: bidGroup,
                    first: first,
                    after: after
                  }, undefined).then(function (param) {
                  return param.viewer;
                }).then(function (__x) {
                return RescriptUtils__OptionPlus.tapNone(__x, (function (param) {
                              RescriptUtils__Logger.warn(MarkotApi__Infra.logger, "BidInfo - fetchBids: `viewer` not returned");
                            }));
              }).then(function (__x) {
              return Belt_Option.map(__x, (function (param) {
                            var match = param.bids;
                            var pageInfo = match.pageInfo;
                            return {
                                    totalItems: match.totalItems,
                                    pageInfo: {
                                      startCursor: pageInfo.startCursor,
                                      endCursor: pageInfo.endCursor,
                                      hasNextPage: pageInfo.hasNextPage,
                                      hasPreviousPage: pageInfo.hasPreviousPage
                                    },
                                    edges: Belt_Array.map(match.edges, (function (param) {
                                            return {
                                                    cursor: param.cursor,
                                                    node: makeBidInfo(param.node)
                                                  };
                                          }))
                                  };
                          }));
            });
}

function executeAcceptBid(apiConfig, param) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$2,
                    Raw: Raw$2,
                    parse: parse$2,
                    serialize: serialize$2,
                    serializeVariables: serializeVariables$1
                  }), {
                input: {
                  id: MarkotApi__Id.toString(param.id)
                }
              }, undefined).then(function (param) {
              return {
                      bidInfo: makeBidInfo(param.acceptBidInfo.bidInfo)
                    };
            });
}

function executeDeclineBid(apiConfig, param) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$3,
                    Raw: Raw$3,
                    parse: parse$3,
                    serialize: serialize$3,
                    serializeVariables: serializeVariables$2
                  }), {
                input: {
                  id: MarkotApi__Id.toString(param.id)
                }
              }, undefined).then(function (param) {
              return {
                      bidInfo: makeBidInfo(param.declineBidInfo.bidInfo)
                    };
            });
}

function executeCancelBid(apiConfig, param) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$4,
                    Raw: Raw$4,
                    parse: parse$4,
                    serialize: serialize$4,
                    serializeVariables: serializeVariables$3
                  }), {
                input: {
                  id: MarkotApi__Id.toString(param.id)
                }
              }, undefined).then(function (param) {
              return {
                      bidInfo: makeBidInfo(param.cancelBidInfo.bidInfo)
                    };
            });
}

function executePostBid(apiConfig, param) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$5,
                    Raw: Raw$5,
                    parse: parse$5,
                    serialize: serialize$5,
                    serializeVariables: serializeVariables$4
                  }), {
                input: {
                  prelovedItemInfoId: MarkotApi__Id.toString(param.prelovedItemInfoId),
                  amount: param.amount
                }
              }, undefined).then(function (param) {
              var match = param.postBid;
              return {
                      prelovedItemInfoId: MarkotApi__Id.parseExn(match.prelovedItemInfoId),
                      bidInfo: makeBidInfo(match.bidInfo)
                    };
            });
}

function BidInfoFields_unsafe_fromJson(prim) {
  return prim;
}

function BidInfoFields_toJson(prim) {
  return prim;
}

var BidInfoFields = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName,
  unsafe_fromJson: BidInfoFields_unsafe_fromJson,
  toJson: BidInfoFields_toJson
};

export {
  BidInfoFields ,
  makeBidInfo ,
  fetchBids ,
  executeAcceptBid ,
  executeDeclineBid ,
  executeCancelBid ,
  executePostBid ,
}
/* MarkotApi__Id Not a pure module */
