// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ParserUtils from "./parser-utils";

function generateProxyDocumentText(prim0, prim1, prim2) {
  return ParserUtils.generateProxyDocumentText(prim0, prim1 !== undefined ? Caml_option.valFromOption(prim1) : undefined);
}

function forceObjectPrototype(prim) {
  ParserUtils.forceObjectPrototype(prim);
}

export {
  generateProxyDocumentText ,
  forceObjectPrototype ,
}
/* ./parser-utils Not a pure module */
