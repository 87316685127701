// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../../../ui/src/group/components/Ui__Group.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as Ui__TextField from "../../../../../ui/src/textField/components/Ui__TextField.bs.js";
import * as Ui__MarkotText from "../../../../../ui/src/text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../../../../ui/src/responsive/Ui__Responsive.bs.js";
import * as Ui__MarkotButton from "../../../../../ui/src/button/components/Ui__MarkotButton.bs.js";
import * as Utils__FormHelpers from "../../../../../utils/src/form/Utils__FormHelpers.bs.js";
import * as App__Address__LookupField from "../addressLookupField/App__Address__LookupField.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";

var match = Ui__Styling.describe({
      flex1: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      errorMessage: {
        TAG: /* Static */0,
        _0: {
          textAlign: "center",
          alignSelf: "center",
          maxWidth: 280
        }
      },
      buttonsContainer: {
        TAG: /* Static */0,
        _0: {
          alignSelf: "center",
          width: 280
        }
      },
      buttons: {
        TAG: /* Static */0,
        _0: {
          justifyContent: "center"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function makeErrorStatus(message, theme) {
  return Ui__TextField.makeStatus(message, theme.colors.errorShade1, theme.colors.errorShade4, theme.colors.error, theme.colors.errorShade3, undefined, undefined);
}

function validateEmptyField(value, theme) {
  if (value.trim() !== "") {
    return {
            kind: "good",
            status: Ui__TextField.neutralStatusPreset
          };
  } else {
    return {
            kind: "bad",
            status: makeErrorStatus("This field can't be empty!", theme)
          };
  }
}

function isFormFilled(param) {
  if (param.recipientName.value.trim() !== "" && param.country.value.trim() !== "" && param.postalCode.value.trim() !== "" && param.streetLine1.value.trim() !== "" && param.city.value.trim() !== "") {
    return param.state.value.trim() !== "";
  } else {
    return false;
  }
}

function App__AddressForm(Props) {
  var initialState = Props.initialState;
  var onCancel = Props.onCancel;
  var onSave = Props.onSave;
  var saving = Props.saving;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "address-form";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var streetLine1Ref = React.useRef(null);
  var streetLine2Ref = React.useRef(null);
  var extraInfoRef = React.useRef(null);
  var cityRef = React.useRef(null);
  var stateRef = React.useRef(null);
  var countryRef = React.useRef(null);
  var postalCodeRef = React.useRef(null);
  var descriptionRef = React.useRef(null);
  var sectionSpacing = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        undefined,
        theme.spacing._8,
        undefined,
        theme.spacing._10,
        undefined,
        theme.spacing._6,
        undefined
      ]);
  var match$1 = React.useReducer((function (param, param$1) {
          if (typeof param$1 === "number") {
            switch (param$1) {
              case /* ValidateRecipientName */0 :
                  var init = param.recipientName;
                  return {
                          recipientName: {
                            value: init.value,
                            validation: validateEmptyField(param.recipientName.value, theme)
                          },
                          country: param.country,
                          postalCode: param.postalCode,
                          city: param.city,
                          streetLine1: param.streetLine1,
                          state: param.state,
                          streetLine2: param.streetLine2,
                          extraInfo: param.extraInfo,
                          description: param.description,
                          error: param.error
                        };
              case /* ValidateCountry */1 :
                  var init$1 = param.country;
                  return {
                          recipientName: param.recipientName,
                          country: {
                            value: init$1.value,
                            validation: validateEmptyField(param.country.value, theme)
                          },
                          postalCode: param.postalCode,
                          city: param.city,
                          streetLine1: param.streetLine1,
                          state: param.state,
                          streetLine2: param.streetLine2,
                          extraInfo: param.extraInfo,
                          description: param.description,
                          error: param.error
                        };
              case /* ValidatePostalCode */2 :
                  var init$2 = param.postalCode;
                  return {
                          recipientName: param.recipientName,
                          country: param.country,
                          postalCode: {
                            value: init$2.value,
                            validation: validateEmptyField(param.postalCode.value, theme)
                          },
                          city: param.city,
                          streetLine1: param.streetLine1,
                          state: param.state,
                          streetLine2: param.streetLine2,
                          extraInfo: param.extraInfo,
                          description: param.description,
                          error: param.error
                        };
              case /* ValidateCity */3 :
                  var init$3 = param.city;
                  return {
                          recipientName: param.recipientName,
                          country: param.country,
                          postalCode: param.postalCode,
                          city: {
                            value: init$3.value,
                            validation: validateEmptyField(param.city.value, theme)
                          },
                          streetLine1: param.streetLine1,
                          state: param.state,
                          streetLine2: param.streetLine2,
                          extraInfo: param.extraInfo,
                          description: param.description,
                          error: param.error
                        };
              case /* ValidateStreetLine1 */4 :
                  var init$4 = param.streetLine1;
                  return {
                          recipientName: param.recipientName,
                          country: param.country,
                          postalCode: param.postalCode,
                          city: param.city,
                          streetLine1: {
                            value: init$4.value,
                            validation: validateEmptyField(param.streetLine1.value, theme)
                          },
                          state: param.state,
                          streetLine2: param.streetLine2,
                          extraInfo: param.extraInfo,
                          description: param.description,
                          error: param.error
                        };
              case /* ValidateState */5 :
                  var init$5 = param.state;
                  return {
                          recipientName: param.recipientName,
                          country: param.country,
                          postalCode: param.postalCode,
                          city: param.city,
                          streetLine1: param.streetLine1,
                          state: {
                            value: init$5.value,
                            validation: validateEmptyField(param.state.value, theme)
                          },
                          streetLine2: param.streetLine2,
                          extraInfo: param.extraInfo,
                          description: param.description,
                          error: param.error
                        };
              case /* ValidateForm */6 :
                  var init$6 = param.recipientName;
                  var init$7 = param.country;
                  var init$8 = param.postalCode;
                  var init$9 = param.city;
                  var init$10 = param.streetLine1;
                  var init$11 = param.state;
                  return {
                          recipientName: {
                            value: init$6.value,
                            validation: validateEmptyField(param.recipientName.value, theme)
                          },
                          country: {
                            value: init$7.value,
                            validation: validateEmptyField(param.country.value, theme)
                          },
                          postalCode: {
                            value: init$8.value,
                            validation: validateEmptyField(param.postalCode.value, theme)
                          },
                          city: {
                            value: init$9.value,
                            validation: validateEmptyField(param.city.value, theme)
                          },
                          streetLine1: {
                            value: init$10.value,
                            validation: validateEmptyField(param.streetLine1.value, theme)
                          },
                          state: {
                            value: init$11.value,
                            validation: validateEmptyField(param.state.value, theme)
                          },
                          streetLine2: param.streetLine2,
                          extraInfo: param.extraInfo,
                          description: param.description,
                          error: param.error
                        };
              
            }
          } else {
            switch (param$1.TAG | 0) {
              case /* ChangeRecipientName */0 :
                  var init$12 = param.recipientName;
                  return {
                          recipientName: {
                            value: param$1._0,
                            validation: init$12.validation
                          },
                          country: param.country,
                          postalCode: param.postalCode,
                          city: param.city,
                          streetLine1: param.streetLine1,
                          state: param.state,
                          streetLine2: param.streetLine2,
                          extraInfo: param.extraInfo,
                          description: param.description,
                          error: param.error
                        };
              case /* ChangeCountry */1 :
                  var init$13 = param.country;
                  return {
                          recipientName: param.recipientName,
                          country: {
                            value: param$1._0,
                            validation: init$13.validation
                          },
                          postalCode: param.postalCode,
                          city: param.city,
                          streetLine1: param.streetLine1,
                          state: param.state,
                          streetLine2: param.streetLine2,
                          extraInfo: param.extraInfo,
                          description: param.description,
                          error: param.error
                        };
              case /* ChangePostalCode */2 :
                  var init$14 = param.postalCode;
                  return {
                          recipientName: param.recipientName,
                          country: param.country,
                          postalCode: {
                            value: param$1._0,
                            validation: init$14.validation
                          },
                          city: param.city,
                          streetLine1: param.streetLine1,
                          state: param.state,
                          streetLine2: param.streetLine2,
                          extraInfo: param.extraInfo,
                          description: param.description,
                          error: param.error
                        };
              case /* ChangeCity */3 :
                  var init$15 = param.city;
                  return {
                          recipientName: param.recipientName,
                          country: param.country,
                          postalCode: param.postalCode,
                          city: {
                            value: param$1._0,
                            validation: init$15.validation
                          },
                          streetLine1: param.streetLine1,
                          state: param.state,
                          streetLine2: param.streetLine2,
                          extraInfo: param.extraInfo,
                          description: param.description,
                          error: param.error
                        };
              case /* ChangeStreetLine1 */4 :
                  var init$16 = param.streetLine1;
                  return {
                          recipientName: param.recipientName,
                          country: param.country,
                          postalCode: param.postalCode,
                          city: param.city,
                          streetLine1: {
                            value: param$1._0,
                            validation: init$16.validation
                          },
                          state: param.state,
                          streetLine2: param.streetLine2,
                          extraInfo: param.extraInfo,
                          description: param.description,
                          error: param.error
                        };
              case /* ChangeStreetLine2 */5 :
                  return {
                          recipientName: param.recipientName,
                          country: param.country,
                          postalCode: param.postalCode,
                          city: param.city,
                          streetLine1: param.streetLine1,
                          state: param.state,
                          streetLine2: param$1._0,
                          extraInfo: param.extraInfo,
                          description: param.description,
                          error: param.error
                        };
              case /* ChangeExtraInfo */6 :
                  return {
                          recipientName: param.recipientName,
                          country: param.country,
                          postalCode: param.postalCode,
                          city: param.city,
                          streetLine1: param.streetLine1,
                          state: param.state,
                          streetLine2: param.streetLine2,
                          extraInfo: param$1._0,
                          description: param.description,
                          error: param.error
                        };
              case /* ChangeState */7 :
                  var init$17 = param.state;
                  return {
                          recipientName: param.recipientName,
                          country: param.country,
                          postalCode: param.postalCode,
                          city: param.city,
                          streetLine1: param.streetLine1,
                          state: {
                            value: param$1._0,
                            validation: init$17.validation
                          },
                          streetLine2: param.streetLine2,
                          extraInfo: param.extraInfo,
                          description: param.description,
                          error: param.error
                        };
              case /* ChangeDescription */8 :
                  return {
                          recipientName: param.recipientName,
                          country: param.country,
                          postalCode: param.postalCode,
                          city: param.city,
                          streetLine1: param.streetLine1,
                          state: param.state,
                          streetLine2: param.streetLine2,
                          extraInfo: param.extraInfo,
                          description: param$1._0,
                          error: param.error
                        };
              case /* UpdateError */9 :
                  return {
                          recipientName: param.recipientName,
                          country: param.country,
                          postalCode: param.postalCode,
                          city: param.city,
                          streetLine1: param.streetLine1,
                          state: param.state,
                          streetLine2: param.streetLine2,
                          extraInfo: param.extraInfo,
                          description: param.description,
                          error: param$1._0
                        };
              case /* ResetForm */10 :
                  return param$1._0;
              
            }
          }
        }), initialState);
  var state = match$1[0];
  var addressState = state.state;
  var streetLine1 = state.streetLine1;
  var city = state.city;
  var postalCode = state.postalCode;
  var country = state.country;
  var recipientName = state.recipientName;
  var dispatch = match$1[1];
  return React.createElement(Ui__Group.make, {
              spacing: theme.sectionSpacing._1,
              children: null,
              containerStyle: Curry._1(styled, styles.flex1),
              testID: testID
            }, React.createElement(Ui__Group.make, {
                  spacing: sectionSpacing,
                  children: null
                }, React.createElement(Ui__TextField.make, {
                      label: "Recipient name",
                      value: recipientName.value,
                      onChangeText: (function (newValue) {
                          Curry._1(dispatch, {
                                TAG: /* ChangeRecipientName */0,
                                _0: newValue
                              });
                        }),
                      placeholder: "John Smith",
                      status: recipientName.validation.status,
                      onBlur: (function (param) {
                          Curry._1(dispatch, /* ValidateRecipientName */0);
                        }),
                      autoComplete: "name",
                      blurOnSubmit: false,
                      onSubmitEditing: (function (param) {
                          Utils__FormHelpers.focusNextField(streetLine1Ref);
                        }),
                      returnKeyType: "next",
                      textContentType: "name",
                      testID: "recipient-name-field"
                    }), React.createElement(App__Address__LookupField.make, {
                      label: "Street line",
                      value: streetLine1.value,
                      placeholder: "Insert your address...",
                      status: streetLine1.validation.status,
                      onChangeText: (function (newValue) {
                          Curry._1(dispatch, {
                                TAG: /* ChangeStreetLine1 */4,
                                _0: newValue
                              });
                        }),
                      onBlur: (function (param) {
                          Curry._1(dispatch, /* ValidateStreetLine1 */4);
                        }),
                      blurOnSubmit: false,
                      returnKeyType: "next",
                      autoComplete: "password-new",
                      textContentType: "streetAddressLine1",
                      onSubmitEditing: (function (param) {
                          Utils__FormHelpers.focusNextField(streetLine2Ref);
                        }),
                      onSelectPredicition: (function (city, state, country, postalCode) {
                          Belt_Option.forEach(city, (function (city) {
                                  Curry._1(dispatch, {
                                        TAG: /* ChangeCity */3,
                                        _0: city
                                      });
                                }));
                          Belt_Option.forEach(state, (function (state) {
                                  Curry._1(dispatch, {
                                        TAG: /* ChangeState */7,
                                        _0: state
                                      });
                                }));
                          Belt_Option.forEach(country, (function (country) {
                                  Curry._1(dispatch, {
                                        TAG: /* ChangeCountry */1,
                                        _0: country
                                      });
                                }));
                          Belt_Option.forEach(postalCode, (function (postalCode) {
                                  Curry._1(dispatch, {
                                        TAG: /* ChangePostalCode */2,
                                        _0: postalCode
                                      });
                                }));
                        }),
                      ref: streetLine1Ref
                    }), React.createElement(Ui__TextField.make, {
                      label: "Street line 2 (Optional)",
                      value: state.streetLine2,
                      onChangeText: (function (newValue) {
                          Curry._1(dispatch, {
                                TAG: /* ChangeStreetLine2 */5,
                                _0: newValue
                              });
                        }),
                      status: Ui__TextField.neutralStatusPreset,
                      blurOnSubmit: false,
                      onSubmitEditing: (function (param) {
                          Utils__FormHelpers.focusNextField(extraInfoRef);
                        }),
                      returnKeyType: "next",
                      textContentType: "streetAddressLine2",
                      ref: streetLine2Ref
                    }), React.createElement(Ui__TextField.make, {
                      label: "Apt/Suite/etc. (Optional)",
                      value: state.extraInfo,
                      onChangeText: (function (newValue) {
                          Curry._1(dispatch, {
                                TAG: /* ChangeExtraInfo */6,
                                _0: newValue
                              });
                        }),
                      status: Ui__TextField.neutralStatusPreset,
                      blurOnSubmit: false,
                      onSubmitEditing: (function (param) {
                          Utils__FormHelpers.focusNextField(cityRef);
                        }),
                      returnKeyType: "next",
                      ref: extraInfoRef
                    }), React.createElement(Ui__TextField.make, {
                      label: "City",
                      value: city.value,
                      onChangeText: (function (newValue) {
                          Curry._1(dispatch, {
                                TAG: /* ChangeCity */3,
                                _0: newValue
                              });
                        }),
                      status: city.validation.status,
                      onBlur: (function (param) {
                          Curry._1(dispatch, /* ValidateCity */3);
                        }),
                      blurOnSubmit: false,
                      onSubmitEditing: (function (param) {
                          Utils__FormHelpers.focusNextField(stateRef);
                        }),
                      returnKeyType: "next",
                      textContentType: "addressCity",
                      testID: "city-text-field",
                      ref: cityRef
                    }), React.createElement(Ui__TextField.make, {
                      label: "State",
                      value: addressState.value,
                      onChangeText: (function (newValue) {
                          Curry._1(dispatch, {
                                TAG: /* ChangeState */7,
                                _0: newValue
                              });
                        }),
                      status: addressState.validation.status,
                      onBlur: (function (param) {
                          Curry._1(dispatch, /* ValidateState */5);
                        }),
                      blurOnSubmit: false,
                      onSubmitEditing: (function (param) {
                          Utils__FormHelpers.focusNextField(countryRef);
                        }),
                      returnKeyType: "next",
                      textContentType: "addressCityAndState",
                      testID: "state-text-field",
                      ref: stateRef
                    }), React.createElement(Ui__TextField.make, {
                      label: "Country",
                      value: country.value,
                      onChangeText: (function (newValue) {
                          Curry._1(dispatch, {
                                TAG: /* ChangeCountry */1,
                                _0: newValue
                              });
                        }),
                      status: country.validation.status,
                      onBlur: (function (param) {
                          Curry._1(dispatch, /* ValidateCountry */1);
                        }),
                      blurOnSubmit: false,
                      onSubmitEditing: (function (param) {
                          Utils__FormHelpers.focusNextField(postalCodeRef);
                        }),
                      returnKeyType: "next",
                      textContentType: "countryName",
                      testID: "country-text-field",
                      ref: countryRef
                    }), React.createElement(Ui__TextField.make, {
                      label: "Postal code",
                      value: postalCode.value,
                      onChangeText: (function (newValue) {
                          Curry._1(dispatch, {
                                TAG: /* ChangePostalCode */2,
                                _0: newValue
                              });
                        }),
                      status: postalCode.validation.status,
                      onBlur: (function (param) {
                          Curry._1(dispatch, /* ValidatePostalCode */2);
                        }),
                      containerStyle: Curry._1(styled, styles.flex1),
                      autoComplete: "postal-code",
                      blurOnSubmit: false,
                      keyboardType: "numeric",
                      onSubmitEditing: (function (param) {
                          Utils__FormHelpers.focusNextField(descriptionRef);
                        }),
                      returnKeyType: "next",
                      textContentType: "postalCode",
                      testID: "postal-code-text-field",
                      ref: postalCodeRef
                    }), React.createElement(Ui__TextField.make, {
                      label: "Description (Optional)",
                      value: state.description,
                      onChangeText: (function (newValue) {
                          Curry._1(dispatch, {
                                TAG: /* ChangeDescription */8,
                                _0: newValue
                              });
                        }),
                      status: Ui__TextField.neutralStatusPreset,
                      returnKeyType: "done_",
                      ref: descriptionRef
                    }), SimpleReact$DecidrIoUtils.renderIfSome(state.error, (function (error) {
                        return React.createElement(Ui__MarkotText.make, {
                                    color: theme.colors.error,
                                    children: error,
                                    style: Curry._1(styled, styles.errorMessage)
                                  });
                      }))), React.createElement(Ui__Group.make, {
                  spacing: sectionSpacing,
                  children: null,
                  containerStyle: Curry._1(styled, styles.buttonsContainer),
                  orientation: "horizontal",
                  testID: "address-form-buttons"
                }, React.createElement(Ui__MarkotButton.make, {
                      kind: "secondaryOutline",
                      onPress: onCancel,
                      title: "Cancel",
                      containerStyle: [
                        Curry._1(styled, styles.flex1),
                        Curry._1(styled, styles.buttons)
                      ],
                      testID: "address-form-cancel-button"
                    }), React.createElement(Ui__MarkotButton.make, {
                      kind: "secondary",
                      onPress: (function (param) {
                          Curry._1(dispatch, /* ValidateForm */6);
                          Curry._1(dispatch, {
                                TAG: /* UpdateError */9,
                                _0: undefined
                              });
                          var recipientNameValidation = validateEmptyField(recipientName.value, theme);
                          var countryValidation = validateEmptyField(country.value, theme);
                          var cityValidation = validateEmptyField(city.value, theme);
                          var postalCodeValidation = validateEmptyField(postalCode.value, theme);
                          var streetLine1Validation = validateEmptyField(streetLine1.value, theme);
                          var stateValidation = validateEmptyField(addressState.value, theme);
                          if (recipientNameValidation.kind === "good" && countryValidation.kind === "good" && cityValidation.kind === "good" && postalCodeValidation.kind === "good" && streetLine1Validation.kind === "good" && stateValidation.kind === "good") {
                            return Curry._3(onSave, state, (function (param) {
                                          Curry._1(dispatch, {
                                                TAG: /* ResetForm */10,
                                                _0: initialState
                                              });
                                        }), (function (error) {
                                          Curry._1(dispatch, {
                                                TAG: /* UpdateError */9,
                                                _0: error
                                              });
                                        }));
                          }
                          
                        }),
                      title: "Save",
                      disabled: !isFormFilled(state),
                      containerStyle: [
                        Curry._1(styled, styles.flex1),
                        Curry._1(styled, styles.buttons)
                      ],
                      busy: saving,
                      testID: "address-form-save-button"
                    })));
}

var make = App__AddressForm;

export {
  make ,
}
/* match Not a pure module */
