// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as App__Report from "../../../utils/App__Report.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactRelay from "react-relay";
import * as Dialog$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/dialog/Dialog.bs.js";
import * as AppDeleteAddressHook_deleteUserAddressMutation_graphql from "../../../../../relay-codegen/generated/AppDeleteAddressHook_deleteUserAddressMutation_graphql.bs.js";

function use(param) {
  var match = ReactRelay.useMutation(AppDeleteAddressHook_deleteUserAddressMutation_graphql.node);
  var mutate = match[0];
  return [
          React.useMemo((function () {
                  return function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
                    return Curry._1(mutate, {
                                onError: param,
                                onCompleted: param$1 !== undefined ? (function (r, errors) {
                                      Curry._2(param$1, AppDeleteAddressHook_deleteUserAddressMutation_graphql.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                                    }) : undefined,
                                onUnsubscribe: param$2,
                                optimisticResponse: param$3 !== undefined ? AppDeleteAddressHook_deleteUserAddressMutation_graphql.Internal.convertWrapRawResponse(param$3) : undefined,
                                optimisticUpdater: param$4,
                                updater: param$5 !== undefined ? (function (store, r) {
                                      Curry._2(param$5, store, AppDeleteAddressHook_deleteUserAddressMutation_graphql.Internal.convertResponse(r));
                                    }) : undefined,
                                variables: AppDeleteAddressHook_deleteUserAddressMutation_graphql.Internal.convertVariables(param$6),
                                uploadables: param$7
                              });
                  };
                }), [mutate]),
          match[1]
        ];
}

function use$1(param) {
  var match = use(undefined);
  var deleteUserAddress = match[0];
  return [
          (function (addressId, relayConnectionsToUpdateOpt, titleOpt, onStartDeleting, onCompleted, onError, param) {
              var relayConnectionsToUpdate = relayConnectionsToUpdateOpt !== undefined ? relayConnectionsToUpdateOpt : [];
              var title = titleOpt !== undefined ? titleOpt : "Are you sure you want to delete this address?";
              Dialog$DecidrIoUniversalComponents.ask(title, undefined, [
                    {
                      text: "Cancel",
                      style: "cancel"
                    },
                    {
                      text: "Confirm",
                      onPress: (function (param) {
                          Belt_Option.forEach(onStartDeleting, (function (onStartDeleting) {
                                  Curry._1(onStartDeleting, undefined);
                                }));
                          Curry.app(deleteUserAddress, [
                                (function (error) {
                                    Belt_Option.forEach(onError, (function (onError) {
                                            Curry._1(onError, "Something went wrong. Please try again!");
                                          }));
                                    App__Report.unexpected(error.message, "App__DeleteAddressHook deleteUserAddress onError");
                                  }),
                                (function (_data, errors) {
                                    if (errors !== undefined) {
                                      Belt_Option.forEach(onError, (function (onError) {
                                              Curry._1(onError, "Something went wrong. Please try again!");
                                            }));
                                      return App__Report.unexpected(Belt_Array.joinWith(errors, ", ", (function (error) {
                                                        return error.message;
                                                      })), "App__DeleteAddressHook deleteUserAddress onCompleted");
                                    } else {
                                      return Belt_Option.forEach(onCompleted, (function (onCompleted) {
                                                    Curry._1(onCompleted, undefined);
                                                  }));
                                    }
                                  }),
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                                {
                                  id: addressId,
                                  relayConnectionsToUpdate: relayConnectionsToUpdate
                                },
                                undefined,
                                undefined
                              ]);
                        }),
                      style: "destructive"
                    }
                  ], undefined, undefined);
            }),
          match[1]
        ];
}

export {
  use$1 as use,
}
/* react Not a pure module */
