// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlMiddleware__Core__FromMarkot from "../core/GqlMiddleware__Core__FromMarkot.bs.js";

function makeAuctionPricing(pricing) {
  if (pricing.TAG !== /* WithBuyout */0) {
    return {
            TAG: /* WithoutBuyout */1,
            __typename: "WithoutBuyout",
            minBidAmount: pricing.minBidAmount
          };
  }
  var minBidLimit = pricing.minBidLimit;
  var tmp;
  tmp = minBidLimit.TAG === /* MinAmount */0 ? ({
        TAG: /* MinAmount */0,
        __typename: "MinAmount",
        amount: GqlMiddleware__Core__FromMarkot.money(minBidLimit.amount)
      }) : ({
        TAG: /* MinPercentageOfBuyNowPrice */1,
        __typename: "MinPercentageOfBuyNowPrice",
        percent: minBidLimit.percent,
        amount: GqlMiddleware__Core__FromMarkot.money(minBidLimit.amount)
      });
  return {
          TAG: /* WithBuyout */0,
          __typename: "WithBuyout",
          buyNowPrice: GqlMiddleware__Core__FromMarkot.money(pricing.buyNowPrice),
          minBidLimit: tmp
        };
}

export {
  makeAuctionPricing ,
}
/* No side effect */
