// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotIcon from "../../icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";

var match = Ui__Styling.describe({
      buttonContainer: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        },
        _1: (function (props) {
            return {
                    backgroundColor: props.theme.colors.secondaryVariant3,
                    borderColor: props.theme.colors.background,
                    borderRadius: props.theme.roundness.circle,
                    borderWidth: props.theme.lineThickness.xs,
                    height: props.size,
                    width: props.size
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__Counter$Button(Props) {
  var onPress = Props.onPress;
  var kind = Props.kind;
  var sizeOpt = Props.size;
  var disabledOpt = Props.disabled;
  var containerStyle = Props.containerStyle;
  var testOnly_pressedOpt = Props.testOnly_pressed;
  var size = sizeOpt !== undefined ? sizeOpt : "s";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var testOnly_pressed = testOnly_pressedOpt !== undefined ? testOnly_pressedOpt : false;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = React.useState(function () {
        return false;
      });
  var setHovered = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setPressed = match$2[1];
  var match$3 = size === "m" ? [
      32,
      17
    ] : (
      size === "s" ? [
          32,
          14
        ] : (
          size === "xxs" || size === "xs" ? [
              26,
              11
            ] : [
              40,
              17
            ]
        )
    );
  var borderIconColor = disabled ? theme.colors.backgroundVariant2 : (
      match$1[0] || match$2[0] ? theme.colors.primaryShade2 : theme.colors.primary
    );
  var styled = Curry._1(stylesheet, {
        theme: theme,
        size: match$3[0],
        disabled: disabled,
        borderIconColor: borderIconColor
      });
  var iconKind = kind === "minus" ? "minus" : "plus";
  return React.createElement(ReactNative.TouchableHighlight, {
              style: [
                Caml_option.some(Curry._1(styled, styles.buttonContainer)),
                containerStyle
              ],
              underlayColor: theme.colors.backgroundVariant2,
              disabled: disabled,
              onPress: (function (param) {
                  Curry._1(onPress, undefined);
                }),
              onPressIn: (function (param) {
                  Curry._1(setPressed, (function (param) {
                          return true;
                        }));
                }),
              onPressOut: (function (param) {
                  Curry._1(setPressed, (function (param) {
                          return false;
                        }));
                }),
              testID: "" + iconKind + "-counter-button",
              testOnly_pressed: testOnly_pressed,
              children: React.createElement(Ui__MarkotIcon.make, {
                    name: iconKind,
                    size: match$3[1],
                    color: borderIconColor,
                    testID: "" + iconKind + "-icon"
                  }),
              onMouseEnter: (function (param) {
                  Curry._1(setHovered, (function (param) {
                          return true;
                        }));
                }),
              onMouseLeave: (function (param) {
                  Curry._1(setHovered, (function (param) {
                          return false;
                        }));
                })
            });
}

var match$1 = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        }
      },
      valueContainer: {
        TAG: /* Static */0,
        _0: {
          width: 30
        }
      },
      valueText: {
        TAG: /* Static */0,
        _0: {
          textAlign: "center"
        }
      }
    });

var stylesheet$1 = match$1[1];

var styles$1 = match$1[0];

function Ui__Counter(Props) {
  var value = Props.value;
  var size = Props.size;
  var lowestValueOpt = Props.lowestValue;
  var highestValueOpt = Props.highestValue;
  var onChange = Props.onChange;
  var containerStyle = Props.containerStyle;
  var testIDOpt = Props.testID;
  var disabledOpt = Props.disabled;
  var lowestValue = lowestValueOpt !== undefined ? lowestValueOpt : 1;
  var highestValue = highestValueOpt !== undefined ? highestValueOpt : 100;
  var testID = testIDOpt !== undefined ? testIDOpt : "counter";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var spacing = size === "m" ? theme.spacing._12 : (
      size === "s" ? theme.spacing._8 : (
          size === "xs" ? theme.spacing._6 : (
              size === "xxs" ? theme.spacing._1 : theme.spacing._5
            )
        )
    );
  var styled = Curry._1(stylesheet$1, theme);
  return React.createElement(Ui__Group.make, {
              spacing: spacing,
              children: null,
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles$1.container)),
                containerStyle
              ],
              orientation: "horizontal",
              testID: testID
            }, React.createElement(Ui__Counter$Button, {
                  onPress: (function (param) {
                      Curry._1(onChange, value - 1 | 0);
                    }),
                  kind: "minus",
                  size: size,
                  disabled: disabled || value <= lowestValue
                }), React.createElement(Ui__MarkotText.make, {
                  size: "b2",
                  children: String(value),
                  style: [
                    Curry._1(styled, styles$1.valueContainer),
                    Curry._1(styled, styles$1.valueText)
                  ],
                  testID: "" + testID + "-value"
                }), React.createElement(Ui__Counter$Button, {
                  onPress: (function (param) {
                      Curry._1(onChange, value + 1 | 0);
                    }),
                  kind: "plus",
                  size: size,
                  disabled: disabled || value >= highestValue
                }));
}

var Button = {
  make: Ui__Counter$Button
};

var make = Ui__Counter;

export {
  Button ,
  make ,
}
/* match Not a pure module */
