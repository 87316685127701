// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../group/components/Ui__Group.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotIcon from "../icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__MarkotText from "../text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../responsive/Ui__Responsive.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as DecidrModal$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/decidrModal/components/DecidrModal.bs.js";

var match = Ui__Styling.describe({
      modal: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.background
                  };
          })
      },
      header: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "space-between"
        },
        _1: (function (theme) {
            return {
                    paddingRight: theme.spacing._10
                  };
          })
      },
      closeButton: {
        TAG: /* Dual */2,
        _0: {
          alignSelf: "flex-end"
        },
        _1: (function (theme) {
            return {
                    paddingVertical: theme.spacing._6
                  };
          })
      },
      flex1: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      scrollViewContent: {
        TAG: /* Static */0,
        _0: {
          flexDirection: "row",
          justifyContent: "center"
        }
      },
      mainContainer: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          flex: 1,
          flexDirection: "row"
        },
        _1: (function (theme) {
            return {
                    paddingHorizontal: theme.sectionSpacing._05,
                    paddingVertical: theme.sectionSpacing._1
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

var widthLimiterStyle = Ui__Responsive.widthLimiterStyle(undefined, 632, undefined);

function Ui__ModalShell(Props) {
  var visible = Props.visible;
  var onClose = Props.onClose;
  var children = Props.children;
  var title = Props.title;
  var containerStyle = Props.containerStyle;
  var isFormOpt = Props.isForm;
  var titleStyle = Props.titleStyle;
  var titleSize = Props.titleSize;
  var isForm = isFormOpt !== undefined ? isFormOpt : true;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var tmp = {
    style: Curry._1(styled, styles.flex1),
    children: React.createElement(ReactNative.ScrollView, {
          contentContainerStyle: [
            Caml_option.some(Curry._1(styled, styles.scrollViewContent)),
            Bool$DecidrIoUtils.thenSome(isForm, widthLimiterStyle)
          ],
          keyboardShouldPersistTaps: "handled",
          style: Curry._1(styled, styles.flex1),
          children: React.createElement(ReactNative.View, {
                style: Curry._1(styled, styles.mainContainer),
                children: React.createElement(Ui__Group.make, {
                      spacing: theme.sectionSpacing._1,
                      children: null,
                      containerStyle: Curry._1(styled, styles.flex1)
                    }, React.createElement(Ui__Group.make, {
                          children: null,
                          containerStyle: [
                            Caml_option.some(Curry._1(styled, styles.header)),
                            Bool$DecidrIoUtils.thenSome(isForm, widthLimiterStyle),
                            titleStyle
                          ],
                          orientation: "horizontal",
                          reversed: true
                        }, React.createElement(ReactNative.TouchableOpacity, {
                              style: Curry._1(styled, styles.closeButton),
                              onPress: (function (param) {
                                  Curry._1(onClose, undefined);
                                }),
                              children: React.createElement(Ui__MarkotIcon.make, {
                                    name: "x-mark",
                                    size: 20
                                  })
                            }), SimpleReact$DecidrIoUtils.renderIfSome(title, (function (title) {
                                return React.createElement(Ui__MarkotText.make, {
                                            size: Belt_Option.getWithDefault(titleSize, "b1"),
                                            children: title
                                          });
                              }))), children)
              })
        })
  };
  var tmp$1 = Bool$DecidrIoUtils.thenSome(ReactNative.Platform.OS === "ios", "padding");
  if (tmp$1 !== undefined) {
    tmp.behavior = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(DecidrModal$DecidrIoUniversalComponents.make, {
              visible: visible,
              onClose: onClose,
              debugContext: "Modal shell",
              transparent: true,
              children: React.createElement(ReactNative.KeyboardAvoidingView, tmp),
              outerContainerStyle: [
                Caml_option.some(Curry._1(styled, styles.modal)),
                containerStyle
              ]
            });
}

var make = Ui__ModalShell;

export {
  make ,
}
/* match Not a pure module */
