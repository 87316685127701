// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Ui__Theme from "../../theme/components/Ui__Theme.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Ui__BaseText from "../../text/components/Ui__BaseText.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as RnUtils$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/utils/RnUtils.bs.js";

var match = Ui__Styling.describe({
      labelText: {
        TAG: /* Dynamic */1,
        _0: (function (param) {
            return {
                    paddingHorizontal: param.theme.spacing._10
                  };
          })
      },
      inputContainer: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center"
        },
        _1: (function (param) {
            var theme = param.theme;
            return {
                    backgroundColor: param.disabled ? theme.colors.backgroundVariant : theme.colors.background,
                    borderColor: "transparent",
                    borderRadius: theme.roundness._7,
                    borderWidth: theme.lineThickness.xxs,
                    paddingHorizontal: theme.spacing._10,
                    paddingVertical: theme.spacing._6
                  };
          })
      },
      focusedInputContainer: {
        TAG: /* Dynamic */1,
        _0: (function (param) {
            var theme = param.theme;
            return {
                    borderColor: theme.colors.primaryShade3,
                    borderWidth: theme.lineThickness.xxs,
                    elevation: 5,
                    shadowColor: theme.colors.info,
                    shadowRadius: 5
                  };
          })
      },
      input: {
        TAG: /* Dual */2,
        _0: {
          flex: 1
        },
        _1: (function (param) {
            var theme = param.theme;
            var font = Ui__BaseText.typographyVariantToFont(param.inputSize, theme);
            return {
                    color: param.disabled ? theme.colors.onSecondaryShade1 : theme.colors.primary,
                    fontSize: Curry._1(Ui__Theme.Typography.Font.getSize, font),
                    lineHeight: Curry._1(Ui__Theme.Typography.Font.getLineHeight, font)
                  };
          })
      },
      statusMessage: {
        TAG: /* Static */0,
        _0: {
          alignSelf: "flex-end"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function makeStatus(message, messageColor, messageDisabledColor, outlineColor, outlineDisabledColor, showOutlineOpt, param) {
  var showOutline = showOutlineOpt !== undefined ? showOutlineOpt : true;
  return {
          message: message,
          messageColor: messageColor,
          messageDisabledColor: messageDisabledColor,
          outlineColor: outlineColor,
          outlineDisabledColor: outlineDisabledColor,
          showOutline: showOutline
        };
}

function useErrorStatusPreset(message, param) {
  var match = Curry._1(Ui__Theme.useTheme, undefined);
  var theme = match.theme;
  return makeStatus(message, theme.colors.errorShade1, theme.colors.errorShade4, theme.colors.error, theme.colors.errorShade3, undefined, undefined);
}

function useSuccessStatusPreset(message, param) {
  var match = Curry._1(Ui__Theme.useTheme, undefined);
  var theme = match.theme;
  return makeStatus(message, theme.colors.successShade1, theme.colors.successShade4, theme.colors.success, theme.colors.successShade3, undefined, undefined);
}

var neutralStatusPreset = {
  message: undefined,
  messageColor: undefined,
  messageDisabledColor: undefined,
  outlineColor: undefined,
  outlineDisabledColor: undefined,
  showOutline: false
};

var make = React.forwardRef(function (Props, ref) {
      var label = Props.label;
      var value = Props.value;
      var statusNumberOfLines = Props.statusNumberOfLines;
      var defaultValue = Props.defaultValue;
      var onChangeText = Props.onChangeText;
      var disabledOpt = Props.disabled;
      var placeholder = Props.placeholder;
      var placeholderTextColor = Props.placeholderTextColor;
      var statusOpt = Props.status;
      var onFocus = Props.onFocus;
      var onBlur = Props.onBlur;
      var left = Props.left;
      var right = Props.right;
      var containerStyle = Props.containerStyle;
      var inputContainerStyle = Props.inputContainerStyle;
      var inputStyle = Props.inputStyle;
      var inputSizeOpt = Props.inputSize;
      var labelStyle = Props.labelStyle;
      var numberOfLinesOpt = Props.numberOfLines;
      var allowFontScaling = Props.allowFontScaling;
      var autoCapitalize = Props.autoCapitalize;
      var autoComplete = Props.autoComplete;
      var autoCorrect = Props.autoCorrect;
      var autoFocus = Props.autoFocus;
      var blurOnSubmit = Props.blurOnSubmit;
      var caretHidden = Props.caretHidden;
      var clearTextOnFocus = Props.clearTextOnFocus;
      var contextMenuHidden = Props.contextMenuHidden;
      var disableFullscreenUI = Props.disableFullscreenUI;
      var enablesReturnKeyAutomatically = Props.enablesReturnKeyAutomatically;
      var importantForAutofill = Props.importantForAutofill;
      var inputAccessoryViewID = Props.inputAccessoryViewID;
      var keyboardAppearance = Props.keyboardAppearance;
      var keyboardType = Props.keyboardType;
      var maxFontSizeMultiplier = Props.maxFontSizeMultiplier;
      var maxLength = Props.maxLength;
      var onContentSizeChange = Props.onContentSizeChange;
      var onEndEditing = Props.onEndEditing;
      var onKeyPress = Props.onKeyPress;
      var onScroll = Props.onScroll;
      var onSelectionChange = Props.onSelectionChange;
      var onSubmitEditing = Props.onSubmitEditing;
      var returnKeyLabel = Props.returnKeyLabel;
      var returnKeyType = Props.returnKeyType;
      var rejectResponderTermination = Props.rejectResponderTermination;
      var scrollEnabled = Props.scrollEnabled;
      var secureTextEntry = Props.secureTextEntry;
      var selection = Props.selection;
      var selectionColor = Props.selectionColor;
      var selectTextOnFocus = Props.selectTextOnFocus;
      var showSoftInputOnFocus = Props.showSoftInputOnFocus;
      var spellCheck = Props.spellCheck;
      var textBreakStrategy = Props.textBreakStrategy;
      var textContentType = Props.textContentType;
      var underlineColorAndroid = Props.underlineColorAndroid;
      var accessibilityActions = Props.accessibilityActions;
      var accessibilityElementsHidden = Props.accessibilityElementsHidden;
      var accessibilityHint = Props.accessibilityHint;
      var accessibilityIgnoresInvertColors = Props.accessibilityIgnoresInvertColors;
      var accessibilityLabel = Props.accessibilityLabel;
      var accessibilityLiveRegion = Props.accessibilityLiveRegion;
      var accessibilityRole = Props.accessibilityRole;
      var accessibilityState = Props.accessibilityState;
      var accessibilityValue = Props.accessibilityValue;
      var accessibilityViewIsModal = Props.accessibilityViewIsModal;
      var accessible = Props.accessible;
      var collapsable = Props.collapsable;
      var hitSlop = Props.hitSlop;
      var importantForAccessibility = Props.importantForAccessibility;
      var nativeID = Props.nativeID;
      var needsOffscreenAlphaCompositing = Props.needsOffscreenAlphaCompositing;
      var onAccessibilityAction = Props.onAccessibilityAction;
      var onAccessibilityEscape = Props.onAccessibilityEscape;
      var onAccessibilityTap = Props.onAccessibilityTap;
      var onLayout = Props.onLayout;
      var onMagicTap = Props.onMagicTap;
      var testIDOpt = Props.testID;
      var disabled = disabledOpt !== undefined ? disabledOpt : false;
      var status = statusOpt !== undefined ? statusOpt : neutralStatusPreset;
      var inputSize = inputSizeOpt !== undefined ? inputSizeOpt : "b2";
      var numberOfLines = numberOfLinesOpt !== undefined ? numberOfLinesOpt : 1;
      var testID = testIDOpt !== undefined ? testIDOpt : "field-input";
      var match = Curry._1(Ui__Theme.useTheme, undefined);
      var theme = match.theme;
      var styled = Curry._1(stylesheet, {
            theme: theme,
            disabled: disabled,
            inputSize: inputSize
          });
      var match$1 = React.useState(function () {
            return false;
          });
      var message = status.message;
      var setFocused = match$1[1];
      var placeholderTextColor$1 = Belt_Option.getWithDefault(placeholderTextColor, disabled ? theme.colors.primaryShade3 : theme.colors.primaryShade2);
      var tmp = {
        spacing: theme.spacing._2,
        children: null,
        testID: "outer-field-container"
      };
      if (containerStyle !== undefined) {
        tmp.containerStyle = Caml_option.valFromOption(containerStyle);
      }
      var tmp$1;
      if (status.showOutline) {
        var tmp$2 = {};
        var tmp$3 = disabled ? status.outlineDisabledColor : status.outlineColor;
        if (tmp$3 !== undefined) {
          tmp$2.borderColor = Caml_option.valFromOption(tmp$3);
        }
        tmp$1 = Caml_option.some(tmp$2);
      } else {
        tmp$1 = undefined;
      }
      var os = ReactNative.Platform.OS;
      var tmp$4 = {
        editable: !disabled,
        multiline: numberOfLines > 1,
        numberOfLines: numberOfLines,
        onBlur: (function ($$event) {
            Curry._1(setFocused, (function (param) {
                    return false;
                  }));
            Belt_Option.forEach(onBlur, (function (onBlur) {
                    Curry._1(onBlur, $$event);
                  }));
          }),
        onFocus: (function ($$event) {
            Curry._1(setFocused, (function (param) {
                    return true;
                  }));
            Belt_Option.forEach(onFocus, (function (onFocus) {
                    Curry._1(onFocus, $$event);
                  }));
          }),
        placeholderTextColor: placeholderTextColor$1,
        hitSlop: Belt_Option.getWithDefault(hitSlop, RnUtils$DecidrIoUniversalComponents.makeSymmetricHitSlop(theme.spacing._10)),
        style: [
          Caml_option.some(Curry._1(styled, styles.input)),
          os === "web" ? ({
                outline: "none"
              }) : undefined,
          inputStyle
        ],
        testID: testID
      };
      var tmp$5 = Belt_Option.map((ref == null) ? undefined : Caml_option.some(ref), (function (prim) {
              return prim;
            }));
      if (tmp$5 !== undefined) {
        tmp$4.ref = Caml_option.valFromOption(tmp$5);
      }
      if (allowFontScaling !== undefined) {
        tmp$4.allowFontScaling = allowFontScaling;
      }
      if (autoCapitalize !== undefined) {
        tmp$4.autoCapitalize = Caml_option.valFromOption(autoCapitalize);
      }
      if (autoComplete !== undefined) {
        tmp$4.autoComplete = Caml_option.valFromOption(autoComplete);
      }
      if (autoCorrect !== undefined) {
        tmp$4.autoCorrect = autoCorrect;
      }
      if (autoFocus !== undefined) {
        tmp$4.autoFocus = autoFocus;
      }
      if (blurOnSubmit !== undefined) {
        tmp$4.blurOnSubmit = blurOnSubmit;
      }
      if (caretHidden !== undefined) {
        tmp$4.caretHidden = caretHidden;
      }
      if (clearTextOnFocus !== undefined) {
        tmp$4.clearTextOnFocus = clearTextOnFocus;
      }
      if (contextMenuHidden !== undefined) {
        tmp$4.contextMenuHidden = contextMenuHidden;
      }
      if (defaultValue !== undefined) {
        tmp$4.defaultValue = defaultValue;
      }
      if (disableFullscreenUI !== undefined) {
        tmp$4.disableFullscreenUI = disableFullscreenUI;
      }
      if (enablesReturnKeyAutomatically !== undefined) {
        tmp$4.enablesReturnKeyAutomatically = enablesReturnKeyAutomatically;
      }
      if (importantForAutofill !== undefined) {
        tmp$4.importantForAutofill = Caml_option.valFromOption(importantForAutofill);
      }
      if (inputAccessoryViewID !== undefined) {
        tmp$4.inputAccessoryViewID = inputAccessoryViewID;
      }
      if (keyboardAppearance !== undefined) {
        tmp$4.keyboardAppearance = Caml_option.valFromOption(keyboardAppearance);
      }
      if (keyboardType !== undefined) {
        tmp$4.keyboardType = (function () {
              switch (keyboardType) {
                case "default" :
                    return "default";
                case "numberPad" :
                    return "number-pad";
                case "decimalPad" :
                    return "decimal-pad";
                case "numeric" :
                    return "numeric";
                case "emailAddress" :
                    return "email-address";
                case "phonePad" :
                    return "phone-pad";
                case "asciiCapable" :
                    return "ascii-capable";
                case "numbersAndPunctuation" :
                    return "numbers-and-punctuation";
                case "url" :
                    return "url";
                case "namePhonePad" :
                    return "name-phone-pad";
                case "twitter" :
                    return "twitter";
                case "webSearch" :
                    return "web-search";
                case "visiblePassword" :
                    return "visible-password";
                
              }
            })();
      }
      if (maxFontSizeMultiplier !== undefined) {
        tmp$4.maxFontSizeMultiplier = maxFontSizeMultiplier;
      }
      if (maxLength !== undefined) {
        tmp$4.maxLength = maxLength;
      }
      if (onChangeText !== undefined) {
        tmp$4.onChangeText = Caml_option.valFromOption(onChangeText);
      }
      if (onContentSizeChange !== undefined) {
        tmp$4.onContentSizeChange = Caml_option.valFromOption(onContentSizeChange);
      }
      if (onEndEditing !== undefined) {
        tmp$4.onEndEditing = Caml_option.valFromOption(onEndEditing);
      }
      if (onKeyPress !== undefined) {
        tmp$4.onKeyPress = Caml_option.valFromOption(onKeyPress);
      }
      if (onScroll !== undefined) {
        tmp$4.onScroll = Caml_option.valFromOption(onScroll);
      }
      if (onSelectionChange !== undefined) {
        tmp$4.onSelectionChange = Caml_option.valFromOption(onSelectionChange);
      }
      if (onSubmitEditing !== undefined) {
        tmp$4.onSubmitEditing = Caml_option.valFromOption(onSubmitEditing);
      }
      if (placeholder !== undefined) {
        tmp$4.placeholder = placeholder;
      }
      if (returnKeyLabel !== undefined) {
        tmp$4.returnKeyLabel = returnKeyLabel;
      }
      if (returnKeyType !== undefined) {
        tmp$4.returnKeyType = (function () {
              switch (returnKeyType) {
                case "done_" :
                    return "done";
                case "go" :
                    return "go";
                case "next" :
                    return "next";
                case "search" :
                    return "search";
                case "send" :
                    return "send";
                case "none" :
                    return "none";
                case "previous" :
                    return "previous";
                case "default" :
                    return "default";
                case "emergencyCall" :
                    return "emergency-call";
                case "google" :
                    return "google";
                case "join" :
                    return "join";
                case "route" :
                    return "route";
                case "yahoo" :
                    return "yahoo";
                
              }
            })();
      }
      if (rejectResponderTermination !== undefined) {
        tmp$4.rejectResponderTermination = rejectResponderTermination;
      }
      if (scrollEnabled !== undefined) {
        tmp$4.scrollEnabled = scrollEnabled;
      }
      if (secureTextEntry !== undefined) {
        tmp$4.secureTextEntry = secureTextEntry;
      }
      if (selection !== undefined) {
        tmp$4.selection = Caml_option.valFromOption(selection);
      }
      if (selectionColor !== undefined) {
        tmp$4.selectionColor = Caml_option.valFromOption(selectionColor);
      }
      if (selectTextOnFocus !== undefined) {
        tmp$4.selectTextOnFocus = selectTextOnFocus;
      }
      if (showSoftInputOnFocus !== undefined) {
        tmp$4.showSoftInputOnFocus = showSoftInputOnFocus;
      }
      if (spellCheck !== undefined) {
        tmp$4.spellCheck = spellCheck;
      }
      if (textBreakStrategy !== undefined) {
        tmp$4.textBreakStrategy = Caml_option.valFromOption(textBreakStrategy);
      }
      if (textContentType !== undefined) {
        tmp$4.textContentType = Caml_option.valFromOption(textContentType);
      }
      if (underlineColorAndroid !== undefined) {
        tmp$4.underlineColorAndroid = Caml_option.valFromOption(underlineColorAndroid);
      }
      if (value !== undefined) {
        tmp$4.value = value;
      }
      if (accessibilityActions !== undefined) {
        tmp$4.accessibilityActions = Caml_option.valFromOption(accessibilityActions);
      }
      if (accessibilityElementsHidden !== undefined) {
        tmp$4.accessibilityElementsHidden = accessibilityElementsHidden;
      }
      if (accessibilityHint !== undefined) {
        tmp$4.accessibilityHint = accessibilityHint;
      }
      if (accessibilityIgnoresInvertColors !== undefined) {
        tmp$4.accessibilityIgnoresInvertColors = accessibilityIgnoresInvertColors;
      }
      if (accessibilityLabel !== undefined) {
        tmp$4.accessibilityLabel = accessibilityLabel;
      }
      if (accessibilityLiveRegion !== undefined) {
        tmp$4.accessibilityLiveRegion = Caml_option.valFromOption(accessibilityLiveRegion);
      }
      if (accessibilityRole !== undefined) {
        tmp$4.accessibilityRole = Caml_option.valFromOption(accessibilityRole);
      }
      if (accessibilityState !== undefined) {
        tmp$4.accessibilityState = Caml_option.valFromOption(accessibilityState);
      }
      if (accessibilityValue !== undefined) {
        tmp$4.accessibilityValue = Caml_option.valFromOption(accessibilityValue);
      }
      if (accessibilityViewIsModal !== undefined) {
        tmp$4.accessibilityViewIsModal = accessibilityViewIsModal;
      }
      if (accessible !== undefined) {
        tmp$4.accessible = accessible;
      }
      if (collapsable !== undefined) {
        tmp$4.collapsable = collapsable;
      }
      if (importantForAccessibility !== undefined) {
        tmp$4.importantForAccessibility = (function () {
              switch (importantForAccessibility) {
                case "auto" :
                    return "auto";
                case "yes" :
                    return "yes";
                case "no" :
                    return "no";
                case "noHideDescendants" :
                    return "no-hide-descendants";
                
              }
            })();
      }
      if (nativeID !== undefined) {
        tmp$4.nativeID = nativeID;
      }
      if (needsOffscreenAlphaCompositing !== undefined) {
        tmp$4.needsOffscreenAlphaCompositing = needsOffscreenAlphaCompositing;
      }
      if (onAccessibilityAction !== undefined) {
        tmp$4.onAccessibilityAction = Caml_option.valFromOption(onAccessibilityAction);
      }
      if (onAccessibilityEscape !== undefined) {
        tmp$4.onAccessibilityEscape = Caml_option.valFromOption(onAccessibilityEscape);
      }
      if (onAccessibilityTap !== undefined) {
        tmp$4.onAccessibilityTap = Caml_option.valFromOption(onAccessibilityTap);
      }
      if (onLayout !== undefined) {
        tmp$4.onLayout = Caml_option.valFromOption(onLayout);
      }
      if (onMagicTap !== undefined) {
        tmp$4.onMagicTap = Caml_option.valFromOption(onMagicTap);
      }
      var tmp$6 = {
        size: "caption1",
        color: disabled ? Belt_Option.getWithDefault(status.messageDisabledColor, theme.colors.primaryShade3) : Belt_Option.getWithDefault(status.messageColor, theme.colors.primaryShade2),
        children: Belt_Option.getWithDefault(message, " "),
        style: [
          Curry._1(styled, styles.labelText),
          Curry._1(styled, styles.statusMessage)
        ]
      };
      if (statusNumberOfLines !== undefined) {
        tmp$6.numberOfLines = Caml_option.valFromOption(statusNumberOfLines);
      }
      return React.createElement(Ui__Group.make, tmp, SimpleReact$DecidrIoUtils.renderIfSome(label, (function (label) {
                        return React.createElement(Ui__MarkotText.make, {
                                    size: "caption1",
                                    color: disabled ? theme.colors.onSecondaryShade1 : theme.colors.primaryShade2,
                                    children: label,
                                    style: [
                                      Caml_option.some(Curry._1(styled, styles.labelText)),
                                      labelStyle
                                    ]
                                  });
                      })), React.createElement(Ui__Group.make, {
                      spacing: theme.spacing._5,
                      children: null,
                      containerStyle: [
                        Caml_option.some(Curry._1(styled, styles.inputContainer)),
                        match$1[0] ? Caml_option.some(Curry._1(styled, styles.focusedInputContainer)) : undefined,
                        tmp$1,
                        inputContainerStyle
                      ],
                      orientation: "horizontal",
                      testID: "" + testID + "-container"
                    }, SimpleReact$DecidrIoUtils.renderIfSome(left, (function (left) {
                            return left;
                          })), React.createElement(ReactNative.TextInput, tmp$4), SimpleReact$DecidrIoUtils.renderIfSome(right, (function (right) {
                            return right;
                          }))), SimpleReact$DecidrIoUtils.whenTrue(message !== "", React.createElement(Ui__MarkotText.make, tmp$6)));
    });

export {
  styles ,
  stylesheet ,
  makeStatus ,
  useErrorStatusPreset ,
  useSuccessStatusPreset ,
  neutralStatusPreset ,
  make ,
}
/* match Not a pure module */
