// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";

function App__RelayProvider(Props) {
  var children = Props.children;
  var environment = Props.environment;
  return React.createElement(RescriptRelay.Context.Provider.make, {
              environment: environment,
              children: children
            });
}

var make = App__RelayProvider;

export {
  make ,
}
/* react Not a pure module */
