// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Ui__MarkotIcon from "./Ui__MarkotIcon";

var make = Ui__MarkotIcon.MarkotIcon;

export {
  make ,
}
/* make Not a pure module */
