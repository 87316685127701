// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_promise from "rescript/lib/es6/js_promise.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactRelay from "react-relay";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as Ui__LocalStorage from "../../../ui/src/localStorage/Ui__LocalStorage.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as AppLocalBagQuery_graphql from "../../../relay-codegen/generated/AppLocalBagQuery_graphql.bs.js";
import * as AppLocalBagViewerQuery_graphql from "../../../relay-codegen/generated/AppLocalBagViewerQuery_graphql.bs.js";
import * as AppLocalBag_setViewerBagWithNodeIdMutation_graphql from "../../../relay-codegen/generated/AppLocalBag_setViewerBagWithNodeIdMutation_graphql.bs.js";

function fetchPromised(environment, variables, networkCacheConfig, fetchPolicy, param) {
  var __x = ReactRelay.fetchQuery(environment, AppLocalBagViewerQuery_graphql.node, AppLocalBagViewerQuery_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).toPromise();
  return Js_promise.then_((function (res) {
                return Promise.resolve(AppLocalBagViewerQuery_graphql.Internal.convertResponse(res));
              }), __x);
}

function fetchPromised$1(environment, variables, networkCacheConfig, fetchPolicy, param) {
  var __x = ReactRelay.fetchQuery(environment, AppLocalBagQuery_graphql.node, AppLocalBagQuery_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).toPromise();
  return Js_promise.then_((function (res) {
                return Promise.resolve(AppLocalBagQuery_graphql.Internal.convertResponse(res));
              }), __x);
}

function commitMutation(environment, variables, optimisticUpdater, optimisticResponse, updater, onCompleted, onError, uploadables, param) {
  return RelayRuntime.commitMutation(environment, {
              mutation: AppLocalBag_setViewerBagWithNodeIdMutation_graphql.node,
              variables: AppLocalBag_setViewerBagWithNodeIdMutation_graphql.Internal.convertVariables(variables),
              onCompleted: (function (res, err) {
                  if (onCompleted !== undefined) {
                    return Curry._2(onCompleted, AppLocalBag_setViewerBagWithNodeIdMutation_graphql.Internal.convertResponse(res), (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              onError: (function (err) {
                  if (onError !== undefined) {
                    return Curry._1(onError, (err == null) ? undefined : Caml_option.some(err));
                  }
                  
                }),
              optimisticResponse: optimisticResponse !== undefined ? AppLocalBag_setViewerBagWithNodeIdMutation_graphql.Internal.convertWrapRawResponse(optimisticResponse) : undefined,
              optimisticUpdater: optimisticUpdater,
              updater: updater !== undefined ? (function (store, r) {
                    Curry._2(updater, store, AppLocalBag_setViewerBagWithNodeIdMutation_graphql.Internal.convertResponse(r));
                  }) : undefined,
              uploadables: uploadables
            });
}

var localStorageKey = "bagId";

function assignLocalBagToViewer(environment) {
  var setViewerBagToLocalBag = function (viewerDataId) {
    return Ui__LocalStorage.ReactNativeAsyncStorage.getItem(localStorageKey).then(function (bagId) {
                if (bagId !== null) {
                  console.log("[App__LocalBag] setting viewer's bagId");
                  commitMutation(environment, {
                        bagId: bagId
                      }, undefined, undefined, (function (store, _result) {
                          Belt_Option.forEach(Belt_Option.map(Caml_option.nullable_to_opt(store.get(viewerDataId)), (function (__x) {
                                      return __x.setValue(bagId, "bagId", undefined);
                                    })), (function (prim) {
                                  return prim;
                                }));
                        }), undefined, undefined, undefined, undefined);
                  return Ui__LocalStorage.ReactNativeAsyncStorage.removeItem(localStorageKey);
                } else {
                  console.log("[App__LocalBag] skipping because there is no bagId in local storage");
                  return Promise.resolve(undefined);
                }
              });
  };
  return fetchPromised(environment, {}, undefined, undefined, undefined).then(function (param) {
              var viewer = param.viewer;
              if (viewer !== undefined) {
                var viewerBagId = viewer.bagId;
                var __id = viewer.__id;
                if (viewerBagId !== undefined) {
                  return fetchPromised$1(environment, {
                                id: viewerBagId
                              }, undefined, undefined, undefined).then(function (param) {
                              var bag2 = param.bag2;
                              if (bag2 !== undefined && bag2.items.edges.length !== 0) {
                                console.log("[App__LocalBag] skipping because user has valid bag");
                                return Ui__LocalStorage.ReactNativeAsyncStorage.removeItem(localStorageKey);
                              } else {
                                return setViewerBagToLocalBag(__id);
                              }
                            });
                } else {
                  return setViewerBagToLocalBag(__id);
                }
              }
              console.log("[App__LocalBag] skipping because user is not logged in");
              return Promise.resolve(undefined);
            });
}

export {
  assignLocalBagToViewer ,
}
/* react-relay Not a pure module */
