// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as FeatureFlags__AppSpecificConfig from "../../../feature-flags/src/local/FeatureFlags__AppSpecificConfig.bs.js";

function isTestUser(cognitoGroups) {
  return Belt_Option.map(cognitoGroups, (function (cognitoGroups) {
                return cognitoGroups.includes("Testers");
              }));
}

function isFeatureFlagOn(context, key) {
  return Belt_Option.getWithDefault(Belt_Option.map(Curry._2(FeatureFlags__AppSpecificConfig.FeatureFlagsMap.get, context.featureFlags, key), (function (param) {
                    return param.status === "on";
                  })), false);
}

export {
  isTestUser ,
  isFeatureFlagOn ,
}
/* FeatureFlags__AppSpecificConfig Not a pure module */
