// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as MarkotApi__CommonFragments from "./MarkotApi__CommonFragments.bs.js";

var Raw = {};

var query = "fragment PrelovedItemMediaFields on PrelovedItemMedia   {\nid  \nstatus  \ntag  \nitem  {\n__typename\n...on ImageResource   {\n...ImageResourceFields   \n}\n\n}\n\n}\n" + MarkotApi__CommonFragments.ImageResourceFields.query;

function parse(value) {
  var value$1 = value.status;
  var tmp;
  switch (value$1) {
    case "Approved" :
        tmp = "Approved";
        break;
    case "Pending" :
        tmp = "Pending";
        break;
    case "Rejected" :
        tmp = "Rejected";
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$1
      };
  }
  var value$2 = value.tag;
  var value$3 = value.item;
  var typename = value$3["__typename"];
  var tmp$1 = typename === "ImageResource" ? ({
        NAME: "ImageResource",
        VAL: {
          imageResourceFields: MarkotApi__CommonFragments.ImageResourceFields.verifyArgsAndParse("ImageResourceFields", value$3)
        }
      }) : ({
        NAME: "UnspecifiedFragment",
        VAL: typename
      });
  return {
          id: value.id,
          status: tmp,
          tag: !(value$2 == null) ? value$2 : undefined,
          item: tmp$1
        };
}

function serialize(value) {
  var value$1 = value.item;
  var item = value$1.NAME === "ImageResource" ? Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
            return {};
          }), [MarkotApi__CommonFragments.ImageResourceFields.serialize(value$1.VAL.imageResourceFields)]) : ({});
  var value$2 = value.tag;
  var tag = value$2 !== undefined ? value$2 : null;
  var value$3 = value.status;
  var status = typeof value$3 === "object" ? value$3.VAL : (
      value$3 === "Pending" ? "Pending" : (
          value$3 === "Rejected" ? "Rejected" : "Approved"
        )
    );
  var value$4 = value.id;
  return {
          id: value$4,
          status: status,
          tag: tag,
          item: item
        };
}

function verifyArgsAndParse(_PrelovedItemMediaFields, value) {
  return parse(value);
}

function verifyName(param) {
  
}

function makePrelovedItemMedia(param) {
  var item = param.item;
  var status = param.status;
  return {
          id: MarkotApi__Id.parseExn(param.id),
          status: typeof status === "object" ? Js_exn.raiseError("PrelovedItemMedia.status unknown: " + status.VAL) : (
              status === "Pending" ? "Pending" : (
                  status === "Rejected" ? "Rejected" : "Approved"
                )
            ),
          tag: param.tag,
          item: item.NAME === "ImageResource" ? MarkotApi__CommonFragments.makeImageResource(item.VAL.imageResourceFields) : Js_exn.raiseError("PrelovedItemMedia.item unknown typename: " + item.VAL)
        };
}

function PrelovedItemMediaFields_unsafe_fromJson(prim) {
  return prim;
}

function PrelovedItemMediaFields_toJson(prim) {
  return prim;
}

var PrelovedItemMediaFields = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName,
  unsafe_fromJson: PrelovedItemMediaFields_unsafe_fromJson,
  toJson: PrelovedItemMediaFields_toJson
};

export {
  PrelovedItemMediaFields ,
  makePrelovedItemMedia ,
}
/* MarkotApi__Id Not a pure module */
