// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ShopifyApi__Id from "../../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as ShopifyApi__SettingsForPrelovedItems from "../../../api-adapter-shopify/src/ShopifyApi__SettingsForPrelovedItems.bs.js";

function makeSettingsForPrelovedItems(param) {
  return {
          __typename: "SettingsForPrelovedItems",
          availableTagsForMedia: param.availableTagsForMedia,
          defaultMinPercentageOfBuyNowPrice: param.defaultMinPercentageOfBuyNowPrice,
          minMediaItems: param.minMediaItems
        };
}

function fetchSettingsForPrelovedItemsByProductId(apiConfig, productId) {
  return ShopifyApi__SettingsForPrelovedItems.fetchSettingsForPrelovedItemsByProductId(apiConfig, ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(productId).internalId)).then(function (settingsForPrelovedItems) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(settingsForPrelovedItems, (function (param) {
                                RescriptUtils__Logger.warn2(GqlMiddleware__Core__Infra.logger, "SettingsForPrelovedItems not found. productId:", productId);
                              })), makeSettingsForPrelovedItems);
            });
}

export {
  makeSettingsForPrelovedItems ,
  fetchSettingsForPrelovedItemsByProductId ,
}
/* ShopifyApi__Id Not a pure module */
