// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import $$Image from "next/legacy/image";

function make(value) {
  if (value >= 1 && value <= 100) {
    return {
            TAG: /* Ok */0,
            _0: value
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: "Quality out of bounds! Must be a value between 1 and 100!"
          };
  }
}

function makeExn(value) {
  if (value >= 1 && value <= 100) {
    return value;
  } else {
    return Js_exn.raiseError("Quality out of bounds! Must be a value between 1 and 100!");
  }
}

var Quality = {
  make: make,
  makeExn: makeExn
};

function ObjectPosition_float(prim) {
  return prim;
}

function ObjectPosition_string(prim) {
  return prim;
}

var ObjectPosition = {
  $$float: ObjectPosition_float,
  string: ObjectPosition_string
};

var make$1 = $$Image;

export {
  Quality ,
  ObjectPosition ,
  make$1 as make,
}
/* make Not a pure module */
