// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as GraphqlClient from "../../graphql-client/src/GraphqlClient.bs.js";

function query(config) {
  var partial_arg = [[
      "X-Shopify-Storefront-Access-Token",
      config.storefrontAccessToken
    ]];
  var partial_arg$1 = config.url;
  var func = function (param, param$1, param$2, param$3) {
    return GraphqlClient.query(partial_arg$1, partial_arg, param, param$1, param$2, param$3);
  };
  return function (param) {
    var func$1 = Curry._1(func, param);
    return function (param) {
      return Curry._2(func$1, param, "Shopify");
    };
  };
}

export {
  query ,
}
/* GraphqlClient Not a pure module */
