// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as StringUtils$DecidrIoUtils from "@decidr-io/utils/src/string/StringUtils.bs.js";

function makeTextWithCount(countText, otherText) {
  return countText + " " + otherText;
}

function makeSingularOrPluralText(count, single, multiple, zeroText, makeTextOpt, param) {
  var makeText = makeTextOpt !== undefined ? makeTextOpt : makeTextWithCount;
  if (count !== 0) {
    if (count !== 1) {
      return Curry._2(makeText, count.toString(), multiple);
    } else {
      return Curry._2(makeText, count.toString(), single);
    }
  } else {
    return zeroText;
  }
}

function splitStringAt(string, indexOf, from) {
  var indexForSplitting = string.indexOf(indexOf, from);
  if (indexForSplitting === -1) {
    return [
            string,
            ""
          ];
  } else {
    return [
            string.slice(0, indexForSplitting),
            string.slice(indexForSplitting)
          ];
  }
}

var toKebabCase = StringUtils$DecidrIoUtils.toKebabCase;

export {
  toKebabCase ,
  makeSingularOrPluralText ,
  splitStringAt ,
}
/* No side effect */
