// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";

var Raw = {};

function parse(value) {
  var value$1 = value.createShopifyCustomerAccessToken;
  return {
          createShopifyCustomerAccessToken: {
            customerAccessToken: value$1.customerAccessToken
          }
        };
}

function serialize(value) {
  var value$1 = value.createShopifyCustomerAccessToken;
  var value$2 = value$1.customerAccessToken;
  var createShopifyCustomerAccessToken = {
    customerAccessToken: value$2
  };
  return {
          createShopifyCustomerAccessToken: createShopifyCustomerAccessToken
        };
}

function serializeVariables(param) {
  
}

function createShopifyCustomerAccessToken(apiConfig) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: "mutation CreateShopifyCustomerAccessToken  {\ncreateShopifyCustomerAccessToken  {\ncustomerAccessToken  \n}\n\n}\n",
                    Raw: Raw,
                    parse: parse,
                    serialize: serialize,
                    serializeVariables: serializeVariables
                  }), undefined, undefined).then(function (param) {
              return {
                      customerAccessToken: param.createShopifyCustomerAccessToken.customerAccessToken
                    };
            });
}

export {
  createShopifyCustomerAccessToken ,
}
/* MarkotApi__Client Not a pure module */
