// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";
import * as Ui__Group from "../../../../../ui/src/group/components/Ui__Group.bs.js";
import * as App__Money from "../../../money/components/App__Money.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as App__Report from "../../../utils/App__Report.bs.js";
import * as AuthContext from "../../../auth/context/AuthContext.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "../../../../../ui/src/routing/Ui__AppLink.bs.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as ReactNative from "react-native";
import * as Ui__LinkButton from "../../../../../ui/src/linkButton/components/Ui__LinkButton.bs.js";
import * as Ui__MarkotIcon from "../../../../../ui/src/icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__MarkotText from "../../../../../ui/src/text/components/Ui__MarkotText.bs.js";
import * as Ui__ModalShell from "../../../../../ui/src/modalShell/Ui__ModalShell.bs.js";
import * as Ui__Navigation from "../../../../../ui/src/routing/navigation/Ui__Navigation.bs.js";
import * as Ui__Responsive from "../../../../../ui/src/responsive/Ui__Responsive.bs.js";
import * as Ui__PageSection from "../../../../../ui/src/pageSection/components/Ui__PageSection.bs.js";
import * as Ui__MarkotButton from "../../../../../ui/src/button/components/Ui__MarkotButton.bs.js";
import * as Ui__Toast__Error from "../../../../../ui/src/toast/components/error/Ui__Toast__Error.bs.js";
import * as Utils__Analytics from "../../../../../utils/src/analytics/Utils__Analytics.bs.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as App__BagItem__Card from "../../../bagItem/components/card/App__BagItem__Card.bs.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Ui__Expo__WebBrowser from "../../../../../ui/src/expo/webBrowser/Ui__Expo__WebBrowser.bs.js";
import * as App__AddAddressButton from "../../../address/components/addAddressButton/App__AddAddressButton.bs.js";
import * as Ignored$DecidrIoUtils from "@decidr-io/utils/src/functions/Ignored.bs.js";
import * as App__Bag__MakeCheckout from "../commons/App__Bag__MakeCheckout.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as App__Bag__CommonStyling from "../commons/App__Bag__CommonStyling.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as App__MarkotUser__AddressPicker from "../../../markotUser/addressPicker/App__MarkotUser__AddressPicker.bs.js";
import * as ReactNativeSafeAreaContext from "react-native-safe-area-context";
import * as App__Bag__MakeAnonymousCheckout from "../commons/App__Bag__MakeAnonymousCheckout.bs.js";
import * as App__Bag__ListPaginationFragment from "../commons/App__Bag__ListPaginationFragment.bs.js";
import * as AppBagListBagFragment_bag_graphql from "../../../../../relay-codegen/generated/AppBagListBagFragment_bag_graphql.bs.js";
import * as Dialog$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/dialog/Dialog.bs.js";
import * as GroupFactory$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/group/components/GroupFactory.bs.js";
import * as DecidrFlatList$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/list/components/DecidrFlatList.bs.js";
import * as AppBagListMarkotUserFragment_markotUser_graphql from "../../../../../relay-codegen/generated/AppBagListMarkotUserFragment_markotUser_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppBagListBagFragment_bag_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppBagListBagFragment_bag_graphql.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = ReactRelay.useFragment(AppBagListMarkotUserFragment_markotUser_graphql.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return RescriptRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return AppBagListMarkotUserFragment_markotUser_graphql.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var match = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          flex: 1,
          justifyContent: "space-between"
        }
      },
      button: {
        TAG: /* Static */0,
        _0: {
          alignSelf: "center"
        }
      },
      boldText: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "bold"
        }
      },
      buttonsContainer: {
        TAG: /* Static */0,
        _0: {
          justifyContent: "center"
        }
      },
      headingContainer: {
        TAG: /* Static */0,
        _0: {
          backgroundColor: "transparent"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

var widthLimiterStyle = Ui__Responsive.widthLimiterStyle(undefined, 768, undefined);

function App__Bag__List(Props) {
  var bag = Props.bag;
  var bagInfo = Props.bagInfo;
  var viewerInfo = Props.viewerInfo;
  var total = Props.total;
  var itemSpacingOpt = Props.itemSpacing;
  var paddingHorizontal = Props.paddingHorizontal;
  var showGoToBagButtonOpt = Props.showGoToBagButton;
  var listImageSize = Props.listImageSize;
  var itemStyle = Props.itemStyle;
  var contentContainerStyle = Props.contentContainerStyle;
  var refresh = Props.refresh;
  var refreshing = Props.refreshing;
  var itemSpacing = itemSpacingOpt !== undefined ? itemSpacingOpt : 0;
  var showGoToBagButton = showGoToBagButtonOpt !== undefined ? showGoToBagButtonOpt : false;
  var data = App__Bag__ListPaginationFragment.use(bag);
  var bagInfo$1 = use(bagInfo);
  var viewerInfo$1 = useOpt(viewerInfo);
  var match = App__Bag__MakeCheckout.use(undefined);
  var makingCheckout = match[1];
  var makeCheckout = match[0];
  var match$1 = App__Bag__MakeAnonymousCheckout.use(undefined);
  var makingAnonymousCheckout = match$1[1];
  var makeAnonymousCheckout = match$1[0];
  var listStyle = App__Bag__CommonStyling.useStyle(paddingHorizontal);
  var match$2 = Utils__Analytics.useSegmentAnalytics(undefined);
  var track = match$2.track;
  var match$3 = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match$3.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$4 = ReactNativeSafeAreaContext.useSafeAreaInsets();
  var isNativeScreenFocused = Ui__Navigation.useIsFocused(undefined);
  React.useEffect((function () {
          if (isNativeScreenFocused || ReactNative.Platform.OS === "web") {
            Curry._2(track, {
                  TAG: /* CartViewed */10,
                  _0: {
                    cartId: bagInfo$1.id,
                    currency: bagInfo$1.totalPrice.currency,
                    total: Belt_Float.fromString(bagInfo$1.totalPrice.amount),
                    quantity: bagInfo$1.totalQuantity,
                    products: Belt_Array.keepMap(Belt_Array.keep(data.bagWithArguments.edges, (function (singleEdge) {
                                return singleEdge.node.quantity !== 0;
                              })), (function (param) {
                            var node = param.node;
                            var match = node.merchandise;
                            if (typeof match !== "object") {
                              return ;
                            }
                            if (match.NAME !== "ProductVariant") {
                              return ;
                            }
                            var variant = match.VAL;
                            return {
                                    cartId: bagInfo$1.id,
                                    name: variant.product.name,
                                    brand: Belt_Option.mapWithDefault(variant.product.brand, "-", (function (param) {
                                            return param.name;
                                          })),
                                    variantId: variant.id,
                                    variantName: Belt_Array.joinWith(variant.attributeValues, " / ", (function (attributeValue) {
                                            if (attributeValue.value === "Default Title") {
                                              return "";
                                            } else {
                                              return attributeValue.value;
                                            }
                                          })),
                                    price: Belt_Float.fromString(variant.price.amount),
                                    currency: variant.price.currency,
                                    quantity: node.quantity,
                                    totalPrice: Belt_Option.map(Belt_Float.fromString(node.pricePerQuantity.amount), (function (amount) {
                                            return node.quantity * amount;
                                          })),
                                    imageUrl: Belt_Option.map(variant.mainImage, (function (param) {
                                            return param.url;
                                          }))
                                  };
                          }))
                  }
                }, "App__Bag__List cart opened");
          }
          
        }), [isNativeScreenFocused]);
  var match$5 = React.useState(function () {
        
      });
  var setError = match$5[1];
  var match$6 = React.useState(function () {
        return false;
      });
  var setVisible = match$6[1];
  var visible = match$6[0];
  var match$7 = React.useState(function () {
        
      });
  var setSelectedShippingAddressId = match$7[1];
  var selectedShippingAddressId = match$7[0];
  var match$8 = Curry._1(AuthContext.useAuth, undefined);
  var isAuthenticated = Belt_Option.mapWithDefault(match$8.authInfo, false, (function (param) {
          return param.status === "authenticated";
        }));
  var match$9 = Curry._1(Ui__AppLink.useRouting, undefined);
  var nativeNavigationHandler = match$9.nativeNavigationHandler;
  var proceedToCheckout = function (checkoutUrl) {
    if (ReactNative.Platform.OS === "web") {
      return Ignored$DecidrIoUtils.promise($$Promise.$$catch(Ui__Expo__WebBrowser.openBrowserAsync(checkoutUrl, {
                            windowName: "_self"
                          }, undefined).then(function (data) {
                          console.log("This is expo browser data", data);
                          return Promise.resolve(undefined);
                        }), (function (e) {
                        var msg;
                        try {
                          msg = "Unknown error occurred! Not a JsError!";
                        }
                        catch (raw_error){
                          var error = Caml_js_exceptions.internalToOCamlException(raw_error);
                          if (error.RE_EXN_ID === $$Promise.JsError) {
                            msg = Belt_Option.getWithDefault(error._1.message, "Unknown error occurred!");
                          } else {
                            throw error;
                          }
                        }
                        App__Report.unexpected("Something went wrong with expo browser, the error is: " + msg + ".", "App__Bag__List makeCheckout onCompleted");
                        Curry._1(setError, (function (param) {
                                return {
                                        title: "Error creating a checkout",
                                        message: "Could not open your checkout."
                                      };
                              }));
                        return Promise.reject(e);
                      })));
    }
    var routeConfiguration = Curry._3(Ui__AppLink.makeConfiguration, {
          TAG: /* ShopifyCheckout */10,
          url: checkoutUrl
        }, undefined, undefined);
    Belt_Option.forEach(nativeNavigationHandler, (function (navHandler) {
            Curry._1(navHandler, routeConfiguration);
          }));
  };
  var handleCheckout = function (param) {
    Curry._2(track, {
          TAG: /* CheckoutStarted */13,
          _0: {
            orderId: bagInfo$1.id,
            total: Belt_Float.fromString(bagInfo$1.totalPrice.amount),
            quantity: bagInfo$1.totalQuantity,
            currency: bagInfo$1.totalPrice.currency,
            products: Belt_Array.keepMap(Belt_Array.keep(data.bagWithArguments.edges, (function (singleEdge) {
                        return singleEdge.node.quantity !== 0;
                      })), (function (param) {
                    var node = param.node;
                    var match = node.merchandise;
                    if (typeof match !== "object") {
                      return ;
                    }
                    if (match.NAME !== "ProductVariant") {
                      return ;
                    }
                    var variant = match.VAL;
                    return {
                            variantId: variant.id,
                            name: variant.product.name,
                            brand: Belt_Option.mapWithDefault(variant.product.brand, "-", (function (param) {
                                    return param.name;
                                  })),
                            variantName: Belt_Array.joinWith(variant.attributeValues, " / ", (function (attributeValue) {
                                    if (attributeValue.value === "Default Title") {
                                      return "";
                                    } else {
                                      return attributeValue.value;
                                    }
                                  })),
                            price: Belt_Float.fromString(variant.price.amount),
                            currency: variant.price.currency,
                            quantity: node.quantity,
                            totalPrice: Belt_Option.map(Belt_Float.fromString(node.pricePerQuantity.amount), (function (amount) {
                                    return node.quantity * amount;
                                  })),
                            imageUrl: Belt_Option.map(variant.mainImage, (function (param) {
                                    return param.url;
                                  }))
                          };
                  }))
          }
        }, "App__Bag__List checkout started");
    if (isAuthenticated) {
      return Curry.app(makeCheckout, [
                  (function (param) {
                      App__Report.unexpected(param.message, "App__Bag__List makeCheckout onError");
                      Curry._1(setError, (function (param) {
                              return {
                                      title: "Error creating a checkout",
                                      message: "An error occurred trying to proceed to checkout. Please try again!"
                                    };
                            }));
                    }),
                  (function (result, errors) {
                      if (errors !== undefined) {
                        Curry._1(setError, (function (param) {
                                return {
                                        title: "Error creating a checkout",
                                        message: "Something went wrong. Please try again!"
                                      };
                              }));
                        return App__Report.unexpected(Belt_Array.joinWith(errors, ", ", (function (error) {
                                          return error.message;
                                        })), "App__Bag__List makeCheckout onCompleted");
                      } else {
                        return proceedToCheckout(Belt_Option.getWithDefault(result.makeCheckoutWithoutCustomerAccessToken.checkoutUrl, bagInfo$1.checkoutUrl));
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  {
                    bagId: bagInfo$1.id
                  },
                  undefined,
                  undefined
                ]);
    } else {
      return Curry.app(makeAnonymousCheckout, [
                  (function (param) {
                      App__Report.unexpected(param.message, "App__Bag__List makeAnonymousCheckout onError");
                      Curry._1(setError, (function (param) {
                              return {
                                      title: "Error creating a checkout",
                                      message: "An error occurred trying to proceed to checkout. Please try again!"
                                    };
                            }));
                    }),
                  (function (result, errors) {
                      if (errors !== undefined) {
                        Curry._1(setError, (function (param) {
                                return {
                                        title: "Error creating a checkout",
                                        message: "Something went wrong. Please try again!"
                                      };
                              }));
                        return App__Report.unexpected(Belt_Array.joinWith(errors, ", ", (function (error) {
                                          return error.message;
                                        })), "App__Bag__List makeAnonymousCheckout onCompleted");
                      }
                      var checkoutUrl = result.makeCheckout.checkoutUrl;
                      if (checkoutUrl !== undefined) {
                        return proceedToCheckout(checkoutUrl);
                      } else {
                        App__Report.unexpected("No checkout url returned from makeAnonymousCheckout", "App__Bag__List makeCheckout makeAnonymousCheckout");
                        return Curry._1(setError, (function (param) {
                                      return {
                                              title: "Error creating a checkout",
                                              message: "Something went wrong. Please try again!"
                                            };
                                    }));
                      }
                    }),
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  {
                    bagId: bagInfo$1.id
                  },
                  undefined,
                  undefined
                ]);
    }
  };
  var tmp = {
    data: Belt_Array.keep(data.bagWithArguments.edges, (function (singleEdge) {
            return singleEdge.node.quantity !== 0;
          })),
    itemSpacing: itemSpacing,
    renderItem: (function (param) {
        var tmp = {
          bagId: data.id,
          bagItem: param.item.node.fragmentRefs,
          onError: (function (title, message) {
              Curry._1(setError, (function (param) {
                      return {
                              title: title,
                              message: message
                            };
                    }));
            })
        };
        if (listImageSize !== undefined) {
          tmp.customImageSize = listImageSize;
        }
        if (itemStyle !== undefined) {
          tmp.style = Caml_option.valFromOption(itemStyle);
        }
        return React.createElement(App__BagItem__Card.make, tmp);
      }),
    keyExtractor: (function (param, param$1) {
        return param.node.id;
      }),
    contentContainerStyle: [
      Caml_option.some(listStyle),
      contentContainerStyle
    ]
  };
  var tmp$1 = refresh !== undefined && refreshing !== undefined ? Caml_option.some(React.createElement(ReactNative.RefreshControl, {
              onRefresh: refresh,
              refreshing: refreshing,
              tintColor: theme.colors.primary
            })) : undefined;
  if (tmp$1 !== undefined) {
    tmp.refreshControl = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(ReactNative.View, {
              style: Curry._1(styled, styles.container),
              children: null
            }, React.createElement(DecidrFlatList$DecidrIoUniversalComponents.make, tmp), React.createElement(GroupFactory$DecidrIoUniversalComponents.Spacer.make, {
                  size: theme.sectionSpacing._05
                }), React.createElement(Ui__Group.make, {
                  spacing: theme.sectionSpacing._05,
                  children: null
                }, React.createElement(Ui__PageSection.Heading.make, {
                      left: React.createElement(Ui__MarkotText.make, {
                            size: "b1",
                            children: "Total"
                          }),
                      right: React.createElement(Ui__MarkotText.make, {
                            size: "price2",
                            children: React.createElement(App__Money.make, {
                                  money: total
                                }),
                            style: Curry._1(styled, styles.boldText),
                            testID: "total-price-container"
                          }),
                      containerStyle: [
                        listStyle,
                        widthLimiterStyle,
                        Curry._1(styled, styles.headingContainer)
                      ]
                    }), React.createElement(Ui__Group.make, {
                      children: null,
                      containerStyle: Curry._1(styled, styles.buttonsContainer),
                      orientation: "horizontal",
                      testID: "buttons-container"
                    }, SimpleReact$DecidrIoUtils.whenTrue(showGoToBagButton, React.createElement(Ui__LinkButton.make, {
                              kind: "secondaryOutline",
                              routeConfiguration: {
                                route: /* Bag */13,
                                a11yLabel: "Go to bag page"
                              },
                              title: "Go to bag",
                              right: React.createElement(Ui__MarkotIcon.make, {
                                    name: "bag-outline",
                                    size: 11
                                  }),
                              testID: "go-to-bag-button"
                            })), React.createElement(Ui__MarkotButton.make, {
                          kind: "primary",
                          onPress: (function (param) {
                              handleCheckout(undefined);
                            }),
                          title: "Checkout",
                          containerStyle: Curry._1(styled, styles.button),
                          busy: makingCheckout || makingAnonymousCheckout
                        })), SimpleReact$DecidrIoUtils.whenTrue(ReactNative.Platform.OS === "ios" || ReactNative.Platform.OS === "android", React.createElement(GroupFactory$DecidrIoUniversalComponents.Spacer.make, {
                          size: match$4.bottom
                        })), React.createElement(React.Fragment, undefined, SimpleReact$DecidrIoUtils.renderIfSome(match$5[0], (function (error) {
                            return React.createElement(Ui__Toast__Error.make, {
                                        title: error.title,
                                        description: error.message,
                                        onDismiss: (function (param) {
                                            Curry._1(setError, (function (param) {
                                                    
                                                  }));
                                          })
                                      });
                          })), SimpleReact$DecidrIoUtils.renderIfSome(viewerInfo$1, (function (param) {
                            var fragmentRefs = param.fragmentRefs;
                            var tmp = {
                              markotUser: fragmentRefs,
                              onSelect: (function (selectedAddressId) {
                                  Curry._1(setSelectedShippingAddressId, (function (param) {
                                          return selectedAddressId;
                                        }));
                                })
                            };
                            if (selectedShippingAddressId !== undefined) {
                              tmp.selectedAddressId = selectedShippingAddressId;
                            }
                            return React.createElement(Ui__ModalShell.make, {
                                        visible: visible,
                                        onClose: (function (param) {
                                            Curry._1(setVisible, (function (param) {
                                                    return false;
                                                  }));
                                          }),
                                        children: React.createElement(Ui__Group.make, {
                                              children: null
                                            }, React.createElement(App__MarkotUser__AddressPicker.make, tmp), React.createElement(App__AddAddressButton.make, {
                                                  markotUser: fragmentRefs,
                                                  addressType: "Shipping"
                                                }), React.createElement(Ui__MarkotButton.make, {
                                                  kind: "primary",
                                                  onPress: (function (param) {
                                                      Curry._1(setVisible, (function (param) {
                                                              return false;
                                                            }));
                                                      handleCheckout(undefined);
                                                    }),
                                                  title: "Confirm",
                                                  disabled: Belt_Option.isNone(selectedShippingAddressId),
                                                  busy: makingCheckout || makingAnonymousCheckout
                                                })),
                                        title: "Choose shipphing address"
                                      });
                          })))));
}

var make = App__Bag__List;

export {
  make ,
}
/* match Not a pure module */
