// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Ui__Route from "./Ui__Route.bs.js";
import * as Ui__LinkFactory from "./Ui__LinkFactory.bs.js";

var include = Ui__LinkFactory.Make({
      toUrl: Ui__Route.toUrl,
      toMobileNav: Ui__Route.toMobileNav,
      unsafe_getRoute: Ui__Route.unsafe_getRoute
    });

var makeConfiguration = include.makeConfiguration;

var useRouting = include.useRouting;

var make = include.make;

export {
  makeConfiguration ,
  useRouting ,
  make ,
}
/* include Not a pure module */
