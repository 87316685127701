// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "../../../api-adapter-markot/src/MarkotApi__Id.bs.js";
import * as MarkotApi__Wallet from "../../../api-adapter-markot/src/MarkotApi__Wallet.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";

function makeWallet(param) {
  return {
          __typename: "Wallet",
          id: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.id)),
          currency: param.currency,
          balance: param.balance,
          availableToWithdraw: param.availableToWithdraw,
          lifetimeEarnings: param.lifetimeEarnings
        };
}

function fetchWallet(apiConfig) {
  return MarkotApi__Wallet.fetchWallet(apiConfig).then(function (__x) {
              return Belt_Option.map(__x, makeWallet);
            });
}

export {
  makeWallet ,
  fetchWallet ,
}
/* MarkotApi__Id Not a pure module */
