/* TypeScript file generated from Ui__OnboardingCircle.resi by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as Ui__OnboardingCircleBS__Es6Import from './Ui__OnboardingCircle.bs';
const Ui__OnboardingCircleBS: any = Ui__OnboardingCircleBS__Es6Import;

import type {Style_t as ReactNative_Style_t} from '../../../../../rescript-shims/ReactNative.shim';

import type {Typography_variant as Ui__Theme_Typography_variant} from '../../../../../packages/ui/src/theme/components/Ui__Theme.gen';

import type {WebText_accessibilityLevel as Ui__BaseText_WebText_accessibilityLevel} from '../../../../../packages/ui/src/text/components/Ui__BaseText.gen';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly titleStyle?: ReactNative_Style_t; 
  readonly containerStyle?: ReactNative_Style_t; 
  readonly titleHeadingLevel?: Ui__BaseText_WebText_accessibilityLevel; 
  readonly testID?: string; 
  readonly title: string; 
  readonly size?: number; 
  readonly textSize?: Ui__Theme_Typography_variant
};

export const make: React.ComponentType<{
  readonly titleStyle?: ReactNative_Style_t; 
  readonly containerStyle?: ReactNative_Style_t; 
  readonly titleHeadingLevel?: Ui__BaseText_WebText_accessibilityLevel; 
  readonly testID?: string; 
  readonly title: string; 
  readonly size?: number; 
  readonly textSize?: Ui__Theme_Typography_variant
}> = Ui__OnboardingCircleBS.make;
