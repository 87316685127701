// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as Ui__Button__CommonStyling from "../Ui__Button__CommonStyling.bs.js";
import * as FeatureFlags__LocalFeatureFlags from "../../../../feature-flags/src/local/FeatureFlags__LocalFeatureFlags.bs.js";

function kindFromString(kind) {
  switch (kind) {
    case "primary" :
        return "primary";
    case "secondary" :
        return "secondary";
    case "secondaryOutline" :
        return "secondaryOutline";
    default:
      return ;
  }
}

var match = Ui__Styling.describe({
      button: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center"
        },
        _1: (function (theme) {
            return {
                    borderRadius: theme.roundness.circle
                  };
          })
      },
      buttonElementsWrapper: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center"
        }
      },
      buttonText: {
        TAG: /* Static */0,
        _0: {
          textTransform: "uppercase"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__MarkotButton(Props) {
  var kind = Props.kind;
  var sizeOpt = Props.size;
  var onPress = Props.onPress;
  var title = Props.title;
  var pressedColor = Props.pressedColor;
  var disabledOpt = Props.disabled;
  var containerStyle = Props.containerStyle;
  var textStyles = Props.textStyles;
  var left = Props.left;
  var right = Props.right;
  var busyOpt = Props.busy;
  var testOnly_pressedOpt = Props.testOnly_pressed;
  var testIDOpt = Props.testID;
  var size = sizeOpt !== undefined ? sizeOpt : "large";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var busy = busyOpt !== undefined ? busyOpt : false;
  var testOnly_pressed = testOnly_pressedOpt !== undefined ? testOnly_pressedOpt : false;
  var testID = testIDOpt !== undefined ? testIDOpt : "markot-button";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = React.useState(function () {
        return false;
      });
  var setHovered = match$1[1];
  var hovered = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setPressed = match$2[1];
  var styled = Curry._1(stylesheet, theme);
  var a11yRoleButtonOnAllButtons = FeatureFlags__LocalFeatureFlags.use("a11yRoleButtonOnAllButtons");
  var match$3 = Ui__Button__CommonStyling.useButtonCommonStyling(busy, disabled, kind, pressedColor, size, match$2[0], hovered, left, right);
  var tmp = {
    backgroundColor: match$3.backgroundColor
  };
  if (match$3.borderColor !== undefined) {
    tmp.borderColor = Caml_option.valFromOption(match$3.borderColor);
  }
  if (match$3.borderWidth !== undefined) {
    tmp.borderWidth = match$3.borderWidth;
  }
  var tmp$1 = {
    color: match$3.textColor
  };
  var tmp$2 = Bool$DecidrIoUtils.thenSome(hovered, "underline");
  if (tmp$2 !== undefined) {
    tmp$1.textDecorationLine = (function () {
          switch (tmp$2) {
            case "none" :
                return "none";
            case "underline" :
                return "underline";
            case "lineThrough" :
                return "line-through";
            case "underlineLineThrough" :
                return "underline line-through";
            
          }
        })();
  }
  var tmp$3 = {
    activeOpacity: 1,
    style: [
      Caml_option.some(Curry._1(styled, styles.button)),
      Caml_option.some(tmp),
      match$3.containerPaddingStyle,
      containerStyle
    ],
    underlayColor: match$3.underlayColor,
    disabled: match$3.isDisabled,
    onPressIn: (function (param) {
        Curry._1(setPressed, (function (param) {
                return true;
              }));
      }),
    onPressOut: (function (param) {
        Curry._1(setPressed, (function (param) {
                return false;
              }));
      }),
    testID: testID,
    testOnly_pressed: testOnly_pressed,
    children: React.createElement(Ui__Group.make, {
          spacing: theme.spacing._3,
          children: null,
          containerStyle: Curry._1(styled, styles.buttonElementsWrapper),
          orientation: "horizontal"
        }, match$3.leftElement, React.createElement(Ui__MarkotText.make, {
              size: match$3.textSize,
              children: title,
              style: [
                Caml_option.some(Curry._1(styled, styles.buttonText)),
                Caml_option.some(tmp$1),
                textStyles
              ],
              testID: "" + testID + "-title"
            }), match$3.rightElement),
    onMouseEnter: (function (param) {
        Curry._1(setHovered, (function (param) {
                return true;
              }));
      }),
    onMouseLeave: (function (param) {
        Curry._1(setHovered, (function (param) {
                return false;
              }));
      })
  };
  var tmp$4 = a11yRoleButtonOnAllButtons === "off" ? undefined : "button";
  if (tmp$4 !== undefined) {
    tmp$3.accessibilityRole = Caml_option.valFromOption(tmp$4);
  }
  var tmp$5 = Belt_Option.map(onPress, (function (onPress, param) {
          Curry._1(onPress, undefined);
        }));
  if (tmp$5 !== undefined) {
    tmp$3.onPress = Caml_option.valFromOption(tmp$5);
  }
  return React.createElement(ReactNative.TouchableHighlight, tmp$3);
}

var make = Ui__MarkotButton;

export {
  kindFromString ,
  make ,
}
/* match Not a pure module */
