// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GraphqlCore__Types from "../../../graphql-core/src/GraphqlCore__Types.bs.js";
import * as MarkotApi__Wishlist from "../../../api-adapter-markot/src/MarkotApi__Wishlist.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";

function makeWishlistItem(id) {
  var match = GqlMiddleware__Core__NodeId.unpackExn(id);
  var unexpectedTypename = match.typename;
  if (unexpectedTypename === "ProductVariant") {
    return {
            TAG: /* ProductVariant */1,
            __typename: "ProductVariant",
            id: id,
            inWishlist: true
          };
  } else if (unexpectedTypename === "PrelovedItemInfo") {
    return {
            TAG: /* PrelovedItemInfo */2,
            __typename: "PrelovedItemInfo",
            id: id,
            inWishlist: true
          };
  } else if (unexpectedTypename === "InfluencerBundle") {
    return {
            TAG: /* InfluencerBundle */4,
            __typename: "InfluencerBundle",
            id: id,
            inWishlist: true
          };
  } else if (unexpectedTypename === "MarkotBundle") {
    return {
            TAG: /* MarkotBundle */3,
            __typename: "MarkotBundle",
            id: id,
            inWishlist: true
          };
  } else if (unexpectedTypename === "Product") {
    return {
            TAG: /* Product */0,
            __typename: "Product",
            id: id,
            inWishlist: true
          };
  } else {
    return Js_exn.raiseError("Unexpected typename found in wishlist. " + unexpectedTypename + ":" + GqlMiddleware__Core__NodeId.toString(id));
  }
}

function fetchWishlist(apiConfig, first, after) {
  return MarkotApi__Wishlist.$$fetch(apiConfig, first, after).then(function (__x) {
              return Belt_Option.map(__x, (function (__x) {
                            return GraphqlCore__Types.ConnectionWithTotal.map(__x, (function (node) {
                                          return makeWishlistItem(GqlMiddleware__Core__NodeId.parseExn(node.id));
                                        }));
                          }));
            });
}

function addToWishlist(apiConfig, itemId) {
  return MarkotApi__Wishlist.addToWishlist(apiConfig, itemId).then(function (param) {
              return {
                      wishlistItemId: itemId,
                      wishlistItem: makeWishlistItem(GqlMiddleware__Core__NodeId.parseExn(itemId))
                    };
            });
}

function removeFromWishlist(apiConfig, itemId) {
  return MarkotApi__Wishlist.removeFromWishlist(apiConfig, itemId).then(function (param) {
              return {
                      wishlistItemId: itemId,
                      wishlistItem: makeWishlistItem(GqlMiddleware__Core__NodeId.parseExn(itemId))
                    };
            });
}

export {
  makeWishlistItem ,
  fetchWishlist ,
  addToWishlist ,
  removeFromWishlist ,
}
/* MarkotApi__Wishlist Not a pure module */
