// Generated by ReScript, PLEASE EDIT WITH CARE


function unknownEnumMember(value) {
  console.warn("Unknown enum value received", value);
}

function unknownUnionMember(typename) {
  console.warn("Unknown union type received", typename);
}

function unknownUnionMember0(param) {
  console.warn("Unknown union type received", param.VAL);
}

function unexpected(message, context) {
  console.warn("Unexpected thing happened in ", context, message);
}

export {
  unknownEnumMember ,
  unknownUnionMember ,
  unknownUnionMember0 ,
  unexpected ,
}
/* No side effect */
