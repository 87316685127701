// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ShopifyApi__Id from "./ShopifyApi__Id.bs.js";
import * as ShopifyApi__Infra from "./ShopifyApi__Infra.bs.js";
import * as ShopifyApi__Client from "./ShopifyApi__Client.bs.js";
import * as ShopifyApi__Products from "./ShopifyApi__Products.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as ShopifyApi__Influencers from "./ShopifyApi__Influencers.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as ShopifyApi__CommonFragments from "./ShopifyApi__CommonFragments.bs.js";
import * as ShopifyApi__TitleWithMetadata from "./ShopifyApi__TitleWithMetadata.bs.js";

var query = "fragment BundleFields on Collection   {\nid  \ntitle  \nhandle  \nimage  {\n...ImageFields   \n}\n\ninfluencer: metafield(namespace: \"real-concepts\", key: \"ibundle-influencer\")  {\nid  \nreference  {\n__typename\n...on Page   {\n...InfluencerFields   \n}\n\n}\n\n}\n\nseo  {\ntitle  \ndescription  \n}\n\n}\n" + ShopifyApi__CommonFragments.ImageFields.query + ShopifyApi__Influencers.InfluencerFields.query;

function parse(value) {
  var value$1 = value.image;
  var value$2 = value.influencer;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.reference;
    var tmp$1;
    if (value$3 == null) {
      tmp$1 = undefined;
    } else {
      var typename = value$3["__typename"];
      var tmp$2 = typename === "Page" ? ({
            NAME: "Page",
            VAL: ShopifyApi__Influencers.InfluencerFields.verifyArgsAndParse("InfluencerFields", value$3)
          }) : ({
            NAME: "FutureAddedValue",
            VAL: value$3
          });
      tmp$1 = tmp$2;
    }
    tmp = {
      id: value$2.id,
      reference: tmp$1
    };
  }
  var value$4 = value.seo;
  var value$5 = value$4.title;
  var value$6 = value$4.description;
  return {
          id: value.id,
          title: ShopifyApi__TitleWithMetadata.parse(value.title),
          handle: value.handle,
          image: !(value$1 == null) ? ShopifyApi__CommonFragments.ImageFields.verifyArgsAndParse("ImageFields", value$1) : undefined,
          influencer: tmp,
          seo: {
            title: !(value$5 == null) ? value$5 : undefined,
            description: !(value$6 == null) ? value$6 : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.seo;
  var value$2 = value$1.description;
  var description = value$2 !== undefined ? value$2 : null;
  var value$3 = value$1.title;
  var title = value$3 !== undefined ? value$3 : null;
  var seo = {
    title: title,
    description: description
  };
  var value$4 = value.influencer;
  var influencer;
  if (value$4 !== undefined) {
    var value$5 = value$4.reference;
    var reference = value$5 !== undefined ? (
        value$5.NAME === "Page" ? ShopifyApi__Influencers.InfluencerFields.serialize(value$5.VAL) : value$5.VAL
      ) : null;
    var value$6 = value$4.id;
    influencer = {
      id: value$6,
      reference: reference
    };
  } else {
    influencer = null;
  }
  var value$7 = value.image;
  var image = value$7 !== undefined ? ShopifyApi__CommonFragments.ImageFields.serialize(value$7) : null;
  var value$8 = value.handle;
  var value$9 = value.title;
  var value$10 = ShopifyApi__TitleWithMetadata.serialize(value$9);
  var value$11 = value.id;
  return {
          id: value$11,
          title: value$10,
          handle: value$8,
          image: image,
          influencer: influencer,
          seo: seo
        };
}

var Raw = {};

var query$1 = "query Bundles($first: Int, $after: String)  {\ncollections(first: $first, after: $after, query: \"[influencer-bundle] OR [markot-bundle]\")  {\npageInfo  {\nstartCursor  \nendCursor  \nhasPreviousPage  \nhasNextPage  \n}\n\nedges  {\ncursor  \nnode  {\n...BundleFields   \n}\n\n}\n\n}\n\n}\n" + query;

function parse$1(value) {
  var value$1 = value.collections;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          collections: {
            pageInfo: {
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined,
              hasPreviousPage: value$2.hasPreviousPage,
              hasNextPage: value$2.hasNextPage
            },
            edges: Js_array.map((function (value) {
                    return {
                            cursor: value.cursor,
                            node: parse(value.node)
                          };
                  }), value$5)
          }
        };
}

function serialize$1(value) {
  var value$1 = value.collections;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var node = serialize(value$1);
          var value$2 = value.cursor;
          return {
                  cursor: value$2,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var value$5 = value$3.hasPreviousPage;
  var value$6 = value$3.endCursor;
  var endCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$3.startCursor;
  var startCursor = value$7 !== undefined ? value$7 : null;
  var pageInfo = {
    startCursor: startCursor,
    endCursor: endCursor,
    hasPreviousPage: value$5,
    hasNextPage: value$4
  };
  var collections = {
    pageInfo: pageInfo,
    edges: edges
  };
  return {
          collections: collections
        };
}

function serializeVariables(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  return {
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined
        };
}

var Raw$1 = {};

var query$2 = "query Bundle($id: ID!)  {\ncollection(id: $id)  {\n...BundleFields   \n}\n\n}\n" + query;

function parse$2(value) {
  var value$1 = value.collection;
  return {
          collection: !(value$1 == null) ? parse(value$1) : undefined
        };
}

function serialize$2(value) {
  var value$1 = value.collection;
  var collection = value$1 !== undefined ? serialize(value$1) : null;
  return {
          collection: collection
        };
}

function serializeVariables$1(inp) {
  return {
          id: inp.id
        };
}

var Raw$2 = {};

function parse$3(value) {
  var value$1 = value.collection;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.products;
    var value$3 = value$2.edges;
    tmp = {
      products: {
        edges: Js_array.map((function (value) {
                return {
                        cursor: value.cursor
                      };
              }), value$3)
      }
    };
  }
  return {
          collection: tmp
        };
}

function serialize$3(value) {
  var value$1 = value.collection;
  var collection;
  if (value$1 !== undefined) {
    var value$2 = value$1.products;
    var value$3 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.cursor;
            return {
                    cursor: value$1
                  };
          }), value$3);
    var products = {
      edges: edges
    };
    collection = {
      products: products
    };
  } else {
    collection = null;
  }
  return {
          collection: collection
        };
}

function serializeVariables$2(inp) {
  return {
          collectionId: inp.collectionId
        };
}

var Raw$3 = {};

var query$3 = "query BundleItems($collectionId: ID!, $first: Int, $after: String, $loadMedia: Boolean!, $includeMarkdownDescription: Boolean!, $includeRefurbishedInfo: Boolean!, $includeOverstockedInfo: Boolean!)  {\ncollection(id: $collectionId)  {\nproducts(first: $first, after: $after)  {\npageInfo  {\nendCursor  \nhasNextPage  \nhasPreviousPage  \nstartCursor  \n}\n\nedges  {\ncursor  \nnode  {\n...ProductFields   \n}\n\n}\n\n}\n\n}\n\n}\n" + ShopifyApi__Products.ProductFields.query;

function parse$4(value) {
  var value$1 = value.collection;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.products;
    var value$3 = value$2.pageInfo;
    var value$4 = value$3.endCursor;
    var value$5 = value$3.startCursor;
    var value$6 = value$2.edges;
    tmp = {
      products: {
        pageInfo: {
          endCursor: !(value$4 == null) ? value$4 : undefined,
          hasNextPage: value$3.hasNextPage,
          hasPreviousPage: value$3.hasPreviousPage,
          startCursor: !(value$5 == null) ? value$5 : undefined
        },
        edges: Js_array.map((function (value) {
                var value$1 = value.node;
                return {
                        cursor: value.cursor,
                        node: ShopifyApi__Products.ProductFields.verifyArgsAndParse("NonNull_Boolean", "NonNull_Boolean", "NonNull_Boolean", "NonNull_Boolean", "ProductFields", value$1)
                      };
              }), value$6)
      }
    };
  }
  return {
          collection: tmp
        };
}

function serialize$4(value) {
  var value$1 = value.collection;
  var collection;
  if (value$1 !== undefined) {
    var value$2 = value$1.products;
    var value$3 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var node = ShopifyApi__Products.ProductFields.serialize(value$1);
            var value$2 = value.cursor;
            return {
                    cursor: value$2,
                    node: node
                  };
          }), value$3);
    var value$4 = value$2.pageInfo;
    var value$5 = value$4.startCursor;
    var startCursor = value$5 !== undefined ? value$5 : null;
    var value$6 = value$4.hasPreviousPage;
    var value$7 = value$4.hasNextPage;
    var value$8 = value$4.endCursor;
    var endCursor = value$8 !== undefined ? value$8 : null;
    var pageInfo = {
      endCursor: endCursor,
      hasNextPage: value$7,
      hasPreviousPage: value$6,
      startCursor: startCursor
    };
    var products = {
      pageInfo: pageInfo,
      edges: edges
    };
    collection = {
      products: products
    };
  } else {
    collection = null;
  }
  return {
          collection: collection
        };
}

function serializeVariables$3(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  return {
          collectionId: inp.collectionId,
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined,
          loadMedia: inp.loadMedia,
          includeMarkdownDescription: inp.includeMarkdownDescription,
          includeRefurbishedInfo: inp.includeRefurbishedInfo,
          includeOverstockedInfo: inp.includeOverstockedInfo
        };
}

function makeVariables(collectionId, first, after, loadMedia, includeMarkdownDescription, includeRefurbishedInfo, includeOverstockedInfo, param) {
  return {
          collectionId: collectionId,
          first: first,
          after: after,
          loadMedia: loadMedia,
          includeMarkdownDescription: includeMarkdownDescription,
          includeRefurbishedInfo: includeRefurbishedInfo,
          includeOverstockedInfo: includeOverstockedInfo
        };
}

function tryDetermineKind(param) {
  var title = param.title;
  var match = ShopifyApi__TitleWithMetadata.peek(title);
  var match$1 = match.tag;
  if (match$1 !== undefined) {
    switch (match$1) {
      case "influencer-bundle" :
          return "InfluencerBundle";
      case "markot-bundle" :
          return "MarkotBundle";
      default:
        
    }
  }
  RescriptUtils__Logger.warn(ShopifyApi__Infra.logger, "Bundle kind could not be determined from title: \"" + ShopifyApi__TitleWithMetadata.rawText(title) + "\"");
}

function tryMakeInfluencerFromMetafield(param) {
  var reference = param.reference;
  if (reference !== undefined) {
    if (reference.NAME === "Page") {
      return ShopifyApi__Influencers.makeInfluencer(reference.VAL);
    } else {
      RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "tryMakeInfluencerFromMetafield - metafield reference of unknown type found", reference);
      return ;
    }
  } else {
    RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "tryMakeInfluencerFromMetafield - metafield reference None found. Id:", param.id);
    return ;
  }
}

function makeBundle(data) {
  var seo = data.seo;
  var image = data.image;
  var handle = data.handle;
  var title = data.title;
  var id = data.id;
  var match = tryDetermineKind(data);
  var match$1 = Belt_Option.flatMap(data.influencer, tryMakeInfluencerFromMetafield);
  if (match !== undefined) {
    if (match === "MarkotBundle") {
      if (match$1 !== undefined) {
        RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "<Unexpected> Markot bundle contains info about an influencer", match$1);
      }
      return {
              TAG: /* MarkotBundle */1,
              id: ShopifyApi__Id.parseExn(id),
              name: ShopifyApi__TitleWithMetadata.toTitle(title),
              slug: handle,
              image: Belt_Option.map(image, ShopifyApi__CommonFragments.makeImage),
              seo: {
                description: seo.description,
                title: seo.title
              }
            };
    } else if (match$1 !== undefined) {
      return {
              TAG: /* InfluencerBundle */0,
              id: ShopifyApi__Id.parseExn(id),
              name: ShopifyApi__TitleWithMetadata.toTitle(title),
              slug: handle,
              image: Belt_Option.map(image, ShopifyApi__CommonFragments.makeImage),
              influencer: match$1,
              seo: {
                description: seo.description,
                title: seo.title
              }
            };
    } else {
      RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "<Unexpected> Influencer bundle doesn't have an influencer", data);
      return Js_exn.raiseError("Could not create InfluencerBundle");
    }
  } else {
    RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "Could not create bundle from data", data);
    return Js_exn.raiseError("Could not create bundle");
  }
}

function fetchBundle(apiConfig, id) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$2,
                    Raw: Raw$1,
                    parse: parse$2,
                    serialize: serialize$2,
                    serializeVariables: serializeVariables$1
                  }), {
                id: ShopifyApi__Id.toString(id)
              }, undefined).then(function (param) {
              return RescriptUtils__OptionPlus.tapNone(Belt_Option.map(param.collection, makeBundle), (function (param) {
                            RescriptUtils__Logger.error2(ShopifyApi__Infra.logger, (new Error("Bundle collection not found.")), {
                                  id: id
                                });
                          }));
            });
}

function fetchBundles(apiConfig, first, after, param) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$1,
                    Raw: Raw,
                    parse: parse$1,
                    serialize: serialize$1,
                    serializeVariables: serializeVariables
                  }), {
                first: first,
                after: after
              }, undefined).then(function (param) {
              var match = param.collections;
              var pageInfo = match.pageInfo;
              return {
                      pageInfo: {
                        startCursor: pageInfo.startCursor,
                        endCursor: pageInfo.endCursor,
                        hasNextPage: pageInfo.hasNextPage,
                        hasPreviousPage: pageInfo.hasPreviousPage
                      },
                      edges: Belt_Array.map(match.edges, (function (edge) {
                              return {
                                      cursor: edge.cursor,
                                      node: makeBundle(edge.node)
                                    };
                            }))
                    };
            });
}

function fetchItemCount(apiConfig, id) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: "query BundleItemCount($collectionId: ID!)  {\ncollection(id: $collectionId)  {\nproducts(first: 25)  {\nedges  {\ncursor  \n}\n\n}\n\n}\n\n}\n",
                    Raw: Raw$2,
                    parse: parse$3,
                    serialize: serialize$3,
                    serializeVariables: serializeVariables$2
                  }), {
                collectionId: ShopifyApi__Id.toString(id)
              }, undefined).then(function (param) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(param.collection, (function (param) {
                                RescriptUtils__Logger.error2(ShopifyApi__Infra.logger, (new Error("Bundle collection not found.")), {
                                      id: id
                                    });
                              })), (function (collection) {
                            return collection.products.edges.length;
                          }));
            });
}

function fetchItems(apiConfig, id, first, after, loadMediaOpt, includeMarkdownDescription, includeRefurbishedInfo, includeOverstockedInfo, param) {
  var loadMedia = loadMediaOpt !== undefined ? loadMediaOpt : false;
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$3,
                    Raw: Raw$3,
                    parse: parse$4,
                    serialize: serialize$4,
                    serializeVariables: serializeVariables$3
                  }), makeVariables(ShopifyApi__Id.toString(id), first, after, loadMedia, includeMarkdownDescription, includeRefurbishedInfo, includeOverstockedInfo, undefined), undefined).then(function (param) {
              return Belt_Option.map(param.collection, (function (param) {
                            var match = param.products;
                            var pageInfo = match.pageInfo;
                            return {
                                    pageInfo: {
                                      startCursor: pageInfo.startCursor,
                                      endCursor: pageInfo.endCursor,
                                      hasNextPage: pageInfo.hasNextPage,
                                      hasPreviousPage: pageInfo.hasPreviousPage
                                    },
                                    edges: Belt_Array.map(match.edges, (function (param) {
                                            return {
                                                    cursor: param.cursor,
                                                    node: ShopifyApi__Products.makeProduct(param.node)
                                                  };
                                          }))
                                  };
                          }));
            });
}

export {
  fetchBundle ,
  fetchBundles ,
  fetchItemCount ,
  fetchItems ,
}
/* ShopifyApi__Id Not a pure module */
