// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function money(param) {
  return {
          amount: param.amount,
          currency: param.currency
        };
}

function tryMakeImageResource(param) {
  var alt = param.alt;
  var url = param.url;
  return Belt_Option.map(param.size, (function (param) {
                return {
                        __typename: "ImageResource",
                        alt: alt,
                        url: url,
                        size: {
                          width: param.width,
                          height: param.height
                        }
                      };
              }));
}

function tryMakeProductMedia(param) {
  var alt = param.alt;
  var url = param.url;
  var id = param.id;
  return Belt_Option.map(param.size, (function (param) {
                return {
                        id: id,
                        item: {
                          __typename: "ImageResource",
                          alt: alt,
                          url: url,
                          size: {
                            width: param.width,
                            height: param.height
                          }
                        }
                      };
              }));
}

export {
  money ,
  tryMakeImageResource ,
  tryMakeProductMedia ,
}
/* No side effect */
