// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "../../../api-adapter-markot/src/MarkotApi__Id.bs.js";
import * as ShopifyApi__Id from "../../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as GraphqlCore__Types from "../../../graphql-core/src/GraphqlCore__Types.bs.js";
import * as MarkotApi__DraftListing from "../../../api-adapter-markot/src/MarkotApi__DraftListing.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Entity__Listing from "./GqlMiddleware__Entity__Listing.bs.js";
import * as GqlMiddleware__Core__FromMarkot from "../core/GqlMiddleware__Core__FromMarkot.bs.js";
import * as GqlMiddleware__Entity__PrelovedItemMedia from "./GqlMiddleware__Entity__PrelovedItemMedia.bs.js";
import * as GqlMiddleware__Entity__PricingOptionSetting from "./GqlMiddleware__Entity__PricingOptionSetting.bs.js";
import * as GqlMiddleware__Entity__PrelovedItemCondition from "./GqlMiddleware__Entity__PrelovedItemCondition.bs.js";

function makeDraftListingId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "DraftListing", param);
}

function makeDraftListing(param) {
  return {
          __typename: "DraftListing",
          updatedAt: param.updatedAt,
          id: GqlMiddleware__Core__NodeId.make(1, "DraftListing", MarkotApi__Id.unpackExn(param.id).internalId),
          productId: Belt_Option.map(param.productId, (function (productId) {
                  return GqlMiddleware__Core__NodeId.make(1, "Product", ShopifyApi__Id.toString(productId));
                })),
          description: param.description,
          condition2: Belt_Option.map(param.condition, GqlMiddleware__Entity__PrelovedItemCondition.fromMarkotApi),
          mainImage: Belt_Option.map(param.mainImage, GqlMiddleware__Core__FromMarkot.imageResource),
          media: Belt_Option.map(param.media, (function (__x) {
                  return Belt_Array.map(__x, GqlMiddleware__Entity__PrelovedItemMedia.makePrelovedItemMedia);
                })),
          billingAddressId: Belt_Option.map(param.billingAddressId, (function (billingAddressId) {
                  return GqlMiddleware__Core__NodeId.make(1, "Address", MarkotApi__Id.unpackExn(billingAddressId).internalId);
                })),
          pickupAddressId: Belt_Option.map(param.pickupAddressId, (function (pickupAddressId) {
                  return GqlMiddleware__Core__NodeId.make(1, "Address", MarkotApi__Id.unpackExn(pickupAddressId).internalId);
                })),
          pricing: Belt_Option.map(param.pricing, GqlMiddleware__Entity__PricingOptionSetting.makePricingOption),
          usedForTesting: param.usedForTesting,
          product2: Belt_Option.map(param.product2, (function (product) {
                  if (product.NAME !== "SuggestedProductInfo") {
                    return {
                            TAG: /* ProductInfo */0,
                            _0: {
                              __typename: "ProductInfo",
                              productId: GqlMiddleware__Core__NodeId.make(1, "Product", MarkotApi__Id.unpackExn(product.VAL.productId).internalId)
                            }
                          };
                  }
                  var match = product.VAL;
                  return {
                          TAG: /* SuggestedProductInfo */1,
                          _0: {
                            __typename: "SuggestedProductInfo",
                            productName: match.productName,
                            brandName: match.brandName
                          }
                        };
                }))
        };
}

function fetchDraftListing(apiConfig, id) {
  return MarkotApi__DraftListing.fetchBasicData(apiConfig, GqlMiddleware__Core__NodeId.toString(id), false).then(function (__x) {
              return Belt_Option.map(__x, makeDraftListing);
            });
}

function fetchDraftListings(apiConfig, first, after) {
  return MarkotApi__DraftListing.fetchDraftListings(apiConfig, first, after, false).then(function (__x) {
              return GraphqlCore__Types.ConnectionWithTotal.map(__x, makeDraftListing);
            });
}

function executeCreateDraftListing(apiConfig, param) {
  return MarkotApi__DraftListing.executeCreateDraftListing(apiConfig, {
                productId: MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(param.productId)),
                productSlug: param.productSlug
              }).then(function (param) {
              return {
                      __typename: "CreateDraftListingPayload",
                      draftListing: makeDraftListing(param.draftListing)
                    };
            });
}

function executeCreateDraftListing2(apiConfig, param) {
  return MarkotApi__DraftListing.executeCreateDraftListing2(apiConfig, {
                product: Belt_Option.map(param.product, (function (param) {
                        return {
                                productId: MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(param.id)),
                                productSlug: param.slug
                              };
                      })),
                suggestedProduct: Belt_Option.map(param.suggestedProduct, (function (param) {
                        return {
                                productName: param.productName,
                                brandName: param.brandName
                              };
                      }))
              }).then(function (param) {
              return {
                      __typename: "CreateDraftListingPayload",
                      draftListing: makeDraftListing(param.draftListing)
                    };
            });
}

function executeUpdateDraftListing(apiConfig, param) {
  return MarkotApi__DraftListing.executeUpdateDraftListing(apiConfig, {
                id: MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(param.id)),
                buyNowPrice: param.buyNowPrice,
                condition: Belt_Option.flatMap(param.condition, GqlMiddleware__Entity__PrelovedItemCondition.toMarkotApi),
                description: param.description,
                pickupAddressId: Belt_Option.map(Belt_Option.map(param.pickupAddressId, GqlMiddleware__Core__NodeId.toString), MarkotApi__Id.parseExn),
                billingAddressId: Belt_Option.map(Belt_Option.map(param.billingAddressId, GqlMiddleware__Core__NodeId.toString), MarkotApi__Id.parseExn)
              }).then(function (param) {
              return {
                      __typename: "UpdateDraftListingPayload",
                      draftListing: makeDraftListing(param.draftListing)
                    };
            });
}

function executeDeleteDraftListing(apiConfig, id) {
  return MarkotApi__DraftListing.executeDeleteDraftListing(apiConfig, id).then(function (param) {
              return {
                      __typename: "DeleteDraftListingPayload",
                      deletedDraftListingId: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.deletedDraftListingId))
                    };
            });
}

function executeUploadDraftListingMedia(apiConfig, draftListingId, mediaFileInBase64, mediaTag) {
  return MarkotApi__DraftListing.executeUploadDraftListingMedia(apiConfig, MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(draftListingId)), mediaFileInBase64, mediaTag).then(function (param) {
              return {
                      __typename: "UploadDraftListingMediaPayload",
                      media: GqlMiddleware__Entity__PrelovedItemMedia.makePrelovedItemMedia(param.media),
                      draftListing: makeDraftListing(param.draftListing)
                    };
            });
}

function executeDeleteDraftListingMedia(apiConfig, id) {
  return MarkotApi__DraftListing.executeDeleteDraftListingMedia(apiConfig, id).then(function (param) {
              return {
                      __typename: "DeleteDraftListingMediaPayload",
                      mediaId: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.mediaId))
                    };
            });
}

function executeSubmitDraftListingForApproval(apiConfig, id) {
  return MarkotApi__DraftListing.executeSubmitDraftListingForApproval(apiConfig, MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(id))).then(GqlMiddleware__Entity__Listing.makeListing);
}

function fetchPrelovedItemMediaByDraftListingId(apiConfig, draftListingId) {
  return MarkotApi__DraftListing.fetchPrelovedItemMediaByDraftListingId(apiConfig, MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(draftListingId))).then(function (media) {
              return Belt_Option.map(media, (function (m) {
                            return Belt_Array.map(m, GqlMiddleware__Entity__PrelovedItemMedia.makePrelovedItemMedia);
                          }));
            });
}

export {
  makeDraftListingId ,
  makeDraftListing ,
  fetchDraftListing ,
  fetchDraftListings ,
  executeCreateDraftListing ,
  executeCreateDraftListing2 ,
  executeUpdateDraftListing ,
  executeDeleteDraftListing ,
  executeUploadDraftListingMedia ,
  executeDeleteDraftListingMedia ,
  executeSubmitDraftListingForApproval ,
  fetchPrelovedItemMediaByDraftListingId ,
}
/* MarkotApi__Id Not a pure module */
