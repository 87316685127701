// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ShopifyApi__Id from "./ShopifyApi__Id.bs.js";
import * as ShopifyApi__Infra from "./ShopifyApi__Infra.bs.js";
import * as ShopifyApi__Client from "./ShopifyApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";

function parse(value) {
  var value$1 = value.seo;
  var value$2 = value$1.title;
  var value$3 = value$1.description;
  return {
          id: value.id,
          handle: value.handle,
          seo: {
            title: !(value$2 == null) ? value$2 : undefined,
            description: !(value$3 == null) ? value$3 : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.seo;
  var value$2 = value$1.description;
  var description = value$2 !== undefined ? value$2 : null;
  var value$3 = value$1.title;
  var title = value$3 !== undefined ? value$3 : null;
  var seo = {
    title: title,
    description: description
  };
  var value$4 = value.handle;
  var value$5 = value.id;
  return {
          id: value$5,
          handle: value$4,
          seo: seo
        };
}

var Raw = {};

var query = "query ProductCollectionByHandle($handle: String!)  {\ncollection(handle: $handle)  {\n...ProductCollectionFields   \n}\n\n}\nfragment ProductCollectionFields on Collection   {\nid  \nhandle  \nseo  {\ntitle  \ndescription  \n}\n\n}\n";

function parse$1(value) {
  var value$1 = value.collection;
  return {
          collection: !(value$1 == null) ? parse(value$1) : undefined
        };
}

function serialize$1(value) {
  var value$1 = value.collection;
  var collection = value$1 !== undefined ? serialize(value$1) : null;
  return {
          collection: collection
        };
}

function serializeVariables(inp) {
  return {
          handle: inp.handle
        };
}

function makeProductCollection(param) {
  var match = param.seo;
  return {
          id: ShopifyApi__Id.parseExn(param.id),
          name: Belt_Option.map(match.title, (function (prim) {
                  return prim.trim();
                })),
          description: match.description,
          slug: param.handle
        };
}

function fetchProductCollectionByHandle(apiConfig, handle, logWarning) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query,
                    Raw: Raw,
                    parse: parse$1,
                    serialize: serialize$1,
                    serializeVariables: serializeVariables
                  }), {
                handle: handle
              }, undefined).then(function (param) {
              return RescriptUtils__OptionPlus.tapNone(Belt_Option.map(param.collection, makeProductCollection), (function (param) {
                            if (logWarning) {
                              return RescriptUtils__Logger.info2(ShopifyApi__Infra.logger, "ProductCollection collection not found. Handle:", handle);
                            }
                            
                          }));
            });
}

export {
  fetchProductCollectionByHandle ,
}
/* ShopifyApi__Id Not a pure module */
