// Generated by ReScript, PLEASE EDIT WITH CARE


function toMarkotApi(condition) {
  if (condition === "WellLoved") {
    return "WellLoved";
  } else if (condition === "AsGoodAsNew") {
    return "AsGoodAsNew";
  } else if (condition === "Good") {
    return "Good";
  } else if (condition === "NeverUsed") {
    return "NeverUsedWithoutPackaging";
  } else {
    return ;
  }
}

function fromMarkotApi(condition) {
  if (condition === "WellLoved") {
    return "WellLoved";
  } else if (condition === "AsGoodAsNew") {
    return "AsGoodAsNew";
  } else if (condition === "Good") {
    return "Good";
  } else {
    return "NeverUsed";
  }
}

export {
  toMarkotApi ,
  fromMarkotApi ,
}
/* No side effect */
