// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function focusNextField(passedRef) {
  Belt_Option.forEach(Caml_option.nullable_to_opt(passedRef.current), (function (passedRef) {
          passedRef.focus();
        }));
}

export {
  focusNextField ,
}
/* No side effect */
