// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MarkotApi__WishlistItem from "../../../api-adapter-markot/src/MarkotApi__WishlistItem.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";

function isInWishlist(apiConfig, itemId) {
  return MarkotApi__WishlistItem.inWishlist(apiConfig, GqlMiddleware__Core__NodeId.toString(itemId));
}

export {
  isInWishlist ,
}
/* MarkotApi__WishlistItem Not a pure module */
