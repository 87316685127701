// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as MarkotApi__Infra from "./MarkotApi__Infra.bs.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as MarkotApi__AuctionCommons from "./MarkotApi__AuctionCommons.bs.js";
import * as MarkotApi__CommonFragments from "./MarkotApi__CommonFragments.bs.js";
import * as MarkotApi__Scalars__DateTime from "./MarkotApi__Scalars__DateTime.bs.js";

var Raw = {};

var query = "fragment PrelovedItemInfoFields on PrelovedItemInfo   {\nid  \nstatus  {\n__typename\n...on ItemNotPublished   {\n__typename  \n}\n\n...on ItemLive   {\npaymentInProgress  \n}\n\n...on ItemSold   {\nprelovedOrderIdIfViewerIsBuyer  \ndate  \ntransactionStatus  \npurchaseMethod  \nprice  {\n...MoneyFields   \n}\n\n}\n\n...on ItemDeletedAndVisible   {\n__typename  \n}\n\n...on ItemDeletedAndHidden   {\n__typename  \n}\n\n}\n\npricing  {\n__typename\n...on SellUsingFixedPrice   {\nprice  {\n...MoneyFields   \n}\n\n}\n\n...on SellUsingAuction   {\nauctionId  \nauction  {\n...AuctionBasicDataFields   \n}\n\n}\n\n}\n\nproductSlug  \nprelovedItemId  \nusedForTesting  \n}\n" + MarkotApi__AuctionCommons.AuctionBasicDataFields.query + MarkotApi__CommonFragments.MoneyFields.query;

function parse(value) {
  var value$1 = value.status;
  var typename = value$1["__typename"];
  var tmp;
  switch (typename) {
    case "ItemDeletedAndHidden" :
        tmp = {
          NAME: "ItemDeletedAndHidden",
          VAL: {
            __typename: value$1.__typename
          }
        };
        break;
    case "ItemDeletedAndVisible" :
        tmp = {
          NAME: "ItemDeletedAndVisible",
          VAL: {
            __typename: value$1.__typename
          }
        };
        break;
    case "ItemLive" :
        tmp = {
          NAME: "ItemLive",
          VAL: {
            paymentInProgress: value$1.paymentInProgress
          }
        };
        break;
    case "ItemNotPublished" :
        tmp = {
          NAME: "ItemNotPublished",
          VAL: {
            __typename: value$1.__typename
          }
        };
        break;
    case "ItemSold" :
        var value$2 = value$1.prelovedOrderIdIfViewerIsBuyer;
        var value$3 = value$1.transactionStatus;
        var tmp$1;
        if (value$3 == null) {
          tmp$1 = undefined;
        } else {
          var tmp$2;
          switch (value$3) {
            case "Pending" :
                tmp$2 = "Pending";
                break;
            case "Successful" :
                tmp$2 = "Successful";
                break;
            default:
              tmp$2 = {
                NAME: "FutureAddedValue",
                VAL: value$3
              };
          }
          tmp$1 = tmp$2;
        }
        var value$4 = value$1.purchaseMethod;
        var tmp$3;
        switch (value$4) {
          case "AuctionBuyNowPrice" :
              tmp$3 = "AuctionBuyNowPrice";
              break;
          case "AuctionHighestBid" :
              tmp$3 = "AuctionHighestBid";
              break;
          case "FixedPrice" :
              tmp$3 = "FixedPrice";
              break;
          default:
            tmp$3 = {
              NAME: "FutureAddedValue",
              VAL: value$4
            };
        }
        var value$5 = value$1.price;
        tmp = {
          NAME: "ItemSold",
          VAL: {
            prelovedOrderIdIfViewerIsBuyer: !(value$2 == null) ? value$2 : undefined,
            date: MarkotApi__Scalars__DateTime.parse(value$1.date),
            transactionStatus: tmp$1,
            purchaseMethod: tmp$3,
            price: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$5)
          }
        };
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$1
      };
  }
  var value$6 = value.pricing;
  var typename$1 = value$6["__typename"];
  var tmp$4;
  switch (typename$1) {
    case "SellUsingAuction" :
        var value$7 = value$6.auction;
        tmp$4 = {
          NAME: "SellUsingAuction",
          VAL: {
            auctionId: value$6.auctionId,
            auction: MarkotApi__AuctionCommons.AuctionBasicDataFields.verifyArgsAndParse("AuctionBasicDataFields", value$7)
          }
        };
        break;
    case "SellUsingFixedPrice" :
        var value$8 = value$6.price;
        tmp$4 = {
          NAME: "SellUsingFixedPrice",
          VAL: {
            price: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$8)
          }
        };
        break;
    default:
      tmp$4 = {
        NAME: "FutureAddedValue",
        VAL: value$6
      };
  }
  var value$9 = value.productSlug;
  return {
          id: value.id,
          status: tmp,
          pricing: tmp$4,
          productSlug: !(value$9 == null) ? value$9 : undefined,
          prelovedItemId: value.prelovedItemId,
          usedForTesting: value.usedForTesting
        };
}

function serialize(value) {
  var value$1 = value.usedForTesting;
  var value$2 = value.prelovedItemId;
  var value$3 = value.productSlug;
  var productSlug = value$3 !== undefined ? value$3 : null;
  var value$4 = value.pricing;
  var variant = value$4.NAME;
  var pricing;
  if (variant === "SellUsingFixedPrice") {
    var value$5 = value$4.VAL.price;
    var price = MarkotApi__CommonFragments.MoneyFields.serialize(value$5);
    pricing = {
      __typename: "SellUsingFixedPrice",
      price: price
    };
  } else if (variant === "FutureAddedValue") {
    pricing = value$4.VAL;
  } else {
    var value$6 = value$4.VAL;
    var value$7 = value$6.auction;
    var auction = MarkotApi__AuctionCommons.AuctionBasicDataFields.serialize(value$7);
    var value$8 = value$6.auctionId;
    pricing = {
      __typename: "SellUsingAuction",
      auctionId: value$8,
      auction: auction
    };
  }
  var value$9 = value.status;
  var variant$1 = value$9.NAME;
  var status;
  if (variant$1 === "ItemDeletedAndHidden") {
    status = {
      __typename: "ItemDeletedAndHidden"
    };
  } else if (variant$1 === "FutureAddedValue") {
    status = value$9.VAL;
  } else if (variant$1 === "ItemLive") {
    var value$10 = value$9.VAL.paymentInProgress;
    status = {
      __typename: "ItemLive",
      paymentInProgress: value$10
    };
  } else if (variant$1 === "ItemSold") {
    var value$11 = value$9.VAL;
    var value$12 = value$11.price;
    var price$1 = MarkotApi__CommonFragments.MoneyFields.serialize(value$12);
    var value$13 = value$11.purchaseMethod;
    var purchaseMethod = typeof value$13 === "object" ? value$13.VAL : (
        value$13 === "AuctionBuyNowPrice" ? "AuctionBuyNowPrice" : (
            value$13 === "FixedPrice" ? "FixedPrice" : "AuctionHighestBid"
          )
      );
    var value$14 = value$11.transactionStatus;
    var transactionStatus = value$14 !== undefined ? (
        typeof value$14 === "object" ? value$14.VAL : (
            value$14 === "Pending" ? "Pending" : "Successful"
          )
      ) : null;
    var value$15 = value$11.date;
    var value$16 = MarkotApi__Scalars__DateTime.serialize(value$15);
    var value$17 = value$11.prelovedOrderIdIfViewerIsBuyer;
    var prelovedOrderIdIfViewerIsBuyer = value$17 !== undefined ? value$17 : null;
    status = {
      __typename: "ItemSold",
      prelovedOrderIdIfViewerIsBuyer: prelovedOrderIdIfViewerIsBuyer,
      date: value$16,
      transactionStatus: transactionStatus,
      purchaseMethod: purchaseMethod,
      price: price$1
    };
  } else {
    status = variant$1 === "ItemDeletedAndVisible" ? ({
          __typename: "ItemDeletedAndVisible"
        }) : ({
          __typename: "ItemNotPublished"
        });
  }
  var value$18 = value.id;
  return {
          id: value$18,
          status: status,
          pricing: pricing,
          productSlug: productSlug,
          prelovedItemId: value$2,
          usedForTesting: value$1
        };
}

function verifyArgsAndParse(_PrelovedItemInfoFields, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var Raw$1 = {};

var query$1 = "query PrelovedItemInfoBasicData($id: ID!)  {\nnode(id: $id)  {\n__typename\n...on PrelovedItemInfo   {\n...PrelovedItemInfoFields   \n}\n\n}\n\n}\n" + query;

function parse$1(value) {
  var value$1 = value.node;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1 = typename === "PrelovedItemInfo" ? ({
          NAME: "PrelovedItemInfo",
          VAL: {
            prelovedItemInfoFields: parse(value$1)
          }
        }) : ({
          NAME: "UnspecifiedFragment",
          VAL: typename
        });
    tmp = tmp$1;
  }
  return {
          node: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.node;
  var node = value$1 !== undefined ? (
      value$1.NAME === "UnspecifiedFragment" ? ({}) : Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                return {};
              }), [serialize(value$1.VAL.prelovedItemInfoFields)])
    ) : null;
  return {
          node: node
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

var Raw$2 = {};

var query$2 = "query PrelovedItems($productId: ID!, $first: Int, $after: String, $status: PrelovedItemInfosStatusFilter!, $sort: PrelovedItemInfosSortOrder)  {\nprelovedItems(productId: $productId, status: $status, first: $first, after: $after, sort: $sort)  {\npageInfo  {\nstartCursor  \nendCursor  \nhasPreviousPage  \nhasNextPage  \n}\n\nedges  {\ncursor  \nnode  {\n...PrelovedItemInfoFields   \n}\n\n}\n\n}\n\n}\n" + query;

function parse$2(value) {
  var value$1 = value.prelovedItems;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          prelovedItems: {
            pageInfo: {
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined,
              hasPreviousPage: value$2.hasPreviousPage,
              hasNextPage: value$2.hasNextPage
            },
            edges: Js_array.map((function (value) {
                    return {
                            cursor: value.cursor,
                            node: parse(value.node)
                          };
                  }), value$5)
          }
        };
}

function serialize$2(value) {
  var value$1 = value.prelovedItems;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var node = serialize(value$1);
          var value$2 = value.cursor;
          return {
                  cursor: value$2,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var value$5 = value$3.hasPreviousPage;
  var value$6 = value$3.endCursor;
  var endCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$3.startCursor;
  var startCursor = value$7 !== undefined ? value$7 : null;
  var pageInfo = {
    startCursor: startCursor,
    endCursor: endCursor,
    hasPreviousPage: value$5,
    hasNextPage: value$4
  };
  var prelovedItems = {
    pageInfo: pageInfo,
    edges: edges
  };
  return {
          prelovedItems: prelovedItems
        };
}

function serializeVariables$1(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  var a$2 = inp.status;
  var a$3 = inp.sort;
  return {
          productId: inp.productId,
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined,
          status: a$2 === "Sold" ? "Sold" : "Live",
          sort: a$3 !== undefined ? (
              a$3 === "DateAsc" ? "DateAsc" : (
                  a$3 === "HighestBidAsc" ? "HighestBidAsc" : (
                      a$3 === "BuyNowPriceAsc" ? "BuyNowPriceAsc" : (
                          a$3 === "BuyNowPriceDesc" ? "BuyNowPriceDesc" : (
                              a$3 === "DateDesc" ? "DateDesc" : (
                                  a$3 === "HighestBidDesc" ? "HighestBidDesc" : (
                                      a$3 === "NumberOfBidsAsc" ? "NumberOfBidsAsc" : "NumberOfBidsDesc"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined
        };
}

function makeVariables(productId, first, after, status, sort, param) {
  return {
          productId: productId,
          first: first,
          after: after,
          status: status,
          sort: sort
        };
}

function makePrelovedItemInfo(param) {
  var pricing = param.pricing;
  var status = param.status;
  var variant = pricing.NAME;
  var tmp;
  if (variant === "SellUsingFixedPrice") {
    tmp = {
      TAG: /* SellUsingFixedPrice */0,
      price: MarkotApi__CommonFragments.makeMoney(pricing.VAL.price)
    };
  } else if (variant === "FutureAddedValue") {
    RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "PrelovedItem makePrelovedItemInfo - PrelovedItem.pricing unknown typename", pricing);
    tmp = Js_exn.raiseError("PrelovedItem.pricing unknown typename.");
  } else {
    var match = pricing.VAL;
    tmp = {
      TAG: /* SellUsingAuction */1,
      auctionId: MarkotApi__Id.parseExn(match.auctionId),
      auction: MarkotApi__AuctionCommons.makeAuction(match.auction)
    };
  }
  var variant$1 = status.NAME;
  var tmp$1;
  if (variant$1 === "ItemDeletedAndHidden") {
    tmp$1 = {
      TAG: /* ItemDeletedAndHidden */4,
      _placeholder: undefined
    };
  } else if (variant$1 === "FutureAddedValue") {
    RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "PrelovedItem makePrelovedItemInfo - PrelovedItemInfo.status unknown typename", pricing);
    tmp$1 = Js_exn.raiseError("PrelovedItemInfo.status unknown typename.");
  } else if (variant$1 === "ItemLive") {
    tmp$1 = {
      TAG: /* ItemLive */1,
      paymentInProgress: status.VAL.paymentInProgress
    };
  } else if (variant$1 === "ItemSold") {
    var match$1 = status.VAL;
    var purchaseMethod = match$1.purchaseMethod;
    tmp$1 = {
      TAG: /* ItemSold */2,
      prelovedOrderIdIfViewerIsBuyer: match$1.prelovedOrderIdIfViewerIsBuyer,
      date: match$1.date,
      transactionStatus: Belt_Option.map(match$1.transactionStatus, (function (transactionStatus) {
              if (typeof transactionStatus === "object") {
                return Js_exn.raiseError("ItemSold.transactionStatus unknown enum value: " + transactionStatus.VAL);
              } else if (transactionStatus === "Pending") {
                return "Pending";
              } else {
                return "Successful";
              }
            })),
      purchaseMethod: typeof purchaseMethod === "object" ? Js_exn.raiseError("ItemSold.purchaseMethod unknown enum value: " + purchaseMethod.VAL) : (
          purchaseMethod === "AuctionBuyNowPrice" ? "AuctionBuyNowPrice" : (
              purchaseMethod === "FixedPrice" ? "FixedPrice" : "AuctionHighestBid"
            )
        ),
      price: MarkotApi__CommonFragments.makeMoney(match$1.price)
    };
  } else {
    tmp$1 = variant$1 === "ItemDeletedAndVisible" ? ({
          TAG: /* ItemDeletedAndVisible */3,
          _placeholder: undefined
        }) : ({
          TAG: /* ItemNotPublished */0,
          _placeholder: undefined
        });
  }
  return {
          id: MarkotApi__Id.parseExn(param.id),
          prelovedItemId: MarkotApi__Id.parseExn(param.prelovedItemId),
          productSlug: param.productSlug,
          pricing: tmp,
          status: tmp$1,
          inWishlist: undefined,
          usedForTesting: param.usedForTesting
        };
}

function fetchBasicData(apiConfig, id, includeTestPrelovedItems) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$1,
                    Raw: Raw$1,
                    parse: parse$1,
                    serialize: serialize$1,
                    serializeVariables: serializeVariables
                  }), {
                id: id
              }, undefined).then(function (param) {
              var node = param.node;
              if (node !== undefined) {
                if (node.NAME === "UnspecifiedFragment") {
                  RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "PrelovedItem fetchBasicData - node of unknown type found. Ignoring.", node);
                  return ;
                }
                var prelovedItemInfoFields = node.VAL.prelovedItemInfoFields;
                if (includeTestPrelovedItems || !prelovedItemInfoFields.usedForTesting) {
                  return makePrelovedItemInfo(prelovedItemInfoFields);
                } else {
                  return ;
                }
              }
              RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "PrelovedItem fetchBasicData - not found. Id", id);
            });
}

function fetchItems(apiConfig, productId, status, first, after, sort) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$2,
                    Raw: Raw$2,
                    parse: parse$2,
                    serialize: serialize$2,
                    serializeVariables: serializeVariables$1
                  }), makeVariables(productId, first, after, status, sort, undefined), undefined).then(function (param) {
              var match = param.prelovedItems;
              var pageInfo = match.pageInfo;
              return {
                      pageInfo: {
                        startCursor: pageInfo.startCursor,
                        endCursor: pageInfo.endCursor,
                        hasNextPage: pageInfo.hasNextPage,
                        hasPreviousPage: pageInfo.hasPreviousPage
                      },
                      edges: Belt_Array.map(match.edges, (function (param) {
                              return {
                                      cursor: param.cursor,
                                      node: makePrelovedItemInfo(param.node)
                                    };
                            }))
                    };
            });
}

function PrelovedItemInfoFields_unsafe_fromJson(prim) {
  return prim;
}

function PrelovedItemInfoFields_toJson(prim) {
  return prim;
}

var PrelovedItemInfoFields = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName,
  unsafe_fromJson: PrelovedItemInfoFields_unsafe_fromJson,
  toJson: PrelovedItemInfoFields_toJson
};

export {
  PrelovedItemInfoFields ,
  makePrelovedItemInfo ,
  fetchBasicData ,
  fetchItems ,
}
/* MarkotApi__Id Not a pure module */
