// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__LoadingIndicator from "./Ui__LoadingIndicator.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    paddingVertical: theme.sectionSpacing._2
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__ListsLoadingView(Props) {
  var style = Props.style;
  var testID = Props.testID;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, match.theme);
  var tmp = {
    style: [
      Caml_option.some(Curry._1(styled, styles.container)),
      style
    ],
    children: React.createElement(Ui__LoadingIndicator.make, {})
  };
  if (testID !== undefined) {
    tmp.testID = testID;
  }
  return React.createElement(ReactNative.View, tmp);
}

var make = Ui__ListsLoadingView;

export {
  make ,
}
/* match Not a pure module */
