// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";

var Types = {};

var variablesConverter = {};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var wrapResponseConverter = {"__root":{"createUserAddress_address":{"f":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"createUserAddress_address":{"f":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var wrapRawResponseConverter = {};

function convertWrapRawResponse(v) {
  return RescriptRelay.convertObj(v, wrapRawResponseConverter, undefined, null);
}

var rawResponseConverter = {};

function convertRawResponse(v) {
  return RescriptRelay.convertObj(v, rawResponseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  wrapRawResponseConverter: wrapRawResponseConverter,
  wrapRawResponseConverterMap: undefined,
  convertWrapRawResponse: convertWrapRawResponse,
  rawResponseConverter: rawResponseConverter,
  rawResponseConverterMap: undefined,
  convertRawResponse: convertRawResponse
};

function addressType_decode($$enum) {
  if ($$enum === "Shipping" || $$enum === "Billing") {
    return $$enum;
  }
  
}

var addressType_fromString = addressType_decode;

var Utils = {
  addressType_decode: addressType_decode,
  addressType_fromString: addressType_fromString
};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addressType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "city"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "country"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "extraInfo"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "postalCode"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recipientName"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "relayConnectionsToUpdate"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "state"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "streetLine1"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "streetLine2"
},
v11 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "addressType",
        "variableName": "addressType"
      },
      {
        "kind": "Variable",
        "name": "city",
        "variableName": "city"
      },
      {
        "kind": "Variable",
        "name": "country",
        "variableName": "country"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "extraInfo",
        "variableName": "extraInfo"
      },
      {
        "kind": "Variable",
        "name": "postalCode",
        "variableName": "postalCode"
      },
      {
        "kind": "Variable",
        "name": "recipientName",
        "variableName": "recipientName"
      },
      {
        "kind": "Variable",
        "name": "state",
        "variableName": "state"
      },
      {
        "kind": "Variable",
        "name": "streetLine1",
        "variableName": "streetLine1"
      },
      {
        "kind": "Variable",
        "name": "streetLine2",
        "variableName": "streetLine2"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streetLine2",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extraInfo",
  "storageKey": null
},
v16 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppAddAddressModal_createUserAddressMutation",
    "selections": [
      {
        "alias": "createUserAddress",
        "args": (v11/*: any*/),
        "concreteType": "CreateUserAddressPayload",
        "kind": "LinkedField",
        "name": "createUserAddress2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Address",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AppAddressSnippet_address"
              },
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v9/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v8/*: any*/),
      (v10/*: any*/),
      (v0/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "AppAddAddressModal_createUserAddressMutation",
    "selections": [
      {
        "alias": "createUserAddress",
        "args": (v11/*: any*/),
        "concreteType": "CreateUserAddressPayload",
        "kind": "LinkedField",
        "name": "createUserAddress2",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Address",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "streetLine1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "recipientName",
                "storageKey": null
              },
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "address",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "relayConnectionsToUpdate"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "AddressEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "185b768c7e5b72c67635759975f63f56",
    "id": null,
    "metadata": {},
    "name": "AppAddAddressModal_createUserAddressMutation",
    "operationKind": "mutation",
    "text": "mutation AppAddAddressModal_createUserAddressMutation(\n  $city: String!\n  $country: String!\n  $postalCode: String!\n  $recipientName: String!\n  $streetLine1: String!\n  $description: String\n  $extraInfo: String\n  $state: String!\n  $streetLine2: String\n  $addressType: AddressType\n) {\n  createUserAddress: createUserAddress2(input: {city: $city, country: $country, extraInfo: $extraInfo, postalCode: $postalCode, recipientName: $recipientName, streetLine1: $streetLine1, streetLine2: $streetLine2, description: $description, state: $state, addressType: $addressType}) {\n    address {\n      id\n      ...AppAddressSnippet_address\n      description\n      streetLine2\n      extraInfo\n    }\n  }\n}\n\nfragment AppAddressSnippet_address on Address {\n  city\n  country\n  streetLine1\n  state2\n  postalCode\n  recipientName\n}\n"
  }
};
})());

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
