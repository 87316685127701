import "raf/polyfill"; // Needed for Reanimated 2.x

import { useState, useMemo, useEffect, useCallback } from "react";
import "../styles/globals.css";
import "../styles/image-gallery.css";
import Head from "next/head";
import dynamic from "next/dynamic";
import type { AppProps } from "next/app";
import { Router } from "next/router";
import { PortalHost, PortalProvider } from "@gorhom/portal";
import { Amplify, Hub } from "@aws-amplify/core";
import { reloadApp } from "@decidr-io/react-native-helpers/src/reloader/Reloader.gen";
import { assertNever } from "@decidr-io/type-safety/src/typescript";
import * as Sentry from "@sentry/nextjs";
import { checkAuthStatus } from "@decidr-io/auth/src/AuthHelpers.gen";
import { AnalyticsBrowser } from "@segment/analytics-next";

import { BottomSheetModalProvider } from "@markot/ui/src/bottomSheet";
import { AppState, ThemeProvider, themeConfigSet } from "@markot/ui";
import { make as RelayProvider } from "@markot/app/src/relay/App__RelayProvider.gen";
import { makeEnvironment } from "@markot/app/src/relay/App__RelayEnv.gen";
import {
  AuthProvider,
  Context_contextData as AuthContextData,
} from "@markot/app/src/auth/context/AuthContext.gen";
import { seed } from "@markot/app/src/relay/App__FakeData.gen";
import config from "@markot/app/src/config/runtime-config.json";
import { FatalErrorBoundary } from "@markot/ui/src/error/fatalErrorBoundary";
import { RNPaperProvider } from "@markot/ui/src/paper";
import { WebDrawersProvider, Drawer } from "@markot/ui/src/webDrawers";
import { ToastError } from "@markot/ui/src/toast/components/error";
import { assignLocalBagToViewer } from "@markot/app/src/bag";
import {
  FeatureFlagsProvider,
  featureFlagsMapToArray,
  featureFlagKeyErrorReportingPrefix,
} from "@markot/feature-flags/src/local";
import { useCombinedFeatureFlags } from "@markot/feature-flags/src/utils";
import { getViewerAid } from "@markot/app/src/userProfile/App__ProfileInfo.gen";
import {
  AnalyticsProvider,
  testModeAnalytics,
  TestModeAnalytics,
} from "@markot/utils/src/analyticsProviderWeb/Utils__AnalyticsProviderWeb";
import { getConfigFromNetwork } from "@markot/app/src/config/App__Config.gen";
import { makeDeviceContextEvent } from "@markot/utils/src/analytics/helpers";

const isSSR = typeof window === "undefined";

const GlobalJSXStyles = dynamic(() => import("../styles/GlobalStyles"), {
  ssr: false,
});

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: config.cognitoIdentityPoolId,
    // REQUIRED - Amazon Cognito Region
    region: config.cognitoRegion,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: config.cognitoUserPoolId,
    // OPTIONAL - Amazon Cognito Web Client ID
    userPoolWebClientId: config.cognitoUserPoolWebClientId,
  },
  oauth: {
    domain: config.cognitoUserPoolDomain,
    scope: ["email", "openid"],
    redirectSignIn: !isSSR ? `${window.location.origin}/au` : "",
    redirectSignOut: !isSSR ? `${window.location.origin}/au` : "",
    responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
});

// We need to ensure that we don't initialize Segment when running tests
// Running tests will create a lot of new users that Segment sees as unique and will count towards quota

let analytics: AnalyticsBrowser | TestModeAnalytics;

if (isSSR || config.markotEnv.toUpperCase() === "QA") {
  analytics = testModeAnalytics;
} else {
  analytics = AnalyticsBrowser.load({
    writeKey: config.segmentWriteKeyWeb,
  });

  // routeChangeComplete from next router can't register initial page loads
  // so we check if the page is loaded and then send the page event that matches the url
  // This will fix initial page loads or refreshes
  analytics.page(window.location.pathname + window.location.search);

  Router.events.on("routeChangeComplete", url => {
    // url is typed as any, so we just want to make sure that it is a string
    if (typeof url === "string") {
      analytics.page(url);
    }
  });
}

const identifyUser = (aid: string) => {
  Sentry.setUser({ id: aid, isLoggedIn: true });
  analytics.user().then(user => {
    if (user.id() == null) {
      analytics
        .identify(aid)
        .then(() => {
          console.log("Identified user in segment");
        })
        .catch(e => {
          console.warn("Failed to identify user in segment", e);
        });
    }
  });
};

function MyApp({ Component, pageProps }: AppProps) {
  const [relayEnvironment /*, setRelayEnvironment */] = useState(() =>
    makeEnvironment()
  );
  const getFeatureFlagsFromNetwork = useCallback(
    () =>
      getConfigFromNetwork({
        onError: () => {
          // We will ignore the error and treat the app as we received
          // the empty JSON from the server.
          return JSON.stringify("{}");
        },
      }),
    []
  );
  const { featureFlags, updateFeatureFlags } = useCombinedFeatureFlags({
    getFeatureFlagsFromNetwork: getFeatureFlagsFromNetwork,
  });

  useEffect(() => {
    // Send ALL feature flags to Sentry
    featureFlagsMapToArray(featureFlags).forEach(([key, value]) => {
      Sentry.setTag(featureFlagKeyErrorReportingPrefix + key, value.status);
    });
  }, [featureFlags]);

  useEffect(() => {
    // Send ALL feature flags to Segment
    const featureFlagsForSegment = featureFlagsMapToArray(featureFlags).map(
      ([key, value]) => [key, value.status]
    );
    if (featureFlagsForSegment.length !== 0) {
      const deviceContextEvent = makeDeviceContextEvent({
        featureFlags: Object.fromEntries(featureFlagsForSegment),
      });
      analytics.track(deviceContextEvent.name, deviceContextEvent.properties);
    }
  }, [featureFlags]);

  useEffect(() => {
    seed({ environment: relayEnvironment });
  }, [relayEnvironment]);

  const [authInfo, setAuthInfo] = useState<AuthContextData["authInfo"]>({
    status: "pending",
  });

  const [userAid, setUserAid] = useState<string | null>(null);

  const [visibleDrawer, setVisibleDrawer] = useState<Drawer | undefined>(
    undefined
  );

  const [authError, setAuthError] = useState<string | undefined>(undefined);

  const cleanAfterLogout = useCallback(() => {
    console.log("cleanAfterLogout initiated");

    setAuthInfo({ status: "notAuthenticated" });

    reloadApp();
    // setRelayEnvironment(makeEnvironment());
  }, []);

  useEffect(() => {
    Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signIn":
        case "autoSignIn":
          setAuthInfo({ status: "authenticated" });
          break;
        case "signOut":
          setAuthInfo({ status: "notAuthenticated" });
          break;
        case "codeFlow":
          setAuthInfo({ status: "pending" });
          break;
        case "cognitoHostedUI_failure":
          console.error("Could not sign in with this provider", payload);
          break;
        case "autoSignIn_failure":
          console.error("Could not auto sign in this user", payload);
          setAuthInfo({ status: "notAuthenticated" });
          setAuthError("Please sign in again");
          break;
        default:
          console.info("Ignoring event", payload.event);
          break;
      }
    });
  }, []);

  const authContextData: AuthContextData = useMemo(
    () => ({
      authInfo: authInfo,
      updateAuthInfo: newAuthInfo => {
        console.log("setting auth info in App", newAuthInfo);
        setAuthInfo(_ => newAuthInfo);
      },
      cleanAfterLogout,
    }),
    [authInfo, setAuthInfo, cleanAfterLogout]
  );

  useEffect(() => {
    checkAuthStatus({}).then(status => {
      // console.log("checkCurrentAuthStatus", status);
      if (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        status === "UserNotFound" ||
        status.NAME === "UserLoggedOutForcefully"
      ) {
        authContextData.updateAuthInfo({ status: "notAuthenticated" });
        return;
      } else {
        switch (status.NAME) {
          case "UserFound":
            // verifyUserBeforeGoingToApp(status.VAL);
            authContextData.updateAuthInfo({ status: "authenticated" });
            return;
          default:
            return assertNever(status);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authInfo?.status === "authenticated") {
      // Check the local state first
      if (userAid != null) {
        identifyUser(userAid);
      } else {
        // user aid is not set, so we need to fetch it
        getViewerAid({ environment: relayEnvironment }).then(aid => {
          if (aid != null) {
            setUserAid(aid);
            identifyUser(aid);
          }
        });
      }
      assignLocalBagToViewer({ environment: relayEnvironment });
    }

    if (authInfo?.status === "notAuthenticated") {
      Sentry.setUser(null);
      setUserAid(null);
      analytics.user().then(user => {
        if (user.id() != null) {
          analytics.reset();
        }
      });
    }
    // We don'r want to re-run this effect when userAid changes
    //  since setUserAid function is only used here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInfo?.status, relayEnvironment]);

  // FIXME - Find a way to get system mode on web
  const colorScheme = "light";

  const toggleThemeVariant = () => {
    setState(({ themeConfigSet, themeVariant, themeContextData, ...rest }) => {
      const newThemeVariant = themeVariant === "light" ? "dark" : "light";
      return {
        ...rest,
        themeConfigSet,
        themeVariant: newThemeVariant,
        themeContextData: {
          ...themeContextData,
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          themeConfig: themeConfigSet[newThemeVariant] ?? themeConfigSet.light,
        },
      };
    });
  };

  const [state, setState] = useState<AppState>({
    themeConfigSet: themeConfigSet,
    themeVariant: colorScheme, // TODO Must come from AppStorage, but that complicates things
    themeContextData: {
      themeConfig: themeConfigSet[colorScheme],
      toggleThemeConfig: toggleThemeVariant,
    },
  });

  return (
    // TODO Add captureException?
    <>
      <Head>
        {/* TODO Check whether maximum-scale is acceptable */}
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1"
        />
        <meta name="apple-itunes-app" content="app-id=1645869388" />
      </Head>

      <FatalErrorBoundary onReload={reloadApp}>
        <GlobalJSXStyles />
        <FeatureFlagsProvider
          featureFlags={featureFlags}
          updateFeatureFlags={updateFeatureFlags}
        >
          <AuthProvider contextData={authContextData}>
            <AnalyticsProvider analytics={analytics}>
              <RNPaperProvider>
                <RelayProvider environment={relayEnvironment}>
                  {/* TODO if WebDrawers are the only one using PortalProvider, include it in WebDrawersProvider  */}
                  <WebDrawersProvider
                    contextData={{
                      visibleDrawer: visibleDrawer,
                      updateVisibleDrawer: drawer =>
                        setVisibleDrawer(drawer === null ? undefined : drawer),
                    }}
                  >
                    <PortalProvider>
                      <ThemeProvider
                        themeConfig={state.themeContextData.themeConfig}
                        toggleTheme={state.themeContextData.toggleThemeConfig}
                      >
                        <BottomSheetModalProvider>
                          <Component {...pageProps} />
                          <PortalHost name="Toast" />
                          {authError != undefined && (
                            <ToastError
                              title="An error has occurred"
                              description={authError}
                              onDismiss={() => setAuthError(undefined)}
                            />
                          )}
                        </BottomSheetModalProvider>
                      </ThemeProvider>
                    </PortalProvider>
                  </WebDrawersProvider>
                </RelayProvider>
              </RNPaperProvider>
            </AnalyticsProvider>
          </AuthProvider>
        </FeatureFlagsProvider>
      </FatalErrorBoundary>
    </>
  );
}

export default MyApp;
