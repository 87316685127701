// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Ui__Theme from "../theme/components/Ui__Theme.bs.js";
import * as ReStyleSheet$DecidrIoStyles from "@decidr-io/styles/src/stylesheet/ReStyleSheet.bs.js";

var pct = ReStyleSheet$DecidrIoStyles.pct;

var deg = ReStyleSheet$DecidrIoStyles.deg;

var rad = ReStyleSheet$DecidrIoStyles.rad;

var create = ReStyleSheet$DecidrIoStyles.create;

var describe = ReStyleSheet$DecidrIoStyles.describe;

var viewStyleWithWebFix = ReStyleSheet$DecidrIoStyles.viewStyleWithWebFix;

var Typography = Ui__Theme.Typography;

var SectionSpacing = Ui__Theme.SectionSpacing;

var Spacing = Ui__Theme.Spacing;

var Colors = Ui__Theme.Colors;

var Roundness = Ui__Theme.Roundness;

var LineThickness = Ui__Theme.LineThickness;

var FontFamily = Ui__Theme.FontFamily;

var Defaults = Ui__Theme.Defaults;

var Theme = Ui__Theme.Theme;

var Context = Ui__Theme.Context;

var useTheme = Ui__Theme.useTheme;

var themeProvider = Ui__Theme.themeProvider;

var themeConsumer = Ui__Theme.themeConsumer;

var themeConfigSet = Ui__Theme.themeConfigSet;

var fallbackTheme = Ui__Theme.fallbackTheme;

export {
  pct ,
  deg ,
  rad ,
  create ,
  describe ,
  viewStyleWithWebFix ,
  Typography ,
  SectionSpacing ,
  Spacing ,
  Colors ,
  Roundness ,
  LineThickness ,
  FontFamily ,
  Defaults ,
  Theme ,
  Context ,
  useTheme ,
  themeProvider ,
  themeConsumer ,
  themeConfigSet ,
  fallbackTheme ,
}
/* Ui__Theme Not a pure module */
