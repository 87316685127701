/* TypeScript file generated from Ui__Counter.resi by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as Ui__CounterBS__Es6Import from './Ui__Counter.bs';
const Ui__CounterBS: any = Ui__CounterBS__Es6Import;

import type {Style_t as ReactNative_Style_t} from '../../../../../rescript-shims/ReactNative.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Button_buttonSize = "l" | "m" | "s" | "xs" | "xxs";

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly disabled?: boolean; 
  readonly containerStyle?: ReactNative_Style_t; 
  readonly testOnly_pressed?: boolean; 
  readonly onPress: () => void; 
  readonly kind: 
    "plus"
  | "minus"; 
  readonly size?: Button_buttonSize
};

export const Button_make: React.ComponentType<{
  readonly disabled?: boolean; 
  readonly containerStyle?: ReactNative_Style_t; 
  readonly testOnly_pressed?: boolean; 
  readonly onPress: () => void; 
  readonly kind: 
    "plus"
  | "minus"; 
  readonly size?: Button_buttonSize
}> = Ui__CounterBS.Button.make;

// tslint:disable-next-line:interface-over-type-literal
export type make_Props = {
  readonly onChange: (_1:number) => void; 
  readonly value: number; 
  readonly highestValue?: number; 
  readonly disabled?: boolean; 
  readonly containerStyle?: ReactNative_Style_t; 
  readonly testID?: string; 
  readonly lowestValue?: number; 
  readonly size: Button_buttonSize
};

export const make: React.ComponentType<{
  readonly onChange: (_1:number) => void; 
  readonly value: number; 
  readonly highestValue?: number; 
  readonly disabled?: boolean; 
  readonly containerStyle?: ReactNative_Style_t; 
  readonly testID?: string; 
  readonly lowestValue?: number; 
  readonly size: Button_buttonSize
}> = Ui__CounterBS.make;

export const Button: { make: React.ComponentType<{
  readonly disabled?: boolean; 
  readonly containerStyle?: ReactNative_Style_t; 
  readonly testOnly_pressed?: boolean; 
  readonly onPress: () => void; 
  readonly kind: 
    "plus"
  | "minus"; 
  readonly size?: Button_buttonSize
}> } = Ui__CounterBS.Button
