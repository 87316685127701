// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ShopifyApi__Id from "../../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as AlgoliaApi__ProductVariant from "../../../api-adapter-algolia/src/AlgoliaApi__ProductVariant.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Entity__Product from "./GqlMiddleware__Entity__Product.bs.js";
import * as GqlMiddleware__Entity__OpenBoxInfo from "./GqlMiddleware__Entity__OpenBoxInfo.bs.js";
import * as GqlMiddleware__Entity__SearchResult from "./GqlMiddleware__Entity__SearchResult.bs.js";
import * as GqlMiddleware__Entity__ProductSnippet from "./GqlMiddleware__Entity__ProductSnippet.bs.js";
import * as GqlMiddleware__Entity__ProductVariant from "./GqlMiddleware__Entity__ProductVariant.bs.js";
import * as GqlMiddleware__Entity__OverstockedInfo from "./GqlMiddleware__Entity__OverstockedInfo.bs.js";
import * as GqlMiddleware__Entity__RefurbishedInfo from "./GqlMiddleware__Entity__RefurbishedInfo.bs.js";

function makeProductVariantSearchResult(param) {
  var isOpenBoxProduct = param.isOpenBoxProduct;
  var productPriceRange = param.productPriceRange;
  var productId = param.productId;
  var sku = param.sku;
  var id = param.id;
  var priceRangeArr = Belt_Option.getWithDefault(Belt_Option.map(productPriceRange, (function (productPriceRange) {
              return productPriceRange.split(":");
            })), [
        "200",
        "400"
      ]);
  var resp = priceRangeArr.length === 2 ? priceRangeArr : (RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "makeProductVariantSearchResult priceRange fetched from Algolia is in incorect format: " + productPriceRange + ". Expected format from:to"), Js_exn.raiseError("priceRange fetched from Algolia is in incorect format: " + productPriceRange + ". Expected format from:to"));
  var min = Belt_Array.get(resp, 0);
  var min$1 = min !== undefined ? min : (RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "makeProductVariantSearchResult priceRange fetched from Algolia is in incorect format: " + productPriceRange + ". Expected format from:to"), Js_exn.raiseError("priceRange fetched from Algolia is in incorect format: " + productPriceRange + ". Expected format from:to"));
  var max = Belt_Array.get(resp, 1);
  var max$1 = max !== undefined ? max : (RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "makeProductVariantSearchResult priceRange fetched from Algolia is in incorect format: " + productPriceRange + ". Expected format from:to"), Js_exn.raiseError("priceRange fetched from Algolia is in incorect format: " + productPriceRange + ". Expected format from:to"));
  var tmp;
  tmp = sku !== undefined && sku !== "" ? sku : undefined;
  return {
          __typename: "ProductVariantSearchResult",
          id: GqlMiddleware__Core__NodeId.make(1, "ProductVariantSearchResult", ShopifyApi__Id.toString(ShopifyApi__Id.make("ProductVariant", id))),
          productVariantId: GqlMiddleware__Entity__ProductVariant.makeProductVariantId(ShopifyApi__Id.toString(ShopifyApi__Id.make("ProductVariant", id))),
          compareAtPrice: Belt_Option.map(param.compareAtPrice, (function (compareAtPrice) {
                  return {
                          amount: String(compareAtPrice),
                          currency: "AUD"
                        };
                })),
          price: {
            amount: String(param.price),
            currency: "AUD"
          },
          inStock: param.inventoryQuantity > 0,
          mainImage: Belt_Option.map(param.mainImage, (function (param) {
                  var size = param.size;
                  return {
                          __typename: "ImageResource",
                          alt: undefined,
                          url: param.url,
                          size: {
                            width: size.width,
                            height: size.height
                          }
                        };
                })),
          sku: tmp,
          product: {
            __typename: "ProductSnippet",
            id: GqlMiddleware__Entity__ProductSnippet.makeProductSnippetId(productId),
            productId: GqlMiddleware__Entity__Product.makeProductId(productId),
            name: Belt_Option.getWithDefault(param.productTitle, "To be added"),
            priceRange: {
              min: {
                amount: min$1,
                currency: "AUD"
              },
              max: {
                amount: max$1,
                currency: "AUD"
              }
            },
            brandName: param.brandName,
            slug: param.handle,
            mainImage: Belt_Option.map(param.productImage, (function (param) {
                    var size = param.size;
                    return {
                            __typename: "ImageResource",
                            alt: undefined,
                            url: param.url,
                            size: {
                              width: size.width,
                              height: size.height
                            }
                          };
                  })),
            usedForTesting: param.usedForTesting,
            sellingNewItems: param.sellingNewItems,
            sellingPrelovedItems: param.sellingPrelovedItems,
            refurbished: Belt_Option.getWithDefault(param.isRefurbishedProduct, false) ? GqlMiddleware__Entity__RefurbishedInfo.make(param.refurbishedProductModelYear, Belt_Option.flatMap(param.refurbishedProductOriginalPrice, (function (param) {
                          var currency = param.currency;
                          var amount = param.amount;
                          var currencyVariant;
                          if (currency === "AUD") {
                            currencyVariant = "AUD";
                          } else {
                            RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "makeProductVariantSearchResult unsupported refurbished currency received: " + currency + " for Product ID: " + productId + "");
                            currencyVariant = undefined;
                          }
                          return Belt_Option.map(currencyVariant, (function (currency) {
                                        return {
                                                amount: amount,
                                                currency: currency
                                              };
                                      }));
                        }))) : undefined,
            overstocked: Belt_Option.getWithDefault(param.isOverstockedProduct, false) ? GqlMiddleware__Entity__OverstockedInfo.make(param.overstockedProductModelYear, Belt_Option.flatMap(param.overstockedProductOriginalPrice, (function (param) {
                          var currency = param.currency;
                          var amount = param.amount;
                          var currencyVariant;
                          if (currency === "AUD") {
                            currencyVariant = "AUD";
                          } else {
                            RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "makeProductVariantSearchResult unsupported overstocked currency received: " + currency + " for Product ID: " + productId + "");
                            currencyVariant = undefined;
                          }
                          return Belt_Option.map(currencyVariant, (function (currency) {
                                        return {
                                                amount: amount,
                                                currency: currency
                                              };
                                      }));
                        }))) : undefined,
            isOpenBoxProduct: Belt_Option.getWithDefault(isOpenBoxProduct, false),
            openbox: Belt_Option.getWithDefault(isOpenBoxProduct, false) ? GqlMiddleware__Entity__OpenBoxInfo.make(param.openboxProductModelYear, Belt_Option.flatMap(param.openboxProductOriginalPrice, (function (param) {
                          var currency = param.currency;
                          var amount = param.amount;
                          var currencyVariant;
                          if (currency === "AUD") {
                            currencyVariant = "AUD";
                          } else {
                            RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "makeProductVariantSearchResult unsupported openbox currency received: " + currency + " for Product ID: " + productId + "");
                            currencyVariant = undefined;
                          }
                          return Belt_Option.map(currencyVariant, (function (currency) {
                                        return {
                                                amount: amount,
                                                currency: currency
                                              };
                                      }));
                        }))) : undefined
          }
        };
}

function searchProductVariants(apiConfig, searchTerm, first, after, filters, settings, sortKey, sortDirection, analyticsTags, includeRefurbishedInfo, includeOverstockedInfo) {
  return AlgoliaApi__ProductVariant.searchProductVariants(apiConfig, searchTerm, first, after, Belt_Option.map(filters, (function (param) {
                      return {
                              brandId: Belt_Option.map(param.brandId, (function (brandId) {
                                      return GqlMiddleware__Core__NodeId.unpackExn(brandId).internalId;
                                    })),
                              categoryIds: Belt_Option.map(param.categoryIds, (function (categoryIds) {
                                      return Belt_Array.map(categoryIds, (function (catId) {
                                                    return GqlMiddleware__Core__NodeId.unpackExn(catId).internalId;
                                                  }));
                                    })),
                              price: Belt_Option.map(param.price, (function (x) {
                                      return Belt_Array.map(x, (function (price) {
                                                    return {
                                                            gt: price.gt,
                                                            lt: price.lt
                                                          };
                                                  }));
                                    })),
                              sellingNewItems: param.sellingNewItems,
                              sellingPrelovedItems: param.sellingPrelovedItems,
                              facetFilters: Belt_Option.map(param.facetFilters, (function (param) {
                                      return {
                                              brandName: param.brandName,
                                              categoryName: param.categoryName,
                                              isRefurbishedProduct: param.isRefurbishedProduct,
                                              isOverstockedProduct: param.isOverstockedProduct,
                                              isPrelovedProduct: param.isPrelovedProduct,
                                              isOpenBoxProduct: param.isOpenBoxProduct
                                            };
                                    }))
                            };
                    })), Belt_Option.map(settings, (function (settings) {
                      return {
                              TAG: /* ProductVariantSearchSettings */0,
                              defaultVariantsOnly: settings.defaultVariantsOnly,
                              includeTestProducts: settings.includeTestProducts
                            };
                    })), sortKey, sortDirection, analyticsTags, includeRefurbishedInfo, includeOverstockedInfo).then(function (__x) {
              return GqlMiddleware__Entity__SearchResult.Connection.makeConnectionFromAlgoliaConnection(__x, makeProductVariantSearchResult);
            });
}

export {
  searchProductVariants ,
}
/* ShopifyApi__Id Not a pure module */
