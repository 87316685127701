/* TypeScript file generated from Utils__AnalyticsHelpers.resi by genType. */
/* eslint-disable import/first */


// @ts-ignore: Implicit any on import
import * as Utils__AnalyticsHelpersBS__Es6Import from './Utils__AnalyticsHelpers.bs';
const Utils__AnalyticsHelpersBS: any = Utils__AnalyticsHelpersBS__Es6Import;

import type {Types_obj_val as Js_Types_obj_val} from '../../../../../rescript-shims/Js.shim';

import type {deviceContextProperties as Utils__Analytics_deviceContextProperties} from '../../../../../packages/utils/src/analytics/Utils__Analytics.gen';

// tslint:disable-next-line:interface-over-type-literal
export type tsEvent = { readonly name: string; readonly properties: Js_Types_obj_val };

export const makeDeviceContextEvent: (_1:Utils__Analytics_deviceContextProperties) => tsEvent = Utils__AnalyticsHelpersBS.makeDeviceContextEvent;
