// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";

var match = Ui__Styling.describe({
      radio: {
        TAG: /* Dual */2,
        _0: {
          height: 20,
          width: 20
        },
        _1: (function (props) {
            var tmp = {
              borderColor: props.theme.colors.primaryShade2,
              borderRadius: props.theme.roundness.circle,
              borderWidth: props.theme.lineThickness.s
            };
            var tmp$1 = Bool$DecidrIoUtils.thenSome(props.selected, props.theme.colors.secondary);
            if (tmp$1 !== undefined) {
              tmp.backgroundColor = Caml_option.valFromOption(tmp$1);
            }
            return tmp;
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__RadioButton(Props) {
  var selectedOpt = Props.selected;
  var containerStyle = Props.containerStyle;
  var selected = selectedOpt !== undefined ? selectedOpt : false;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, {
        theme: match.theme,
        selected: selected
      });
  return React.createElement(ReactNative.View, {
              style: [
                Caml_option.some(Curry._1(styled, styles.radio)),
                containerStyle
              ],
              testID: selected ? "selected-radio-button" : "unselected-radio-button"
            });
}

var make = Ui__RadioButton;

export {
  make ,
}
/* match Not a pure module */
