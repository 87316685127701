import { createContext, useContext } from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";

export type TestModeAnalytics = {
  page: () => Promise<void>;
  identify: () => Promise<void>;
  user: () => Promise<{ id: () => string }>;
  track: () => Promise<void>;
  reset: () => Promise<void>;
};

export const testModeAnalytics: TestModeAnalytics = {
  page: () => Promise.resolve(),
  identify: () => Promise.resolve(),
  user: () => Promise.resolve({ id: () => "test" }),
  reset: () => Promise.resolve(),
  track: () => Promise.resolve(),
};

const AnalyticsContext = createContext<
  AnalyticsBrowser | TestModeAnalytics | null
>(null);

type Props = {
  analytics: AnalyticsBrowser | TestModeAnalytics;
  children: React.ReactNode;
};
export const AnalyticsProvider = ({ analytics, children }: Props) => {
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const result = useContext(AnalyticsContext);
  if (result == null) {
    throw new Error("Context used outside of its Provider!");
  }
  return result;
};
