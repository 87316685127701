/* TypeScript file generated from Ui__LinkButton.resi by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as Ui__LinkButtonBS__Es6Import from './Ui__LinkButton.bs';
const Ui__LinkButtonBS: any = Ui__LinkButtonBS__Es6Import;

import type {Style_t as ReactNative_Style_t} from '../../../../../rescript-shims/ReactNative.shim';

import type {_linkProps as Ui__AppLink__linkProps} from '../../../../../packages/ui/src/routing/Ui__AppLink.gen';

// tslint:disable-next-line:interface-over-type-literal
export type kind = "primary" | "secondary" | "secondaryOutline";

// tslint:disable-next-line:interface-over-type-literal
export type size = "large" | "small";

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly right?: JSX.Element; 
  readonly routeConfiguration: Ui__AppLink__linkProps; 
  readonly textStyles?: ReactNative_Style_t; 
  readonly disabled?: boolean; 
  readonly containerStyle?: ReactNative_Style_t; 
  readonly testOnly_pressed?: boolean; 
  readonly testID?: string; 
  readonly wrapContent?: boolean; 
  readonly linkStyle?: ReactNative_Style_t; 
  readonly title: string; 
  readonly busy?: boolean; 
  readonly left?: JSX.Element; 
  readonly buttonElementsWrapperStyle?: ReactNative_Style_t; 
  readonly kind: kind; 
  readonly size?: size; 
  readonly pressedColor?: string
};

export const make: React.ComponentType<{
  readonly right?: JSX.Element; 
  readonly routeConfiguration: Ui__AppLink__linkProps; 
  readonly textStyles?: ReactNative_Style_t; 
  readonly disabled?: boolean; 
  readonly containerStyle?: ReactNative_Style_t; 
  readonly testOnly_pressed?: boolean; 
  readonly testID?: string; 
  readonly wrapContent?: boolean; 
  readonly linkStyle?: ReactNative_Style_t; 
  readonly title: string; 
  readonly busy?: boolean; 
  readonly left?: JSX.Element; 
  readonly buttonElementsWrapperStyle?: ReactNative_Style_t; 
  readonly kind: kind; 
  readonly size?: size; 
  readonly pressedColor?: string
}> = Ui__LinkButtonBS.make;
