// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as App__Report from "../../../utils/App__Report.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as Ui__TextField from "../../../../../ui/src/textField/components/Ui__TextField.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as Ui__MarkotText from "../../../../../ui/src/text/components/Ui__MarkotText.bs.js";
import * as App__AddressForm from "../addressForm/App__AddressForm.bs.js";
import * as Ui__Toast__Error from "../../../../../ui/src/toast/components/error/Ui__Toast__Error.bs.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as App__AddressEntity from "../../App__AddressEntity.bs.js";
import * as App__AddressModalShell from "../addressModalShell/App__AddressModalShell.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as AppUpdateAddressModal_address_graphql from "../../../../../relay-codegen/generated/AppUpdateAddressModal_address_graphql.bs.js";
import * as AppUpdateAddressModal_updateUserAddressMutation_graphql from "../../../../../relay-codegen/generated/AppUpdateAddressModal_updateUserAddressMutation_graphql.bs.js";

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = ReactRelay.useFragment(AppUpdateAddressModal_address_graphql.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return RescriptRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return AppUpdateAddressModal_address_graphql.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

function use(param) {
  var match = ReactRelay.useMutation(AppUpdateAddressModal_updateUserAddressMutation_graphql.node);
  var mutate = match[0];
  return [
          React.useMemo((function () {
                  return function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
                    return Curry._1(mutate, {
                                onError: param,
                                onCompleted: param$1 !== undefined ? (function (r, errors) {
                                      Curry._2(param$1, AppUpdateAddressModal_updateUserAddressMutation_graphql.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                                    }) : undefined,
                                onUnsubscribe: param$2,
                                optimisticResponse: param$3 !== undefined ? AppUpdateAddressModal_updateUserAddressMutation_graphql.Internal.convertWrapRawResponse(param$3) : undefined,
                                optimisticUpdater: param$4,
                                updater: param$5 !== undefined ? (function (store, r) {
                                      Curry._2(param$5, store, AppUpdateAddressModal_updateUserAddressMutation_graphql.Internal.convertResponse(r));
                                    }) : undefined,
                                variables: AppUpdateAddressModal_updateUserAddressMutation_graphql.Internal.convertVariables(param$6),
                                uploadables: param$7
                              });
                  };
                }), [mutate]),
          match[1]
        ];
}

function App__UpdateAddressModal(Props) {
  var viewerDataId = Props.viewerDataId;
  var address = Props.address;
  var onClose = Props.onClose;
  var visible = Props.visible;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var address$1 = useOpt(address);
  var match$1 = use(undefined);
  var updateUserAddress = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setError = match$2[1];
  var tmp;
  if (address$1 !== undefined) {
    var id = address$1.id;
    var addressDataId = address$1.__id;
    var initialState_recipientName = {
      value: address$1.recipientName,
      validation: {
        kind: "neutral",
        status: Ui__TextField.neutralStatusPreset
      }
    };
    var initialState_country = {
      value: address$1.country,
      validation: {
        kind: "neutral",
        status: Ui__TextField.neutralStatusPreset
      }
    };
    var initialState_postalCode = {
      value: address$1.postalCode,
      validation: {
        kind: "neutral",
        status: Ui__TextField.neutralStatusPreset
      }
    };
    var initialState_city = {
      value: address$1.city,
      validation: {
        kind: "neutral",
        status: Ui__TextField.neutralStatusPreset
      }
    };
    var initialState_streetLine1 = {
      value: address$1.streetLine1,
      validation: {
        kind: "neutral",
        status: Ui__TextField.neutralStatusPreset
      }
    };
    var initialState_state = {
      value: address$1.state2,
      validation: {
        kind: "neutral",
        status: Ui__TextField.neutralStatusPreset
      }
    };
    var initialState_streetLine2 = Belt_Option.getWithDefault(address$1.streetLine2, "");
    var initialState_extraInfo = Belt_Option.getWithDefault(address$1.extraInfo, "");
    var initialState_description = Belt_Option.getWithDefault(address$1.description, "");
    var initialState = {
      recipientName: initialState_recipientName,
      country: initialState_country,
      postalCode: initialState_postalCode,
      city: initialState_city,
      streetLine1: initialState_streetLine1,
      state: initialState_state,
      streetLine2: initialState_streetLine2,
      extraInfo: initialState_extraInfo,
      description: initialState_description,
      error: undefined
    };
    tmp = React.createElement(App__AddressForm.make, {
          initialState: initialState,
          onCancel: onClose,
          onSave: (function (param, onCompleted, onError) {
              Curry.app(updateUserAddress, [
                    (function (param) {
                        App__Report.unexpected(param.message, "App__UpdateAddressModal updateUserAddress onError");
                        Curry._1(setError, (function (param) {
                                return "An error occurred trying to update the address. Please try again!";
                              }));
                        Curry._1(onError, "An error occurred trying to update the address. Please try again!");
                      }),
                    (function (_data, error) {
                        if (error !== undefined) {
                          Curry._1(setError, (function (param) {
                                  return "An error occurred trying to update the address. Please try again!";
                                }));
                          App__Report.unexpected(Belt_Array.joinWith(error, ", ", (function (error) {
                                      return error.message;
                                    })), "App__UpdateAddressModal updateUserAddress onCompleted");
                          return Curry._1(onError, "An error occurred trying to update the address. Please try again!");
                        } else {
                          Curry._1(onCompleted, undefined);
                          return Curry._1(onClose, undefined);
                        }
                      }),
                    undefined,
                    undefined,
                    undefined,
                    (function (store, result) {
                        var match = store.get(viewerDataId);
                        var match$1 = store.get(result.updateUserAddress.address.__id);
                        if (match == null) {
                          return ;
                        }
                        if (match$1 == null) {
                          return ;
                        }
                        Belt_Array.forEach(App__AddressEntity.collectAllRelayConnections(viewerDataId), (function (addressConnectionId) {
                                Belt_Option.forEach(Caml_option.nullable_to_opt(store.get(addressConnectionId)), (function (addressConnection) {
                                        RelayRuntime.ConnectionHandler.deleteNode(addressConnection, addressDataId);
                                      }));
                              }));
                        var defaultBillingAddressId = match.getValue(App__AddressEntity.defaultBillingAddressIdCacheField, undefined);
                        Belt_Option.forEach((defaultBillingAddressId == null) ? undefined : Caml_option.some(defaultBillingAddressId), (function (addressId) {
                                if (addressId === id) {
                                  match.setValue(result.updateUserAddress.address.id, App__AddressEntity.defaultBillingAddressIdCacheField, undefined);
                                  return match.setLinkedRecord(match$1, App__AddressEntity.defaultBillingAddressCacheField, undefined);
                                }
                                
                              }));
                        var defaultShippingAddressId = match.getValue(App__AddressEntity.defaultShippingAddressIdCacheField, undefined);
                        Belt_Option.forEach((defaultShippingAddressId == null) ? undefined : Caml_option.some(defaultShippingAddressId), (function (addressId) {
                                if (addressId === id) {
                                  match.setValue(result.updateUserAddress.address.id, App__AddressEntity.defaultShippingAddressIdCacheField, undefined);
                                  return match.setLinkedRecord(match$1, App__AddressEntity.defaultShippingAddressCacheField, undefined);
                                }
                                
                              }));
                      }),
                    {
                      city: param.city.value,
                      country: param.country.value,
                      description: param.description,
                      extraInfo: param.extraInfo,
                      id: id,
                      postalCode: param.postalCode.value,
                      recipientName: param.recipientName.value,
                      relayConnectionsToUpdate: App__AddressEntity.collectAllRelayConnections(viewerDataId),
                      state: param.state.value,
                      streetLine1: param.streetLine1.value,
                      streetLine2: param.streetLine2
                    },
                    undefined,
                    undefined
                  ]);
            }),
          saving: match$1[1]
        });
  } else {
    tmp = React.createElement(Ui__MarkotText.make, {
          color: match.theme.colors.error,
          children: "Something went wrong. Try closing and reopening the modal."
        });
  }
  return React.createElement(App__AddressModalShell.make, {
              visible: visible,
              onClose: onClose,
              children: null,
              title: "Update address"
            }, tmp, SimpleReact$DecidrIoUtils.renderIfSome(match$2[0], (function (errorMessage) {
                    return React.createElement(Ui__Toast__Error.make, {
                                title: "Error updating address!",
                                description: errorMessage,
                                onDismiss: (function (param) {
                                    Curry._1(setError, (function (param) {
                                            
                                          }));
                                  })
                              });
                  })));
}

var make = App__UpdateAddressModal;

export {
  make ,
}
/* react Not a pure module */
