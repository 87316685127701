// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactNative from "react-native";
import * as TextUtils$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/text/TextUtils.bs.js";

function determineFontName(styles) {
  var styleDict = ReactNative.StyleSheet.flatten(Belt_Array.keepMap(styles, (function (x) {
              return x;
            })));
  var match = Js_dict.get(styleDict, "fontFamily");
  var fontFamily;
  if (match !== undefined) {
    switch (match) {
      case "dm-sans" :
          fontFamily = "dm-sans";
          break;
      case "libre" :
          fontFamily = "libre";
          break;
      default:
        fontFamily = "dm-sans";
    }
  } else {
    fontFamily = "dm-sans";
  }
  var fontWeight = Belt_Option.getWithDefault(Js_dict.get(styleDict, "fontWeight"), "400");
  var fontStyle = Belt_Option.getWithDefault(Js_dict.get(styleDict, "fontStyle"), "");
  var tmp;
  switch (fontWeight) {
    case "500" :
        tmp = "-medium";
        break;
    case "700" :
    case "bold" :
        tmp = "-bold";
        break;
    default:
      tmp = "";
  }
  var modifiers = {
    fontWeight: tmp,
    fontStyle: fontStyle === "italic" ? "-italic" : ""
  };
  var os = ReactNative.Platform.OS;
  if (os === "web") {
    return fontFamily + ", " + TextUtils$DecidrIoUniversalComponents.webSystemFonts;
  } else {
    return fontFamily + modifiers.fontWeight + modifiers.fontStyle;
  }
}

var webSystemFonts = TextUtils$DecidrIoUniversalComponents.webSystemFonts;

export {
  webSystemFonts ,
  determineFontName ,
}
/* react-native Not a pure module */
