// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as ShopifyApi__Id from "./ShopifyApi__Id.bs.js";
import * as ShopifyApi__Infra from "./ShopifyApi__Infra.bs.js";
import * as ShopifyApi__Client from "./ShopifyApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as ShopifyApi__CommonFragments from "./ShopifyApi__CommonFragments.bs.js";
import * as ShopifyApi__ProductVariants from "./ShopifyApi__ProductVariants.bs.js";
import * as ShopifyApi__Scalars__DateTime from "./ShopifyApi__Scalars__DateTime.bs.js";

var query = "fragment NewOrderLineItemFields on OrderLineItem   {\nquantity  \ntitle  \nvariant  {\n...ProductVariantFields   \n}\n\n}\n" + ShopifyApi__ProductVariants.ProductVariantFields.query;

function parse(value) {
  var value$1 = value.variant;
  return {
          quantity: value.quantity,
          title: value.title,
          variant: !(value$1 == null) ? ShopifyApi__ProductVariants.ProductVariantFields.verifyArgsAndParse("ProductVariantFields", value$1) : undefined
        };
}

function serialize(value) {
  var value$1 = value.variant;
  var variant = value$1 !== undefined ? ShopifyApi__ProductVariants.ProductVariantFields.serialize(value$1) : null;
  var value$2 = value.title;
  var value$3 = value.quantity;
  return {
          quantity: value$3,
          title: value$2,
          variant: variant
        };
}

var query$1 = "fragment NewOrderWithLineItemFields on Order   {\nlineItems(first: $first, after: $after)  {\nedges  {\ncursor  \nnode  {\n...NewOrderLineItemFields   \n}\n\n}\n\npageInfo  {\nhasNextPage  \nhasPreviousPage  \nstartCursor  \nendCursor  \n}\n\n}\n\n}\n" + query;

function parse$1(value) {
  var value$1 = value.lineItems;
  var value$2 = value$1.edges;
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.startCursor;
  var value$5 = value$3.endCursor;
  return {
          lineItems: {
            edges: Js_array.map((function (value) {
                    return {
                            cursor: value.cursor,
                            node: parse(value.node)
                          };
                  }), value$2),
            pageInfo: {
              hasNextPage: value$3.hasNextPage,
              hasPreviousPage: value$3.hasPreviousPage,
              startCursor: !(value$4 == null) ? value$4 : undefined,
              endCursor: !(value$5 == null) ? value$5 : undefined
            }
          }
        };
}

function serialize$1(value) {
  var value$1 = value.lineItems;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.endCursor;
  var endCursor = value$3 !== undefined ? value$3 : null;
  var value$4 = value$2.startCursor;
  var startCursor = value$4 !== undefined ? value$4 : null;
  var value$5 = value$2.hasPreviousPage;
  var value$6 = value$2.hasNextPage;
  var pageInfo = {
    hasNextPage: value$6,
    hasPreviousPage: value$5,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$7 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var node = serialize(value$1);
          var value$2 = value.cursor;
          return {
                  cursor: value$2,
                  node: node
                };
        }), value$7);
  var lineItems = {
    edges: edges,
    pageInfo: pageInfo
  };
  return {
          lineItems: lineItems
        };
}

var query$2 = "fragment NewOrderFields on Order   {\nid  \nname  \nemail  \nshippingAddress  {\naddress1  \naddress2  \ncity  \ncountry  \nname  \nphone  \nprovince  \nprovinceCode  \nzip  \n}\n\nphone  \norderNumber  \nprocessedAt  \ntotalShippingPriceV2  {\n...ShopifyMoneyFields   \n}\n\ntotalTaxV2  {\n...ShopifyMoneyFields   \n}\n\nsubtotalPriceV2  {\n...ShopifyMoneyFields   \n}\n\ntotalPriceV2  {\n...ShopifyMoneyFields   \n}\n\n}\n" + ShopifyApi__CommonFragments.ShopifyMoneyFields.query;

function parse$2(value) {
  var value$1 = value.email;
  var value$2 = value.shippingAddress;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var value$3 = value$2.address1;
    var value$4 = value$2.address2;
    var value$5 = value$2.city;
    var value$6 = value$2.country;
    var value$7 = value$2.name;
    var value$8 = value$2.phone;
    var value$9 = value$2.province;
    var value$10 = value$2.provinceCode;
    var value$11 = value$2.zip;
    tmp = {
      address1: !(value$3 == null) ? value$3 : undefined,
      address2: !(value$4 == null) ? value$4 : undefined,
      city: !(value$5 == null) ? value$5 : undefined,
      country: !(value$6 == null) ? value$6 : undefined,
      name: !(value$7 == null) ? value$7 : undefined,
      phone: !(value$8 == null) ? value$8 : undefined,
      province: !(value$9 == null) ? value$9 : undefined,
      provinceCode: !(value$10 == null) ? value$10 : undefined,
      zip: !(value$11 == null) ? value$11 : undefined
    };
  }
  var value$12 = value.phone;
  var value$13 = value.totalShippingPriceV2;
  var value$14 = value.totalTaxV2;
  var value$15 = value.subtotalPriceV2;
  var value$16 = value.totalPriceV2;
  return {
          id: value.id,
          name: value.name,
          email: !(value$1 == null) ? value$1 : undefined,
          shippingAddress: tmp,
          phone: !(value$12 == null) ? value$12 : undefined,
          orderNumber: value.orderNumber,
          processedAt: ShopifyApi__Scalars__DateTime.parse(value.processedAt),
          totalShippingPriceV2: ShopifyApi__CommonFragments.ShopifyMoneyFields.verifyArgsAndParse("ShopifyMoneyFields", value$13),
          totalTaxV2: !(value$14 == null) ? ShopifyApi__CommonFragments.ShopifyMoneyFields.verifyArgsAndParse("ShopifyMoneyFields", value$14) : undefined,
          subtotalPriceV2: !(value$15 == null) ? ShopifyApi__CommonFragments.ShopifyMoneyFields.verifyArgsAndParse("ShopifyMoneyFields", value$15) : undefined,
          totalPriceV2: ShopifyApi__CommonFragments.ShopifyMoneyFields.verifyArgsAndParse("ShopifyMoneyFields", value$16)
        };
}

function serialize$2(value) {
  var value$1 = value.totalPriceV2;
  var totalPriceV2 = ShopifyApi__CommonFragments.ShopifyMoneyFields.serialize(value$1);
  var value$2 = value.subtotalPriceV2;
  var subtotalPriceV2 = value$2 !== undefined ? ShopifyApi__CommonFragments.ShopifyMoneyFields.serialize(value$2) : null;
  var value$3 = value.totalTaxV2;
  var totalTaxV2 = value$3 !== undefined ? ShopifyApi__CommonFragments.ShopifyMoneyFields.serialize(value$3) : null;
  var value$4 = value.totalShippingPriceV2;
  var totalShippingPriceV2 = ShopifyApi__CommonFragments.ShopifyMoneyFields.serialize(value$4);
  var value$5 = value.processedAt;
  var value$6 = ShopifyApi__Scalars__DateTime.serialize(value$5);
  var value$7 = value.orderNumber;
  var value$8 = value.phone;
  var phone = value$8 !== undefined ? value$8 : null;
  var value$9 = value.shippingAddress;
  var shippingAddress;
  if (value$9 !== undefined) {
    var value$10 = value$9.zip;
    var zip = value$10 !== undefined ? value$10 : null;
    var value$11 = value$9.provinceCode;
    var provinceCode = value$11 !== undefined ? value$11 : null;
    var value$12 = value$9.province;
    var province = value$12 !== undefined ? value$12 : null;
    var value$13 = value$9.phone;
    var phone$1 = value$13 !== undefined ? value$13 : null;
    var value$14 = value$9.name;
    var name = value$14 !== undefined ? value$14 : null;
    var value$15 = value$9.country;
    var country = value$15 !== undefined ? value$15 : null;
    var value$16 = value$9.city;
    var city = value$16 !== undefined ? value$16 : null;
    var value$17 = value$9.address2;
    var address2 = value$17 !== undefined ? value$17 : null;
    var value$18 = value$9.address1;
    var address1 = value$18 !== undefined ? value$18 : null;
    shippingAddress = {
      address1: address1,
      address2: address2,
      city: city,
      country: country,
      name: name,
      phone: phone$1,
      province: province,
      provinceCode: provinceCode,
      zip: zip
    };
  } else {
    shippingAddress = null;
  }
  var value$19 = value.email;
  var email = value$19 !== undefined ? value$19 : null;
  var value$20 = value.name;
  var value$21 = value.id;
  return {
          id: value$21,
          name: value$20,
          email: email,
          shippingAddress: shippingAddress,
          phone: phone,
          orderNumber: value$7,
          processedAt: value$6,
          totalShippingPriceV2: totalShippingPriceV2,
          totalTaxV2: totalTaxV2,
          subtotalPriceV2: subtotalPriceV2,
          totalPriceV2: totalPriceV2
        };
}

var Raw = {};

var query$3 = "query OrdersQuery($customerAccessToken: String!, $first: Int, $after: String)  {\ncustomer(customerAccessToken: $customerAccessToken)  {\nid  \norders(first: $first, after: $after, sortKey: PROCESSED_AT, reverse: true)  {\nedges  {\ncursor  \nnode  {\n...NewOrderFields   \n}\n\n}\n\npageInfo  {\nhasNextPage  \nhasPreviousPage  \nstartCursor  \nendCursor  \n}\n\n}\n\n}\n\n}\n" + query$2;

function parse$3(value) {
  var value$1 = value.customer;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.orders;
    var value$3 = value$2.edges;
    var value$4 = value$2.pageInfo;
    var value$5 = value$4.startCursor;
    var value$6 = value$4.endCursor;
    tmp = {
      id: value$1.id,
      orders: {
        edges: Js_array.map((function (value) {
                return {
                        cursor: value.cursor,
                        node: parse$2(value.node)
                      };
              }), value$3),
        pageInfo: {
          hasNextPage: value$4.hasNextPage,
          hasPreviousPage: value$4.hasPreviousPage,
          startCursor: !(value$5 == null) ? value$5 : undefined,
          endCursor: !(value$6 == null) ? value$6 : undefined
        }
      }
    };
  }
  return {
          customer: tmp
        };
}

function serialize$3(value) {
  var value$1 = value.customer;
  var customer;
  if (value$1 !== undefined) {
    var value$2 = value$1.orders;
    var value$3 = value$2.pageInfo;
    var value$4 = value$3.endCursor;
    var endCursor = value$4 !== undefined ? value$4 : null;
    var value$5 = value$3.startCursor;
    var startCursor = value$5 !== undefined ? value$5 : null;
    var value$6 = value$3.hasPreviousPage;
    var value$7 = value$3.hasNextPage;
    var pageInfo = {
      hasNextPage: value$7,
      hasPreviousPage: value$6,
      startCursor: startCursor,
      endCursor: endCursor
    };
    var value$8 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var node = serialize$2(value$1);
            var value$2 = value.cursor;
            return {
                    cursor: value$2,
                    node: node
                  };
          }), value$8);
    var orders = {
      edges: edges,
      pageInfo: pageInfo
    };
    var value$9 = value$1.id;
    customer = {
      id: value$9,
      orders: orders
    };
  } else {
    customer = null;
  }
  return {
          customer: customer
        };
}

function serializeVariables(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  return {
          customerAccessToken: inp.customerAccessToken,
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined
        };
}

var Raw$1 = {};

var query$4 = "query NewOrderById($id: ID!)  {\nnode(id: $id)  {\n__typename\n...on Order   {\n...NewOrderFields   \n}\n\n}\n\n}\n" + query$2;

function parse$4(value) {
  var value$1 = value.node;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1 = typename === "Order" ? ({
          NAME: "Order",
          VAL: {
            newOrderFields: parse$2(value$1)
          }
        }) : ({
          NAME: "UnspecifiedFragment",
          VAL: typename
        });
    tmp = tmp$1;
  }
  return {
          node: tmp
        };
}

function serialize$4(value) {
  var value$1 = value.node;
  var node = value$1 !== undefined ? (
      value$1.NAME === "UnspecifiedFragment" ? ({}) : Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                return {};
              }), [serialize$2(value$1.VAL.newOrderFields)])
    ) : null;
  return {
          node: node
        };
}

function serializeVariables$1(inp) {
  return {
          id: inp.id
        };
}

var Raw$2 = {};

var query$5 = "query NewOrderLineItems($id: ID!, $first: Int, $after: String)  {\nnode(id: $id)  {\n__typename\n...on Order   {\n...NewOrderWithLineItemFields   \n}\n\n}\n\n}\n" + query$1;

function parse$5(value) {
  var value$1 = value.node;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1 = typename === "Order" ? ({
          NAME: "Order",
          VAL: {
            newOrderWithLineItemFields: parse$1(value$1)
          }
        }) : ({
          NAME: "UnspecifiedFragment",
          VAL: typename
        });
    tmp = tmp$1;
  }
  return {
          node: tmp
        };
}

function serialize$5(value) {
  var value$1 = value.node;
  var node = value$1 !== undefined ? (
      value$1.NAME === "UnspecifiedFragment" ? ({}) : Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                return {};
              }), [serialize$1(value$1.VAL.newOrderWithLineItemFields)])
    ) : null;
  return {
          node: node
        };
}

function serializeVariables$2(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  return {
          id: inp.id,
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined
        };
}

function makeNewOrderLineItem(param) {
  return {
          quantity: param.quantity,
          title: param.title,
          variant: Belt_Option.map(param.variant, ShopifyApi__ProductVariants.makeProductVariant)
        };
}

function makeAddress(param) {
  return {
          address1: param.address1,
          address2: param.address2,
          city: param.city,
          country: param.country,
          name: param.name,
          phone: param.phone,
          province: param.province,
          provinceCode: param.provinceCode,
          zip: param.zip
        };
}

function makeNewOrder(param) {
  return {
          id: ShopifyApi__Id.parseExn(param.id),
          name: param.name,
          phone: param.phone,
          orderNumber: param.orderNumber,
          processedAt: param.processedAt,
          shippingAddress: Belt_Option.map(param.shippingAddress, makeAddress),
          subtotalPrice: Belt_Option.map(param.subtotalPriceV2, ShopifyApi__CommonFragments.makeMoney),
          totalShippingPrice: ShopifyApi__CommonFragments.makeMoney(param.totalShippingPriceV2),
          totalTaxPrice: Belt_Option.map(param.totalTaxV2, ShopifyApi__CommonFragments.makeMoney),
          totalPrice: ShopifyApi__CommonFragments.makeMoney(param.totalPriceV2)
        };
}

function fetchOrders(apiConfig, customerAccessToken, first, after) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                        query: query$3,
                        Raw: Raw,
                        parse: parse$3,
                        serialize: serialize$3,
                        serializeVariables: serializeVariables
                      }), {
                    customerAccessToken: customerAccessToken,
                    first: first,
                    after: after
                  }, undefined).then(function (param) {
                  return Belt_Option.map(param.customer, (function (param) {
                                return param.orders;
                              }));
                }).then(function (orders) {
                return Belt_Option.map(orders, (function (param) {
                              var pageInfo = param.pageInfo;
                              return {
                                      pageInfo: {
                                        startCursor: pageInfo.startCursor,
                                        endCursor: pageInfo.endCursor,
                                        hasNextPage: pageInfo.hasNextPage,
                                        hasPreviousPage: pageInfo.hasPreviousPage
                                      },
                                      edges: Belt_Array.map(param.edges, (function (param) {
                                              return {
                                                      cursor: param.cursor,
                                                      node: makeNewOrder(param.node)
                                                    };
                                            }))
                                    };
                            }));
              }).then(function (__x) {
              return Belt_Option.getWithDefault(__x, {
                          pageInfo: {
                            startCursor: undefined,
                            endCursor: undefined,
                            hasNextPage: false,
                            hasPreviousPage: false
                          },
                          edges: []
                        });
            });
}

function fetchNewOrderById(apiConfig, id) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$4,
                    Raw: Raw$1,
                    parse: parse$4,
                    serialize: serialize$4,
                    serializeVariables: serializeVariables$1
                  }), {
                id: ShopifyApi__Id.toString(id)
              }, undefined).then(function (param) {
              var node = param.node;
              if (node !== undefined) {
                if (node.NAME === "UnspecifiedFragment") {
                  RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "fetchNewOrderById - node of unexpected type found:", node);
                  return ;
                } else {
                  return makeNewOrder(node.VAL.newOrderFields);
                }
              } else {
                RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "fetchNewOrderById - node not found. Id", id);
                return ;
              }
            });
}

function fetchOrderLineItems(apiConfig, id, first, after) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query$5,
                    Raw: Raw$2,
                    parse: parse$5,
                    serialize: serialize$5,
                    serializeVariables: serializeVariables$2
                  }), {
                id: id,
                first: first,
                after: after
              }, undefined).then(function (param) {
              var node = param.node;
              if (node !== undefined) {
                if (node.NAME === "UnspecifiedFragment") {
                  RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "NewOrder fetchOrderLineItems - node of unknown type found. Ignoring.", node);
                  return {
                          pageInfo: {
                            startCursor: undefined,
                            endCursor: undefined,
                            hasNextPage: false,
                            hasPreviousPage: false
                          },
                          edges: []
                        };
                }
                var match = node.VAL.newOrderWithLineItemFields.lineItems;
                var pageInfo = match.pageInfo;
                return {
                        pageInfo: {
                          startCursor: pageInfo.startCursor,
                          endCursor: pageInfo.endCursor,
                          hasNextPage: pageInfo.hasNextPage,
                          hasPreviousPage: pageInfo.hasPreviousPage
                        },
                        edges: Belt_Array.map(match.edges, (function (param) {
                                return {
                                        cursor: param.cursor,
                                        node: makeNewOrderLineItem(param.node)
                                      };
                              }))
                      };
              }
              RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "NewOrder fetchOrderLineItems - not found. Id", id);
              return {
                      pageInfo: {
                        startCursor: undefined,
                        endCursor: undefined,
                        hasNextPage: false,
                        hasPreviousPage: false
                      },
                      edges: []
                    };
            });
}

export {
  fetchOrders ,
  fetchNewOrderById ,
  fetchOrderLineItems ,
}
/* ShopifyApi__Id Not a pure module */
