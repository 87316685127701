// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../styling/Ui__Styling.bs.js";
import * as Ui__NextLink from "../next/link/Ui__NextLink.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotText from "../text/components/Ui__MarkotText.bs.js";
import * as Ui__Navigation from "./navigation/Ui__Navigation.bs.js";
import * as Utils__WebUtils from "../../../utils/src/Utils__WebUtils.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";

function refMagic(ref) {
  return Belt_Option.map((ref == null) ? undefined : Caml_option.some(ref), (function (prim) {
                return prim;
              }));
}

var match = Ui__Styling.describe({
      wrapper: {
        TAG: /* Static */0,
        _0: {
          display: "flex",
          flexDirection: "column"
        }
      },
      wrappedContent: {
        TAG: /* Static */0,
        _0: {
          alignSelf: "flex-start"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

var make = React.forwardRef(function (Props, ref) {
      var children = Props.children;
      var style = Props.style;
      var href = Props.href;
      var onClick = Props.onClick;
      var a11yLabel = Props.a11yLabel;
      var wrapContentOpt = Props.wrapContent;
      var hrefAttrs = Props.hrefAttrs;
      var wrapContent = wrapContentOpt !== undefined ? wrapContentOpt : false;
      var styled = Curry._1(stylesheet, undefined);
      var tmp = {
        children: children,
        style: [
          Caml_option.some(Curry._1(styled, styles.wrapper)),
          Bool$DecidrIoUtils.thenSome(wrapContent, Curry._1(styled, styles.wrappedContent)),
          style
        ],
        accessibilityLabel: a11yLabel
      };
      if (href !== undefined) {
        tmp.href = Caml_option.valFromOption(href);
      }
      if (hrefAttrs !== undefined) {
        tmp.hrefAttrs = Caml_option.valFromOption(hrefAttrs);
      }
      if (onClick !== undefined) {
        tmp.onClick = Caml_option.valFromOption(onClick);
      }
      var tmp$1 = Belt_Option.map((ref == null) ? undefined : Caml_option.some(ref), (function (prim) {
              return prim;
            }));
      if (tmp$1 !== undefined) {
        tmp.ref = Caml_option.valFromOption(tmp$1);
      }
      return React.createElement(Ui__MarkotText.make, tmp);
    });

var WebNavigationWrapper = {
  refMagic: refMagic,
  styles: styles,
  stylesheet: stylesheet,
  make: make
};

function Make(Config) {
  var makeConfiguration = function (route, mode, param) {
    return {
            route: route,
            mode: mode
          };
  };
  var useRouting = function (param) {
    var navigation = Ui__Navigation.useNavigation(undefined);
    var handleNativeNavigation = function (configuration) {
      var match = Curry._1(Config.toMobileNav, configuration.route);
      var params = match[1];
      var screenName = match[0];
      var match$1 = Belt_Option.getWithDefault(configuration.mode, "push");
      var match$2 = match$1 === "replace" ? [
          (function (prim0, prim1) {
              prim0.replace(prim1);
            }),
          (function (prim0, prim1, prim2) {
              prim0.replace(prim1, prim2);
            })
        ] : [
          (function (prim0, prim1) {
              prim0.navigate(prim1);
            }),
          (function (prim0, prim1, prim2) {
              prim0.navigate(prim1, prim2);
            })
        ];
      var goWithParams = match$2[1];
      var go = match$2[0];
      Belt_Option.forEach((navigation == null) ? undefined : Caml_option.some(navigation), (function (nav) {
              if (params !== undefined) {
                return Curry._3(goWithParams, nav, screenName, Caml_option.valFromOption(params));
              } else {
                return Curry._2(go, nav, screenName);
              }
            }));
    };
    var setNativeParams = function (route) {
      var match = Curry._1(Config.toMobileNav, route);
      var params = match[1];
      Belt_Option.forEach((navigation == null) ? undefined : Caml_option.some(navigation), (function (nav) {
              if (params !== undefined) {
                nav.setParams(Caml_option.valFromOption(params));
                return ;
              }
              
            }));
    };
    return {
            nativeNavigationHandler: Bool$DecidrIoUtils.thenSome(ReactNative.Platform.OS !== "web", handleNativeNavigation),
            setNativeParams: Bool$DecidrIoUtils.thenSome(ReactNative.Platform.OS !== "web", setNativeParams)
          };
  };
  var Ui__LinkFactory$Make = function (Props) {
    var configuration = Props.configuration;
    var children = Props.children;
    var a11yLabel = Props.a11yLabel;
    var wrapContentOpt = Props.wrapContent;
    var style = Props.style;
    var wrapContent = wrapContentOpt !== undefined ? wrapContentOpt : false;
    if (ReactNative.Platform.OS !== "web") {
      return children;
    }
    var match = Curry._1(Config.unsafe_getRoute, configuration.route);
    if (typeof match !== "number" && match.TAG === /* ExternalRoute */12) {
      var tmp = {
        children: children,
        href: match.url,
        a11yLabel: a11yLabel,
        wrapContent: wrapContent,
        hrefAttrs: {
          target: Belt_Option.getWithDefault(match.target, "_blank")
        }
      };
      if (style !== undefined) {
        tmp.style = Caml_option.valFromOption(style);
      }
      return React.createElement(make, tmp);
    }
    var tmp$1 = {
      href: Curry._1(Utils__WebUtils.Url.asString, Curry._1(Config.toUrl, configuration.route)),
      children: children,
      passHref: true,
      a11yLabel: a11yLabel,
      wrapContent: wrapContent
    };
    var tmp$2 = Belt_Option.map(configuration.mode, (function (mode) {
            return mode === "replace";
          }));
    if (tmp$2 !== undefined) {
      tmp$1.replace = tmp$2;
    }
    if (style !== undefined) {
      tmp$1.style = Caml_option.valFromOption(style);
    }
    return React.createElement(Ui__NextLink.make, tmp$1);
  };
  return {
          makeConfiguration: makeConfiguration,
          useRouting: useRouting,
          make: Ui__LinkFactory$Make
        };
}

export {
  WebNavigationWrapper ,
  Make ,
}
/* match Not a pure module */
