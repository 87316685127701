// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as MarkotApi__PaymentMethod from "../../../api-adapter-markot/src/MarkotApi__PaymentMethod.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";

function makePaymentMethod(param) {
  return {
          __typename: "PaymentMethodDetails",
          stripePaymentMethodId: param.stripePaymentMethodId,
          cardBrand: param.cardBrand,
          cardLast4: param.cardLast4
        };
}

function fetchPaymentMethods2(apiConfig, fetchLiveData) {
  return MarkotApi__PaymentMethod.fetchPaymentMethods2(apiConfig, fetchLiveData).then(function (__x) {
                return Belt_Option.map(__x, (function (__x) {
                              return Belt_Array.map(__x, makePaymentMethod);
                            }));
              }).then(function (result) {
              if (result !== undefined) {
                return result;
              } else {
                RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "[PaymentMethod] fetchPaymentMethods2 - viewer not returned from Markot API");
                return [];
              }
            });
}

function fetchDefaultPaymentMethod2(apiConfig, fetchLiveData) {
  return MarkotApi__PaymentMethod.fetchDefaultPaymentMethod2(apiConfig, fetchLiveData).then(function (__x) {
              return Belt_Option.map(__x, makePaymentMethod);
            });
}

function executeDeletePaymentMethod(apiConfig, stripePaymentMethodInput) {
  return MarkotApi__PaymentMethod.deletePaymentMethod(apiConfig, {
                stripePaymentMethodId: stripePaymentMethodInput.stripePaymentMethodId
              }).then(function (param) {
              return {
                      __typename: "DeletePaymentMethodPayload",
                      deletedStripePaymentMethodId: param.deletedStripePaymentMethodId
                    };
            });
}

export {
  makePaymentMethod ,
  fetchPaymentMethods2 ,
  fetchDefaultPaymentMethod2 ,
  executeDeletePaymentMethod ,
}
/* MarkotApi__PaymentMethod Not a pure module */
