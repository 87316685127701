// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Ui__BaseText from "./Ui__BaseText.bs.js";

var make = React.forwardRef(function (Props, ref) {
      var fontFamily = Props.fontFamily;
      var size = Props.size;
      var color = Props.color;
      var children = Props.children;
      var style = Props.style;
      var ellipsizeMode = Props.ellipsizeMode;
      var numberOfLines = Props.numberOfLines;
      var testID = Props.testID;
      var href = Props.href;
      var hrefAttrs = Props.hrefAttrs;
      var onClick = Props.onClick;
      var onPress = Props.onPress;
      var selectable = Props.selectable;
      var accessibilityLabel = Props.accessibilityLabel;
      var accessibilityRole = Props.accessibilityRole;
      var accessibilityLevel = Props.accessibilityLevel;
      var onLayout = Props.onLayout;
      var match = Curry._1(Ui__Styling.useTheme, undefined);
      var theme = match.theme;
      var tmp = {
        children: children
      };
      if (fontFamily !== undefined) {
        tmp.fontFamily = Caml_option.valFromOption(fontFamily);
      }
      var tmp$1 = Belt_Option.map(size, (function (size) {
              return Ui__BaseText.typographyVariantToFont(size, theme);
            }));
      if (tmp$1 !== undefined) {
        tmp.size = Caml_option.valFromOption(tmp$1);
      }
      if (color !== undefined) {
        tmp.color = Caml_option.valFromOption(color);
      }
      if (accessibilityLabel !== undefined) {
        tmp.accessibilityLabel = Caml_option.valFromOption(accessibilityLabel);
      }
      if (accessibilityLevel !== undefined) {
        tmp.accessibilityLevel = Caml_option.valFromOption(accessibilityLevel);
      }
      if (accessibilityRole !== undefined) {
        tmp.accessibilityRole = Caml_option.valFromOption(accessibilityRole);
      }
      if (ellipsizeMode !== undefined) {
        tmp.ellipsizeMode = Caml_option.valFromOption(ellipsizeMode);
      }
      if (numberOfLines !== undefined) {
        tmp.numberOfLines = Caml_option.valFromOption(numberOfLines);
      }
      if (onLayout !== undefined) {
        tmp.onLayout = Caml_option.valFromOption(onLayout);
      }
      if (onPress !== undefined) {
        tmp.onPress = Caml_option.valFromOption(onPress);
      }
      if (selectable !== undefined) {
        tmp.selectable = Caml_option.valFromOption(selectable);
      }
      if (style !== undefined) {
        tmp.style = Caml_option.valFromOption(style);
      }
      if (testID !== undefined) {
        tmp.testID = Caml_option.valFromOption(testID);
      }
      if (href !== undefined) {
        tmp.href = Caml_option.valFromOption(href);
      }
      if (hrefAttrs !== undefined) {
        tmp.hrefAttrs = Caml_option.valFromOption(hrefAttrs);
      }
      if (onClick !== undefined) {
        tmp.onClick = Caml_option.valFromOption(onClick);
      }
      var tmp$2 = Belt_Option.map((ref == null) ? undefined : Caml_option.some(ref), (function (prim) {
              return prim;
            }));
      if (tmp$2 !== undefined) {
        tmp.ref = Caml_option.valFromOption(tmp$2);
      }
      return React.createElement(Ui__BaseText.make, tmp);
    });

export {
  make ,
}
/* make Not a pure module */
