// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "../../../api-adapter-markot/src/MarkotApi__Id.bs.js";
import * as GraphqlCore__Types from "../../../graphql-core/src/GraphqlCore__Types.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as MarkotApi__PrelovedItemInfo from "../../../api-adapter-markot/src/MarkotApi__PrelovedItemInfo.bs.js";
import * as GqlMiddleware__Entity__PricingOption from "./GqlMiddleware__Entity__PricingOption.bs.js";

function makePrelovedItemInfoId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "PrelovedItemInfo", param);
}

function makePrelovedItemInfo(param) {
  var status = param.status;
  var tmp;
  switch (status.TAG | 0) {
    case /* ItemNotPublished */0 :
        tmp = {
          TAG: /* ItemNotPublished */0,
          __typename: "ItemNotPublished",
          _placeholder: undefined
        };
        break;
    case /* ItemLive */1 :
        tmp = {
          TAG: /* ItemLive */1,
          __typename: "ItemLive",
          paymentInProgress: status.paymentInProgress
        };
        break;
    case /* ItemSold */2 :
        var price = status.price;
        var purchaseMethod = status.purchaseMethod;
        tmp = {
          TAG: /* ItemSold */2,
          __typename: "ItemSold",
          prelovedOrderIdIfViewerIsBuyer: status.prelovedOrderIdIfViewerIsBuyer,
          date: status.date,
          transactionStatus: Belt_Option.map(status.transactionStatus, (function (transactionStatus) {
                  if (transactionStatus === "Pending") {
                    return "Pending";
                  } else {
                    return "Successful";
                  }
                })),
          purchaseMethod: purchaseMethod === "AuctionBuyNowPrice" ? "AuctionBuyNowPrice" : (
              purchaseMethod === "FixedPrice" ? "FixedPrice" : "AuctionHighestBid"
            ),
          price: {
            amount: price.amount,
            currency: price.currency
          }
        };
        break;
    case /* ItemDeletedAndVisible */3 :
        tmp = {
          TAG: /* ItemDeletedAndVisible */4,
          __typename: "ItemDeletedAndVisible",
          _placeholder: undefined
        };
        break;
    case /* ItemDeletedAndHidden */4 :
        tmp = {
          TAG: /* ItemDeletedAndHidden */3,
          __typename: "ItemDeletedAndHidden",
          _placeholder: undefined
        };
        break;
    
  }
  return {
          __typename: "PrelovedItemInfo",
          id: GqlMiddleware__Core__NodeId.make(1, "PrelovedItemInfo", MarkotApi__Id.unpackExn(param.id).internalId),
          prelovedItemId: GqlMiddleware__Core__NodeId.make(1, "PrelovedItem", MarkotApi__Id.unpackExn(param.prelovedItemId).internalId),
          productSlug: param.productSlug,
          pricing: GqlMiddleware__Entity__PricingOption.makePricingOption(param.pricing),
          status: tmp,
          inWishlist: param.inWishlist,
          usedForTesting: param.usedForTesting
        };
}

function fetchPrelovedItemInfo(apiConfig, id, includeTestPrelovedItems) {
  return MarkotApi__PrelovedItemInfo.fetchBasicData(apiConfig, GqlMiddleware__Core__NodeId.toString(id), includeTestPrelovedItems).then(function (__x) {
              return Belt_Option.map(__x, makePrelovedItemInfo);
            });
}

function fetchPrelovedItems(apiConfig, productId, status, first, after, sort) {
  var id = GqlMiddleware__Core__NodeId.toString(productId);
  return MarkotApi__PrelovedItemInfo.fetchItems(apiConfig, id, status, first, after, sort).then(function (__x) {
              return GraphqlCore__Types.Connection.map(__x, makePrelovedItemInfo);
            });
}

export {
  makePrelovedItemInfoId ,
  makePrelovedItemInfo ,
  fetchPrelovedItemInfo ,
  fetchPrelovedItems ,
}
/* MarkotApi__Id Not a pure module */
