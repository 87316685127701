// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__Responsive from "../responsive/Ui__Responsive.bs.js";
import * as Ui__LoadingIndicator from "../loading/components/Ui__LoadingIndicator.bs.js";

function useButtonCommonStyling(busy, disabled, kind, pressedColor, size, pressed, hovered, left, right) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var isWeb = ReactNative.Platform.OS === "web";
  var isDisabled = busy || disabled;
  var match$1 = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        undefined,
        {
          smallPaddingHorizontal: theme.spacing._8,
          smallPaddingVertical: theme.spacing._4,
          largePaddingHorizontal: theme.spacing._10,
          largePaddingVertical: theme.spacing._6
        },
        undefined,
        {
          smallPaddingHorizontal: theme.spacing._10,
          smallPaddingVertical: theme.spacing._6,
          largePaddingHorizontal: theme.spacing._10,
          largePaddingVertical: theme.spacing._6
        },
        undefined,
        {
          smallPaddingHorizontal: theme.spacing._8,
          smallPaddingVertical: theme.spacing._4,
          largePaddingHorizontal: theme.spacing._8,
          largePaddingVertical: theme.spacing._4
        },
        undefined
      ]);
  var backgroundColor = kind === "secondaryOutline" ? "transparent" : (
      kind === "primary" ? (
          isDisabled ? theme.colors.primaryShade3 : theme.colors.primary
        ) : (
          isDisabled ? theme.colors.secondaryShade1 : theme.colors.secondary
        )
    );
  var borderColor;
  var exit = 0;
  if (isWeb && hovered) {
    if (kind === "secondary") {
      borderColor = isDisabled ? undefined : theme.colors.secondaryVariant;
    } else if (kind === "primary") {
      borderColor = isDisabled ? undefined : (
          pressed ? theme.colors.primaryShade1 : theme.colors.primaryShade2
        );
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    borderColor = kind === "secondaryOutline" ? (
        isDisabled ? theme.colors.backgroundVariant2 : theme.colors.onSecondary
      ) : undefined;
  }
  var borderWidth = kind === "secondaryOutline" ? theme.lineThickness.xs : undefined;
  var underlayColor = pressedColor !== undefined ? pressedColor : (
      kind === "secondaryOutline" ? theme.colors.backgroundVariant2 : (
          kind === "primary" ? theme.colors.primaryShade1 : theme.colors.secondaryVariant
        )
    );
  var textColor = kind === "secondaryOutline" ? (
      isDisabled ? theme.colors.backgroundVariant2 : theme.colors.onBackground
    ) : (
      kind === "primary" ? (
          isDisabled ? theme.colors.backgroundVariant2 : theme.colors.onPrimary
        ) : (
          isDisabled ? theme.colors.onSecondaryShade1 : theme.colors.onBackground
        )
    );
  var containerPaddingStyle = size === "small" ? ({
        paddingHorizontal: match$1.smallPaddingHorizontal,
        paddingVertical: match$1.smallPaddingVertical
      }) : ({
        paddingHorizontal: match$1.largePaddingHorizontal,
        paddingVertical: match$1.largePaddingVertical
      });
  var leftElement = busy ? React.createElement(React.Fragment, undefined, React.createElement(ReactNative.View, {
              style: {
                width: 20
              }
            }), React.createElement(Ui__LoadingIndicator.make, {
              size: "small",
              color: textColor,
              testID: "loading-indicator",
              style: {
                position: "absolute"
              }
            })) : (
      left !== undefined ? Caml_option.valFromOption(left) : null
    );
  var textSize = size === "small" ? "button2" : "button1";
  var rightElement = Belt_Option.getWithDefault(right, null);
  return {
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: borderWidth,
          underlayColor: underlayColor,
          textColor: textColor,
          containerPaddingStyle: containerPaddingStyle,
          isDisabled: isDisabled,
          leftElement: leftElement,
          rightElement: rightElement,
          textSize: textSize
        };
}

export {
  useButtonCommonStyling ,
}
/* react Not a pure module */
