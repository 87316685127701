import { make, Button } from "./Ui__Counter.gen";

export const Counter: typeof make & {
  Button: typeof Button["make"];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} = make as any;

Counter.Button = Button.make;

export type {
  Props as ButtonProps,
  make_Props as CounterProps,
} from "./Ui__Counter.gen";
