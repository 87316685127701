// Generated by ReScript, PLEASE EDIT WITH CARE

import FakeRelayStoreJs from "./fake-relay-store.js";

function seed(param) {
  
}

export {
  seed ,
}
/*  Not a pure module */
