// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as MarkotApi__Infra from "./MarkotApi__Infra.bs.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as MarkotApi__PaymentMethod from "./MarkotApi__PaymentMethod.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as MarkotApi__CommonFragments from "./MarkotApi__CommonFragments.bs.js";
import * as MarkotApi__PrelovedItemInfo from "./MarkotApi__PrelovedItemInfo.bs.js";
import * as MarkotApi__Scalars__DateTime from "./MarkotApi__Scalars__DateTime.bs.js";

var query = "fragment PrelovedOrderPaymentMethodFields on PrelovedOrder   {\npaymentMethod  {\n...PaymentMethodFields   \n}\n\n}\n" + MarkotApi__PaymentMethod.PaymentMethodFields.query;

function parse(value) {
  var value$1 = value.paymentMethod;
  return {
          paymentMethod: MarkotApi__PaymentMethod.PaymentMethodFields.verifyArgsAndParse("PaymentMethodFields", value$1)
        };
}

function serialize(value) {
  var value$1 = value.paymentMethod;
  var paymentMethod = MarkotApi__PaymentMethod.PaymentMethodFields.serialize(value$1);
  return {
          paymentMethod: paymentMethod
        };
}

var query$1 = "fragment PrelovedOrderWithLineItemFields on PrelovedOrder   {\nlineItems(first: $first, after: $after)  {\npageInfo  {\nstartCursor  \nendCursor  \nhasPreviousPage  \nhasNextPage  \n}\n\nedges  {\ncursor  \nnode  {\n__typename\n...on PrelovedOrderLineItemCustom   {\ndescription  \ndiscountedTotalPrice  {\n...MoneyFields   \n}\n\nid  \nisRefundAvailable  \nisReturnAvailable  \noriginalTotalPrice  {\n...MoneyFields   \n}\n\nquantity  \n}\n\n...on PrelovedOrderLineItemPrelovedItem   {\ndiscountedTotalPrice  {\n...MoneyFields   \n}\n\nid  \nisRefundAvailable  \nisReturnAvailable  \noriginalTotalPrice  {\n...MoneyFields   \n}\n\nprelovedItem  {\n...PrelovedItemInfoFields   \n}\n\nquantity  \n}\n\n}\n\n}\n\n}\n\n}\n" + MarkotApi__CommonFragments.MoneyFields.query + MarkotApi__PrelovedItemInfo.PrelovedItemInfoFields.query;

function parse$1(value) {
  var value$1 = value.lineItems;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          lineItems: {
            pageInfo: {
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined,
              hasPreviousPage: value$2.hasPreviousPage,
              hasNextPage: value$2.hasNextPage
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var typename = value$1["__typename"];
                    var tmp;
                    switch (typename) {
                      case "PrelovedOrderLineItemCustom" :
                          var value$2 = value$1.discountedTotalPrice;
                          var value$3 = value$1.originalTotalPrice;
                          tmp = {
                            NAME: "PrelovedOrderLineItemCustom",
                            VAL: {
                              description: value$1.description,
                              discountedTotalPrice: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$2),
                              id: value$1.id,
                              isRefundAvailable: value$1.isRefundAvailable,
                              isReturnAvailable: value$1.isReturnAvailable,
                              originalTotalPrice: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$3),
                              quantity: value$1.quantity
                            }
                          };
                          break;
                      case "PrelovedOrderLineItemPrelovedItem" :
                          var value$4 = value$1.discountedTotalPrice;
                          var value$5 = value$1.originalTotalPrice;
                          var value$6 = value$1.prelovedItem;
                          tmp = {
                            NAME: "PrelovedOrderLineItemPrelovedItem",
                            VAL: {
                              discountedTotalPrice: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$4),
                              id: value$1.id,
                              isRefundAvailable: value$1.isRefundAvailable,
                              isReturnAvailable: value$1.isReturnAvailable,
                              originalTotalPrice: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$5),
                              prelovedItem: MarkotApi__PrelovedItemInfo.PrelovedItemInfoFields.verifyArgsAndParse("PrelovedItemInfoFields", value$6),
                              quantity: value$1.quantity
                            }
                          };
                          break;
                      default:
                        tmp = {
                          NAME: "FutureAddedValue",
                          VAL: value$1
                        };
                    }
                    return {
                            cursor: value.cursor,
                            node: tmp
                          };
                  }), value$5)
          }
        };
}

function serialize$1(value) {
  var value$1 = value.lineItems;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var variant = value$1.NAME;
          var node;
          if (variant === "PrelovedOrderLineItemCustom") {
            var value$2 = value$1.VAL;
            var value$3 = value$2.quantity;
            var value$4 = value$2.originalTotalPrice;
            var originalTotalPrice = MarkotApi__CommonFragments.MoneyFields.serialize(value$4);
            var value$5 = value$2.isReturnAvailable;
            var value$6 = value$2.isRefundAvailable;
            var value$7 = value$2.id;
            var value$8 = value$2.discountedTotalPrice;
            var discountedTotalPrice = MarkotApi__CommonFragments.MoneyFields.serialize(value$8);
            var value$9 = value$2.description;
            node = {
              __typename: "PrelovedOrderLineItemCustom",
              description: value$9,
              discountedTotalPrice: discountedTotalPrice,
              id: value$7,
              isRefundAvailable: value$6,
              isReturnAvailable: value$5,
              originalTotalPrice: originalTotalPrice,
              quantity: value$3
            };
          } else if (variant === "PrelovedOrderLineItemPrelovedItem") {
            var value$10 = value$1.VAL;
            var value$11 = value$10.quantity;
            var value$12 = value$10.prelovedItem;
            var prelovedItem = MarkotApi__PrelovedItemInfo.PrelovedItemInfoFields.serialize(value$12);
            var value$13 = value$10.originalTotalPrice;
            var originalTotalPrice$1 = MarkotApi__CommonFragments.MoneyFields.serialize(value$13);
            var value$14 = value$10.isReturnAvailable;
            var value$15 = value$10.isRefundAvailable;
            var value$16 = value$10.id;
            var value$17 = value$10.discountedTotalPrice;
            var discountedTotalPrice$1 = MarkotApi__CommonFragments.MoneyFields.serialize(value$17);
            node = {
              __typename: "PrelovedOrderLineItemPrelovedItem",
              discountedTotalPrice: discountedTotalPrice$1,
              id: value$16,
              isRefundAvailable: value$15,
              isReturnAvailable: value$14,
              originalTotalPrice: originalTotalPrice$1,
              prelovedItem: prelovedItem,
              quantity: value$11
            };
          } else {
            node = value$1.VAL;
          }
          var value$18 = value.cursor;
          return {
                  cursor: value$18,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var value$5 = value$3.hasPreviousPage;
  var value$6 = value$3.endCursor;
  var endCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$3.startCursor;
  var startCursor = value$7 !== undefined ? value$7 : null;
  var pageInfo = {
    startCursor: startCursor,
    endCursor: endCursor,
    hasPreviousPage: value$5,
    hasNextPage: value$4
  };
  var lineItems = {
    pageInfo: pageInfo,
    edges: edges
  };
  return {
          lineItems: lineItems
        };
}

var query$2 = "fragment PrelovedOrderFields on PrelovedOrder   {\nid  \nbillingAddressId  \ndate  \ndeliveryAddressId  \nitemCount  \npaymentMethodId  \npurchaseMethod  \nstatus  {\n__typename\n...on OrderStatusCancelled   {\nreason  \n}\n\n...on OrderStatusDeliveryInProgress   {\ndateSent  \nestimatedDeliveryDate  \n}\n\n...on OrderStatusItemDelivered   {\ndate  \n}\n\n...on OrderStatusMarkotProcessing   {\n__typename  \n}\n\n}\n\ntotalPrice  {\n...MoneyFields   \n}\n\n}\n" + MarkotApi__CommonFragments.MoneyFields.query;

function parse$2(value) {
  var value$1 = value.purchaseMethod;
  var tmp;
  switch (value$1) {
    case "AuctionBuyNowPrice" :
        tmp = "AuctionBuyNowPrice";
        break;
    case "AuctionHighestBid" :
        tmp = "AuctionHighestBid";
        break;
    case "FixedPrice" :
        tmp = "FixedPrice";
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$1
      };
  }
  var value$2 = value.status;
  var typename = value$2["__typename"];
  var tmp$1;
  switch (typename) {
    case "OrderStatusCancelled" :
        var value$3 = value$2.reason;
        tmp$1 = {
          NAME: "OrderStatusCancelled",
          VAL: {
            reason: !(value$3 == null) ? value$3 : undefined
          }
        };
        break;
    case "OrderStatusDeliveryInProgress" :
        var value$4 = value$2.estimatedDeliveryDate;
        tmp$1 = {
          NAME: "OrderStatusDeliveryInProgress",
          VAL: {
            dateSent: MarkotApi__Scalars__DateTime.parse(value$2.dateSent),
            estimatedDeliveryDate: !(value$4 == null) ? MarkotApi__Scalars__DateTime.parse(value$4) : undefined
          }
        };
        break;
    case "OrderStatusItemDelivered" :
        tmp$1 = {
          NAME: "OrderStatusItemDelivered",
          VAL: {
            date: MarkotApi__Scalars__DateTime.parse(value$2.date)
          }
        };
        break;
    case "OrderStatusMarkotProcessing" :
        tmp$1 = {
          NAME: "OrderStatusMarkotProcessing",
          VAL: {
            __typename: value$2.__typename
          }
        };
        break;
    default:
      tmp$1 = {
        NAME: "FutureAddedValue",
        VAL: value$2
      };
  }
  var value$5 = value.totalPrice;
  return {
          id: value.id,
          billingAddressId: value.billingAddressId,
          date: MarkotApi__Scalars__DateTime.parse(value.date),
          deliveryAddressId: value.deliveryAddressId,
          itemCount: value.itemCount,
          paymentMethodId: value.paymentMethodId,
          purchaseMethod: tmp,
          status: tmp$1,
          totalPrice: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$5)
        };
}

function serialize$2(value) {
  var value$1 = value.totalPrice;
  var totalPrice = MarkotApi__CommonFragments.MoneyFields.serialize(value$1);
  var value$2 = value.status;
  var variant = value$2.NAME;
  var status;
  if (variant === "FutureAddedValue") {
    status = value$2.VAL;
  } else if (variant === "OrderStatusCancelled") {
    var value$3 = value$2.VAL.reason;
    var reason = value$3 !== undefined ? value$3 : null;
    status = {
      __typename: "OrderStatusCancelled",
      reason: reason
    };
  } else if (variant === "OrderStatusDeliveryInProgress") {
    var value$4 = value$2.VAL;
    var value$5 = value$4.estimatedDeliveryDate;
    var estimatedDeliveryDate = value$5 !== undefined ? MarkotApi__Scalars__DateTime.serialize(value$5) : null;
    var value$6 = value$4.dateSent;
    var value$7 = MarkotApi__Scalars__DateTime.serialize(value$6);
    status = {
      __typename: "OrderStatusDeliveryInProgress",
      dateSent: value$7,
      estimatedDeliveryDate: estimatedDeliveryDate
    };
  } else if (variant === "OrderStatusItemDelivered") {
    var value$8 = value$2.VAL.date;
    var value$9 = MarkotApi__Scalars__DateTime.serialize(value$8);
    status = {
      __typename: "OrderStatusItemDelivered",
      date: value$9
    };
  } else {
    status = {
      __typename: "OrderStatusMarkotProcessing"
    };
  }
  var value$10 = value.purchaseMethod;
  var purchaseMethod = typeof value$10 === "object" ? value$10.VAL : (
      value$10 === "AuctionBuyNowPrice" ? "AuctionBuyNowPrice" : (
          value$10 === "FixedPrice" ? "FixedPrice" : "AuctionHighestBid"
        )
    );
  var value$11 = value.paymentMethodId;
  var value$12 = value.itemCount;
  var value$13 = value.deliveryAddressId;
  var value$14 = value.date;
  var value$15 = MarkotApi__Scalars__DateTime.serialize(value$14);
  var value$16 = value.billingAddressId;
  var value$17 = value.id;
  return {
          id: value$17,
          billingAddressId: value$16,
          date: value$15,
          deliveryAddressId: value$13,
          itemCount: value$12,
          paymentMethodId: value$11,
          purchaseMethod: purchaseMethod,
          status: status,
          totalPrice: totalPrice
        };
}

var Raw = {};

var query$3 = "query PrelovedOrderBasicData($id: ID!)  {\nnode(id: $id)  {\n__typename\n...on PrelovedOrder   {\n...PrelovedOrderFields   \n}\n\n}\n\n}\n" + query$2;

function parse$3(value) {
  var value$1 = value.node;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1 = typename === "PrelovedOrder" ? ({
          NAME: "PrelovedOrder",
          VAL: {
            prelovedOrderFields: parse$2(value$1)
          }
        }) : ({
          NAME: "UnspecifiedFragment",
          VAL: typename
        });
    tmp = tmp$1;
  }
  return {
          node: tmp
        };
}

function serialize$3(value) {
  var value$1 = value.node;
  var node = value$1 !== undefined ? (
      value$1.NAME === "UnspecifiedFragment" ? ({}) : Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                return {};
              }), [serialize$2(value$1.VAL.prelovedOrderFields)])
    ) : null;
  return {
          node: node
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

var Raw$1 = {};

var query$4 = "query PrelovedOrders($first: Int, $after: String)  {\nviewer  {\nprelovedOrders(first: $first, after: $after)  {\npageInfo  {\nstartCursor  \nendCursor  \nhasPreviousPage  \nhasNextPage  \n}\n\nedges  {\ncursor  \nnode  {\n...PrelovedOrderFields   \n}\n\n}\n\n}\n\n}\n\n}\n" + query$2;

function parse$4(value) {
  var value$1 = value.viewer;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.prelovedOrders;
    var value$3 = value$2.pageInfo;
    var value$4 = value$3.startCursor;
    var value$5 = value$3.endCursor;
    var value$6 = value$2.edges;
    tmp = {
      prelovedOrders: {
        pageInfo: {
          startCursor: !(value$4 == null) ? value$4 : undefined,
          endCursor: !(value$5 == null) ? value$5 : undefined,
          hasPreviousPage: value$3.hasPreviousPage,
          hasNextPage: value$3.hasNextPage
        },
        edges: Js_array.map((function (value) {
                return {
                        cursor: value.cursor,
                        node: parse$2(value.node)
                      };
              }), value$6)
      }
    };
  }
  return {
          viewer: tmp
        };
}

function serialize$4(value) {
  var value$1 = value.viewer;
  var viewer;
  if (value$1 !== undefined) {
    var value$2 = value$1.prelovedOrders;
    var value$3 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var node = serialize$2(value$1);
            var value$2 = value.cursor;
            return {
                    cursor: value$2,
                    node: node
                  };
          }), value$3);
    var value$4 = value$2.pageInfo;
    var value$5 = value$4.hasNextPage;
    var value$6 = value$4.hasPreviousPage;
    var value$7 = value$4.endCursor;
    var endCursor = value$7 !== undefined ? value$7 : null;
    var value$8 = value$4.startCursor;
    var startCursor = value$8 !== undefined ? value$8 : null;
    var pageInfo = {
      startCursor: startCursor,
      endCursor: endCursor,
      hasPreviousPage: value$6,
      hasNextPage: value$5
    };
    var prelovedOrders = {
      pageInfo: pageInfo,
      edges: edges
    };
    viewer = {
      prelovedOrders: prelovedOrders
    };
  } else {
    viewer = null;
  }
  return {
          viewer: viewer
        };
}

function serializeVariables$1(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  return {
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined
        };
}

var Raw$2 = {};

var query$5 = "query PrelovedOrderLineItems($id: ID!, $first: Int, $after: String)  {\nnode(id: $id)  {\n__typename\n...on PrelovedOrder   {\n...PrelovedOrderWithLineItemFields   \n}\n\n}\n\n}\n" + query$1;

function parse$5(value) {
  var value$1 = value.node;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1 = typename === "PrelovedOrder" ? ({
          NAME: "PrelovedOrder",
          VAL: {
            prelovedOrderWithLineItemFields: parse$1(value$1)
          }
        }) : ({
          NAME: "UnspecifiedFragment",
          VAL: typename
        });
    tmp = tmp$1;
  }
  return {
          node: tmp
        };
}

function serialize$5(value) {
  var value$1 = value.node;
  var node = value$1 !== undefined ? (
      value$1.NAME === "UnspecifiedFragment" ? ({}) : Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                return {};
              }), [serialize$1(value$1.VAL.prelovedOrderWithLineItemFields)])
    ) : null;
  return {
          node: node
        };
}

function serializeVariables$2(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  return {
          id: inp.id,
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined
        };
}

var Raw$3 = {};

var query$6 = "query PrelovedOrderPaymentMethod($id: ID!)  {\nnode(id: $id)  {\n__typename\n...on PrelovedOrder   {\n...PrelovedOrderPaymentMethodFields   \n}\n\n}\n\n}\n" + query;

function parse$6(value) {
  var value$1 = value.node;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1 = typename === "PrelovedOrder" ? ({
          NAME: "PrelovedOrder",
          VAL: {
            prelovedOrderPaymentMethodFields: parse(value$1)
          }
        }) : ({
          NAME: "UnspecifiedFragment",
          VAL: typename
        });
    tmp = tmp$1;
  }
  return {
          node: tmp
        };
}

function serialize$6(value) {
  var value$1 = value.node;
  var node = value$1 !== undefined ? (
      value$1.NAME === "UnspecifiedFragment" ? ({}) : Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                return {};
              }), [serialize(value$1.VAL.prelovedOrderPaymentMethodFields)])
    ) : null;
  return {
          node: node
        };
}

function serializeVariables$3(inp) {
  return {
          id: inp.id
        };
}

function makeStatus(status) {
  var variant = status.NAME;
  if (variant === "FutureAddedValue") {
    RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "PrelovedOrder makeStatus - PrelovedOrder.status unknown typename", status);
    return Js_exn.raiseError("PrelovedOrder.status unknown typename.");
  }
  if (variant === "OrderStatusCancelled") {
    return {
            TAG: /* OrderStatusCancelled */0,
            reason: status.VAL.reason
          };
  }
  if (variant !== "OrderStatusDeliveryInProgress") {
    if (variant === "OrderStatusItemDelivered") {
      return {
              TAG: /* OrderStatusItemDelivered */2,
              date: status.VAL.date
            };
    } else {
      return /* OrderStatusMarkotProcessing */0;
    }
  }
  var match = status.VAL;
  return {
          TAG: /* OrderStatusDeliveryInProgress */1,
          dateSent: match.dateSent,
          estimatedDeliveryDate: match.estimatedDeliveryDate
        };
}

function makePurchaseMethod(purchaseMethod) {
  if (typeof purchaseMethod === "object") {
    return Js_exn.raiseError("PrelovedOrder.purchaseMethod unknown enum value: " + purchaseMethod.VAL);
  } else if (purchaseMethod === "AuctionBuyNowPrice") {
    return "AuctionBuyNowPrice";
  } else if (purchaseMethod === "FixedPrice") {
    return "FixedPrice";
  } else {
    return "AuctionHighestBid";
  }
}

function makePrelovedOrder(param) {
  return {
          id: MarkotApi__Id.parseExn(param.id),
          billingAddressId: MarkotApi__Id.parseExn(param.billingAddressId),
          date: param.date,
          deliveryAddressId: MarkotApi__Id.parseExn(param.deliveryAddressId),
          itemCount: param.itemCount,
          paymentMethodId: param.paymentMethodId,
          purchaseMethod: makePurchaseMethod(param.purchaseMethod),
          status: makeStatus(param.status),
          totalPrice: MarkotApi__CommonFragments.makeMoney(param.totalPrice)
        };
}

function fetchBasicData(apiConfig, id) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$3,
                    Raw: Raw,
                    parse: parse$3,
                    serialize: serialize$3,
                    serializeVariables: serializeVariables
                  }), {
                id: id
              }, undefined).then(function (param) {
              var node = param.node;
              if (node !== undefined) {
                if (node.NAME === "UnspecifiedFragment") {
                  RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "PrelovedOrder fetchBasicData - node of unknown type found. Ignoring.", node);
                  return ;
                } else {
                  return makePrelovedOrder(node.VAL.prelovedOrderFields);
                }
              } else {
                RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "PrelovedOrder fetchBasicData - not found. Id", id);
                return ;
              }
            });
}

function fetchPrelovedOrders(apiConfig, first, after) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                          query: query$4,
                          Raw: Raw$1,
                          parse: parse$4,
                          serialize: serialize$4,
                          serializeVariables: serializeVariables$1
                        }), {
                      first: first,
                      after: after
                    }, undefined).then(function (param) {
                    return param.viewer;
                  }).then(function (viewer) {
                  return RescriptUtils__OptionPlus.tapNone(viewer, (function (param) {
                                RescriptUtils__Logger.warn(MarkotApi__Infra.logger, "Viewer not found when getting preloved orders");
                              }));
                }).then(function (__x) {
                return Belt_Option.map(__x, (function (param) {
                              var match = param.prelovedOrders;
                              var pageInfo = match.pageInfo;
                              return {
                                      pageInfo: {
                                        startCursor: pageInfo.startCursor,
                                        endCursor: pageInfo.endCursor,
                                        hasNextPage: pageInfo.hasNextPage,
                                        hasPreviousPage: pageInfo.hasPreviousPage
                                      },
                                      edges: Belt_Array.map(match.edges, (function (param) {
                                              return {
                                                      cursor: param.cursor,
                                                      node: makePrelovedOrder(param.node)
                                                    };
                                            }))
                                    };
                            }));
              }).then(function (__x) {
              return Belt_Option.getWithDefault(__x, {
                          pageInfo: {
                            startCursor: undefined,
                            endCursor: undefined,
                            hasNextPage: false,
                            hasPreviousPage: false
                          },
                          edges: []
                        });
            });
}

function makePrelovedOrderLineItem(lineItem) {
  var variant = lineItem.NAME;
  if (variant === "PrelovedOrderLineItemCustom") {
    var match = lineItem.VAL;
    return {
            TAG: /* PrelovedOrderLineItemCustom */0,
            description: match.description,
            discountedTotalPrice: MarkotApi__CommonFragments.makeMoney(match.discountedTotalPrice),
            id: MarkotApi__Id.parseExn(match.id),
            isRefundAvailable: match.isRefundAvailable,
            isReturnAvailable: match.isReturnAvailable,
            originalTotalPrice: MarkotApi__CommonFragments.makeMoney(match.originalTotalPrice),
            quantity: match.quantity
          };
  }
  if (variant === "PrelovedOrderLineItemPrelovedItem") {
    var match$1 = lineItem.VAL;
    return {
            TAG: /* PrelovedOrderLineItemPrelovedItem */1,
            discountedTotalPrice: MarkotApi__CommonFragments.makeMoney(match$1.discountedTotalPrice),
            id: MarkotApi__Id.parseExn(match$1.id),
            isRefundAvailable: match$1.isRefundAvailable,
            isReturnAvailable: match$1.isReturnAvailable,
            originalTotalPrice: MarkotApi__CommonFragments.makeMoney(match$1.originalTotalPrice),
            prelovedItem: MarkotApi__PrelovedItemInfo.makePrelovedItemInfo(match$1.prelovedItem),
            quantity: match$1.quantity
          };
  }
  RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "PrelovedOrder.makePrelovedOrderLineItem - PrelovedOrderLineItem unknown typename", lineItem);
  return Js_exn.raiseError("PrelovedOrderLineItem unknown typename.");
}

function fetchPrelovedOrderLineItems(apiConfig, id, first, after) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$5,
                    Raw: Raw$2,
                    parse: parse$5,
                    serialize: serialize$5,
                    serializeVariables: serializeVariables$2
                  }), {
                id: id,
                first: first,
                after: after
              }, undefined).then(function (param) {
              var node = param.node;
              if (node !== undefined) {
                if (node.NAME === "UnspecifiedFragment") {
                  RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "PrelovedOrder fetchPrelovedOrderLineItems - node of unknown type found. Ignoring.", node);
                  return {
                          pageInfo: {
                            startCursor: undefined,
                            endCursor: undefined,
                            hasNextPage: false,
                            hasPreviousPage: false
                          },
                          edges: []
                        };
                }
                var match = node.VAL.prelovedOrderWithLineItemFields.lineItems;
                var pageInfo = match.pageInfo;
                return {
                        pageInfo: {
                          startCursor: pageInfo.startCursor,
                          endCursor: pageInfo.endCursor,
                          hasNextPage: pageInfo.hasNextPage,
                          hasPreviousPage: pageInfo.hasPreviousPage
                        },
                        edges: Belt_Array.map(match.edges, (function (param) {
                                return {
                                        cursor: param.cursor,
                                        node: makePrelovedOrderLineItem(param.node)
                                      };
                              }))
                      };
              }
              RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "PrelovedOrder fetchPrelovedOrderLineItems - not found. Id", id);
              return {
                      pageInfo: {
                        startCursor: undefined,
                        endCursor: undefined,
                        hasNextPage: false,
                        hasPreviousPage: false
                      },
                      edges: []
                    };
            });
}

function fetchPaymentMethodData(apiConfig, id) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$6,
                    Raw: Raw$3,
                    parse: parse$6,
                    serialize: serialize$6,
                    serializeVariables: serializeVariables$3
                  }), {
                id: id
              }, undefined).then(function (param) {
              var node = param.node;
              if (node !== undefined) {
                if (node.NAME === "UnspecifiedFragment") {
                  RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "PrelovedOrder fetchPaymentMethodData - node of unknown type found. Ignoring.", node);
                  return ;
                } else {
                  return MarkotApi__PaymentMethod.makePaymentMethod(node.VAL.prelovedOrderPaymentMethodFields.paymentMethod);
                }
              } else {
                RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "PrelovedOrder fetchPaymentMethodData - not found. Id", id);
                return ;
              }
            });
}

export {
  fetchBasicData ,
  fetchPrelovedOrders ,
  fetchPrelovedOrderLineItems ,
  fetchPaymentMethodData ,
}
/* MarkotApi__Id Not a pure module */
