// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../ui/src/group/components/Ui__Group.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AuthContext from "../auth/context/AuthContext.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as ReactNative from "react-native";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as App__Bag__List from "./components/list/App__Bag__List.bs.js";
import * as Ui__Responsive from "../../../ui/src/responsive/Ui__Responsive.bs.js";
import * as Ui__PageSection from "../../../ui/src/pageSection/components/Ui__PageSection.bs.js";
import * as Ui__LocalStorage from "../../../ui/src/localStorage/Ui__LocalStorage.bs.js";
import * as Ui__BagEmptyState from "../../../ui/src/bagEmptyState/components/Ui__BagEmptyState.bs.js";
import * as Utils__StringUtils from "../../../utils/src/Utils__StringUtils.bs.js";
import * as Ui__ListsLoadingView from "../../../ui/src/loading/components/Ui__ListsLoadingView.bs.js";
import * as App__RefreshQueryHook from "../relay/App__RefreshQueryHook.bs.js";
import * as Ignored$DecidrIoUtils from "@decidr-io/utils/src/functions/Ignored.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as AppBagPageQuery_graphql from "../../../relay-codegen/generated/AppBagPageQuery_graphql.bs.js";
import * as Ui__MarkotErrorBoundary from "../../../ui/src/error/markotErrorBoundary/Ui__MarkotErrorBoundary.bs.js";
import * as AppBagPageMarkotUserQuery_graphql from "../../../relay-codegen/generated/AppBagPageMarkotUserQuery_graphql.bs.js";

function use(variables, fetchPolicy, fetchKey, networkCacheConfig, param) {
  var data = ReactRelay.useLazyLoadQuery(AppBagPageQuery_graphql.node, RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(AppBagPageQuery_graphql.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return RescriptRelay_Internal.internal_useConvertedValue(AppBagPageQuery_graphql.Internal.convertResponse, data);
}

function $$fetch(environment, variables, onResult, networkCacheConfig, fetchPolicy, param) {
  ReactRelay.fetchQuery(environment, AppBagPageQuery_graphql.node, AppBagPageQuery_graphql.Internal.convertVariables(variables), {
          networkCacheConfig: networkCacheConfig,
          fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
        }).subscribe({
        next: (function (res) {
            Curry._1(onResult, {
                  TAG: /* Ok */0,
                  _0: AppBagPageQuery_graphql.Internal.convertResponse(res)
                });
          }),
        error: (function (err) {
            Curry._1(onResult, {
                  TAG: /* Error */1,
                  _0: err
                });
          })
      });
}

function use$1(variables, fetchPolicy, fetchKey, networkCacheConfig, param) {
  var data = ReactRelay.useLazyLoadQuery(AppBagPageMarkotUserQuery_graphql.node, RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(AppBagPageMarkotUserQuery_graphql.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return RescriptRelay_Internal.internal_useConvertedValue(AppBagPageMarkotUserQuery_graphql.Internal.convertResponse, data);
}

var match = Ui__Styling.describe({
      emptyStateContainer: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      container: {
        TAG: /* Dual */2,
        _0: {
          flex: 1
        },
        _1: (function (props) {
            return {
                    backgroundColor: props.theme.colors.background,
                    paddingHorizontal: props.theme.sectionSpacing._1,
                    paddingTop: props.theme.sectionSpacing._1
                  };
          })
      },
      listContainer: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      listItemStyle: {
        TAG: /* Dynamic */1,
        _0: (function (props) {
            return {
                    paddingHorizontal: props.theme.spacing._4
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

var widthLimiterStyle = Ui__Responsive.widthLimiterStyle(undefined, 768, undefined);

function App__BagPage(Props) {
  var showGoToBagButtonOpt = Props.showGoToBagButton;
  var runQueryOpt = Props.runQuery;
  var showGoToBagButton = showGoToBagButtonOpt !== undefined ? showGoToBagButtonOpt : false;
  var runQuery = runQueryOpt !== undefined ? runQueryOpt : true;
  var match = Curry._1(AuthContext.useAuth, undefined);
  var isAuthenticated = Belt_Option.mapWithDefault(match.authInfo, false, (function (param) {
          return param.status === "authenticated";
        }));
  var match$1 = Ui__LocalStorage.use("bagId");
  var getItem = match$1.getItem;
  var match$2 = React.useState(function () {
        
      });
  var setLocalBagId = match$2[1];
  var localBagId = match$2[0];
  var match$3 = React.useState(function () {
        return true;
      });
  var setReadingCache = match$3[1];
  React.useEffect(function () {
        if (isAuthenticated) {
          Curry._1(setReadingCache, (function (param) {
                  return false;
                }));
        } else {
          Ignored$DecidrIoUtils.promise(Curry._1(getItem, undefined).then(function (value) {
                    Curry._1(setLocalBagId, (function (param) {
                            if (value === null) {
                              return ;
                            } else {
                              return Caml_option.some(value);
                            }
                          }));
                    Curry._1(setReadingCache, (function (param) {
                            return false;
                          }));
                  }));
        }
      });
  var viewerResult = use$1({
        isAuthenticated: isAuthenticated
      }, undefined, undefined, undefined, undefined);
  var viewerBagId = Belt_Option.flatMap(viewerResult.viewer, (function (param) {
          return param.bagId;
        }));
  var bagId = React.useMemo((function () {
          return Belt_Option.flatMap(Belt_Array.getBy([
                          viewerBagId,
                          localBagId
                        ], Belt_Option.isSome), (function (x) {
                        return x;
                      }));
        }), [
        viewerBagId,
        localBagId
      ]);
  var result = use({
        bagId: Belt_Option.getWithDefault(bagId, ""),
        viewerHasABag: Belt_Option.isSome(bagId) && runQuery
      }, /* StoreAndNetwork */2, undefined, undefined, undefined);
  var match$4 = App__RefreshQueryHook.use({
        Types: {},
        $$fetch: $$fetch
      }, {
        bagId: Belt_Option.getWithDefault(bagId, ""),
        viewerHasABag: Belt_Option.isSome(bagId)
      }, "App__BagPage");
  var match$5 = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match$5.theme;
  var match$6 = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        {
          imageSize: 92,
          listItemSpacing: theme.spacing._8,
          dividerSpacing: theme.spacing._6
        },
        undefined,
        {
          imageSize: 106,
          listItemSpacing: theme.spacing._8,
          dividerSpacing: theme.spacing._6
        },
        undefined,
        {
          imageSize: 106,
          listItemSpacing: theme.spacing._10,
          dividerSpacing: theme.spacing._8
        },
        undefined,
        {
          imageSize: 80,
          listItemSpacing: theme.spacing._6,
          dividerSpacing: theme.spacing._4
        },
        undefined
      ]);
  var styled = Curry._1(stylesheet, {
        theme: theme,
        dividerSpacing: match$6.dividerSpacing
      });
  var tmp;
  if (match$3[0]) {
    tmp = null;
  } else {
    var bag = result.bag;
    var exit = 0;
    if (bag !== undefined && bag.totalQuantity !== 0) {
      var tmp$1 = {
        bag: bag.fragmentRefs,
        bagInfo: bag.fragmentRefs,
        total: bag.totalPrice.fragmentRefs,
        itemSpacing: match$6.listItemSpacing,
        paddingHorizontal: 0,
        showGoToBagButton: showGoToBagButton,
        listImageSize: match$6.imageSize,
        itemStyle: Curry._1(styled, styles.listItemStyle),
        contentContainerStyle: widthLimiterStyle,
        refresh: match$4[1],
        refreshing: match$4[0]
      };
      var tmp$2 = Belt_Option.map(viewerResult.viewer, (function (param) {
              return param.fragmentRefs;
            }));
      if (tmp$2 !== undefined) {
        tmp$1.viewerInfo = Caml_option.valFromOption(tmp$2);
      }
      tmp = React.createElement(Ui__Group.make, {
            spacing: theme.spacing._6,
            children: null,
            containerStyle: Curry._1(styled, styles.listContainer),
            testID: "bag-page-container"
          }, React.createElement(Ui__PageSection.HeadingWithCount.make, {
                title: Utils__StringUtils.makeSingularOrPluralText(bag.totalQuantity, "item in the bag", "items in the bag", "No items in the bag", (function (param, otherText) {
                        return otherText;
                      }), undefined),
                count: bag.totalQuantity,
                style: widthLimiterStyle
              }), React.createElement(Ui__MarkotErrorBoundary.make, {
                debugContext: "App__BagPage List",
                children: React.createElement(React.Suspense, {
                      children: React.createElement(App__Bag__List.make, tmp$1),
                      fallback: React.createElement(Ui__ListsLoadingView.make, {})
                    })
              }));
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp = React.createElement(Ui__BagEmptyState.make, {
            containerStyle: Curry._1(styled, styles.emptyStateContainer)
          });
    }
    
  }
  return React.createElement(ReactNative.View, {
              style: Curry._1(styled, styles.container),
              testID: "bag-page-wrapper",
              children: tmp
            });
}

var make = App__BagPage;

export {
  make ,
}
/* match Not a pure module */
