// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";

var storageDelimiter = "::";

var storagePrefix = "feature-flag" + storageDelimiter;

var displayFeatureFlags = [
  {
    key: "placeholder",
    title: "Placeholder feature flag",
    description: "Used to show how to work with and use feature flags"
  },
  {
    key: "heroSection230227",
    title: "Discover Hero section",
    description: "Display different hero section on Discover page"
  },
  {
    key: "promoBanner230227",
    title: "Promo banner",
    description: "Display promo banner on top of the pages"
  },
  {
    key: "notificationScreen",
    title: "Notification preferences screen",
    description: "Make 'notification preferences' part of the user menu where users are enabled to set email or push notifications they want to receive for all kind of events (e.g. bidding events)."
  },
  {
    key: "useMarkdownDescription",
    title: "Enable displaying markdown description on Product page",
    description: "Enable displaying markdown description on Product page to present data in markdown formating."
  },
  {
    key: "showReloadAppButtonOnFFPage",
    title: "Show 'RELOAD APP' button on feature flags page",
    description: "Show 'RELOAD APP' button on feature flags page in order to be able to reload app when some feature flag status is changed."
  },
  {
    key: "implementRefurbishedProducts",
    title: "Refurbished products",
    description: "Implement refurbished products and have a different product page for them"
  },
  {
    key: "showRefurbishedTagOverImages",
    title: "Show 'Actual refurbished product' tag",
    description: "Show 'Actual refurbished product' tag over the images on a refurbished product page."
  },
  {
    key: "productImageThumbnails",
    title: "Show product image thumbnails",
    description: "Show product image thumbnails on a product page."
  },
  {
    key: "linkToHomepageFromAuthPages",
    title: "Markot logo navigates to home page",
    description: "On login/register page Markot logo navigates to home page."
  },
  {
    key: "newPrelovedCards",
    title: "New updated preloved cards",
    description: "Use new updated preloved cards in order to match rest of the cards across the app."
  },
  {
    key: "updateUrlOnQueryChange",
    title: "Update page URL when search input changes",
    description: "Update URL on Brand and Subcategory pages when search input changes. Preserves last search when going back from Product page."
  },
  {
    key: "redesignDiscoverHeroBanner",
    title: "Redesign Discover hero banner",
    description: "Redesign Discover hero banner to be a carousel of few banners instead of one, static banner."
  },
  {
    key: "noPrelovedNotSupportedMessage",
    title: "Remove 'This product does not support preloved items' message",
    description: "Remove the message that may lead Google to think product page is in fact a soft 404 page."
  },
  {
    key: "a11yRoleButtonOnAllButtons",
    title: "Use a11y role button on all buttons",
    description: "Use a11y role button on all buttons to improve accessibility."
  },
  {
    key: "expandProductFeaturesOnLoad",
    title: "Expand product features on load",
    description: "Expands product features section on load to improve SEO and user experience."
  },
  {
    key: "newZeroPrelovedMessageForSeo",
    title: "Change a message when there are no preloved items",
    description: "The message is now more a call to action and more SEO friendly."
  },
  {
    key: "leaveSpecsTabContentInDom",
    title: "Have Product specs content always in DOM",
    description: "Product specs content is present when visually hidden, for SEO."
  },
  {
    key: "batchFetchDefaultVariants",
    title: "Load product cards faster by batch-fetching default variants",
    description: "Default variants are fetched in batches to improve performance."
  },
  {
    key: "addFooterToMobileWeb",
    title: "Displays a footer to mobile web",
    description: "It displays a footer with all of its information on the mobile web."
  },
  {
    key: "implementOverstockedProducts",
    title: "Overstocked products",
    description: "Implement overstocked products and have a different product page for them"
  }
];

var cmp = Caml.int_compare;

var FeatureFlagsComparable = Belt_Id.MakeComparable({
      cmp: cmp
    });

function fromArray(param) {
  return Belt_Map.fromArray(param, FeatureFlagsComparable);
}

function mergeWithNetwork(map1, map2) {
  return Belt_Map.merge(map1, map2, (function (_key, v1, v2) {
                if (v1 !== undefined) {
                  if (v2 !== undefined) {
                    return v2;
                  } else {
                    return v1;
                  }
                } else if (v2 !== undefined) {
                  return v2;
                } else {
                  return ;
                }
              }));
}

var empty = Belt_Map.fromArray([], FeatureFlagsComparable);

var FeatureFlagsMap = {
  toArray: Belt_Map.toArray,
  fromArray: fromArray,
  get: Belt_Map.get,
  mergeWithNetwork: mergeWithNetwork,
  empty: empty
};

function unsafe_make(prim) {
  return prim;
}

var errorReportingPrefix = "f.";

var featureFlagKeyMaximumLength = 30;

export {
  errorReportingPrefix ,
  featureFlagKeyMaximumLength ,
  storageDelimiter ,
  storagePrefix ,
  displayFeatureFlags ,
  FeatureFlagsComparable ,
  FeatureFlagsMap ,
  unsafe_make ,
}
/* FeatureFlagsComparable Not a pure module */
