// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as MarkotApi__Client from "../../../api-adapter-markot/src/MarkotApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as GraphqlCore__ParserUtils from "../../../graphql-core/src/GraphqlCore__ParserUtils.bs.js";
import * as GqlMiddleware__Core__Infra from "./GqlMiddleware__Core__Infra.bs.js";

function markotApiResolverProxy(_obj, _args, context, info) {
  var op = GraphqlCore__ParserUtils.generateProxyDocumentText(info, undefined, undefined);
  return Curry._2(MarkotApi__Client.rawQuery(context.markotApiConfig)(op), info.variableValues, undefined).then(function (rawResult) {
              return rawResult[info.fieldName];
            });
}

function markotApiResolverProxyForViewer(obj, _args, context, info) {
  RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "markotApiResolverProxyForViewer operation", info.operation.name.value);
  RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "markotApiResolverProxyForViewer fieldName", info.fieldName);
  RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "markotApiResolverProxyForViewer obj", obj);
  var op = GraphqlCore__ParserUtils.generateProxyDocumentText(info, (function (selectionSet) {
          return " viewer { " + selectionSet + " }";
        }), undefined);
  return Curry._2(MarkotApi__Client.rawQuery(context.markotApiConfig)(op), info.variableValues, undefined).then(function (rawResult) {
              RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "markotApiResolverProxyForViewer received", rawResult);
              return rawResult["viewer"][info.fieldName];
            });
}

function markotApiResolverProxyForAuction(obj, _args, context, info) {
  RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "markotApiResolverProxyForAuction operation", info.operation.name.value);
  RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "markotApiResolverProxyForAuction info", info);
  RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "markotApiResolverProxyForAuction obj", obj);
  var auctionNodeId = obj.id;
  var op = GraphqlCore__ParserUtils.generateProxyDocumentText(info, (function (selectionSet) {
          return "\n    node(id: \"" + auctionNodeId + "\") {\n      ... on Auction {\n        " + selectionSet + "\n      }\n    }";
        }), undefined);
  return Curry._2(MarkotApi__Client.rawQuery(context.markotApiConfig)(op), info.variableValues, undefined).then(function (rawResult) {
              RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "markotApiResolverProxyForAuction received", rawResult);
              return rawResult["node"][info.fieldName];
            });
}

function markotApiResolverProxyForDraftListing(obj, _args, context, info) {
  RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "markotApiResolverProxyForDraftListing operation", info.operation.name.value);
  RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "markotApiResolverProxyForDraftListing info", info);
  RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "markotApiResolverProxyForDraftListing obj", obj);
  var draftListingNodeId = obj.id;
  var op = GraphqlCore__ParserUtils.generateProxyDocumentText(info, (function (selectionSet) {
          return "\n    node(id: \"" + draftListingNodeId + "\") {\n      ... on DraftListing {\n        " + selectionSet + "\n      }\n    }";
        }), undefined);
  return Curry._2(MarkotApi__Client.rawQuery(context.markotApiConfig)(op), info.variableValues, undefined).then(function (rawResult) {
              RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "markotApiResolverProxyForDraftListing received", rawResult);
              return rawResult["node"][info.fieldName];
            });
}

export {
  markotApiResolverProxy ,
  markotApiResolverProxyForViewer ,
  markotApiResolverProxyForAuction ,
  markotApiResolverProxyForDraftListing ,
}
/* MarkotApi__Client Not a pure module */
