// Generated by ReScript, PLEASE EDIT WITH CARE

import * as AuthContextFactory$DecidrIoAuthHooks from "@decidr-io/auth-hooks/src/AuthContextFactory.bs.js";

var Context = AuthContextFactory$DecidrIoAuthHooks.Make({});

var AuthProvider = Context.AuthProvider.make;

var AuthConsumer = Context.AuthConsumer.make;

var useAuth = Context.useAuth;

export {
  Context ,
  useAuth ,
  AuthProvider ,
  AuthConsumer ,
}
/* Context Not a pure module */
