// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Ui__Route from "./Ui__Route.bs.js";
import * as ReactNative from "react-native";
import * as Ui__Expo__WebBrowser from "../expo/webBrowser/Ui__Expo__WebBrowser.bs.js";
import * as Ignored$DecidrIoUtils from "@decidr-io/utils/src/functions/Ignored.bs.js";

function openLink(route, baseUrl) {
  var tmp;
  tmp = typeof route === "number" ? baseUrl + Ui__Route.toUrl(route) : (
      route.TAG === /* ExternalRoute */12 ? route.url : baseUrl + Ui__Route.toUrl(route)
    );
  return Ui__Expo__WebBrowser.openBrowserAsync(tmp, undefined, undefined);
}

function handle(route, baseUrl) {
  if (ReactNative.Platform.OS === "ios" || ReactNative.Platform.OS === "android") {
    return {
            NAME: "Native",
            VAL: (function (param) {
                Ignored$DecidrIoUtils.promiseWithPayload(openLink(route, baseUrl));
              })
          };
  } else if (ReactNative.Platform.OS === "web") {
    return {
            NAME: "Web",
            VAL: route
          };
  } else {
    return {
            NAME: "Native",
            VAL: (function (param) {
                console.log("Unsupported platform");
              })
          };
  }
}

export {
  openLink ,
  handle ,
}
/* Ui__Route Not a pure module */
