// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as ShopifyApi__Id from "../../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as ShopifyApi__ProductVariants from "../../../api-adapter-shopify/src/ShopifyApi__ProductVariants.bs.js";
import * as GqlMiddleware__Entity__Product from "./GqlMiddleware__Entity__Product.bs.js";
import * as GqlMiddleware__Core__FromShopify from "../core/GqlMiddleware__Core__FromShopify.bs.js";

function makeProductVariantId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "ProductVariant", param);
}

function makeVariant(param) {
  var productId = param.productId;
  RescriptUtils__Logger.info2(GqlMiddleware__Core__Infra.logger, "ProductVariant.productId", productId);
  return {
          __typename: "ProductVariant",
          id: GqlMiddleware__Core__NodeId.make(1, "ProductVariant", ShopifyApi__Id.toString(param.id)),
          compareAtPrice: Belt_Option.map(param.compareAtPrice, GqlMiddleware__Core__FromShopify.money),
          price: GqlMiddleware__Core__FromShopify.money(param.price),
          attributeValues: Belt_Array.map(param.attributeValues, (function (param) {
                  return {
                          id: param.id,
                          image: Belt_Option.flatMap(param.image, GqlMiddleware__Core__FromShopify.tryMakeImageResource),
                          value: param.value,
                          attributeId: GqlMiddleware__Core__NodeId.make(1, "Attribute", ShopifyApi__Id.toString(param.attributeId))
                        };
                })),
          availableForSale: param.availableForSale,
          inStock: param.inStock,
          mainImage: Belt_Option.flatMap(param.mainImage, GqlMiddleware__Core__FromShopify.tryMakeImageResource),
          media: Belt_Array.keepMap(param.media, GqlMiddleware__Core__FromShopify.tryMakeProductMedia),
          productId: GqlMiddleware__Core__NodeId.make(1, "Product", ShopifyApi__Id.toString(productId)),
          sku: param.sku,
          inWishlist: undefined,
          quantityAvailable: param.quantityAvailable
        };
}

function fetchProductDefaultVariant(apiConfig, productId) {
  return ShopifyApi__ProductVariants.fetchDefaultVariant(apiConfig, ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(productId).internalId)).then(function (__x) {
              return Belt_Option.map(__x, makeVariant);
            });
}

function fetchDefaultVariantByProductIds(apiConfig, productIds) {
  return ShopifyApi__ProductVariants.fetchDefaultVariantByProductIds(apiConfig, Belt_Array.map(productIds, (function (id) {
                      return ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(id).internalId);
                    }))).then(function (__x) {
              return Belt_Array.map(__x, (function (__x) {
                            return Belt_Option.map(__x, makeVariant);
                          }));
            });
}

function fetchProductVariant(apiConfig, id) {
  return ShopifyApi__ProductVariants.fetchVariant(apiConfig, ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(id).internalId)).then(function (__x) {
              return Belt_Option.map(__x, makeVariant);
            });
}

function fetchProductVariantsByIds(apiConfig, ids) {
  return ShopifyApi__ProductVariants.fetchVariantsByIds(apiConfig, Belt_Array.map(ids, (function (id) {
                      return ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(id).internalId);
                    }))).then(function (__x) {
              return Belt_Array.map(__x, (function (__x) {
                            return Belt_Option.map(__x, makeVariant);
                          }));
            });
}

function fetchProductVariantByProductIdAndAttributeValueIds(apiConfig, id, attributeValueIds) {
  return GqlMiddleware__Entity__Product.fetchProductVariantsByProductId(apiConfig, id).then(function (productVariants) {
                var __x = Belt_Option.getWithDefault(Belt_Option.map(RescriptUtils__OptionPlus.tapNone(productVariants, (function (param) {
                                RescriptUtils__Logger.warn2(GqlMiddleware__Core__Infra.logger, "fetchProductVariantsByProductId not found. Id:", id);
                              })), (function (productVariants) {
                            return Belt_Array.map(Belt_Array.map(attributeValueIds, (function (attributeValueId) {
                                              var match = attributeValueId.split("_");
                                              if (match.length !== 2) {
                                                return Js_exn.raiseError("Invalid attribute value ID: " + attributeValueId);
                                              }
                                              var optionId = match[0];
                                              var value = match[1];
                                              return {
                                                      value: value,
                                                      name: Belt_MapString.getExn(productVariants.productOptionNamesByIds, optionId)
                                                    };
                                            })), (function (selectedOption) {
                                          return {
                                                  name: selectedOption.name,
                                                  value: selectedOption.value
                                                };
                                        }));
                          })), []);
                return ShopifyApi__ProductVariants.fetchProductVariantBySelectedOptions(apiConfig, ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(id).internalId), __x);
              }).then(function (__x) {
              return Belt_Option.map(__x, makeVariant);
            });
}

export {
  makeProductVariantId ,
  makeVariant ,
  fetchProductDefaultVariant ,
  fetchDefaultVariantByProductIds ,
  fetchProductVariant ,
  fetchProductVariantsByIds ,
  fetchProductVariantByProductIdAndAttributeValueIds ,
}
/* ShopifyApi__Id Not a pure module */
