// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "../../../api-adapter-markot/src/MarkotApi__Id.bs.js";
import * as GqlMiddleware__Entity__BidInfo from "./GqlMiddleware__Entity__BidInfo.bs.js";
import * as GqlMiddleware__Entity__AuctionCommons from "./GqlMiddleware__Entity__AuctionCommons.bs.js";
import * as GqlMiddleware__Entity__AuctionPricing from "./GqlMiddleware__Entity__AuctionPricing.bs.js";

function makePricingOption(pricing) {
  if (pricing.TAG === /* SellUsingFixedPrice */0) {
    var price = pricing.price;
    return {
            TAG: /* SellUsingFixedPrice */0,
            __typename: "SellUsingFixedPrice",
            price: {
              amount: price.amount,
              currency: price.currency
            }
          };
  }
  var match = pricing.auction;
  return {
          TAG: /* SellUsingAuction */1,
          __typename: "SellUsingAuction",
          auctionId: pricing.auctionId,
          auction: {
            __typename: "Auction",
            id: GqlMiddleware__Entity__AuctionCommons.makeAuctionId(MarkotApi__Id.unpackExn(match.id).internalId),
            pricing: GqlMiddleware__Entity__AuctionPricing.makeAuctionPricing(match.pricing),
            settings: {
              __typename: "AuctionSettings",
              endsAt: match.settings.endsAt
            },
            totalBids: match.totalBids,
            highestBidInfo: Belt_Option.map(match.highestBidInfo, GqlMiddleware__Entity__BidInfo.makeBidInfo),
            latestBidAt: match.latestBidAt,
            viewerLastActiveBidInfo: Belt_Option.map(match.viewerLastActiveBidInfo, GqlMiddleware__Entity__BidInfo.makeBidInfo)
          }
        };
}

export {
  makePricingOption ,
}
/* MarkotApi__Id Not a pure module */
