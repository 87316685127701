/* TypeScript file generated from FeatureFlags__LocalFeatureFlags.resi by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as Curry__Es6Import from 'rescript/lib/es6/curry.js';
const Curry: any = Curry__Es6Import;

// @ts-ignore: Implicit any on import
import * as FeatureFlags__LocalFeatureFlagsBS__Es6Import from './FeatureFlags__LocalFeatureFlags.bs';
const FeatureFlags__LocalFeatureFlagsBS: any = FeatureFlags__LocalFeatureFlagsBS__Es6Import;

import type {FeatureFlagsMap_t as FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t} from './FeatureFlags__AppSpecificConfig.gen';

import type {status as FeatureFlags__AppSpecificConfig_status} from './FeatureFlags__AppSpecificConfig.gen';

import type {t as FeatureFlags__GeneratedTypes_t} from './FeatureFlags__GeneratedTypes.gen';

export const getFeatureFlagStatus: (_1:FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t, _2:{ readonly featureFlag: FeatureFlags__GeneratedTypes_t }) => FeatureFlags__AppSpecificConfig_status = function (Arg1: any, Arg2: any) {
  const result = Curry._2(FeatureFlags__LocalFeatureFlagsBS.getFeatureFlagStatus, Arg1, Arg2.featureFlag);
  return result
};

/** \n * Use to get the current status of the given feature flag\n *\n * // #on or #off\n * let placeholder = FeatureFlags.LocalFeatureFlags.use(#placeholder)\n  */
export const use: (_1:FeatureFlags__GeneratedTypes_t) => FeatureFlags__AppSpecificConfig_status = FeatureFlags__LocalFeatureFlagsBS.use;

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly updateFeatureFlags: (_1:((_1:FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t) => FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t)) => void; 
  readonly children: React.ReactNode; 
  readonly featureFlags: FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t
};

export const FeatureFlagsProvider_make: React.ComponentType<{
  readonly updateFeatureFlags: (_1:((_1:FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t) => FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t)) => void; 
  readonly children: React.ReactNode; 
  readonly featureFlags: FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t
}> = FeatureFlags__LocalFeatureFlagsBS.FeatureFlagsProvider.make;

export const FeatureFlagsProvider: { make: React.ComponentType<{
  readonly updateFeatureFlags: (_1:((_1:FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t) => FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t)) => void; 
  readonly children: React.ReactNode; 
  readonly featureFlags: FeatureFlags__AppSpecificConfig_FeatureFlagsMap_t
}> } = FeatureFlags__LocalFeatureFlagsBS.FeatureFlagsProvider
