/* TypeScript file generated from Ui__MarkotButton.resi by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as Ui__MarkotButtonBS__Es6Import from './Ui__MarkotButton.bs';
const Ui__MarkotButtonBS: any = Ui__MarkotButtonBS__Es6Import;

import type {Style_t as ReactNative_Style_t} from '../../../../../rescript-shims/ReactNative.shim';

// tslint:disable-next-line:interface-over-type-literal
export type kind = "primary" | "secondary" | "secondaryOutline";

// tslint:disable-next-line:interface-over-type-literal
export type size = "large" | "small";

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly right?: JSX.Element; 
  readonly textStyles?: ReactNative_Style_t; 
  readonly disabled?: boolean; 
  readonly containerStyle?: ReactNative_Style_t; 
  readonly testOnly_pressed?: boolean; 
  readonly testID?: string; 
  readonly onPress?: () => void; 
  readonly title: string; 
  readonly busy?: boolean; 
  readonly left?: JSX.Element; 
  readonly kind: kind; 
  readonly size?: size; 
  readonly pressedColor?: string
};

export const make: React.ComponentType<{
  readonly right?: JSX.Element; 
  readonly textStyles?: ReactNative_Style_t; 
  readonly disabled?: boolean; 
  readonly containerStyle?: ReactNative_Style_t; 
  readonly testOnly_pressed?: boolean; 
  readonly testID?: string; 
  readonly onPress?: () => void; 
  readonly title: string; 
  readonly busy?: boolean; 
  readonly left?: JSX.Element; 
  readonly kind: kind; 
  readonly size?: size; 
  readonly pressedColor?: string
}> = Ui__MarkotButtonBS.make;
