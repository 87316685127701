// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ShopifyApi__Id from "../../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as ShopifyApi__Influencers from "../../../api-adapter-shopify/src/ShopifyApi__Influencers.bs.js";
import * as RescriptUtils__OptionPlus from "../../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";

function makeInfluencerId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "Influencer", param);
}

function makeInfluencer(param) {
  return {
          __typename: "Influencer",
          id: GqlMiddleware__Core__NodeId.make(1, "Influencer", ShopifyApi__Id.toString(param.id)),
          fullName: param.fullName,
          nickname: param.nickname,
          intro: param.intro,
          blogPostTag: param.blogPostTag,
          seo: Belt_Option.map(param.seo, (function (param) {
                  return {
                          description: param.description,
                          title: param.title
                        };
                }))
        };
}

function fetchInfluencer(apiConfig, id) {
  return ShopifyApi__Influencers.$$fetch(apiConfig, id).then(function (influencer) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(influencer, (function (param) {
                                RescriptUtils__Logger.warn2(GqlMiddleware__Core__Infra.logger, "Influencer not found. Id:", id);
                              })), makeInfluencer);
            });
}

export {
  makeInfluencerId ,
  makeInfluencer ,
  fetchInfluencer ,
}
/* ShopifyApi__Id Not a pure module */
