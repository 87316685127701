// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as App__Report from "../utils/App__Report.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";

function use(Query, variables, context) {
  var match = React.useState(function () {
        return false;
      });
  var setRefreshing = match[1];
  var refreshing = match[0];
  var environment = RescriptRelay.useEnvironmentFromContext(undefined);
  var refresh = React.useCallback((function (param) {
          if (!refreshing) {
            Curry._1(setRefreshing, (function (param) {
                    return true;
                  }));
            return Curry._6(Query.$$fetch, environment, variables, (function (res) {
                          if (res.TAG !== /* Ok */0) {
                            App__Report.unexpected(Belt_Option.getWithDefault(JSON.stringify(res._0), "An error occurred"), "" + context + " refresh data");
                          }
                          Curry._1(setRefreshing, (function (param) {
                                  return false;
                                }));
                        }), undefined, /* NetworkOnly */0, undefined);
          }
          
        }), [
        variables,
        environment
      ]);
  return [
          refreshing,
          refresh
        ];
}

export {
  use ,
}
/* react Not a pure module */
