// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils__WebUtils from "../../../utils/src/Utils__WebUtils.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";

function transformText(text) {
  return text.trim().split("").map(function (x) {
                  if (x === " ") {
                    return "-";
                  } else {
                    return x;
                  }
                }).toString().replace(/,/g, "");
}

function toUrl(route) {
  if (typeof route === "number") {
    switch (route) {
      case /* NotFound */0 :
          return "/not-found";
      case /* Login */1 :
          return "/login";
      case /* AccountInfo */2 :
          return "/account-info";
      case /* CreateNewPassword */3 :
          return "/create-new-password";
      case /* ChangePassword */4 :
          return "/change-password";
      case /* Browse */7 :
          return "/browse";
      case /* Bids */8 :
          return "/bids";
      case /* PastBids */9 :
          return "/past-bids";
      case /* Wishlist */10 :
          return "/wishlist";
      case /* Lifecircle */12 :
          return "/lifecircle";
      case /* Bag */13 :
          return "/bag";
      case /* Bundles */14 :
          return "/bundles";
      case /* Brands */15 :
          return "/brands";
      case /* Sell */11 :
      case /* CurrentListings */16 :
          return "/sell";
      case /* Register */17 :
          return "/register";
      case /* SellerRatingFees */18 :
          return "/seller-rating-fees";
      case /* Wallet */19 :
          return "/wallet";
      case /* User */20 :
          return "/user";
      case /* Home */5 :
      case /* Discover */6 :
      case /* HandledInternally */21 :
          return "/";
      case /* PaymentAndAddressSettings */22 :
          return "/payment-and-address-settings";
      case /* NotificationPreferences */23 :
          return "/notification-preferences";
      case /* Contact */24 :
          return "/contact";
      case /* SearchPreloved */25 :
          return "/preloved";
      case /* Guides */26 :
          return "/guides";
      case /* Help */27 :
          return "/help";
      case /* Terms */28 :
          return "/terms";
      case /* WithdrawViaBankAccount */29 :
          return "withdraw-via-bank-account";
      case /* Competition */30 :
          return "/competition";
      
    }
  } else {
    switch (route.TAG | 0) {
      case /* ForgotPassword */0 :
          var email = route.email;
          if (email !== undefined) {
            return "/forgot-password?email=" + email + "";
          } else {
            return "/forgot-password";
          }
      case /* Category */1 :
          return "/browse/" + route.slug + "?id=" + route.id + "";
      case /* MyListing */2 :
          return "/my-listing?id=" + route.listingId + "&itemSlug=" + route.itemSlug + "";
      case /* Product */3 :
          return "/" + route.slug + "?id=" + route.productId + "" + Belt_Option.getWithDefault(Belt_Option.map(route.variantId, (function (variantId) {
                            return "&v=" + variantId + "";
                          })), "") + "";
      case /* PrelovedItem */4 :
          return "/preloved/" + route.slug + "?id=" + route.prelovedId + "";
      case /* ProductLivePrelovedItems */5 :
          return "/preloved/" + route.slug + "/live?id=" + route.productId + "";
      case /* ProductSoldPrelovedItems */6 :
          return "/preloved/" + route.slug + "/sold?id=" + route.productId + "";
      case /* Bundle */7 :
          return "/bundle/" + route.slug + "?id=" + route.bundleId + "";
      case /* CreateListingFlow */8 :
          var match = route.flow;
          if (match !== undefined) {
            if (match.TAG === /* DraftListing */0) {
              return "/draft-listing?id=" + match.draftListingId + "" + Belt_Option.getWithDefault(Belt_Option.map(match.step, (function (step) {
                                return "&step=" + step + "";
                              })), "") + "";
            } else {
              return "/create-listing?productId=" + match.productId + "";
            }
          } else {
            return "/create-listing";
          }
      case /* ListingDashboard */9 :
          return "/listing?id=" + route.id + "";
      case /* SubcategoryProducts */11 :
          var searchTerm = route.searchTerm;
          var searchTerm$1 = searchTerm !== undefined && searchTerm !== "" ? "&term=" + searchTerm + "" : "";
          return "/subcategory/" + route.subcategorySlug + "?id=" + route.subcategoryId + "" + searchTerm$1 + "";
      case /* ExternalRoute */12 :
          return route.url;
      case /* Brand */13 :
          var searchTerm$2 = route.searchTerm;
          var searchTerm$3 = searchTerm$2 !== undefined && searchTerm$2 !== "" ? "&term=" + searchTerm$2 + "" : "";
          return "/brand/" + route.brandSlug + "?brandId=" + route.brandId + "" + searchTerm$3 + "";
      case /* PrelovedCheckout */14 :
          return "/preloved-checkout?id=" + route.prelovedItemInfoId + "";
      case /* PrelovedOrder */15 :
          return "/preloved-order?id=" + route.prelovedOrderId + "";
      case /* NewOrder */16 :
          return "/new-order?id=" + route.newOrderId + "";
      case /* Orders */17 :
          return "/orders" + Belt_Option.getWithDefault(Belt_Option.map(route.checkout, (function (checkout) {
                            return "?checkout=" + checkout + "";
                          })), "") + "";
      case /* Search */18 :
          var productType = route.productType;
          var category = route.category;
          var brand = route.brand;
          var sellingNewItems = route.sellingNewItems;
          var searchTerm$4 = route.searchTerm;
          var base = "/search";
          var searchTermQueryParam = searchTerm$4 !== undefined && searchTerm$4 !== "" ? "term=" + searchTerm$4 + "" : undefined;
          var sellingNewItemsQueryParam = sellingNewItems !== undefined && sellingNewItems ? "filter=selling-new" : undefined;
          var brandQueryParam = brand !== undefined && brand !== "" ? "brand=" + brand + "" : undefined;
          var categoryQueryParam = category !== undefined && category !== "" ? "category=" + category + "" : undefined;
          var productTypeQueryParam = productType !== undefined && productType !== "" ? "productType=" + productType + "" : undefined;
          var queryParams = Belt_Array.keepMap([
                searchTermQueryParam,
                sellingNewItemsQueryParam,
                brandQueryParam,
                categoryQueryParam,
                productTypeQueryParam
              ], (function (x) {
                  return x;
                }));
          if (queryParams.length === 0) {
            return base;
          } else {
            return "" + base + "?" + queryParams.join("&");
          }
      case /* ShopifyCheckout */10 :
      case /* FullScreenImageViewer */19 :
          return "/";
      
    }
  }
}

function extractOneLevelDeepSlug(pathname) {
  return pathname.slice(pathname.lastIndexOf("/")).slice(1);
}

function fromUrl(url, isInternalRoute) {
  var pathname = Curry._1(Utils__WebUtils.Url.getPathname, Curry._1(Utils__WebUtils.Url.make, Curry._1(Utils__WebUtils.Url.asString, url)));
  var searchParams = Curry._1(Utils__WebUtils.Url.getSearchParams, Curry._1(Utils__WebUtils.Url.make, Curry._1(Utils__WebUtils.Url.asString, url)));
  if (!isInternalRoute) {
    return {
            TAG: /* ExternalRoute */12,
            url: url,
            target: undefined
          };
  }
  switch (pathname) {
    case "/" :
        return /* Discover */6;
    case "/account-info" :
        return /* AccountInfo */2;
    case "/bag" :
        return /* Bag */13;
    case "/bids" :
        return /* Bids */8;
    case "/bundles" :
        return /* Bundles */14;
    case "/change-password" :
        return /* ChangePassword */4;
    case "/contact" :
        return /* Contact */24;
    case "/create-listing" :
        var productId = Utils__WebUtils.$$URLSearchParams.get(searchParams, "productId");
        if (productId !== undefined) {
          return {
                  TAG: /* CreateListingFlow */8,
                  flow: {
                    TAG: /* PreselectedProduct */1,
                    productId: Curry._1(Utils__WebUtils.QueryParams.getString, Caml_option.valFromOption(productId))
                  }
                };
        } else {
          return {
                  TAG: /* CreateListingFlow */8,
                  flow: undefined
                };
        }
    case "/create-new-password" :
        return /* CreateNewPassword */3;
    case "/draft-listing" :
        var draftListingIdQueryParam = Utils__WebUtils.$$URLSearchParams.get(searchParams, "id");
        if (draftListingIdQueryParam === undefined) {
          return /* NotFound */0;
        }
        var draftListingIdQueryParam$1 = Caml_option.valFromOption(draftListingIdQueryParam);
        if (!Utils__WebUtils.QueryParams.isNull(draftListingIdQueryParam$1)) {
          return {
                  TAG: /* CreateListingFlow */8,
                  flow: {
                    TAG: /* DraftListing */0,
                    draftListingId: Curry._1(Utils__WebUtils.QueryParams.getString, draftListingIdQueryParam$1),
                    step: undefined
                  }
                };
        } else {
          return /* NotFound */0;
        }
    case "/forgot-password" :
        var emailQueryParam = Utils__WebUtils.$$URLSearchParams.get(searchParams, "email");
        if (emailQueryParam === undefined) {
          return {
                  TAG: /* ForgotPassword */0,
                  email: undefined
                };
        }
        var emailQueryParam$1 = Caml_option.valFromOption(emailQueryParam);
        return {
                TAG: /* ForgotPassword */0,
                email: Bool$DecidrIoUtils.thenSome(!Utils__WebUtils.QueryParams.isNull(emailQueryParam$1), Curry._1(Utils__WebUtils.QueryParams.getString, emailQueryParam$1))
              };
    case "/lifecircle" :
        return /* Lifecircle */12;
    case "/listing" :
        var listingIdQueryParam = Utils__WebUtils.$$URLSearchParams.get(searchParams, "id");
        if (listingIdQueryParam === undefined) {
          return {
                  TAG: /* CreateListingFlow */8,
                  flow: undefined
                };
        }
        var listingIdQueryParam$1 = Caml_option.valFromOption(listingIdQueryParam);
        if (!Utils__WebUtils.QueryParams.isNull(listingIdQueryParam$1)) {
          return {
                  TAG: /* ListingDashboard */9,
                  id: Curry._1(Utils__WebUtils.QueryParams.getString, listingIdQueryParam$1)
                };
        } else {
          return /* NotFound */0;
        }
    case "/login" :
        return /* Login */1;
    case "/orders" :
        var checkoutQueryParam = Utils__WebUtils.$$URLSearchParams.get(searchParams, "checkout");
        if (checkoutQueryParam === undefined) {
          return {
                  TAG: /* Orders */17,
                  checkout: undefined
                };
        }
        var checkoutQueryParam$1 = Caml_option.valFromOption(checkoutQueryParam);
        return {
                TAG: /* Orders */17,
                checkout: Bool$DecidrIoUtils.thenSome(!Utils__WebUtils.QueryParams.isNull(checkoutQueryParam$1), Curry._1(Utils__WebUtils.QueryParams.getString, checkoutQueryParam$1))
              };
    case "/past-bids" :
        return /* PastBids */9;
    case "/payment-and-address-settings" :
        return /* PaymentAndAddressSettings */22;
    case "/preloved" :
        return /* SearchPreloved */25;
    case "/register" :
        return /* Register */17;
    case "/sell" :
        return /* Sell */11;
    case "/user" :
        return /* User */20;
    case "/wallet" :
        return /* Wallet */19;
    case "/wishlist" :
        return /* Wishlist */10;
    default:
      if (pathname.includes("/bundle")) {
        var bundleIdQueryParam = Utils__WebUtils.$$URLSearchParams.get(searchParams, "id");
        if (bundleIdQueryParam === undefined) {
          return /* NotFound */0;
        }
        var bundleIdQueryParam$1 = Caml_option.valFromOption(bundleIdQueryParam);
        if (Utils__WebUtils.QueryParams.isNull(bundleIdQueryParam$1)) {
          return /* NotFound */0;
        } else {
          return {
                  TAG: /* Bundle */7,
                  slug: extractOneLevelDeepSlug(pathname),
                  bundleId: Curry._1(Utils__WebUtils.QueryParams.getString, bundleIdQueryParam$1)
                };
        }
      }
      if (pathname.includes("/subcategory")) {
        var subcategoryIdQueryParam = Utils__WebUtils.$$URLSearchParams.get(searchParams, "id");
        if (subcategoryIdQueryParam === undefined) {
          return /* NotFound */0;
        }
        var subcategoryIdQueryParam$1 = Caml_option.valFromOption(subcategoryIdQueryParam);
        var slug = extractOneLevelDeepSlug(pathname);
        if (Utils__WebUtils.QueryParams.isNull(subcategoryIdQueryParam$1)) {
          return /* NotFound */0;
        }
        var searchTerm = Utils__WebUtils.$$URLSearchParams.get(searchParams, "term");
        var tmp;
        if (searchTerm !== undefined) {
          var searchTerm$1 = Caml_option.valFromOption(searchTerm);
          tmp = Utils__WebUtils.QueryParams.isNull(searchTerm$1) ? undefined : Curry._1(Utils__WebUtils.QueryParams.getString, searchTerm$1);
        } else {
          tmp = undefined;
        }
        return {
                TAG: /* SubcategoryProducts */11,
                subcategorySlug: slug,
                subcategoryId: Curry._1(Utils__WebUtils.QueryParams.getString, subcategoryIdQueryParam$1),
                subcategoryName: slug.split("-").join(" "),
                searchTerm: tmp
              };
      }
      if (!pathname.includes("/browse")) {
        return /* NotFound */0;
      }
      var categoryIdQueryParam = Utils__WebUtils.$$URLSearchParams.get(searchParams, "id");
      if (categoryIdQueryParam === undefined) {
        return /* Browse */7;
      }
      var categoryIdQueryParam$1 = Caml_option.valFromOption(categoryIdQueryParam);
      var slug$1 = extractOneLevelDeepSlug(pathname);
      if (Utils__WebUtils.QueryParams.isNull(categoryIdQueryParam$1)) {
        return /* Browse */7;
      } else {
        return {
                TAG: /* Category */1,
                id: Curry._1(Utils__WebUtils.QueryParams.getString, categoryIdQueryParam$1),
                slug: slug$1
              };
      }
  }
}

function toMobileNav(route) {
  if (typeof route === "number") {
    switch (route) {
      case /* Login */1 :
          return [
                  "Login",
                  undefined
                ];
      case /* AccountInfo */2 :
          return [
                  "AccountInfo",
                  undefined
                ];
      case /* CreateNewPassword */3 :
          return [
                  "CreateNewPassword",
                  undefined
                ];
      case /* ChangePassword */4 :
          return [
                  "ChangePassword",
                  undefined
                ];
      case /* Home */5 :
          return [
                  "Home",
                  {
                    screen: "Discover"
                  }
                ];
      case /* Discover */6 :
          return [
                  "Discover",
                  undefined
                ];
      case /* Browse */7 :
          return [
                  "Browse",
                  {
                    id: undefined,
                    slug: undefined
                  }
                ];
      case /* Bids */8 :
          return [
                  "Bids",
                  undefined
                ];
      case /* PastBids */9 :
          return [
                  "PastBids",
                  undefined
                ];
      case /* Wishlist */10 :
          return [
                  "Wishlist",
                  undefined
                ];
      case /* Bag */13 :
          return [
                  "Bag",
                  undefined
                ];
      case /* Bundles */14 :
          return [
                  "Bundles",
                  undefined
                ];
      case /* Brands */15 :
          return [
                  "Brands",
                  undefined
                ];
      case /* CurrentListings */16 :
          return [
                  "SellTabNav",
                  undefined
                ];
      case /* Register */17 :
          return [
                  "Register",
                  undefined
                ];
      case /* SellerRatingFees */18 :
          return [
                  "SellerRatingFees",
                  undefined
                ];
      case /* Wallet */19 :
          return [
                  "Wallet",
                  undefined
                ];
      case /* User */20 :
          return [
                  "User",
                  undefined
                ];
      case /* PaymentAndAddressSettings */22 :
          return [
                  "PaymentAndAddressSettings",
                  undefined
                ];
      case /* NotificationPreferences */23 :
          return [
                  "NotificationPreferences",
                  undefined
                ];
      case /* Contact */24 :
          return [
                  "Contact",
                  undefined
                ];
      case /* SearchPreloved */25 :
          return [
                  "SearchPreloved",
                  undefined
                ];
      case /* Guides */26 :
          return [
                  "Guides",
                  undefined
                ];
      case /* Help */27 :
          return [
                  "Help",
                  undefined
                ];
      case /* Terms */28 :
          return [
                  "Terms",
                  undefined
                ];
      case /* WithdrawViaBankAccount */29 :
          return [
                  "WithdrawViaBankAccount",
                  undefined
                ];
      default:
        return [
                "Home",
                {
                  screen: "Discover"
                }
              ];
    }
  } else {
    switch (route.TAG | 0) {
      case /* ForgotPassword */0 :
          var email = route.email;
          if (email !== undefined) {
            return [
                    "ForgotPassword",
                    {
                      email: email
                    }
                  ];
          } else {
            return [
                    "ForgotPassword",
                    undefined
                  ];
          }
      case /* Category */1 :
          return [
                  "Browse",
                  {
                    id: route.id,
                    slug: route.slug
                  }
                ];
      case /* MyListing */2 :
          return [
                  "MyListing",
                  {
                    id: route.listingId
                  }
                ];
      case /* Product */3 :
          return [
                  "Product",
                  {
                    id: route.productId,
                    v: route.variantId,
                    slug: route.slug
                  }
                ];
      case /* PrelovedItem */4 :
          return [
                  "Preloved",
                  {
                    id: route.prelovedId
                  }
                ];
      case /* ProductLivePrelovedItems */5 :
          return [
                  "ProductPrelovedItemsTabNav",
                  {
                    productId: route.productId
                  }
                ];
      case /* ProductSoldPrelovedItems */6 :
          return [
                  "ProductPrelovedItemsTabNav",
                  {
                    productId: route.productId,
                    screen: "ProductSoldPrelovedItems"
                  }
                ];
      case /* Bundle */7 :
          return [
                  "Bundle",
                  {
                    bundleId: route.bundleId
                  }
                ];
      case /* CreateListingFlow */8 :
          var match = route.flow;
          if (match === undefined) {
            return [
                    "ChooseProductForSellerFlow",
                    undefined
                  ];
          }
          if (match.TAG !== /* DraftListing */0) {
            return [
                    "ChooseProductForSellerFlow",
                    {
                      productId: match.productId
                    }
                  ];
          }
          var step = match.step;
          var screenName = step !== undefined ? (
              step === "price" ? "SetPriceForSellerFlow" : (
                  step === "description" ? "EditDescriptionForSellerFlow" : (
                      step === "ready" ? "SellerDashboard" : (
                          step === "product" ? "ChooseProductForSellerFlow" : (
                              step === "condition" ? "ChooseConditionForSellerFlow" : (
                                  step === "media" ? "ChoosePhotosForSellerFlow" : "ChooseFeeForSellerFlow"
                                )
                            )
                        )
                    )
                )
            ) : "ChooseProductForSellerFlow";
          return [
                  screenName,
                  {
                    draftListingId: match.draftListingId
                  }
                ];
      case /* ListingDashboard */9 :
          return [
                  "ListingDashboard",
                  {
                    id: route.id
                  }
                ];
      case /* ShopifyCheckout */10 :
          return [
                  "ShopifyCheckout",
                  {
                    url: route.url
                  }
                ];
      case /* SubcategoryProducts */11 :
          return [
                  "SubcategoryProducts",
                  {
                    subcategoryId: route.subcategoryId,
                    subcategorySlug: route.subcategorySlug,
                    subcategoryName: route.subcategoryName,
                    searchTerm: route.searchTerm
                  }
                ];
      case /* Brand */13 :
          return [
                  "Brand",
                  {
                    brandId: route.brandId,
                    brandSlug: route.brandSlug,
                    brandName: route.brandName,
                    searchTerm: route.searchTerm
                  }
                ];
      case /* PrelovedCheckout */14 :
          return [
                  "PrelovedCheckout",
                  {
                    id: route.prelovedItemInfoId
                  }
                ];
      case /* PrelovedOrder */15 :
          return [
                  "PrelovedOrder",
                  {
                    id: route.prelovedOrderId
                  }
                ];
      case /* NewOrder */16 :
          return [
                  "NewOrder",
                  {
                    id: route.newOrderId
                  }
                ];
      case /* Orders */17 :
          var checkout = route.checkout;
          if (checkout !== undefined) {
            return [
                    "OrdersTabNav",
                    {
                      checkout: checkout
                    }
                  ];
          } else {
            return [
                    "OrdersTabNav",
                    undefined
                  ];
          }
      case /* Search */18 :
          return [
                  "Search",
                  {
                    searchTerm: route.searchTerm,
                    loadDataInitially: route.loadDataInitially,
                    filter: Belt_Option.flatMap(route.sellingNewItems, (function (sellingNewItems) {
                            if (sellingNewItems) {
                              return "selling-new";
                            }
                            
                          })),
                    productType: route.productType
                  }
                ];
      case /* FullScreenImageViewer */19 :
          return [
                  "FullScreenImageViewer",
                  {
                    src: route.src,
                    alt: route.alt
                  }
                ];
      default:
        return [
                "Home",
                {
                  screen: "Discover"
                }
              ];
    }
  }
}

function unsafe_getRoute(t) {
  return t;
}

export {
  transformText ,
  toUrl ,
  fromUrl ,
  toMobileNav ,
  unsafe_getRoute ,
}
/* Utils__WebUtils Not a pure module */
