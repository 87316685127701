// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";

function makeAuctionId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "Auction", param);
}

export {
  makeAuctionId ,
}
/* GqlMiddleware__Core__NodeId Not a pure module */
