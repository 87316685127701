// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";

var Types = {};

var fragmentConverter = {"__root":{"":{"f":""}}};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppBagListMarkotUserFragment_markotUser",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppMarkotUserAddressPickerFragment_markotUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppAddAddressModal_markotUser"
    }
  ],
  "type": "MarkotUser",
  "abstractKey": null
};

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* RescriptRelay Not a pure module */
