// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

var deviceSizes = [
  320,
  480,
  640,
  750,
  828,
  1080,
  1200,
  1920,
  2048
];

var imageSizes = [
  16,
  32,
  48,
  64,
  96,
  128,
  256
];

function extractImageId(src) {
  var idStartIndex = src.lastIndexOf("/") + 1 | 0;
  return src.slice(idStartIndex);
}

function nextLoader(param) {
  return "https://cdn.filestackcontent.com/resize=width:" + String(param.width) + "/output=format:webp/" + extractImageId(param.src) + "";
}

function makeSourcesForNative(src, aspectRatio) {
  return Belt_Array.map(Belt_Array.concat(imageSizes, deviceSizes), (function (size) {
                return {
                        uri: "https://cdn.filestackcontent.com/resize=width:" + String(size) + "/" + extractImageId(src) + "",
                        width: size,
                        height: size / aspectRatio
                      };
              }));
}

var Filestack = {
  domain: "cdn.filestackcontent.com",
  extractImageId: extractImageId,
  nextLoader: nextLoader,
  makeSourcesForNative: makeSourcesForNative,
  unoptimized: false
};

var domain = "blog.markot.com";

function extractImageId$1(src) {
  var idStartIndex = src.indexOf(domain);
  var idEndIndex = src.lastIndexOf(".");
  return src.slice(idStartIndex, idEndIndex);
}

function extractImageSrc(src) {
  var idStartIndex = src.indexOf(domain);
  return src.slice(idStartIndex);
}

function nextLoader$1(param) {
  return "https://i0.wp.com/" + extractImageSrc(param.src) + "?w=" + String(param.width) + "";
}

function makeSourcesForNative$1(src, aspectRatio) {
  return Belt_Array.map(Belt_Array.concat(imageSizes, deviceSizes), (function (size) {
                return {
                        uri: "https://i0.wp.com/" + extractImageSrc(src) + "?w=" + String(size) + "",
                        width: size,
                        height: size / aspectRatio
                      };
              }));
}

function extractImageId$2(src) {
  var idStartIndex = src.lastIndexOf("/") + 1 | 0;
  return src.slice(idStartIndex);
}

function nextLoader$2(param) {
  return "" + param.src + "&width=" + String(param.width) + "";
}

function makeSourcesForNative$2(src, aspectRatio) {
  return Belt_Array.map(Belt_Array.concat(imageSizes, deviceSizes), (function (size) {
                return {
                        uri: "" + src + "&width=" + String(size) + "",
                        width: size,
                        height: size / aspectRatio
                      };
              }));
}

var Shopify = {
  domain: "cdn.shopify.com",
  extractImageId: extractImageId$2,
  nextLoader: nextLoader$2,
  makeSourcesForNative: makeSourcesForNative$2,
  unoptimized: false
};

var WordPress = {
  domain: domain,
  extractImageId: extractImageId$1,
  nextLoader: nextLoader$1,
  makeSourcesForNative: makeSourcesForNative$1,
  unoptimized: false
};

export {
  deviceSizes ,
  imageSizes ,
  Filestack ,
  WordPress ,
  Shopify ,
}
/* No side effect */
