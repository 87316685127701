// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ExpoClipboard from "expo-clipboard";

function setStringAsync(prim0, prim1, prim2) {
  return ExpoClipboard.setStringAsync(prim0, prim1 !== undefined ? Caml_option.valFromOption(prim1) : undefined);
}

export {
  setStringAsync ,
}
/* expo-clipboard Not a pure module */
