// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ExpoWebBrowser from "expo-web-browser";

function openBrowserAsync(prim0, prim1, prim2) {
  return ExpoWebBrowser.openBrowserAsync(prim0, prim1 !== undefined ? Caml_option.valFromOption(prim1) : undefined);
}

export {
  openBrowserAsync ,
}
/* expo-web-browser Not a pure module */
