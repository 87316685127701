// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RelayRuntime from "relay-runtime";
import * as App__MarkotUser__AddressPickerFragment from "../markotUser/addressPicker/App__MarkotUser__AddressPickerFragment.bs.js";

function collectAllRelayConnections(viewerDataId) {
  return [RelayRuntime.ConnectionHandler.getConnectionID(viewerDataId, App__MarkotUser__AddressPickerFragment.Operation.connectionKey, undefined)];
}

var defaultBillingAddressIdCacheField = "defaultBillingAddressId";

var defaultBillingAddressCacheField = "defaultBillingAddress";

var defaultShippingAddressIdCacheField = "defaultShippingAddressId";

var defaultShippingAddressCacheField = "defaultShippingAddress";

export {
  collectAllRelayConnections ,
  defaultBillingAddressIdCacheField ,
  defaultBillingAddressCacheField ,
  defaultShippingAddressIdCacheField ,
  defaultShippingAddressCacheField ,
}
/* relay-runtime Not a pure module */
