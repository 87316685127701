// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Card from "../../../card/Ui__Card.bs.js";
import * as Ui__Portal from "../../../portal/Ui__Portal.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotIcon from "../../../icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__MarkotText from "../../../text/components/Ui__MarkotText.bs.js";
import * as Ui__SlideInRightView from "./Ui__SlideInRightView.bs.js";
import * as Ignored$DecidrIoUtils from "@decidr-io/utils/src/functions/Ignored.bs.js";
import * as RnUtils$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/utils/RnUtils.bs.js";

var match = Ui__Styling.describe({
      wrapper: {
        TAG: /* Static */0,
        _0: {
          bottom: 10,
          maxWidth: 500,
          position: "absolute",
          right: 10,
          width: Ui__Styling.pct(80)
        }
      },
      container: {
        TAG: /* Static */0,
        _0: {
          justifyContent: "space-between"
        }
      },
      statusSection: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        }
      },
      button: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center",
          flex: 1,
          justifyContent: "center"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__Toast__Error(Props) {
  var title = Props.title;
  var description = Props.description;
  var onDismiss = Props.onDismiss;
  var wrapperStyle = Props.wrapperStyle;
  var autoDismissOpt = Props.autoDismiss;
  var autoDismiss = autoDismissOpt !== undefined ? autoDismissOpt : false;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, undefined);
  React.useEffect((function () {
          ReactNative.Keyboard.dismiss();
        }), []);
  React.useEffect((function () {
          if (autoDismiss) {
            Ignored$DecidrIoUtils.unsafe(setTimeout((function (param) {
                        Curry._1(onDismiss, undefined);
                      }), 5000));
          }
          
        }), [autoDismiss]);
  return React.createElement(Ui__Portal.make, {
              children: React.createElement(Ui__SlideInRightView.make, {
                    children: React.createElement(Ui__Card.make, {
                          children: null,
                          orientation: "horizontal",
                          spacing: theme.spacing._4,
                          paddingHorizontal: theme.spacing._6,
                          paddingVertical: theme.spacing._6,
                          backgroundColor: theme.colors.primary,
                          style: Curry._1(styled, styles.container),
                          testID: "error-toast"
                        }, React.createElement(Ui__Card.Section.make, {
                              children: null,
                              style: {
                                flex: 1
                              }
                            }, React.createElement(Ui__MarkotText.make, {
                                  size: "caption1",
                                  color: theme.colors.onPrimary,
                                  children: title
                                }), React.createElement(Ui__MarkotText.make, {
                                  size: "caption2",
                                  color: theme.colors.onPrimaryShade2,
                                  children: description
                                })), React.createElement(ReactNative.View, {
                              style: Curry._1(styled, styles.statusSection),
                              children: React.createElement(ReactNative.TouchableOpacity, {
                                    style: Curry._1(styled, styles.button),
                                    hitSlop: RnUtils$DecidrIoUniversalComponents.makeSymmetricHitSlop(theme.spacing._6),
                                    onPress: (function (param) {
                                        Curry._1(onDismiss, undefined);
                                      }),
                                    children: React.createElement(Ui__MarkotIcon.make, {
                                          name: "x-mark",
                                          size: 14,
                                          color: theme.colors.onPrimary
                                        })
                                  })
                            })),
                    style: [
                      Caml_option.some(Curry._1(styled, styles.wrapper)),
                      wrapperStyle
                    ]
                  }),
              hostName: "Toast"
            });
}

var make = Ui__Toast__Error;

export {
  make ,
}
/* match Not a pure module */
