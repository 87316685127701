// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SubscriptionsTransportWs from "subscriptions-transport-ws";
import * as Base64 from "@decidr-io/utils/src/base64";

function make(url) {
  var trimmedUrl = url.trim();
  if (trimmedUrl.startsWith("https://") && trimmedUrl.includes(".appsync-api.") && trimmedUrl.endsWith("/graphql")) {
    return {
            TAG: /* Ok */0,
            _0: trimmedUrl
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: "Not a valid AppSync url"
          };
  }
}

function RegularEndpointUrl_asString(prim) {
  return prim;
}

var RegularEndpointUrl = {
  make: make,
  asString: RegularEndpointUrl_asString
};

function make$1(regularUrl) {
  return Curry._1(RegularEndpointUrl_asString, regularUrl).replace("https://", "wss://").replace(".appsync-api.", ".appsync-realtime-api.");
}

function RealtimeEndpointUrl_asString(prim) {
  return prim;
}

var RealtimeEndpointUrl = {
  make: make$1,
  asString: RealtimeEndpointUrl_asString
};

function make$2(regularUrl) {
  return Curry._1(RegularEndpointUrl_asString, regularUrl).replace("https://", "").replace("/graphql", "");
}

function Host_asString(prim) {
  return prim;
}

function make$3(url, host, apiKey) {
  var header64 = Base64.btoa(Js_json.serializeExn({
            host: Curry._1(Host_asString, host),
            "x-api-key": apiKey
          }));
  var payload64 = Base64.btoa("{}");
  return "" + Curry._1(RealtimeEndpointUrl_asString, url) + "?header=" + header64 + "&payload=" + payload64 + "";
}

function WsUrl_asString(prim) {
  return prim;
}

function make$4(url, apiKey) {
  var host = make$2(url);
  var wsUrl = make$1(url);
  var client = new SubscriptionsTransportWs.SubscriptionClient(Curry._1(WsUrl_asString, make$3(wsUrl, host, apiKey)), {
        reconnect: true
      });
  client.use([{
          applyMiddleware: (function (options, next) {
              var query = options.query;
              if (query !== undefined) {
                options.data = Js_json.serializeExn({
                      query: Caml_option.valFromOption(query),
                      variables: options.variables
                    });
                options.extensions = {
                  authorization: {
                    host: Curry._1(Host_asString, host),
                    "x-api-key": apiKey
                  }
                };
              }
              Curry._1(next, undefined);
            })
        }]);
  return client;
}

function btoa(prim) {
  return Base64.btoa(prim);
}

var WsClient = {
  make: make$4
};

export {
  RegularEndpointUrl ,
  RealtimeEndpointUrl ,
  btoa ,
  WsClient ,
}
/* subscriptions-transport-ws Not a pure module */
