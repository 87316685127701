// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as AppSync from "./AppSync.bs.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as App__Config from "../config/App__Config.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as FeatureFlags__Utils from "../../../feature-flags/src/utils/FeatureFlags__Utils.bs.js";
import * as AuthHelpers$DecidrIoAuth from "@decidr-io/auth/src/AuthHelpers.bs.js";
import * as Base64 from "@decidr-io/utils/src/base64";
import * as FeatureFlags__AppSpecificConfig from "../../../feature-flags/src/local/FeatureFlags__AppSpecificConfig.bs.js";
import * as GqlMiddleware__Server__Executor from "../../../graphql-middleware/src/server/GqlMiddleware__Server__Executor.bs.js";

var freshdeskBaseUrl = App__Config.values.freshdeskBaseUrl;

var freshdeskApiKey = App__Config.values.freshdeskApiKey;

var algoliaSearchOnlyApiKey = App__Config.values.algoliaSearchOnlyApiKey;

var algoliaAppId = App__Config.values.algoliaAppId;

var shopifyStorefrontAccessToken = App__Config.values.shopifyStorefrontAccessToken;

var shopifyStorefrontApiUrl = App__Config.values.shopifyStorefrontApiUrl;

var decidrAppId = App__Config.values.decidrAppId;

var apiKey = App__Config.values.apiKey;

var apiUrl = App__Config.values.apiUrl;

function getCognitoGroupsFromAccessToken(accessToken) {
  var dataFromAccessToken;
  if (accessToken !== undefined) {
    var x = Belt_Array.get(accessToken.split("."), 1);
    dataFromAccessToken = x !== undefined ? JSON.parse(Base64.atob(x).replace("cognito:groups", "cognito_groups")) : undefined;
  } else {
    dataFromAccessToken = undefined;
  }
  return Belt_Option.flatMap(dataFromAccessToken, (function (data) {
                return data.cognito_groups;
              }));
}

function processQueryUsingGqlMiddleware(operation, variables, _cacheConfig, _uploadables) {
  return $$Promise.$$catch(AuthHelpers$DecidrIoAuth.obtainAccessToken(undefined), (function (reason) {
                  console.log("tryAccessToken catch", reason);
                  return Promise.resolve(undefined);
                })).then(function (accessToken) {
              return $$Promise.$$catch(FeatureFlags__Utils.getAllFeatureFlagsFromLocalStorage(undefined), (function (reason) {
                              console.log("getAllFeatureFlagsFromLocalStorage catch", reason);
                              return Promise.resolve([]);
                            })).then(function (featureFlags) {
                          if (accessToken !== undefined) {
                            console.log("Authenticated request, operation", operation.name);
                          } else {
                            console.log("Non-authenticated request, operation", operation.name);
                          }
                          var cognitoGroups = getCognitoGroupsFromAccessToken(accessToken);
                          var activeFeatureFlags = FeatureFlags__AppSpecificConfig.FeatureFlagsMap.fromArray(Belt_Array.keepMap(Belt_Array.map(featureFlags, (function (param) {
                                          return [
                                                  FeatureFlags__AppSpecificConfig.unsafe_make(Belt_Array.getExn(param[0].split(FeatureFlags__AppSpecificConfig.storageDelimiter), 1)),
                                                  Caml_option.null_to_opt(param[1])
                                                ];
                                        })), (function (param) {
                                      var value = param[1];
                                      if (value !== undefined) {
                                        return [
                                                param[0],
                                                {
                                                  status: FeatureFlags__Utils.statusFromString(value),
                                                  disabled: false
                                                }
                                              ];
                                      }
                                      
                                    })));
                          return GqlMiddleware__Server__Executor.execute(operation.text, variables, {
                                        shopifyApiConfig: {
                                          url: shopifyStorefrontApiUrl,
                                          storefrontAccessToken: shopifyStorefrontAccessToken
                                        },
                                        markotApiConfig: {
                                          url: apiUrl,
                                          apiKey: apiKey,
                                          accessToken: accessToken,
                                          decidrAppId: decidrAppId
                                        },
                                        algoliaApiConfig: {
                                          appId: algoliaAppId,
                                          searchOnlyApiKey: algoliaSearchOnlyApiKey
                                        },
                                        freshdeskApiConfig: {
                                          apiKey: freshdeskApiKey,
                                          baseUrl: freshdeskBaseUrl
                                        },
                                        cognitoGroups: cognitoGroups,
                                        featureFlags: activeFeatureFlags
                                      }).then(function (result) {
                                      var errors = result.errors;
                                      if (errors !== undefined && errors.length !== 0) {
                                        Js_exn.raiseError("Errors encountered in GraphQL response:\n\n" + Belt_Array.joinWith(errors, "\n", (function (prim) {
                                                    return prim;
                                                  })));
                                      }
                                      return result;
                                    });
                        });
            });
}

var isSSR = (typeof window === "undefined");

var appSyncUrl = AppSync.RegularEndpointUrl.make(apiUrl);

if (appSyncUrl.TAG !== /* Ok */0) {
  Js_exn.raiseError("Wrong configuration. Cannot create AppSync subscriptions client. " + appSyncUrl._0);
}

var subscriptionClient;

subscriptionClient = appSyncUrl.TAG === /* Ok */0 && !isSSR ? Caml_option.some(AppSync.WsClient.make(appSyncUrl._0, apiKey)) : undefined;

var network = RelayRuntime.Network.create(processQueryUsingGqlMiddleware, Caml_option.option_get(Belt_Option.map(subscriptionClient, (function (client) {
                return function (param, param$1, param$2) {
                  var query = param.text;
                  var subscriptionQuery = {
                    query: query,
                    variables: param$1
                  };
                  return RelayRuntime.Observable.create(function (sink) {
                              var observable = client.request(subscriptionQuery);
                              return observable.subscribe(sink);
                            });
                };
              }))));

function makeEnvironment(param) {
  console.log("Making Relay environment...");
  return RescriptRelay.Environment.make(network, RescriptRelay.Store.make(new RelayRuntime.RecordSource(undefined), 10, undefined, undefined), undefined, undefined, undefined, undefined, undefined, undefined);
}

export {
  makeEnvironment ,
}
/* isSSR Not a pure module */
