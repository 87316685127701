// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__ErrorDisplay from "../errorDisplay/components/Ui__ErrorDisplay.bs.js";
import * as Reloader$DecidrIoReactNativeHelpers from "@decidr-io/react-native-helpers/src/reloader/Reloader.bs.js";
import * as ErrorBoundary$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/error/components/errorBoundary/ErrorBoundary.bs.js";

function Ui__MarkotErrorBoundary(Props) {
  var debugContext = Props.debugContext;
  var titleOpt = Props.title;
  var onReloadOpt = Props.onReload;
  var children = Props.children;
  var _FallbackComponent = Props.FallbackComponent;
  var showHeader = Props.showHeader;
  var title = titleOpt !== undefined ? titleOpt : "Oops, something crashed!";
  var onReload = onReloadOpt !== undefined ? onReloadOpt : Reloader$DecidrIoReactNativeHelpers.reloadApp;
  return React.createElement(ErrorBoundary$DecidrIoUniversalComponents.make, {
              debugContext: debugContext,
              children: children,
              FallbackComponent: (function (param) {
                  var errorId = param.errorId;
                  var errorMessage = param.errorMessage;
                  if (_FallbackComponent !== undefined) {
                    return Curry._1(_FallbackComponent, {
                                error: param.error,
                                errorMessage: errorMessage,
                                errorId: errorId
                              });
                  }
                  var tmp = {
                    title: title,
                    errorMessage: errorMessage,
                    onReload: onReload
                  };
                  if (errorId !== undefined) {
                    tmp.errorId = errorId;
                  }
                  if (showHeader !== undefined) {
                    tmp.showHeader = showHeader;
                  }
                  return React.createElement(Ui__ErrorDisplay.make, tmp);
                })
            });
}

var make = Ui__MarkotErrorBoundary;

export {
  make ,
}
/* react Not a pure module */
