// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MarkotApi__Id from "../../../api-adapter-markot/src/MarkotApi__Id.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Core__FromMarkot from "../core/GqlMiddleware__Core__FromMarkot.bs.js";

function makePrelovedItemMedia(param) {
  return {
          id: GqlMiddleware__Core__NodeId.make(1, "PrelovedItemMedia", MarkotApi__Id.unpackExn(param.id).internalId),
          status: param.status,
          tag: param.tag,
          item: GqlMiddleware__Core__FromMarkot.imageResource(param.item)
        };
}

export {
  makePrelovedItemMedia ,
}
/* MarkotApi__Id Not a pure module */
