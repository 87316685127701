// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ShopifyApi__Id from "./ShopifyApi__Id.bs.js";
import * as ShopifyApi__Infra from "./ShopifyApi__Infra.bs.js";
import * as ShopifyApi__Client from "./ShopifyApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";

var Raw = {};

var query = "query ProductOptions($productId: ID!)  {\nproduct(id: $productId)  {\noptions  {\n...ProductOptionFields   \n}\n\n}\n\n}\nfragment ProductOptionFields on ProductOption   {\nid  \nname  \nvalues  \n}\n";

function parse(value) {
  var value$1 = value.product;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.options;
    tmp = {
      options: Js_array.map((function (value) {
              var value$1 = value.values;
              return {
                      id: value.id,
                      name: value.name,
                      values: Js_array.map((function (value) {
                              return value;
                            }), value$1)
                    };
            }), value$2)
    };
  }
  return {
          product: tmp
        };
}

function serialize(value) {
  var value$1 = value.product;
  var product;
  if (value$1 !== undefined) {
    var value$2 = value$1.options;
    var options = Js_array.map((function (value) {
            var value$1 = value.values;
            var values = Js_array.map((function (value) {
                    return value;
                  }), value$1);
            var value$2 = value.name;
            var value$3 = value.id;
            return {
                    id: value$3,
                    name: value$2,
                    values: values
                  };
          }), value$2);
    product = {
      options: options
    };
  } else {
    product = null;
  }
  return {
          product: product
        };
}

function serializeVariables(inp) {
  return {
          productId: inp.productId
        };
}

function makeProductOption(param) {
  return {
          id: ShopifyApi__Id.parseExn(param.id),
          name: param.name,
          values: param.values
        };
}

function $$fetch(apiConfig, productId) {
  return Curry._2(ShopifyApi__Client.query(apiConfig)({
                    query: query,
                    Raw: Raw,
                    parse: parse,
                    serialize: serialize,
                    serializeVariables: serializeVariables
                  }), {
                productId: ShopifyApi__Id.toString(productId)
              }, undefined).then(function (param) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(param.product, (function (param) {
                                RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "Product not found while fetching product options. Id:", productId);
                              })), (function (param) {
                            return Belt_Array.map(param.options, makeProductOption);
                          }));
            });
}

export {
  $$fetch ,
}
/* ShopifyApi__Id Not a pure module */
