// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "../../routing/Ui__AppLink.bs.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as Ui__Expo__WebBrowser from "../../expo/webBrowser/Ui__Expo__WebBrowser.bs.js";
import * as Ignored$DecidrIoUtils from "@decidr-io/utils/src/functions/Ignored.bs.js";
import * as Ui__Button__CommonStyling from "../../button/Ui__Button__CommonStyling.bs.js";

function Ui__LinkButton$Wrapper(Props) {
  var children = Props.children;
  var internalVariant = Props.internalVariant;
  var isDisabled = Props.isDisabled;
  var style = Props.style;
  var wrapContentOpt = Props.wrapContent;
  var wrapContent = wrapContentOpt !== undefined ? wrapContentOpt : false;
  if (internalVariant.NAME !== "WebLink") {
    return children;
  }
  if (isDisabled) {
    return children;
  }
  var match = internalVariant.VAL;
  var tmp = {
    configuration: match[0],
    children: children,
    a11yLabel: match[1],
    wrapContent: wrapContent
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  return React.createElement(Ui__AppLink.make, tmp);
}

var Wrapper = {
  make: Ui__LinkButton$Wrapper
};

var match = Ui__Styling.describe({
      button: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center"
        },
        _1: (function (theme) {
            return {
                    borderRadius: theme.roundness.circle
                  };
          })
      },
      buttonElementsWrapper: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center",
          flexGrow: 1
        }
      },
      buttonText: {
        TAG: /* Static */0,
        _0: {
          textTransform: "uppercase"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__LinkButton(Props) {
  var kind = Props.kind;
  var sizeOpt = Props.size;
  var routeConfiguration = Props.routeConfiguration;
  var title = Props.title;
  var pressedColor = Props.pressedColor;
  var disabledOpt = Props.disabled;
  var containerStyle = Props.containerStyle;
  var textStyles = Props.textStyles;
  var left = Props.left;
  var right = Props.right;
  var busyOpt = Props.busy;
  var testOnly_pressedOpt = Props.testOnly_pressed;
  var testIDOpt = Props.testID;
  var linkStyle = Props.linkStyle;
  var buttonElementsWrapperStyle = Props.buttonElementsWrapperStyle;
  var wrapContent = Props.wrapContent;
  var size = sizeOpt !== undefined ? sizeOpt : "large";
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var busy = busyOpt !== undefined ? busyOpt : false;
  var testOnly_pressed = testOnly_pressedOpt !== undefined ? testOnly_pressedOpt : false;
  var testID = testIDOpt !== undefined ? testIDOpt : "link-button";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = React.useState(function () {
        return false;
      });
  var setHovered = match$1[1];
  var hovered = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setPressed = match$2[1];
  var styled = Curry._1(stylesheet, theme);
  var match$3 = Ui__Button__CommonStyling.useButtonCommonStyling(busy, disabled, kind, pressedColor, size, match$2[0], hovered, left, right);
  var isDisabled = match$3.isDisabled;
  var match$4 = Curry._1(Ui__AppLink.useRouting, undefined);
  var nativeNavigationHandler = match$4.nativeNavigationHandler;
  var internalVariant = nativeNavigationHandler !== undefined ? ({
        NAME: "NativeLink",
        VAL: (function (param) {
            Curry._1(nativeNavigationHandler, Curry._3(Ui__AppLink.makeConfiguration, routeConfiguration.route, undefined, undefined));
          })
      }) : ({
        NAME: "WebLink",
        VAL: [
          Curry._3(Ui__AppLink.makeConfiguration, routeConfiguration.route, undefined, undefined),
          routeConfiguration.a11yLabel
        ]
      });
  var tmp = {
    backgroundColor: match$3.backgroundColor
  };
  if (match$3.borderColor !== undefined) {
    tmp.borderColor = Caml_option.valFromOption(match$3.borderColor);
  }
  if (match$3.borderWidth !== undefined) {
    tmp.borderWidth = match$3.borderWidth;
  }
  var tmp$1;
  if (internalVariant.NAME === "WebLink" || isDisabled) {
    tmp$1 = undefined;
  } else {
    var onPress = internalVariant.VAL;
    tmp$1 = (function (param) {
        var _route = routeConfiguration.route;
        if (typeof _route === "number" || _route.TAG !== /* ExternalRoute */12) {
          return Curry._1(onPress, undefined);
        } else {
          return Ignored$DecidrIoUtils.promiseWithPayload(Ui__Expo__WebBrowser.openBrowserAsync(_route.url, undefined, undefined));
        }
      });
  }
  var tmp$2 = {
    color: match$3.textColor
  };
  var tmp$3 = Bool$DecidrIoUtils.thenSome(hovered && !isDisabled, "underline");
  if (tmp$3 !== undefined) {
    tmp$2.textDecorationLine = (function () {
          switch (tmp$3) {
            case "none" :
                return "none";
            case "underline" :
                return "underline";
            case "lineThrough" :
                return "line-through";
            case "underlineLineThrough" :
                return "underline line-through";
            
          }
        })();
  }
  var tmp$4 = {
    activeOpacity: 1,
    style: [
      Caml_option.some(Curry._1(styled, styles.button)),
      Caml_option.some(tmp),
      Bool$DecidrIoUtils.thenSome(ReactNative.Platform.OS === "web", {
            flex: 1
          }),
      match$3.containerPaddingStyle,
      containerStyle
    ],
    underlayColor: match$3.underlayColor,
    disabled: isDisabled,
    onPressIn: (function (param) {
        Curry._1(setPressed, (function (param) {
                return true;
              }));
      }),
    onPressOut: (function (param) {
        Curry._1(setPressed, (function (param) {
                return false;
              }));
      }),
    testID: testID,
    testOnly_pressed: testOnly_pressed,
    children: React.createElement(Ui__Group.make, {
          spacing: theme.spacing._4,
          children: null,
          containerStyle: [
            Caml_option.some(Curry._1(styled, styles.buttonElementsWrapper)),
            buttonElementsWrapperStyle
          ],
          orientation: "horizontal"
        }, match$3.leftElement, React.createElement(Ui__MarkotText.make, {
              size: match$3.textSize,
              children: title,
              style: [
                Caml_option.some(Curry._1(styled, styles.buttonText)),
                Caml_option.some(tmp$2),
                textStyles
              ],
              testID: "" + testID + "-title"
            }), match$3.rightElement),
    onMouseEnter: (function (param) {
        Curry._1(setHovered, (function (param) {
                return true;
              }));
      }),
    onMouseLeave: (function (param) {
        Curry._1(setHovered, (function (param) {
                return false;
              }));
      })
  };
  if (tmp$1 !== undefined) {
    tmp$4.onPress = Caml_option.valFromOption(tmp$1);
  }
  var tmp$5 = {
    children: React.createElement(ReactNative.TouchableHighlight, tmp$4),
    internalVariant: internalVariant,
    isDisabled: isDisabled
  };
  if (linkStyle !== undefined) {
    tmp$5.style = Caml_option.valFromOption(linkStyle);
  }
  if (wrapContent !== undefined) {
    tmp$5.wrapContent = Caml_option.valFromOption(wrapContent);
  }
  return React.createElement(Ui__LinkButton$Wrapper, tmp$5);
}

var make = Ui__LinkButton;

export {
  Wrapper ,
  make ,
}
/* match Not a pure module */
