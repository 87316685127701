import {
  visit,
  print,
  ASTNode,
  GraphQLResolveInfo,
  FragmentDefinitionNode,
} from "graphql";
import { ObjMap } from "graphql/jsutils/ObjMap";
import { hasValue } from "@decidr-io/type-safety/src/nullable";

// go from path to the ancestors and work out the hierarchy until the top
// the top could be a fragment definition or the top of the query (if path empty, parent null)

function collectFragmentInfoInQuery(ast: ASTNode) {
  const fragments = new Set<string>();
  const variables = new Set<string>();
  visit(ast, {
    FragmentSpread: node => {
      fragments.add(node.name.value);
      return undefined;
    },
    Variable: node => {
      variables.add(node.name.value);
      return undefined;
    },
  });
  return { fragments, variables };
}

function collectFragmentInfoInFragmentsRec(
  fragmentNamesToCollect: string[],
  fragmentMap: ObjMap<FragmentDefinitionNode>,
  collectedFragmentNames: Set<string>,
  collectedVariableNames: Set<string>
) {
  const newCollectedNames = new Set<string>();
  for (const name of fragmentNamesToCollect) {
    visit(fragmentMap[name], {
      FragmentSpread: node => {
        if (!collectedFragmentNames.has(node.name.value)) {
          newCollectedNames.add(node.name.value);
          collectedFragmentNames.add(node.name.value);
        }
        return undefined;
      },
      Variable: node => {
        collectedVariableNames.add(node.name.value);
        return undefined;
      },
    });
  }
  if (newCollectedNames.size === 0) {
    return;
  }
  collectFragmentInfoInFragmentsRec(
    Array.from(newCollectedNames),
    fragmentMap,
    collectedFragmentNames,
    collectedVariableNames
  );
}

function collectUsedFragments(info: GraphQLResolveInfo) {
  const mentionedFragments = info.fieldNodes
    .map(collectFragmentInfoInQuery) // directly mentioned
    .reduce(({ fragments, variables }, x) => {
      fragments = new Set(
        Array.from(fragments).concat(Array.from(x.fragments))
      );
      variables = new Set(
        Array.from(variables).concat(Array.from(x.variables))
      );

      return { fragments, variables };
    });

  // console.log(
  //   "[collectUsedFragments] directly mentionedFragmentNames",
  //   mentionedFragments.fragments.values()
  // );

  // indirectly mentioned
  collectFragmentInfoInFragmentsRec(
    Array.from(mentionedFragments.fragments),
    info.fragments,
    mentionedFragments.fragments,
    mentionedFragments.variables
  );

  // console.log(
  //   "[collectUsedFragments] indirectly mentionedFragmentNames",
  //   mentionedFragments.fragments.values()
  // );

  return {
    fragments: Array.from(mentionedFragments.fragments).map(
      name => info.fragments[name]
    ),
    variables: Array.from(mentionedFragments.variables)
      .map(name =>
        (info.operation.variableDefinitions ?? []).find(
          def => def.variable.name.value === name
        )
      )
      .filter(hasValue),
  };
}

const removeAliases = (x: ASTNode) =>
  visit(x, {
    Field: (node, _key, _parent, _path, _ancestors) => {
      if (node.alias) {
        // console.info(`De-aliasing ${node.alias.value} to ${node.name.value}`);
        return {
          ...node,
          alias: null,
        };
      }
      return undefined;
    },
  });

export const generateDefsForFragmentsAndVariables = (
  info: GraphQLResolveInfo
) => {
  const { fragments, variables } = collectUsedFragments(info);
  return {
    fragments: fragments.map(removeAliases).map(print).join("\n"),
    variables: variables.map(print).join(", "),
  };
};

export function generateProxyDocumentText(
  info: GraphQLResolveInfo,
  wrapSelectionSet: (selectionSet: string) => string = x => x
) {
  const fragVarsDefs = generateDefsForFragmentsAndVariables(info);
  // console.log("generateProxyDocumentText:");
  // console.dir(info);

  const originalOpName = info.operation.name?.value ?? "UnknownOp";
  const variableDefs = fragVarsDefs.variables
    ? `(${fragVarsDefs.variables}) `
    : "";

  const selectionSet = String(
    print(
      info.fieldNodes.map(
        removeAliases
      ) as unknown as ASTNode /* This will work */
    )
  );

  return `
  ${info.operation.operation} Proxy_${originalOpName}_for_${
    info.fieldName
  } ${variableDefs}{
    ${wrapSelectionSet(selectionSet)}
  }
  ${fragVarsDefs.fragments}
  `; // operation contains the full operation issued towards this graphql executor
}

// `graphql-js` result objects don't have a prototype to avoid field name clashes
// Relay (or `rescript-relay`) relies on Object.prototype, so we need to re-instantiate it
// https://github.com/graphql/graphql-js/issues/484
export function forceObjectPrototype(obj: unknown) {
  if (obj != null && Object.getPrototypeOf(obj) == null) {
    // console.log("setting proto on", obj)
    Object.setPrototypeOf(obj, {});
  }
  switch (typeof obj) {
    case "object":
      if (obj === null) {
        // do nothing
        break;
      } else if (Array.isArray(obj)) {
        obj.forEach(forceObjectPrototype);
        break;
      } else {
        Object.values(obj).forEach(forceObjectPrototype);
        break;
      }
    default:
    // do nothing
  }
}
