// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as WebUtils from "./webUtils";

function make(prim) {
  return WebUtils.makeQueryParam(prim.VAL);
}

function isNull(prim) {
  return WebUtils.isQueryParamNull(prim);
}

function QueryParams_getString(prim) {
  return prim;
}

var QueryParams = {
  make: make,
  isNull: isNull,
  getString: QueryParams_getString
};

var make$1 = Js_dict.fromArray;

function Query_asString(prim) {
  return prim;
}

var Query = {
  asString: Query_asString,
  make: make$1
};

function makeUrlObject(auth, hash, host, hostname, href, pathname, protocol, search, slashes, port, query, param) {
  return {
          auth: auth,
          hash: hash,
          host: host,
          hostname: hostname,
          href: href,
          pathname: pathname,
          protocol: protocol,
          search: search,
          slashes: slashes,
          port: port,
          query: query
        };
}

function Url_make(prim) {
  return new URL(prim);
}

function Url_makeWithBase(prim0, prim1) {
  return new URL(prim0, prim1);
}

function Url_asString(prim) {
  return prim;
}

function Url_asUrlObject(prim) {
  return prim;
}

function Url_getHash(prim) {
  return prim.hash;
}

function Url_getHost(prim) {
  return prim.host;
}

function Url_getHostname(prim) {
  return prim.hostname;
}

function Url_getHref(prim) {
  return prim.href;
}

function Url_getOrigin(prim) {
  return prim.origin;
}

function Url_getPassword(prim) {
  return prim.password;
}

function Url_getPathname(prim) {
  return prim.pathname;
}

function Url_getPort(prim) {
  return prim.port;
}

function Url_getProtocol(prim) {
  return prim.protocol;
}

function Url_getSearch(prim) {
  return prim.search;
}

function Url_getSearchParams(prim) {
  return prim.searchParams;
}

function Url_getUsername(prim) {
  return prim.username;
}

var Url = {
  make: Url_make,
  makeWithBase: Url_makeWithBase,
  asString: Url_asString,
  asUrlObject: Url_asUrlObject,
  makeUrlObject: makeUrlObject,
  getHash: Url_getHash,
  getHost: Url_getHost,
  getHostname: Url_getHostname,
  getHref: Url_getHref,
  getOrigin: Url_getOrigin,
  getPassword: Url_getPassword,
  getPathname: Url_getPathname,
  getPort: Url_getPort,
  getProtocol: Url_getProtocol,
  getSearch: Url_getSearch,
  getSearchParams: Url_getSearchParams,
  getUsername: Url_getUsername
};

function Locale_asString(prim) {
  return prim;
}

function Locale_asBool(prim) {
  return prim;
}

var Locale = {
  asString: Locale_asString,
  asBool: Locale_asBool
};

function Port_asString(prim) {
  return prim;
}

function Port_asInt(prim) {
  return prim;
}

var Port = {
  asString: Port_asString,
  asInt: Port_asInt
};

var $$URLSearchParams = {
  get: (function (prim0, prim1) {
      return prim0.get(prim1);
    })
};

export {
  Locale ,
  Port ,
  QueryParams ,
  Query ,
  $$URLSearchParams ,
  Url ,
}
/* ./webUtils Not a pure module */
