// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReactNative from "react-native";

function toSafeA11yRole(role) {
  if ((role === "region" || role === "article" || role === "banner" || role === "navigation" || role === "complementary") && ReactNative.Platform.OS !== "web") {
    return ;
  } else {
    return role;
  }
}

export {
  toSafeA11yRole ,
}
/* react-native Not a pure module */
