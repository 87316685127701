// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as MarkotApi__Infra from "./MarkotApi__Infra.bs.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as MarkotApi__CommonFragments from "./MarkotApi__CommonFragments.bs.js";

var query = "fragment BlogPostFields on BlogPost   {\nid  \ntitle  \nurl  \nblurb  \ncoverImage  {\n...ImageResourceFields   \n}\n\nslug  \n}\n" + MarkotApi__CommonFragments.ImageResourceFields.query;

function parse(value) {
  var value$1 = value.blurb;
  var value$2 = value.coverImage;
  return {
          id: value.id,
          title: value.title,
          url: value.url,
          blurb: !(value$1 == null) ? value$1 : undefined,
          coverImage: !(value$2 == null) ? MarkotApi__CommonFragments.ImageResourceFields.verifyArgsAndParse("ImageResourceFields", value$2) : undefined,
          slug: value.slug
        };
}

function serialize(value) {
  var value$1 = value.slug;
  var value$2 = value.coverImage;
  var coverImage = value$2 !== undefined ? MarkotApi__CommonFragments.ImageResourceFields.serialize(value$2) : null;
  var value$3 = value.blurb;
  var blurb = value$3 !== undefined ? value$3 : null;
  var value$4 = value.url;
  var value$5 = value.title;
  var value$6 = value.id;
  return {
          id: value$6,
          title: value$5,
          url: value$4,
          blurb: blurb,
          coverImage: coverImage,
          slug: value$1
        };
}

var Raw = {};

var query$1 = "query BlogPostBasicData($id: ID!)  {\nnode(id: $id)  {\n__typename\n...on BlogPost   {\n...BlogPostFields   \n}\n\n}\n\n}\n" + query;

function parse$1(value) {
  var value$1 = value.node;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1 = typename === "BlogPost" ? ({
          NAME: "BlogPost",
          VAL: {
            blogPostFields: parse(value$1)
          }
        }) : ({
          NAME: "UnspecifiedFragment",
          VAL: typename
        });
    tmp = tmp$1;
  }
  return {
          node: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.node;
  var node = value$1 !== undefined ? (
      value$1.NAME === "BlogPost" ? Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                return {};
              }), [serialize(value$1.VAL.blogPostFields)]) : ({})
    ) : null;
  return {
          node: node
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

var Raw$1 = {};

var query$2 = "query BlogPosts($first: Int, $after: String, $tag: String)  {\nblogPosts(first: $first, after: $after, tag: $tag)  {\npageInfo  {\nstartCursor  \nendCursor  \nhasNextPage  \nhasPreviousPage  \n}\n\nedges  {\ncursor  \nnode  {\n...BlogPostFields   \n}\n\n}\n\n}\n\n}\n" + query;

function parse$2(value) {
  var value$1 = value.blogPosts;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          blogPosts: {
            pageInfo: {
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined,
              hasNextPage: value$2.hasNextPage,
              hasPreviousPage: value$2.hasPreviousPage
            },
            edges: Js_array.map((function (value) {
                    return {
                            cursor: value.cursor,
                            node: parse(value.node)
                          };
                  }), value$5)
          }
        };
}

function serialize$2(value) {
  var value$1 = value.blogPosts;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var node = serialize(value$1);
          var value$2 = value.cursor;
          return {
                  cursor: value$2,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasPreviousPage;
  var value$5 = value$3.hasNextPage;
  var value$6 = value$3.endCursor;
  var endCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$3.startCursor;
  var startCursor = value$7 !== undefined ? value$7 : null;
  var pageInfo = {
    startCursor: startCursor,
    endCursor: endCursor,
    hasNextPage: value$5,
    hasPreviousPage: value$4
  };
  var blogPosts = {
    pageInfo: pageInfo,
    edges: edges
  };
  return {
          blogPosts: blogPosts
        };
}

function serializeVariables$1(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  var a$2 = inp.tag;
  return {
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined,
          tag: a$2 !== undefined ? a$2 : undefined
        };
}

function makeBlogPost(param) {
  return {
          id: MarkotApi__Id.parseExn(param.id),
          title: param.title,
          url: param.url,
          blurb: param.blurb,
          coverImage: Belt_Option.map(param.coverImage, MarkotApi__CommonFragments.makeImageResource),
          slug: param.slug
        };
}

function fetchBasicData(apiConfig, id) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$1,
                    Raw: Raw,
                    parse: parse$1,
                    serialize: serialize$1,
                    serializeVariables: serializeVariables
                  }), {
                id: MarkotApi__Id.toString(id)
              }, undefined).then(function (param) {
              var node = param.node;
              if (node !== undefined) {
                if (node.NAME === "BlogPost") {
                  return makeBlogPost(node.VAL.blogPostFields);
                } else {
                  RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "BlogPost fetchBasicData - node of unknown type found. Ignoring.", node);
                  return ;
                }
              } else {
                RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "BlogPost fetchBasicData - not found. Id", id);
                return ;
              }
            });
}

function fetchBlogPosts(apiConfig, first, after, tag) {
  return $$Promise.$$catch(Curry._2(MarkotApi__Client.query(apiConfig)({
                        query: query$2,
                        Raw: Raw$1,
                        parse: parse$2,
                        serialize: serialize$2,
                        serializeVariables: serializeVariables$1
                      }), {
                    first: first,
                    after: after,
                    tag: tag
                  }, undefined).then(function (param) {
                  var match = param.blogPosts;
                  var pageInfo = match.pageInfo;
                  return {
                          pageInfo: {
                            startCursor: pageInfo.startCursor,
                            endCursor: pageInfo.endCursor,
                            hasNextPage: pageInfo.hasNextPage,
                            hasPreviousPage: pageInfo.hasPreviousPage
                          },
                          edges: Belt_Array.map(match.edges, (function (param) {
                                  return {
                                          cursor: param.cursor,
                                          node: makeBlogPost(param.node)
                                        };
                                }))
                        };
                }), (function (e) {
                RescriptUtils__Logger.error2(MarkotApi__Infra.logger, e, "BlogPost fetchBlogPosts - error reading posts (ignoring the error)");
                return Promise.resolve(undefined);
              }));
}

export {
  fetchBasicData ,
  fetchBlogPosts ,
}
/* MarkotApi__Id Not a pure module */
