// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "../../../api-adapter-markot/src/MarkotApi__Id.bs.js";
import * as GraphqlCore__Types from "../../../graphql-core/src/GraphqlCore__Types.bs.js";
import * as MarkotApi__BidInfo from "../../../api-adapter-markot/src/MarkotApi__BidInfo.bs.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";
import * as GqlMiddleware__Entity__Bid from "./GqlMiddleware__Entity__Bid.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";

function makeBidInfo(param) {
  var status = param.status;
  return {
          __typename: "BidInfo",
          id: param.id,
          bid: GqlMiddleware__Entity__Bid.makeBid(param.bid),
          status: status === "Cancelled" ? "Cancelled" : (
              status === "Active" ? "Active" : (
                  status === "Expired" ? "Expired" : (
                      status === "ActiveHighest" ? "ActiveHighest" : (
                          status === "Accepted" ? "Accepted" : (
                              status === "Declined" ? "Declined" : (
                                  status === "Deactivated" ? "Deactivated" : (
                                      status === "Lost" ? "Lost" : (
                                          status === "ListingDeleted" ? "ListingDeleted" : (
                                              status === "Superseded" ? "Superseded" : (
                                                  status === "PaymentFailed" ? "PaymentFailed" : "Removed"
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
          bidderHasActiveBidsOnSimilarItems: param.bidderHasActiveBidsOnSimilarItems,
          statusLastUpdatedAt: param.statusLastUpdatedAt
        };
}

function fetchBids(apiConfig, first, after, bidGroup) {
  return MarkotApi__BidInfo.fetchBids(apiConfig, first, after, bidGroup).then(function (__x) {
                return Belt_Option.map(__x, (function (__x) {
                              return GraphqlCore__Types.ConnectionWithTotal.map(__x, makeBidInfo);
                            }));
              }).then(function (result) {
              if (result !== undefined) {
                return result;
              } else {
                RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "[BidInfo] fetchBidInfos - viewer not returned from Markot API");
                return {
                        totalItems: 0,
                        pageInfo: {
                          startCursor: undefined,
                          endCursor: undefined,
                          hasNextPage: false,
                          hasPreviousPage: false
                        },
                        edges: []
                      };
              }
            });
}

function executePostBid(apiConfig, param) {
  return MarkotApi__BidInfo.executePostBid(apiConfig, {
                prelovedItemInfoId: MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(param.prelovedItemInfoId)),
                amount: param.amount
              }).then(function (param) {
              return {
                      __typename: "BidEventPayload",
                      prelovedItemInfoId: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.prelovedItemInfoId)),
                      bidInfo: makeBidInfo(param.bidInfo)
                    };
            });
}

function executeAcceptBid(apiConfig, param) {
  return MarkotApi__BidInfo.executeAcceptBid(apiConfig, {
                id: MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(param.id))
              }).then(function (param) {
              return {
                      __typename: "BidInfoStatusChangedPayload",
                      bidInfo: makeBidInfo(param.bidInfo)
                    };
            });
}

function executeDeclineBid(apiConfig, param) {
  return MarkotApi__BidInfo.executeDeclineBid(apiConfig, {
                id: MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(param.id))
              }).then(function (param) {
              return {
                      __typename: "BidInfoStatusChangedPayload",
                      bidInfo: makeBidInfo(param.bidInfo)
                    };
            });
}

function executeCancelBid(apiConfig, param) {
  return MarkotApi__BidInfo.executeCancelBid(apiConfig, {
                id: MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(param.id))
              }).then(function (param) {
              return {
                      __typename: "BidInfoStatusChangedPayload",
                      bidInfo: makeBidInfo(param.bidInfo)
                    };
            });
}

export {
  makeBidInfo ,
  fetchBids ,
  executePostBid ,
  executeAcceptBid ,
  executeDeclineBid ,
  executeCancelBid ,
}
/* MarkotApi__Id Not a pure module */
