// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";

function map(param, transform) {
  return {
          pageInfo: param.pageInfo,
          edges: Belt_Array.map(param.edges, (function (param) {
                  return {
                          cursor: param.cursor,
                          node: Curry._1(transform, param.node)
                        };
                })),
          searchInfo: param.searchInfo
        };
}

function makeConnectionFromAlgoliaConnection(param, nodeTransform) {
  var match = param.searchInfo;
  var facets = match.facets;
  var match$1 = param.pageInfo;
  var getFacetSummary = function (facetsDict, facetName) {
    var x = Js_dict.entries(facetsDict).find(function (param) {
          return param[0] === facetName;
        });
    return (
              x !== undefined ? Js_dict.entries((
                        x === undefined ? undefined : Caml_option.some(x)
                      )[1]) : []
            ).map(function (param) {
                return {
                        value: param[0],
                        count: param[1]
                      };
              });
  };
  return {
          pageInfo: {
            startCursor: match$1.startCursor,
            endCursor: match$1.endCursor,
            hasNextPage: match$1.hasNextPage,
            hasPreviousPage: match$1.hasPreviousPage
          },
          edges: Belt_Array.map(param.edges, (function (param) {
                  return {
                          cursor: param.cursor,
                          node: Curry._1(nodeTransform, param.node)
                        };
                })),
          searchInfo: {
            nbHits: match.nbHits,
            query: match.query,
            processingTimeMS: match.processingTimeMS,
            facetsInfo: {
              variantFacetsSummary: {
                brandNames: getFacetSummary(facets, "brandName"),
                allBrandNames: Belt_Array.map(match.allBrandNames, (function (param) {
                        return {
                                value: param.value,
                                count: param.count
                              };
                      })),
                categoryNames: getFacetSummary(facets, "categoryNames"),
                allCategoryNames: Belt_Array.map(match.allCategoryNames, (function (param) {
                        return {
                                value: param.value,
                                count: param.count
                              };
                      }))
              },
              prelovedItemFacetsSummary: {
                conditions: Belt_Array.map(Belt_Array.keep(getFacetSummary(facets, "condition"), (function (param) {
                            var hasUnsupportedValue = param.value === "NeverUsedWithPackaging";
                            if (hasUnsupportedValue) {
                              RescriptUtils__Logger.warn(GqlMiddleware__Core__Infra.logger, "GqlMiddleware__Entity__SearchResult encountered `NeverUsedWithPackaging` in `prelovedItemFacetsSummary`");
                            }
                            return !hasUnsupportedValue;
                          })), (function (param) {
                        var value = param.value;
                        var tmp;
                        switch (value) {
                          case "AsGoodAsNew" :
                              tmp = "AsGoodAsNew";
                              break;
                          case "Good" :
                              tmp = "Good";
                              break;
                          case "NeverUsedWithoutPackaging" :
                              tmp = "NeverUsed";
                              break;
                          case "WellLoved" :
                              tmp = "WellLoved";
                              break;
                          default:
                            tmp = value;
                        }
                        return {
                                value: tmp,
                                count: param.count
                              };
                      }))
              }
            },
            minPrice: match.minPrice,
            maxPrice: match.maxPrice
          }
        };
}

var Connection = {
  map: map,
  makeConnectionFromAlgoliaConnection: makeConnectionFromAlgoliaConnection
};

export {
  Connection ,
}
/* GqlMiddleware__Core__Infra Not a pure module */
