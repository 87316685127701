// Generated by ReScript, PLEASE EDIT WITH CARE


function make(modelYear, originalPrice) {
  return {
          __typename: "OpenBoxInfo",
          modelYear: modelYear,
          originalPrice: originalPrice
        };
}

export {
  make ,
}
/* No side effect */
