// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as App__Report from "../../../utils/App__Report.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactRelay from "react-relay";
import * as Ui__TextField from "../../../../../ui/src/textField/components/Ui__TextField.bs.js";
import * as App__AddressForm from "../addressForm/App__AddressForm.bs.js";
import * as Ui__Toast__Error from "../../../../../ui/src/toast/components/error/Ui__Toast__Error.bs.js";
import * as App__AddressEntity from "../../App__AddressEntity.bs.js";
import * as App__AddressModalShell from "../addressModalShell/App__AddressModalShell.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as AppAddAddressModal_markotUser_graphql from "../../../../../relay-codegen/generated/AppAddAddressModal_markotUser_graphql.bs.js";
import * as AppAddAddressModal_createUserAddressMutation_graphql from "../../../../../relay-codegen/generated/AppAddAddressModal_createUserAddressMutation_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppAddAddressModal_markotUser_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppAddAddressModal_markotUser_graphql.Internal.convertFragment, data);
}

function use$1(param) {
  var match = ReactRelay.useMutation(AppAddAddressModal_createUserAddressMutation_graphql.node);
  var mutate = match[0];
  return [
          React.useMemo((function () {
                  return function (param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
                    return Curry._1(mutate, {
                                onError: param,
                                onCompleted: param$1 !== undefined ? (function (r, errors) {
                                      Curry._2(param$1, AppAddAddressModal_createUserAddressMutation_graphql.Internal.convertResponse(r), (errors == null) ? undefined : Caml_option.some(errors));
                                    }) : undefined,
                                onUnsubscribe: param$2,
                                optimisticResponse: param$3 !== undefined ? AppAddAddressModal_createUserAddressMutation_graphql.Internal.convertWrapRawResponse(param$3) : undefined,
                                optimisticUpdater: param$4,
                                updater: param$5 !== undefined ? (function (store, r) {
                                      Curry._2(param$5, store, AppAddAddressModal_createUserAddressMutation_graphql.Internal.convertResponse(r));
                                    }) : undefined,
                                variables: AppAddAddressModal_createUserAddressMutation_graphql.Internal.convertVariables(param$6),
                                uploadables: param$7
                              });
                  };
                }), [mutate]),
          match[1]
        ];
}

function makeInitialState(recipientName) {
  return {
          recipientName: {
            value: recipientName,
            validation: {
              kind: "neutral",
              status: Ui__TextField.neutralStatusPreset
            }
          },
          country: {
            value: "",
            validation: {
              kind: "neutral",
              status: Ui__TextField.neutralStatusPreset
            }
          },
          postalCode: {
            value: "",
            validation: {
              kind: "neutral",
              status: Ui__TextField.neutralStatusPreset
            }
          },
          city: {
            value: "",
            validation: {
              kind: "neutral",
              status: Ui__TextField.neutralStatusPreset
            }
          },
          streetLine1: {
            value: "",
            validation: {
              kind: "neutral",
              status: Ui__TextField.neutralStatusPreset
            }
          },
          state: {
            value: "",
            validation: {
              kind: "neutral",
              status: Ui__TextField.neutralStatusPreset
            }
          },
          streetLine2: "",
          extraInfo: "",
          description: "",
          error: undefined
        };
}

function App__AddAddressModal(Props) {
  var markotUser = Props.markotUser;
  var visible = Props.visible;
  var onClose = Props.onClose;
  var onAddCompleted = Props.onAddCompleted;
  var addressType = Props.addressType;
  var match = use(markotUser);
  var __id = match.__id;
  var match$1 = use$1(undefined);
  var createUserAddress = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setError = match$2[1];
  var initialState = makeInitialState(Belt_Option.mapWithDefault(match.firstName, "", (function (firstName) {
              return firstName + " ";
            })) + Belt_Option.getWithDefault(match.lastName, ""));
  return React.createElement(App__AddressModalShell.make, {
              visible: visible,
              onClose: onClose,
              children: null,
              title: "Add address"
            }, React.createElement(App__AddressForm.make, {
                  initialState: initialState,
                  onCancel: onClose,
                  onSave: (function (param, onCompleted, onError) {
                      Curry.app(createUserAddress, [
                            (function (param) {
                                App__Report.unexpected(param.message, "App__AddAddressModal createUserAddress onError");
                                Curry._1(setError, (function (param) {
                                        return "An error occurred trying to add the address. Please try again!";
                                      }));
                                Curry._1(onError, "An error occurred trying to add the address. Please try again!");
                              }),
                            (function (data, error) {
                                if (error !== undefined) {
                                  Curry._1(setError, (function (param) {
                                          return "An error occurred trying to add the address. Please try again!";
                                        }));
                                  App__Report.unexpected(Belt_Array.joinWith(error, ", ", (function (error) {
                                              return error.message;
                                            })), "App__AddAddressModal createUserAddress onCompleted");
                                  return Curry._1(onError, "An error occurred trying to add the address. Please try again!");
                                } else {
                                  Curry._1(onCompleted, undefined);
                                  Belt_Option.forEach(onAddCompleted, (function (onAddCompleted) {
                                          Curry._1(onAddCompleted, data.createUserAddress.address.id);
                                        }));
                                  return Curry._1(onClose, undefined);
                                }
                              }),
                            undefined,
                            undefined,
                            undefined,
                            (function (store, result) {
                                if (addressType === undefined) {
                                  return ;
                                }
                                var match = store.get(__id);
                                var match$1 = store.get(result.createUserAddress.address.__id);
                                if (match == null) {
                                  return ;
                                }
                                if (match$1 == null) {
                                  return ;
                                }
                                var match$2 = addressType === "Shipping" ? [
                                    App__AddressEntity.defaultShippingAddressIdCacheField,
                                    App__AddressEntity.defaultShippingAddressCacheField
                                  ] : [
                                    App__AddressEntity.defaultBillingAddressIdCacheField,
                                    App__AddressEntity.defaultBillingAddressCacheField
                                  ];
                                match.setValue(result.createUserAddress.address.id, match$2[0], undefined);
                                match.setLinkedRecord(match$1, match$2[1], undefined);
                              }),
                            {
                              addressType: addressType,
                              city: param.city.value,
                              country: param.country.value,
                              description: param.description,
                              extraInfo: param.extraInfo,
                              postalCode: param.postalCode.value,
                              recipientName: param.recipientName.value,
                              relayConnectionsToUpdate: App__AddressEntity.collectAllRelayConnections(__id),
                              state: param.state.value,
                              streetLine1: param.streetLine1.value,
                              streetLine2: param.streetLine2
                            },
                            undefined,
                            undefined
                          ]);
                    }),
                  saving: match$1[1]
                }), SimpleReact$DecidrIoUtils.renderIfSome(match$2[0], (function (errorMessage) {
                    return React.createElement(Ui__Toast__Error.make, {
                                title: "An error has occurred!",
                                description: errorMessage,
                                onDismiss: (function (param) {
                                    Curry._1(setError, (function (param) {
                                            
                                          }));
                                  })
                              });
                  })));
}

var make = App__AddAddressModal;

export {
  make ,
}
/* react Not a pure module */
