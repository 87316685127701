// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as ShopifyApi__Id from "../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as MarkotApi__Infra from "./MarkotApi__Infra.bs.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as MarkotApi__CommonFragments from "./MarkotApi__CommonFragments.bs.js";
import * as MarkotApi__PrelovedItemMedia from "./MarkotApi__PrelovedItemMedia.bs.js";

var query = "fragment PrelovedItemFields on PrelovedItem   {\nid  \nproductId: productId3  \nlistingId  \ndescription  \ncondition  \nbelongsToViewer  \nmainImage  {\n...ImageResourceFields   \n}\n\nmedia @include(if: $loadMedia) {\n...PrelovedItemMediaFields   \n}\n\n}\n" + MarkotApi__CommonFragments.ImageResourceFields.query + MarkotApi__PrelovedItemMedia.PrelovedItemMediaFields.query;

function parse(value) {
  var value$1 = value.productId;
  var value$2 = value.description;
  var value$3 = value.condition;
  var tmp;
  switch (value$3) {
    case "AsGoodAsNew" :
        tmp = "AsGoodAsNew";
        break;
    case "Good" :
        tmp = "Good";
        break;
    case "NeverUsedWithPackaging" :
        tmp = "NeverUsedWithPackaging";
        break;
    case "NeverUsedWithoutPackaging" :
        tmp = "NeverUsedWithoutPackaging";
        break;
    case "WellLoved" :
        tmp = "WellLoved";
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$3
      };
  }
  var value$4 = value.mainImage;
  var value$5 = value.media;
  return {
          id: value.id,
          productId: !(value$1 == null) ? value$1 : undefined,
          listingId: value.listingId,
          description: !(value$2 == null) ? value$2 : undefined,
          condition: tmp,
          belongsToViewer: value.belongsToViewer,
          mainImage: !(value$4 == null) ? MarkotApi__CommonFragments.ImageResourceFields.verifyArgsAndParse("ImageResourceFields", value$4) : undefined,
          media: !(value$5 == null) ? Js_array.map((function (value) {
                    return MarkotApi__PrelovedItemMedia.PrelovedItemMediaFields.verifyArgsAndParse("PrelovedItemMediaFields", value);
                  }), value$5) : undefined
        };
}

function serialize(value) {
  var value$1 = value.media;
  var media = value$1 !== undefined ? Js_array.map((function (value) {
            return MarkotApi__PrelovedItemMedia.PrelovedItemMediaFields.serialize(value);
          }), value$1) : null;
  var value$2 = value.mainImage;
  var mainImage = value$2 !== undefined ? MarkotApi__CommonFragments.ImageResourceFields.serialize(value$2) : null;
  var value$3 = value.belongsToViewer;
  var value$4 = value.condition;
  var condition = typeof value$4 === "object" ? value$4.VAL : (
      value$4 === "NeverUsedWithoutPackaging" ? "NeverUsedWithoutPackaging" : (
          value$4 === "WellLoved" ? "WellLoved" : (
              value$4 === "AsGoodAsNew" ? "AsGoodAsNew" : (
                  value$4 === "Good" ? "Good" : "NeverUsedWithPackaging"
                )
            )
        )
    );
  var value$5 = value.description;
  var description = value$5 !== undefined ? value$5 : null;
  var value$6 = value.listingId;
  var value$7 = value.productId;
  var productId = value$7 !== undefined ? value$7 : null;
  var value$8 = value.id;
  return {
          id: value$8,
          productId: productId,
          listingId: value$6,
          description: description,
          condition: condition,
          belongsToViewer: value$3,
          mainImage: mainImage,
          media: media
        };
}

var Raw = {};

var query$1 = "query PrelovedItemBasicData($id: ID!, $loadMedia: Boolean!)  {\nnode(id: $id)  {\n__typename\n...on PrelovedItem   {\n...PrelovedItemFields   \n}\n\n}\n\n}\n" + query;

function parse$1(value) {
  var value$1 = value.node;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1 = typename === "PrelovedItem" ? ({
          NAME: "PrelovedItem",
          VAL: {
            prelovedItemFields: parse(value$1)
          }
        }) : ({
          NAME: "UnspecifiedFragment",
          VAL: typename
        });
    tmp = tmp$1;
  }
  return {
          node: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.node;
  var node = value$1 !== undefined ? (
      value$1.NAME === "UnspecifiedFragment" ? ({}) : Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                return {};
              }), [serialize(value$1.VAL.prelovedItemFields)])
    ) : null;
  return {
          node: node
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id,
          loadMedia: inp.loadMedia
        };
}

function makePrelovedItem(param) {
  var condition = param.condition;
  return {
          id: MarkotApi__Id.parseExn(param.id),
          productId: Belt_Option.map(param.productId, ShopifyApi__Id.parseExn),
          listingId: MarkotApi__Id.parseExn(param.listingId),
          description: param.description,
          condition: typeof condition === "object" ? Js_exn.raiseError("PrelovedItem.condition unknown: " + condition.VAL) : (
              condition === "NeverUsedWithoutPackaging" ? "NeverUsedWithoutPackaging" : (
                  condition === "WellLoved" ? "WellLoved" : (
                      condition === "AsGoodAsNew" ? "AsGoodAsNew" : (
                          condition === "Good" ? "Good" : "NeverUsedWithPackaging"
                        )
                    )
                )
            ),
          belongsToViewer: param.belongsToViewer,
          mainImage: Belt_Option.map(param.mainImage, MarkotApi__CommonFragments.makeImageResource),
          media: Belt_Option.map(param.media, (function (__x) {
                  return Belt_Array.map(__x, MarkotApi__PrelovedItemMedia.makePrelovedItemMedia);
                }))
        };
}

function fetchBasicData(apiConfig, id, loadMedia) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$1,
                    Raw: Raw,
                    parse: parse$1,
                    serialize: serialize$1,
                    serializeVariables: serializeVariables
                  }), {
                id: id,
                loadMedia: loadMedia
              }, undefined).then(function (param) {
              var node = param.node;
              if (node !== undefined) {
                if (node.NAME === "UnspecifiedFragment") {
                  RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "PrelovedItem fetchBasicData - node of unknown type found. Ignoring.", node);
                  return ;
                } else {
                  return makePrelovedItem(node.VAL.prelovedItemFields);
                }
              } else {
                RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "PrelovedItem fetchBasicData - not found. Id", id);
                return ;
              }
            });
}

export {
  fetchBasicData ,
}
/* MarkotApi__Id Not a pure module */
