/* TypeScript file generated from Ui__Theme.res by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as Curry__Es6Import from 'rescript/lib/es6/curry.js';
const Curry: any = Curry__Es6Import;

// @ts-ignore: Implicit any on import
import * as Ui__ThemeBS__Es6Import from './Ui__Theme.bs';
const Ui__ThemeBS: any = Ui__ThemeBS__Es6Import;

import type {adaptive as DecidrTheme_adaptive} from '@decidr-io/styles/src/theme/DecidrTheme.gen';

// tslint:disable-next-line:interface-over-type-literal
export type typographyVariant = 
    "title1"
  | "title2"
  | "title3"
  | "price1"
  | "price2"
  | "price3"
  | "subtitle"
  | "b1"
  | "b2"
  | "button1"
  | "button2"
  | "caption1"
  | "caption2"
  | { NAME: "unsafe"; VAL: {
  readonly size: number; 
  readonly lineHeight: number; 
  readonly letterSpacingPct: number
} };

// tslint:disable-next-line:max-classes-per-file 
export abstract class Font_t { protected opaque!: any }; /* simulate opaque types */

// tslint:disable-next-line:max-classes-per-file 
export abstract class Typography_Font_t { protected opaque!: any }; /* simulate opaque types */

// tslint:disable-next-line:interface-over-type-literal
export type Typography_t = {
  readonly title1: Typography_Font_t; 
  readonly title2: Typography_Font_t; 
  readonly title3: Typography_Font_t; 
  readonly price1: Typography_Font_t; 
  readonly price2: Typography_Font_t; 
  readonly price3: Typography_Font_t; 
  readonly subtitle: Typography_Font_t; 
  readonly b1: Typography_Font_t; 
  readonly b2: Typography_Font_t; 
  readonly button1: Typography_Font_t; 
  readonly button2: Typography_Font_t; 
  readonly caption1: Typography_Font_t; 
  readonly caption2: Typography_Font_t
};

// tslint:disable-next-line:interface-over-type-literal
export type Typography_variant = typographyVariant;

// tslint:disable-next-line:interface-over-type-literal
export type Typography_adaptiveTypography = DecidrTheme_adaptive<Typography_t>;

// tslint:disable-next-line:interface-over-type-literal
export type SectionSpacing_t = {
  readonly _05: number; 
  readonly _1: number; 
  readonly _2: number; 
  readonly _3: number
};

// tslint:disable-next-line:interface-over-type-literal
export type SectionSpacing_adaptiveSectionSpacing = DecidrTheme_adaptive<SectionSpacing_t>;

// tslint:disable-next-line:interface-over-type-literal
export type Spacing_t = {
  readonly _1: number; 
  readonly _2: number; 
  readonly _3: number; 
  readonly _4: number; 
  readonly _5: number; 
  readonly _6: number; 
  readonly _7: number; 
  readonly _8: number; 
  readonly _10: number; 
  readonly _12: number; 
  readonly _14: number; 
  readonly _16: number; 
  readonly _18: number; 
  readonly _20: number; 
  readonly _22: number; 
  readonly _24: number; 
  readonly _26: number; 
  readonly _28: number; 
  readonly _30: number; 
  readonly _32: number; 
  readonly _34: number; 
  readonly _36: number; 
  readonly _38: number; 
  readonly _40: number; 
  readonly _43: number; 
  readonly _46: number; 
  readonly _50: number
};

// tslint:disable-next-line:interface-over-type-literal
export type Spacing_adaptiveSpacing = DecidrTheme_adaptive<Spacing_t>;

// tslint:disable-next-line:interface-over-type-literal
export type Colors_t = {
  readonly primary: string; 
  readonly onPrimary: string; 
  readonly primaryShade1: string; 
  readonly onPrimaryShade1: string; 
  readonly primaryShade2: string; 
  readonly onPrimaryShade2: string; 
  readonly primaryShade3: string; 
  readonly onPrimaryShade3: string; 
  readonly primaryShade4: string; 
  readonly onPrimaryShade4: string; 
  readonly primaryVariant: string; 
  readonly onPrimaryVariant: string; 
  readonly primaryVariantShade1: string; 
  readonly onPrimaryVariantShade1: string; 
  readonly secondary: string; 
  readonly onSecondary: string; 
  readonly secondaryShade1: string; 
  readonly onSecondaryShade1: string; 
  readonly secondaryVariant: string; 
  readonly onSecondaryVariant: string; 
  readonly secondaryVariantShade1: string; 
  readonly onSecondaryVariantShade1: string; 
  readonly secondaryVariant2: string; 
  readonly onSecondaryVariant2: string; 
  readonly secondaryVariant3: string; 
  readonly onSecondaryVariant3: string; 
  readonly secondaryVariant4: string; 
  readonly onSecondaryVariant4: string; 
  readonly background: string; 
  readonly onBackground: string; 
  readonly backgroundVariant: string; 
  readonly onBackgroundVariant: string; 
  readonly backgroundVariant2: string; 
  readonly onBackgroundVariant2: string; 
  readonly backgroundVariant2Shade1: string; 
  readonly onBackgroundVariant2Shade1: string; 
  readonly onBackgroundShade: string; 
  readonly surface: string; 
  readonly onSurface: string; 
  readonly info: string; 
  readonly onInfo: string; 
  readonly infoShade1: string; 
  readonly onInfoShade1: string; 
  readonly infoShade2: string; 
  readonly onInfoShade2: string; 
  readonly infoShade3: string; 
  readonly onInfoShade3: string; 
  readonly infoShade4: string; 
  readonly onInfoShade4: string; 
  readonly success: string; 
  readonly onSuccess: string; 
  readonly successShade1: string; 
  readonly onSuccessShade1: string; 
  readonly successShade2: string; 
  readonly onSuccessShade2: string; 
  readonly successShade3: string; 
  readonly onSuccessShade3: string; 
  readonly successShade4: string; 
  readonly onSuccessShade4: string; 
  readonly error: string; 
  readonly onError: string; 
  readonly errorShade1: string; 
  readonly onErrorShade1: string; 
  readonly errorShade2: string; 
  readonly onErrorShade2: string; 
  readonly errorShade3: string; 
  readonly onErrorShade3: string; 
  readonly errorShade4: string; 
  readonly onErrorShade4: string
};

// tslint:disable-next-line:interface-over-type-literal
export type Roundness_t = {
  readonly _1: number; 
  readonly _2: number; 
  readonly _3: number; 
  readonly _4: number; 
  readonly _5: number; 
  readonly _6: number; 
  readonly _7: number; 
  readonly _8: number; 
  readonly _9: number; 
  readonly _10: number; 
  readonly _11: number; 
  readonly _12: number; 
  readonly _13: number; 
  readonly _14: number; 
  readonly circle: number
};

// tslint:disable-next-line:interface-over-type-literal
export type LineThickness_t = {
  readonly xxs: number; 
  readonly xs: number; 
  readonly s: number; 
  readonly m: number; 
  readonly l: number; 
  readonly xl: number
};

// tslint:disable-next-line:interface-over-type-literal
export type FontFamily_t = { readonly libre: string; readonly dm: string };

// tslint:disable-next-line:interface-over-type-literal
export type theme = {
  readonly isDark: boolean; 
  readonly colors: Colors_t; 
  readonly spacing: Spacing_t; 
  readonly roundness: Roundness_t; 
  readonly lineThickness: LineThickness_t; 
  readonly fontFamily: FontFamily_t; 
  readonly typography: Typography_t; 
  readonly sectionSpacing: SectionSpacing_t
};

// tslint:disable-next-line:interface-over-type-literal
export type Defaults_config = {
  readonly isDark: boolean; 
  readonly colors: Colors_t; 
  readonly adaptiveSpacing: Spacing_adaptiveSpacing; 
  readonly adaptiveRoundness: Roundness_t; 
  readonly adaptiveLineThickness: LineThickness_t; 
  readonly fontFamily: FontFamily_t; 
  readonly adaptiveTypography: Typography_adaptiveTypography; 
  readonly adaptiveSectionSpacing: SectionSpacing_adaptiveSectionSpacing
};
export type Config = Defaults_config;

// tslint:disable-next-line:interface-over-type-literal
export type themeConfigSet = { readonly light: Defaults_config; readonly dark: Defaults_config };
export type ThemeConfigSet = themeConfigSet;

// tslint:disable-next-line:interface-over-type-literal
export type Theme_t = theme;

// tslint:disable-next-line:interface-over-type-literal
export type Theme_config = Defaults_config;

// tslint:disable-next-line:interface-over-type-literal
export type Context_themeContextData = { readonly theme: Theme_t; readonly toggleTheme: () => void };

// tslint:disable-next-line:interface-over-type-literal
export type themeContextData = Context_themeContextData;
export type ThemeContextData = themeContextData;

// tslint:disable-next-line:interface-over-type-literal
export type themeConfig = Defaults_config;

export const Font_unsafeMake: (_1:{
  readonly size: number; 
  readonly lineHeight: number; 
  readonly letterSpacingPct: number
}) => Font_t = function (Arg1: any) {
  const result = Curry._3(Ui__ThemeBS.Font.unsafeMake, Arg1.size, Arg1.lineHeight, Arg1.letterSpacingPct);
  return result
};

export const Typography_Font_unsafeMake: (_1:{
  readonly size: number; 
  readonly lineHeight: number; 
  readonly letterSpacingPct: number
}) => Typography_Font_t = function (Arg1: any) {
  const result = Curry._3(Ui__ThemeBS.Typography.Typography.unsafeMake, Arg1.size, Arg1.lineHeight, Arg1.letterSpacingPct);
  return result
};

export const Colors_colors: Colors_t = Ui__ThemeBS.Colors.colors;

export const Theme_fallbackTheme: theme = Ui__ThemeBS.Theme.fallbackTheme;

export const useTheme: () => Context_themeContextData = Ui__ThemeBS.useTheme;

// tslint:disable-next-line:interface-over-type-literal
export type themeProvider_Props = {
  readonly children: React.ReactNode; 
  readonly themeConfig: Theme_config; 
  readonly toggleTheme: () => void
};

export const themeProvider: React.ComponentType<{
  readonly children: React.ReactNode; 
  readonly themeConfig: Theme_config; 
  readonly toggleTheme: () => void
}> = Ui__ThemeBS.themeProvider;

export const themeConsumer: <T1>(_1:{ readonly children: ((_1:Context_themeContextData) => T1) }) => T1 = Ui__ThemeBS.themeConsumer;

export const themeConfigSet: themeConfigSet = Ui__ThemeBS.themeConfigSet;

export const fallbackTheme: theme = Ui__ThemeBS.fallbackTheme;

export const Colors: { colors: Colors_t } = Ui__ThemeBS.Colors

export const Theme: { fallbackTheme: theme } = Ui__ThemeBS.Theme
