// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Index from "./index";

function getShopifyInternalId(prim) {
  return Index.getShopifyInternalId(prim);
}

export {
  getShopifyInternalId ,
}
/* ./index Not a pure module */
