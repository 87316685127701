// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MarkotApi__ShopifyCustomer from "../../../api-adapter-markot/src/MarkotApi__ShopifyCustomer.bs.js";

function createAccessToken(apiConfig) {
  return MarkotApi__ShopifyCustomer.createShopifyCustomerAccessToken(apiConfig).then(function (param) {
              return param.customerAccessToken;
            });
}

export {
  createAccessToken ,
}
/* MarkotApi__ShopifyCustomer Not a pure module */
