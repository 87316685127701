// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "../../../api-adapter-markot/src/MarkotApi__Id.bs.js";
import * as GraphqlCore__Types from "../../../graphql-core/src/GraphqlCore__Types.bs.js";
import * as MarkotApi__Listing from "../../../api-adapter-markot/src/MarkotApi__Listing.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Entity__PrelovedItemMedia from "./GqlMiddleware__Entity__PrelovedItemMedia.bs.js";
import * as GqlMiddleware__Entity__PrelovedItemCondition from "./GqlMiddleware__Entity__PrelovedItemCondition.bs.js";

function makeListing(param) {
  var status = param.status;
  var tmp;
  if (typeof status === "number") {
    switch (status) {
      case /* ListingAwaitingReview */0 :
          tmp = {
            TAG: /* ListingAwaitingReview */0,
            __typename: "ListingAwaitingReview"
          };
          break;
      case /* ListingInReview */1 :
          tmp = {
            TAG: /* ListingInReview */1,
            __typename: "ListingInReview"
          };
          break;
      case /* ListingApproved */2 :
          tmp = {
            TAG: /* ListingApproved */3,
            __typename: "ListingApproved"
          };
          break;
      
    }
  } else {
    switch (status.TAG | 0) {
      case /* ListingNeedsChanges */0 :
          var match = status.requiredChanges;
          tmp = {
            TAG: /* ListingNeedsChanges */2,
            __typename: "ListingNeedsChanges",
            requiredChanges: {
              id: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(match.id)),
              generalIssue: match.generalIssue,
              conditionIssue: match.conditionIssue,
              mediaIssue: match.mediaIssue,
              descriptionIssue: match.descriptionIssue,
              priceIssue: match.priceIssue,
              listingAndHandlingFeeIssue: match.listingAndHandlingFeeIssue,
              pickupAddressIssue: match.pickupAddressIssue,
              billingAddressIssue: match.billingAddressIssue
            }
          };
          break;
      case /* ListingRejected */1 :
          var match$1 = status.reasons;
          tmp = {
            TAG: /* ListingRejected */4,
            __typename: "ListingRejected",
            reasons: {
              id: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(match$1.id)),
              generalIssue: match$1.generalIssue,
              conditionIssue: match$1.conditionIssue,
              mediaIssue: match$1.mediaIssue,
              descriptionIssue: match$1.descriptionIssue,
              priceIssue: match$1.priceIssue,
              listingAndHandlingFeeIssue: match$1.listingAndHandlingFeeIssue,
              pickupAddressIssue: match$1.pickupAddressIssue,
              billingAddressIssue: match$1.billingAddressIssue
            }
          };
          break;
      case /* ListingSold */2 :
          tmp = {
            TAG: /* ListingSold */5,
            __typename: "ListingSold",
            orderFlowStatus: status.orderFlowStatus
          };
          break;
      case /* ListingDeleted */3 :
          tmp = {
            TAG: /* ListingDeleted */6,
            __typename: "ListingDeleted",
            reason: status.reason,
            deletedAt: status.deletedAt
          };
          break;
      
    }
  }
  return {
          __typename: "Listing",
          id: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.id)),
          prelovedItemInfoId: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.prelovedItemInfoId)),
          submittedAt: param.submittedAt,
          status: tmp,
          pickupAddressId: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.pickupAddressId)),
          billingAddressId: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.billingAddressId)),
          product: Belt_Option.map(param.product, (function (product) {
                  if (product.NAME !== "SuggestedProductInfo") {
                    return {
                            TAG: /* ProductInfo */0,
                            _0: {
                              __typename: "ProductInfo",
                              productId: GqlMiddleware__Core__NodeId.make(1, "Product", MarkotApi__Id.unpackExn(product.VAL.productId).internalId)
                            }
                          };
                  }
                  var match = product.VAL;
                  return {
                          TAG: /* SuggestedProductInfo */1,
                          _0: {
                            __typename: "SuggestedProductInfo",
                            productName: match.productName,
                            brandName: match.brandName
                          }
                        };
                }))
        };
}

function makeListingId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "Listing", param);
}

function fetchListing(apiConfig, id) {
  return MarkotApi__Listing.fetchBasicData(apiConfig, GqlMiddleware__Core__NodeId.toString(id)).then(function (__x) {
              return Belt_Option.map(__x, makeListing);
            });
}

function fetchListings(apiConfig, first, after, status) {
  return MarkotApi__Listing.fetchListings(apiConfig, first, after, status).then(function (__x) {
              return GraphqlCore__Types.ConnectionWithTotal.map(__x, makeListing);
            });
}

function executeUpdateListing(apiConfig, param) {
  return MarkotApi__Listing.executeUpdateListing(apiConfig, {
                id: MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(param.id)),
                buyNowPrice: param.buyNowPrice,
                condition: Belt_Option.flatMap(param.condition, GqlMiddleware__Entity__PrelovedItemCondition.toMarkotApi),
                description: param.description,
                pickupAddressId: Belt_Option.map(Belt_Option.map(param.pickupAddressId, GqlMiddleware__Core__NodeId.toString), MarkotApi__Id.parseExn),
                billingAddressId: Belt_Option.map(Belt_Option.map(param.billingAddressId, GqlMiddleware__Core__NodeId.toString), MarkotApi__Id.parseExn)
              }).then(makeListing);
}

function executeDeleteListing(apiConfig, id) {
  return MarkotApi__Listing.executeDeleteListing(apiConfig, id).then(function (param) {
              return {
                      __typename: "DeleteListingPayload",
                      deletedListingId: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.deletedListingId))
                    };
            });
}

function executeUploadListingMedia(apiConfig, listingId, mediaFileInBase64, mediaTag) {
  return MarkotApi__Listing.executeUploadListingMedia(apiConfig, MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(listingId)), mediaFileInBase64, mediaTag).then(function (param) {
              return {
                      __typename: "UploadListingMediaPayload",
                      media: GqlMiddleware__Entity__PrelovedItemMedia.makePrelovedItemMedia(param.media),
                      listing: makeListing(param.listing)
                    };
            });
}

function executeDeleteListingMedia(apiConfig, id) {
  return MarkotApi__Listing.executeDeleteListingMedia(apiConfig, id).then(function (param) {
              return {
                      __typename: "DeleteListingMediaPayload",
                      mediaId: GqlMiddleware__Core__NodeId.parseExn(MarkotApi__Id.toString(param.mediaId))
                    };
            });
}

function executeSubmitListingForApproval(apiConfig, id) {
  return MarkotApi__Listing.executeSubmitListingForApproval(apiConfig, MarkotApi__Id.parseExn(GqlMiddleware__Core__NodeId.toString(id))).then(makeListing);
}

export {
  makeListingId ,
  makeListing ,
  fetchListing ,
  fetchListings ,
  executeUpdateListing ,
  executeSubmitListingForApproval ,
  executeUploadListingMedia ,
  executeDeleteListingMedia ,
  executeDeleteListing ,
}
/* MarkotApi__Id Not a pure module */
