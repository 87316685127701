// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Index from "./index";

function toLocaleString(prim0, prim1) {
  return Index.toLocaleString(prim0, prim1);
}

export {
  toLocaleString ,
}
/* ./index Not a pure module */
