// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as GraphQL_PPX from "@reasonml-community/graphql-ppx/bucklescript/GraphQL_PPX.bs.js";
import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as MarkotApi__Infra from "./MarkotApi__Infra.bs.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as MarkotApi__PrelovedItemMedia from "./MarkotApi__PrelovedItemMedia.bs.js";
import * as MarkotApi__Scalars__DateTime from "./MarkotApi__Scalars__DateTime.bs.js";

var Raw = {};

var query = "fragment ReviewFields on Review   {\nid  \ngeneralIssue  \nconditionIssue  \nmediaIssue  \ndescriptionIssue  \npriceIssue  \nlistingAndHandlingFeeIssue  \npickupAddressIssue  \nbillingAddressIssue  \n}\n";

function parse(value) {
  var value$1 = value.generalIssue;
  var value$2 = value.conditionIssue;
  var value$3 = value.mediaIssue;
  var value$4 = value.descriptionIssue;
  var value$5 = value.priceIssue;
  var value$6 = value.listingAndHandlingFeeIssue;
  var value$7 = value.pickupAddressIssue;
  var value$8 = value.billingAddressIssue;
  return {
          id: value.id,
          generalIssue: !(value$1 == null) ? value$1 : undefined,
          conditionIssue: !(value$2 == null) ? value$2 : undefined,
          mediaIssue: !(value$3 == null) ? value$3 : undefined,
          descriptionIssue: !(value$4 == null) ? value$4 : undefined,
          priceIssue: !(value$5 == null) ? value$5 : undefined,
          listingAndHandlingFeeIssue: !(value$6 == null) ? value$6 : undefined,
          pickupAddressIssue: !(value$7 == null) ? value$7 : undefined,
          billingAddressIssue: !(value$8 == null) ? value$8 : undefined
        };
}

function serialize(value) {
  var value$1 = value.billingAddressIssue;
  var billingAddressIssue = value$1 !== undefined ? value$1 : null;
  var value$2 = value.pickupAddressIssue;
  var pickupAddressIssue = value$2 !== undefined ? value$2 : null;
  var value$3 = value.listingAndHandlingFeeIssue;
  var listingAndHandlingFeeIssue = value$3 !== undefined ? value$3 : null;
  var value$4 = value.priceIssue;
  var priceIssue = value$4 !== undefined ? value$4 : null;
  var value$5 = value.descriptionIssue;
  var descriptionIssue = value$5 !== undefined ? value$5 : null;
  var value$6 = value.mediaIssue;
  var mediaIssue = value$6 !== undefined ? value$6 : null;
  var value$7 = value.conditionIssue;
  var conditionIssue = value$7 !== undefined ? value$7 : null;
  var value$8 = value.generalIssue;
  var generalIssue = value$8 !== undefined ? value$8 : null;
  var value$9 = value.id;
  return {
          id: value$9,
          generalIssue: generalIssue,
          conditionIssue: conditionIssue,
          mediaIssue: mediaIssue,
          descriptionIssue: descriptionIssue,
          priceIssue: priceIssue,
          listingAndHandlingFeeIssue: listingAndHandlingFeeIssue,
          pickupAddressIssue: pickupAddressIssue,
          billingAddressIssue: billingAddressIssue
        };
}

function verifyArgsAndParse(_ReviewFields, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var Raw$1 = {};

var query$1 = "fragment ListingFields on Listing   {\nid  \nprelovedItemInfoId  \nsubmittedAt  \nstatus  {\n__typename\n...on ListingAwaitingReview   {\n__typename  \n}\n\n...on ListingInReview   {\n__typename  \n}\n\n...on ListingNeedsChanges   {\nrequiredChanges  {\n...ReviewFields   \n}\n\n}\n\n...on ListingApproved   {\n__typename  \n}\n\n...on ListingRejected   {\nreasons  {\n...ReviewFields   \n}\n\n}\n\n...on ListingSold   {\norderFlowStatus  \n}\n\n...on ListingDeleted   {\nreason  \ndeletedAt  \n}\n\n}\n\npickupAddressId  \nbillingAddressId  \nproduct  {\n__typename\n...on ProductInfo   {\nproductId  \n}\n\n...on SuggestedProductInfo   {\nproductName  \nbrandName  \n}\n\n}\n\n}\nfragment ReviewFields on Review   {\nid  \ngeneralIssue  \nconditionIssue  \nmediaIssue  \ndescriptionIssue  \npriceIssue  \nlistingAndHandlingFeeIssue  \npickupAddressIssue  \nbillingAddressIssue  \n}\n";

function parse$1(value) {
  var value$1 = value.status;
  var typename = value$1["__typename"];
  var tmp;
  switch (typename) {
    case "ListingApproved" :
        tmp = {
          NAME: "ListingApproved",
          VAL: {
            __typename: value$1.__typename
          }
        };
        break;
    case "ListingAwaitingReview" :
        tmp = {
          NAME: "ListingAwaitingReview",
          VAL: {
            __typename: value$1.__typename
          }
        };
        break;
    case "ListingDeleted" :
        tmp = {
          NAME: "ListingDeleted",
          VAL: {
            reason: value$1.reason,
            deletedAt: MarkotApi__Scalars__DateTime.parse(value$1.deletedAt)
          }
        };
        break;
    case "ListingInReview" :
        tmp = {
          NAME: "ListingInReview",
          VAL: {
            __typename: value$1.__typename
          }
        };
        break;
    case "ListingNeedsChanges" :
        tmp = {
          NAME: "ListingNeedsChanges",
          VAL: {
            requiredChanges: parse(value$1.requiredChanges)
          }
        };
        break;
    case "ListingRejected" :
        tmp = {
          NAME: "ListingRejected",
          VAL: {
            reasons: parse(value$1.reasons)
          }
        };
        break;
    case "ListingSold" :
        var value$2 = value$1.orderFlowStatus;
        var tmp$1;
        switch (value$2) {
          case "MarkotBoxSent" :
              tmp$1 = "MarkotBoxSent";
              break;
          case "MarkotItemAccepted" :
              tmp$1 = "MarkotItemAccepted";
              break;
          case "MarkotItemDeclined" :
              tmp$1 = "MarkotItemDeclined";
              break;
          case "MarkotProcessing" :
              tmp$1 = "MarkotProcessing";
              break;
          case "SellerScheduledAPickup" :
              tmp$1 = "SellerScheduledAPickup";
              break;
          default:
            tmp$1 = {
              NAME: "FutureAddedValue",
              VAL: value$2
            };
        }
        tmp = {
          NAME: "ListingSold",
          VAL: {
            orderFlowStatus: tmp$1
          }
        };
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$1
      };
  }
  var value$3 = value.product;
  var tmp$2;
  if (value$3 == null) {
    tmp$2 = undefined;
  } else {
    var typename$1 = value$3["__typename"];
    var tmp$3;
    switch (typename$1) {
      case "ProductInfo" :
          tmp$3 = {
            NAME: "ProductInfo",
            VAL: {
              productId: value$3.productId
            }
          };
          break;
      case "SuggestedProductInfo" :
          tmp$3 = {
            NAME: "SuggestedProductInfo",
            VAL: {
              productName: value$3.productName,
              brandName: value$3.brandName
            }
          };
          break;
      default:
        tmp$3 = {
          NAME: "FutureAddedValue",
          VAL: value$3
        };
    }
    tmp$2 = tmp$3;
  }
  return {
          id: value.id,
          prelovedItemInfoId: value.prelovedItemInfoId,
          submittedAt: MarkotApi__Scalars__DateTime.parse(value.submittedAt),
          status: tmp,
          pickupAddressId: value.pickupAddressId,
          billingAddressId: value.billingAddressId,
          product: tmp$2
        };
}

function serialize$1(value) {
  var value$1 = value.product;
  var product;
  if (value$1 !== undefined) {
    var variant = value$1.NAME;
    if (variant === "SuggestedProductInfo") {
      var value$2 = value$1.VAL;
      var value$3 = value$2.brandName;
      var value$4 = value$2.productName;
      product = {
        __typename: "SuggestedProductInfo",
        productName: value$4,
        brandName: value$3
      };
    } else if (variant === "FutureAddedValue") {
      product = value$1.VAL;
    } else {
      var value$5 = value$1.VAL.productId;
      product = {
        __typename: "ProductInfo",
        productId: value$5
      };
    }
  } else {
    product = null;
  }
  var value$6 = value.billingAddressId;
  var value$7 = value.pickupAddressId;
  var value$8 = value.status;
  var variant$1 = value$8.NAME;
  var status;
  if (variant$1 === "ListingNeedsChanges") {
    var value$9 = value$8.VAL.requiredChanges;
    var requiredChanges = serialize(value$9);
    status = {
      __typename: "ListingNeedsChanges",
      requiredChanges: requiredChanges
    };
  } else if (variant$1 === "ListingAwaitingReview") {
    status = {
      __typename: "ListingAwaitingReview"
    };
  } else if (variant$1 === "ListingApproved") {
    status = {
      __typename: "ListingApproved"
    };
  } else if (variant$1 === "ListingInReview") {
    status = {
      __typename: "ListingInReview"
    };
  } else if (variant$1 === "FutureAddedValue") {
    status = value$8.VAL;
  } else if (variant$1 === "ListingRejected") {
    var value$10 = value$8.VAL.reasons;
    var reasons = serialize(value$10);
    status = {
      __typename: "ListingRejected",
      reasons: reasons
    };
  } else if (variant$1 === "ListingDeleted") {
    var value$11 = value$8.VAL;
    var value$12 = value$11.deletedAt;
    var value$13 = MarkotApi__Scalars__DateTime.serialize(value$12);
    var value$14 = value$11.reason;
    status = {
      __typename: "ListingDeleted",
      reason: value$14,
      deletedAt: value$13
    };
  } else {
    var value$15 = value$8.VAL.orderFlowStatus;
    var orderFlowStatus = typeof value$15 === "object" ? value$15.VAL : (
        value$15 === "MarkotBoxSent" ? "MarkotBoxSent" : (
            value$15 === "SellerScheduledAPickup" ? "SellerScheduledAPickup" : (
                value$15 === "MarkotItemAccepted" ? "MarkotItemAccepted" : (
                    value$15 === "MarkotItemDeclined" ? "MarkotItemDeclined" : "MarkotProcessing"
                  )
              )
          )
      );
    status = {
      __typename: "ListingSold",
      orderFlowStatus: orderFlowStatus
    };
  }
  var value$16 = value.submittedAt;
  var value$17 = MarkotApi__Scalars__DateTime.serialize(value$16);
  var value$18 = value.prelovedItemInfoId;
  var value$19 = value.id;
  return {
          id: value$19,
          prelovedItemInfoId: value$18,
          submittedAt: value$17,
          status: status,
          pickupAddressId: value$7,
          billingAddressId: value$6,
          product: product
        };
}

function verifyArgsAndParse$1(_ListingFields, value) {
  return parse$1(value);
}

function verifyName$1(param) {
  
}

var Raw$2 = {};

var query$2 = "query ListingBasicData($id: ID!)  {\nnode(id: $id)  {\n__typename\n...on Listing   {\n...ListingFields   \n}\n\n}\n\n}\nfragment ListingFields on Listing   {\nid  \nprelovedItemInfoId  \nsubmittedAt  \nstatus  {\n__typename\n...on ListingAwaitingReview   {\n__typename  \n}\n\n...on ListingInReview   {\n__typename  \n}\n\n...on ListingNeedsChanges   {\nrequiredChanges  {\n...ReviewFields   \n}\n\n}\n\n...on ListingApproved   {\n__typename  \n}\n\n...on ListingRejected   {\nreasons  {\n...ReviewFields   \n}\n\n}\n\n...on ListingSold   {\norderFlowStatus  \n}\n\n...on ListingDeleted   {\nreason  \ndeletedAt  \n}\n\n}\n\npickupAddressId  \nbillingAddressId  \nproduct  {\n__typename\n...on ProductInfo   {\nproductId  \n}\n\n...on SuggestedProductInfo   {\nproductName  \nbrandName  \n}\n\n}\n\n}\nfragment ReviewFields on Review   {\nid  \ngeneralIssue  \nconditionIssue  \nmediaIssue  \ndescriptionIssue  \npriceIssue  \nlistingAndHandlingFeeIssue  \npickupAddressIssue  \nbillingAddressIssue  \n}\n";

function parse$2(value) {
  var value$1 = value.node;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1 = typename === "Listing" ? ({
          NAME: "Listing",
          VAL: {
            listingFields: parse$1(value$1)
          }
        }) : ({
          NAME: "UnspecifiedFragment",
          VAL: typename
        });
    tmp = tmp$1;
  }
  return {
          node: tmp
        };
}

function serialize$2(value) {
  var value$1 = value.node;
  var node = value$1 !== undefined ? (
      value$1.NAME === "UnspecifiedFragment" ? ({}) : Js_array.reduce(GraphQL_PPX.deepMerge, (function (prim) {
                return {};
              }), [serialize$1(value$1.VAL.listingFields)])
    ) : null;
  return {
          node: node
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

var Raw$3 = {};

var query$3 = "query Listings($first: Int, $after: String, $status: ListingsStatusFilter!)  {\nviewer  {\nlistings(first: $first, after: $after, status: $status)  {\ntotalItems  \npageInfo  {\nstartCursor  \nendCursor  \nhasPreviousPage  \nhasNextPage  \n}\n\nedges  {\ncursor  \nnode  {\n...ListingFields   \n}\n\n}\n\n}\n\n}\n\n}\nfragment ListingFields on Listing   {\nid  \nprelovedItemInfoId  \nsubmittedAt  \nstatus  {\n__typename\n...on ListingAwaitingReview   {\n__typename  \n}\n\n...on ListingInReview   {\n__typename  \n}\n\n...on ListingNeedsChanges   {\nrequiredChanges  {\n...ReviewFields   \n}\n\n}\n\n...on ListingApproved   {\n__typename  \n}\n\n...on ListingRejected   {\nreasons  {\n...ReviewFields   \n}\n\n}\n\n...on ListingSold   {\norderFlowStatus  \n}\n\n...on ListingDeleted   {\nreason  \ndeletedAt  \n}\n\n}\n\npickupAddressId  \nbillingAddressId  \nproduct  {\n__typename\n...on ProductInfo   {\nproductId  \n}\n\n...on SuggestedProductInfo   {\nproductName  \nbrandName  \n}\n\n}\n\n}\nfragment ReviewFields on Review   {\nid  \ngeneralIssue  \nconditionIssue  \nmediaIssue  \ndescriptionIssue  \npriceIssue  \nlistingAndHandlingFeeIssue  \npickupAddressIssue  \nbillingAddressIssue  \n}\n";

function parse$3(value) {
  var value$1 = value.viewer;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.listings;
    var value$3 = value$2.pageInfo;
    var value$4 = value$3.startCursor;
    var value$5 = value$3.endCursor;
    var value$6 = value$2.edges;
    tmp = {
      listings: {
        totalItems: value$2.totalItems,
        pageInfo: {
          startCursor: !(value$4 == null) ? value$4 : undefined,
          endCursor: !(value$5 == null) ? value$5 : undefined,
          hasPreviousPage: value$3.hasPreviousPage,
          hasNextPage: value$3.hasNextPage
        },
        edges: Js_array.map((function (value) {
                return {
                        cursor: value.cursor,
                        node: parse$1(value.node)
                      };
              }), value$6)
      }
    };
  }
  return {
          viewer: tmp
        };
}

function serialize$3(value) {
  var value$1 = value.viewer;
  var viewer;
  if (value$1 !== undefined) {
    var value$2 = value$1.listings;
    var value$3 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var node = serialize$1(value$1);
            var value$2 = value.cursor;
            return {
                    cursor: value$2,
                    node: node
                  };
          }), value$3);
    var value$4 = value$2.pageInfo;
    var value$5 = value$4.hasNextPage;
    var value$6 = value$4.hasPreviousPage;
    var value$7 = value$4.endCursor;
    var endCursor = value$7 !== undefined ? value$7 : null;
    var value$8 = value$4.startCursor;
    var startCursor = value$8 !== undefined ? value$8 : null;
    var pageInfo = {
      startCursor: startCursor,
      endCursor: endCursor,
      hasPreviousPage: value$6,
      hasNextPage: value$5
    };
    var value$9 = value$2.totalItems;
    var listings = {
      totalItems: value$9,
      pageInfo: pageInfo,
      edges: edges
    };
    viewer = {
      listings: listings
    };
  } else {
    viewer = null;
  }
  return {
          viewer: viewer
        };
}

function serializeVariables$1(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  var a$2 = inp.status;
  return {
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined,
          status: a$2 === "Deleted" ? "Deleted" : (
              a$2 === "Rejected" ? "Rejected" : (
                  a$2 === "Live" ? "Live" : (
                      a$2 === "Sold" ? "Sold" : "Submitted"
                    )
                )
            )
        };
}

var Raw$4 = {};

var query$4 = "mutation UpdateListing($input: UpdateListingInput!)  {\nupdateListing(input: $input)  {\n...ListingFields   \n}\n\n}\nfragment ListingFields on Listing   {\nid  \nprelovedItemInfoId  \nsubmittedAt  \nstatus  {\n__typename\n...on ListingAwaitingReview   {\n__typename  \n}\n\n...on ListingInReview   {\n__typename  \n}\n\n...on ListingNeedsChanges   {\nrequiredChanges  {\n...ReviewFields   \n}\n\n}\n\n...on ListingApproved   {\n__typename  \n}\n\n...on ListingRejected   {\nreasons  {\n...ReviewFields   \n}\n\n}\n\n...on ListingSold   {\norderFlowStatus  \n}\n\n...on ListingDeleted   {\nreason  \ndeletedAt  \n}\n\n}\n\npickupAddressId  \nbillingAddressId  \nproduct  {\n__typename\n...on ProductInfo   {\nproductId  \n}\n\n...on SuggestedProductInfo   {\nproductName  \nbrandName  \n}\n\n}\n\n}\nfragment ReviewFields on Review   {\nid  \ngeneralIssue  \nconditionIssue  \nmediaIssue  \ndescriptionIssue  \npriceIssue  \nlistingAndHandlingFeeIssue  \npickupAddressIssue  \nbillingAddressIssue  \n}\n";

function parse$4(value) {
  return {
          updateListing: parse$1(value.updateListing)
        };
}

function serialize$4(value) {
  var value$1 = value.updateListing;
  var updateListing = serialize$1(value$1);
  return {
          updateListing: updateListing
        };
}

function serializeInputObjectUpdateListingInput(inp) {
  var a = inp.buyNowPrice;
  var a$1 = inp.condition;
  var a$2 = inp.listingAndHandlingFeeId;
  var a$3 = inp.description;
  var a$4 = inp.pickupAddressId;
  var a$5 = inp.billingAddressId;
  return {
          id: inp.id,
          buyNowPrice: a !== undefined ? a : undefined,
          condition: a$1 !== undefined ? (
              a$1 === "NeverUsedWithoutPackaging" ? "NeverUsedWithoutPackaging" : (
                  a$1 === "WellLoved" ? "WellLoved" : (
                      a$1 === "AsGoodAsNew" ? "AsGoodAsNew" : (
                          a$1 === "Good" ? "Good" : "NeverUsedWithPackaging"
                        )
                    )
                )
            ) : undefined,
          listingAndHandlingFeeId: a$2 !== undefined ? a$2 : undefined,
          description: a$3 !== undefined ? a$3 : undefined,
          pickupAddressId: a$4 !== undefined ? a$4 : undefined,
          billingAddressId: a$5 !== undefined ? a$5 : undefined
        };
}

function serializeVariables$2(inp) {
  return {
          input: serializeInputObjectUpdateListingInput(inp.input)
        };
}

var Raw$5 = {};

var query$5 = "mutation SubmitListingForApproval($id: ID!)  {\nsubmitListingForApproval(listingId: $id)  {\n...ListingFields   \n}\n\n}\nfragment ListingFields on Listing   {\nid  \nprelovedItemInfoId  \nsubmittedAt  \nstatus  {\n__typename\n...on ListingAwaitingReview   {\n__typename  \n}\n\n...on ListingInReview   {\n__typename  \n}\n\n...on ListingNeedsChanges   {\nrequiredChanges  {\n...ReviewFields   \n}\n\n}\n\n...on ListingApproved   {\n__typename  \n}\n\n...on ListingRejected   {\nreasons  {\n...ReviewFields   \n}\n\n}\n\n...on ListingSold   {\norderFlowStatus  \n}\n\n...on ListingDeleted   {\nreason  \ndeletedAt  \n}\n\n}\n\npickupAddressId  \nbillingAddressId  \nproduct  {\n__typename\n...on ProductInfo   {\nproductId  \n}\n\n...on SuggestedProductInfo   {\nproductName  \nbrandName  \n}\n\n}\n\n}\nfragment ReviewFields on Review   {\nid  \ngeneralIssue  \nconditionIssue  \nmediaIssue  \ndescriptionIssue  \npriceIssue  \nlistingAndHandlingFeeIssue  \npickupAddressIssue  \nbillingAddressIssue  \n}\n";

function parse$5(value) {
  return {
          submitListingForApproval: parse$1(value.submitListingForApproval)
        };
}

function serialize$5(value) {
  var value$1 = value.submitListingForApproval;
  var submitListingForApproval = serialize$1(value$1);
  return {
          submitListingForApproval: submitListingForApproval
        };
}

function serializeVariables$3(inp) {
  return {
          id: inp.id
        };
}

var Raw$6 = {};

function parse$6(value) {
  var value$1 = value.deleteListing;
  return {
          deleteListing: {
            deletedListingId: value$1.deletedListingId
          }
        };
}

function serialize$6(value) {
  var value$1 = value.deleteListing;
  var value$2 = value$1.deletedListingId;
  var deleteListing = {
    deletedListingId: value$2
  };
  return {
          deleteListing: deleteListing
        };
}

function serializeVariables$4(inp) {
  return {
          id: inp.id
        };
}

var Raw$7 = {};

var query$6 = "mutation UploadListingMedia($listingId: ID!, $mediaFileInBase64: String!, $mediaTag: String)  {\nuploadListingMedia(listingId: $listingId, mediaFileInBase64: $mediaFileInBase64, mediaTag: $mediaTag)  {\nmedia  {\n...PrelovedItemMediaFields   \n}\n\nlisting  {\n...ListingFields   \n}\n\n}\n\n}\nfragment ListingFields on Listing   {\nid  \nprelovedItemInfoId  \nsubmittedAt  \nstatus  {\n__typename\n...on ListingAwaitingReview   {\n__typename  \n}\n\n...on ListingInReview   {\n__typename  \n}\n\n...on ListingNeedsChanges   {\nrequiredChanges  {\n...ReviewFields   \n}\n\n}\n\n...on ListingApproved   {\n__typename  \n}\n\n...on ListingRejected   {\nreasons  {\n...ReviewFields   \n}\n\n}\n\n...on ListingSold   {\norderFlowStatus  \n}\n\n...on ListingDeleted   {\nreason  \ndeletedAt  \n}\n\n}\n\npickupAddressId  \nbillingAddressId  \nproduct  {\n__typename\n...on ProductInfo   {\nproductId  \n}\n\n...on SuggestedProductInfo   {\nproductName  \nbrandName  \n}\n\n}\n\n}\nfragment ReviewFields on Review   {\nid  \ngeneralIssue  \nconditionIssue  \nmediaIssue  \ndescriptionIssue  \npriceIssue  \nlistingAndHandlingFeeIssue  \npickupAddressIssue  \nbillingAddressIssue  \n}\n" + MarkotApi__PrelovedItemMedia.PrelovedItemMediaFields.query;

function parse$7(value) {
  var value$1 = value.uploadListingMedia;
  var value$2 = value$1.media;
  return {
          uploadListingMedia: {
            media: MarkotApi__PrelovedItemMedia.PrelovedItemMediaFields.verifyArgsAndParse("PrelovedItemMediaFields", value$2),
            listing: parse$1(value$1.listing)
          }
        };
}

function serialize$7(value) {
  var value$1 = value.uploadListingMedia;
  var value$2 = value$1.listing;
  var listing = serialize$1(value$2);
  var value$3 = value$1.media;
  var media = MarkotApi__PrelovedItemMedia.PrelovedItemMediaFields.serialize(value$3);
  var uploadListingMedia = {
    media: media,
    listing: listing
  };
  return {
          uploadListingMedia: uploadListingMedia
        };
}

function serializeVariables$5(inp) {
  var a = inp.mediaTag;
  return {
          listingId: inp.listingId,
          mediaFileInBase64: inp.mediaFileInBase64,
          mediaTag: a !== undefined ? a : undefined
        };
}

var Raw$8 = {};

function parse$8(value) {
  var value$1 = value.deleteListingMedia;
  return {
          deleteListingMedia: {
            mediaId: value$1.mediaId
          }
        };
}

function serialize$8(value) {
  var value$1 = value.deleteListingMedia;
  var value$2 = value$1.mediaId;
  var deleteListingMedia = {
    mediaId: value$2
  };
  return {
          deleteListingMedia: deleteListingMedia
        };
}

function serializeVariables$6(inp) {
  return {
          id: inp.id
        };
}

function parseOrderFlowStatus(orderFlowStatus) {
  if (typeof orderFlowStatus === "object") {
    RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "Listing parseOrderFlowStatus - Listing.status.ListingSold.orderFlowStatus unknown typename", orderFlowStatus);
    return Js_exn.raiseError("Listing.status.ListingSold.orderFlowStatus unknown typename.");
  } else if (orderFlowStatus === "MarkotBoxSent") {
    return "MarkotBoxSent";
  } else if (orderFlowStatus === "SellerScheduledAPickup") {
    return "SellerScheduledAPickup";
  } else if (orderFlowStatus === "MarkotItemAccepted") {
    return "MarkotItemAccepted";
  } else if (orderFlowStatus === "MarkotItemDeclined") {
    return "MarkotItemDeclined";
  } else {
    return "MarkotProcessing";
  }
}

function makeListing(param) {
  var status = param.status;
  var variant = status.NAME;
  var tmp;
  if (variant === "ListingNeedsChanges") {
    var match = status.VAL.requiredChanges;
    tmp = {
      TAG: /* ListingNeedsChanges */0,
      requiredChanges: {
        id: MarkotApi__Id.parseExn(match.id),
        generalIssue: match.generalIssue,
        conditionIssue: match.conditionIssue,
        mediaIssue: match.mediaIssue,
        descriptionIssue: match.descriptionIssue,
        priceIssue: match.priceIssue,
        listingAndHandlingFeeIssue: match.listingAndHandlingFeeIssue,
        pickupAddressIssue: match.pickupAddressIssue,
        billingAddressIssue: match.billingAddressIssue
      }
    };
  } else if (variant === "ListingAwaitingReview") {
    tmp = /* ListingAwaitingReview */0;
  } else if (variant === "ListingApproved") {
    tmp = /* ListingApproved */2;
  } else if (variant === "ListingInReview") {
    tmp = /* ListingInReview */1;
  } else if (variant === "FutureAddedValue") {
    RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "Listing makeListing - Listing.status unknown typename", status);
    tmp = Js_exn.raiseError("Listing.status unknown typename.");
  } else if (variant === "ListingRejected") {
    var match$1 = status.VAL.reasons;
    tmp = {
      TAG: /* ListingRejected */1,
      reasons: {
        id: MarkotApi__Id.parseExn(match$1.id),
        generalIssue: match$1.generalIssue,
        conditionIssue: match$1.conditionIssue,
        mediaIssue: match$1.mediaIssue,
        descriptionIssue: match$1.descriptionIssue,
        priceIssue: match$1.priceIssue,
        listingAndHandlingFeeIssue: match$1.listingAndHandlingFeeIssue,
        pickupAddressIssue: match$1.pickupAddressIssue,
        billingAddressIssue: match$1.billingAddressIssue
      }
    };
  } else if (variant === "ListingDeleted") {
    var match$2 = status.VAL;
    tmp = {
      TAG: /* ListingDeleted */3,
      reason: match$2.reason,
      deletedAt: match$2.deletedAt
    };
  } else {
    tmp = {
      TAG: /* ListingSold */2,
      orderFlowStatus: parseOrderFlowStatus(status.VAL.orderFlowStatus)
    };
  }
  return {
          id: MarkotApi__Id.parseExn(param.id),
          prelovedItemInfoId: MarkotApi__Id.parseExn(param.prelovedItemInfoId),
          submittedAt: param.submittedAt,
          status: tmp,
          pickupAddressId: MarkotApi__Id.parseExn(param.pickupAddressId),
          billingAddressId: MarkotApi__Id.parseExn(param.billingAddressId),
          product: Belt_Option.flatMap(param.product, (function (product) {
                  var variant = product.NAME;
                  if (variant !== "SuggestedProductInfo") {
                    if (variant === "FutureAddedValue") {
                      RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "Listing makeListing - Listing.product unknown typename", product);
                      return ;
                    } else {
                      return {
                              NAME: "ProductInfo",
                              VAL: {
                                productId: MarkotApi__Id.parseExn(product.VAL.productId)
                              }
                            };
                    }
                  }
                  var match = product.VAL;
                  return {
                          NAME: "SuggestedProductInfo",
                          VAL: {
                            productName: match.productName,
                            brandName: match.brandName
                          }
                        };
                }))
        };
}

function fetchBasicData(apiConfig, id) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$2,
                    Raw: Raw$2,
                    parse: parse$2,
                    serialize: serialize$2,
                    serializeVariables: serializeVariables
                  }), {
                id: id
              }, undefined).then(function (param) {
              var node = param.node;
              if (node !== undefined) {
                if (node.NAME === "UnspecifiedFragment") {
                  RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "Listing fetchBasicData - node of unknown type found. Ignoring.", node);
                  return ;
                } else {
                  return makeListing(node.VAL.listingFields);
                }
              } else {
                RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "Listing fetchBasicData - not found. Id", id);
                return ;
              }
            });
}

function fetchListings(apiConfig, first, after, status) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                          query: query$3,
                          Raw: Raw$3,
                          parse: parse$3,
                          serialize: serialize$3,
                          serializeVariables: serializeVariables$1
                        }), {
                      first: first,
                      after: after,
                      status: status
                    }, undefined).then(function (param) {
                    return param.viewer;
                  }).then(function (__x) {
                  return RescriptUtils__OptionPlus.tapNone(__x, (function (param) {
                                RescriptUtils__Logger.warn(MarkotApi__Infra.logger, "Viewer not found when getting listings");
                              }));
                }).then(function (__x) {
                return Belt_Option.map(__x, (function (param) {
                              var match = param.listings;
                              var pageInfo = match.pageInfo;
                              return {
                                      totalItems: match.totalItems,
                                      pageInfo: {
                                        startCursor: pageInfo.startCursor,
                                        endCursor: pageInfo.endCursor,
                                        hasNextPage: pageInfo.hasNextPage,
                                        hasPreviousPage: pageInfo.hasPreviousPage
                                      },
                                      edges: Belt_Array.map(match.edges, (function (param) {
                                              return {
                                                      cursor: param.cursor,
                                                      node: makeListing(param.node)
                                                    };
                                            }))
                                    };
                            }));
              }).then(function (__x) {
              return Belt_Option.getWithDefault(__x, {
                          totalItems: 0,
                          pageInfo: {
                            startCursor: undefined,
                            endCursor: undefined,
                            hasNextPage: false,
                            hasPreviousPage: false
                          },
                          edges: []
                        });
            });
}

function executeUpdateListing(apiConfig, param) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$4,
                    Raw: Raw$4,
                    parse: parse$4,
                    serialize: serialize$4,
                    serializeVariables: serializeVariables$2
                  }), {
                input: {
                  id: MarkotApi__Id.toString(param.id),
                  buyNowPrice: param.buyNowPrice,
                  condition: param.condition,
                  listingAndHandlingFeeId: undefined,
                  description: param.description,
                  pickupAddressId: Belt_Option.map(param.pickupAddressId, MarkotApi__Id.toString),
                  billingAddressId: Belt_Option.map(param.billingAddressId, MarkotApi__Id.toString)
                }
              }, undefined).then(function (param) {
              return makeListing(param.updateListing);
            });
}

function executeDeleteListing(apiConfig, id) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: "mutation DeleteListing($id: ID!)  {\ndeleteListing(id: $id)  {\ndeletedListingId  \n}\n\n}\n",
                    Raw: Raw$6,
                    parse: parse$6,
                    serialize: serialize$6,
                    serializeVariables: serializeVariables$4
                  }), {
                id: MarkotApi__Id.toString(id)
              }, undefined).then(function (param) {
              return {
                      deletedListingId: MarkotApi__Id.parseExn(param.deleteListing.deletedListingId)
                    };
            });
}

function executeUploadListingMedia(apiConfig, listingId, mediaFileInBase64, mediaTag) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$6,
                    Raw: Raw$7,
                    parse: parse$7,
                    serialize: serialize$7,
                    serializeVariables: serializeVariables$5
                  }), {
                listingId: MarkotApi__Id.toString(listingId),
                mediaFileInBase64: mediaFileInBase64,
                mediaTag: mediaTag
              }, undefined).then(function (param) {
              var match = param.uploadListingMedia;
              return {
                      media: MarkotApi__PrelovedItemMedia.makePrelovedItemMedia(match.media),
                      listing: makeListing(match.listing)
                    };
            });
}

function executeDeleteListingMedia(apiConfig, id) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: "mutation DeleteListingMedia($id: ID!)  {\ndeleteListingMedia(mediaId: $id)  {\nmediaId  \n}\n\n}\n",
                    Raw: Raw$8,
                    parse: parse$8,
                    serialize: serialize$8,
                    serializeVariables: serializeVariables$6
                  }), {
                id: MarkotApi__Id.toString(id)
              }, undefined).then(function (param) {
              return {
                      mediaId: MarkotApi__Id.parseExn(param.deleteListingMedia.mediaId)
                    };
            });
}

function executeSubmitListingForApproval(apiConfig, id) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query$5,
                    Raw: Raw$5,
                    parse: parse$5,
                    serialize: serialize$5,
                    serializeVariables: serializeVariables$3
                  }), {
                id: MarkotApi__Id.toString(id)
              }, undefined).then(function (param) {
              return makeListing(param.submitListingForApproval);
            });
}

function ReviewFields_unsafe_fromJson(prim) {
  return prim;
}

function ReviewFields_toJson(prim) {
  return prim;
}

var ReviewFields = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName,
  unsafe_fromJson: ReviewFields_unsafe_fromJson,
  toJson: ReviewFields_toJson
};

function ListingFields_unsafe_fromJson(prim) {
  return prim;
}

function ListingFields_toJson(prim) {
  return prim;
}

var ListingFields = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  verifyArgsAndParse: verifyArgsAndParse$1,
  verifyName: verifyName$1,
  unsafe_fromJson: ListingFields_unsafe_fromJson,
  toJson: ListingFields_toJson
};

export {
  ReviewFields ,
  ListingFields ,
  makeListing ,
  fetchBasicData ,
  fetchListings ,
  executeUpdateListing ,
  executeDeleteListing ,
  executeUploadListingMedia ,
  executeDeleteListingMedia ,
  executeSubmitListingForApproval ,
}
/* MarkotApi__Id Not a pure module */
