/* TypeScript file generated from Ui__BaseText.res by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as Ui__BaseTextBS__Es6Import from './Ui__BaseText.bs';
const Ui__BaseTextBS: any = Ui__BaseTextBS__Es6Import;

import type {Accessibility_role as ReactNative_Accessibility_role} from '../../../../../rescript-shims/ReactNative.shim';

import type {Accessibility_state as ReactNative_Accessibility_state} from '../../../../../rescript-shims/ReactNative.shim';

import type {Color_t as ReactNative_Color_t} from '../../../../../rescript-shims/ReactNative.shim';

import type {Event_layoutEvent as ReactNative_Event_layoutEvent} from '../../../../../rescript-shims/ReactNative.shim';

import type {Event_pressEvent as ReactNative_Event_pressEvent} from '../../../../../rescript-shims/ReactNative.shim';

import type {Style_t as ReactNative_Style_t} from '../../../../../rescript-shims/ReactNative.shim';

import type {Typography_Font_t as Ui__Theme_Typography_Font_t} from '../../../../../packages/ui/src/theme/components/Ui__Theme.gen';

import type {View_edgeInsets as ReactNative_View_edgeInsets} from '../../../../../rescript-shims/ReactNative.shim';

import type {Web_hrefAttrs as ReactNative_Web_hrefAttrs} from '../../../../../rescript-shims/ReactNative.shim';

// tslint:disable-next-line:interface-over-type-literal
export type WebText_ellipsizeMode = "clip" | "head" | "middle" | "tail";

// tslint:disable-next-line:interface-over-type-literal
export type WebText_textBreakStrategy = "simple" | "highQuality" | "balanced";

// tslint:disable-next-line:interface-over-type-literal
export type WebText_accessibilityLevel = 1 | 2 | 3 | 4 | 5 | 6;

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly accessibilityHint?: string; 
  readonly accessibilityLabel?: string; 
  readonly accessibilityLevel?: WebText_accessibilityLevel; 
  readonly accessibilityRole?: ReactNative_Accessibility_role; 
  readonly accessibilityState?: ReactNative_Accessibility_state; 
  readonly accessible?: boolean; 
  readonly adjustsFontSizeToFit?: boolean; 
  readonly allowFontScaling?: boolean; 
  readonly children?: React.ReactNode; 
  readonly color?: ReactNative_Color_t; 
  readonly ellipsizeMode?: WebText_ellipsizeMode; 
  readonly fontFamily?: string; 
  readonly href?: string; 
  readonly hrefAttrs?: ReactNative_Web_hrefAttrs; 
  readonly minimumFontScale?: number; 
  readonly numberOfLines?: number; 
  readonly onClick?: (_1:MouseEvent) => void; 
  readonly onLayout?: (_1:ReactNative_Event_layoutEvent) => void; 
  readonly onLongPress?: (_1:ReactNative_Event_pressEvent) => void; 
  readonly onMouseEnter?: (_1:MouseEvent) => void; 
  readonly onMouseLeave?: (_1:MouseEvent) => void; 
  readonly onPress?: (_1:ReactNative_Event_pressEvent) => void; 
  readonly pressRetentionOffset?: ReactNative_View_edgeInsets; 
  readonly selectable?: boolean; 
  readonly selectionColor?: string; 
  readonly size?: Ui__Theme_Typography_Font_t; 
  readonly style?: ReactNative_Style_t; 
  readonly suppressHighlighting?: boolean; 
  readonly testID?: string; 
  readonly textBreakStrategy?: WebText_textBreakStrategy; 
  readonly value?: string
};

export const make: React.ComponentType<{
  readonly accessibilityHint?: string; 
  readonly accessibilityLabel?: string; 
  readonly accessibilityLevel?: WebText_accessibilityLevel; 
  readonly accessibilityRole?: ReactNative_Accessibility_role; 
  readonly accessibilityState?: ReactNative_Accessibility_state; 
  readonly accessible?: boolean; 
  readonly adjustsFontSizeToFit?: boolean; 
  readonly allowFontScaling?: boolean; 
  readonly children?: React.ReactNode; 
  readonly color?: ReactNative_Color_t; 
  readonly ellipsizeMode?: WebText_ellipsizeMode; 
  readonly fontFamily?: string; 
  readonly href?: string; 
  readonly hrefAttrs?: ReactNative_Web_hrefAttrs; 
  readonly minimumFontScale?: number; 
  readonly numberOfLines?: number; 
  readonly onClick?: (_1:MouseEvent) => void; 
  readonly onLayout?: (_1:ReactNative_Event_layoutEvent) => void; 
  readonly onLongPress?: (_1:ReactNative_Event_pressEvent) => void; 
  readonly onMouseEnter?: (_1:MouseEvent) => void; 
  readonly onMouseLeave?: (_1:MouseEvent) => void; 
  readonly onPress?: (_1:ReactNative_Event_pressEvent) => void; 
  readonly pressRetentionOffset?: ReactNative_View_edgeInsets; 
  readonly selectable?: boolean; 
  readonly selectionColor?: string; 
  readonly size?: Ui__Theme_Typography_Font_t; 
  readonly style?: ReactNative_Style_t; 
  readonly suppressHighlighting?: boolean; 
  readonly testID?: string; 
  readonly textBreakStrategy?: WebText_textBreakStrategy; 
  readonly value?: string
}> = Ui__BaseTextBS.make;
