/* TypeScript file generated from Ui__FatalErrorDisplay.resi by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as Ui__FatalErrorDisplayBS__Es6Import from './Ui__FatalErrorDisplay.bs';
const Ui__FatalErrorDisplayBS: any = Ui__FatalErrorDisplayBS__Es6Import;

import type {Style_t as ReactNative_Style_t} from '../../../../../rescript-shims/ReactNative.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly style?: ReactNative_Style_t; 
  readonly technicalDetails: string; 
  readonly onReload: () => void; 
  readonly errorId?: string
};

export const make: React.ComponentType<{
  readonly style?: ReactNative_Style_t; 
  readonly technicalDetails: string; 
  readonly onReload: () => void; 
  readonly errorId?: string
}> = Ui__FatalErrorDisplayBS.make;
