// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Portal from "../../../portal/Ui__Portal.bs.js";
import * as Ui__Styling from "../../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Style$ReactNative from "rescript-react-native/src/apis/Style.bs.js";
import * as Ui__LoadingIndicator from "../../../loading/components/Ui__LoadingIndicator.bs.js";
import * as Ui__SlideInRightView from "../error/Ui__SlideInRightView.bs.js";

var match = Ui__Styling.describe({
      wrapper: {
        TAG: /* Dual */2,
        _0: {
          height: 40,
          justifyContent: "center",
          position: "absolute",
          right: Ui__Styling.pct(50),
          top: 120,
          width: 40
        },
        _1: (function (props) {
            return {
                    backgroundColor: props.theme.colors.primary,
                    borderRadius: props.theme.roundness._2,
                    left: props.left
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__Toast__Loading(Props) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = ReactNative.useWindowDimensions();
  var leftForNative = (match$1.width - 40) / 2;
  var styled = Curry._1(stylesheet, {
        theme: theme,
        left: ReactNative.Platform.OS === "web" ? Style$ReactNative.pct(50) : leftForNative
      });
  return React.createElement(Ui__Portal.make, {
              children: React.createElement(Ui__SlideInRightView.make, {
                    children: React.createElement(Ui__LoadingIndicator.make, {
                          size: "small",
                          color: theme.colors.onPrimary
                        }),
                    style: Curry._1(styled, styles.wrapper)
                  }),
              hostName: "Toast"
            });
}

var make = Ui__Toast__Loading;

export {
  make ,
}
/* match Not a pure module */
