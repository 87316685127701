interface PredictionSubstring {
  length: number;
  offset: number;
}

export interface AutocompleteStructuredFormatting {
  mainText: string;
  mainTextMatchedSubstrings: PredictionSubstring[];
  secondaryText: string;
}

export type AutocompletePredictionComponent = {
  street?: string;
  suburb?: string;
  state?: string;
  country?: string;
};

export interface AutocompletePrediction {
  text: string;
  placeId: string;
  matchedSubstrings: PredictionSubstring[];
  structuredFormatting: AutocompleteStructuredFormatting;
  components: AutocompletePredictionComponent;
}

export type AutocompletePredictionsUrlProps = {
  input: string;
  apiKey?: string;
  types?: string[];
  sessionToken?: string;
};

export interface AutocompleteServiceInfo {
  name: string;
  attributionUrl?: string;
  attributionText?: string;
  getPredictions: (props: {
    input: string;
    apiKey?: string;
    types?: string[];
    sessionToken?: string;
  }) => Promise<AutocompletePrediction[]>;
}

export type Address = {
  subpremise?: string;
  streetNumber?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
};

export type GetAddressResult = {
  address?: Address;
  error?: string;
};

// https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
export const makeAddressResult = (
  addressComponents: google.maps.GeocoderAddressComponent[]
) => {
  const addressResult: Address = {};

  addressComponents.forEach(component => {
    const componentType = component.types[0];

    switch (componentType) {
      case "subpremise": {
        addressResult.subpremise = component.long_name;
        break;
      }

      case "street_number": {
        addressResult.streetNumber = component.long_name;
        break;
      }

      case "route": {
        addressResult.street = component.long_name;
        break;
      }

      case "locality": {
        addressResult.city = component.long_name;
        break;
      }

      case "administrative_area_level_1": {
        addressResult.state = component.long_name;
        break;
      }

      case "postal_code": {
        addressResult.postalCode = component.long_name;
        break;
      }

      case "country":
        addressResult.country = component.long_name;
        break;

      default:
        break;
    }
  });
  return addressResult;
};
