// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as LoadingIndicator$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/loading/components/loadingIndicator/LoadingIndicator.bs.js";

function Ui__LoadingIndicator(Props) {
  var size = Props.size;
  var color = Props.color;
  var testIDOpt = Props.testID;
  var style = Props.style;
  var testID = testIDOpt !== undefined ? testIDOpt : "loading-indicator";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var tmp = {
    color: Belt_Option.getWithDefault(color, match.theme.colors.primary),
    testID: testID
  };
  if (size !== undefined) {
    tmp.size = Caml_option.valFromOption(size);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  return React.createElement(LoadingIndicator$DecidrIoUniversalComponents.make, tmp);
}

var Size;

var make = Ui__LoadingIndicator;

export {
  Size ,
  make ,
}
/* react Not a pure module */
