// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as ShopifyApi__Id from "../../../api-adapter-shopify/src/ShopifyApi__Id.bs.js";
import * as GraphqlCore__Types from "../../../graphql-core/src/GraphqlCore__Types.bs.js";
import * as ShopifyApi__Products from "../../../api-adapter-shopify/src/ShopifyApi__Products.bs.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Core__FromShopify from "../core/GqlMiddleware__Core__FromShopify.bs.js";
import * as GqlMiddleware__Entity__OpenBoxInfo from "./GqlMiddleware__Entity__OpenBoxInfo.bs.js";
import * as GqlMiddleware__Entity__OverstockedInfo from "./GqlMiddleware__Entity__OverstockedInfo.bs.js";
import * as GqlMiddleware__Entity__RefurbishedInfo from "./GqlMiddleware__Entity__RefurbishedInfo.bs.js";

function makeProductId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "Product", param);
}

function makeExpandableText(fullText) {
  if (fullText === "") {
    return ;
  } else {
    return {
            blurb: fullText.substring(0, 250),
            remainingText: fullText.length > 250 ? fullText.substring(250) : undefined
          };
  }
}

function makeProduct(param) {
  var compareAtPriceRange = param.compareAtPriceRange;
  var priceRange = param.priceRange;
  var match = Belt_Float.fromString(compareAtPriceRange.min.amount);
  var match$1 = Belt_Float.fromString(compareAtPriceRange.max.amount);
  var tmp;
  if (match !== undefined) {
    var exit = 0;
    if (match !== 0 || match$1 !== 0) {
      exit = 1;
    } else {
      tmp = undefined;
    }
    if (exit === 1) {
      if (match$1 !== undefined) {
        tmp = {
          min: {
            amount: compareAtPriceRange.min.amount,
            currency: compareAtPriceRange.min.currency
          },
          max: {
            amount: compareAtPriceRange.max.amount,
            currency: compareAtPriceRange.max.currency
          }
        };
      } else {
        RescriptUtils__Logger.warn2(GqlMiddleware__Core__Infra.logger, "Failed to make float out of string for compareAtPriceRange's max", compareAtPriceRange.max);
        tmp = undefined;
      }
    }
    
  } else if (match$1 !== undefined) {
    RescriptUtils__Logger.warn2(GqlMiddleware__Core__Infra.logger, "Failed to make float out of string for compareAtPriceRange's min", compareAtPriceRange.min);
    tmp = undefined;
  } else {
    RescriptUtils__Logger.warn3(GqlMiddleware__Core__Infra.logger, "Failed to make float out of string for compareAtPriceRange's min and max", compareAtPriceRange.min, compareAtPriceRange.max);
    tmp = undefined;
  }
  return {
          __typename: "Product",
          id: GqlMiddleware__Core__NodeId.make(1, "Product", ShopifyApi__Id.toString(param.id)),
          name: param.name,
          features: makeExpandableText(param.description),
          descriptionHtml: param.descriptionHtml,
          slug: param.slug,
          priceRange: {
            min: {
              amount: priceRange.min.amount,
              currency: priceRange.min.currency
            },
            max: {
              amount: priceRange.max.amount,
              currency: priceRange.max.currency
            }
          },
          compareAtPriceRange: tmp,
          sellingPrelovedItems: param.sellingPrelovedItems,
          sellingNewItems: param.sellingNewItems,
          usedForTesting: param.usedForTesting,
          discontinued: param.discontinued,
          brandSlug: param.brandSlug,
          brandId: Belt_Option.map(param.brandId, (function (brandId) {
                  return GqlMiddleware__Core__NodeId.make(1, "Brand", ShopifyApi__Id.toString(brandId));
                })),
          mainImage: Belt_Option.flatMap(param.mainImage, GqlMiddleware__Core__FromShopify.tryMakeImageResource),
          lifeSceneImage: Belt_Option.flatMap(param.lifeSceneImage, GqlMiddleware__Core__FromShopify.tryMakeImageResource),
          media: Belt_Option.map(param.media, (function (media) {
                  return Belt_Array.keepMap(media.edges, (function (param) {
                                return GqlMiddleware__Core__FromShopify.tryMakeProductMedia(param.node);
                              }));
                })),
          inWishlist: undefined,
          seo: Belt_Option.map(param.seo, (function (param) {
                  return {
                          description: param.description,
                          title: param.title
                        };
                })),
          markdownDescription: Belt_Option.flatMap(param.markdownDescription, makeExpandableText),
          refurbished: param.refurbished ? GqlMiddleware__Entity__RefurbishedInfo.make(param.refurbishedModelYear, Belt_Option.map(param.refurbishedOriginalPrice, GqlMiddleware__Core__FromShopify.money)) : undefined,
          overstocked: param.overstocked ? GqlMiddleware__Entity__OverstockedInfo.make(param.overstockedModelYear, Belt_Option.map(param.overstockedOriginalPrice, GqlMiddleware__Core__FromShopify.money)) : undefined,
          isOverstockedProduct: param.isOverstockedProduct,
          isRefurbishedProduct: param.isRefurbishedProduct,
          isOpenBoxProduct: param.isOpenBoxProduct,
          openbox: param.openbox ? GqlMiddleware__Entity__OpenBoxInfo.make(param.openboxModelYear, Belt_Option.map(param.openboxOriginalPrice, GqlMiddleware__Core__FromShopify.money)) : undefined
        };
}

function fetchProductsInProductCollection(apiConfig, productCollectionId, first, includeTestProductsOpt, after, includeMarkdownDescription, includeRefurbishedInfo, includeOverstockedInfo, param) {
  var includeTestProducts = includeTestProductsOpt !== undefined ? includeTestProductsOpt : false;
  var collectionId = ShopifyApi__Id.parseExn(productCollectionId);
  return ShopifyApi__Products.fetchProductsInCollection(apiConfig, collectionId, first, includeTestProducts, after, includeMarkdownDescription, includeRefurbishedInfo, includeOverstockedInfo, undefined).then(function (result) {
              if (result !== undefined) {
                return GraphqlCore__Types.Connection.map(result, makeProduct);
              } else {
                return Js_exn.raiseError("ProductCollectionProducts: collection not found. ID=" + ShopifyApi__Id.toString(collectionId));
              }
            });
}

function fetchProductsRandomCollection(apiConfig, queryString, first, param) {
  return ShopifyApi__Products.fetchProductsRandomCollection(apiConfig, queryString, first, undefined).then(function (result) {
              if (result !== undefined) {
                return GraphqlCore__Types.Connection.map(result, makeProduct);
              } else {
                return Js_exn.raiseError("ProductsRandomCollection: collection not found. ID=");
              }
            });
}

function fetchProduct(apiConfig, id, includeMarkdownDescription, includeRefurbishedInfo, includeOverstockedInfo) {
  var shopifyProductId = ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(id).internalId);
  return ShopifyApi__Products.fetchProduct(apiConfig, shopifyProductId, true, includeMarkdownDescription, includeRefurbishedInfo, includeOverstockedInfo, undefined).then(function (x) {
                return x;
              }).then(function (__x) {
              return Belt_Option.map(__x, makeProduct);
            });
}

function fetchProductsByIds(apiConfig, ids, includeTestProducts, includeMarkdownDescription, includeRefurbishedInfo, includeOverstockedInfo) {
  return ShopifyApi__Products.fetchProductsByIds(apiConfig, Belt_Array.map(ids, (function (id) {
                      return ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(id).internalId);
                    })), includeTestProducts, includeMarkdownDescription, includeRefurbishedInfo, includeOverstockedInfo).then(function (__x) {
              return Belt_Array.map(__x, (function (__x) {
                            return Belt_Option.map(__x, makeProduct);
                          }));
            });
}

function fetchProductMedia(apiConfig, productId) {
  var shopifyProductId = ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(productId).internalId);
  return ShopifyApi__Products.fetchProductMedia(apiConfig, shopifyProductId).then(function (x) {
                return x;
              }).then(function (__x) {
              return Belt_Option.map(__x, (function (param) {
                            return Belt_Array.keepMap(param.edges, (function (param) {
                                          return GqlMiddleware__Core__FromShopify.tryMakeProductMedia(param.node);
                                        }));
                          }));
            });
}

function fetchProductVariantsByProductId(apiConfig, id) {
  return ShopifyApi__Products.fetchProductOptions(apiConfig, ShopifyApi__Id.parseExn(GqlMiddleware__Core__NodeId.unpackExn(id).internalId), undefined).then(function (productWithOptions) {
              return Belt_Option.map(RescriptUtils__OptionPlus.tapNone(productWithOptions, (function (param) {
                                RescriptUtils__Logger.warn2(GqlMiddleware__Core__Infra.logger, "productWithOptions not found. Id:", id);
                              })), (function (param) {
                            var param$1 = Belt_Array.reduce(param.options, [
                                  undefined,
                                  undefined
                                ], (function (param, productOption) {
                                    return [
                                            Belt_MapString.set(param[0], productOption.name, ShopifyApi__Id.toString(productOption.id)),
                                            Belt_MapString.set(param[1], ShopifyApi__Id.toString(productOption.id), productOption.name)
                                          ];
                                  }));
                            return {
                                    productOptionIdsByNames: param$1[0],
                                    productOptionNamesByIds: param$1[1]
                                  };
                          }));
            });
}

export {
  makeProductId ,
  makeProduct ,
  fetchProductsInProductCollection ,
  fetchProductsRandomCollection ,
  fetchProduct ,
  fetchProductsByIds ,
  fetchProductMedia ,
  fetchProductVariantsByProductId ,
  makeExpandableText ,
}
/* ShopifyApi__Id Not a pure module */
