// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";

var logger = RescriptUtils__Logger.make("gql-middleware");

export {
  logger ,
}
/* logger Not a pure module */
