import { StyleProp, TextStyle, PixelRatio } from "react-native";
import { SvgProps } from "react-native-svg";

import { IconSvg as Svg } from "@decidr-io/universal-components/src/lineAwesome/components/IconSvg";

import { useTheme } from "../../theme";
import selection from "../../../assets/optimized-markot-icons.json";
import { MarkotIconName } from "./markotGeneratedIconNames";
export type { MarkotIconName } from "./markotGeneratedIconNames";

interface MarkotSvgProps extends SvgProps {
  name: MarkotIconName;
  childOfOtherSvgComponent?: boolean;
}

const MarkotSvg = (props: MarkotSvgProps) => (
  <Svg {...props} svg={selection[props.name]} fillRule="evenodd" />
);

interface MarkotIconProps {
  name: MarkotIconName;
  color?: string;
  size?: number;
  style?: StyleProp<TextStyle>;
  testID?: string;
  childOfOtherSvgComponent?: boolean;
  opacity?: number;
}

export const MarkotIcon = ({
  name,
  color,
  size = 24,
  style,
  testID,
  childOfOtherSvgComponent = false,
  opacity = 1,
}: MarkotIconProps) => {
  const { theme } = useTheme();
  // We want to scale icons as well, because we mostly
  // use icons in combination with text.
  const fontScale = PixelRatio.getFontScale();
  return (
    <MarkotSvg
      name={name}
      width={size * fontScale}
      height={size * fontScale}
      fill={color != undefined ? color : theme.colors.primary}
      style={style}
      testID={testID}
      childOfOtherSvgComponent={childOfOtherSvgComponent}
      opacity={opacity}
    />
  );
};
