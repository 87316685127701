// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__Clipboard from "../../clipboard/Ui__Clipboard.bs.js";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Ui__MarkotButton from "../../button/components/Ui__MarkotButton.bs.js";
import * as Ui__MultilineText from "../../multipleLines/components/Ui__MultilineText.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as Ignored$DecidrIoUtils from "@decidr-io/utils/src/functions/Ignored.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as Ui__BearErrorIllustration from "../../svg/bearError/Ui__BearErrorIllustration.bs.js";

var match = Ui__Styling.describe({
      safeAreaView: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          flex: 1,
          justifyContent: "center"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.background,
                    paddingVertical: theme.sectionSpacing._1
                  };
          })
      },
      markotText: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    color: theme.colors.primary,
                    fontWeight: "bold"
                  };
          })
      },
      errorContainer: {
        TAG: /* Dual */2,
        _0: Ui__Styling.viewStyleWithWebFix(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 110, undefined, undefined, 500, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
        _1: (function (theme) {
            return {
                    borderRadius: theme.roundness._2,
                    padding: theme.spacing._8
                  };
          })
      },
      heading: {
        TAG: /* Dual */2,
        _0: {
          fontWeight: "bold",
          textAlign: "center"
        },
        _1: (function (theme) {
            return {
                    color: theme.colors.onBackground,
                    paddingTop: theme.spacing._6
                  };
          })
      },
      bolded: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "bold"
        }
      },
      detailsContainer: {
        TAG: /* Dual */2,
        _0: {
          flex: 1
        },
        _1: (function (theme) {
            return {
                    paddingHorizontal: theme.spacing._4,
                    paddingTop: theme.spacing._8
                  };
          })
      },
      notice: {
        TAG: /* Dual */2,
        _0: {
          textAlign: "center"
        },
        _1: (function (theme) {
            return {
                    color: theme.colors.onBackground,
                    paddingBottom: theme.spacing._6
                  };
          })
      },
      details: {
        TAG: /* Dual */2,
        _0: {
          textAlign: "center"
        },
        _1: (function (theme) {
            return {
                    color: theme.colors.onBackground
                  };
          })
      },
      actions: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    marginTop: theme.spacing._6
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__FatalErrorDisplay(Props) {
  var errorId = Props.errorId;
  var technicalDetails = Props.technicalDetails;
  var onReload = Props.onReload;
  var style = Props.style;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, match.theme);
  return React.createElement(ReactNative.SafeAreaView, {
              style: Curry._1(styled, styles.safeAreaView),
              children: React.createElement(ReactNative.View, {
                    style: [
                      Caml_option.some(Curry._1(styled, styles.container)),
                      Bool$DecidrIoUtils.thenSome(ReactNative.Platform.OS === "web", {
                            minHeight: "100vh"
                          }),
                      style
                    ],
                    children: null
                  }, React.createElement(Ui__MarkotText.make, {
                        size: "title2",
                        children: "Markot",
                        style: Curry._1(styled, styles.markotText)
                      }), React.createElement(ReactNative.View, {
                        style: Curry._1(styled, styles.errorContainer),
                        children: null
                      }, React.createElement(Ui__BearErrorIllustration.make, {}), React.createElement(Ui__MarkotText.make, {
                            size: "title3",
                            children: "Oops!",
                            style: Curry._1(styled, styles.heading)
                          }), React.createElement(Ui__MarkotText.make, {
                            children: "Your app has crashed. Please try reloading the app.",
                            style: Curry._1(styled, styles.heading)
                          }), React.createElement(ReactNative.View, {
                            style: Curry._1(styled, styles.detailsContainer),
                            children: null
                          }, React.createElement(Ui__MultilineText.make, {
                                textLines: [
                                  "We couldn't load this page because an error has occurred. Please, try again.",
                                  "Our team is notified of this error. We are working on fixing it."
                                ],
                                textStyle: Curry._1(styled, styles.notice),
                                spacing: 0,
                                size: "b2"
                              }), SimpleReact$DecidrIoUtils.renderIfSome(errorId, (function (errorId) {
                                  return React.createElement(Ui__MarkotText.make, {
                                              size: "b2",
                                              children: null,
                                              style: Curry._1(styled, styles.details),
                                              selectable: true
                                            }, React.createElement(Ui__MarkotText.make, {
                                                  size: "b2",
                                                  children: "Error ID: ",
                                                  style: Curry._1(styled, styles.bolded)
                                                }), errorId);
                                }))), React.createElement(Ui__Group.make, {
                            children: null,
                            containerStyle: Curry._1(styled, styles.actions),
                            orientation: "horizontal"
                          }, React.createElement(Ui__MarkotButton.make, {
                                kind: "secondaryOutline",
                                size: "small",
                                onPress: (function (param) {
                                    Ignored$DecidrIoUtils.promiseWithPayload(Ui__Clipboard.setStringAsync(technicalDetails, undefined, undefined));
                                  }),
                                title: "Copy error"
                              }), React.createElement(Ui__MarkotButton.make, {
                                kind: "primary",
                                size: "small",
                                onPress: onReload,
                                title: "Reload the app"
                              }))))
            });
}

var make = Ui__FatalErrorDisplay;

export {
  make ,
}
/* match Not a pure module */
