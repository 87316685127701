// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as MarkotApi__ProductSpecifications from "../../../api-adapter-markot/src/MarkotApi__ProductSpecifications.bs.js";

function makeProductSpecifications(param) {
  return {
          title: param.title,
          value: param.value
        };
}

function fetchProductSpecifications(apiConfig, productId) {
  return MarkotApi__ProductSpecifications.fetchProductSpecifications(apiConfig, GqlMiddleware__Core__NodeId.unpackExn(productId).internalId).then(function (__x) {
              return Belt_Array.map(__x, makeProductSpecifications);
            });
}

export {
  makeProductSpecifications ,
  fetchProductSpecifications ,
}
/* GqlMiddleware__Core__NodeId Not a pure module */
