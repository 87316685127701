// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../group/components/Ui__Group.bs.js";
import * as Ui__Heading from "../../../text/components/Ui__Heading.bs.js";
import * as Ui__Styling from "../../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__Clipboard from "../../../clipboard/Ui__Clipboard.bs.js";
import * as Ui__MarkotText from "../../../text/components/Ui__MarkotText.bs.js";
import * as Ui__MarkotButton from "../../../button/components/Ui__MarkotButton.bs.js";
import * as Ui__MultilineText from "../../../multipleLines/components/Ui__MultilineText.bs.js";
import * as Ignored$DecidrIoUtils from "@decidr-io/utils/src/functions/Ignored.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as Ui__BearErrorIllustration from "../../../svg/bearError/Ui__BearErrorIllustration.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center"
        },
        _1: (function (theme) {
            return {
                    paddingVertical: theme.sectionSpacing._05
                  };
          })
      },
      illustration: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    paddingVertical: theme.sectionSpacing._05
                  };
          })
      },
      heading: {
        TAG: /* Dual */2,
        _0: {
          fontWeight: "bold"
        },
        _1: (function (theme) {
            return {
                    paddingBottom: theme.spacing._12
                  };
          })
      },
      title: {
        TAG: /* Dual */2,
        _0: {
          fontWeight: "bold"
        },
        _1: (function (theme) {
            return {
                    paddingBottom: theme.spacing._8
                  };
          })
      },
      errorIdLabel: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "bold"
        }
      },
      actions: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    paddingTop: theme.sectionSpacing._05
                  };
          })
      },
      subtitle: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "bold"
        }
      },
      multilineText: {
        TAG: /* Static */0,
        _0: {
          maxWidth: 250
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__ErrorDisplay(Props) {
  var title = Props.title;
  var errorId = Props.errorId;
  var errorMessage = Props.errorMessage;
  var onReload = Props.onReload;
  var testIDOpt = Props.testID;
  var showHeaderOpt = Props.showHeader;
  var testID = testIDOpt !== undefined ? testIDOpt : "error-display";
  var showHeader = showHeaderOpt !== undefined ? showHeaderOpt : false;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  return React.createElement(Ui__Group.make, {
              spacing: theme.spacing._6,
              children: null,
              containerStyle: Curry._1(styled, styles.container),
              testID: testID
            }, SimpleReact$DecidrIoUtils.whenTrue(showHeader, React.createElement(Ui__Heading.make, {
                      size: "title1",
                      level: 1,
                      children: "Markot",
                      style: Curry._1(styled, styles.heading)
                    })), React.createElement(ReactNative.View, {
                  style: Curry._1(styled, styles.illustration),
                  children: React.createElement(Ui__BearErrorIllustration.make, {})
                }), React.createElement(Ui__MarkotText.make, {
                  size: "title3",
                  color: theme.colors.error,
                  children: title,
                  style: Curry._1(styled, styles.title)
                }), SimpleReact$DecidrIoUtils.renderIfSome(errorId, (function (errorId) {
                    return React.createElement(Ui__MarkotText.make, {
                                size: "subtitle",
                                children: null
                              }, React.createElement(Ui__MarkotText.make, {
                                    size: "subtitle",
                                    children: "Error ID: ",
                                    style: Curry._1(styled, styles.errorIdLabel)
                                  }), errorId);
                  })), React.createElement(Ui__MultilineText.make, {
                  textLines: [
                    "We couldn't load this page because an error has occurred. Please, try again.",
                    "Our team is notified of this error."
                  ],
                  containerStyle: Curry._1(styled, styles.multilineText),
                  textStyle: Curry._1(styled, styles.subtitle),
                  spacing: 0,
                  size: "b2"
                }), React.createElement(Ui__Group.make, {
                  children: null,
                  containerStyle: Curry._1(styled, styles.actions),
                  orientation: "horizontal"
                }, React.createElement(Ui__MarkotButton.make, {
                      kind: "secondaryOutline",
                      onPress: (function (param) {
                          Ignored$DecidrIoUtils.promiseWithPayload(Ui__Clipboard.setStringAsync(errorMessage, undefined, undefined));
                        }),
                      title: "Copy error"
                    }), SimpleReact$DecidrIoUtils.renderIfSome(onReload, (function (onReload) {
                        return React.createElement(Ui__MarkotButton.make, {
                                    kind: "primary",
                                    onPress: (function (param) {
                                        Curry._1(onReload, undefined);
                                      }),
                                    title: "Reload app",
                                    testID: "reload-button"
                                  });
                      }))));
}

var make = Ui__ErrorDisplay;

export {
  make ,
}
/* match Not a pure module */
