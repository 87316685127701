// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as RescriptUtils__Logger from "../../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as AlgoliaApi__PrelovedItem from "../../../api-adapter-algolia/src/AlgoliaApi__PrelovedItem.bs.js";
import * as GqlMiddleware__Core__Infra from "../core/GqlMiddleware__Core__Infra.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Entity__SearchResult from "./GqlMiddleware__Entity__SearchResult.bs.js";
import * as GqlMiddleware__Entity__PrelovedItemInfo from "./GqlMiddleware__Entity__PrelovedItemInfo.bs.js";
import * as GqlMiddleware__Entity__PrelovedItemCondition from "./GqlMiddleware__Entity__PrelovedItemCondition.bs.js";

function makePrelovedItemSearchResult(param) {
  var condition = param.condition;
  var id = param.id;
  var tmp;
  switch (condition) {
    case "AsGoodAsNew" :
        tmp = "AsGoodAsNew";
        break;
    case "Good" :
        tmp = "Good";
        break;
    case "NeverUsedWithPackaging" :
    case "NeverUsedWithoutPackaging" :
        tmp = "NeverUsed";
        break;
    case "WellLoved" :
        tmp = "WellLoved";
        break;
    default:
      RescriptUtils__Logger.warn2(GqlMiddleware__Core__Infra.logger, "makePrelovedItemSearchResult - unhandled condition received:", condition);
      tmp = "FutureAddedValue";
  }
  return {
          __typename: "PrelovedItemSearchResult",
          id: GqlMiddleware__Core__NodeId.make(1, "PrelovedItemSearchResult", id),
          buyNowPrice: {
            amount: String(param.buyNowPrice),
            currency: "AUD"
          },
          highestBid: Belt_Option.map(param.highestBid, (function (highestBid) {
                  return {
                          amount: String(highestBid),
                          currency: "AUD"
                        };
                })),
          numberOfBids: param.numberOfBids,
          productSlug: param.productSlug,
          purchaseAmount: Belt_Option.map(param.purchaseAmount, (function (pa) {
                  return {
                          amount: String(pa),
                          currency: "AUD"
                        };
                })),
          prelovedItemInfoId: GqlMiddleware__Entity__PrelovedItemInfo.makePrelovedItemInfoId(id),
          status: param.status === "Sold" ? "Sold" : "Live",
          mainImage: Belt_Option.map(param.mainImage, (function (param) {
                  var size = param.size;
                  return {
                          __typename: "ImageResource",
                          alt: undefined,
                          url: param.url,
                          size: {
                            width: size.width,
                            height: size.height
                          }
                        };
                })),
          productName: param.productName,
          brandName: param.brandName,
          condition: tmp,
          minimumBid: Belt_Option.map(param.minimumBid, (function (minimumBid) {
                  return {
                          amount: String(minimumBid),
                          currency: "AUD"
                        };
                })),
          usedForTesting: param.usedForTesting
        };
}

function searchPrelovedItems(apiConfig, searchTerm, first, after, prelovedItemsSortOrder, sortDirection, filters, settings, newPrelovedCards) {
  return AlgoliaApi__PrelovedItem.searchPrelovedItems(apiConfig, searchTerm, first, after, prelovedItemsSortOrder, sortDirection, Belt_Option.map(filters, (function (param) {
                      return {
                              productId: Belt_Option.map(param.productId, (function (productId) {
                                      return GqlMiddleware__Core__NodeId.unpackExn(productId).internalId;
                                    })),
                              status: param.status,
                              buyNowPrice: Belt_Option.map(param.buyNowPrice, (function (x) {
                                      return Belt_Array.map(x, (function (buyNowPrice) {
                                                    return {
                                                            gt: buyNowPrice.gt,
                                                            lt: buyNowPrice.lt
                                                          };
                                                  }));
                                    })),
                              highestBid: Belt_Option.map(param.highestBid, (function (x) {
                                      return Belt_Array.map(x, (function (highestBid) {
                                                    return {
                                                            gt: highestBid.gt,
                                                            lt: highestBid.lt
                                                          };
                                                  }));
                                    })),
                              condition: Belt_Option.map(param.condition, (function (__x) {
                                      return Belt_Array.keepMap(__x, GqlMiddleware__Entity__PrelovedItemCondition.toMarkotApi);
                                    }))
                            };
                    })), Belt_Option.map(settings, (function (settings) {
                      return {
                              TAG: /* PrelovedItemSearchSettings */1,
                              includeTestItems: settings.includeTestItems
                            };
                    })), newPrelovedCards).then(function (__x) {
              return GqlMiddleware__Entity__SearchResult.Connection.makeConnectionFromAlgoliaConnection(__x, makePrelovedItemSearchResult);
            });
}

export {
  searchPrelovedItems ,
}
/* AlgoliaApi__PrelovedItem Not a pure module */
