// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotImage from "../Ui__MarkotImage.bs.js";
import * as ReStyleSheet$DecidrIoStyles from "@decidr-io/styles/src/stylesheet/ReStyleSheet.bs.js";
import * as Box$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/box/components/Box.bs.js";

var match = ReStyleSheet$DecidrIoStyles.describe({
      absoluteFillObject: {
        TAG: /* Static */0,
        _0: ReactNative.StyleSheet.absoluteFillObject
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__MarkotImageBackground(Props) {
  var kindOpt = Props.kind;
  var image = Props.image;
  var alt = Props.alt;
  var accessibilityRole = Props.accessibilityRole;
  var sizes = Props.sizes;
  var resizeModeOpt = Props.resizeMode;
  var priorityOpt = Props.priority;
  var style = Props.style;
  var imageStyle = Props.imageStyle;
  var testID = Props.testID;
  var imageTestID = Props.imageTestID;
  var childrenOpt = Props.children;
  var kind = kindOpt !== undefined ? kindOpt : "NonPressable";
  var resizeMode = resizeModeOpt !== undefined ? resizeModeOpt : "cover";
  var priority = priorityOpt !== undefined ? priorityOpt : "normal";
  var children = childrenOpt !== undefined ? Caml_option.valFromOption(childrenOpt) : null;
  var styled = Curry._1(stylesheet, undefined);
  var match;
  if (style !== undefined) {
    var style$1 = Caml_option.valFromOption(style);
    match = [
      Belt_Option.map(Js_dict.get(style$1, "width"), (function (prim) {
              return prim;
            })),
      Belt_Option.map(Js_dict.get(style$1, "height"), (function (prim) {
              return prim;
            }))
    ];
  } else {
    match = [
      undefined,
      undefined
    ];
  }
  var tmp = {
    kind: kind,
    children: null,
    accessibilityIgnoresInvertColors: true
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (testID !== undefined) {
    tmp.testID = Caml_option.valFromOption(testID);
  }
  if (accessibilityRole !== undefined) {
    tmp.accessibilityRole = Caml_option.valFromOption(accessibilityRole);
  }
  var tmp$1 = {
    zIndex: -1
  };
  var tmp$2 = match[1];
  if (tmp$2 !== undefined) {
    tmp$1.height = Caml_option.valFromOption(tmp$2);
  }
  var tmp$3 = match[0];
  if (tmp$3 !== undefined) {
    tmp$1.width = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = {
    image: image,
    alt: alt,
    size: {
      NAME: "fluid",
      VAL: {
        sizes: sizes
      }
    },
    resizeMode: resizeMode,
    priority: priority,
    style: [
      Caml_option.some(Curry._1(styled, styles.absoluteFillObject)),
      Caml_option.some(tmp$1),
      imageStyle
    ]
  };
  if (imageTestID !== undefined) {
    tmp$4.testID = imageTestID;
  }
  return React.createElement(Box$DecidrIoUniversalComponents.make, tmp, React.createElement(Ui__MarkotImage.make, tmp$4), children);
}

var make = Ui__MarkotImageBackground;

export {
  make ,
}
/* match Not a pure module */
