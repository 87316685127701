// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

import runtimeConfig from "@markot/app/src/config/runtime-config.json";

const SENTRY_DSN =
  process.env.MARKOT_SENTRY_DSN_WEB ||
  process.env.NEXT_PUBLIC_MARKOT_SENTRY_DSN_WEB;

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.05,
  normalizeDepth: 5,

  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [runtimeConfig.apiUrl],
    }),
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ["error", "warn"],
    }),
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
