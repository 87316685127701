// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";

function map(param, transform) {
  return {
          pageInfo: param.pageInfo,
          edges: Belt_Array.map(param.edges, (function (param) {
                  return {
                          cursor: param.cursor,
                          node: Curry._1(transform, param.node)
                        };
                }))
        };
}

var Connection = {
  map: map
};

function map$1(param, transform) {
  return {
          totalItems: param.totalItems,
          pageInfo: param.pageInfo,
          edges: Belt_Array.map(param.edges, (function (param) {
                  return {
                          cursor: param.cursor,
                          node: Curry._1(transform, param.node)
                        };
                }))
        };
}

var ConnectionWithTotal = {
  map: map$1
};

export {
  Connection ,
  ConnectionWithTotal ,
}
/* No side effect */
