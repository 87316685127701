// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__NextImage from "./Ui__NextImage.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";

function dpToSizes(x) {
  return "" + x.toString() + "px";
}

var match = Ui__Styling.describe({
      webImageContainer: {
        TAG: /* Static */0,
        _0: {
          overflow: "hidden"
        }
      },
      common: {
        TAG: /* Dynamic */1,
        _0: (function (param) {
            var tmp = {};
            var tmp$1 = Belt_Option.map(param[1], (function (prim) {
                    return prim;
                  }));
            if (tmp$1 !== undefined) {
              tmp.height = Caml_option.valFromOption(tmp$1);
            }
            var tmp$2 = Belt_Option.map(param[0], (function (prim) {
                    return prim;
                  }));
            if (tmp$2 !== undefined) {
              tmp.width = Caml_option.valFromOption(tmp$2);
            }
            return tmp;
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function toObjectFit(resizeMode) {
  if (resizeMode === "contain") {
    return "contain";
  } else {
    return "cover";
  }
}

function toRNResizeMode(resizeMode) {
  if (resizeMode === "contain") {
    return "contain";
  } else {
    return "cover";
  }
}

var ResizeMode = {
  toObjectFit: toObjectFit,
  toRNResizeMode: toRNResizeMode
};

var Size = {};

var _filterId = {
  contents: 0
};

function getWidthAndHeight(size) {
  var variant = size.NAME;
  if (variant === "fluid") {
    return [
            undefined,
            undefined
          ];
  }
  if (variant === "fixedHeight") {
    var match = size.VAL;
    var height = match.height;
    return [
            height * match.aspectRatio,
            height
          ];
  }
  if (variant !== "fixedWidth") {
    if (variant === "fixedWidthAutoHeight") {
      return [
              size.VAL.width,
              undefined
            ];
    } else {
      return [
              undefined,
              size.VAL.height
            ];
    }
  }
  var match$1 = size.VAL;
  var width = match$1.width;
  return [
          width,
          width * match$1.aspectRatio
        ];
}

function getImageStyle(size) {
  var variant = size.NAME;
  if (variant === "fixedWidthAutoHeight" || variant === "fluid") {
    return {
            flex: 1
          };
  }
  
}

function Make(Adapter) {
  var Ui__ImageFactory$Make = function (Props) {
    var image = Props.image;
    var alt = Props.alt;
    var size = Props.size;
    var resizeModeOpt = Props.resizeMode;
    var priorityOpt = Props.priority;
    var style = Props.style;
    var testID = Props.testID;
    var tintColor = Props.tintColor;
    var resizeMode = resizeModeOpt !== undefined ? resizeModeOpt : "cover";
    var priority = priorityOpt !== undefined ? priorityOpt : "normal";
    var filterRef = React.useRef((_filterId.contents = _filterId.contents + 1 | 0, _filterId.contents));
    var match = getWidthAndHeight(size);
    var imageStyle = getImageStyle(size);
    var variant = size.NAME;
    var sizes;
    if (variant === "fluid") {
      sizes = size.VAL.sizes;
    } else if (variant === "fixedHeight") {
      var match$1 = size.VAL;
      sizes = dpToSizes(match$1.height * match$1.aspectRatio);
    } else {
      sizes = variant === "fixedWidthAutoHeight" || variant === "fixedWidth" ? dpToSizes(size.VAL.width) : size.VAL.sizes;
    }
    var styled = Curry._1(stylesheet, [
          match[0],
          match[1]
        ]);
    if (ReactNative.Platform.OS === "web") {
      var tmp = {
        style: [
          Caml_option.some(Curry._1(styled, styles.webImageContainer)),
          Caml_option.some(Curry._1(styled, styles.common)),
          {
            filter: "url(#image-tint-" + String(filterRef.current) + ""
          },
          imageStyle,
          style
        ],
        children: null
      };
      if (testID !== undefined) {
        tmp.testID = testID;
      }
      return React.createElement(ReactNative.View, tmp, React.createElement(Ui__NextImage.make, {
                      src: image.src,
                      priority: priority !== "normal",
                      layout: "fill",
                      sizes: sizes,
                      alt: alt,
                      loader: (function (param) {
                          return Curry._1(Adapter.nextLoader, {
                                      src: param.src,
                                      width: param.width,
                                      quality: param.quality
                                    });
                        }),
                      objectFit: toObjectFit(resizeMode),
                      unoptimized: Adapter.unoptimized
                    }), SimpleReact$DecidrIoUtils.renderIfSome(tintColor, (function (tintColor) {
                        return React.createElement("svg", {
                                    style: {
                                      height: "0px",
                                      position: "absolute",
                                      visibility: "hidden",
                                      width: "0px"
                                    }
                                  }, React.createElement("defs", undefined, React.createElement("filter", {
                                            id: "image-tint-" + String(filterRef.current) + "",
                                            suppressContentEditableWarning: true
                                          }, React.createElement("feFlood", {
                                                key: tintColor,
                                                floodColor: tintColor
                                              }), React.createElement("feComposite", {
                                                in2: "SourceAlpha",
                                                operator: "atop"
                                              }))));
                      })));
    }
    var tmp$1 = {};
    if (tintColor !== undefined) {
      tmp$1.tintColor = Caml_option.valFromOption(tintColor);
    }
    var tmp$2 = {
      accessibilityLabel: alt,
      resizeMode: toRNResizeMode(resizeMode),
      source: Curry._2(Adapter.makeSourcesForNative, image.src, image.width / image.height),
      style: [
        Caml_option.some(Curry._1(styled, styles.common)),
        Caml_option.some(tmp$1),
        imageStyle,
        style
      ]
    };
    if (testID !== undefined) {
      tmp$2.testID = testID;
    }
    return React.createElement(ReactNative.Image, tmp$2);
  };
  return {
          make: Ui__ImageFactory$Make
        };
}

export {
  dpToSizes ,
  ResizeMode ,
  Size ,
  getWidthAndHeight ,
  getImageStyle ,
  Make ,
}
/* match Not a pure module */
