// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Heading from "../../text/components/Ui__Heading.bs.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";

var match = Ui__Styling.describe({
      containerStyle: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        },
        _1: (function (param) {
            var size = param.size;
            var theme = param.theme;
            return {
                    backgroundColor: theme.colors.primary,
                    borderRadius: size / 2,
                    height: size,
                    padding: theme.spacing._20,
                    width: size
                  };
          })
      },
      titleStyle: {
        TAG: /* Dual */2,
        _0: {
          textAlign: "center"
        },
        _1: (function (param) {
            return {
                    color: param.theme.colors.secondaryVariant3
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__OnboardingCircle(Props) {
  var title = Props.title;
  var titleHeadingLevelOpt = Props.titleHeadingLevel;
  var titleStyle = Props.titleStyle;
  var testIDOpt = Props.testID;
  var containerStyle = Props.containerStyle;
  var sizeOpt = Props.size;
  var textSize = Props.textSize;
  var titleHeadingLevel = titleHeadingLevelOpt !== undefined ? titleHeadingLevelOpt : 1;
  var testID = testIDOpt !== undefined ? testIDOpt : "onboarding-circle";
  var size = sizeOpt !== undefined ? sizeOpt : 365;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, {
        theme: theme,
        size: size
      });
  return React.createElement(ReactNative.View, {
              style: [
                Caml_option.some(Curry._1(styled, styles.containerStyle)),
                containerStyle
              ],
              testID: testID,
              children: React.createElement(Ui__Heading.make, {
                    fontFamily: theme.fontFamily.libre,
                    size: Belt_Option.getWithDefault(textSize, "title2"),
                    level: titleHeadingLevel,
                    children: title,
                    style: [
                      Caml_option.some(Curry._1(styled, styles.titleStyle)),
                      titleStyle
                    ],
                    ellipsizeMode: "tail",
                    numberOfLines: 3
                  })
            });
}

var make = Ui__OnboardingCircle;

export {
  make ,
}
/* match Not a pure module */
