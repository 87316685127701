// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as AppBagListPaginationFragment_graphql from "./AppBagListPaginationFragment_graphql.bs.js";

var Types = {};

function unwrap_fragment_bagWithArguments_edges_node_merchandise(u) {
  var v = u.__typename;
  if (v === "ProductVariant") {
    return {
            NAME: "ProductVariant",
            VAL: u
          };
  } else {
    return {
            NAME: "UnselectedUnionMember",
            VAL: v
          };
  }
}

function wrap_fragment_bagWithArguments_edges_node_merchandise(v) {
  if (v.NAME === "UnselectedUnionMember") {
    return {
            __typename: v.VAL
          };
  } else {
    return v.VAL;
  }
}

var fragmentConverter = {"__root":{"bagWithArguments_edges_node_merchandise":{"u":"fragment_bagWithArguments_edges_node_merchandise"},"bagWithArguments_edges_node":{"f":""}}};

var fragmentConverterMap = {
  fragment_bagWithArguments_edges_node_merchandise: unwrap_fragment_bagWithArguments_edges_node_merchandise
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function makeConnectionId(connectionParentDataId) {
  return RelayRuntime.ConnectionHandler.getConnectionID(connectionParentDataId, "AppBagListPaginationFragment_bagWithArguments", undefined);
}

function getConnectionNodes(connection) {
  return Belt_Array.keepMap(connection.edges, (function (edge) {
                return edge.node;
              }));
}

function currency_decode($$enum) {
  if ($$enum === "AUD") {
    return $$enum;
  }
  
}

var currency_fromString = currency_decode;

var Utils = {
  getConnectionNodes: getConnectionNodes,
  currency_decode: currency_decode,
  currency_fromString: currency_fromString
};

function makeNode(rescript_graphql_node_AppBagListPaginationFragment) {
  return ((function(){
var v0 = [
  "bagWithArguments"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 200,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": rescript_graphql_node_AppBagListPaginationFragment,
      "identifierField": "id"
    }
  },
  "name": "AppBagListPaginationFragment_bag",
  "selections": [
    (v1/*: any*/),
    {
      "alias": "bagWithArguments",
      "args": null,
      "concreteType": "BagItemConnection",
      "kind": "LinkedField",
      "name": "__AppBagListPaginationFragment_bagWithArguments_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BagItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BagItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "merchandise",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Product",
                          "kind": "LinkedField",
                          "name": "product",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v3/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Brand",
                              "kind": "LinkedField",
                              "name": "brand",
                              "plural": false,
                              "selections": [
                                (v3/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AttributeValue",
                          "kind": "LinkedField",
                          "name": "attributeValues",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "value",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ImageResource",
                          "kind": "LinkedField",
                          "name": "mainImage",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "url",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Money",
                          "kind": "LinkedField",
                          "name": "price",
                          "plural": false,
                          "selections": (v4/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "type": "ProductVariant",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Money",
                  "kind": "LinkedField",
                  "name": "pricePerQuantity",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AppBagItemCard_bagItem"
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Bag",
  "abstractKey": null
};
})());
}

var node = makeNode(AppBagListPaginationFragment_graphql.node);

export {
  Types ,
  unwrap_fragment_bagWithArguments_edges_node_merchandise ,
  wrap_fragment_bagWithArguments_edges_node_merchandise ,
  Internal ,
  makeConnectionId ,
  Utils ,
  node ,
}
/* node Not a pure module */
