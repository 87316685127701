// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MiscApi__Freshdesk from "../../../api-adapter-misc/src/MiscApi__Freshdesk.bs.js";

function submitSupportTicket(apiConfig, name, email, subject, description) {
  return MiscApi__Freshdesk.submitSupportTicket(apiConfig, name, email, subject, description).then(function (param) {
              return {
                      ticketId: param.id
                    };
            });
}

export {
  submitSupportTicket ,
}
/* MiscApi__Freshdesk Not a pure module */
