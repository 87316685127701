// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as MarkotApi__Infra from "./MarkotApi__Infra.bs.js";
import * as MarkotApi__BidInfo from "./MarkotApi__BidInfo.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as MarkotApi__CommonFragments from "./MarkotApi__CommonFragments.bs.js";
import * as MarkotApi__Scalars__DateTime from "./MarkotApi__Scalars__DateTime.bs.js";

var Raw = {};

var query = "fragment AuctionBasicDataFields on Auction   {\nid  \nsettings  {\nendsAt  \n}\n\ntotalBids  \nhighestBidInfo  {\n...BidInfoFields   \n}\n\nlatestBidAt  \nviewerLastActiveBidInfo  {\n...BidInfoFields   \n}\n\npricing  {\n__typename\n...on WithBuyout   {\nbuyNowPrice  {\n...MoneyFields   \n}\n\nminBidLimit  {\n__typename\n...on MinAmount   {\namount  {\n...MoneyFields   \n}\n\n}\n\n...on MinPercentageOfBuyNowPrice   {\npercent  \namount  {\n...MoneyFields   \n}\n\n}\n\n}\n\n}\n\n...on WithoutBuyout   {\nminBidAmount  \n}\n\n}\n\n}\n" + MarkotApi__BidInfo.BidInfoFields.query + MarkotApi__CommonFragments.MoneyFields.query;

function parse(value) {
  var value$1 = value.settings;
  var value$2 = value$1.endsAt;
  var value$3 = value.highestBidInfo;
  var value$4 = value.latestBidAt;
  var value$5 = value.viewerLastActiveBidInfo;
  var value$6 = value.pricing;
  var typename = value$6["__typename"];
  var tmp;
  switch (typename) {
    case "WithBuyout" :
        var value$7 = value$6.buyNowPrice;
        var value$8 = value$6.minBidLimit;
        var typename$1 = value$8["__typename"];
        var tmp$1;
        switch (typename$1) {
          case "MinAmount" :
              var value$9 = value$8.amount;
              tmp$1 = {
                NAME: "MinAmount",
                VAL: {
                  amount: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$9)
                }
              };
              break;
          case "MinPercentageOfBuyNowPrice" :
              var value$10 = value$8.amount;
              tmp$1 = {
                NAME: "MinPercentageOfBuyNowPrice",
                VAL: {
                  percent: value$8.percent,
                  amount: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$10)
                }
              };
              break;
          default:
            tmp$1 = {
              NAME: "FutureAddedValue",
              VAL: value$8
            };
        }
        tmp = {
          NAME: "WithBuyout",
          VAL: {
            buyNowPrice: MarkotApi__CommonFragments.MoneyFields.verifyArgsAndParse("MoneyFields", value$7),
            minBidLimit: tmp$1
          }
        };
        break;
    case "WithoutBuyout" :
        tmp = {
          NAME: "WithoutBuyout",
          VAL: {
            minBidAmount: value$6.minBidAmount
          }
        };
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$6
      };
  }
  return {
          id: value.id,
          settings: {
            endsAt: !(value$2 == null) ? MarkotApi__Scalars__DateTime.parse(value$2) : undefined
          },
          totalBids: value.totalBids,
          highestBidInfo: !(value$3 == null) ? MarkotApi__BidInfo.BidInfoFields.verifyArgsAndParse("BidInfoFields", value$3) : undefined,
          latestBidAt: !(value$4 == null) ? MarkotApi__Scalars__DateTime.parse(value$4) : undefined,
          viewerLastActiveBidInfo: !(value$5 == null) ? MarkotApi__BidInfo.BidInfoFields.verifyArgsAndParse("BidInfoFields", value$5) : undefined,
          pricing: tmp
        };
}

function serialize(value) {
  var value$1 = value.pricing;
  var variant = value$1.NAME;
  var pricing;
  if (variant === "WithoutBuyout") {
    var value$2 = value$1.VAL.minBidAmount;
    pricing = {
      __typename: "WithoutBuyout",
      minBidAmount: value$2
    };
  } else if (variant === "WithBuyout") {
    var value$3 = value$1.VAL;
    var value$4 = value$3.minBidLimit;
    var variant$1 = value$4.NAME;
    var minBidLimit;
    if (variant$1 === "MinPercentageOfBuyNowPrice") {
      var value$5 = value$4.VAL;
      var value$6 = value$5.amount;
      var amount = MarkotApi__CommonFragments.MoneyFields.serialize(value$6);
      var value$7 = value$5.percent;
      minBidLimit = {
        __typename: "MinPercentageOfBuyNowPrice",
        percent: value$7,
        amount: amount
      };
    } else if (variant$1 === "FutureAddedValue") {
      minBidLimit = value$4.VAL;
    } else {
      var value$8 = value$4.VAL.amount;
      var amount$1 = MarkotApi__CommonFragments.MoneyFields.serialize(value$8);
      minBidLimit = {
        __typename: "MinAmount",
        amount: amount$1
      };
    }
    var value$9 = value$3.buyNowPrice;
    var buyNowPrice = MarkotApi__CommonFragments.MoneyFields.serialize(value$9);
    pricing = {
      __typename: "WithBuyout",
      buyNowPrice: buyNowPrice,
      minBidLimit: minBidLimit
    };
  } else {
    pricing = value$1.VAL;
  }
  var value$10 = value.viewerLastActiveBidInfo;
  var viewerLastActiveBidInfo = value$10 !== undefined ? MarkotApi__BidInfo.BidInfoFields.serialize(value$10) : null;
  var value$11 = value.latestBidAt;
  var latestBidAt = value$11 !== undefined ? MarkotApi__Scalars__DateTime.serialize(value$11) : null;
  var value$12 = value.highestBidInfo;
  var highestBidInfo = value$12 !== undefined ? MarkotApi__BidInfo.BidInfoFields.serialize(value$12) : null;
  var value$13 = value.totalBids;
  var value$14 = value.settings;
  var value$15 = value$14.endsAt;
  var endsAt = value$15 !== undefined ? MarkotApi__Scalars__DateTime.serialize(value$15) : null;
  var settings = {
    endsAt: endsAt
  };
  var value$16 = value.id;
  return {
          id: value$16,
          settings: settings,
          totalBids: value$13,
          highestBidInfo: highestBidInfo,
          latestBidAt: latestBidAt,
          viewerLastActiveBidInfo: viewerLastActiveBidInfo,
          pricing: pricing
        };
}

function verifyArgsAndParse(_AuctionBasicDataFields, value) {
  return parse(value);
}

function verifyName(param) {
  
}

function makeAuction(param) {
  var pricing = param.pricing;
  var variant = pricing.NAME;
  var tmp;
  if (variant === "WithoutBuyout") {
    tmp = {
      TAG: /* WithoutBuyout */1,
      minBidAmount: pricing.VAL.minBidAmount
    };
  } else if (variant === "WithBuyout") {
    var match = pricing.VAL;
    var minBidLimit = match.minBidLimit;
    var variant$1 = minBidLimit.NAME;
    var tmp$1;
    if (variant$1 === "MinPercentageOfBuyNowPrice") {
      var match$1 = minBidLimit.VAL;
      tmp$1 = {
        TAG: /* MinPercentageOfBuyNowPrice */1,
        percent: match$1.percent,
        amount: MarkotApi__CommonFragments.makeMoney(match$1.amount)
      };
    } else if (variant$1 === "FutureAddedValue") {
      RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "Auction makeAuction - WithBuyout.minBidLimit unknown typename", minBidLimit);
      tmp$1 = Js_exn.raiseError("WithBuyout.minBidLimit unknown typename.");
    } else {
      tmp$1 = {
        TAG: /* MinAmount */0,
        amount: MarkotApi__CommonFragments.makeMoney(minBidLimit.VAL.amount)
      };
    }
    tmp = {
      TAG: /* WithBuyout */0,
      buyNowPrice: MarkotApi__CommonFragments.makeMoney(match.buyNowPrice),
      minBidLimit: tmp$1
    };
  } else {
    RescriptUtils__Logger.warn2(MarkotApi__Infra.logger, "Auction makeAuction - Auction.pricing unknown typename", pricing);
    tmp = Js_exn.raiseError("Auction.pricing unknown typename.");
  }
  return {
          id: MarkotApi__Id.parseExn(param.id),
          pricing: tmp,
          settings: {
            endsAt: param.settings.endsAt
          },
          totalBids: param.totalBids,
          highestBidInfo: Belt_Option.map(param.highestBidInfo, MarkotApi__BidInfo.makeBidInfo),
          latestBidAt: param.latestBidAt,
          viewerLastActiveBidInfo: Belt_Option.map(param.viewerLastActiveBidInfo, MarkotApi__BidInfo.makeBidInfo)
        };
}

function AuctionBasicDataFields_unsafe_fromJson(prim) {
  return prim;
}

function AuctionBasicDataFields_toJson(prim) {
  return prim;
}

var AuctionBasicDataFields = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName,
  unsafe_fromJson: AuctionBasicDataFields_unsafe_fromJson,
  toJson: AuctionBasicDataFields_toJson
};

export {
  AuctionBasicDataFields ,
  makeAuction ,
}
/* MarkotApi__Id Not a pure module */
