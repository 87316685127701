// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Fetch from "bs-fetch/src/Fetch.bs.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as GraphqlClient__Infra from "./GraphqlClient__Infra.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";

var Graphql_error = /* @__PURE__ */Caml_exceptions.create("GraphqlClient.Graphql_error");

var graphql = require("graphql");
var gql = require("graphql-tag").default;
;

var normalize = (function normalize(rawQuery) {
  // console.log("normalizing query...", rawQuery);
  const normalized = graphql.print(gql(rawQuery));
  // console.log("normalizing query... normalized", normalized)
  return normalized;
});

var $$Error = {};

function query(url, extraHeaders, Operation, variables, apiName, param) {
  RescriptUtils__Logger.info3(GraphqlClient__Infra.logger, "[" + Belt_Option.getWithDefault(apiName, url) + "] Graphql.query sending...", Belt_Option.getWithDefault(Belt_Array.get(Operation.query.split("\n"), 0), "(error getting query 1st line)") + " ...", Belt_Option.getWithDefault(variables, "(no variables)"));
  var defaultHeaders = [
    [
      "content-type",
      "application/json"
    ],
    [
      "accept",
      "application/json"
    ]
  ];
  return fetch(url, Fetch.RequestInit.make(/* Post */2, Caml_option.some(Js_dict.fromArray(Belt_Option.getWithDefault(Belt_Option.map(extraHeaders, (function (extraHeaders) {
                                        return Belt_Array.concat(defaultHeaders, extraHeaders);
                                      })), defaultHeaders))), Caml_option.some(JSON.stringify(Js_dict.fromArray([
                                  [
                                    "query",
                                    normalize(Operation.query)
                                  ],
                                  [
                                    "variables",
                                    Curry._1(Operation.serializeVariables, variables)
                                  ]
                                ]))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (resp) {
              if (resp.ok) {
                return Fetch.$$Response.json(resp).then(function (_json) {
                            var errors = (_json["errors"]);
                            if (errors !== undefined) {
                              RescriptUtils__Logger.error2(GraphqlClient__Infra.logger, (new Error("Error(s) in GraphQL response (query)")), errors);
                              return Js_exn.raiseError("Request failed. Details: " + Belt_Option.getWithDefault(JSON.stringify(errors), "(no info)"));
                            } else {
                              return Curry._1(Operation.parse, (_json["data"]));
                            }
                          });
              } else {
                RescriptUtils__Logger.error2(GraphqlClient__Infra.logger, (new Error("GraphQL request failed (query)")), {
                      status: resp.status,
                      statusText: resp.statusText
                    });
                return Promise.reject({
                            RE_EXN_ID: Graphql_error,
                            _1: "Request failed. Status code: " + resp.status.toString() + ", status text: " + resp.statusText + ""
                          });
              }
            });
}

function rawQuery(url, extraHeaders, query, variables, apiName, param) {
  RescriptUtils__Logger.info3(GraphqlClient__Infra.logger, "[" + Belt_Option.getWithDefault(apiName, url) + "] Graphql.rawQuery sending...", Belt_Option.getWithDefault(Belt_Array.get(Belt_Array.keep(query.split("\n"), (function (s) {
                      return s.length > 0;
                    })), 0), "(error getting query 1st line)") + " ...", Belt_Option.getWithDefault(variables, "(no variables)"));
  var defaultHeaders = [
    [
      "content-type",
      "application/json"
    ],
    [
      "accept",
      "application/json"
    ]
  ];
  return fetch(url, Fetch.RequestInit.make(/* Post */2, Caml_option.some(Js_dict.fromArray(Belt_Option.getWithDefault(Belt_Option.map(extraHeaders, (function (extraHeaders) {
                                        return Belt_Array.concat(defaultHeaders, extraHeaders);
                                      })), defaultHeaders))), Caml_option.some(JSON.stringify(Js_dict.fromArray([
                                  [
                                    "query",
                                    query
                                  ],
                                  [
                                    "variables",
                                    Js_json.serializeExn(variables)
                                  ]
                                ]))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)).then(function (resp) {
              if (resp.ok) {
                return Fetch.$$Response.json(resp).then(function (_json) {
                            var errors = (_json["errors"]);
                            if (errors !== undefined) {
                              RescriptUtils__Logger.error2(GraphqlClient__Infra.logger, (new Error("Error(s) in GraphQL response (query)")), errors);
                              return Js_exn.raiseError("Request failed.");
                            } else {
                              return (_json["data"]);
                            }
                          });
              } else {
                RescriptUtils__Logger.error2(GraphqlClient__Infra.logger, (new Error("GraphQL request failed (rawQuery)")), {
                      status: resp.status,
                      statusText: resp.statusText
                    });
                return Promise.reject({
                            RE_EXN_ID: Graphql_error,
                            _1: "Request failed. Status code: " + resp.status.toString() + ", status text: " + resp.statusText + ""
                          });
              }
            });
}

export {
  Graphql_error ,
  $$Error ,
  query ,
  rawQuery ,
}
/*  Not a pure module */
