// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils__A11y from "../../../../utils/src/Utils__A11y.bs.js";
import * as Ui__MarkotImageBackground from "../../image/components/markotImageBackground/Ui__MarkotImageBackground.bs.js";
import * as GroupFactory$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/group/components/GroupFactory.bs.js";

function Ui__Group$Group__(Props) {
  var kind = Props.kind;
  var image = Props.image;
  var imageTestID = Props.imageTestID;
  var testID = Props.testID;
  var style = Props.style;
  var children = Props.children;
  var imageStyle = Props.imageStyle;
  var accessibilityLabel = Props.accessibilityLabel;
  var accessibilityRole = Props.accessibilityRole;
  var resizeMode = Props.resizeMode;
  var priority = Props.priority;
  var sizes = Props.sizes;
  var tmp = {
    image: image,
    alt: Belt_Option.getWithDefault(accessibilityLabel, ""),
    sizes: Belt_Option.getWithDefault(sizes, "100vw"),
    children: children
  };
  if (kind !== undefined) {
    tmp.kind = Caml_option.valFromOption(kind);
  }
  if (accessibilityRole !== undefined) {
    tmp.accessibilityRole = Caml_option.valFromOption(accessibilityRole);
  }
  var tmp$1 = Belt_Option.map(resizeMode, (function (resizeMode) {
          if (resizeMode === "contain") {
            return "contain";
          } else {
            return "cover";
          }
        }));
  if (tmp$1 !== undefined) {
    tmp.resizeMode = Caml_option.valFromOption(tmp$1);
  }
  if (priority !== undefined) {
    tmp.priority = Caml_option.valFromOption(priority);
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (imageStyle !== undefined) {
    tmp.imageStyle = Caml_option.valFromOption(imageStyle);
  }
  if (testID !== undefined) {
    tmp.testID = testID;
  }
  if (imageTestID !== undefined) {
    tmp.imageTestID = imageTestID;
  }
  return React.createElement(Ui__MarkotImageBackground.make, tmp);
}

var Group__ = GroupFactory$DecidrIoUniversalComponents.Make({
      make: Ui__Group$Group__
    });

function Ui__Group(Props) {
  var kindOpt = Props.kind;
  var spacing = Props.spacing;
  var children = Props.children;
  var containerStyle = Props.containerStyle;
  var orientationOpt = Props.orientation;
  var reversedOpt = Props.reversed;
  var testID = Props.testID;
  var includeEndSpacingOpt = Props.includeEndSpacing;
  var includeStartSpacingOpt = Props.includeStartSpacing;
  var accessibilityLabel = Props.accessibilityLabel;
  var accessibilityRole = Props.accessibilityRole;
  var imageBackground = Props.imageBackground;
  var imageBackgroundStyle = Props.imageBackgroundStyle;
  var resizeMode = Props.resizeMode;
  var sizes = Props.sizes;
  var priority = Props.priority;
  var testMode = Props.testMode;
  var kind = kindOpt !== undefined ? kindOpt : "NonPressable";
  var orientation = orientationOpt !== undefined ? orientationOpt : "vertical";
  var reversed = reversedOpt !== undefined ? reversedOpt : false;
  var includeEndSpacing = includeEndSpacingOpt !== undefined ? includeEndSpacingOpt : false;
  var includeStartSpacing = includeStartSpacingOpt !== undefined ? includeStartSpacingOpt : false;
  var tmp = {
    kind: kind,
    children: children,
    orientation: orientation,
    reversed: reversed,
    includeEndSpacing: includeEndSpacing,
    includeStartSpacing: includeStartSpacing
  };
  if (spacing !== undefined) {
    tmp.spacing = Caml_option.valFromOption(spacing);
  }
  if (containerStyle !== undefined) {
    tmp.containerStyle = Caml_option.valFromOption(containerStyle);
  }
  if (testID !== undefined) {
    tmp.testID = Caml_option.valFromOption(testID);
  }
  if (accessibilityLabel !== undefined) {
    tmp.accessibilityLabel = Caml_option.valFromOption(accessibilityLabel);
  }
  var tmp$1 = Belt_Option.flatMap(accessibilityRole, Utils__A11y.toSafeA11yRole);
  if (tmp$1 !== undefined) {
    tmp.accessibilityRole = Caml_option.valFromOption(tmp$1);
  }
  if (imageBackground !== undefined) {
    tmp.imageBackground = Caml_option.valFromOption(imageBackground);
  }
  if (imageBackgroundStyle !== undefined) {
    tmp.imageBackgroundStyle = Caml_option.valFromOption(imageBackgroundStyle);
  }
  if (resizeMode !== undefined) {
    tmp.resizeMode = Caml_option.valFromOption(resizeMode);
  }
  if (priority !== undefined) {
    tmp.priority = Caml_option.valFromOption(priority);
  }
  if (sizes !== undefined) {
    tmp.sizes = Caml_option.valFromOption(sizes);
  }
  if (testMode !== undefined) {
    tmp.testMode = Caml_option.valFromOption(testMode);
  }
  return React.createElement(Group__.make, tmp);
}

var Spacer;

var make = Ui__Group;

export {
  Spacer ,
  make ,
}
/* Group__ Not a pure module */
