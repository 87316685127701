// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__MarkotText from "../text/components/Ui__MarkotText.bs.js";

var brandNameFallback = "—";

var brandNameFallbackA11yLabel = "Brand info unavailable";

function Ui__BrandNameFallback(Props) {
  var size = Props.size;
  var fontWeight = Props.fontWeight;
  var color = Props.color;
  var style = Props.style;
  var fontFamily = Props.fontFamily;
  var numberOfLinesOpt = Props.numberOfLines;
  var numberOfLines = numberOfLinesOpt !== undefined ? numberOfLinesOpt : 2;
  var tmp = {};
  if (fontWeight !== undefined) {
    tmp.fontWeight = (function () {
          switch (fontWeight) {
            case "normal" :
                return "normal";
            case "bold" :
                return "bold";
            case "_100" :
                return "100";
            case "_200" :
                return "200";
            case "_300" :
                return "300";
            case "_400" :
                return "400";
            case "_500" :
                return "500";
            case "_600" :
                return "600";
            case "_700" :
                return "700";
            case "_800" :
                return "800";
            case "_900" :
                return "900";
            
          }
        })();
  }
  var tmp$1 = {
    children: brandNameFallback,
    style: [
      Caml_option.some(tmp),
      style
    ],
    numberOfLines: numberOfLines,
    testID: "brand-name-fallback",
    accessibilityLabel: brandNameFallbackA11yLabel
  };
  if (fontFamily !== undefined) {
    tmp$1.fontFamily = Caml_option.valFromOption(fontFamily);
  }
  if (size !== undefined) {
    tmp$1.size = Caml_option.valFromOption(size);
  }
  if (color !== undefined) {
    tmp$1.color = Caml_option.valFromOption(color);
  }
  return React.createElement(Ui__MarkotText.make, tmp$1);
}

var make = Ui__BrandNameFallback;

export {
  brandNameFallback ,
  brandNameFallbackA11yLabel ,
  make ,
}
/* react Not a pure module */
