// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "../../../api-adapter-markot/src/MarkotApi__Id.bs.js";
import * as GraphqlCore__Types from "../../../graphql-core/src/GraphqlCore__Types.bs.js";
import * as MarkotApi__MarkotUser from "../../../api-adapter-markot/src/MarkotApi__MarkotUser.bs.js";
import * as GqlMiddleware__Entity__Bag from "./GqlMiddleware__Entity__Bag.bs.js";
import * as GqlMiddleware__Core__NodeId from "../core/GqlMiddleware__Core__NodeId.bs.js";
import * as GqlMiddleware__Entity__Address from "./GqlMiddleware__Entity__Address.bs.js";
import * as GqlMiddleware__Entity__PhoneNumber from "./GqlMiddleware__Entity__PhoneNumber.bs.js";
import * as GqlMiddleware__Entity__SellerRatingFee from "./GqlMiddleware__Entity__SellerRatingFee.bs.js";

function makeMarkotUserId(param) {
  return GqlMiddleware__Core__NodeId.make(1, "MarkotUser", param);
}

function makeMarkotUser(param) {
  var sellerRating = param.sellerRating;
  return {
          __typename: "MarkotUser",
          id: GqlMiddleware__Core__NodeId.make(1, "MarkotUser", MarkotApi__Id.unpackExn(param.id).internalId),
          aid: param.aid,
          firstName: param.firstName,
          lastName: param.lastName,
          email: param.email,
          bagId: Belt_Option.map(param.bagId, (function (bagId) {
                  return GqlMiddleware__Entity__Bag.makeBagId(MarkotApi__Id.unpackExn(bagId).internalId);
                })),
          phone: Belt_Option.map(param.phone, (function (param) {
                  return {
                          id: GqlMiddleware__Entity__PhoneNumber.makePhoneNumberId(MarkotApi__Id.unpackExn(param.id).internalId),
                          countryCallingCode: param.countryCallingCode,
                          phoneNumber: param.phoneNumber
                        };
                })),
          sellerRating: {
            id: GqlMiddleware__Entity__SellerRatingFee.makeSellerRatingFeeId(MarkotApi__Id.unpackExn(sellerRating.id).internalId),
            title: sellerRating.title,
            percent: sellerRating.percent,
            description: sellerRating.description
          },
          defaultPaymentMethodId2: param.defaultPaymentMethodId2,
          defaultBillingAddressId: Belt_Option.map(param.defaultBillingAddressId, (function (defaultBillingAddressId) {
                  return GqlMiddleware__Entity__Address.makeAddressId(MarkotApi__Id.unpackExn(defaultBillingAddressId).internalId);
                })),
          defaultShippingAddressId: Belt_Option.map(param.defaultShippingAddressId, (function (defaultShippingAddressId) {
                  return GqlMiddleware__Entity__Address.makeAddressId(MarkotApi__Id.unpackExn(defaultShippingAddressId).internalId);
                })),
          addresses: Belt_Option.map(param.addresses, (function (x) {
                  return GraphqlCore__Types.Connection.map(x, GqlMiddleware__Entity__Address.makeAddress);
                })),
          expoNotificationIds: param.expoNotificationIds
        };
}

function updateMarkotUser(apiConfig, param) {
  return MarkotApi__MarkotUser.updateMarkotUser(apiConfig, {
                email: param.email,
                phone: param.phone,
                firstName: param.firstName,
                lastName: param.lastName,
                defaultBillingAddressId: param.defaultBillingAddressId,
                defaultShippingAddressId: param.defaultShippingAddressId,
                stripePaymentMethodId: param.stripePaymentMethodId
              }).then(function (result) {
              return {
                      __typename: "UpdateMarkotUserPayload",
                      markotUser: makeMarkotUser(result.markotUser),
                      markotUserId: GqlMiddleware__Core__NodeId.make(1, "MarkotUser", MarkotApi__Id.unpackExn(result.markotUserId).internalId)
                    };
            });
}

function addExpoToken(apiConfig, param) {
  return MarkotApi__MarkotUser.addExpoToken(apiConfig, {
                expoNotificationToken: param.expoNotificationToken
              }).then(function (result) {
              return {
                      __typename: "AddExpoTokenPayload",
                      expoTokens: result.expoTokens
                    };
            });
}

function removeExpoToken(apiConfig, param) {
  return MarkotApi__MarkotUser.removeExpoToken(apiConfig, {
                expoNotificationToken: param.expoNotificationToken
              }).then(function (result) {
              return {
                      __typename: "RemoveExpoTokenPayload",
                      expoTokens: result.expoTokens
                    };
            });
}

function fetchBasicData(apiConfig) {
  return MarkotApi__MarkotUser.fetchBasicData(apiConfig).then(function (markotUser) {
              return Belt_Option.map(markotUser, makeMarkotUser);
            });
}

export {
  makeMarkotUserId ,
  makeMarkotUser ,
  fetchBasicData ,
  updateMarkotUser ,
  addExpoToken ,
  removeExpoToken ,
}
/* MarkotApi__Id Not a pure module */
