// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils__Graphql from "../graphql/Utils__Graphql.bs.js";
import * as Ignored$DecidrIoUtils from "@decidr-io/utils/src/functions/Ignored.bs.js";
import * as Utils__SegmentAnalytics from "./Utils__SegmentAnalytics.bs.js";

function adaptDeviceContextForSegment(param) {
  return {
          feature_flags: param.featureFlags
        };
}

function adaptProductForSegment(param) {
  return {
          product_id: Belt_Option.map(param.variantId, Utils__Graphql.getShopifyInternalId),
          sku: param.sku,
          name: param.name,
          category: param.category,
          brand: param.brand,
          test_product: param.testProduct,
          variant: param.variantName,
          price: param.price,
          compare_at_price: param.compareAtPrice,
          currency: param.currency,
          quantity: param.quantity,
          value: param.totalPrice,
          coupon: param.coupon,
          url: param.url,
          image_url: param.imageUrl,
          affiliation: param.affiliation,
          discount: param.discount
        };
}

function adaptPrelovedProductForSegment(param) {
  return {
          product_id: Belt_Option.map(param.variantId, Utils__Graphql.getShopifyInternalId),
          product_for_preloved_id: param.productId,
          sku: param.sku,
          name: param.name,
          category: param.category,
          brand: param.brand,
          test_product: param.testProduct,
          price: param.price,
          compare_at_price: param.compareAtPrice,
          currency: param.currency,
          quantity: param.quantity,
          coupon: param.coupon,
          url: param.url,
          image_url: param.imageUrl,
          affiliation: param.affiliation,
          discount: param.discount
        };
}

function adaptCartProductForSegment(param) {
  return {
          cart_id: param.cartId,
          product_id: Belt_Option.map(param.variantId, Utils__Graphql.getShopifyInternalId),
          sku: param.sku,
          name: param.name,
          category: param.category,
          brand: param.brand,
          variant: param.variantName,
          price: param.price,
          compare_at_price: param.compareAtPrice,
          currency: param.currency,
          quantity: param.quantity,
          value: param.totalPrice,
          coupon: param.coupon,
          url: param.url,
          image_url: param.imageUrl,
          affiliation: param.affiliation,
          discount: param.discount
        };
}

function adaptWishlistProductForSegment(param) {
  return {
          wishlist_id: param.wishlistId,
          wishlist_name: param.wishlistName,
          wishlist_item_type: param.wishlistItemType,
          product_id: Belt_Option.map(param.variantId, Utils__Graphql.getShopifyInternalId),
          sku: param.sku,
          name: param.name,
          category: param.category,
          brand: param.brand,
          variant: param.variantName,
          price: param.price,
          compare_at_price: param.compareAtPrice,
          currency: param.currency,
          quantity: param.quantity,
          coupon: param.coupon,
          url: param.url,
          imageUrl: param.imageUrl,
          affiliation: param.affiliation,
          discount: param.discount
        };
}

function adaptWishlistInCartProductForSegment(param) {
  return {
          wishlist_id: param.wishlistId,
          wishlist_name: param.wishlistName,
          cart_id: param.cartId,
          product_id: Belt_Option.map(param.variantId, Utils__Graphql.getShopifyInternalId),
          sku: param.sku,
          name: param.name,
          category: param.category,
          brand: param.brand,
          variant: param.variantName,
          price: param.price,
          currency: param.currency,
          quantity: param.quantity,
          coupon: param.coupon,
          url: param.url,
          imageUrl: param.imageUrl,
          affiliation: param.affiliation,
          discount: param.discount
        };
}

function adaptShareProductForSegment(param) {
  return {
          share_url: param.shareUrl,
          share_via: param.shareVia,
          share_message: param.shareMessage,
          product_id: Belt_Option.map(param.variantId, Utils__Graphql.getShopifyInternalId),
          sku: param.sku,
          name: param.name,
          category: param.category,
          brand: param.brand,
          variant: param.variantName,
          price: param.price,
          currency: param.currency,
          quantity: param.quantity,
          coupon: param.coupon,
          url: param.url,
          image_url: param.imageUrl,
          affiliation: param.affiliation,
          discount: param.discount
        };
}

function adaptAddressForSegment(param) {
  return {
          city: param.city,
          country: param.country,
          description: param.description,
          extra_info: param.extraInfo,
          postal_code: param.postalCode,
          recipient_name: param.recipientName,
          state: param.state,
          street_line1: param.streetLine1,
          street_line2: param.streetLine2
        };
}

function adaptCheckoutForSegment(param) {
  return {
          order_id: param.orderId,
          cart_id: param.cartId,
          checkout_id: param.checkoutId,
          checkout_url: param.checkoutUrl,
          affiliation: param.affiliation,
          subtotal: param.subtotal,
          total: param.total,
          quantity: param.quantity,
          revenue: param.revenue,
          shipping: param.shipping,
          tax: param.tax,
          discount: param.discount,
          coupon: param.coupon,
          currency: param.currency,
          products: Belt_Option.map(param.products, (function (products) {
                  return Belt_Array.map(products, adaptProductForSegment);
                }))
        };
}

function adaptPrelovedItemPropertiesForSegment(param) {
  return {
          preloved_id: param.prelovedId,
          number_of_bids: param.numberOfBids,
          buy_now_price: param.buyNowPrice,
          highest_bid: param.highestBid,
          currency: param.currency,
          condition: param.condition,
          test_product: param.testProduct,
          product: Belt_Option.map(param.product, adaptPrelovedProductForSegment)
        };
}

function adaptFiltersForSegment(param) {
  return {
          name: param.name,
          values: param.values
        };
}

function adaptVideoPlaybackForSegment(param) {
  return {
          session_id: param.sessionId,
          content_asset_id: param.contentAssetId,
          content_pod_id: param.contentPodId,
          ad_asset_id: param.addAssetId,
          ad_pod_id: param.adPodId,
          ad_type: param.adType,
          position: param.position,
          total_length: param.totalLength,
          bitrate: param.bitrate,
          framerate: param.frameRate,
          video_player: param.videoPlayer,
          sound: param.sound,
          full_screen: param.fullScreen,
          ad_enabled: param.adEnabled,
          quality: param.quality,
          method: param.method,
          livestream: param.liveStream
        };
}

function adaptVideoContentForSegment(param) {
  return {
          session_id: param.sessionId,
          asset_id: param.assetId,
          pod_id: param.podId,
          title: param.title,
          description: param.description,
          keywords: param.keywords,
          season: param.season,
          episode: param.episode,
          genre: param.genre,
          program: param.program,
          publisher: param.publisher,
          position: param.position,
          total_length: param.totalLength,
          channel: param.channel,
          full_episode: param.fullEpisode,
          livestream: param.liveStream,
          airdate: param.airDate,
          bitrate: param.bitrate,
          framerate: param.frameRate
        };
}

function adaptVideoAdForSegment(param) {
  return {
          session_id: param.sessionId,
          asset_id: param.assetId,
          pod_id: param.podId,
          pod_position: param.podPosition,
          pod_length: param.podLength,
          type: param._type,
          title: param.title,
          publisher: param.publisher,
          position: param.position,
          total_length: param.totalLength,
          load_type: param.loadType,
          content: param.content,
          quartile: param.quartile
        };
}

var deviceContextEventNameForSegment = "Device Context Set";

function useSegmentAnalytics(param) {
  var analytics = Utils__SegmentAnalytics.useAnalytics(undefined);
  var segmentTrack = function ($$event, properties, debugContext) {
    Ignored$DecidrIoUtils.promise($$Promise.$$catch(analytics.track($$event, properties), (function (error) {
                console.warn("" + debugContext + " | Error tracking " + $$event + "", error);
                return Promise.resolve(undefined);
              })));
  };
  var track = function (track$1, debugContext) {
    switch (track$1.TAG | 0) {
      case /* DeviceContextSet */0 :
          return segmentTrack(deviceContextEventNameForSegment, adaptDeviceContextForSegment(track$1._0), debugContext);
      case /* NewProductsSearched */1 :
          var match = track$1._0;
          return segmentTrack("Products Searched", {
                      query: match.query,
                      origin: match.origin,
                      hits: match.hits,
                      filters: Belt_Option.map(match.filters, (function (__x) {
                              return Belt_Array.map(__x, adaptFiltersForSegment);
                            })),
                      products: Belt_Option.map(match.products, (function (products) {
                              return Belt_Array.map(products, adaptProductForSegment);
                            }))
                    }, debugContext);
      case /* PrelovedProductsSearched */2 :
          var match$1 = track$1._0;
          return segmentTrack("Preloved Products Searched", {
                      query: match$1.query,
                      origin: match$1.origin,
                      hits: match$1.hits,
                      filters: Belt_Option.map(match$1.filters, (function (__x) {
                              return Belt_Array.map(__x, adaptFiltersForSegment);
                            })),
                      products: Belt_Option.map(match$1.products, (function (products) {
                              return Belt_Array.map(products, adaptPrelovedItemPropertiesForSegment);
                            }))
                    }, debugContext);
      case /* SellerProductsSearched */3 :
          var match$2 = track$1._0;
          return segmentTrack("Seller Products Searched", {
                      query: match$2.query,
                      origin: match$2.origin,
                      hits: match$2.hits,
                      products: Belt_Option.map(match$2.products, (function (__x) {
                              return Belt_Array.map(__x, adaptProductForSegment);
                            }))
                    }, debugContext);
      case /* ProductListViewed */4 :
          var match$3 = track$1._0;
          return segmentTrack("Product List Viewed", {
                      list_id: match$3.listId,
                      category: match$3.category,
                      products: Belt_Option.map(match$3.products, (function (__x) {
                              return Belt_Array.map(__x, adaptProductForSegment);
                            }))
                    }, debugContext);
      case /* ProductListFiltered */5 :
          var match$4 = track$1._0;
          return segmentTrack("Product List Filtered", {
                      list_id: match$4.listId,
                      category: match$4.category,
                      sorts: Belt_Option.map(match$4.sorts, (function (__x) {
                              return Belt_Array.map(__x, (function (param) {
                                            return {
                                                    type: param.type_,
                                                    name: param.value
                                                  };
                                          }));
                            })),
                      filters: Belt_Option.map(match$4.filters, (function (__x) {
                              return Belt_Array.map(__x, (function (param) {
                                            return {
                                                    type: param.type_,
                                                    name: param.value
                                                  };
                                          }));
                            })),
                      products: Belt_Option.map(match$4.products, (function (__x) {
                              return Belt_Array.map(__x, adaptProductForSegment);
                            }))
                    }, debugContext);
      case /* ProductClicked */6 :
          return segmentTrack("Product Clicked", adaptProductForSegment(track$1._0), debugContext);
      case /* ProductViewed */7 :
          return segmentTrack("Product Viewed", adaptProductForSegment(track$1._0), debugContext);
      case /* AddToCart */8 :
          return segmentTrack("Product Added", adaptCartProductForSegment(track$1._0), debugContext);
      case /* RemoveFromCart */9 :
          return segmentTrack("Product Removed", adaptCartProductForSegment(track$1._0), debugContext);
      case /* CartViewed */10 :
          var match$5 = track$1._0;
          return segmentTrack("Cart Viewed", {
                      cart_id: match$5.cartId,
                      total: match$5.total,
                      quantity: match$5.quantity,
                      currency: match$5.currency,
                      products: Belt_Option.map(match$5.products, (function (__x) {
                              return Belt_Array.map(__x, adaptCartProductForSegment);
                            }))
                    }, debugContext);
      case /* PaymentInfoEntered */11 :
          return segmentTrack("Payment Info Entered", {
                      card_type: track$1._0.cardType
                    }, debugContext);
      case /* PaymentInfoRemoved */12 :
          return segmentTrack("Payment Info Removed", {
                      card_type: track$1._0.cardType
                    }, debugContext);
      case /* CheckoutStarted */13 :
          return segmentTrack("Checkout Started", adaptCheckoutForSegment(track$1._0), debugContext);
      case /* OrderCompleted */14 :
          return segmentTrack("Order Completed", adaptCheckoutForSegment(track$1._0), debugContext);
      case /* ProductAddedToWishlist */15 :
          return segmentTrack("Product Added to Wishlist", adaptWishlistProductForSegment(track$1._0), debugContext);
      case /* ProductRemovedFromWishlist */16 :
          return segmentTrack("Product Removed from Wishlist", adaptWishlistProductForSegment(track$1._0), debugContext);
      case /* WishlistProductAddedToCart */17 :
          return segmentTrack("Wishlist Product Added to Cart", adaptWishlistInCartProductForSegment(track$1._0), debugContext);
      case /* WishlistProductRemovedFromCart */18 :
          return segmentTrack("Wishlist Product Removed from Cart", adaptWishlistInCartProductForSegment(track$1._0), debugContext);
      case /* ProductShared */19 :
          return segmentTrack("Product Shared", adaptShareProductForSegment(track$1._0), debugContext);
      case /* AddressAdded */20 :
          return segmentTrack("Address Added", adaptAddressForSegment(track$1._0), debugContext);
      case /* AddressRemoved */21 :
          return segmentTrack("Address Removed", adaptAddressForSegment(track$1._0), debugContext);
      case /* VideoPlaybackStarted */22 :
          return segmentTrack("Video Playback Started", adaptVideoPlaybackForSegment(track$1._0), debugContext);
      case /* VideoPlaybackPaused */23 :
          return segmentTrack("Video Playback Paused", adaptVideoPlaybackForSegment(track$1._0), debugContext);
      case /* VideoPlaybackInterrupted */24 :
          return segmentTrack("Video Playback Interrupted", adaptVideoPlaybackForSegment(track$1._0), debugContext);
      case /* VideoPlaybackBufferStarted */25 :
          return segmentTrack("Video Playback Buffer Started", adaptVideoPlaybackForSegment(track$1._0), debugContext);
      case /* VideoPlaybackBufferCompleted */26 :
          return segmentTrack("Video Playback Buffer Completed", adaptVideoPlaybackForSegment(track$1._0), debugContext);
      case /* VideoPlaybackSeekStarted */27 :
          return segmentTrack("Video Playback Seek Started", adaptVideoPlaybackForSegment(track$1._0), debugContext);
      case /* VideoPlaybackSeekCompleted */28 :
          return segmentTrack("Video Playback Seek Completed", adaptVideoPlaybackForSegment(track$1._0), debugContext);
      case /* VideoPlaybackResumed */29 :
          return segmentTrack("Video Playback Resumed", adaptVideoPlaybackForSegment(track$1._0), debugContext);
      case /* VideoPlaybackCompleted */30 :
          return segmentTrack("Video Playback Completed", adaptVideoPlaybackForSegment(track$1._0), debugContext);
      case /* VideoPlaybackExited */31 :
          return segmentTrack("Video Playback Exited", adaptVideoPlaybackForSegment(track$1._0), debugContext);
      case /* VideoContentStarted */32 :
          return segmentTrack("Video Content Started", adaptVideoContentForSegment(track$1._0), debugContext);
      case /* VideoContentPlaying */33 :
          return segmentTrack("Video Content Playing", adaptVideoContentForSegment(track$1._0), debugContext);
      case /* VideoContentCompleted */34 :
          return segmentTrack("Video Content Completed", adaptVideoContentForSegment(track$1._0), debugContext);
      case /* VideoAdStarted */35 :
          return segmentTrack("Video Ad Started", adaptVideoAdForSegment(track$1._0), debugContext);
      case /* VideoAdPlaying */36 :
          return segmentTrack("Video Ad Playing", adaptVideoAdForSegment(track$1._0), debugContext);
      case /* VideoAdCompleted */37 :
          return segmentTrack("Video Ad Completed", adaptVideoAdForSegment(track$1._0), debugContext);
      
    }
  };
  var screen = function (name, properties) {
    return analytics.screen(name, Caml_option.some(properties), undefined);
  };
  var identify = function (userId, userTraits) {
    return analytics.identify(userId, Caml_option.some(userTraits), undefined);
  };
  var flush = analytics.flush;
  var group = function (groupId, groupTraits) {
    return analytics.group(groupId, Caml_option.some(groupTraits), undefined);
  };
  var alias = function (newUserId) {
    return analytics.alias(newUserId);
  };
  var reset = function (resetAnonymousId, param) {
    return analytics.reset(Belt_Option.getWithDefault(resetAnonymousId, false), undefined);
  };
  return {
          track: track,
          screen: screen,
          identify: identify,
          flush: flush,
          group: group,
          alias: alias,
          reset: reset
        };
}

export {
  adaptDeviceContextForSegment ,
  deviceContextEventNameForSegment ,
  useSegmentAnalytics ,
}
/* Utils__Graphql Not a pure module */
