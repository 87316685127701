// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ShopifyApi__Infra from "./ShopifyApi__Infra.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as ShopifyApi__Scalars__Url from "./ShopifyApi__Scalars__Url.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as ShopifyApi__Scalars__Decimal from "./ShopifyApi__Scalars__Decimal.bs.js";

var Raw = {};

var query = "fragment ImageFields on Image   {\nid  \nurl  \naltText  \nwidth  \nheight  \n}\n";

function parse(value) {
  var value$1 = value.id;
  var value$2 = value.altText;
  var value$3 = value.width;
  var value$4 = value.height;
  return {
          id: !(value$1 == null) ? value$1 : undefined,
          url: ShopifyApi__Scalars__Url.parse(value.url),
          altText: !(value$2 == null) ? value$2 : undefined,
          width: !(value$3 == null) ? value$3 : undefined,
          height: !(value$4 == null) ? value$4 : undefined
        };
}

function serialize(value) {
  var value$1 = value.height;
  var height = value$1 !== undefined ? value$1 : null;
  var value$2 = value.width;
  var width = value$2 !== undefined ? value$2 : null;
  var value$3 = value.altText;
  var altText = value$3 !== undefined ? value$3 : null;
  var value$4 = value.url;
  var value$5 = ShopifyApi__Scalars__Url.serialize(value$4);
  var value$6 = value.id;
  var id = value$6 !== undefined ? value$6 : null;
  return {
          id: id,
          url: value$5,
          altText: altText,
          width: width,
          height: height
        };
}

function verifyArgsAndParse(_ImageFields, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var ImageFields = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = "fragment MetafieldImageFields on Metafield   {\nid  \nreference  {\n__typename\n...on MediaImage   {\nimage  {\n...ImageFields   \n}\n\n}\n\n}\n\n}\nfragment ImageFields on Image   {\nid  \nurl  \naltText  \nwidth  \nheight  \n}\n";

function parse$1(value) {
  var value$1 = value.reference;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var typename = value$1["__typename"];
    var tmp$1;
    if (typename === "MediaImage") {
      var value$2 = value$1.image;
      tmp$1 = {
        NAME: "MediaImage",
        VAL: {
          image: !(value$2 == null) ? parse(value$2) : undefined
        }
      };
    } else {
      tmp$1 = {
        NAME: "FutureAddedValue",
        VAL: value$1
      };
    }
    tmp = tmp$1;
  }
  return {
          id: value.id,
          reference: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.reference;
  var reference;
  if (value$1 !== undefined) {
    if (value$1.NAME === "MediaImage") {
      var value$2 = value$1.VAL.image;
      var image = value$2 !== undefined ? serialize(value$2) : null;
      reference = {
        __typename: "MediaImage",
        image: image
      };
    } else {
      reference = value$1.VAL;
    }
  } else {
    reference = null;
  }
  var value$3 = value.id;
  return {
          id: value$3,
          reference: reference
        };
}

function verifyArgsAndParse$1(_MetafieldImageFields, value) {
  return parse$1(value);
}

function verifyName$1(param) {
  
}

var MetafieldImageFields = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  verifyArgsAndParse: verifyArgsAndParse$1,
  verifyName: verifyName$1
};

var Raw$2 = {};

function parse$2(value) {
  var value$1 = value.currencyCode;
  var tmp;
  switch (value$1) {
    case "AED" :
        tmp = "AED";
        break;
    case "AFN" :
        tmp = "AFN";
        break;
    case "ALL" :
        tmp = "ALL";
        break;
    case "AMD" :
        tmp = "AMD";
        break;
    case "ANG" :
        tmp = "ANG";
        break;
    case "AOA" :
        tmp = "AOA";
        break;
    case "ARS" :
        tmp = "ARS";
        break;
    case "AUD" :
        tmp = "AUD";
        break;
    case "AWG" :
        tmp = "AWG";
        break;
    case "AZN" :
        tmp = "AZN";
        break;
    case "BAM" :
        tmp = "BAM";
        break;
    case "BBD" :
        tmp = "BBD";
        break;
    case "BDT" :
        tmp = "BDT";
        break;
    case "BGN" :
        tmp = "BGN";
        break;
    case "BHD" :
        tmp = "BHD";
        break;
    case "BIF" :
        tmp = "BIF";
        break;
    case "BMD" :
        tmp = "BMD";
        break;
    case "BND" :
        tmp = "BND";
        break;
    case "BOB" :
        tmp = "BOB";
        break;
    case "BRL" :
        tmp = "BRL";
        break;
    case "BSD" :
        tmp = "BSD";
        break;
    case "BTN" :
        tmp = "BTN";
        break;
    case "BWP" :
        tmp = "BWP";
        break;
    case "BYN" :
        tmp = "BYN";
        break;
    case "BYR" :
        tmp = "BYR";
        break;
    case "BZD" :
        tmp = "BZD";
        break;
    case "CAD" :
        tmp = "CAD";
        break;
    case "CDF" :
        tmp = "CDF";
        break;
    case "CHF" :
        tmp = "CHF";
        break;
    case "CLP" :
        tmp = "CLP";
        break;
    case "CNY" :
        tmp = "CNY";
        break;
    case "COP" :
        tmp = "COP";
        break;
    case "CRC" :
        tmp = "CRC";
        break;
    case "CVE" :
        tmp = "CVE";
        break;
    case "CZK" :
        tmp = "CZK";
        break;
    case "DJF" :
        tmp = "DJF";
        break;
    case "DKK" :
        tmp = "DKK";
        break;
    case "DOP" :
        tmp = "DOP";
        break;
    case "DZD" :
        tmp = "DZD";
        break;
    case "EGP" :
        tmp = "EGP";
        break;
    case "ERN" :
        tmp = "ERN";
        break;
    case "ETB" :
        tmp = "ETB";
        break;
    case "EUR" :
        tmp = "EUR";
        break;
    case "FJD" :
        tmp = "FJD";
        break;
    case "FKP" :
        tmp = "FKP";
        break;
    case "GBP" :
        tmp = "GBP";
        break;
    case "GEL" :
        tmp = "GEL";
        break;
    case "GHS" :
        tmp = "GHS";
        break;
    case "GIP" :
        tmp = "GIP";
        break;
    case "GMD" :
        tmp = "GMD";
        break;
    case "GNF" :
        tmp = "GNF";
        break;
    case "GTQ" :
        tmp = "GTQ";
        break;
    case "GYD" :
        tmp = "GYD";
        break;
    case "HKD" :
        tmp = "HKD";
        break;
    case "HNL" :
        tmp = "HNL";
        break;
    case "HRK" :
        tmp = "HRK";
        break;
    case "HTG" :
        tmp = "HTG";
        break;
    case "HUF" :
        tmp = "HUF";
        break;
    case "IDR" :
        tmp = "IDR";
        break;
    case "ILS" :
        tmp = "ILS";
        break;
    case "INR" :
        tmp = "INR";
        break;
    case "IQD" :
        tmp = "IQD";
        break;
    case "IRR" :
        tmp = "IRR";
        break;
    case "ISK" :
        tmp = "ISK";
        break;
    case "JEP" :
        tmp = "JEP";
        break;
    case "JMD" :
        tmp = "JMD";
        break;
    case "JOD" :
        tmp = "JOD";
        break;
    case "JPY" :
        tmp = "JPY";
        break;
    case "KES" :
        tmp = "KES";
        break;
    case "KGS" :
        tmp = "KGS";
        break;
    case "KHR" :
        tmp = "KHR";
        break;
    case "KID" :
        tmp = "KID";
        break;
    case "KMF" :
        tmp = "KMF";
        break;
    case "KRW" :
        tmp = "KRW";
        break;
    case "KWD" :
        tmp = "KWD";
        break;
    case "KYD" :
        tmp = "KYD";
        break;
    case "KZT" :
        tmp = "KZT";
        break;
    case "LAK" :
        tmp = "LAK";
        break;
    case "LBP" :
        tmp = "LBP";
        break;
    case "LKR" :
        tmp = "LKR";
        break;
    case "LRD" :
        tmp = "LRD";
        break;
    case "LSL" :
        tmp = "LSL";
        break;
    case "LTL" :
        tmp = "LTL";
        break;
    case "LVL" :
        tmp = "LVL";
        break;
    case "LYD" :
        tmp = "LYD";
        break;
    case "MAD" :
        tmp = "MAD";
        break;
    case "MDL" :
        tmp = "MDL";
        break;
    case "MGA" :
        tmp = "MGA";
        break;
    case "MKD" :
        tmp = "MKD";
        break;
    case "MMK" :
        tmp = "MMK";
        break;
    case "MNT" :
        tmp = "MNT";
        break;
    case "MOP" :
        tmp = "MOP";
        break;
    case "MRU" :
        tmp = "MRU";
        break;
    case "MUR" :
        tmp = "MUR";
        break;
    case "MVR" :
        tmp = "MVR";
        break;
    case "MWK" :
        tmp = "MWK";
        break;
    case "MXN" :
        tmp = "MXN";
        break;
    case "MYR" :
        tmp = "MYR";
        break;
    case "MZN" :
        tmp = "MZN";
        break;
    case "NAD" :
        tmp = "NAD";
        break;
    case "NGN" :
        tmp = "NGN";
        break;
    case "NIO" :
        tmp = "NIO";
        break;
    case "NOK" :
        tmp = "NOK";
        break;
    case "NPR" :
        tmp = "NPR";
        break;
    case "NZD" :
        tmp = "NZD";
        break;
    case "OMR" :
        tmp = "OMR";
        break;
    case "PAB" :
        tmp = "PAB";
        break;
    case "PEN" :
        tmp = "PEN";
        break;
    case "PGK" :
        tmp = "PGK";
        break;
    case "PHP" :
        tmp = "PHP";
        break;
    case "PKR" :
        tmp = "PKR";
        break;
    case "PLN" :
        tmp = "PLN";
        break;
    case "PYG" :
        tmp = "PYG";
        break;
    case "QAR" :
        tmp = "QAR";
        break;
    case "RON" :
        tmp = "RON";
        break;
    case "RSD" :
        tmp = "RSD";
        break;
    case "RUB" :
        tmp = "RUB";
        break;
    case "RWF" :
        tmp = "RWF";
        break;
    case "SAR" :
        tmp = "SAR";
        break;
    case "SBD" :
        tmp = "SBD";
        break;
    case "SCR" :
        tmp = "SCR";
        break;
    case "SDG" :
        tmp = "SDG";
        break;
    case "SEK" :
        tmp = "SEK";
        break;
    case "SGD" :
        tmp = "SGD";
        break;
    case "SHP" :
        tmp = "SHP";
        break;
    case "SLL" :
        tmp = "SLL";
        break;
    case "SOS" :
        tmp = "SOS";
        break;
    case "SRD" :
        tmp = "SRD";
        break;
    case "SSP" :
        tmp = "SSP";
        break;
    case "STD" :
        tmp = "STD";
        break;
    case "STN" :
        tmp = "STN";
        break;
    case "SYP" :
        tmp = "SYP";
        break;
    case "SZL" :
        tmp = "SZL";
        break;
    case "THB" :
        tmp = "THB";
        break;
    case "TJS" :
        tmp = "TJS";
        break;
    case "TMT" :
        tmp = "TMT";
        break;
    case "TND" :
        tmp = "TND";
        break;
    case "TOP" :
        tmp = "TOP";
        break;
    case "TRY" :
        tmp = "TRY";
        break;
    case "TTD" :
        tmp = "TTD";
        break;
    case "TWD" :
        tmp = "TWD";
        break;
    case "TZS" :
        tmp = "TZS";
        break;
    case "UAH" :
        tmp = "UAH";
        break;
    case "UGX" :
        tmp = "UGX";
        break;
    case "USD" :
        tmp = "USD";
        break;
    case "UYU" :
        tmp = "UYU";
        break;
    case "UZS" :
        tmp = "UZS";
        break;
    case "VED" :
        tmp = "VED";
        break;
    case "VEF" :
        tmp = "VEF";
        break;
    case "VES" :
        tmp = "VES";
        break;
    case "VND" :
        tmp = "VND";
        break;
    case "VUV" :
        tmp = "VUV";
        break;
    case "WST" :
        tmp = "WST";
        break;
    case "XAF" :
        tmp = "XAF";
        break;
    case "XCD" :
        tmp = "XCD";
        break;
    case "XOF" :
        tmp = "XOF";
        break;
    case "XPF" :
        tmp = "XPF";
        break;
    case "XXX" :
        tmp = "XXX";
        break;
    case "YER" :
        tmp = "YER";
        break;
    case "ZAR" :
        tmp = "ZAR";
        break;
    case "ZMW" :
        tmp = "ZMW";
        break;
    default:
      tmp = {
        NAME: "FutureAddedValue",
        VAL: value$1
      };
  }
  return {
          amount: ShopifyApi__Scalars__Decimal.parse(value.amount),
          currencyCode: tmp
        };
}

function serialize$2(value) {
  var value$1 = value.currencyCode;
  var currencyCode = typeof value$1 === "object" ? value$1.VAL : (
      value$1 === "AFN" ? "AFN" : (
          value$1 === "ALL" ? "ALL" : (
              value$1 === "AMD" ? "AMD" : (
                  value$1 === "ANG" ? "ANG" : (
                      value$1 === "AOA" ? "AOA" : (
                          value$1 === "ARS" ? "ARS" : (
                              value$1 === "AUD" ? "AUD" : (
                                  value$1 === "AWG" ? "AWG" : (
                                      value$1 === "AZN" ? "AZN" : (
                                          value$1 === "BAM" ? "BAM" : (
                                              value$1 === "BBD" ? "BBD" : (
                                                  value$1 === "BDT" ? "BDT" : (
                                                      value$1 === "BGN" ? "BGN" : (
                                                          value$1 === "BHD" ? "BHD" : (
                                                              value$1 === "BIF" ? "BIF" : (
                                                                  value$1 === "BMD" ? "BMD" : (
                                                                      value$1 === "BND" ? "BND" : (
                                                                          value$1 === "BOB" ? "BOB" : (
                                                                              value$1 === "BRL" ? "BRL" : (
                                                                                  value$1 === "BSD" ? "BSD" : (
                                                                                      value$1 === "BTN" ? "BTN" : (
                                                                                          value$1 === "BWP" ? "BWP" : (
                                                                                              value$1 === "BYN" ? "BYN" : (
                                                                                                  value$1 === "BYR" ? "BYR" : (
                                                                                                      value$1 === "BZD" ? "BZD" : (
                                                                                                          value$1 === "CAD" ? "CAD" : (
                                                                                                              value$1 === "CDF" ? "CDF" : (
                                                                                                                  value$1 === "CHF" ? "CHF" : (
                                                                                                                      value$1 === "CLP" ? "CLP" : (
                                                                                                                          value$1 === "CNY" ? "CNY" : (
                                                                                                                              value$1 === "COP" ? "COP" : (
                                                                                                                                  value$1 === "CRC" ? "CRC" : (
                                                                                                                                      value$1 === "CVE" ? "CVE" : (
                                                                                                                                          value$1 === "CZK" ? "CZK" : (
                                                                                                                                              value$1 === "DJF" ? "DJF" : (
                                                                                                                                                  value$1 === "DKK" ? "DKK" : (
                                                                                                                                                      value$1 === "DOP" ? "DOP" : (
                                                                                                                                                          value$1 === "DZD" ? "DZD" : (
                                                                                                                                                              value$1 === "EGP" ? "EGP" : (
                                                                                                                                                                  value$1 === "ERN" ? "ERN" : (
                                                                                                                                                                      value$1 === "ETB" ? "ETB" : (
                                                                                                                                                                          value$1 === "EUR" ? "EUR" : (
                                                                                                                                                                              value$1 === "FJD" ? "FJD" : (
                                                                                                                                                                                  value$1 === "FKP" ? "FKP" : (
                                                                                                                                                                                      value$1 === "GBP" ? "GBP" : (
                                                                                                                                                                                          value$1 === "GEL" ? "GEL" : (
                                                                                                                                                                                              value$1 === "GHS" ? "GHS" : (
                                                                                                                                                                                                  value$1 === "GIP" ? "GIP" : (
                                                                                                                                                                                                      value$1 === "GMD" ? "GMD" : (
                                                                                                                                                                                                          value$1 === "GNF" ? "GNF" : (
                                                                                                                                                                                                              value$1 === "GTQ" ? "GTQ" : (
                                                                                                                                                                                                                  value$1 === "GYD" ? "GYD" : (
                                                                                                                                                                                                                      value$1 === "HKD" ? "HKD" : (
                                                                                                                                                                                                                          value$1 === "HNL" ? "HNL" : (
                                                                                                                                                                                                                              value$1 === "HRK" ? "HRK" : (
                                                                                                                                                                                                                                  value$1 === "HTG" ? "HTG" : (
                                                                                                                                                                                                                                      value$1 === "HUF" ? "HUF" : (
                                                                                                                                                                                                                                          value$1 === "IDR" ? "IDR" : (
                                                                                                                                                                                                                                              value$1 === "ILS" ? "ILS" : (
                                                                                                                                                                                                                                                  value$1 === "INR" ? "INR" : (
                                                                                                                                                                                                                                                      value$1 === "IQD" ? "IQD" : (
                                                                                                                                                                                                                                                          value$1 === "IRR" ? "IRR" : (
                                                                                                                                                                                                                                                              value$1 === "ISK" ? "ISK" : (
                                                                                                                                                                                                                                                                  value$1 === "JEP" ? "JEP" : (
                                                                                                                                                                                                                                                                      value$1 === "JMD" ? "JMD" : (
                                                                                                                                                                                                                                                                          value$1 === "JOD" ? "JOD" : (
                                                                                                                                                                                                                                                                              value$1 === "JPY" ? "JPY" : (
                                                                                                                                                                                                                                                                                  value$1 === "KES" ? "KES" : (
                                                                                                                                                                                                                                                                                      value$1 === "KGS" ? "KGS" : (
                                                                                                                                                                                                                                                                                          value$1 === "KHR" ? "KHR" : (
                                                                                                                                                                                                                                                                                              value$1 === "KID" ? "KID" : (
                                                                                                                                                                                                                                                                                                  value$1 === "KMF" ? "KMF" : (
                                                                                                                                                                                                                                                                                                      value$1 === "KRW" ? "KRW" : (
                                                                                                                                                                                                                                                                                                          value$1 === "KWD" ? "KWD" : (
                                                                                                                                                                                                                                                                                                              value$1 === "KYD" ? "KYD" : (
                                                                                                                                                                                                                                                                                                                  value$1 === "KZT" ? "KZT" : (
                                                                                                                                                                                                                                                                                                                      value$1 === "LAK" ? "LAK" : (
                                                                                                                                                                                                                                                                                                                          value$1 === "LBP" ? "LBP" : (
                                                                                                                                                                                                                                                                                                                              value$1 === "LKR" ? "LKR" : (
                                                                                                                                                                                                                                                                                                                                  value$1 === "LRD" ? "LRD" : (
                                                                                                                                                                                                                                                                                                                                      value$1 === "LSL" ? "LSL" : (
                                                                                                                                                                                                                                                                                                                                          value$1 === "LTL" ? "LTL" : (
                                                                                                                                                                                                                                                                                                                                              value$1 === "LVL" ? "LVL" : (
                                                                                                                                                                                                                                                                                                                                                  value$1 === "LYD" ? "LYD" : (
                                                                                                                                                                                                                                                                                                                                                      value$1 === "MAD" ? "MAD" : (
                                                                                                                                                                                                                                                                                                                                                          value$1 === "MDL" ? "MDL" : (
                                                                                                                                                                                                                                                                                                                                                              value$1 === "MGA" ? "MGA" : (
                                                                                                                                                                                                                                                                                                                                                                  value$1 === "MKD" ? "MKD" : (
                                                                                                                                                                                                                                                                                                                                                                      value$1 === "MMK" ? "MMK" : (
                                                                                                                                                                                                                                                                                                                                                                          value$1 === "MNT" ? "MNT" : (
                                                                                                                                                                                                                                                                                                                                                                              value$1 === "MOP" ? "MOP" : (
                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "MRU" ? "MRU" : (
                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "MUR" ? "MUR" : (
                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "MVR" ? "MVR" : (
                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "MWK" ? "MWK" : (
                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "MXN" ? "MXN" : (
                                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "MYR" ? "MYR" : (
                                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "MZN" ? "MZN" : (
                                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "NAD" ? "NAD" : (
                                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "NGN" ? "NGN" : (
                                                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "NIO" ? "NIO" : (
                                                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "NOK" ? "NOK" : (
                                                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "NPR" ? "NPR" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "NZD" ? "NZD" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "OMR" ? "OMR" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "PAB" ? "PAB" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "PEN" ? "PEN" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "PGK" ? "PGK" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "PHP" ? "PHP" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "PKR" ? "PKR" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "PLN" ? "PLN" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "PYG" ? "PYG" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "QAR" ? "QAR" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "RON" ? "RON" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "RSD" ? "RSD" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "RUB" ? "RUB" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "RWF" ? "RWF" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "SAR" ? "SAR" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "SBD" ? "SBD" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "SCR" ? "SCR" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "SDG" ? "SDG" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "SEK" ? "SEK" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "SGD" ? "SGD" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "SHP" ? "SHP" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "SLL" ? "SLL" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "SOS" ? "SOS" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "SRD" ? "SRD" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "SSP" ? "SSP" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "STD" ? "STD" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "STN" ? "STN" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "SYP" ? "SYP" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "SZL" ? "SZL" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "THB" ? "THB" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "TJS" ? "TJS" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "TMT" ? "TMT" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "TND" ? "TND" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "TOP" ? "TOP" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "TRY" ? "TRY" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "TTD" ? "TTD" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "TWD" ? "TWD" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "TZS" ? "TZS" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "UAH" ? "UAH" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "UGX" ? "UGX" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "USD" ? "USD" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "UYU" ? "UYU" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "UZS" ? "UZS" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "VED" ? "VED" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "VEF" ? "VEF" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "VES" ? "VES" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "VND" ? "VND" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "VUV" ? "VUV" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "WST" ? "WST" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "XAF" ? "XAF" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "XCD" ? "XCD" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "XOF" ? "XOF" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "XPF" ? "XPF" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      value$1 === "XXX" ? "XXX" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          value$1 === "YER" ? "YER" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              value$1 === "ZAR" ? "ZAR" : (
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  value$1 === "ZMW" ? "ZMW" : "AED"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                                )
                                                                                                                                                                                                                                            )
                                                                                                                                                                                                                                        )
                                                                                                                                                                                                                                    )
                                                                                                                                                                                                                                )
                                                                                                                                                                                                                            )
                                                                                                                                                                                                                        )
                                                                                                                                                                                                                    )
                                                                                                                                                                                                                )
                                                                                                                                                                                                            )
                                                                                                                                                                                                        )
                                                                                                                                                                                                    )
                                                                                                                                                                                                )
                                                                                                                                                                                            )
                                                                                                                                                                                        )
                                                                                                                                                                                    )
                                                                                                                                                                                )
                                                                                                                                                                            )
                                                                                                                                                                        )
                                                                                                                                                                    )
                                                                                                                                                                )
                                                                                                                                                            )
                                                                                                                                                        )
                                                                                                                                                    )
                                                                                                                                                )
                                                                                                                                            )
                                                                                                                                        )
                                                                                                                                    )
                                                                                                                                )
                                                                                                                            )
                                                                                                                        )
                                                                                                                    )
                                                                                                                )
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    );
  var value$2 = value.amount;
  var value$3 = ShopifyApi__Scalars__Decimal.serialize(value$2);
  return {
          amount: value$3,
          currencyCode: currencyCode
        };
}

function verifyArgsAndParse$2(_ShopifyMoneyFields, value) {
  return parse$2(value);
}

function verifyName$2(param) {
  
}

var ShopifyMoneyFields = {
  Raw: Raw$2,
  query: "fragment ShopifyMoneyFields on MoneyV2   {\namount  \ncurrencyCode  \n}\n",
  parse: parse$2,
  serialize: serialize$2,
  verifyArgsAndParse: verifyArgsAndParse$2,
  verifyName: verifyName$2
};

function makeMoney(param) {
  var currencyCode = param.currencyCode;
  var tmp;
  var exit = 0;
  if (typeof currencyCode === "object") {
    if (currencyCode.NAME === "FutureAddedValue") {
      var newCurrency = currencyCode.VAL;
      RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "makeMoney - unsupported unknown currency encountered", newCurrency);
      tmp = Js_exn.raiseError("Unsupported unknown currency encountered: " + newCurrency);
    } else {
      exit = 1;
    }
  } else if (currencyCode === "AUD") {
    tmp = "AUD";
  } else {
    exit = 1;
  }
  if (exit === 1) {
    RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "makeMoney - unsupported currency encountered", currencyCode);
    tmp = Js_exn.raiseError("Unsupported currency encountered: " + currencyCode);
  }
  return {
          amount: param.amount,
          currency: tmp
        };
}

function adaptAltText(altText) {
  if (altText.includes("gid://shopify")) {
    return ;
  } else {
    return altText;
  }
}

function makeImage(img) {
  var height = img.height;
  var width = img.width;
  var url = img.url;
  return {
          id: Belt_Option.getWithDefault(RescriptUtils__OptionPlus.tapNone(img.id, (function (param) {
                      RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "Image without `id` found, so using its `url` as `id`", img);
                    })), url),
          url: url,
          alt: Belt_Option.flatMap(img.altText, adaptAltText),
          size: width !== undefined && height !== undefined ? ({
                width: width,
                height: height
              }) : undefined
        };
}

function tryMakeImageFromMetafield(param) {
  var reference = param.reference;
  if (reference !== undefined) {
    if (reference.NAME === "MediaImage") {
      var image = reference.VAL.image;
      if (image !== undefined) {
        return makeImage(image);
      } else {
        RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "tryMakeImageFromMetafield - metafield reference of `#MediaImage(image: None)` found", reference);
        return ;
      }
    }
    RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "tryMakeImageFromMetafield - metafield reference of unknown type found", reference);
    return ;
  }
  RescriptUtils__Logger.warn2(ShopifyApi__Infra.logger, "tryMakeImageFromMetafield - metafield reference None found. Id:", param.id);
}

var logger = ShopifyApi__Infra.logger;

var Logger;

export {
  logger ,
  Logger ,
  ImageFields ,
  MetafieldImageFields ,
  ShopifyMoneyFields ,
  makeMoney ,
  adaptAltText ,
  makeImage ,
  tryMakeImageFromMetafield ,
}
/* ShopifyApi__Infra Not a pure module */
