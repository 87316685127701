// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as MarkotApi__Infra from "./MarkotApi__Infra.bs.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";
import * as RescriptUtils__Logger from "../../rescript-utils/src/RescriptUtils__Logger.bs.js";
import * as RescriptUtils__OptionPlus from "../../rescript-utils/src/RescriptUtils__OptionPlus.bs.js";
import * as MarkotApi__CommonFragments from "./MarkotApi__CommonFragments.bs.js";

function parse(value) {
  var value$1 = value.currency;
  var tmp = value$1 === "AUD" ? "AUD" : ({
        NAME: "FutureAddedValue",
        VAL: value$1
      });
  return {
          id: value.id,
          currency: tmp,
          balance: value.balance,
          availableToWithdraw: value.availableToWithdraw,
          lifetimeEarnings: value.lifetimeEarnings
        };
}

function serialize(value) {
  var value$1 = value.lifetimeEarnings;
  var value$2 = value.availableToWithdraw;
  var value$3 = value.balance;
  var value$4 = value.currency;
  var currency = typeof value$4 === "object" ? value$4.VAL : "AUD";
  var value$5 = value.id;
  return {
          id: value$5,
          currency: currency,
          balance: value$3,
          availableToWithdraw: value$2,
          lifetimeEarnings: value$1
        };
}

var Raw = {};

var query = "query Wallet  {\nviewer  {\nwallet  {\n...WalletFields   \n}\n\n}\n\n}\nfragment WalletFields on Wallet   {\nid  \ncurrency  \nbalance  \navailableToWithdraw  \nlifetimeEarnings  \n}\n";

function parse$1(value) {
  var value$1 = value.viewer;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.wallet;
    tmp = {
      wallet: !(value$2 == null) ? parse(value$2) : undefined
    };
  }
  return {
          viewer: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.viewer;
  var viewer;
  if (value$1 !== undefined) {
    var value$2 = value$1.wallet;
    var wallet = value$2 !== undefined ? serialize(value$2) : null;
    viewer = {
      wallet: wallet
    };
  } else {
    viewer = null;
  }
  return {
          viewer: viewer
        };
}

function serializeVariables(param) {
  
}

function makeWallet(param) {
  return {
          id: MarkotApi__Id.parseExn(param.id),
          currency: MarkotApi__CommonFragments.makeCurrency(param.currency),
          balance: param.balance,
          availableToWithdraw: param.availableToWithdraw,
          lifetimeEarnings: param.lifetimeEarnings
        };
}

function fetchWallet(apiConfig) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: query,
                    Raw: Raw,
                    parse: parse$1,
                    serialize: serialize$1,
                    serializeVariables: serializeVariables
                  }), undefined, undefined).then(function (param) {
              return Belt_Option.map(Belt_Option.flatMap(RescriptUtils__OptionPlus.tapNone(param.viewer, (function (param) {
                                    RescriptUtils__Logger.warn(MarkotApi__Infra.logger, "Wallet - fetchWallet: `viewer` not returned");
                                  })), (function (param) {
                                return param.wallet;
                              })), makeWallet);
            });
}

export {
  fetchWallet ,
}
/* MarkotApi__Id Not a pure module */
