import { Component, ErrorInfo, ReactNode } from "react";

import { make as FatalErrorDisplay } from "./Ui__FatalErrorDisplay.gen";

interface FatalErrorBoundaryProps {
  captureError?: (e: Error) => string | undefined;
  onReload: () => void;
  message?: string;
  children?: ReactNode;
}

interface FatalErrorBoundaryState {
  caughtError?: { error: Error; info: ErrorInfo };
  errorId?: string;
}

export class Ui__FatalErrorBoundary extends Component<
  FatalErrorBoundaryProps,
  FatalErrorBoundaryState
> {
  state: FatalErrorBoundaryState = {
    caughtError: undefined,
  };
  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ caughtError: { error, info } }, () => {
      console.error(error, "FatalErrorBoundary componentDidCatch", { info });
    });
    const errorId = this.props.captureError
      ? this.props.captureError(error)
      : undefined;
    this.setState({
      errorId,
    });
  }
  render() {
    const { caughtError, errorId } = this.state;

    if (caughtError) {
      return (
        <FatalErrorDisplay
          errorId={errorId}
          technicalDetails={String(caughtError.error)}
          onReload={this.props.onReload}
        />
      );
    }
    return this.props.children;
  }
}
