// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as MarkotApi__Id from "./MarkotApi__Id.bs.js";
import * as MarkotApi__Client from "./MarkotApi__Client.bs.js";

var Raw = {};

function parse(value) {
  var value$1 = value.makeCheckout;
  var value$2 = value$1.checkoutUrl;
  return {
          makeCheckout: {
            checkoutUrl: !(value$2 == null) ? value$2 : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.makeCheckout;
  var value$2 = value$1.checkoutUrl;
  var checkoutUrl = value$2 !== undefined ? value$2 : null;
  var makeCheckout = {
    checkoutUrl: checkoutUrl
  };
  return {
          makeCheckout: makeCheckout
        };
}

function serializeVariables(inp) {
  return {
          bagId: inp.bagId,
          customerAccessToken: inp.customerAccessToken
        };
}

function executeMakeCheckout(apiConfig, bagId, customerAccessToken) {
  return Curry._2(MarkotApi__Client.query(apiConfig)({
                    query: "mutation MakeCheckout($bagId: ID!, $customerAccessToken: String!)  {\nmakeCheckout(input: {bagId: $bagId, userAccessToken: $customerAccessToken})  {\ncheckoutUrl  \n}\n\n}\n",
                    Raw: Raw,
                    parse: parse,
                    serialize: serialize,
                    serializeVariables: serializeVariables
                  }), {
                bagId: MarkotApi__Id.toString(bagId),
                customerAccessToken: customerAccessToken
              }, undefined).then(function (param) {
              return {
                      checkoutUrl: param.makeCheckout.checkoutUrl
                    };
            });
}

export {
  executeMakeCheckout ,
}
/* MarkotApi__Id Not a pure module */
