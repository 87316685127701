/* TypeScript file generated from App__LocalBag.resi by genType. */
/* eslint-disable import/first */


// @ts-ignore: Implicit any on import
import * as App__LocalBagBS__Es6Import from './App__LocalBag.bs';
const App__LocalBagBS: any = App__LocalBagBS__Es6Import;

import type {Environment_t as RescriptRelay_Environment_t} from '../../../../rescript-shims/RescriptRelay.shim';

import type {t as Promise_t} from '../../../../rescript-shims/Promise.shim';

export const assignLocalBagToViewer: (_1:{ readonly environment: RescriptRelay_Environment_t }) => Promise_t<void> = function (Arg1: any) {
  const result = App__LocalBagBS.assignLocalBagToViewer(Arg1.environment);
  return result
};
