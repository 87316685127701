// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function parseTypename(str) {
  switch (str) {
    case "" :
        return {
                TAG: /* Error */1,
                _0: "Empty Shopify typename provided"
              };
    case "Cart" :
        return {
                TAG: /* Ok */0,
                _0: "Cart"
              };
    case "CartLine" :
        return {
                TAG: /* Ok */0,
                _0: "CartLine"
              };
    case "Collection" :
        return {
                TAG: /* Ok */0,
                _0: "Collection"
              };
    case "Order" :
        return {
                TAG: /* Ok */0,
                _0: "Order"
              };
    case "Page" :
        return {
                TAG: /* Ok */0,
                _0: "Page"
              };
    case "Product" :
        return {
                TAG: /* Ok */0,
                _0: "Product"
              };
    case "ProductOption" :
        return {
                TAG: /* Ok */0,
                _0: "ProductOption"
              };
    case "ProductVariant" :
        return {
                TAG: /* Ok */0,
                _0: "ProductVariant"
              };
    default:
      return {
              TAG: /* Error */1,
              _0: "Unsupported Shopify typename provided: \"" + str + "\""
            };
  }
}

var prefix = "gid://shopify";

var componentsRegex = new RegExp("^" + prefix.replace(/\//g, "\/") + "\/(\\w+)\/(.+)$");

function make(typename, internalId) {
  return "" + prefix + "/" + typename + "/" + internalId + "";
}

function getComponents(str) {
  var match = Belt_Option.map(Belt_Option.map(Caml_option.null_to_opt(componentsRegex.exec(str)), (function (prim) {
              return prim;
            })), (function (__x) {
          return Belt_Array.map(__x, (function (prim) {
                        if (prim == null) {
                          return ;
                        } else {
                          return Caml_option.some(prim);
                        }
                      }));
        }));
  if (match === undefined) {
    return {
            TAG: /* Error */1,
            _0: "Could not make a Shopify id from " + str
          };
  }
  if (match.length !== 3) {
    return {
            TAG: /* Error */1,
            _0: "Could not make a Shopify id from " + str
          };
  }
  var typename = match[1];
  if (typename === undefined) {
    return {
            TAG: /* Error */1,
            _0: "Could not make a Shopify id from " + str
          };
  }
  var internalId = match[2];
  if (internalId === undefined) {
    return {
            TAG: /* Error */1,
            _0: "Could not make a Shopify id from " + str
          };
  }
  var match$1 = parseTypename(typename);
  if (match$1.TAG === /* Ok */0) {
    if (internalId === "") {
      return {
              TAG: /* Error */1,
              _0: "Error while processing Shopify internalId - empty string"
            };
    } else {
      return {
              TAG: /* Ok */0,
              _0: {
                typename: match$1._0,
                internalId: internalId
              }
            };
    }
  } else {
    return {
            TAG: /* Error */1,
            _0: "Error while processing Shopify typename: " + match$1._0
          };
  }
}

function parse(str) {
  return Belt_Result.flatMap(getComponents(str), (function (param) {
                return {
                        TAG: /* Ok */0,
                        _0: str
                      };
              }));
}

function parseExn(str) {
  var result = parse(str);
  if (result.TAG === /* Ok */0) {
    return result._0;
  } else {
    return Js_exn.raiseError("Couldn't parse a Shopify ID " + str + ". Reason: " + result._0);
  }
}

function unpackExn(id) {
  var components = getComponents(id);
  if (components.TAG === /* Ok */0) {
    return components._0;
  } else {
    return Js_exn.raiseError("Couldn't unpack Shopify ID " + id + ". Reason: " + components._0);
  }
}

function toString(prim) {
  return prim;
}

var unpack = getComponents;

export {
  make ,
  parse ,
  parseExn ,
  toString ,
  unpack ,
  unpackExn ,
}
/* componentsRegex Not a pure module */
