/// <reference lib="dom" />

import { Loader } from "@googlemaps/js-api-loader";

import { isProduction } from "../../../config/";

const getApiKey = () => {
  if (isProduction()) {
    return "AIzaSyDnJ1eofVj7swkbgM-BvuQ3-xudlgBdr2E";
  } else {
    return "AIzaSyAA8zE30058Ev2q44ZflO8ZpsFa3AuKM40";
  }
};

const serviceLoader = new Loader({
  apiKey: getApiKey(),
  version: "weekly", // default choice
  libraries: ["places"],
});

import {
  AutocompletePrediction,
  AutocompletePredictionsUrlProps,
  AutocompleteServiceInfo,
  makeAddressResult,
  GetAddressResult,
} from "./models";

const GooglePlacesError = {
  NO_RESULTS: "Got empty results from Google Places search",
  ZERO_RESULTS: "There are no autocomplete predictions for this input",
  INVALID_REQUEST: "Invalid request input parameter is missing",
  REQUEST_DENIED:
    "Your request was denied, check if you are using valid key parameter",
  OVER_QUERY_LIMIT: "Error your are over your quota",
  UNKNOWN_ERROR:
    "Unknown error ocurred please check your internet connection and try again",
  LOADING_ERROR:
    "Error trying to autocomplete the address. Please enter the address manually.",
} as const;

export const getAutocompletePredictions = (
  props: AutocompletePredictionsUrlProps
) =>
  serviceLoader
    .load()
    .then(
      google =>
        new Promise<AutocompletePrediction[]>((res, rej) => {
          console.log("Predictions props", props);
          const service = new google.maps.places.AutocompleteService();
          // TODO: We must include attribution. Find how to do it on native/web
          service
            .getPlacePredictions(
              { ...props, componentRestrictions: { country: "au" } },
              (results, status) => {
                if (status === "OK") {
                  if (!results) {
                    rej(new Error(GooglePlacesError.NO_RESULTS));
                  } else {
                    res(
                      results.map(
                        (
                          prediction: google.maps.places.AutocompletePrediction
                        ) => {
                          return {
                            text: prediction.description,
                            placeId: prediction.place_id,
                            matchedSubstrings: [
                              ...prediction.matched_substrings,
                            ],
                            structuredFormatting: {
                              mainText:
                                prediction.structured_formatting.main_text,
                              mainTextMatchedSubstrings:
                                prediction.structured_formatting
                                  .main_text_matched_substrings,
                              secondaryText:
                                prediction.structured_formatting.secondary_text,
                            },
                            components: {
                              street:
                                prediction.structured_formatting.main_text,
                              suburb:
                                prediction.terms[prediction.terms.length - 3]
                                  ?.value,
                              state:
                                prediction.terms[prediction.terms.length - 2]
                                  ?.value,
                              country:
                                prediction.terms[prediction.terms.length - 1]
                                  ?.value,
                            },
                          };
                        }
                      )
                    );
                  }
                } else if (status === "ZERO_RESULTS") {
                  rej(new Error(GooglePlacesError.ZERO_RESULTS));
                } else if (status === "INVALID_REQUEST") {
                  rej(new Error(GooglePlacesError.INVALID_REQUEST));
                } else if (status === "REQUEST_DENIED") {
                  rej(new Error(GooglePlacesError.REQUEST_DENIED));
                } else if (status === "OVER_QUERY_LIMIT") {
                  rej(new Error(GooglePlacesError.OVER_QUERY_LIMIT));
                } else {
                  // NOTE In case of UNKNOWN_ERROR
                  rej(new Error(GooglePlacesError.UNKNOWN_ERROR));
                }
              }
            )
            // Some of the errors are not handled by the callback function
            // Google Maps JavaScript API error: RefererNotAllowedMapError being one of them
            // getPlacePredictions is typed as a promise, but the catch is not defined
            // If it doesn't get resolved in the callback, we have to call the catch to throw an error
            // Then we will handle the error in the catch block below.
            // If we don't call this catch, it will stay in the loading loop forever (when invalid key is used)
            .catch((e: Error) => {
              throw new Error(e.message);
            });
        })
    )
    .catch((error: Error) => {
      // Check if the error is the one we are expecting
      if (Object.values(GooglePlacesError).some(x => x === error.message)) {
        throw new Error(error.message);
      }
      throw new Error(GooglePlacesError.LOADING_ERROR);
    });

export const getPlaceDetails = (id: string) =>
  serviceLoader.load().then(
    google =>
      new Promise<GetAddressResult>((res, _rej) => {
        const service = new google.maps.places.PlacesService(
          // This is fake, just to make the library work
          document.createElement("div")
        );
        service.getDetails(
          { placeId: id, fields: ["address_components"] },
          (place, status) => {
            if (
              status === google.maps.places.PlacesServiceStatus.OK ||
              status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
            ) {
              res({
                address: makeAddressResult(place?.address_components ?? []),
              });
            } else {
              res({
                error: "Unknown error occurred",
              });
            }
          }
        );
      })
  );

export const googlePlacesAutocompleteServiceInfo: AutocompleteServiceInfo = {
  name: "Google Places Autocomplete",
  getPredictions: props => getAutocompletePredictions(props),
};
