// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Ui__SlideInRightView from "./Ui__SlideInRightView";

var make = Ui__SlideInRightView.SlideInRightView;

export {
  make ,
}
/* make Not a pure module */
